import {Base} from "@/api/Base"

const api = "plan"

export class PlanAdd extends Base {
    path = api + "/add"
}

export class PlanDelete extends Base {
    path = api + "/delete"
}

export class PlanList extends Base {
    path = api + "/list"
}

export class PlanEdit extends Base {
    path = api + "/edit"
}

export class PlanListAll extends Base {
    path = api + "/listAll"
}
