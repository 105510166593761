<template>
  <Row id="GetConfiguration">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.GetConfiguration')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row>
              <Col span="24">
                    <FormItem :label="$t('chargeStationImages.field.id_cha_poi')" name="idChaPoi">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="searchForm.idChaPoi"
                                :placeholder="$t('chargeStationImages.validate.required.id_cha_poi')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['chargingStationList']" :value="item['idChaPoi']">{{ item['chargeBoxId'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
              <Col span="24">
                <FormItem :label="$t('public.field.status')">
                  <Textarea :rows="5" v-model:value="searchForm.keysStr">

                  </Textarea>
                </FormItem>
              </Col>
              <Col span="24">
                <Button type="primary" @click="search_submit" :loading="loading">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>

      </Row>
    </Col>
  </Row>
</template>

<script>
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,Textarea } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { ChargingStationGetConfiguration, ChargingStationListAll } from "@/api/chargingStation"

export default {
  name: "GetConfiguration",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Textarea
  },
  data() {
    return {
      loading: false,
      searchForm: {
      },
      selectList:{
        chargingStationList: [],
      }
    };
  },
  methods: {
    get_list() {
      const api = new ChargingStationListAll();
      api.get().then((response) => {
        if (0 === parseInt(response.data.code)) {
            this.selectList.chargingStationList = response.data.data;
        } else {
            if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
        }
      });
    },
    search_submit() {
      this.loading = true
      const api = new ChargingStationGetConfiguration();
      delete this.searchForm.keysStr
      api.get(this.searchForm).then((response) => {
        this.loading = false
        if (0 === parseInt(response.data.code)) {
          this.searchForm.keysStr = JSON.stringify(response.data.data)
        } else {
            if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
        }
      });
    },
    search_reset() {
      this.searchForm = {
        // search: "",
        // status: 0,
        // order: "",
      }
      this.get_list()
    }
  },
  mounted() {
    this.get_list()
  },
}
</script>

<style lang="less">
@import url("style.less");
</style>
