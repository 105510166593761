<template>
  <Modal
      id="OrderDetail"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="visible = !visible"
      :title="$t('order.name.detailTitle')" :zIndex="9999999900"
      :destroyOnClose="true"
  >
    <Descriptions>
      <DescriptionsItem :label="$t('order.table.id_ord')">{{ data.idOrd }}</DescriptionsItem>
      <DescriptionsItem label="Pay ID">{{ handelNull(data.yedPayId) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.name')">{{ data.regName }}</DescriptionsItem>

      <DescriptionsItem :label="$t('meter.table.name')">{{ data.metName }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.id_car')">{{ data.carModel }}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.table.car_license')">{{ data.carLicense }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.id_con')">{{ data.connectorName }}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.account')">{{ data.account }}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{ data.phone }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.order_time')">{{ data.orderTime }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.status')">{{ data.status }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.postpaid')">{{ data.postpaid }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.amount')">{{ handelNull(data.amount) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.payment_time')">{{ handelNull(data.paymentTime) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.pay_type')">{{ data.payType }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.payment_method')">{{ handelNull(data.paymentMethod) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.price')">{{ data.price }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.id_tra_log')">{{ handelNull(data.idTraLog) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('public.name.args')">{{ handelNull(data.byUnit) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.type')">{{ data.type }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_minutes') + '(min)'">{{ data.chargeMinutes }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.electricity') + '(kwh)'">{{ handelNull(data.electricity) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_start_time')">{{ handelNull(data.chargeStartTime) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_end_time')">{{ handelNull(data.chargeEndTime) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.chargeEndTimeActual')">{{ handelNull(data.chargeEndTimeActual) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.currentElectricity') + '(kwh)'">{{ handelNull(data.currentElectricity) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.ref_id_pla') + '(ID)'">{{ handelNull(data.refIdPla) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('plan.table.free_charging_time_limit') + '(min)'">{{ handelNull(data.freeMeter) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.maximumMinute') + '(min)'">{{ handelNull(data.maximumMinute) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.maximumPower') + '(kwh)'">{{ handelNull(maximumPower) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('region.table.directDebit')">{{ handelNull(directDebit) }}</DescriptionsItem>
      <DescriptionsItem label="SoC">{{ handelNull(data.soc) === '-' ? handelNull(data.soc) : handelNull(data.soc) + '%' }}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.remark')">{{ handelNull(data.remark) }}</DescriptionsItem>
    </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "OrderDetail",
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem} from "ant-design-vue";
import {ref, reactive} from 'vue'
import {useI18n} from 'vue-i18n'

import {OrderGetInfoByIdBalLog} from "@/api/order";

const i18n = useI18n()

const selectList = reactive({
  // typeList: [{value: 1,label: '按时'},{value: 2,label: '按電量'},{value: 3,label: '按用戶計劃'}],
  // statusList: [{value: 1,label: '未支付'},{value: 2,label: '已支付'},{value: 3,label: '充電中'},{value: 4,label: '已完成'}],
  typeList: [
    {value: 1, label: i18n.t("public.chargeType.0")},
    {value: 2, label: i18n.t("public.chargeType.1")},
    {value: 3, label: i18n.t("public.chargeType.2")},
  ],
  statusList: [
    {value: 1, label: i18n.t("public.payStatus.0")},
    {value: 2, label: i18n.t("public.payStatus.1")},
    {value: 3, label: i18n.t("public.payStatus.2")},
    {value: 4, label: i18n.t("public.payStatus.3")},
    {value: 5, label: i18n.t("public.payStatus.4")}
  ],
})
const data = ref({})
const visible = ref(false)
function detailShow(idBalLog) {
  const api = new OrderGetInfoByIdBalLog()
  api.get({idBalLog: idBalLog}).then(res =>{
    const oj = res.data.data;
    oj.electricity = oj.electricity === undefined ? 0 : oj.electricity / 1000;
    oj.currentElectricity = oj.currentElectricity === undefined ? 0 : oj.currentElectricity / 1000;

    oj.chargeMinutes = oj.type === 1 || oj.type === 3 ? handelNull(oj.chargeMinutes) : '-';

    const type = selectList.typeList.find(item => item.value === oj.type)
    oj.type = type === undefined ? type : type.label
    const status = selectList.statusList.find(item => item.value === oj.status)
    oj.status = status === undefined ? status : status.label

    if (oj.payType === undefined || oj.payType === null) {
      oj.payType = '-'
    } else if (oj.payType === 2) {
      oj.payType = 'Balance'
    } else if (oj.payType === 16) {
      oj.payType = 'Swipe'
    } else {
      oj.payType = 'Online Pay'
    }

    if (oj.postpaid === 1) {
      oj.postpaid = i18n.t("public.isTrue.1")
    } else if (oj.postpaid === 2) {
      oj.postpaid = i18n.t("public.payStatus.3")
    } else {
      oj.postpaid = i18n.t("public.isTrue.0")
    }

    data.value = oj;
    visible.value = true;
  })
}

function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow,
});
</script>

<style lang="less">
</style>