<template>
  <Modal
      id="RoleEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="roleForm"
                :model="form"
                layout="vertical"
                :rules="data.validateRules"
            >
              <Row justify="space-around" :gutter="[18,18]">
                <Col :span="12">
                  <Row>
                    <Col :span="24">
                      <FormItem :label="$t('role.field.name')" name="nameRole">
                        <Input :placeholder="$t('role.validate.required.name')"
                               v-model:value="form.name">
                        </Input>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col :span="24">
                      <FormItem :label="$t('role.field.remark')" name="remarkRole">
                      <Textarea
                          v-model:value="form.remark"
                          :placeholder="$t('role.validate.required.remark')"
                          :auto-size="{ minRows: 5, maxRows: 10 }"
                          showCount=""
                      />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col :span="12">
                  <FormItem
                      :label="$t('role.field.authorityList')"
                      name="authorityList"
                      ref="authorityList"
                  >
                    <div style="height: 500px; overflow: auto;">
                      <Tree
                          checkable
                          v-model:checkedKeys="checkedKeys"
                          :tree-data="treeData"
                          autoExpandParent
                          @check="checkTree"
                      />
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "RoleEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
  Tree,
} from "ant-design-vue";
import {RoleAdd, RoleEdit, RoleGetAuthIdsByRolId, RoleGetAuthTree} from "@/api/role";

import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let visible = ref(false)
let actionType = ref('')
let title = ref('')

const roleForm = ref()

const validateRules = {
  idRol: [
    {
      required: true,
      message: i18n.t("role.validate.required.id_rol"),
      trigger: "change",
      type: "number",
    },
  ],
  nameRole: [
    {
      required: true,
      message: i18n.t("role.validate.required.name"),
      trigger: "change",
    },
  ],
  remarkRole: [
    {
      required: true,
      message: i18n.t("role.validate.required.remark"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {},
  checkedKeys: [],
  treeData: [],
})
const {form, checkedKeys, treeData} = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add")
  actionType.value = "add";
  resetData();
  getTreeData()
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  resetData();
  getAuthIds(obj.idRol)
  getTreeData()
  form.value = obj;
  visible.value = true;
}

function submit() {
  roleForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new RoleAdd();
        break;
      case "edit":
        api = new RoleEdit();
        break;
      default:
        return false;
    }

    form.value.checkedKeys = checkedKeys.value
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {
  });
}

function resetData() {
  form.value = {};
  checkedKeys.value = [];
}

async function getTreeData() {
  const api = new RoleGetAuthTree()
  await api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      treeData.value = response.data.data
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function getAuthIds(id) {
  const api = new RoleGetAuthIdsByRolId()
  api.get({idRol: id}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      checkedKeys.value = response.data.data
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function checkTree(e) {
  // console.log("checkedKeys =>", checkedKeys.value)
}

defineExpose({
  addShow,
  editShow
});
</script>