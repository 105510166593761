<template>
  <div style="height: 100vh">
    <Card id="inner-float" title="註銷帳戶" style="width: 600px">
      <Form ref="formRef" :layout="formState.layout" :model="formState" :rules="rules" v-bind="formItemLayout">
        <FormItem label="帳戶" name="account">
          <Input v-model:value="formState.account" placeholder="請輸入帳戶"/>
        </FormItem>
        <FormItem label="密碼" name="password">
          <Input v-model:value="formState.password" placeholder="請輸入帳戶密碼"/>
        </FormItem>
        <FormItem label="校验密碼" name="password1">
          <Input v-model:value="formState. password1" placeholder="请再次輸入密碼"/>
        </FormItem>
        <FormItem :wrapper-col="buttonItemLayout.wrapperCol">
          <Button type="primary" @click="cancelYourAccount">提交</Button>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script>
export default {
  name: "DelAccount",
}
</script>
<script setup>
import {computed, reactive, ref} from 'vue';
import {
  Card,
  Form,
  FormItem,
  Input,
  Button, message
} from "ant-design-vue";
import { UserCancelAccount } from "@/api/user";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

const formRef = ref()

const formState = reactive({
  layout: 'horizontal',
  account: '',
  password: '',
  password1: '',
});
const rules = {
  account: [
    {
      required: true,
      message: "請輸入帳戶",
      trigger: "change",
      // type: "number",
    },
  ],
  password: [
    {
      required: true,
      message: "請輸入帳戶密碼",
      trigger: "change",
      // type: "number",
    },
  ],
  password1: [
    {
      required: true,
      message: "请再次輸入密碼",
      trigger: "change",
      // type: "number",
    },
  ],
}

// 注销账户
function cancelYourAccount() {
  if (formState.password !== formState.password1 ) {
    message.error("兩次密碼不一致");
    return
  }
  formRef.value?.validate().then(() => {
    let api = new UserCancelAccount();
    api.post(formState).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {
  });
}

const formItemLayout = computed(() => {
  const {layout} = formState;
  return layout === 'horizontal'
      ? {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      }
      : {};
});
const buttonItemLayout = computed(() => {
  const {layout} = formState;
  return layout === 'horizontal'
      ? {
        wrapperCol: {
          span: 14,
          offset: 4,
        },
      }
      : {};
});
</script>
<style scoped>
#inner-float {
  position: relative;
  top: 250px;
  left: 35%;
  /*margin-left: -100px;*/
}
</style>

