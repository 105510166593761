import {Base} from "@/api/Base"

const api = "role"

export class RoleAdd extends Base {
    path = api + "/add"
}

export class RoleDelete extends Base {
    path = api + "/delete"
}

export class RoleList extends Base {
    path = api + "/list"
}

export class RoleEdit extends Base {
    path = api + "/edit"
}

export class RoleListAll extends Base {
    path = api + "/listAll"
}

export class RoleGetAuthIdsByRolId extends Base {
    path = api + "/getAuthIdsByRolId"
}

export class RoleGetAuthTree extends Base {
    path = api + "/getAuthTree"
}
