<template>
  <Modal
      id="CardEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" >
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around" :gutter="[18,18]">
                <Col span="10" v-if="showId">
                  <FormItem :label="$t('card.table.cardId')" name="cardId">
                    <Input :placeholder="$t('card.validate.cardId')"
                           v-model:value="form.id" disabled>
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('card.table.cardNo')" name="cardNo">
                    <Input
                        :placeholder="$t('card.validate.cardNo')"
                        v-model:value="form.cardNo"
                        :disabled="actionType==='edit'"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('card.table.cardType')" name="cardType">
                    <Select v-model:value="form.cardType" :placeholder="$t('card.validate.cardType')" :dropdownStyle="{zIndex: 9999999950}">
                      <SelectOption :value="0">{{ $t('card.cardType.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('card.cardType.1') }}</SelectOption>
                      <SelectOption :value="2">{{ $t('card.cardType.2') }}</SelectOption>
                      <SelectOption :value="3">{{ $t('card.cardType.3') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('card.table.cardStatus')" name="cardStatus">
                    <Select v-model:value="form.cardStatus" :placeholder="$t('card.validate.cardStatus')"
                            :dropdownStyle="{zIndex: 9999999950}">
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10"></Col>
<!--                <Col span="10"></Col>-->
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('public.name.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount=""
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "cardEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  SelectOption,
  Textarea
} from "ant-design-vue";
import {CardAdd, CardEdit} from "@/api/card";
import {ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let title = ref("")
let visible = ref(false)
let actionType = ref("")
let showId = ref(false)
const refForm = ref()
const validateRules = reactive({
  cardNo: [
    {
      required: true,
      message: i18n.t("card.validate.cardNo"),
      trigger: "change",
    },
  ],
  cardType: [
    {
      required: true,
      message: i18n.t("card.validate.cardType"),
      trigger: "change",
    },
  ],
  cardStatus: [
    {
      required: true,
      message: i18n.t("card.validate.cardStatus"),
      trigger: "change",
    },
  ],
},)
const data = reactive({
  form: {},
})
const {form} = toRefs(data)

function addShow() {
  showId.value = false
  title.value = i18n.t("public.name.add")
  actionType.value = "add"
  _resetData()
  visible.value = true
}

async function editShow(obj) {
  showId.value = true
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  form.value = obj;
  visible.value = true;
}

function submit() {
  refForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new CardAdd();
        break;
      case "edit":
        api = new CardEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

defineExpose({
  addShow,
  editShow
});
</script>
