<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('iPadManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('iPadManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('iPadManage.field.machineCode')" name="machineCode">
                    <Input :placeholder="$t('iPadManage.validate.machineCode')"
                           v-model:value="form.machineCode">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('iPadManage.field.name')" name="name">
                    <Input :placeholder="$t('iPadManage.validate.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('iPadManage.field.regId')" name="regId">
<!--                    <Input :placeholder="$t('iPadManage.validate.regId')"-->
<!--                           v-model:value="form.regId">-->
<!--                    </Input>-->
                    <Select
                        v-model:value="form.regId"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        :options="this.$props.selectList"
                        :filter-option="filterOption"
                        @change="handleChange"
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('iPadManage.field.seckey')" name="seckey">
                    <Input :placeholder="$t('iPadManage.validate.seckey')"
                           v-model:value="form.seckey">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('iPadManage.field.args')" name="args">
                    <Input :placeholder="$t('iPadManage.validate.args')"
                           v-model:value="form.args">
                    </Input>
                  </FormItem>
                </Col>
  

                <Col span="10">

                </Col>
            
                <Col span="22">
                  <FormItem :label="$t('iPadManage.field.remark')" name="remark">
                    <Textarea :placeholder="$t('iPadManage.validate.remark')"
                              v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import {IpadAddInfo, IpadEdit} from "@/api/iPad";

export default {
  name: "FormModel",
  props:['selectList'],
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {

      visible: false,
      actionType: "",
      form: {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        reLeaseTime: undefined,
        remark: undefined,
      },
      fileList: {},
      validateRules: {
        id: [
          {
            required: false,
            message: this.$t("iPadManage.validate.id"),
            trigger: "change",
          },
        ],
        machineCode: [
          {
            required: true,
            message: this.$t("iPadManage.validate.machineCode"),
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("iPadManage.validate.name"),
            trigger: "change",
          },
        ],
        regId: [
          {
            required: true,
            message: this.$t("iPadManage.validate.regId"),
            trigger: "change",
          },
        ],
        seckey: [
          {
            required: true,
            message: this.$t("iPadManage.validate.seckey"),
            trigger: "change",
          },
        ],
        args: [
          {
            required: false,
            message: this.$t("iPadManage.validate.args"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: this.$t("iPadManage.validate.remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    "visible": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      // 格式化日期
      // obj.reLeaseTime = obj.reLeaseTime === undefined ? new Date() : moment(obj.reLeaseTime).format('YYYY-MM-DD HH:mm:ss')
      this._resetData();
      this.actionType = "edit";

      this.form = obj;
      this.visible = true;
    },
    submit() {
      this.$refs["refForm"].validate().then(() => {
        let api;
        switch (this.actionType) {
          case "add":
            console.log("add");
            api = new IpadAddInfo();
            break;
          case "edit":
            console.log("edit");
            api = new IpadEdit();
            break;
          default:
            return false;
        }
        // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
        // this.form.reLeaseTime = moment(this.form.reLeaseTime).format('YYYY-MM-DD HH:mm:ss');
        delete this.form.createTime
        delete this.form.updateTime
        api.post(this.form).then((response, any) => {
          if (0 === parseInt(response.data.code)) {
            message.success(this.$t("succeedMsg.0"));
            // 触发主页面组件定义事件
            this.$emit("updateList", response.data.data);
            this.visible = false;
          } else {
            if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
          }
        });
      }).catch(() => {});
    },

    // reset data
    _resetData() {
      this.form = {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        remark: undefined,
      };
    },

    // 搜索框过滤筛选
    filterOption(input, option) {
      return option.label.indexOf(input) >= 0;
    },

    // 搜索框选择事件
    handleChange(value) {
      this.form.regId = value
    },
  },
  mounted() {

  },
};
</script>

<style lang="less">
@import url("style");
</style>
