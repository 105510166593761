<template>
  <Modal
      id="MeterEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="submitForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.reId')" name="reId">
                    <Select
                        v-model:value="form.reId"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="regList"
                        :filter-option="filterOption"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.type')" name="type">
                    <Select v-model:value="form.type" :placeholder="$t('serviceManage.validate.type')"
                            :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">{{ $t('serviceManage.type.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('serviceManage.type.1') }}</SelectOption>
                      <SelectOption :value="2">{{ $t('serviceManage.type.2') }}</SelectOption>
                      <SelectOption :value="3">{{ $t('serviceManage.type.3') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.name')" name="name">
                    <Input :placeholder="$t('serviceManage.validate.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.description')" name="description">
                    <Input :placeholder="$t('serviceManage.validate.description')" v-model:value="form.description">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.address')" name="address">
                    <Input :placeholder="$t('serviceManage.validate.address')" v-model:value="form.address"></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('serviceManage.table.status')" name="status">
                    <Select v-model:value="form.status" :placeholder="$t('serviceManage.validate.status')"
                            :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "ServiceEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Select,
  SelectOption,
} from "ant-design-vue";
import {ServiceAdd, ServiceEdit} from "@/api/service";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const submitForm = ref()

let visible = ref()
let title = ref("")
let actionType = ref("")
const validateRules = {
  type: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.type"),
      trigger: "change",
      type: "number",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.status"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.name"),
      trigger: "change",
    },
  ],
  address: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.address"),
      trigger: "change",
    },
  ],
  description: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.description"),
      trigger: "change",
    },
  ],
  reId: [
    {
      required: true,
      message: i18n.t("serviceManage.validate.reId"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  regList: [],
  form: {
    idMet: undefined,
    idReg: undefined,
    name: undefined,
    currentThreshold: undefined,
    tenantKey: undefined,
  }
})
const {form, regList} = toRefs(data)

function addShow(reg) {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  regList.value = reg
  visible.value = true;
}

async function editShow(obj, reg) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  delete obj.createTime
  delete obj.updateTime
  _resetData();
  regList.value = reg

  form.value = obj;
  visible.value = true;
}

function submit(){
  submitForm.value.validate().then(() => {
        let api;
        switch (this.actionType) {
          case "add":
            api = new ServiceAdd();
            break;
          case "edit":
            api = new ServiceEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then((response, any) => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if (response.data.code !== undefined) {
              message.error(i18n.t("errorMsg." + response.data.code));
            }
          }
        });
      }).catch(() => {
  });
}

function _resetData(){
  form.value = {
    idMet: undefined,
    idReg: undefined,
    name: undefined,
    currentThreshold: undefined,
    tenantKey: undefined,
  };
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

defineExpose({
  addShow,
  editShow
})
</script>
