<template>
  <Modal id="UserPlanDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('userPlan.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('balanceLog.table.id_bal_log')">{{data.idUsePla}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{data.phone}}</DescriptionsItem>
      <DescriptionsItem :label="$t('statisticsManage.table.identifier')">{{data.identifier}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.id_pla')">{{data.planName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.arrearsCount')">{{data.arrearsCount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.table.free_charging_time_limit')">{{data.currentlyFreeTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.start_date')">{{data.startDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.status')">{{status}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.tenant_key')">{{data.tenantKey}}</DescriptionsItem>

      <DescriptionsItem :label="$t('userPlan.table.createType')">{{data.createType}}</DescriptionsItem>

      <DescriptionsItem :label="$t('public.name.createBy')">{{data.createBy}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.createTime')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateBy')">{{handelNull(data.updateBy)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateTime')">{{handelNull(data.updateTime)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.remark')">{{handelNull(data.remark)}}</DescriptionsItem>

<!--      <DescriptionsItem :label="$t('balanceLog.name.title')">{{ data.idBalLogs }}</DescriptionsItem>-->
  </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "UserPlanDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {ref, reactive} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()

let visible = ref(false)
const selectList = reactive({})
let data = reactive({})
let status = ref('')

async function detailShow(obj) {
  status.value = i18n.t('public.doingStatus.' + obj.status)
  data = obj;
  visible.value = true;
}

function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow
});

</script>

<style lang="less">
</style>