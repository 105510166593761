<template>
  <div :class="{container:true, 'sign-up-model':vari}">
    <div class="inner-left-container">
      <div class="login-content">
        <span style="color: white; font-size: 50px">EvCharge</span>
<!--                <Button type="primary" @click="clickGoButton" size="large">去注册</Button>-->
      </div>
<!--      <img src="@/assets/undraw_2.svg" class="image" alt="">-->
      <img src="@/assets/draw1.svg" class="image" alt="">
    </div>
    <div class="inner-right-container">
      <div class="register-content">
        <span style="color: white; font-size: 50px">EvCharge</span>
<!--                <Button type="primary" @click="clickGoButton" size="large">去登陆</Button>-->
      </div>
<!--      <img src="@/assets/undraw_2.svg" class="image" alt="">-->
<!--      <img src="@/assets/draw1.svg" class="image" alt="">-->
    </div>
    <div class="innet-sign-up-container">
      <LoginForm :class="{'sign-up-model':vari}"></LoginForm>
<!--      <RegisterForm :class="{'sign-up-model':vari}"></RegisterForm>-->
    </div>
  </div>

  <div style="width: 100%; text-align:center;flex: auto;bottom: 0;position: absolute; height: 20px">{{ store.getters.getCopyRight }}</div>
</template>

<!--<script>-->
<!--export default {-->
<!--  // eslint-disable-next-line vue/multi-word-component-names-->
<!--  name: "Login"-->
<!--}-->
<!--</script>-->
<script setup>
import {Button} from "ant-design-vue";
import { useStore } from 'vuex';
import LoginForm from "@/components/Login/loginForm"
import RegisterForm from "@/components/Login/registerForm"
import {ref, onMounted} from "vue";

const store = useStore()

let vari = ref(false)
let clickState = ref(true)
// const clickGoButton = () => {
//   vari.value = !vari.value
// }
onMounted(()=>{
  // 清空tenantDb缓存标识
  store.commit("reset", undefined);
})
</script>

<style lang="less" scoped>
//@import url("style.less");
.container {
  width: 95vw;
  height: 100vh;
  background-color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
}

.container::before {
  content: "";
  width: 1650px;
  height: 1650px;
  background-color: rgb(160, 209, 35);
  /* background-color: rgb(108, 99, 255); */
  position: absolute;
  border-radius: 50%;
  transform: translateY(-50%);
  right: 48%;
  top: -10%;
  transition: 1.8s ease-in-out;
  z-index: 1;
}

.inner-left-container {
  width: 0;
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 3rem 10% 2rem 10%;
  pointer-events: all;
}

.inner-right-container {
  width: 0;
  flex: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 3rem 10% 2rem 10%;
  pointer-events: none;
}

.container .inner-right-container .register-content,
.container .inner-right-container .image {
  transform: translate(1000px);
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
}

.container .inner-left-container .login-content,
.container .inner-left-container .image {
  transform: translateX(0px);
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
}

.image {
  width: 100%;
}

.innet-sign-up-container {
  width: 50%;
  height: 50%;
  /* background-color: antiquewhite; */
  position: absolute;
  right: 0;
  top: 20%;
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
  display: grid;
  grid-template-columns: 1fr;
}

/* 动画 */
.container.sign-up-model::before {
  transform: translate(100%, -50%);
  transition: 1.8s ease-in-out;
  right: 52%;
}

.container.container.sign-up-model .inner-right-container .register-content,
.container.sign-up-model .inner-right-container .image {
  transform: translate(0px);
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
}

.container.container.sign-up-model .inner-left-container .login-content,
.container.sign-up-model .inner-left-container .image {
  transform: translateX(-1000px);
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
}

.container.sign-up-model .innet-sign-up-container {
  width: 50%;
  height: 50%;
  top: 20%;
  right: 50%;
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
}

.container.sign-up-model .inner-right-container {
  pointer-events: all;
}

.container.sign-up-model .inner-left-container {
  pointer-events: none;
}
</style>
