<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('card.table.cardNo')" name="cardNo">
                  <Input :placeholder="$t('card.validate.cardNo')" v-model:value="searchForm.cardNo" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('card.table.cardType')" name="cardType">
                  <Select v-model:value="searchForm.cardType" :placeholder="$t('card.validate.cardType')" :dropdownStyle="{zIndex: 9999999950}">
                    <SelectOption :value="0">{{ $t('card.cardType.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('card.cardType.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('card.cardType.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('card.cardType.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('card.table.cardStatus')" name="cardStatus">
                  <Select v-model:value="searchForm.cardStatus" :placeholder="$t('card.validate.cardStatus')"
                          :dropdownStyle="{zIndex: 9999999950}">
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <!--                <FormItem :label="$t('card.table.cardId')" name="cardId">-->
                <!--                  <Input :placeholder="$t('card.validate.cardId')" v-model:value="searchForm.cardId" allowClear></Input>-->
                <!--                </FormItem>-->
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button :loading="exportButtonLoading" type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>
              <span>&nbsp;</span>
              <Button @click="showUploadForm" >{{ $t('public.name.Import') }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list"
               :scroll="{x: 1000}"
               rowKey="id"
               :loading="loading" :pagination="tablePagination" @change="chang_page">

          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('CardManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
<!--                <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>-->
<!--                <span>&nbsp;</span>-->
                <Button v-if="checkPermission('CardManage.Edit')" type="primary" size="small" @click="edit(record)">
                  {{ $t("public.name.edit") }}
                </Button>
                <span>&nbsp;</span>
                <Button v-if="checkPermission('CardManage.Delete')" type="primary" danger size="small" @click="del(record.cardId, record.tenantKey)">{{ $t("public.name.del") }}
                </Button>
              </div>
            </template>

            <template v-if="column.dataIndex === 'cardType'">
              <Tag v-if="record.cardType === 0" color="green">{{ $t("card.cardType.0") }}</Tag>
              <Tag v-else-if="record.cardType === 1" >{{ $t("card.cardType.1") }}</Tag>
              <Tag v-else-if="record.cardType === 2" color="orange">{{ $t("card.cardType.2") }}</Tag>
              <Tag v-else-if="record.cardType === 3" color="cyan">{{ $t("card.cardType.3") }}</Tag>
              <Tag v-else>{{ record.cardType }}</Tag>
            </template>

            <template v-if="column.dataIndex === 'cardStatus'">
              <Switch
                  :checked="record.cardStatus === 1"
                  @click="e => switchClick(e, record.cardId)"
                  :checked-children="$t('public.isTrue.1')"
                  :un-checked-children="$t('public.isTrue.0')"
                  :loading="switchLoading"
              />
            </template>
          </template>

        </Table>
      </div>
    </Col>
    <UploadForm ref="refUploadForm"></UploadForm>
    <CardEdit ref="refCardEdit" @updateList="get_list" />
  </Row>
</template>

<script>
export default {
  name: "cardManage"
}
</script>
<script setup>
import {createVNode, onMounted} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  SelectOption,
  Modal,
  message,
  Tag,
  Switch
} from "ant-design-vue";
import moment from "moment";
import CardEdit from "./CardEdit";
import {ref, reactive, toRefs} from "vue";
import {CardDelete, CardEditStatus, CardList, CardExportToExcel} from "@/api/card";
import {useI18n} from "vue-i18n"
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {useStore} from "vuex";
import UploadForm from "./UploadForm";
const i18n = useI18n()

const refUploadForm = ref()
const refCardEdit = ref()
let loading = ref(false)
let switchLoading = ref(false)
let exportButtonLoading = ref(false)
let columns = [
  {
    title: i18n.t("public.name.index"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    customRender: ({index}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  // {
  //   title: i18n.t("card.table.cardId"),
  //   width: 60,
  //   ellipsis: true,
  //   dataIndex: "cardId",
  //   key: "cardId"
  // },
  {
    title: i18n.t("card.table.cardNo"),
    width: 120,
    ellipsis: true,
    dataIndex: "cardNo",
    key: "cardNo"
  },
  {
    title: i18n.t("card.table.cardType"),
    width: 60,
    ellipsis: true,
    dataIndex: "cardType",
    key: "cardType"
  },
  {
    title: i18n.t("user.table.account"),
    width: 60,
    ellipsis: true,
    dataIndex: "account",
    key: "account"
  },
  {
    title: i18n.t("card.table.cardStatus"),
    width: 60,
    ellipsis: true,
    dataIndex: "cardStatus",
    key: "cardStatus"
  },
  {
    title: i18n.t("public.name.createTime"),
    width: 100,
    ellipsis: true,
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    title: i18n.t("public.name.remark"),
    width: 80,
    ellipsis: true,
    dataIndex: "remark",
    key: "remark"
  },
  {key: "action", fixed: "right", width: 120, dataIndex:"action"},
]

let searchData = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: []
})
const {searchForm, tablePagination, list} = toRefs(searchData)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list()
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}
function detail(pagination) {

}
function add() {
  refCardEdit.value?.addShow()
}
function edit(text) {
  refCardEdit.value?.editShow(text)
}
function del(id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new CardDelete();
      let ids = []
      ids.push(id)
      api.post({ ids: ids}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}
function exportToExcel() {
  loading.value = true
  const api = new CardExportToExcel()
  api.getDownLoad(searchForm.value).then(() => {}).finally(f => {
    loading.value = false
  })
}

function switchClick(e, id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      loading.value = true
      const cStatus = e ? 1 : 0
      const api = new CardEditStatus()
      api.post({cardId: id, cardStatus: cStatus}).then(response => {
        if (0 === parseInt(response.data.code)) {
          message.success(i18n.t("succeedMsg.0"));
          get_list()
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      }).finally(()=>{
        loading.value = false
      })
    },
  });
}

function get_list() {
  loading.value = true;
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  const api = new CardList();
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function showUploadForm() {
  refUploadForm.value?.showForm();
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.CardManage'));

onMounted(()=>{
  get_list();
})
defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>