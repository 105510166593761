<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.toFrom')" toFrom="toFrom">
                  <Input :placeholder="$t('noticeManage.validate.toFrom')" v-model:value="searchForm.toFrom" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.type')" toFrom="type">
                  <Select v-model:value="searchForm.type" :placeholder="$t('noticeManage.validate.type')" allowClear>
                    <SelectOption :value="0">{{ $t('noticeManage.noticeName.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('noticeManage.noticeName.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.noticeType')" toFrom="noticeType">
                  <!--                  <Input :placeholder="$t('noticeManage.validate.noticeType')" v-model:value="searchForm.noticeType" allowClear></Input>-->
                  <Select
                      v-model:value="value"
                      mode="multiple"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="options"
                      @change="handleChange"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('noticeManage.field.status')" toFrom="status">
                  <Select v-model:value="searchForm.status" :placeholder="$t('noticeManage.validate.status')" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button type="primary" size="default" @click="add()">{{ $t('public.name.add') }}</Button>
              <span>&nbsp;</span>
              <Button type="primary" size="default" danger @click="delByIds()" :disabled="selectedRowKeys === undefined || selectedRowKeys.length === 0">{{ $t('public.name.batchDel') }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table
            :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :pagination="tablePagination"
            :scroll="{ x: 1200}"
            @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'noticeType'">
              <span>
                {{ $t("noticeManage.field.noticeType") }}
              </span>
            </template>

            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('NoticeManage.Add')" type="primary" size="small"
                        shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>
          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'noticeType'">
              <span>
                 <Tag color="#f50" v-if="record.noticeType.indexOf(0) >= 0">{{$t("noticeManage.noticeType.0")}}</Tag>
                 <Tag color="#87d068" v-if="record.noticeType.indexOf(1) >= 0">{{$t("noticeManage.noticeType.1")}}</Tag>
                 <Tag color="#2db7f5" v-if="record.noticeType.indexOf(2) >= 0">{{$t("noticeManage.noticeType.2")}}</Tag>
                 <Tag color="#2db7f5" v-if="record.noticeType.indexOf(3) >= 0">{{$t("noticeManage.noticeType.3")}}</Tag>
               </span>
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                  <span>&nbsp;</span>
                  <Button type="primary" danger size="small" @click="del(record)">{{ $t("public.name.del") }}</Button>
                </div>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <FormModel ref="refFormModel" @updateList="get_list" :selectList="selectList"></FormModel>
  </Row>
</template>

<script>
export default {
  name: "NoticeManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Table,
  Tag,
  Spin
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {NoticeGetListByPage, NoticeDelete} from "@/api/notice";
import FormModel from "./FormModel";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

let refFormModel = ref()

let value = ref()
let loading = ref(false)
let isClickLoading = ref(false)
const options = [
  {label: i18n.t("noticeManage.noticeType.0"), value: '0'},
  {label: i18n.t("noticeManage.noticeType.1"), value: '1'},
  {label: i18n.t("noticeManage.noticeType.2"), value: '2'},
]
const columns = [
  {
    title: i18n.t("noticeManage.field.index"),
    fixed: "center",
    width: 60,
    customRender: ({text, record, index, column}) => `${index + 1}`
  },
  {title: i18n.t("noticeManage.field.toFrom"), fixed: "center", width: 120, dataIndex: "toFrom", key: "toFrom"},
  {title: i18n.t("noticeManage.field.type"), fixed: "center", width: 80, dataIndex: "type", key: "type",
    customRender: ({text, record, index, column}) => {
      if (text === 0) {
        return i18n.t("public.name.email")
      } else if (text === 1) {
        return i18n.t("public.name.phone")
      } else {
        return i18n.t("public.name.unknown")
      }
    }},
  {title: i18n.t("noticeManage.field.noticeType"), fixed: "center", width: 180, dataIndex: "noticeType", key: "noticeType"},
  {title: i18n.t("noticeManage.field.status"), fixed: "center", width: 60, dataIndex: "status", key: "status",
    customRender: ({text, record, index, column}) => {
      if (text === 0) {
        return i18n.t("public.isTrue.0")
      } else if (text === 1) {
        return i18n.t("public.isTrue.1")
      } else {
        return i18n.t("public.name.unknown")
      }
    }},
  {
    title: i18n.t("noticeManage.field.createBy"),
    fixed: "center",
    width: 100,
    dataIndex: "createBy",
    key: "createBy"
  },
  {
    title: i18n.t("noticeManage.field.createTime"),
    fixed: "center",
    width: 100,
    dataIndex: "createTime",
    key: "createTime"
  },
  {key: "action", fixed: "center", width: 150, dataIndex: "action"},
]
const data = reactive({
  searchForm: {},
  selectList: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: [],
  selectedRowKeys: []
})
const {searchForm, selectList, tablePagination, list, selectedRowKeys} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new NoticeGetListByPage();
  // 构建查询参数
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data
    } else {
      list.value = [];
    }
  });
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  refFormModel.value?.editShow(obj);
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new NoticeDelete();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  tablePagination.current = 1
  get_list()
}

function search_reset() {
  value.value = undefined
  searchForm.value = {}
  get_list()
}

function handleChange(v) {
  value.value = v
  let a = ''
  for (let i=0; v.length > i; i++) {
    a = a + v[i] + ','
  }
  if (a.endsWith(',')) {
    a = a.substring(0, a.length-1)
  }
  searchForm.value.noticeType = a
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.NoticeManage'));
get_list();

defineExpose({
  get_list
})

</script>
