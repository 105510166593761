import {Base} from "@/api/Base"

const api = "ipad"

export class IpadGetListByPage extends Base {
    path = api + "/getListByPage"
}

export class IpadGetInfo extends Base {
    path = api + "/getInfo"
}

export class IpadAddInfo extends Base {
    path = api + "/addInfo"
}

export class IpadDelete extends Base {
    path = api + "/delInfo"
}

export class IpadEdit extends Base {
    path = api + "/editInfo"
}
