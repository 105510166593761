<template>
  <Modal
      id="CarEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="carForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('car.field.id_use')" name="idUse">
                    <!--关联表的下拉选择-->
                    <Select
                        v-model:value="form.idUse"
                        :placeholder="$t('car.validate.required.id_use')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                        :disabled="actionType === 'edit'"
                    >
                      <SelectOption :key="sk" v-for="(item,sk) in userList" :value="item['idUse']">
                        {{ item['account'] }}
                      </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.model')" name="model">
                    <Input :placeholder="$t('car.validate.required.model')"
                           v-model:value="form.model">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.car_license')" name="carLicense">
                    <Input :placeholder="$t('car.validate.required.car_license')"
                           v-model:value="form.carLicense">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.max_years_old')" name="maxYearsOld">
                    <InputNumber style="width: 100%" :placeholder="$t('car.validate.required.max_years_old')" v-model:value="form.maxYearsOld"></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.manufacture_year')" name="manufactureYear">
                    <Input :placeholder="$t('car.validate.required.manufacture_year')"
                           v-model:value="form.manufactureYear">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.maximum_power')" name="maximumPower">
                    <Input :placeholder="$t('car.validate.required.maximum_power')"
                           v-model:value="form.maximumPower">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.is_second_hand')" name="isSecondHand">
                    <Select
                        v-model:value="form.isSecondHand"
                        :placeholder="$t('car.validate.required.is_second_hand')"
                        :options="booleanList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.vehicle_inspection_date')" name="vehicleInspectionDate">
                    <Input :placeholder="$t('car.validate.required.vehicle_inspection_date')"
                           v-model:value="form.vehicleInspectionDate">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('car.field.insurance_expiry_date')" name="insuranceExpiryDate">
                    <Input :placeholder="$t('car.validate.required.insurance_expiry_date')"
                           v-model:value="form.insuranceExpiryDate">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <!-- <Col span="10">
                  <FormItem :label="$t('car.field.tenant_key')" name="tenantKey">
                                          <Input :placeholder="$t('car.validate.required.tenant_key')"
                                                 v-model:value="form.tenantKey">
                                          </Input>
                </FormItem>
                </Col> -->
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "CarEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  SelectOption,
  message,
} from "ant-design-vue";
import {CarAdd, CarEdit} from "@/api/car";
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()

const emits = defineEmits(['updateList'])
const carForm = ref()

const visible = ref(false)
let actionType = ref("")
let title = ref("")

const booleanList = [{value: 0, label: i18n.t("public.isTrue.0")}, {value: 1, label: i18n.t("public.isTrue.1")}]
const validateRules = {
  idCar: [
    {
      required: true,
      message: i18n.t("car.validate.required.id_car"),
      trigger: "change",
      type: "number",
    },
  ],
  idUse: [
    {
      required: true,
      message: i18n.t("car.validate.required.id_use"),
      trigger: "change",
      type: "number",
    },
  ],
  model: [
    {
      required: true,
      message: i18n.t("car.validate.required.model"),
      trigger: "change",
    },
  ],
  carLicense: [
    {
      required: true,
      message: i18n.t("car.validate.required.car_license"),
      trigger: "change",
    },
  ],
  maxYearsOld: [
    {
      required: false,
      message: i18n.t("car.validate.required.max_years_old"),
      trigger: "change",
      type: "number",
    },
  ],
  manufactureYear: [
    {
      required: false,
      message: i18n.t("car.validate.required.manufacture_year"),
      trigger: "change",
    },
  ],
  maximumPower: [
    {
      required: false,
      message: i18n.t("car.validate.required.maximum_power"),
      trigger: "change",
    },
  ],
  isSecondHand: [
    {
      required: false,
      message: i18n.t("car.validate.required.is_second_hand"),
      trigger: "change",
      type: "number",
    },
  ],
  vehicleInspectionDate: [
    {
      required: false,
      message: i18n.t("car.validate.required.vehicle_inspection_date"),
      trigger: "change",
    },
  ],
  insuranceExpiryDate: [
    {
      required: false,
      message: i18n.t("car.validate.required.insurance_expiry_date"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("car.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
}
let userList = ref([])
let form = ref({
  idCar: "",
  idUse: "",
  model: "",
  carLicense: "",
  maxYearsOld: "",
  manufactureYear: "",
  maximumPower: "",
  isSecondHand: "",
  vehicleInspectionDate: "",
  insuranceExpiryDate: "",
  tenantKey: "",
})

function addShow(uList) {
  title.value = i18n.t("public.name.add")
  actionType.value = "add";
  _resetData();
  userList.value = uList
  visible.value = true;
}

async function editShow(obj, uList) {
  title.value = i18n.t("public.name.edit")
  _resetData();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  userList.value = uList
  visible.value = true;
}

function _resetData() {
  form.value = {};
}

function submit() {
  carForm.value?.validate().then(() => {
        let api;
        switch (actionType.value) {
          case "add":
            api = new CarAdd();
            break;
          case "edit":
            api = new CarEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then(response => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      }).catch(() => {});
}

defineExpose({
  addShow,
  editShow,
})
</script>

<style lang="less">
</style>
