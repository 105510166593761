const baseMixin = {
    methods: {
        //全局权限管理方法
        checkPermission(permission){
            if(this.$store.getters.getPermissionList === null || this.$store.getters.getPermissionList.length === 0){
                return true
            }
			return this.$store.getters.getPermissionList.some((value) =>{
                 return value === permission
            })
		},
        money(amount) {
            if (!amount) {
              return "0.00"
            }
            //强制保留两位小数
            let f = parseFloat(amount);
            if (isNaN(f)) return false;
            f = Math.round(amount * 100) / 100;
            let s = f.toString();
            let rs = s.indexOf('.');
            if (rs < 0) {
              rs = s.length;
              s += '.';
            }
            while (s.length < (rs + 1) + 2) {
              s += '0';
            }
            //每三位用一个逗号隔开
            let leftNum = s.split(".")[0];
            let rightNum = "." + s.split(".")[1];
            let result;
            //定义数组记录截取后的价格
            let resultArray = new Array();
            if (leftNum.length > 3) {
              let i = true;
              while (i) {
                resultArray.push(leftNum.slice(-3));
                leftNum = leftNum.slice(0, leftNum.length - 3);
                if (leftNum.length < 4) {
                  i = false;
                }
              }
              //由于从后向前截取，所以从最后一个开始遍历并存到一个新的数组，顺序调换
              let sortArray = new Array();
              for (let i = resultArray.length - 1; i >= 0; i--) {
                sortArray.push(resultArray[i]);
              }
              result = leftNum + "," + sortArray.join(",") + rightNum;
            } else {
              result = s;
            }
            return result;
          }
    }
}

export default baseMixin;