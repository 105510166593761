<template>
  <Modal
      id="TenantPackageEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" >
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around" :gutter="[18,18]">
                <Col span="10" v-if="showId">
                  <FormItem :label="$t('tenantPackage.table.id')" name="id">
                    <Input :placeholder="$t('tenantPackage.validate.id')"
                           v-model:value="form.id" disabled>
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.packageNo')" name="packageNo">
                    <Input
                        :placeholder="$t('tenantPackage.validate.packageNo')"
                        v-model:value="form.packageNo"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.name')" name="name">
                    <Input
                        :placeholder="$t('tenantPackage.validate.name')"
                        v-model:value="form.name"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.createTenantMax')" name="createTenantMax">
                    <InputNumber
                        style="width: 100%;"
                        :placeholder="$t('tenantPackage.validate.createTenantMax')"
                        v-model:value="form.createTenantMax"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.createUserMax')" name="createUserMax">
                    <InputNumber
                        style="width: 100%;"
                        :placeholder="$t('tenantPackage.validate.createUserMax')"
                        v-model:value="form.createUserMax"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.createAppMax')" name="createAppMax">
                    <InputNumber
                        style="width: 100%;"
                        :placeholder="$t('tenantPackage.validate.createAppMax')"
                        v-model:value="form.createAppMax"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('tenantPackage.table.expireLong')" name="expireLong">
                    <InputNumber
                        style="width: 100%;"
                        :placeholder="$t('tenantPackage.validate.expireLong')"
                        v-model:value="form.expireLong"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('public.name.status')" name="status">
                    <Select v-model:value="form.status" :placeholder="$t('public.pleaseSelect')"
                            :dropdownStyle="{zIndex: 9999999950}">
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('tenantPackage.table.tenantKey')" name="tenantKey">-->
<!--                    <Input-->
<!--                        :placeholder="$t('tenantPackage.validate.tenantKey')"-->
<!--                        v-model:value="form.tenantKey"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('tenantPackage.table.type')" name="type">-->
<!--                    <Input-->
<!--                        :placeholder="$t('tenantPackage.validate.type')"-->
<!--                        v-model:value="form.type"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('tenantPackage.table.status')" name="status">-->
<!--                    <Input-->
<!--                        :placeholder="$t('tenantPackage.validate.status')"-->
<!--                        v-model:value="form.status"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "TenantPackageEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {TenantPackageAdd, TenantPackageEdit} from "@/api/tenantPackage";
import {ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let title = ref("")
let visible = ref(false)
let actionType = ref("")
let showId = ref(false)
const refForm = ref()
const validateRules = reactive({
  packageNo: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.packageNo"),
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.name"),
      trigger: "change",
    },
  ],
  createTenantMax: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.createTenantMax"),
      trigger: "change",
      type: "number",
    },
  ],
  createUserMax: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.createTenantMax"),
      trigger: "change",
      type: "number",
    },
  ],
  createAppMax: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.createAppMax"),
      trigger: "change",
      type: "number",
    },
  ],
  expireLong: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.expireLong"),
      trigger: "change",
      type: "number",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("public.name.status"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("tenantPackage.validate.tenantKey"),
      trigger: "change",
    },
  ],
},)
const data = reactive({
  form: {},
})
const {form} = toRefs(data)

function addShow() {
  showId.value = false
  title.value = i18n.t("public.name.add")
  actionType.value = "add"
  _resetData()
  visible.value = true
}

async function editShow(obj) {
  showId.value = true
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  form.value = obj;
  visible.value = true;
}

function submit() {
  refForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new TenantPackageAdd();
        break;
      case "edit":
        api = new TenantPackageEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

defineExpose({
  addShow,
  editShow
});
</script>
