import {createStore} from 'vuex'


import createPersistedstate from 'vuex-persistedstate'

import state from "@/store/state"
import getters from "@/store/getters"
import actions from "@/store/actions"
import mutations from "@/store/mutations"

export default createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {},
    plugins: [
        //默认是存储在localStorage中
        createPersistedstate({
            // 存储方式 ：localStorage \ sessionStorage
            storage:localStorage,
            key: 'longStore', // // key: 存储数据的键名
        })
    ]
})
