<template>
  <Modal
      id="PlanEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="data.validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('plan.field.name')" name="name">
                    <Input :placeholder="$t('plan.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('plan.field.price_per_month') " name="pricePerMonth">
                    <InputNumber
                        style="width: 100%"
                        v-model:value="form.pricePerMonth"
                        :placeholder="$t('plan.validate.required.price_per_month') "
                        :step="0.01"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('plan.field.contract_period')+ '(month)'" name="contractPeriod">
                    <InputNumber :placeholder="$t('plan.validate.required.contract_period') + '(month)'" style="width: 100%"
                                 v-model:value="form.contractPeriod">
                    </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('plan.field.free_charging_time_limit') + '(hr)'" name="freeChargingTimeLimit">
                    <InputNumber
                        style="width: 100%"
                        v-model:value="form.freeChargingTimeLimit"
                        :placeholder="$t('plan.validate.required.free_charging_time_limit') + '(hr)'"
                        :step="0.01"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('plan.field.overtime_charge_price') + '(hr)'" name="overtimeChargePrice">
                    <InputNumber
                        style="width: 100%"
                        v-model:value="form.overtimeChargePrice"
                        :placeholder="$t('plan.validate.required.overtime_charge_price') + '(hr)'"
                        :step="0.01"
                    />
                  </FormItem>
                </Col>
                <Col span="10"></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "PlanEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
} from "ant-design-vue";
import {PlanAdd, PlanEdit} from "@/api/plan";
import {ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let title = ref("")
let visible = ref(false)
let actionType = ref("")
const refForm = ref()

const data = reactive({
  form: {
    idPla: "",
    name: "",
    pricePerMonth: "",
    contractPeriod: "",
    freeChargingTimeLimit: "",
    overtimeChargePrice: "",
    tenantKey: "",
  },
  fileList: {},
  selectList: {},
  validateRules: {
    idPla: [
      {
        required: true,
        message: i18n.t("plan.validate.required.id_pla"),
        trigger: "change",
        type: "number",
      },
    ],
    name: [
      {
        required: true,
        message: i18n.t("plan.validate.required.name"),
        trigger: "change",
      },
    ],
    pricePerMonth: [
      {
        required: true,
        message: i18n.t("plan.validate.required.price_per_month"),
        trigger: "change",
        type: "number",
      },
    ],
    contractPeriod: [
      {
        required: true,
        message: i18n.t("plan.validate.required.contract_period"),
        trigger: "change",
        type: "number",
      },
    ],
    freeChargingTimeLimit: [
      {
        required: true,
        message: i18n.t("plan.validate.required.free_charging_time_limit"),
        trigger: "change",
        type: "number",
      },
    ],
    overtimeChargePrice: [
      {
        required: true,
        message: i18n.t("plan.validate.required.overtime_charge_price"),
        trigger: "change",
        type: "number",
      },
    ],
    tenantKey: [
      {
        required: true,
        message: i18n.t("plan.validate.required.tenant_key"),
        trigger: "change",
      },
    ],
  },
})
const { form } = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  form.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;
}

function submit() {
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new PlanAdd();
        break;
      case "edit":
        api = new PlanEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response, any) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    });
  }).catch(() => {});
}

function _resetData(){
  form.value = {
    idPla: "",
    name: "",
    pricePerMonth: "",
    contractPeriod: "",
    freeChargingTimeLimit: "",
    overtimeChargePrice: "",
    tenantKey: "",
  };
}

defineExpose({
  addShow,
  editShow
});
</script>
