<template>
  <Modal id="UserPlanTimeLine" width="50%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('userPlan.name.detailTitle')" :zIndex="9999999900">
    <Timeline>
      <TimelineItem>2015/09/01 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/02 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/03 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/04 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/05 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/06 - Create a services site - 这里简单地备注</TimelineItem>
      <TimelineItem>2015/09/07 - Create a services site - 这里简单地备注</TimelineItem>
    </Timeline>
  </Modal>
</template>

<script>
export default {
  name: "UserPlanTimeLine"
}
</script>
<script setup>
import { Modal, Timeline, TimelineItem} from "ant-design-vue";
import {ref, reactive} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()

let visible = ref(false)
const selectList = reactive({})

function detailShow(idUsePla) {
  visible.value = true;
}

defineExpose({
  detailShow
});

</script>

<style lang="less">
</style>