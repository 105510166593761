<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Row :gutter="[16,32]">
            <Col :span="24" >
              <Form :model="searchForm">
                <Row :gutter="30">
                  <Col :span="6">
                    <FormItem :label="$t('meter.table.id_reg')" name="idReg">
                      <Select
                          v-model:value="searchForm.idReg"
                          show-search
                          :placeholder="$t('public.pleaseSelect')"
                          style="width: 100%"
                          :options="options"
                          :filter-option="filterOption"
                          @change="handleChange"
                          allowClear
                      ></Select>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('chargingStation.table.id_met')" name="idMet">
                      <Select
                          v-model:value="searchForm.idMet"
                          show-search
                          :placeholder="$t('public.pleaseSelect')"
                          style="width: 100%"
                          :options="metOptions"
                          :filter-option="filterOption"
                          allowClear
                      ></Select>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('statisticsManage.table.startTime')" name="startTime">
                      <!--                      <Input :placeholder="$t('statisticsManage.validate.startTime')" v-model:value="searchForm.startTime" allowClear></Input>-->
                      <DatePicker style="width: 100%" show-time :placeholder="$t('public.pleaseSelect')" v-model:value="searchForm.startTime" allowClear/>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('statisticsManage.table.endTime')" name="endTime">
                      <!--                      <Input :placeholder="$t('statisticsManage.validate.endTime')" v-model:value="searchForm.endTime" allowClear></Input>-->
                      <DatePicker style="width: 100%" show-time :placeholder="$t('public.pleaseSelect')" v-model:value="searchForm.endTime" allowClear/>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('statisticsManage.table.identifier')" name="identifier">
                      <Input :placeholder="$t('statisticsManage.validate.identifier')" v-model:value="searchForm.identifier" allowClear></Input>
                    </FormItem>
                  </Col>
                  <Col :span="6"></Col>
                </Row>
              </Form>
              <Row style="margin-bottom: 5px">
                <Col :span="8">
                  <Button type="primary" size="default" @click="exportToExcel()" :loading="exportButtonLoading">{{$t('public.name.Export')}}</Button>
                </Col>
                <Col :span="8" :offset="8" style="text-align: right;">
                  <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
                  <span>&nbsp;</span>
                  <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{ x: 1500}" rowKey="idCon" :loading="loading"
               :pagination="tablePagination" @change="chang_page">
        </Table>
      </div>
    </Col>
  </Row>
</template>
<script>
export default {
  name: "StatisticsManage"
}
</script>
<script setup>
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  DatePicker,
  Select, message
} from "ant-design-vue";
import {ChargingStationStatisticByChargingStation, ChargingStationStatisticByChargingStationToExcel} from "@/api/chargingStation";
import dayjs from 'dayjs';
import {onMounted, reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {RegionGetSelectList} from "@/api/region";
import {MeterGetSelectListByidReg} from "@/api/meter";

const store = useStore()
const i18n = useI18n()

// 导出按钮的加载状态
let exportButtonLoading = ref(false)
let loading = ref(false)
let list = ref([])
// 区域搜索框
let options = ref([])
// 电表搜索框
let metOptions = ref([])

const columns = [
  {
    title: i18n.t("public.name.index"),
    fixed: "center",
    width: 100,
    customRender: ({text, record, index, column}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  { title: i18n.t("meter.table.id_reg"), width: 180, ellipsis: true, dataIndex: "regName", key: "regName" },
  { title: i18n.t("chargingStation.table.id_met"), width: 180, ellipsis: true, dataIndex: "metName", key: "metName" },
  {
    title: i18n.t("statisticsManage.table.identifier"),
    width: 180,
    dataIndex: "identifier",
    key: "identifier"
  },
  {title: i18n.t("statisticsManage.table.useNum"),
    width: 180,
    dataIndex: "useNum",
    key: "useNum"
  },
  {
    title: i18n.t("statisticsManage.table.chargeCapacity"),
    width: 180,
    dataIndex: "chargeCapacity",
    key: "chargeCapacity"
  }
]

const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination
  get_list();
}

function get_list() {
  loading.value = true;

  // 获取电表列表
  getMeterList()
  // 获取区域列表
  getRegionSelectList()

  const api = new ChargingStationStatisticByChargingStation();
  // 格式化时间
  const getData = {
    ...searchForm.value,
    startTime: searchForm.value.startTime === undefined || searchForm.value.startTime === null ? undefined : dayjs(searchForm.value.startTime, ).format("YYYY-MM-DD HH:mm:ss"),
    endTime: searchForm.value.endTime === undefined || searchForm.value.endTime === null ? undefined : dayjs(searchForm.value.endTime).format("YYYY-MM-DD HH:mm:ss"),
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }

  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function exportToExcel() {
  exportButtonLoading.value = true;
  const api = new ChargingStationStatisticByChargingStationToExcel();
  // 格式化时间
  api.getDownLoad({
    idReg: searchForm.value.idReg,
    idMet: searchForm.value.idMet,
    identifier: searchForm.value.identifier,
    startTime: searchForm.value.startTime === undefined || searchForm.value.startTime === null ? undefined : dayjs(searchForm.value.startTime).format("YYYY-MM-DD HH:mm:ss"),
    endTime: searchForm.value.endTime === undefined || searchForm.value.endTime === null ? undefined : dayjs(searchForm.value.endTime).format("YYYY-MM-DD HH:mm:ss")
  }).then(() => {}).finally(()=>{
    exportButtonLoading.value = false;
  });
}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

// 获取所有区域 id-name 列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

function handleChange() {
  searchForm.value.idMet = undefined
  getMeterList()
}

function getMeterList() {
  const api = new MeterGetSelectListByidReg();
  api.get({idReg: searchForm.value.idReg}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      metOptions.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}


// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.ServiceManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
