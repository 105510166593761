<template>
  <Modal
      id="ConLogDetail"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="visible = !visible"
      :title="$t('public.name.detail')"
      :zIndex="9999999900">
    <div>
      <Row>
        <Col span="24">
          <Descriptions>
            <DescriptionsItem :label="$t('chargingStation.table.id_cha_poi')">{{ data.id }}</DescriptionsItem>
            <DescriptionsItem :label="$t('region.table.name')">{{ data.regName }}</DescriptionsItem>
            <DescriptionsItem :label="$t('meter.table.name')">{{ data.metName }}</DescriptionsItem>

            <DescriptionsItem :label="$t('chargingStation.table.charge_box_id')">{{ data.chargeBoxId }}</DescriptionsItem>
            <DescriptionsItem :label="$t('connector.table.connector_id')">{{ data.connectorId }}</DescriptionsItem>
            <DescriptionsItem :label="$t('connector.table.status')">
              <div>
                <Tag v-if="data.status === 8 || data.status === 10" color="#f50">
                  {{ statusList.find(item => item.value === data.status).label }}
                </Tag>
                <Tag v-else color="#87d068">{{ statusList.find(item => item.value === data.status).label }}</Tag>
              </div>
            </DescriptionsItem>

            <DescriptionsItem :label="$t('public.table.created_at')">{{ data.createTime }}</DescriptionsItem>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Descriptions>
            <DescriptionsItem :label="$t('eventLog.table.log')">{{ data.logInfo }}</DescriptionsItem>
          </Descriptions>
        </Col>
      </Row>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "ConLogDetail"
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem, Tag, Row, Col} from "ant-design-vue";
import {reactive, ref} from "vue";

let visible = ref(false)
let data = ref({})
const statusList = reactive(
    [
      {value: 1, label: 'Available'},
      {value: 2, label: 'Preparing'},
      {value: 3, label: 'Charging'},
      {value: 4, label: 'SuspendedEVSE'},
      {value: 5, label: 'SuspendedEV'},
      {value: 6, label: 'Finishing'},
      {value: 7, label: 'Reserved'},
      {value: 8, label: 'Unavailable'},
      {value: 9, label: 'Faulted'},
      {value: 10, label: 'Disconnect'}
    ]
)

function detailShow(obj) {
  data.value = obj
  visible.value = true
}

defineExpose({
  detailShow
});

</script>