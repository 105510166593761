import {Base} from "@/api/Base"

const api = "region"

export class RegionAdd extends Base {
    path = api + "/add"
}

export class RegionDelete extends Base {
    path = api + "/delete"
}

export class RegionList extends Base {
    path = api + "/list"
}

export class RegionEdit extends Base {
    path = api + "/edit"
}

export class RegionListAll extends Base {
    path = api + "/listAll"
}

export class RegionGetAllRegion extends Base {
    path = api + "/getAllRegion"
}

export class RegionGetAllRegionBackEnd extends Base {
    path = api + "/getAllRegionBackEnd"
}

// 获取树状列表
export class RegionGetTreeByRegionPage extends Base {
    path = api + "/getTreeByRegionPage"
}

// 获取下拉列表
export class RegionGetSelectList extends Base {
    path = api + "/getSelectList"
}

// 设置区域状态
export class RegionSetStatus extends Base {
    path = api + "/setStatus"
}
