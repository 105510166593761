<template>
  <Modal
      id="AuthorityEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="authorityForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('authority.field.a_id_aut')" name="aIdAut">
                    <!--关联表的下拉选择-->
                    <Select
                        v-model:value="form.aIdAut"
                        :placeholder="$t('authority.validate.required.a_id_aut')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption :key="sk" v-for="(item,sk) in selectList['authorityList']" :value="item['idAut']">
                        {{ item['name'] }}
                      </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('authority.field.name')" name="name">
                    <Input :placeholder="$t('authority.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('authority.field.resource_name')" name="resourceName">
                    <Input :placeholder="$t('authority.validate.required.resource_name')"
                           v-model:value="form.resourceName">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('authority.field.type')" name="type">
                    <Select
                        v-model:value="form.type"
                        :placeholder="$t('authority.validate.required.type')"
                        :options="selectList.typeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('authority.field.sort')" name="sort">
                    <InputNumber :placeholder="$t('authority.validate.required.sort')"
                                 style="width: 100%"
                                 v-model:value="form.sort">
                    </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "AuthorityEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {AuthorityAdd, AuthorityEdit} from "@/api/authority";
import {reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

const authorityForm = ref()

let visible = ref(false)
let title = ref("")
let actionType = ref("")

let selectList = reactive({
  authorityList: [],
  typeList: [{value: 1, label: '菜單'}, {value: 2, label: '按鈕'}],
})

const validateRules = {
  idAut: [
    {
      required: false,
      message: i18n.t("authority.validate.required.id_aut"),
      trigger: "change",
      type: "number",
    },
  ],
  aIdAut: [
    {
      required: false,
      message: i18n.t("authority.validate.required.a_id_aut"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("authority.validate.required.name"),
      trigger: "change",
    },
  ],
  resourceName: [
    {
      required: true,
      message: i18n.t("authority.validate.required.resource_name"),
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: i18n.t("authority.validate.required.type"),
      trigger: "change",
      type: "number",
    },
  ],
  sort: [
    {
      required: true,
      message: i18n.t("authority.validate.required.sort"),
      trigger: "change",
      type: "number",
    },
  ],
}

const data = reactive({
  form: {
    idAut: "",
    aIdAut: "",
    name: "",
    resourceName: "",
    type: "",
    sort: "",
  },
  fileList: {},
})
const {form} = toRefs(data)

function addShow(authorityList) {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  selectList.authorityList = authorityList;
  visible.value = true;
}

async function editShow(obj, authorityList) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  selectList.authorityList = authorityList;
  form.value = obj;
  visible.value = true;
}

function submit() {
  authorityForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new AuthorityAdd();
        break;
      case "edit":
        api = new AuthorityEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response, any) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {
  });
}

function _resetData() {
  form.value = {};
}

defineExpose({
  addShow,
  editShow
})

</script>