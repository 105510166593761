const it = {
    'succeedMsg': {'0': 'Avere successo'},
    'errorMsg': {
        '500100': 'Eccezione lato server',
        '500101': 'Errore dei parametri',
        '500102': 'Eccezione di convalida dei parametri',
        '500103': 'La richiesta è illegale',
        '500104': 'Le visite sono troppo frequenti',
        '500105': "L'oggetto esiste già",
        '500106': 'L\'operazione non è riuscita, "Riprova',
        '500107': 'C\'è un\'associazione, "La cancellazione è vietata',
        '500108': 'Eccezioni di esportazione',
        '500200': 'Accesso scaduto, "Accedi di nuovo',
        '500201': 'Il numero di conto o la password non possono essere vuoti',
        '500202': 'Il numero di telefono non può essere vuoto',
        '500203': 'Il numero di telefono è nel formato sbagliato',
        '500204': 'Il numero di telefono non esiste',
        '500205': 'La password non è corretta',
        '500206': "L'account non esiste",
        '500207': 'I numeri di account possono contenere solo numeri, "inglese" e sottolineate',
        '500208': 'Autenticazione fallita',
        '500209': 'Le due password non sono le stesse',
        '500210': 'Account duplicati',
        '500211': "L'account è stato bloccato",
        '500212': 'Captcha fallito',
        '500213': 'Il numero di telefono è sbagliato',
        '500214': 'Non esiste un inquilino corrispondente al nome di dominio "Contatta l\'amministratore',
        '500215': 'esiste lo stesso nome di ruolo',
        '500300': 'Se il servizio OCCP è anormale ", contattare l\'amministratore',
        '500301': 'Le pile elettriche non sono disponibili',
        '500302': 'La configurazione della pila è vuota',
        '500303': 'La batteria è offline, "Verifica se è disponibile',
        '500304': 'La porta della pila non è disponibile',
        '500305': 'La pistola non è inserita o la pistola attuale non è pronta',
        '500306': 'Inizia a caricare il fallimento',
        '500307': 'Guasto di ricarica in bundle',
        '500401': 'Equilibrio insufficiente',
        '500402': "L'ordine non esiste",
        '500403': "L'ordine non è stato pagato",
        '500404': "L'ordine è stato pagato",
        '500405': "Lo stato dell'ordine non è [pagato inutilizzato]",
        '500501': "L'oggetto è stato venduto in pochi secondi",
        '500502': 'Gli accordi di fulmini non possono essere ripetuti',
        '500503': 'Secondo fallimento di uccisione',
        '500601': 'Il file non può essere vuoto',
        '500602': 'Errore in formato file',
        '500603': 'La cartella di lavoro di Excel è vuota',
        '500604': 'Impossibile leggere il file'
    },
    'public': {
        'name': {
            'nextStep': 'Passo successivo',
            'status': 'Stato',
            'hello': 'Ciao',
            'login': 'Login',
            'logout': 'Disconnettersi',
            'username': 'Nome utente',
            'email': 'E-mail',
            'password': "Parola d'ordine",
            'remember': 'Ricordati di me',
            'currentPassword': 'password attuale',
            'newPassword': 'nuova password',
            'confirm_password': 'Conferma password',
            'reset_password': 'Resetta la password',
            'captcha': 'Captcha',
            'getCaptcha': 'Prendi il captcha',
            'more': 'Di più',
            'add': 'Aggiungere',
            'batchDel': 'Elimina batch',
            'Export': 'Esportare',
            'Import': 'Importare',
            'DownloadTemp': 'Scarica modello',
            'ImportTips': "Fare clic o trascinare il file in quest'area per caricare",
            'edit': 'Modificare',
            'del': 'Eliminare',
            'detail': 'Dettaglio',
            'yes': 'SÌ',
            'no': 'NO',
            'empty': 'Empty',
            'are_you_sure_delete': 'Sei sicuro che desideri eliminare questo articolo',
            'action_cannot_resumed': 'Questa azione non può essere ripresa',
            'are_you_sure_operate': "Sei sicuro dell'attuale operazione",
            'operate_remind': 'L\'operazione corrente è sensibile, "Si prega di essere cauto',
            'search': 'Ricerca',
            'reset': 'Ripristina',
            'spread': 'Diffusione',
            'retract': 'Ritrattare',
            'show': 'Spettacolo',
            'no_page': 'Nessuna pagina',
            'service': 'Servizio',
            'phone_country_code': 'Prefisso telefonico del Paese',
            'name': 'Nome',
            'phone': 'Telefono',
            'sex': 'Sesso',
            'country': 'Paese',
            'address': 'Indirizzo',
            'birthday': 'Compleanno',
            'remark': 'Osservazione',
            'personal': 'Personale',
            'enclosure': 'Allegato',
            'logo': 'Logo',
            'submit': 'Invia',
            'unknown': 'Unknown',
            'setting': 'Collocamento',
            'index': 'Indice',
            'createBy': 'Creato da',
            'createTime': 'Creare tempo',
            'updateBy': 'Aggiornamento da',
            'updateTime': 'Tempo di aggiornamento',
            'type': 'Type',
            'args': 'Parametro',
            'qrcode': 'QR Code',
            'query': 'Domanda',
            'refund': 'Rimborso',
            'cancel': 'Annulla',
            'revoke': 'Revocare'
        },
        'msg': {
            'unRead': 'Non letto',
            'read': 'Leggere',
            'alarm': 'Allarme',
            'reminder': 'Promemoria',
            'advertise': 'Pubblicizzare'
        },
        'language': {
            'zhTW': 'Cinese tradizionale)',
            'enUS': 'Inglese',
            'zhCN': 'Cinese semplificato)',
            'daDK': 'danese',
            'idID': 'Indonesian',
            'msMY': 'malese',
            'thTH': 'tailandese',
            'viVN': 'vietnamita',
            'frFR': 'francese',
            'plPL': 'Polish',
            'it': 'Italiano'
        },
        'timeType': {'0': 'Anno', '1': 'Mese', '2': 'Settimana', '3': 'Giorno'},
        'promptsToSelectTime': 'Seleziona un tempo',
        'pleaseSelect': 'Seleziona',
        'pileStatus': {'0': 'Disponibile', '1': 'Carica', '2': 'Non disponibile'},
        'isTrue': {'0': 'NO', '1': 'SÌ'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E-mail', '1': 'Telefono'},
        'doingStatus': {
            '0': 'In attesa di',
            '1': 'in lavorazione',
            '2': 'Completato',
            '3': 'Annulla',
            '4': 'Expired',
            '5': 'Terminazione'
        },
        'consumeType': {
            '0': 'Ricaricare',
            '1': 'Consumo di ricarica',
            '2': 'Cancellazione di ricarica',
            '3': 'Bilancia',
            '4': 'Piano di acquisto',
            '5': 'Annulla piano',
            '6': 'Piano di rinnovo',
            '7': 'Rimborso',
            '8': 'Biglietto',
            '9': 'Annulla le multe',
            '10': 'Ritiro',
            '11': 'Regalo completo',
            '12': 'Revocare'
        },
        'payStatus': {'0': 'Non pagato', '1': 'Paid', '2': 'Carica', '3': 'Completato', '4': 'Annulla'},
        'chargeType': {'0': 'Col tempo', '1': 'Per ordine', '2': 'Dal piano utente'},
        'status': {'0': 'Unknown', '1': 'Normale', '2': 'disattivare', '3': 'Eliminare'},
        'sex': {'1': 'Uomo', '2': 'Donna', '3': 'Unknown'},
        'placeholder': {'please_select': 'Seleziona'},
        'table': {
            'name': 'Nome',
            'status': 'Stato',
            'action': 'Azione',
            'content': 'Contenuto',
            'created_at': 'Creare tempo',
            'image': 'Immagine',
            'email': 'E-mail',
            'order_id': 'Order',
            'sex': 'Sesso',
            'last_ip': 'Last Ip',
            'phone_country_code': 'Seleziona il codice del paese del telefono',
            'phone': 'Telefono'
        },
        'field': {
            'name': 'Nome',
            'username': 'Nome utente',
            'content': 'Contenuto',
            'phone_country_code': 'Prefisso telefonico del Paese',
            'phone': 'Telefono',
            'email': 'E-mail',
            'order_id': 'Ordine',
            'portrait': 'Ritratto',
            'status': 'Stato',
            'image': 'Immagine',
            'sex': 'Sesso'
        },
        'validate': {
            'required': {
                'id': 'Manca id',
                'username': 'Si prega di ingresso nome utente',
                'password': 'Si prega di ingresso password',
                'repassword': 'Please Repeat password',
                'name': 'Si prega di ingresso',
                'phone': 'Si prega di entrare nel telefono',
                'email': 'Si prega di ingresso e-mail',
                'portrait': 'Carica il ritratto'
            },
            'tips': {
                'inconsistent_password': 'Password incoerente',
                'length_should': 'La lunghezza dovrebbe essere da {min} a {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Cinese tradizionale)',
        'enUS': 'Inglese',
        'zhCN': 'Cinese semplificato)',
        'daDK': 'danese',
        'idID': 'Indonesian',
        'msMY': 'malese',
        'thTH': 'tailandese',
        'viVN': 'vietnamita',
        'frFR': 'francese',
        'it': 'Italiano'
    },
    'nav': {
        'name': {
            'Dashboard': 'Pannello di controllo',
            'RegionDashboard': 'Panoramica delle pile',
            'BigScreen': 'Grande schermo',
            'AuthorityManage': 'Autorità Gestione',
            'BalanceLogManage': 'Bilancia Logmanage',
            'CarManage': 'Gestione auto',
            'CarImagesManage': 'Manage delle immagini delle auto',
            'ChargeStationImagesManage': 'Punto di carica ImageManage',
            'ChargingStationManage': 'Punta di carica',
            'ConnectorManage': 'Connector Gestione',
            'EmployeeManage': "Gestione dell'operatore",
            'EventLogManage': 'Logmanage di eventi',
            'ConnectorLogManage': 'Registro del connettore',
            'MeterManage': 'Gestisci metro',
            'OrderManage': 'Ordine Gestione',
            'PlanManage': 'Piano Gestire',
            'RegionManage': 'Regione gestire',
            'RegionImagesManage': 'Gestione delle immagini della regione',
            'RepairLogManage': 'Ripara il logmanage',
            'RoleManage': 'Ruolo Gestire',
            'TransactionLogManage': 'Logmanage delle transazioni',
            'UserManage': 'Gestione utente',
            'UserPlanManage': 'Pianifica utente',
            'GetConfiguration': 'Ottieni configurazione',
            'DataTransfer': 'Trasferimento dati',
            'TenantManagement': 'Gestione del sistema',
            'SuperTenantManage': 'Super inquilino',
            'TenantManage': 'Gestire inquilino',
            'TenantInfo': 'Gestire inquilino',
            'TenantPackage': 'Pacchetto di inquilini',
            'NoticeManage': 'Avviso Gestisci',
            'PublicityManage': 'Pubblicità gestire',
            'ChargerSetting': 'Impostazione del caricabatterie',
            'StatisticsManage': 'Statistiche',
            'ServiceManage': 'Gestione del servizio',
            'CardManage': 'Gestione della carta',
            'AppManagement': 'Gestione delle app',
            'LogManagement': 'Gestione dei registri',
            'AppVersionManage': "Versione dell'app Gestione",
            'WallerManage': 'Limite di conto',
            'iPadManagement': 'Gestione iPad',
            'iPadManage': 'Gestione iPad'
        },
        'table': {'order_id': 'ID ordine', 'url': 'URL'},
        'field': {
            'pid': 'Pid',
            'order_id': 'ID ordine',
            'name': 'Nome',
            'url': 'URL',
            'status': 'Stato',
            'icon': 'Icona'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Si prega di ingresso nome utente',
                'email': 'Si prega di entrare in email',
                'password': 'Si prega di ingresso password',
                'captcha': 'Si prega di ingresso captcha'
            }
        }
    },
    'authority': {
        'name': {
            'title': 'Autorità',
            'detailTitle': "Dettaglio dell'autorità",
            'editTitle': 'Modifica autorità'
        },
        'table': {
            'id_aut': 'ID',
            'a_id_aut': 'Autorità dei genitori',
            'name': 'Autorità',
            'resource_name': 'Nome delle risorse',
            'type': 'Type',
            'sort': 'Ordinare'
        },
        'field': {
            'id_aut': 'ID',
            'a_id_aut': 'Autorità dei genitori',
            'name': 'Autorità',
            'resource_name': 'Nome delle risorse',
            'type': 'Type',
            'sort': 'Ordinare'
        },
        'validate': {
            'required': {
                'id_aut': 'ID richiesto',
                'a_id_aut': 'Autorità genitore richiesta',
                'name': 'Autorità richiesta',
                'resource_name': 'Nome delle risorse richiesto',
                'type': 'Tipo richiesto',
                'sort': 'Ordina richiesto'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Pacchetto di ricarica',
        'addRule': 'Aggiungi regola',
        'gift': {
            'enable': 'È abilitato',
            'percentage': 'Per percentuale',
            'star': 'Valore iniziale',
            'end': 'Valore finale',
            'give': 'Limite'
        },
        'vGift': {
            'enable': 'Si prega di scegliere se abilitarlo o no',
            'percentage': 'Si prega di scegliere se utilizzare la percentuale o meno',
            'star': 'Inserisci il valore iniziale',
            'end': 'Inserisci il valore finale',
            'give': 'Si prega di inserire il limite di credito'
        },
        'name': {
            'title': 'Log di bilanciamento',
            'detailTitle': 'Dettagli del registro di bilanciamento',
            'editTitle': 'Modifica il registro del saldo',
            'ticket': 'Biglietto',
            'cancelFines': 'Annulla le multe'
        },
        'table': {
            'id_bal_log': 'ID',
            'id_use': 'Utilizzo',
            'type': 'Type',
            'amount': 'Quantità',
            'log_time': 'Tempo di registro',
            'tenant_key': 'Chiave degli inquilini',
            'status': 'Stato',
            'payment_method': 'Metodo di pagamento'
        },
        'field': {
            'id_bal_log': 'ID',
            'id_use': 'Uer',
            'type': 'Type',
            'amount': 'Quantità',
            'log_time': 'Tempo di registro',
            'tenant_key': 'Chiave degli inquilini',
            'status': 'Stato',
            'payment_method': 'Metodo di pagamento'
        },
        'validate': {
            'required': {
                'id_bal_log': 'ID richiesto',
                'id_use': 'Uer richiesto',
                'type': 'Tipo richiesto',
                'amount': 'Importo richiesto',
                'log_time': 'Tempo di registro richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta',
                'status': 'Stato richiesto',
                'payment_method': 'Metodo di pagamento richiesto'
            }
        }
    },
    'car': {
        'name': {'title': 'Auto', 'detailTitle': "Dettaglio dell'auto", 'editTitle': 'Modifica auto'},
        'table': {
            'id_car': 'ID',
            'id_use': 'Uer',
            'model': 'Model',
            'car_license': 'Licenza',
            'max_years_old': 'Max anno',
            'manufacture_year': 'Anno di produzione',
            'maximum_power': 'Massima potenza',
            'is_second_hand': 'È di seconda mano',
            'vehicle_inspection_date': 'Data di ispezione del veicolo',
            'insurance_expiry_date': 'Data di scadenza assicurativa',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_car': 'ID',
            'id_use': 'Uer',
            'model': 'Model',
            'car_license': 'Licenza',
            'max_years_old': 'Max anno',
            'manufacture_year': 'Anno di produzione',
            'maximum_power': 'Massima potenza',
            'is_second_hand': 'È di seconda mano',
            'vehicle_inspection_date': 'Data di ispezione del veicolo',
            'insurance_expiry_date': 'Data di scadenza assicurativa',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_car': 'ID richiesto',
                'id_use': 'Uer richiesto',
                'model': 'Modello richiesto',
                'car_license': 'Licenza richiesta',
                'max_years_old': 'Richiesto anno massimo',
                'manufacture_year': 'Richiesto anno di produzione',
                'maximum_power': 'Potenza massima richiesta',
                'is_second_hand': 'È richiesto di seconda mano',
                'vehicle_inspection_date': 'Data di ispezione del veicolo richiesto',
                'insurance_expiry_date': "Data di scadenza dell'assicurazione richiesta",
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'carImages': {
        'name': {
            'title': "Immagini dell'auto",
            'detailTitle': 'Dettagli delle immagini delle auto',
            'editTitle': 'Modifica immagini di auto'
        },
        'table': {
            'id_car_ima': 'ID',
            'id_car': 'Auto',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_car_ima': 'ID',
            'id_car': 'Auto',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_car_ima': 'ID richiesto',
                'id_car': 'Auto richiesta',
                'url': 'URL richiesto',
                'upload_time': 'Carica il tempo richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Immagine del punto di carica',
            'detailTitle': "Dettaglio dell'immagine del punto di carica",
            'editTitle': "Modifica l'immagine del punto di carica"
        },
        'table': {
            'id_ima': 'ID',
            'id_cha_poi': 'Punto di carica',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_ima': 'ID',
            'id_cha_poi': 'Punto di carica',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_ima': 'ID richiesto',
                'id_cha_poi': 'Punto di carica richiesto',
                'url': 'URL richiesto',
                'upload_time': 'Carica il tempo richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Imposta la sequenza di pagamento dell'ordine di addebito predefinito alle impostazioni regionali",
            'billingMethod': "Imposta il metodo di fatturazione dell'ordine di ricarica predefinito su set per regione"
        },
        'billingMethodType': {
            '0': 'Col tempo',
            '1': 'Per potere',
            '2': 'Dal piano utente',
            '3': 'Da app',
            '4': 'Per regione'
        },
        'updateFirmware': {
            'identifier': 'Numero di serie',
            'location': 'Indirizzo remoto',
            'retrieveDate': 'Data di recupero'
        },
        'uValidate': {
            'identifier': 'Per favore inserisci il numero di serie',
            'location': 'Inserisci un indirizzo remoto',
            'retrieveDate': 'Seleziona una data di ricerca'
        },
        'setting': {
            'index': 'numero di serie',
            'readonly': 'Sola lettura',
            'key': 'Imposta la chiave',
            'value': 'Imposta il valore'
        },
        'settingField': {
            'readonly': 'Seleziona se è di sola lettura',
            'key': 'Il tasto set non può essere vuoto',
            'value': 'Il valore di impostazione non può essere vuoto'
        },
        'name': {
            'title': 'Punto di carica',
            'detailTitle': 'Dettaglio del punto di carica',
            'editTitle': 'Modifica il punto di carica',
            'quickAdd': 'Aggiungi il punto di carica'
        },
        'table': {
            'id_cha_poi': 'ID',
            'id_met': 'Metro',
            'charge_box_id': 'ID casella di carica',
            'firmwareVersion': 'Versione del firmware',
            'last_heartbeat_time': 'Ultimo tempo cardiaco',
            'monthly_fee': 'Canone mensile',
            'charger_type': 'Tipo di caricabatterie',
            'tenant_key': 'Chiave degli inquilini',
            'name': 'Nome',
            'heartbeat_interval': 'Intervallo del battito cardiaco',
            'enable': 'Abilitare',
            'maxCurrent': 'Corrente massima',
            'minCurrent': 'Corrente minima',
            'isPrivate': 'È privato',
            'isReadMeter': 'Se leggere la tabella',
            'isReset': 'Se riavviare (il riavvio può avere effetto)',
            'action': {
                'updateFirmware': 'Aggiornamento del firmware',
                'hardReset': 'Hard reset',
                'softReset': 'Ripristino soft'
            }
        },
        'validate': {
            'id_cha_poi': 'ID richiesto',
            'id_met': 'Contatore richiesto',
            'charge_box_id': 'Casella di carica ID richiesto',
            'last_heartbeat_time': 'Ultimo tempo cardiaco richiesto',
            'monthly_fee': 'Commissione mensile richiesta',
            'charger_type': 'Tipo di caricabatterie richiesto',
            'tenant_key': 'Chiave degli inquilini richiesta',
            'name': 'Nome (obbligatorio',
            'heartbeat_interval': "L'intervallo del battito cardiaco richiesto",
            'enable': 'Abilita richiesta',
            'maxCurrent': 'Si prega di compilare la corrente massima',
            'minCurrent': 'Si prega di compilare la corrente minima'
        }
    },
    'connector': {
        'name': {
            'title': 'Connettore',
            'detailTitle': 'Dettaglio del connettore',
            'editTitle': 'Modifica connettore',
            'startTransaction': 'Avviare la transazione',
            'stopTransaction': 'Stop Transaction'
        },
        'table': {
            'id_con': 'ID',
            'id_cha_poi': 'Punto di carica',
            'name': 'Nome',
            'connector_id': 'ID connettore',
            'status': 'Stato',
            'tenant_key': 'Chiave degli inquilini',
            'power': 'Potenza (KW)',
            'type': 'Type'
        },
        'field': {
            'id_con': 'ID',
            'id_cha_poi': 'Punto di carica',
            'name': 'Nome',
            'connector_id': 'ID connettore',
            'status': 'Stato',
            'tenant_key': 'Chiave degli inquilini',
            'power': 'Potenza (KW)',
            'type': 'Type'
        },
        'validate': {
            'required': {
                'id_con': 'ID richiesto',
                'id_cha_poi': 'Punto di carica richiesto',
                'name': 'Nome (obbligatorio',
                'connector_id': 'ID connettore richiesto',
                'status': 'Stato richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta',
                'power': 'Potenza richiesta (KW)',
                'type': 'Tipo richiesto'
            }
        }
    },
    'employee': {
        'name': {'title': 'Operator', 'detailTitle': "Dettaglio dell'operatore", 'editTitle': 'Modifica operatore'},
        'table': {
            'id_emp': 'ID',
            'id_rol': 'Ruolo',
            'account': 'Account',
            'password': "Parola d'ordine",
            'salt': 'Sale',
            'last_login_time': 'Ultimo orario di accesso',
            'register_time': 'Tempo di registrazione',
            'login_failure_counter': 'Contatore di errore di accesso',
            'email': 'E-mail',
            'whatsAppPhone': 'Numero whatsapp',
            'remark': 'Osservazione',
            'status': 'Stato',
            'name': 'Nome',
            'commission_rate': 'Tasso di commissione',
            'yedpay_api_key': 'Chiave API Yedpay',
            'yedpay_sign_key': 'Chiave di segno Yedpay',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_emp': 'ID',
            'id_rol': 'Ruolo',
            'account': 'Account',
            'password': "Parola d'ordine",
            'salt': 'Sale',
            'last_login_time': 'Ultimo orario di accesso',
            'register_time': 'Tempo di registrazione',
            'login_failure_counter': 'Contatore di errore di accesso',
            'email': 'E-mail',
            'whatsAppPhone': 'Numero whatsapp',
            'remark': 'Osservazione',
            'status': 'Stato',
            'name': 'Nome',
            'commission_rate': 'Tasso di commissione',
            'yedpay_api_key': 'Chiave API Yedpay',
            'yedpay_sign_key': 'Chiave di segno Yedpay',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_emp': 'ID richiesto',
                'id_rol': 'Ruolo richiesto',
                'account': 'Account richiesto',
                'password': 'Password richiesta',
                'salt': 'Sale richiesto',
                'last_login_time': 'Ultimo orario di accesso richiesto',
                'register_time': 'Tempo di registrazione richiesto',
                'login_failure_counter': 'Contatore di errore di accesso richiesto',
                'email': 'Email (obbligatorio',
                'whatsAppPhone': 'Per favore compila il numero di whatsapp',
                'remark': 'Nota richiesta',
                'status': 'Stato richiesto',
                'name': 'Nome (obbligatorio',
                'commission_rate': 'Valuta di commissione richiesta',
                'yedpay_api_key': 'Chiave API Yedpay richiesta',
                'yedpay_sign_key': 'Chiave di segno Yedpay richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Registro eventi',
            'detailTitle': 'Dettaglio del registro degli eventi',
            'editTitle': 'Modifica registro degli eventi'
        },
        'table': {
            'id_env_log': 'ID',
            'run_time': 'Tempo di esecuzione (MS)',
            'class_method': 'Metodo di esecuzione',
            'event_time': "Orario dell'evento",
            'log': "Tronco d'albero",
            'tenant_key': 'Chiave degli inquilini',
            'resCode': 'Codice di risposta'
        },
        'field': {
            'id_env_log': 'ID',
            'event_time': "Orario dell'evento",
            'log': "Tronco d'albero",
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_env_log': 'ID richiesto',
                'class_method': 'Si prega di compilare il metodo di esecuzione',
                'event_time': "Tempo dell'evento richiesto",
                'log': 'Registro richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'meter': {
        'name': {'title': 'Metro', 'detailTitle': 'Dettaglio del contatore', 'editTitle': 'Modifica metro'},
        'table': {
            'id_met': 'ID',
            'id_reg': 'Regione',
            'name': 'Nome contatore',
            'current_threshold': 'Soglia attuale',
            'tenant_key': 'Chiave degli inquilini',
            'loadByTactics': 'Accendi le politiche'
        },
        'field': {
            'id_met': 'ID',
            'id_reg': 'Regione',
            'name': 'Nome contatore',
            'current_threshold': 'Soglia attuale',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_met': 'ID richiesto',
                'id_reg': 'Regione richiesta',
                'name': 'Nome del misuratore richiesto',
                'current_threshold': 'Soglia corrente richiesta',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Ordine',
            'detailTitle': 'Dettagli ordine',
            'editTitle': 'Modifica ordine',
            'cancel': "Cancellazione dell'ordine",
            'cancelAndRefund': "Annulla l'ordine e il rimborso"
        },
        'table': {
            'id_ord': 'ID',
            'id_car': 'Auto',
            'id_con': 'Connettore',
            'order_time': "Tempo dell'ordine",
            'type': 'Type',
            'amount': 'Quantità',
            'charge_start_time': 'Tempo di inizio addebito',
            'charge_end_time': 'Tempo di fine previsto',
            'chargeEndTimeActual': 'Tempo di fine effettivo',
            'status': 'Stato',
            'payment_time': 'Tempo di pagamento',
            'electricity': 'Acquista elettricità',
            'charge_minutes': "Durata dell'acquisto",
            'currentElectricity': 'Elettricità corrente',
            'ref_id_pla': 'Piano',
            'price': 'Prezzo',
            'commission_rate': 'Tasso di commissione',
            'tenant_key': 'Chiave degli inquilini',
            'id_tra_log': 'Transazionilogid',
            'pay_type': 'PayType',
            'payment_method': 'Metodo di pagamento'
        },
        'validate': {
            'id_ord': 'ID richiesto',
            'id_car': 'Auto richiesta',
            'id_con': 'Connettore richiesto',
            'order_time': "Tempo d'ordine richiesto",
            'type': 'Tipo richiesto',
            'amount': 'Importo richiesto',
            'charge_start_time': 'Tempo di inizio di addebito richiesto',
            'charge_end_time': 'Tempo di fine addebito richiesto',
            'status': 'Stato richiesto',
            'payment_time': 'Tempo di pagamento richiesto',
            'electricity': 'Elettricità richiesta',
            'ref_id_pla': 'Piano richiesto',
            'price': 'Prezzo richiesto',
            'commission_rate': 'Valuta di commissione richiesta',
            'tenant_key': 'Chiave degli inquilini richiesta',
            'id_tra_log': 'TransactionLogId richiesto',
            'charge_minutes': 'CHARGEMINUTI RICHIESTI',
            'pay_type': 'PayType richiesto',
            'payment_method': 'Metodo di pagamento richiesto'
        }
    },
    'plan': {
        'name': {'title': 'Piano', 'detailTitle': 'Dettaglio del piano', 'editTitle': 'Modifica piano'},
        'table': {
            'id_pla': 'ID',
            'name': 'Nome del piano',
            'price_per_month': 'Prezzo',
            'contract_period': 'Periodo di contratto',
            'free_charging_time_limit': 'Tempo di ricarica gratuito',
            'overtime_charge_price': 'Prezzo degli straordinari',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_pla': 'ID',
            'name': 'Nome del piano',
            'price_per_month': 'Prezzo',
            'contract_period': 'Periodo di contratto',
            'free_charging_time_limit': 'Tempo di ricarica gratuito',
            'overtime_charge_price': 'Prezzo degli straordinari',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_pla': 'ID richiesto',
                'name': 'Nome del piano richiesto',
                'price_per_month': 'Prezzo richiesto',
                'contract_period': 'Periodo di contratto richiesto',
                'free_charging_time_limit': 'Tempo di ricarica gratuito richiesto',
                'overtime_charge_price': 'Prezzo straordinario richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "La dimensione del passo per ogni salto se meno di un salto verrà calcolata come un salto.Se calcolato in base al consumo effettivo dell'elettricità dell'ordine, impostalo su zero",
            'byTimeUnit': "La dimensione del passo per ogni salto se meno di un salto verrà calcolata come un salto.Se calcolato in base al tempo effettivo dell'ordine, impostalo su zero",
            'maximumPower': 'Quando si carica in base al livello della batteria, il limite superiore di una singola carica non è limitato se lasciato vuoto o zero e non è limitato per impostazione predefinita',
            'maximumMinute': 'Quando si carica in base al tempo, il limite superiore di un singolo tempo di ricarica viene lasciato vuoto o zero che non è limitato per impostazione predefinita'
        },
        'billingMethodType': {'0': 'Col tempo', '1': 'Per potere', '2': 'Da app'},
        'name': {'title': 'Regione', 'detailTitle': 'Dettaglio della regione', 'editTitle': 'Modifica regione'},
        'table': {
            'id_reg': 'ID',
            'name': 'Nome regione',
            'ac_price_per_electricity': 'Prezzo CA per elettricità',
            'ac_price_per_minute': 'Prezzo CA al minuto',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Indirizzo',
            'dc_price_per_electricity': 'Prezzo DC per elettricità',
            'dc_price_per_minute': 'Prezzo DC al minuto',
            'tenant_key': 'Chiave degli inquilini',
            'businessAllDay': 'Aperto tutto il giorno',
            'images': 'immagini',
            'byPowerUnit': 'Mediante unità di potenza',
            'byTimeUnit': "Dall'unità temporale",
            'postpaid': 'Postpagato',
            'billingMethod': 'Metodo di fatturazione',
            'businessHours': 'Ore di lavoro',
            'maximumPower': 'Limite superiore della capacità di ricarica singola',
            'maximumMinute': 'Limite superiore del tempo di ricarica singolo',
            'directDebit': 'debito diretto',
            'enableTimeoutFine': 'Penalità di timeout di apertura',
            'timeoutFine': 'Timeout Time (min)',
            'timeoutCharging': 'Fatturazione straordinaria'
        },
        'validate': {
            'id_reg': 'ID richiesto',
            'name': 'Nome regione richiesto',
            'ac_price_per_electricity': 'Prezzo CA per elettricità richiesta',
            'ac_price_per_minute': 'Prezzo CA al minuto richiesto',
            'lon': 'Lon richiesto',
            'lat': 'Lat richiesto',
            'address': 'Indirizzo richiesto',
            'dc_price_per_electricity': 'Prezzo DC per elettricità richiesta',
            'dc_price_per_minute': 'Prezzo DC al minuto richiesto',
            'tenant_key': 'Chiave degli inquilini richiesta',
            'businessAllDay': 'Seleziona se è aperto tutto il giorno',
            'byPowerUnit': "Si prega di compilare l'unità di misurazione in base alla quantità di elettricità",
            'byTimeUnit': 'Si prega di compilare unità di misurazione per tempo',
            'postpaid': 'Si prega di scegliere se pagare dopo',
            'billingMethod': 'Seleziona un metodo di fatturazione',
            'businessHours': 'Seleziona ore di lavoro',
            'maximumPower': 'Si prega di compilare il limite superiore della capacità di ricarica singola',
            'maximumMinute': 'Si prega di compilare il limite superiore del tempo di ricarica singolo',
            'directDebit': 'Si prega di scegliere se detrarre automaticamente il pagamento',
            'enableTimeoutFine': 'Seleziona se abilitare la penalità di timeout',
            'timeoutFine': 'Si prega di compilare il periodo di timeout (min)',
            'timeoutCharging': 'Si prega di compilare la fatturazione degli straordinari'
        }
    },
    'regionImages': {
        'name': {
            'title': 'Immagine della regione',
            'detailTitle': "Dettaglio dell'immagine della regione",
            'editTitle': 'Modifica immagine della regione'
        },
        'table': {
            'id_reg_img': 'ID',
            'id_reg': 'Regione',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_reg_img': 'ID',
            'id_reg': 'Regione',
            'url': 'URL',
            'upload_time': 'Caricare tempo',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_reg_img': 'ID richiesto',
                'id_reg': 'Regione richiesta',
                'url': 'URL richiesto',
                'upload_time': 'Carica il tempo richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Registro di riparazione',
            'detailTitle': 'Dettagli del registro di riparazione',
            'editTitle': 'Modifica registro di riparazione'
        },
        'table': {
            'id_rep_log': 'ID',
            'id_car': 'Auto',
            'amount': 'Quantità',
            'repair_date': 'Data di riparazione',
            'remark': 'Osservazione',
            'tenant_key': 'Chiave degli inquilini'
        },
        'field': {
            'id_rep_log': 'ID',
            'id_car': 'Auto',
            'amount': 'Quantità',
            'repair_date': 'Data di riparazione',
            'remark': 'Osservazione',
            'tenant_key': 'Chiave degli inquilini'
        },
        'validate': {
            'required': {
                'id_rep_log': 'ID richiesto',
                'id_car': 'Auto richiesta',
                'amount': 'Importo richiesto',
                'repair_date': 'Data di riparazione richiesta',
                'remark': 'Nota richiesta',
                'tenant_key': 'Chiave degli inquilini richiesta'
            }
        }
    },
    'role': {
        'name': {'title': 'Ruolo', 'detailTitle': 'Dettaglio del ruolo', 'editTitle': 'Modifica il ruolo'},
        'table': {'id_rol': 'ID', 'name': 'Ruolo', 'remark': 'Osservazione'},
        'field': {'id_rol': 'ID', 'name': 'Ruolo', 'remark': 'Osservazione', 'authorityList': 'Elenco delle autorità'},
        'validate': {'required': {'id_rol': 'ID richiesto', 'name': 'Ruolo richiesto', 'remark': 'Nota richiesta'}}
    },
    'roleAuthority': {
        'name': {
            'title': 'Roleauthority',
            'detailTitle': 'Dettagli roleauthority',
            'editTitle': 'Modifica roleauthority'
        },
        'table': {'id_rol_aut': 'ID', 'id_rol': 'Ruolo', 'id_aut': 'Autorità'},
        'field': {'id_rol_aut': 'ID', 'id_rol': 'Ruolo', 'id_aut': 'Autorità'},
        'validate': {
            'required': {
                'id_rol_aut': 'ID richiesto',
                'id_rol': 'Ruolo richiesto',
                'id_aut': 'Autorità richiesta'
            }
        }
    },
    'transactionLog': {
        'name': {
            'title': 'Registro delle transazioni',
            'detailTitle': 'Dettaglio del registro delle transazioni',
            'editTitle': 'Modificare il registro delle transazioni'
        },
        'table': {
            'id_tra_log': 'ID',
            'id_con': 'Connettore IDT',
            'event_time': "Orario dell'evento",
            'id_tag': 'Tag ID',
            'start_time': 'Ora di inizio',
            'start_value': 'Valore di inizio',
            'stop_time': 'Tempo di stop',
            'stop_value': 'Valore di arresto',
            'stop_reason': 'Smettere di motivi',
            'fail_reason': 'Fallire',
            'tenant_key': 'Chiave degli inquilini',
            'current_electricity': 'Elettricità corrente'
        },
        'field': {
            'id_tra_log': 'ID',
            'id_con': 'Connettore IDT',
            'event_time': "Orario dell'evento",
            'id_tag': 'Tag ID',
            'start_time': 'Ora di inizio',
            'start_value': 'Valore di inizio',
            'stop_time': 'Tempo di stop',
            'stop_value': 'Valore di arresto',
            'stop_reason': 'Smettere di motivi',
            'fail_reason': 'Fallire',
            'tenant_key': 'Chiave degli inquilini',
            'current_electricity': 'Elettricità corrente'
        },
        'validate': {
            'required': {
                'id_tra_log': 'ID richiesto',
                'id_con': 'Connettore IDT richiesto',
                'event_time': "Tempo dell'evento richiesto",
                'id_tag': 'ID Tag Required',
                'start_time': 'Ora di inizio richiesto',
                'start_value': 'Avvia valore richiesto',
                'stop_time': 'Tempo di arresto richiesto',
                'stop_value': 'Valore di arresto richiesto',
                'stop_reason': 'STOP RIUSIONE RICHIESTA',
                'fail_reason': 'Fallire motivo richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta',
                'current_electricity': 'Elettricità corrente richiesta'
            }
        }
    },
    'user': {
        'name': {'title': 'Utente', 'detailTitle': "Dettaglio dell'utente", 'editTitle': "Modifica l'utente"},
        'table': {
            'id_use': 'ID',
            'balance': 'Bilancia',
            'account': 'Account',
            'password': "Parola d'ordine",
            'register_time': 'Tempo di registrazione',
            'email': 'E-mail',
            'token': 'Gettone',
            'salt': 'Sale',
            'tenant_key': 'Chiave degli inquilini',
            'phone': 'Telefono',
            'register_type': 'Tipo di registro'
        },
        'field': {
            'id_use': 'ID',
            'balance': 'Bilancia',
            'account': 'Account',
            'password': "Parola d'ordine",
            'register_time': 'Tempo di registrazione',
            'email': 'E-mail',
            'token': 'Gettone',
            'salt': 'Sale',
            'tenant_key': 'Chiave degli inquilini',
            'phone': 'Telefono',
            'register_type': 'Tipo di registro',
            'chargingCard': 'Numero della carta di ricarica',
            'chargingType': 'Modalità di ricarica prioritaria',
            'chargingDefDuration': 'Tempo di ricarica della carta (min)',
            'chargingDefPower': 'Capacità di ricarica della carta (KWH)'
        },
        'validate': {
            'required': {
                'id_use': 'ID richiesto',
                'balance': 'Equilibrio richiesto',
                'account': 'Account richiesto',
                'password': 'Password richiesta',
                'register_time': 'Tempo di registrazione richiesto',
                'email': 'Email (obbligatorio',
                'token': 'Token richiesto',
                'salt': 'Sale richiesto',
                'tenant_key': 'Chiave degli inquilini richiesta',
                'phone': 'Telefono richiesto',
                'register_type': 'Tipo di registrazione richiesto',
                'chargingCard': 'Si prega di compilare il numero della scheda di ricarica',
                'chargingType': 'Seleziona la modalità di ricarica prioritaria della carta',
                'chargingDefDuration': 'Si prega di compilare il tempo di ricarica della carta',
                'chargingDefPower': "Si prega di compilare l'importo della ricarica della carta (KWH)"
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Piano utente',
            'detailTitle': 'Dettaglio del piano utente',
            'editTitle': 'Modifica il piano utente'
        },
        'table': {
            'id_use': 'Utente',
            'id_pla': 'Piano',
            'arrearsCount': 'Contano',
            'start_date': "Data d'inizio",
            'tenant_key': 'Chiave degli inquilini',
            'createType': 'Creare identità',
            'updateByMonth': 'Tempo di pagamento anticipato',
            'total': 'Mesi prepagati',
            'amount': 'Importo del pagamento previsto',
            'prePayData': 'Mese di pagamento previsto'
        },
        'validate': {
            'id_use': 'Uer richiesto',
            'id_pla': 'Piano richiesto',
            'arrearsCount': 'Si prega di compilare il numero di mesi in arretrato',
            'start_date': 'Data di inizio richiesta',
            'tenant_key': 'Chiave degli inquilini richiesta'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Caricabatterie totali',
            'availableChargers': 'Caricatore disponibile',
            'inUseChargers': 'In uso caricatore',
            'unavailableChargers': 'Caricatore non disponibile',
            'portUsage': 'Utilizzo del caricabatterie',
            'energyUsage': 'Uso energetico (KWH)',
            'totalRevenue': 'Entrate totali',
            'totalEnergy': 'Energia totale',
            'newUser': 'Nuovo utente'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Versione Android',
            'androidDownloadLink': 'Link per il download Android',
            'androidComplieNumber': 'Numero di compilazione Android',
            'iosVersion': 'Versione iOS',
            'iosBundleId': 'ID pacchetto iOS',
            'iosComplieNumber': 'IOS Numero di compilazione',
            'isForcedUpdate': 'È un aggiornamento forzato'
        },
        'validate': {
            'required': {
                'androidVersion': 'Versione Android richiesta',
                'androidDownloadLink': 'Link per il download Android richiesto',
                'androidComplieNumber': 'Numero di compilazione Android richiesto',
                'iosVersion': 'Versione iOS richiesta',
                'iosBundleId': 'ID pacchetto iOS richiesto',
                'iosComplieNumber': 'IOS Numero di compilazione richiesto',
                'isForcedUpdate': 'Si prega di scegliere se forzare gli aggiornamenti'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Tasto di impostazione', 'dataStr': 'Impostazione del valore'}},
    'superTenantManage': {
        'name': {'title': 'Autorizzazioni', 'detailTitle': 'Dettagli', 'editTitle': 'Modificare'},
        'searchForm': {
            'dataSourceKey': "Chiave dell'origine dati",
            'url': 'URL',
            'username': 'Nome utente',
            'tenant': 'Tenant',
            'isEnable': 'È abilitato',
            'isCreate': 'Iscreate'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': "Immettere la chiave dell'origine dati",
            'url': "Inserisci l'indirizzo del collegamento",
            'username': "Inserisci l'account",
            'tenant': "Inserisci il nome dell'inquilino",
            'isEnable': 'Si prega di scegliere se abilitare',
            'isCreate': 'Si prega di scegliere di creare un logo'
        },
        'table': {
            'index': 'Indice',
            'dataSourceKey': "Chiave dell'origine dati",
            'url': 'URL',
            'host': 'Ospite',
            'port': 'Porta',
            'dbName': 'Nome del database',
            'username': 'Nome utente',
            'password': "Parola d'ordine",
            'tenant': 'tenant',
            'isEnable': 'È abilitato',
            'isCreate': 'Iscreate',
            'domain': 'Dominio',
            'action': {'testConn': 'Conn', 'create': 'Creare', 'edit': 'Modificare', 'del': 'Eliminare'}
        },
        'field': {
            'id': 'ID',
            'datasourceKey': "Chiave dell'origine dati",
            'seckey': 'Chiavi di accesso',
            'url': 'URL',
            'host': 'Ospite',
            'port': 'Porta',
            'dbName': 'Nome del database',
            'username': 'Nome utente',
            'password': "Parola d'ordine",
            'tenant': 'Tenant',
            'isEnable': 'È abilitato',
            'isCreate': 'Iscreate',
            'remark': 'Osservazione',
            'domain': 'Dominio',
            'onesignalKey': 'Chiave di notifica delle app'
        },
        'validate': {
            'required': {
                'id': "Si prega di compilare l'ID",
                'dataSourceKey': "Si prega di compilare la chiave dell'origine dati",
                'seckey': 'Si prega di compilare il seckey',
                'url': "Si prega di compilare l'URL del collegamento",
                'host': "Si prega di compilare l'host",
                'port': 'Si prega di compilare la porta',
                'dbName': 'Si prega di compilare il nome del database',
                'username': 'Si prega di inserire il nome utente',
                'password': 'Si prega di compilare la password',
                'tenant': "Si prega di compilare il nome dell'inquilino",
                'isEnable': 'Seleziona uno stato attivo',
                'remark': 'Si prega di compilare le osservazioni',
                'domain': 'Si prega di compilare il dominio',
                'onesignalKey': "Si prega di compilare la chiave di notifica dell'app"
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'ID',
            'index': 'Indice',
            'machineCode': 'Codice macchina',
            'name': 'Nome',
            'regId': 'Regione ID',
            'regName': 'Nome regione',
            'seckey': 'Seckey',
            'args': 'Args',
            'createBy': 'Creato da',
            'createTime': 'Creare tempo',
            'updateBy': 'Aggiornamento da',
            'updateTime': 'Tempo di aggiornamento',
            'remark': 'Osservazione'
        },
        'validate': {
            'id': "Si prega di compilare l'ID",
            'machineCode': 'Si prega di compilare il codice macchina',
            'name': 'Si prega di compilare il nome',
            'regId': "Si prega di compilare l'ID della regione",
            'seckey': 'Si prega di compilare il seckey',
            'args': 'Si prega di compilare gli Args',
            'remark': "Per favore, compila l'osservazione"
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mail', '1': 'Telefono'},
        'noticeType': {'0': 'Avvertimento', '1': 'Ricordare', '2': 'Annuncio', '3': 'Tempo scaduto'},
        'field': {
            'id': 'ID',
            'index': 'Indice',
            'toFrom': 'Account',
            'type': 'Tipo di account',
            'noticeType': 'Tipo di notifica',
            'status': 'È abilitato',
            'createBy': 'Creato da',
            'createTime': 'Creare tempo',
            'updateBy': 'Aggiornamento da',
            'updateTime': 'Tempo di aggiornamento',
            'remark': 'Osservazione'
        },
        'validate': {
            'id': "Si prega di compilare l'ID",
            'type': 'Si prega di scegliere il tipo di account',
            'toFrom': "Si prega di compilare l'account",
            'noticeType': 'Scegli il tipo di notifica',
            'status': 'Si prega di scegliere se abilitare',
            'remark': "Per favore, compila l'osservazione"
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'Termini di servizio',
            '1': 'Chi siamo',
            '2': 'Annuncio',
            '3': 'Avviso',
            '4': 'Ricordare'
        },
        'field': {
            'id': 'ID',
            'index': 'Indice',
            'type': 'Tipo di pubblicità',
            'status': 'È abilitato',
            'title': 'Titolo',
            'content': 'Contenuto',
            'tenantKey': 'Chiave degli inquilini',
            'createBy': 'creato da',
            'createTime': 'Creare tempo',
            'updateBy': 'Aggiornamento da',
            'updateTime': 'Tempo di aggiornamento',
            'remark': 'Osservazione'
        },
        'validate': {
            'id': "Si prega di compilare l'ID",
            'type': 'Seleziona il tipo di annuncio',
            'status': 'Seleziona se abilitarlo',
            'title': "Si prega di compilare il titolo dell'annuncio",
            'content': "Si prega di compilare il contenuto dell'avviso",
            'remark': 'Si prega di compilare i commenti'
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'Numero di serie',
            'identifier': 'Identificatore di pile',
            'useNum': 'Usa il numero',
            'chargeCapacity': 'CONDARE TOTALE (KW)',
            'startTime': 'Ora di inizio',
            'endTime': 'Tempo scaduto'
        },
        'validate': {
            'identifier': 'Inserisci il numero della pila',
            'startTime': 'Seleziona un orario di inizio',
            'endTime': 'Si prega di selezionare un orario di fine'
        }
    },
    'serviceManage': {
        'type': {'0': 'Bevande', '1': 'Cibo', '2': 'Bagno', '3': 'Negozi'},
        'table': {
            'id': 'ID',
            'type': 'type',
            'status': 'stato',
            'name': 'nome',
            'lng': 'longitudine',
            'lat': 'latitudine',
            'address': 'indirizzo',
            'description': 'descrizione',
            'reId': 'Reid'
        },
        'validate': {
            'type': 'Seleziona un tipo',
            'status': 'Seleziona uno stato',
            'name': 'Si prega di compilare il nome',
            'lng': 'Si prega di compilare la longitudine',
            'lat': 'Si prega di compilare la latitudine',
            'address': "Si prega di compilare l'indirizzo",
            'description': 'Si prega di compilare una descrizione',
            'reId': 'Regione associata'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Chiave primaria',
            'tId': 'Associato ID inquilino',
            'name': 'Nome della ditta',
            'attn': 'Collegamento aziendale',
            'tel': 'Telefono aziendale',
            'address': 'indirizzo aziendale',
            'email': "Email dell'azienda",
            'invoiceDate': 'Data fattura',
            'invoiceNo': 'Numero di fattura',
            'contactPerson': 'Contatto',
            'contactTel': 'Numero di contatto',
            'contactEmail': 'contatto email',
            'monthlyFee': 'Accesso al sistema Accesso mensile Valore preimpostato',
            'company': 'Informazioni sulla società'
        },
        'validate': {
            'id': 'Si prega di inserire una chiave primaria',
            'tId': "Immettere l'ID inquilino associato",
            'name': 'Si prega di inserire un nome di una società',
            'attn': "Inserisci la persona di contatto dell'azienda",
            'tel': 'Inserisci il telefono della tua azienda',
            'address': "Inserisci l'indirizzo della tua azienda",
            'email': 'Inserisci la tua email della tua azienda',
            'invoiceDate': 'Inserisci la data della fattura',
            'invoiceNo': 'Inserisci il numero di fattura',
            'contactPerson': 'Inserisci una persona di contatto',
            'contactTel': 'Inserisci il tuo numero di contatto',
            'contactEmail': 'Inserisci la tua email di contatto',
            'monthlyFee': 'Immettere il valore predefinito della commissione mensile di accesso al sistema'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'ID inquilino genitore',
            'tenantKey': 'Chiave degli inquilini',
            'name': 'Nome inquilino',
            'domain': 'Nome del dominio Bind',
            'tpId': 'Pacchetto associato',
            'authId': 'Autorizzazioni associate',
            'level': 'livello',
            'contact': 'Referente',
            'email': 'Contatto email',
            'phone': 'Numero di contatto',
            'startTime': 'Tempo di attivazione',
            'expireTime': 'Data di scadenza'
        },
        'args': {
            'yedPayExtendParams': 'Yedpay Args',
            'yedPayApiKey': 'Yedpay Apikey',
            'yedPaySingKey': 'Yedpay Singkey',
            'mPayExtendParams': 'Mpay args',
            'mPayPubKey': 'Mpay Pubkey',
            'mPayPriKey': 'Mpay Prikey'
        },
        'validate': {
            'id': 'Si prega di inserire un ID',
            'pid': "Inserisci l'ID inquilino principale",
            'tenantKey': 'Si prega di compilare la chiave degli inquilini',
            'name': "Si prega di compilare il nome dell'inquilino",
            'domain': 'Si prega di compilare il nome del dominio vincolato',
            'tpId': 'Seleziona un pacchetto associato',
            'authId': 'Seleziona le autorizzazioni associate',
            'contact': 'Si prega di compilare la persona di contatto',
            'email': "Si prega di compilare l'e -mail di contatto",
            'phone': 'Si prega di compilare il numero di telefono di contatto',
            'startTime': 'Seleziona un orario di inizio',
            'expireTime': 'Seleziona un tempo di scadenza',
            'yedPayExtendParams': 'Inserisci il parametro Yedpay',
            'yedPayApiKey': 'Inserisci Yedpay Apikey',
            'yedPaySingKey': 'Inserisci Yedpay Singkey',
            'mPayExtendParams': 'Immettere i parametri MPAY',
            'mPayPubKey': 'Inserisci la chiave pubblica MPAY',
            'mPayPriKey': 'Inserisci la chiave privata MPAY'
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Numero di pacchetto',
            'name': 'Nome del pacchetto',
            'createTenantMax': 'Numero di inquilini che possono essere creati',
            'createUserMax': 'Numero di utenti che possono essere creati',
            'createAppMax': 'Numero di app regolabili',
            'expireLong': 'Durata effettiva del pacchetto',
            'tenantKey': 'Identificazione degli inquilini'
        },
        'validate': {
            'id': 'Si prega di inserire un ID',
            'packageNo': 'Inserisci il numero del pacchetto',
            'name': 'Immettere il nome del pacchetto',
            'createTenantMax': 'Inserisci il numero di inquilini che possono essere creati',
            'createUserMax': 'Inserisci il numero di inquilini che possono essere creati',
            'createAppMax': 'Immettere il numero di app cancellabili',
            'expireLong': 'Seleziona la durata effettiva del pacchetto',
            'tenantKey': 'Inserisci il tasto degli inquilini'
        }
    },
    'card': {
        'cardType': {'0': 'Lista bianca', '1': 'Lista nera', '2': 'Scheda di sistema', '3': 'Scheda utente'},
        'table': {'cardId': 'ID', 'cardNo': 'Numero di carta', 'cardType': 'Type', 'cardStatus': 'È abilitato'},
        'validate': {
            'cardId': "Si prega di compilare l'ID",
            'cardNo': 'Si prega di compilare il numero della carta',
            'cardType': 'Seleziona un tipo',
            'cardStatus': 'Seleziona uno stato'
        }
    },
    'waller': {
        'wType': {'1': 'Bilancia', '2': 'Importo regalo', '3': 'Integrante'},
        'table': {
            'walletId': 'ID',
            'account': 'Account',
            'type': 'Type',
            'status': 'Congelamento o no',
            'balance': 'Bilancia'
        },
        'validate': {
            'account': 'Si prega di compilare il tuo account',
            'type': 'Seleziona il tipo',
            'status': 'Seleziona uno stato'
        }
    }
}

export default it