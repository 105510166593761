<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('user.field.account')" name="account">
                  <Input :placeholder="$t('user.validate.required.account')" v-model:value="searchForm.account"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('user.field.email')" name="email">
                  <Input :placeholder="$t('user.validate.required.email')" v-model:value="searchForm.email"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('user.field.phone')" name="phone">
                  <Input :placeholder="$t('user.validate.required.phone')" v-model:value="searchForm.phone"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('user.field.chargingCard')" name="chargingCard">
                  <Input :placeholder="$t('user.validate.required.chargingCard')" v-model:value="searchForm.chargingCard"></Input>
                </FormItem>
              </Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <Button type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>
                <span>&nbsp;</span>
                <Button @click="showUploadForm" >{{ $t('public.name.Import') }}</Button>
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
                <span>&nbsp;</span>
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idUse" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('UserManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'status'">
              <div>
                <!-- 0:未注册；1:正常；2:异常 -->
                <Tag v-if="record === 1" color="#ff4757">&nbsp;</Tag>
                <!--              <Tag v-else-if="text === false" color="#2ed573">&nbsp;</Tag>-->
                <Tag v-else color="#2ed573">&nbsp;</Tag>
              </div>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">
                  {{ $t("public.name.detail") }}
                </a>
                <Divider type="vertical"/>
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem>
                        <Button size="small" @click="resetPassword(record.idUse)">{{$t("public.name.reset_password")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('UserManage.Edit') && record.isDel !== 1">
                        <Button size="small" @click="editBal(record)">{{$t("balanceLog.name.title")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('UserManage.Edit') && record.isDel !== 1">
                        <Button  type="primary" size="small" @click="edit(record)">{{$t("public.name.edit")}}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('UserManage.Delete') && record.isDel !== 1" >
                        <Button type="primary" danger size="small" @click="del(record.idUse)">{{$t("public.name.del")}}</Button>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <UploadForm ref="refUploadForm"></UploadForm>
    <UserDetail ref="refUserDetail"></UserDetail>
    <UserEdit ref="refUserEdit" @updateList="get_list"></UserEdit>
    <UserBalanceLogEdit ref="refUserBalanceLogEdit" @updateList="get_list"></UserBalanceLogEdit>
  </Row>
</template>
<script>
export default {
  name: "UserManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import { Row, Col, Table, Tag, Button, Dropdown, Menu, MenuItem, Modal, Form, FormItem, Input, message, Divider, Upload} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import {UserList, UserDelete, UserResetPassword, UserExportToExcel} from "@/api/user";
import UserDetail from "./UserDetail";
import UserEdit from "./UserEdit";
import UserBalanceLogEdit from "./UserBalanceLogEdit";
import UploadForm from "./UploadForm";
import moment from "moment";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const i18n = useI18n()
const store = useStore()

const refUploadForm = ref()
const refUserEdit = ref()
const refUserDetail = ref()
const refUserBalanceLogEdit = ref()

const columns = [
  { title: i18n.t("user.table.id_use"), width: 60, ellipsis: true, fixed: "left", dataIndex: "idUse", key: "idUse" },
  { title: i18n.t("public.name.status"), width: 80, ellipsis: true, dataIndex: "status", key: "isDel"},
  { title: i18n.t("user.table.balance"), width: 90, ellipsis: true, dataIndex: "balance", key: "balance" },
  { title: i18n.t("user.table.account"), width: 180, ellipsis: true, dataIndex: "account", key: "account" },
  // { title: i18n.t("user.table.password"), dataIndex: "password", key: "password" },
  { title: i18n.t("user.table.register_time"), width: 180, ellipsis: true, dataIndex: "registerTime", key: "registerTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
  { title: i18n.t("user.table.email"), width: 180, ellipsis: true, dataIndex: "email", key: "email" },

  // { title: i18n.t("user.table.token"), dataIndex: "token", key: "token" },
  // { title: i18n.t("user.table.salt"), dataIndex: "salt", key: "salt" },

  { title: i18n.t("user.table.phone"), width: 120, ellipsis: true, dataIndex: "phone", key: "phone" },
  { title: i18n.t("user.table.register_type"), width: 80, ellipsis: true, dataIndex: "registerType", key: "registerType", customRender: ({text}) =>{
      if(text)
        return registerTypeList.find(item=>item.value===text).label
    }
  },
  { title: i18n.t("user.field.chargingCard"), width: 120, ellipsis: true, dataIndex: "chargingCard", key: "chargingCard" },
  { title: i18n.t("user.table.tenant_key"), width: 120, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey" },
  { key: "action", width: 120, ellipsis: true, fixed: "right", dataIndex: "action"},
]
const registerTypeList = [{value: 1,label: i18n.t("public.registerType.0")},{value: 2,label: i18n.t("public.registerType.1")}]

let loading = ref(false)
let list = ref([])
const fileList = ref([]);
const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new UserList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function showUploadForm() {
  refUploadForm.value?.showForm();
}

function add() {
  refUserEdit.value?.addShow();
}

function detail(obj) {
  refUserDetail.value?.detailShow(obj);
}

function resetPassword(idUse){
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new UserResetPassword();
      api.post({ idUse: idUse }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      })
    }
  })
}

function edit(obj) {
  refUserEdit.value.editShow(obj);
}

function editBal(obj) {
  refUserBalanceLogEdit.value.addShow(obj);
}

function del(idUse) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new UserDelete();
      api.post({ idUse: idUse }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function exportToExcel() {
  loading.value = true
  const api = new UserExportToExcel()
  api.getDownLoad(searchForm.value).then(() => {}).finally(f => {
    loading.value = false
  })
}



function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  tablePagination.current = 1
  searchForm.value = {}
  get_list()
}

store.commit("setPageTitle", i18n.t('nav.name.UserManage'))
get_list()

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
