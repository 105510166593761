<template>
  <Modal
      id="PartnerBoxList"
      width="50%"
      :open="visible"
      @cancel="cancel"
      @ok="cancel"
      :title="chargeBoxId"
      :footer="null"
      :destroyOnClose="true"
  >
    <Table :columns="columns" :dataSource="listView" :scroll="{ x: 800, y: 500 }" rowKey="id" :loading="loading" :pagination=false>
      <!-- 标题插槽-->
      <template #headerCell="{ column }">
        <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
        </template>
      </template>
      <!-- 内容插槽-->
      <template #bodyCell="{column, record}">
        <template v-if="column.dataIndex === 'partnerName'">
          <div v-if="record?.isEdit">
            <Select v-model:value="formData.partnerId" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" style="width: 100%;">
              <SelectOption v-for="(item) in hzsList" :key="item.partnerId" :value="item.partnerId">{{ item.partnerName }}</SelectOption>
            </Select>
          </div>
          <div v-else>
            {{ hzsList.find(item => item.partnerId === record.partnerId)?.partnerName }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'divided'">
          <div v-if="record?.isEdit">
            <Input v-model:value="formData.divided" />
          </div>
          <div v-else>
            {{ record.divided }}
          </div>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <div v-if="record?.isEdit">
            <Button type="primary" size="small" @click="onSubmit()" :loading="onSubmitLoading">{{ $t("public.name.submit") }}</Button>
            <span>&nbsp;</span>
            <Button size="small" @click="edit(record, false)">{{ $t("public.name.cancel") }}</Button>
          </div>
          <div v-else>
            <Button type="primary" size="small" @click="edit(record, true)">
              {{ $t("public.name.edit") }}
            </Button>
            <span>&nbsp;</span>
            <Button type="primary" danger size="small" @click="del(record.partnerId)">{{ $t("public.name.del") }}
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </Modal>
</template>

<script setup>
import {
  Modal,
  Button,
  Table,
  Select,
  SelectOption,
  Input, message
} from "ant-design-vue";
import {PlusOutlined} from "@ant-design/icons-vue";
import {ref, reactive, toRefs} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n"
import {ConnectorLogFindByPage} from "@/api/connectorLog";
import {
  ChargingStationDelPartner, ChargingStationDelPartnerBox, ChargingStationGetPartnerBoxList,
  ChargingStationGetPartnerList,
  ChargingStationSaveOrUpdatePartner, ChargingStationSaveOrUpdatePartnerBox
} from "@/api/chargingStation";
const i18n = useI18n()

let visible = ref(false)
let loading = ref(false)
let onSubmitLoading = ref(false)
let boxId = ref()
let chargeBoxId = ref("")

const formData = ref({
  partnerId: undefined,
  boxId: undefined,
  divided: undefined
})

const columns = [
  {
    title: "合作商名称",
    width: 90,
    ellipsis: true,
    dataIndex: "partnerName",
    key: "partnerName"
  },
  {
    title: "分成",
    width: 90,
    ellipsis: true,
    dataIndex: "divided",
    key: "divided"
  },
  {key: "action", fixed: "right", width: 50, dataIndex:"action"},
]

const data = reactive({
  listView: [],
  hzsList: [],
})
const {listView, hzsList} = toRefs(data)

function onShow(id, no) {
  visible.value = true
  boxId.value = id
  chargeBoxId.value = no
  get_list()
}

function add() {
  // 校验是否有存在编辑
  if (listView.value.length > 0 && listView.value.find((item=>item.isEdit===true))) return;

  formData.value = {}
  // 添加一行
  listView.value.push({
    partnerId: undefined,
    partnerName: undefined,
    divided: undefined,
    isEdit: true
  })
}

function edit(record, isEdit) {
  if (record) {
    if (isEdit) {
      // 校验是否有存在编辑
      if (listView.value.find((item=>item.isEdit===true))) return;

      formData.value.partnerId = record.partnerId
      formData.value.partnerName = record.partnerName
      formData.value.divided = record.divided
    } else {
      formData.value = {}
      listView.value = listView.value.filter(item => {
        return item.partnerId !== undefined
      })
    }
    record.isEdit = isEdit
  }
}

function onSubmit(){
  formData.value.boxId = boxId.value
  if (formData.value.partnerId === undefined || formData.value.divided === undefined) {
    return
  }
  onSubmitLoading.value = true
  let api = new ChargingStationSaveOrUpdatePartnerBox();
  api.post(formData.value).then(response => {
    if (0 === parseInt(response.data.code)) {
      get_list()
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  }).finally(() => {
    onSubmitLoading.value = false;
  });
}

function del(k){
  let api = new ChargingStationDelPartnerBox();
  api.post({partnerId: k, boxId: boxId.value}).then(response => {
    if (0 === parseInt(response.data.code)) {
      get_list()
    }
  })
}

function cancel() {
  visible.value = false
}

function get_list() {
  loading.value = true
  listView.value = []
  loading.value = false

  // 获取合作商列表
  const hzsApi = new ChargingStationGetPartnerList();
  hzsApi.get().then(response => {
    hzsList.value = response.data.data
  })

  const api = new ChargingStationGetPartnerBoxList();
  api.get().then(response => {
    console.log("response => ", response.data.data)

    loading.value = false
    if (0 === parseInt(response.data.code)) {
      let ls = []
      response.data.data.forEach(item => {
        ls.push({
          ...item,
          isEdit: false
        })
      })
      listView.value = ls
    } else {
      listView.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

defineExpose({
  onShow,
});
</script>
