import {Base} from "@/api/Base"

const api = "tenantPackage"

export class TenantPackageList extends Base {
    path = api + "/findByPage"
}

export class TenantPackageAdd extends Base {
    path = api + "/addInfo"
}

export class TenantPackageDelete extends Base {
    path = api + "/delInfo"
}

export class TenantPackageEdit extends Base {
    path = api + "/editInfo"
}

export class TenantPackageExport extends Base {
    path = api + "/export"
}

export class TenantPackageSelectList extends Base {
    path = api + "/selectList"
}
