<template>
  <div id="Dashboard">
    <Row :gutter="[16,16]">
      <Col :span="6">
        <Card>
          <Statistic :title="$t('dashboard.name.totalChargers')" :value="allChargingStationNum"
                     style="margin-right: 50px"/>
          <Progress :percent="100" size="small" :showInfo="false"/>
          100%
        </Card>
      </Col>
      <Col :span="6">
        <Card>
          <Statistic :title="$t('dashboard.name.availableChargers')" :value="allAvaliableChargingStationNum"
                     style="margin-right: 50px"/>
          <Progress :percent="allAvaliableChargingStationNum / allChargingStationNum * 100" size="small"
                    :showInfo="false"/>
          {{ (allAvaliableChargingStationNum / allChargingStationNum * 100).toFixed(2) }}%
        </Card>
      </Col>
      <Col :span="6">
        <Card>
          <Statistic :title="$t('dashboard.name.inUseChargers')" :value="usingChargingStationNum"
                     style="margin-right: 50px"/>
          <Progress :percent="usingChargingStationNum / allChargingStationNum * 100" size="small" :showInfo="false"/>
          {{ (usingChargingStationNum / allChargingStationNum * 100).toFixed(2) }}%
        </Card>
      </Col>
      <Col :span="6">
        <Card>
          <Statistic :title="$t('dashboard.name.unavailableChargers')" :value="allUnavaliableChargingStationNum"
                     style="margin-right: 50px"/>
          <Progress :percent="allUnavaliableChargingStationNum / allChargingStationNum * 100" size="small"
                    :showInfo="false"/>
          {{ (allUnavaliableChargingStationNum / allChargingStationNum * 100).toFixed(2) }}%
        </Card>
      </Col>
      <Col :span="12">
        <Card :title="$t('dashboard.name.energyUsage')">
          <template #extra>
            <Button type="primary" @click="exportEnergyUsage">{{ $t("public.name.Export") }}</Button>
            <span>&nbsp;</span>
            <Select
                v-model:value="idReg"
                show-search
                :placeholder="$t('public.pleaseSelect')"
                style="width: 132px;"
                :options="options"
                :filter-option="filterOption"
                @change="initStatistic"
                allowClear
            ></Select>
            <span>&nbsp;</span>
            <Select v-model:value="energyUsageType">
              <SelectOption value="date">{{ $t("public.timeType.3") }}</SelectOption>
              <SelectOption value="week">{{ $t("public.timeType.2") }}</SelectOption>
              <SelectOption value="month">{{ $t("public.timeType.1") }}</SelectOption>
              <SelectOption value="year">{{ $t("public.timeType.0") }}</SelectOption>
            </Select>
            <span>&nbsp;</span>
            <DatePicker v-model:value="dateByEnergyUsage" style="width: 132px;" allowClear @change="initStatistic" :picker="energyUsageType" :placeholder="$t('public.promptsToSelectTime')"/>
          </template>
          <div id="container"></div>
        </Card>

      </Col>
      <Col :span="12">
        <Card :title="$t('dashboard.name.portUsage')">
          <template #extra>
            <!--            <DatePicker @change="getStatisticEnergyUsage" picker="year" :placeholder="this.$t('public.promptsToSelectTime')" />-->
          </template>
          <div id="gauge"></div>
        </Card>

      </Col>
      <Col :span="6">
        <Card :title="$t('dashboard.name.totalRevenue')">

          <template #extra>
            <!--            <Button type="primary" @click="exportTotalRevenueType">导出</Button>-->
            <!--            <span>&nbsp;</span>-->
            <!--            <Select v-model:value="totalRevenueType">-->
            <!--              <SelectOption value="date">{{$t("public.timeType.3")}}</SelectOption>-->
            <!--              <SelectOption value="week">{{$t("public.timeType.2")}}</SelectOption>-->
            <!--              <SelectOption value="month">{{$t("public.timeType.1")}}</SelectOption>-->
            <!--              <SelectOption value="year">{{$t("public.timeType.0")}}</SelectOption>-->
            <!--              </Select>-->
            <!--            <DatePicker @change="getStatisticTotalRevenue" :picker="totalRevenueType" :placeholder="$t('public.promptsToSelectTime')" />-->
          </template>
          <Statistic :value="totalRevenue" style="margin-right: 50px" :placeholder="$t('public.promptsToSelectTime')"/>

        </Card>
      </Col>
      <Col :span="6">
        <Card :title="$t('dashboard.name.totalEnergy')">
          <template #extra>
            <!--            <Button type="primary" @click="exportTotalEnergyType">导出</Button>-->
            <!--            <span>&nbsp;</span>-->
            <!--            <Select v-model:value="totalEnergyType" :placeholder="$t('public.promptsToSelectTime')">-->
            <!--              <SelectOption value="date">{{$t("public.timeType.3")}}</SelectOption>-->
            <!--              <SelectOption value="week">{{$t("public.timeType.2")}}</SelectOption>-->
            <!--              <SelectOption value="month">{{$t("public.timeType.1")}}</SelectOption>-->
            <!--              <SelectOption value="year">{{$t("public.timeType.0")}}</SelectOption>-->
            <!--              </Select>-->
            <!--            <DatePicker @change="getStatisticTotalEnergy" :picker="totalEnergyType" :placeholder="$t('public.promptsToSelectTime')" />-->
          </template>
          <Statistic suffix="kWh" :value="totalEnergy" style="margin-right: 50px"
                     :placeholder="$t('public.promptsToSelectTime')"/>
        </Card>
      </Col>
      <Col :span="6">
        <Card :title="$t('dashboard.name.newUser')">
          <template #extra>
            <!--            <Select v-model:value="newUserType" >-->
            <!--              <SelectOption value="date">{{$t("public.timeType.3")}}</SelectOption>-->
            <!--              <SelectOption value="week">{{$t("public.timeType.2")}}</SelectOption>-->
            <!--              <SelectOption value="month">{{$t("public.timeType.1")}}</SelectOption>-->
            <!--              <SelectOption value="year">{{$t("public.timeType.0")}}</SelectOption>-->
            <!--              </Select>-->
            <!--            <DatePicker @change="getStatisticNewUser" :picker="newUserType" :placeholder="$t('public.promptsToSelectTime')" />-->
          </template>
          <Statistic :value="newUser" style="margin-right: 50px" :placeholder="$t('public.promptsToSelectTime')"/>

        </Card>
      </Col>
    </Row>
<!--    <Row style="margin-top: 50px;">-->
<!--      <Col :span="24">-->
<!--        <Row justify="center">-->
<!--          <Col :span="2">-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <Image :src="require('@/assets/img/1-green.png')" :preview="false"></Image>-->
<!--              </Col>-->
<!--            </Row>-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <span>{{ $t('public.pileStatus.0') }}</span>-->
<!--              </Col>-->
<!--            </Row>-->
<!--          </Col>-->
<!--          <Col :span="2">-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <Image :src="require('@/assets/img/1-yellow.png')" :preview="false"></Image>-->
<!--              </Col>-->
<!--            </Row>-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <span>{{ $t('public.pileStatus.1') }}</span>-->
<!--              </Col>-->
<!--            </Row>-->
<!--          </Col>-->
<!--          <Col :span="2">-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <Image :src="require('@/assets/img/1-red.png')" :preview="false"></Image>-->
<!--              </Col>-->
<!--            </Row>-->
<!--            <Row>-->
<!--              <Col :span="24" style="text-align: center">-->
<!--                <span>{{ $t('public.pileStatus.2') }}</span>-->
<!--              </Col>-->
<!--            </Row>-->
<!--          </Col>-->
<!--        </Row>-->

<!--        <Row justify="center" v-for="(item,index) in allRegionList" style="margin-top: 25px;" :key="index">-->
<!--          <Card :title="item.name" style="width: 100%" hoverable>-->
<!--            <Row :gutter="16">-->
<!--              <Col v-for="(item1,index) in item.chargingStationList" :key="index">-->
<!--                <Card :title="item1.name" hoverable>-->
<!--                  <Row :gutter="16">-->
<!--                    <Col v-for="(item2,index) in item1.connectorList" :key="index">-->
<!--                      <Card :title="item2.name" :bordered="true" style="text-align: center; min-width: 200px" hoverable >-->
<!--                        <Image v-if="item2.status === 1" :src="require('@/assets/img/2-green.png')" :preview="false"></Image>-->
<!--                        <Image v-else-if="item2.status === 2 || item2.status === 3 || item2.status === 4 || item2.status === 5" :src="require('@/assets/img/2-yellow.png')" :preview="false"></Image>-->
<!--                        <Image v-else :src="require('@/assets/img/2-red.png')" :preview="false"></Image>-->
<!--                      </Card>-->
<!--                    </Col>-->
<!--                  </Row>-->
<!--                </Card>-->
<!--              </Col>-->
<!--            </Row>-->
<!--          </Card>-->
<!--        </Row>-->
<!--      </Col>-->

<!--    </Row>-->

    <GoogleMap :api-key="googleMapApiKey" style="margin-top:50px;width : 100%; height: 500px" :center="center" :zoom="15">
      <Marker v-for="(item, index) in regionList" :options="item" :key="index" >
        <InfoWindow>
          <div id="contet">
            <div id="siteNotice"></div>
            <h1 id="firstHeading" class="firstHeading">{{ item.data.name }}</h1>
            <div id="bodyContent">
              <p>
                Chargers:{{ item.data.chargingStationList.length }}
              </p>
              <p>
                Price Per Minute: {{ item.data.pricePerMinute }}
              </p>
              <p>
                Price Per Electricity:{{ item.data.pricePerElectricity }}
              </p>
            </div>
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
<!--    <div id="allmap" style="height: 500px;margin-top: 50px;"></div>-->

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
}
</script>
<script setup>
import {
  Card,
  CardGrid,
  Statistic,
  Progress,
  Row,
  Col,
  DatePicker,
  Image,
  Select,
  SelectOption,
  Button
} from "ant-design-vue";
import {GoogleMap, Marker, InfoWindow} from "vue3-google-map";
import {RegionGetAllRegion, RegionGetAllRegionBackEnd, RegionGetSelectList} from "@/api/region"
import {
  ChargingStationGetStatistic,
  ChargingStationGetStatisticEnergyUsage,
  ChargingStationGetStatisticTotalRevenue,
  ChargingStationGetStatisticTotalEnergy,
  ChargingStationGetStatisticNewUser,
  ChargingStationGetStatisticUtilizationRate,
  ChargingStationExportEnergyUsage,
} from "@/api/chargingStation"

import {Line, Pie} from '@antv/g2plot';
import moment from 'moment'
import {useStore} from "vuex";
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const store = useStore();
const { proxy } = getCurrentInstance();

const isSuperAdmin = store.getters.getIsSuperAdmin

const piePlot = ref()
const line = ref()

const googleMapApiKey = "AIzaSyDbz9n5Ce5qwmUP5Bl5gS5g6oTtzOu6cAI"

let dbKey = ref(undefined)
let idReg = ref(undefined)

let regionList = ref([])
// let allRegionList = ref([])
let options = ref([])
let list = ref([])

let allChargingStationNum = ref(0)
let allAvaliableChargingStationNum = ref(0)
let usingChargingStationNum = ref(0)
let allUnavaliableChargingStationNum = ref(0)
let totalRevenue = ref(0)
let totalEnergy = ref(0)
let newUser = ref(0)

let dateByEnergyUsage = ref()
let idRegByPortUsage = ref()
let energyUsageDate = ref()

let exportButtonloading = ref(false)

let energyUsageType = ref('year')
// totalRevenueType: 'year',
// totalEnergyType: 'year',
let newUserType = 'year'

const data = reactive({
  center: {},
})
const {center} = toRefs(data)

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

function handleChange(type) {

}

// 获取所有区域 id-name 列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

function onPanelChange (value, mode) {
  // console.log(value, mode);
}

function getRegionList() {
  const api = new RegionGetAllRegion();
  api.get({
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      regionList.value.length = 0
      for (let i = 0; i < response.data.data.length; i++) {
        if (i === 0) {
          center.value = {
            lat: response.data.data[i].lat * 1,
            lng: response.data.data[i].lon * 1,
          }
        }
        regionList.value.push({
          data: response.data.data[i],
          position: {
            lat: response.data.data[i].lat * 1,
            lng: response.data.data[i].lon * 1,
          },
          icon: store.getters.getLogo,
          // label: "C",
          title: response.data.data[i].name
        })
      }
      // this.initMap();

    } else {
      list.value = [];
    }
  });
}

function getStatistic() {
  const api = new ChargingStationGetStatistic();
  api.get({
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      allChargingStationNum.value = response.data.data.allChargingStationNum;
      allAvaliableChargingStationNum.value = response.data.data.allAvaliableChargingStationNum;
      usingChargingStationNum.value = response.data.data.usingChargingStationNum;
      allUnavaliableChargingStationNum.value = response.data.data.allUnavaliableChargingStationNum;
    }
  });
}

function getStatisticEnergyUsage() {
  let date = dateByEnergyUsage.value
  let dateString = "";
  if (!date) {
    dateString = moment().format("YYYY")
  } else {
    switch (energyUsageType.value) {
      case 'date':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'week':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'month':
        dateString = date.format("YYYY-MM");
        break;
      case 'year':
        dateString = date.format("YYYY");
        break;
    }
  }
  const api = new ChargingStationGetStatisticEnergyUsage();
  api.get({
    type: energyUsageType.value,
    dateStr: dateString,
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      line.value.changeData(response.data.data)
    }
  });
}

function getStatisticTotalRevenue() {
  let date = dateByEnergyUsage.value
  let dateString = "";
  if (!date) {
    dateString = moment().format("YYYY")
  } else {
    switch (energyUsageType.value) {
      case 'date':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'week':
        dateString = date.format("YYYY-ww");
        break;
      case 'month':
        dateString = date.format("YYYY-MM");
        break;
      case 'year':
        dateString = date.format("YYYY");
        break;
    }
  }
  const api = new ChargingStationGetStatisticTotalRevenue();
  api.get({
    dateStr: dateString,
    type: energyUsageType.value,
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      totalRevenue.value = response.data.data.totalRevenue
    }
  });
}

function getStatisticTotalEnergy() {
  let date = dateByEnergyUsage.value
  let dateString = "";
  if (!date) {
    dateString = moment().format("YYYY")
  } else {
    switch (energyUsageType.value) {
      case 'date':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'week':
        dateString = date.format("YYYY-ww");
        break;
      case 'month':
        dateString = date.format("YYYY-MM");
        break;
      case 'year':
        dateString = date.format("YYYY");
        break;
    }
  }
  const api = new ChargingStationGetStatisticTotalEnergy();
  api.get({
    dateStr: dateString,
    type: energyUsageType.value,
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      totalEnergy.value = response.data.data.totalRevenue
    }
  });
}

function getStatisticNewUser() {
  let date = dateByEnergyUsage.value
  let dateString = "";
  if (!date) {
    dateString = moment().format("YYYY")
  } else {
    switch (energyUsageType.value) {
      case 'date':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'week':
        dateString = date.format("YYYY-ww");
        break;
      case 'month':
        dateString = date.format("YYYY-MM");
        break;
      case 'year':
        dateString = date.format("YYYY");
        break;
    }
  }
  const api = new ChargingStationGetStatisticNewUser();
  api.get({
    dateStr: dateString,
    type: energyUsageType.value,
    dbKey: dbKey.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      newUser.value = response.data.data.newUser
    }
  });
}

function getRegionGetAllRegionBackEnd() {
  // const api = new RegionGetAllRegionBackEnd();
  // api.get({
  //   dbKey: dbKey.value,
  //   idReg: idReg.value
  // }).then((response) => {
  //   if (0 === parseInt(response.data.code)) {
  //     allRegionList.value = response.data.data
  //   }
  // });
}

function getStatisticUtilizationRate() {
  const api = new ChargingStationGetStatisticUtilizationRate();
  api.get({
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      piePlot.value.changeData(response.data.data)
    }
  });
}

// function initMap() {
  // let Bmap = window.BMap;
  //
  // let map = new Bmap.Map("allmap");
  // map.enableScrollWheelZoom(true)     //开启鼠标滚轮缩放
  // map.setMinZoom(10);
  // map.setMaxZoom(30);
  // this.$nextTick(() => {
  //   for (let i = 0; i < this.regionList.length; i++) {
  //
  //     if (i === 0) {
  //       map.centerAndZoom(new BMap.Point(this.regionList[i].position.lng, this.regionList[i].position.lat), 10);
  //     }
  //     var point = new BMap.Point(this.regionList[i].position.lng, this.regionList[i].position.lat);
  //     var marker = new BMap.Marker(point)
  //     var opts = {
  //       width: 200,     // 信息窗口宽度
  //       height: 100,     // 信息窗口高度
  //       title: this.regionList[i].data.name, // 信息窗口标题
  //       message: this.regionList[i].data.name
  //     }
  //     var infoWindow = new BMap.InfoWindow("Chargers:" + this.regionList[i].data.chargingStationList.length + "<br>Price Per Minute:" + this.regionList[i].data.pricePerMinute + "<br>Price Per Electricity:" + this.regionList[i].data.pricePerElectricity, opts);  // 创建信息窗口对象
  //     marker.addEventListener("click", function () {
  //
  //       map.openInfoWindow(infoWindow, point); //开启信息窗口
  //     });
  //     map.addOverlay(marker)
  // }
  // })
// }

function initChart() {
  line.value = new Line('container', {
    data: [],
    xField: 'dateStr',
    yField: 'energyUsage',
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    smooth: true,
  });
  line.value.render();

  piePlot.value = new Pie('gauge', {
    appendPadding: 10,
    data: [],
    angleField: 'number',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `¥ ${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: {textAlign: 'center'},
      formatter: ({percent}) => `${(percent * 100).toFixed(0)}%`,
    },
    statistic: {
      title: {
        offsetY: -8,
      },
      content: {
        offsetY: -4,
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
      {
        type: 'pie-statistic-active',
        cfg: {
          start: [
            {trigger: 'element:mouseenter', action: 'pie-statistic:change'},
            {trigger: 'legend-item:mouseenter', action: 'pie-statistic:change'},
          ],
          end: [
            {trigger: 'element:mouseleave', action: 'pie-statistic:reset'},
            {trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset'},
          ],
        },
      },
    ],
  });
  piePlot.value.render();
}

function initStatistic() {
  getStatistic();
  getStatisticEnergyUsage();
  getStatisticUtilizationRate();
  getStatisticTotalRevenue();
  getStatisticTotalEnergy();
  getStatisticNewUser();
  getRegionGetAllRegionBackEnd();
  getRegionList();
  getRegionSelectList()
}

function exportEnergyUsage() {
  let date = dateByEnergyUsage.value
  let dateString = "";
  if (!date) {
    dateString = moment().format("YYYY")
  } else {
    switch (energyUsageType.value) {
      case 'date':
        dateString = date.format("YYYY-MM-DD");
        break;
      case 'week':
        dateString = date.format("YYYY-ww");
        break;
      case 'month':
        dateString = date.format("YYYY-MM");
        break;
      case 'year':
        dateString = date.format("YYYY");
        break;
    }
  }
  exportButtonloading.value = true;
  const api = new ChargingStationExportEnergyUsage();
  api.getDownLoad({
    dateStr: dateString,
    type: energyUsageType.value,
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then(() => {
    exportButtonloading.value = false;
  }).finally(() => {
    exportButtonloading.value = false;
  });
}

function get_list(v) {
  if (isSuperAdmin) {
    if (v === undefined) {
      dbKey.value = store.getters.getDsKey === undefined ? 'default' : store.getters.getDsKey
    } else {
      dbKey.value = v
    }
  }
  initStatistic()
}

onMounted(()=>{
  initChart()
  get_list()
})

store.commit("setPageTitle", i18n.t('nav.name.Dashboard'))

defineExpose({
  get_list
})
</script>
<style lang="less">
@import url("./style.less");
</style>

