<template>
  <div id="regionDashboard">
    <!--    <Row style="margin-top: 50px;">-->

    <Row>

      <Col :span="24">
        <Spin size="large" :spinning="loading"/>
        <Row justify="center" v-for="(item,index) in allRegionList" style="margin-top: 25px;" :key="index">
          <Card :title="item.name" style="width: 100%" hoverable>
            <Row :gutter="16">
              <Col v-for="(item1,index) in item?.chargingStationList" :key="index">
                <Card :title="item1.name" hoverable>
                  <Row :gutter="16">
                    <Col v-for="(item2,index) in item1?.connectorList" :key="index">
                      <Card :title="item2.name" :bordered="true" style="text-align: center; min-width: 550px" hoverable @click="onClick(item1.chargeBoxId, item2.connectorId)">
                        <Row>
                          <Col :span="6">
                            <div
                                style="position: relative;display: flex;justify-content: center;align-items: center;margin-top: 48px;">
                              <div style="position:absolute;">
                                <Image v-if="item2.status === 1" :src="require('@/assets/img/2-green.png')" :preview="false"></Image>
                                <Image v-else-if="item2.status === 2 || item2.status === 3 || item2.status === 4 || item2.status === 5 || item2.status === 6" :src="require('@/assets/img/2-yellow.png')" :preview="false"></Image>
                                <Image v-else :src="require('@/assets/img/2-red.png')" :preview="false"></Image>
                              </div>
                              <div style="position:absolute;margin-top: 85px;">
                                <Tag v-if="item2.status === 1" color="green">
                                  {{ statusList.find(it => it.value === item2.status).label }}
                                </Tag>
                                <Tag v-else-if="item2.status === 2 || item2.status === 3 || item2.status === 4 || item2.status === 5 || item2.status === 6" color="orange">
                                  {{ statusList.find(it => it.value === item2.status).label }}
                                </Tag>
                                <Tag v-else-if="item2.status === 9" color="pink">
                                  {{ statusList.find(it => it.value === item2.status).label }}
                                </Tag>
                                <Tag v-else color="red">{{ statusList.find(it => it.value === item2.status).label }}</Tag>
                              </div>
                            </div>
                          </Col>
                          <Col :span="18" style="background: #ecf0f1;border-radius: 5px;padding: 2px 2px">
                            <Row>

                              <Col :span="12" style="font-size: xx-small;text-align: left;">
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/xt.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item1.lastHeartbeatTime }}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/gj.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item1.firmwareVersion }}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/wd.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item2.temperature }}</p>
                                  </Col>
                                </Row>
                              </Col>

                              <Col :span="12" style="font-size: xx-small;text-align: left;">
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/hd.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item2.chargeMeter }}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/soc.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item2.soc }}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col :span="4">
                                    <img style="width: 16px;height: 16px;" src="@/assets/ioc/gl.png" alt="xt.png">
                                  </Col >
                                  <Col :span="20">
                                    <p>{{ item2.meterPower }}</p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row v-if="item2.info !== null && item2.info !== '' && item2.info !== undefined">
                              <Col :span="24">
                                <Textarea
                                    style="width: 100%;"
                                    v-model:value="item2.info"
                                    placeholder="info"
                                    :auto-size="{ minRows: 1, maxRows: 10 }"
                                    disabled
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </Row>
      </Col>
    </Row>
    <MeterDetail ref="meterDetailRef"/>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "regionDashboard",
}
</script>
<script setup>
import {
  Card,
  Row,
  Col,
  Image,
  Tag,
  Spin,
  Textarea
} from "ant-design-vue";
import {onMounted, ref} from "vue";
import {RegionGetAllRegionBackEnd} from "@/api/region";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import MeterDetail from "./MeterDetail";

const i18n = useI18n()
const store = useStore();

const meterDetailRef = ref()

const isSuperAdmin = store.getters.getIsSuperAdmin
let dbKey = ref('default')
let loading = ref(false)
let idReg = ref()
let allRegionList = ref([])
const statusList = [
  {value: 1, label: 'Available'},
  {value: 2, label: 'Preparing'},
  {value: 3, label: 'Charging'},
  {value: 4, label: 'SuspendedEVSE'},
  {value: 5, label: 'SuspendedEV'},
  {value: 6, label: 'Finishing'},
  {value: 7, label: 'Reserved'},
  {value: 8, label: 'Unavailable'},
  {value: 9, label: 'Faulted'},
  {value: 10, label: 'Disconnect'}
]

function getRegionGetAllRegionBackEnd() {
  loading.value = true
  const api = new RegionGetAllRegionBackEnd();
  api.get({
    dbKey: dbKey.value,
    idReg: idReg.value
  }).then((response) => {
    if (0 === parseInt(response.data.code)) {
      allRegionList.value = response.data.data
    }
  }).finally(() => {
    loading.value = false
  });
}

function get_list(v) {
  if (isSuperAdmin) {
    if (v === undefined) {
      dbKey.value = store.getters.getDsKey === undefined ? 'default' : store.getters.getDsKey
    } else {
      dbKey.value = v
    }
  } else {
    dbKey.value = store.getters.getDsKey
  }
  getRegionGetAllRegionBackEnd();
}

function onClick(boxId, conId) {
  // 获取该电桩数据
  meterDetailRef.value?.detailShow(boxId, conId)
}

onMounted(() => {
  get_list()
})

store.commit("setPageTitle", i18n.t('nav.name.Dashboard'))

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("./style.less");
</style>