<template>
  <Modal id="CarImagesDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('carImages.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('carImages.field.id_car_ima')">{{data.idCarIma}}</DescriptionsItem>
      <DescriptionsItem :label="$t('carImages.field.id_car')">{{data.carModel}}</DescriptionsItem>
      <DescriptionsItem :label="$t('carImages.field.url')">{{data.url}}</DescriptionsItem>
      <DescriptionsItem :label="$t('carImages.field.upload_time')">{{data.uploadTime}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('carImages.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "CarImagesDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>