<template>
  <Modal id="ConnectorDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('connector.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('connector.field.id_con')">{{data.idCon}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.id_cha_poi')">{{data.chargingStationChargeBoxId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.connector_id')">{{data.connectorId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.status')">{{statusList.find(item=>item.value===data.status)?.label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.power')">{{data.power}}</DescriptionsItem>
      <DescriptionsItem :label="$t('connector.field.type')">{{chargingPortTypeList.find(item=>item.value===data.type)?.label}}</DescriptionsItem>
       <DescriptionsItem :label="$t('connector.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "ConnectorDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";

let visible = ref(false)

const statusList = [
  {value: 1,label: 'Available'},
  {value: 2,label: 'Preparing'},
  {value: 3,label: 'Charging'},
  {value: 4,label: 'SuspendedEVSE'},
  {value: 5,label: 'SuspendedEV'},
  {value: 6,label: 'Finishing'},
  {value: 7,label: 'Reserved'},
  {value: 8,label: 'Unavailable'},
  {value: 9,label: 'Faulted'},
  {value: 10,label: 'Disconnect'}
]
const chargingPortTypeList = [
  {value: 0, label: 'Type 1'},
  {value: 1, label: 'Type 2'},
  {value: 2, label: 'CHAdeMO'},
  {value: 3, label: 'CCS 1'},
  {value: 4, label: 'CCS 2'},
]

const dataTemp = reactive({
  data: {}
})
const {data} = toRefs(dataTemp)

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>