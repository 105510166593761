<template>
  <Modal
      id="OcppConfig"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.name.edit')"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" >
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('chargingStation.table.charge_box_id')" name="identifier">
                    <Input :placeholder="$t('chargingStation.table.charge_box_id')" v-model:value="form.identifier" disabled></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargingStation.table.firmwareVersion')" name="firmwareVersion">
                    <Input :placeholder="$t('chargingStation.table.firmwareVersion')" v-model:value="form.firmwareVersion" disabled></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargingStation.table.isReadMeter')" name="isReadMeter">
                    <Select v-model:value="form.readMeter" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="'0'">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="'1'">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargingStation.table.isReset')" name="isReset">
                    <Select v-model:value="reset" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="'0'">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="'1'">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
export default {
  name: "OcppConfig",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {ChargingStationGetOcppInfo, ChargingStationReset, ChargingStationSetOcppInfo} from "@/api/chargingStation";
import {useStore} from "vuex";
import {createVNode, reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()

const isAdmin = store.getters.getIsAdmin

let visible = ref(false)

let ptt = ref("")
let reset = ref("1")

const validateRules = {
  name: [
    {
      required: true,
      message: i18n.t("meter.validate.required.name"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function editShow(identifier) {
  _resetData();
  // 获取远程电桩配置
  let api = new ChargingStationGetOcppInfo;
  api.get({identifier: identifier}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      const dt = response.data.data;
      if (dt === null) {
        return
      }
      visible.value = true;
      form.value.id = dt.id
      form.value.identifier = dt.identifier
      form.value.firmwareVersion = dt.firmwareVersion
      form.value.readMeter = dt.readMeter

      ptt.value = JSON.stringify(form.value)
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function submit() {
  refForm.value?.validate().then(() => {
    if (ptt.value === JSON.stringify(form.value)) {
      message.error(i18n.t("errorMsg.500105"))
      return
    }
    Modal.confirm({
      title: i18n.t("public.name.are_you_sure_operate") + "?",
      content: i18n.t("public.name.operate_remind"),
      icon: createVNode(ExclamationCircleOutlined),
      okText: i18n.t("public.name.yes"),
      okType: "danger",
      cancelText: i18n.t("public.name.no"),
      onOk: () => {
        form.value.reset = reset.value === '1'
        let api = new ChargingStationSetOcppInfo;
        api.post(form.value).then((response) => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      },
    });
  }).catch(() => {});
}

function _resetData() {
  reset.value = '1'
  form.value = {};
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

defineExpose({
  editShow
})
</script>