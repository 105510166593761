<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('tenantInfo.table.id')" name="id">
                  <InputNumber style="width: 100%;" :placeholder="$t('tenantInfo.validate.id')" v-model:value="searchForm.id" allowClear></InputNumber>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tenantInfo.table.pid')" name="pid">
                  <InputNumber style="width: 100%;" :placeholder="$t('tenantInfo.validate.pid')" v-model:value="searchForm.pid" allowClear></InputNumber>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tenantInfo.table.tenantKey')" name="tenantKey">
                  <Input :placeholder="$t('tenantInfo.validate.tenantKey')" v-model:value="searchForm.tenantKey" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.createBy')" name="createBy">
                  <Input :placeholder="$t('public.name.createBy')" v-model:value="searchForm.createBy" allowClear></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button :loading="exportButtonLoading" type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1000}" rowKey="idOrd" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('TenantInfo.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <!--              <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>-->
                <!--              <span>&nbsp;</span>-->
                <Button v-if="checkPermission('TenantInfo.Edit')" type="primary" size="small" @click="edit(record)">
                  {{ $t("public.name.edit") }}
                </Button>
                <span>&nbsp;</span>
                <Button v-if="checkPermission('TenantInfo.Edit') && userInfo.userTenantKey === record.tenantKey" size="small" @click="editArgs(record.id, record.tenantKey)">
                  {{ $t("public.name.args") }}
                </Button>
                <span>&nbsp;</span>
                <Button v-if="checkPermission('TenantInfo.Delete') && store.getters.getTenantKey !== record.tenantKey" type="primary" danger size="small"
                        @click="del(record.id, record.tenantKey)">{{ $t("public.name.del") }}
                </Button>
              </div>
            </template>
          </template>

        </Table>
      </div>
    </Col>
    <TenantEdit ref="refTenantEdit" @updateList="get_list" />
    <TenantArgs ref="refTenantArgs" @updateList="get_list" />
  </Row>
</template>

<script>
export default {
  name: "TenantInfo"
}
</script>
<script setup>
import {createVNode, onMounted} from "vue";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {
  TypographyTitle,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  InputNumber, Modal, message,
} from "ant-design-vue";

import dayjs from "dayjs";
import {ref, reactive, toRefs} from "vue";
import {TenantList, TenantDelete, TenantExport} from "@/api/tenantInfo";
import TenantEdit from "./TenantEdit";
import TenantArgs from "./TenantArgs";
import {useI18n} from "vue-i18n"
import {TenantPackageSelectList} from "@/api/tenantPackage";
import {useStore} from "vuex";
const i18n = useI18n()
const store = useStore()
const userInfo = ref(store.getters.getUserMsg)
const refTenantEdit = ref()
const refTenantArgs = ref()

let loading = ref(false)
let exportButtonLoading = ref(false)
const columns = reactive([
  {
    title: i18n.t("public.name.index"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    customRender: ({index}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  {
    title: i18n.t("tenantInfo.table.id"),
    width: 60,
    ellipsis: true,
    dataIndex: "id",
    key: "id",
  },
  {
    title: i18n.t("tenantInfo.table.pid"),
    width: 60,
    ellipsis: true,
    dataIndex: "pid",
    key: "pid",
  },
  {
    title: i18n.t("tenantInfo.table.tenantKey"),
    width: 100,
    ellipsis: true,
    dataIndex: "tenantKey",
    key: "tenantKey"
  },
  {
    title: i18n.t("tenantInfo.table.tpId"),
    width: 60,
    ellipsis: true,
    dataIndex: "tpId",
    key: "tpId",
    customRender: ({text}) =>{
      return tenantPackageSelectList.value.find(item=>item.value === text)?.label
    }
  },
  {
    title: i18n.t("tenantInfo.table.level"),
    width: 60,
    ellipsis: true,
    dataIndex: "level",
    key: "level"
  },
  // {
  //   title: i18n.t("tenantInfo.table.startTime"),
  //   width: 100,
  //   ellipsis: true,
  //   dataIndex: "startTime",
  //   key: "startTime",
  // },
  // {
  //   title: i18n.t("tenantInfo.table.expireTime"),
  //   width: 100,
  //   ellipsis: true,
  //   dataIndex: "expireTime",
  //   key: "expireTime",
  // },
  {
    title: i18n.t("public.name.createTime"),
    width: 100,
    ellipsis: true,
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: i18n.t("public.name.createBy"),
    width: 80,
    ellipsis: true,
    dataIndex: "createBy",
    key: "createBy"
  },
  {key: "action", fixed: "right", width: 120, ellipsis: true, dataIndex: "action"},
])
let searchData = reactive({
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  tenantPackageSelectList: []
})
const {searchForm, tablePagination, list, tenantPackageSelectList} = toRefs(searchData)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list()
}

function search_submit() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function detail(pagination) {

}
function add() {
  refTenantEdit.value?.addShow(tenantPackageSelectList.value)
}
function edit(text) {
  refTenantEdit.value?.editShow(text, tenantPackageSelectList.value)
}
function editArgs(id, tenantKey) {
  refTenantArgs.value?.editShow(id, tenantKey)
}
function del(id, tenantKey) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new TenantDelete();
      let ids = []
      ids.push(id)
      api.post({ ids: ids, tenantKey: tenantKey}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}
function exportToExcel() {
  exportButtonLoading.value = true;
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const api = new TenantExport();
  api.getDownLoad(args).then((res) => {
    exportButtonLoading.value = false;
  });
}

function get_list() {
  loading.value = true;
  getTenantPackageSelectList(store.getters.getTenantKey)

  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...args
  }
  const api = new TenantList();
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function getTenantPackageSelectList(tenantKey) {
  const api = new TenantPackageSelectList()
  api.get({tenantKey:tenantKey}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      let ls = []
      if (response.data.data) {
        response.data.data.forEach(item => {
          ls.push({
            value: item.id,
            label: item.name
          })
        })
      }
      tenantPackageSelectList.value = ls
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}



onMounted(()=>{
  // 设置页标题
  store.commit("setPageTitle", i18n.t('nav.name.TenantInfo'));

  get_list();
})
defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>