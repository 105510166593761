import {Base} from "@/api/Base"

const api = "connector"

export class ConnectorAdd extends Base {
    path = api + "/add"
}

export class ConnectorDelete extends Base {
    path = api + "/delete"
}

export class ConnectorList extends Base {
    path = api + "/list"
}

export class ConnectorEdit extends Base {
    path = api + "/edit"
}

export class ConnectorListAll extends Base {
    path = api + "/listAll"
}
