<template>
  <Modal id="RoleDetail" width="50%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('role.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('role.field.id_rol')">{{data.idRol}}</DescriptionsItem>
      <DescriptionsItem :label="$t('role.field.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('role.field.remark')">{{data.remark}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "RoleDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref} from "vue";

let visible = ref(false)
let data = reactive({})

async function detailShow(obj) {
  data = obj;
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>

<style lang="less">
</style>