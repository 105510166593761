<template>
  <Modal
      id="CompanyModel"
      width="50%"
      v-model:open="visible"
      @cancel="onCancel"
      @ok="onSubmit"
      :confirmLoading="confirmLoading"
      :title="$t('public.name.edit')"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="formState"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem label="&nbsp;">
                    <RangePicker v-model:value="valueTime"  @change="handlePanelChange"/>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem label="&nbsp;">
                    <Button type="primary" :loading="exportLoading" @click="exportExcelBymonthlyFee()">
                      {{ $t("public.name.Export") }}
                    </Button>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('companyManage.validate.id')"
                        v-model:value="formState.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.name')" name="name">
                    <Input
                        :placeholder="$t('companyManage.validate.name')"
                        v-model:value="formState.name">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.attn')" name="attn">
                    <Input
                        :placeholder="$t('companyManage.validate.attn')"
                        v-model:value="formState.attn">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.tel')" name="tel">
                    <Input
                        :placeholder="$t('companyManage.validate.tel')"
                        v-model:value="formState.tel">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.address')" name="address">
                    <Input
                        :placeholder="$t('companyManage.validate.address')"
                        v-model:value="formState.address">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.email')" name="email">
                    <Input
                        :placeholder="$t('companyManage.validate.email')"
                        v-model:value="formState.email">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.invoiceDate')" name="invoiceDate">
                    <DatePicker :placeholder="$t('companyManage.validate.invoiceDate')" style="width: 100%;" v-model:value="formState.invoiceDate" />
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.invoiceNo')" name="invoiceNo">
                    <Input
                        :placeholder="$t('companyManage.validate.invoiceNo')"
                        v-model:value="formState.invoiceNo">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.contactPerson')" name="contactPerson">
                    <Input
                        :placeholder="$t('companyManage.validate.contactPerson')"
                        v-model:value="formState.contactPerson">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.contactTel')" name="contactTel">
                    <Input
                        :placeholder="$t('companyManage.validate.contactTel')"
                        v-model:value="formState.contactTel">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('companyManage.field.contactEmail')" name="contactEmail">
                    <Input
                        :placeholder="$t('companyManage.validate.contactEmail')"
                        v-model:value="formState.contactEmail">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('companyManage.field.monthlyFee')" name="monthlyFee">
                    <InputNumber
                        style="width: 100%;"
                        :placeholder="$t('companyManage.validate.monthlyFee')"
                        v-model:value="formState.monthlyFee">
                    </InputNumber>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('public.name.createTime')" name="createTime">
                    <Input
                        :disabled="true"
                        :placeholder="$t('public.name.createTime')"
                        v-model:value="formState.createTime">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('public.name.createBy')" name="createBy">
                    <Input
                        :disabled="true"
                        :placeholder="$t('public.name.createBy')"
                        v-model:value="formState.createBy">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('public.name.updateTime')" name="updateTime">
                    <Input
                        :disabled="true"
                        :placeholder="$t('public.name.updateTime')"
                        v-model:value="formState.updateTime">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('public.name.updateBy')" name="updateBy">
                    <Input
                        :disabled="true"
                        :placeholder="$t('public.name.updateBy')"
                        v-model:value="formState.updateBy">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="22">
                  <FormItem :label="$t('superTenantManage.field.remark')" name="remarkRole">
                    <Textarea :placeholder="$t('superTenantManage.validate.required.remark')"
                              v-model:value="formState.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "CompanyModel",
};
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  Textarea, message, RangePicker, Button, DatePicker
} from "ant-design-vue";
import {ref, reactive} from 'vue'
import {useI18n} from "vue-i18n";
import {
  companyGetInfoByTid,
  companyAddInfo,
  companyEditInfo,
  companyExportExcelBymonthlyFee
} from "@/api/company";
import dayjs from 'dayjs';

const i18n = useI18n()

const refForm = ref();
let valueTime = ref()
let exportLoading = ref(false)
let visible = ref(false)
let confirmLoading = ref(false)
const formState = ref({})
const validateRules = {
  name: [
    {
      required: true,
      message: i18n.t("companyManage.validate.name"),
      trigger: "change",
    },
  ],
  attn: [
    {
      required: true,
      message: i18n.t("companyManage.validate.attn"),
      trigger: "change",
    },
  ],
  tel: [
    {
      required: true,
      message: i18n.t("companyManage.validate.tel"),
      trigger: "change",
    },
  ],
  address: [
    {
      required: true,
      message: i18n.t("companyManage.validate.address"),
      trigger: "change",
    },
  ],
  email: [
    {
      required: true,
      // type: "email",
      message: i18n.t("companyManage.validate.email"),
      trigger: "change",
    },
  ],
  invoiceDate: [
    {
      required: true,
      message: i18n.t("companyManage.validate.invoiceDate"),
      trigger: "change",
    },
  ],
  invoiceNo: [
    {
      required: true,
      message: i18n.t("companyManage.validate.invoiceNo"),
      trigger: "change",
    },
  ],
  contactPerson: [
    {
      required: true,
      message: i18n.t("companyManage.validate.contactPerson"),
      trigger: "change",
    },
  ],
  contactTel: [
    {
      required: true,
      message: i18n.t("companyManage.validate.contactTel"),
      trigger: "change",
    },
  ],
  contactEmail: [
    {
      required: true,
      // type: "email",
      message: i18n.t("companyManage.validate.contactEmail"),
      trigger: "change",
    },
  ],
  monthlyFee: [
    {
      required: true,
      message: i18n.t("companyManage.validate.monthlyFee"),
      trigger: "change",
    },
  ],
}
const tId = ref(0)

const onShowVisible = (tid) => {
  tId.value = tid
  _resetData()
  visible.value = true
  getInfo()
  formState.value.tId = tid
}
const onCancel = () => {
  visible.value = false
}
const getInfo = () => {
  // 获取后台数据
  const api = new companyGetInfoByTid()
  api.get({tId: tId.value}).then(response=>{
    if (0 === parseInt(response.data.code)) {
      if (response.data.data !== null && response.data.data !== undefined) {
        let res = response.data.data
        if (res.invoiceDate !== null && res.invoiceDate !== undefined) {
          res.invoiceDate = dayjs(res.invoiceDate, "YYYY/MM/DD")
        }
        formState.value = res
      }
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  }).finally(() => {
  })
}

const handlePanelChange = (val) => {
  // console.log("================", val)
}
// 提交
const onSubmit = (val) => {
  refForm.value.validate().then(() => {
    confirmLoading.value = true
    let api
    if (formState.value.id === undefined) {
      api = new companyAddInfo()
    } else {
      api = new companyEditInfo()
    }
    let f = {...formState.value}
    if (f.invoiceDate !== undefined) {
      f.invoiceDate = dayjs(f.invoiceDate).format("YYYY/MM/DD")
    }
    api.post(f).then(response => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"))
        getInfo()
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    }).finally(()=>{
      confirmLoading.value = false
    })
  })
}
const _resetData = () => {
  formState.value = {};
}
// 导出
const exportExcelBymonthlyFee = () => {
  if (valueTime.value === undefined) {
    message.error(i18n.t('public.promptsToSelectTime'))
    return false
  }
  let inTime = dayjs(valueTime.value[0]).format("YYYY/MM/DD")
  let toTime = dayjs(valueTime.value[1]).format("YYYY/MM/DD")
  exportLoading.value = true
  const api = new companyExportExcelBymonthlyFee()
  api.getDownLoad({tId: tId.value, inTime, toTime}).then((response)=>{}).finally(f => {
    exportLoading.value = false
  })
}

defineExpose({
  onShowVisible,
});
</script>
