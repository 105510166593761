<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('region.table.name')" name="regName">
                  <Input :placeholder="$t('region.validate.name')" v-model:value="searchForm.regName"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('meter.table.name')" name="metName">
                  <Input :placeholder="$t('meter.validate.required.name')" v-model:value="searchForm.metName"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.charge_box_id')" name="charge_box_id">
                  <Input :placeholder="$t('chargingStation.table.charge_box_id')" v-model:value="searchForm.chargeBoxId"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('connector.table.connector_id')" name="charge_box_id">
                  <Input :placeholder="$t('connector.table.connector_id')" v-model:value="searchForm.connectorId"
                         allowClear></Input>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('connector.table.status')" name="charge_box_id">
                  <Select
                      v-model:value="searchForm.status"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="statusList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.createTime')" name="order_time">
                  <RangePicker style="width: 100%" v-model:value="searchForm.valueTime"/>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>

          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button :loading="exportButtonLoading" type="primary" @click="exportToExcel">{{
                  $t('public.name.Export')
                }}
              </Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table
            :columns="columns"
            :dataSource="list"
            :scroll="{ x: 1000}"
            rowKey="id"
            :loading="loading"
            :pagination="tablePagination"
            @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column, record }">
            <template v-if="column.dataIndex === 'actionTitle'">
              <span>
                {{ $t("public.table.action") }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">
                  {{ $t("public.name.detail") }}
                </a>
              </div>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'status'">
              <div>
                <Tag v-if="record.status === 1" color="green">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else-if="record.status === 2 || record.status === 3 || record.status === 4 || record.status === 5 || record.status === 6" color="orange">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else-if="record.status === 9" color="pink">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else color="red">{{ statusList.find(item => item.value === record.status).label }}</Tag>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
  </Row>
  <ConLogDetail ref="refConLogDetail" @updateList="get_list"></ConLogDetail>
</template>

<script>
export default {
  name: "ConnectorLogManage"
}
</script>
<script setup>
import {onMounted} from "vue";
import {
  TypographyTitle,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  Tag,
  Select,
  RangePicker
} from "ant-design-vue";
import moment from "moment";

import ConLogDetail from './ConLogDetail.vue'

import dayjs from "dayjs";
import {ref, reactive, toRefs} from "vue";
import {ConnectorLogExportToExcel, ConnectorLogFindByPage} from "@/api/connectorLog"
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const i18n = useI18n()

let refConLogDetail = ref()

let loading = ref(false)
let exportButtonLoading = ref(false)
const statusList = reactive([
  {value: 1, label: 'Available'},
  {value: 2, label: 'Preparing'},
  {value: 3, label: 'Charging'},
  {value: 4, label: 'SuspendedEVSE'},
  {value: 5, label: 'SuspendedEV'},
  {value: 6, label: 'Finishing'},
  {value: 7, label: 'Reserved'},
  {value: 8, label: 'Unavailable'},
  {value: 9, label: 'Faulted'},
  {value: 10, label: 'Disconnect'}
])
const columns = reactive([
  {
    title: i18n.t("chargingStation.table.id_cha_poi"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    dataIndex: "id",
    key: "id"
  },
  {
    title: i18n.t("region.table.name"),
    width: 120,
    ellipsis: true,
    dataIndex: "regName",
    key: "regName"
  },
  {
    title: i18n.t("meter.table.name"),
    width: 120,
    ellipsis: true,
    dataIndex: "metName",
    key: "metName"
  },
  {
    title: i18n.t("chargingStation.table.charge_box_id"),
    width: 120,
    ellipsis: true,
    dataIndex: "chargeBoxId",
    key: "chargeBoxId"
  },
  {
    title: i18n.t("connector.table.connector_id"),
    width: 120,
    ellipsis: true,
    dataIndex: "connectorId",
    key: "connectorId"
  },
  {
    title: i18n.t("connector.table.status"),
    width: 80,
    ellipsis: true,
    dataIndex: "status",
  },
  {
    title: i18n.t("eventLog.table.log"),
    width: 180,
    ellipsis: true,
    dataIndex: "logInfo",
    key: "logInfo"
  },
  // {
  //   title: i18n.t("chargingStation.table.tenant_key"),
  //   width: 120,
  //   ellipsis: true,
  //   dataIndex: "tenantKey",
  //   key: "tenantKey"
  // },
  {
    title: i18n.t("public.table.created_at"),
    width: 120,
    ellipsis: true,
    fixed: "right",
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    key: "action",
    width: 80,
    ellipsis: true,
    dataIndex: "action",
    fixed: "right"
  },
])

let searchData = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: [],
})
const {searchForm, tablePagination, list} = toRefs(searchData)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list()
}

function search_submit() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function exportToExcel() {
  exportButtonLoading.value = true;
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const api = new ConnectorLogExportToExcel();
  // 格式化时间
  api.getDownLoad(args).then((res) => {
    exportButtonLoading.value = false;
  });
}

function get_list() {
  loading.value = true;
  const api = new ConnectorLogFindByPage();

  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...args
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function detail(txt) {
  refConLogDetail.value?.detailShow(txt)
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.ConnectorLogManage'));

onMounted(() => {
  get_list();
})
defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>
