<template>
  <Modal
      id="RenewEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.consumeType.6')"
      destroyOnClose
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('balanceLog.table.id_bal_log')" name="idUsePla">
                    <InputNumber v-model:value="form.idUsePla" style="width: 100%;" disabled></InputNumber>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('userPlan.table.total')" name="total">
                    <InputNumber v-model:value="form.total" style="width: 100%;" defaultValue="0" min="0" :max="inputMaxMonth" @change="onChange" ></InputNumber>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('userPlan.table.amount')" name="amount">
                    <InputNumber v-model:value="amount" style="width: 100%;" disabled></InputNumber>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('userPlan.table.prePayData')" name="prePayData">
                    <DatePicker
                        picker="month"
                        :placeholder="$t('public.promptsToSelectTime')"
                        valueFormat="YYYY-MM"
                        style="width: 100%"
                        v-model:value="prePayData"
                        :popupStyle="{ zIndex: 9999999950 }"
                        disabled>
                    </DatePicker>
                  </FormItem>
                </Col>

                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "RenewEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  message,
  Select,
  SelectOption,
  DatePicker,
  Input,
  InputNumber,
  Textarea,
  Tag
} from "ant-design-vue";
import {ref, reactive, toRefs, createVNode} from "vue";
import {useI18n} from "vue-i18n"
import {
  UserPlanEditStatus,
  UserPlanGetRenewAmount,
  UserPlanGetRenewDate,
  UserPlanGetRenewMonth,
  UserPlanRenewUserPlanByAdmin
} from "@/api/userPlan";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()
let visible = ref(false)
let actionType = ref('')

let inputMaxMonth = ref(0)
let amount = ref(0)
let prePayData = ref(undefined)

const validateRules = reactive({
  idUsePla: [
    {
      required: true,
      message: i18n.t("userPlan.validate.id_use"),
      trigger: "change",
      type: "number",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
})

const data = reactive({
  form: {
    idUsePla: undefined,
    total: 0,
    remark: ''
  },
})
const {form} = toRefs(data)

async function editShow(obj) {
  _resetData();
  const api = new UserPlanGetRenewMonth();
  api.get({idUsePla: obj.idUsePla}).then(response => {
    if (0 === parseInt(response.data.code)) {
      inputMaxMonth.value = response.data.data < 0 ? 0 : response.data.data
      fGet(obj.idUsePla)
      form.value.idUsePla = obj.idUsePla;
      visible.value = true;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

function onChange(e) {
  if (e !== undefined && e !== null) {
    fGet(form.value.idUsePla, e)
  } else {
    fGet(form.value.idUsePla)
  }
}

function fGet(idUsePlan, total){
  const api = new UserPlanGetRenewAmount();
  api.get({idUsePla: idUsePlan, total: total}).then(response => {
    if (0 === parseInt(response.data.code)) {
      amount.value = response.data.data
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })

  const api1 = new UserPlanGetRenewDate();
  api1.get({idUsePla: idUsePlan, total: total}).then(response => {
    if (0 === parseInt(response.data.code)) {
      prePayData.value = response.data.data
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

function submit() {
  refForm.value.validate().then(() => {
    Modal.confirm({
      title: i18n.t("public.name.are_you_sure_operate") + "?",
      content: i18n.t("public.name.operate_remind") + "！",
      icon: createVNode(ExclamationCircleOutlined),
      okText: i18n.t("public.name.yes"),
      okType: "danger",
      cancelText: i18n.t("public.name.no"),
      onOk: () => {
        if (amount.value <= 0) {
          message.success(i18n.t("errorMsg.500404"));
        } else {
          const api = new UserPlanRenewUserPlanByAdmin();
          api.post(form.value).then(response=>{
            if (0 === parseInt(response.data.code)) {
              message.success(i18n.t("succeedMsg.0"));
              emits("updateList", response.data.data);
              visible.value = false;
            } else {
              if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
            }
          })
        }
      }
    })
  }).catch(() => {});
}

function _resetData() {
  amount.value = 0
  form.value = {
    idUsePla: undefined,
    total: 0,
    remark: ''
  }
}

defineExpose({
  editShow
});

</script>

<style lang="less">
</style>
