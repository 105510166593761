<template>
  <Modal id="PersonalEdit" :open="visible" @cancel="visible = !visible" @ok="send" :title="$t('public.name.personal')" :zIndex="9999999900">
    <Form ref="member_form" :model="data" layout="vertical" :rules="validateRules">
      <Row :gutter="[16,16]">
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.name')" name="name">
            <Input :placeholder="$t('public.field.name')" v-model:value="data.name"></Input>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('member.field.birthday')" name="birthday">
            <DatePicker v-model:value="data.birthday" :popupStyle="{zIndex: 9999999950}" :style="{width:'100%'}" />
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('member.field.sex')" name="sex">
            <Select v-model:value="data.sex" :placeholder="$t('public.placeholder.please_select')" :dropdownStyle="{zIndex: 9999999950}">
              <SelectOption :value="1">{{ $t('public.sex.1') }}</SelectOption>
              <SelectOption :value="2">{{ $t('public.sex.2') }}</SelectOption>
              <SelectOption :value="3">{{ $t('public.sex.3') }}</SelectOption>
            </Select>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.email')" name="email">
            <Input :placeholder="$t('public.field.email')" v-model:value="data.email"></Input>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.phone_country_code')" name="phone_country_code">
            <Input :placeholder="$t('public.field.phone_country_code')" v-model:value="data.phone_country_code"></Input>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.phone')" name="phone">
            <Input :placeholder="$t('public.field.phone')" v-model:value="data.phone"></Input>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.status')" name="status">
            <Select v-model:value="data.status" :placeholder="$t('public.placeholder.please_select')" :dropdownStyle="{zIndex: 9999999950}">
              <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
              <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
            </Select>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.field.portrait')" name="portrait">
            <UploadImage :url="data.portrait" @new_image_url="_get_new_image_url" ref="upload_image" />
          </FormItem>
        </Col>
      </Row>
    </Form>
  </Modal>
</template>


<script>
import { Modal, Row, Col, Form, Input, Select, DatePicker, message } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

import UploadImage from "@/components/Common/UploadImage";
import moment from "moment";

export default {
  name: "PersonalEdit",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    InputPassword: Input.Password,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    LoadingOutlined,
    PlusOutlined,
    UploadImage,
  },
  data() {
    return {
      visible: false,
      upLoading: false,
      portraitShow: "",
      data: {
        name: "",
        sex: 3,
        phone_country_code: "",
        phone: "",
        email: "",
        portrait: "",
        birthday: "",
        status: 1,
      },
      validateRules: {
        name: [
          {
            required: true,
            message: this.$t("public.validate.required.name"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("public.validate.required.email"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async show() {
      this._resetData();
      let api = new loginApi();
      await api.get().then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.data = res.data.data;
          this.data.birthday = moment(res.data.data.birthday * 1000);
        }
      });
      this.visible = true;
    },
    // send put
    send() {
      let api = new personalApi();
      let param = { ...this.data };
      delete param.token;
      if (param.birthday) {
        param.birthday = param.birthday.unix();
      }
      this.$refs["member_form"]
        .validate()
        .then(() => {
          param.put_type = "message";
          api.put(param).then((response) => {
            if (1 == parseInt(response.data.code)) {
              message.success(this.$t("succeedMsg.0"));
              this.$store.commit("setName", param.name);
              this.$store.commit("setPortrait", param.portrait);
              this.visible = false;
            } else {
              if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
            }
          });
        })
        .catch();
    },
    // reset data
    _resetData() {
      this.data = {
        name: "",
        sex: 3,
        phone_country_code: "",
        phone: "",
        email: "",
        portrait: "",
        birthday: "",
        status: 1,
      };
    },
    _get_new_image_url(value) {
      this.data.portrait = value;
    },
  },
}
</script>

<style lang="less">
</style>