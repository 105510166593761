<template>
  <Row id="AppVersion">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form ref="appVersionForm" :model="form" layout="vertical" :rules="validateRules">
            <Row :gutter="30">
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.androidVersion')" name="androidVersion">
                  <Input :placeholder="$t('appVersionManage.field.androidVersion')"
                         v-model:value="form.androidVersion"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.androidDownloadLink')" name="androidDownloadLink">
                  <Input :placeholder="$t('appVersionManage.field.androidDownloadLink')"
                         v-model:value="form.androidDownloadLink"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.androidComplieNumber')" name="androidComplieNumber">
                  <Input :placeholder="$t('appVersionManage.field.androidComplieNumber')"
                         v-model:value="form.androidComplieNumber"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.iosVersion')" name="iosVersion">
                  <Input :placeholder="$t('appVersionManage.field.iosVersion')" v-model:value="form.iosVersion"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.iosBundleId')" name="iosBundleId">
                  <Input :placeholder="$t('appVersionManage.field.iosBundleId')"
                         v-model:value="form.iosBundleId"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.iosComplieNumber')" name="iosComplieNumber">
                  <Input :placeholder="$t('appVersionManage.field.iosComplieNumber')"
                         v-model:value="form.iosComplieNumber"></Input>
                </FormItem>
              </Col>

              <Col :xs="24" :sm="10">
                <FormItem :label="$t('appVersionManage.field.isForcedUpdate')" name="isForcedUpdate">
                  <!--                  <Input :placeholder="$t('appVersionManage.field.isForcedUpdate')" v-model:value="form.isForcedUpdate"></Input>-->
                  <Select v-model:value="form.isForcedUpdate" :placeholder="$t('appVersionManage.field.isForcedUpdate')"
                          :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="10">
                <FormItem :label="$t('balanceLog.table.payment_method')" name="hasPay">
                  <Select
                      v-model:value="form.hasPay"
                      mode="multiple"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="options"
                      @change="handleChange"
                  ></Select>
                </FormItem>
              </Col>

              <Col :xs="24" :sm="10" class="text-right">
                <Button type="primary" :loading="loading" @click="search_submit">{{ $t('public.name.submit') }}</Button>&nbsp;
              </Col>
            </Row>
          </Form>
        </Col>

      </Row>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "AppVersionManage",
}
</script>
<script setup>
import {
  Row,
  Col,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  SelectOption,
  message
} from "ant-design-vue";
import {SettingsAdd, SettingsGetByTag} from "@/api/settings"
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const i18n = useI18n()
const store = useStore()

const validateRules = {
  androidVersion: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.androidVersion"),
      trigger: "change",
    },
  ],
  androidDownloadLink: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.androidDownloadLink"),
      trigger: "change",
    },
  ],
  androidComplieNumber: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.androidComplieNumber"),
      trigger: "change",
    },
  ],
  iosVersion: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.iosVersion"),
      trigger: "change",
    },
  ],
  iosBundleId: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.iosBundleId"),
      trigger: "change",
    },
  ],
  iosComplieNumber: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.iosComplieNumber"),
      trigger: "change",
    },
  ],
  isForcedUpdate: [
    {
      required: true,
      message: i18n.t("appVersionManage.validate.required.isForcedUpdate"),
      trigger: "change",
    },
  ],

}

let appVersionForm =  ref()
let form = ref({})
let loading = ref(false)
let hasPay = ref()

const options = [
  {label: "wallet", value: '1'},
  {label: "yedpay", value: '2'},
  {label: "mpay", value: '3'},
  {label: "chinaums", value: '4'},
]

function get_list() {
  let api = new SettingsGetByTag();
  api.get({tag: "appVersion"}).then((response, any) => {
    if (0 === parseInt(response.data.code)) {
      form.value = JSON.parse(response.data.data.value)
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function search_submit() {
  appVersionForm.value?.validate().then(() => {
        loading.value = true
        let api = new SettingsAdd();
        api.post({tag: "appVersion", value: JSON.stringify(form.value)}).then(response => {
          loading.value = false
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
          } else {
            if (response.data.code !== undefined) {
              message.error(i18n.t("errorMsg." + response.data.code));
            }
          }
        });
      })
      .catch(() => {
      });
}

function handleChange(v) {}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.AppVersionManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
