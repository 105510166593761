/**
 * @Auther    william
 * @Name      state get function
 * @Note      
 * @Time      2021/04/13 16:07
 */

let getters = {
    // get all state message
    getAll(state) {
        return state
    },
    // get axios token
    getToken(state) {
        return state.token
    },
    // get logo
    getLogo(state) {
        return state.logo
    },
    // get dsKey
    getDsKey(state) {
        return state.dsKey
    },
    // get dsKey
    getTenantKey(state) {
        return state.tenantKey
    },
    // get language
    getLanguage(state) {
        return state.language
    },
    // get copy right message
    getCopyRight(state) {
        return state.copyRight
    },
    // get noImage
    getNoImage(state) {
        return state.noImage
    },
    // get noImage
    getIsAdmin(state) {
        return state.isAdmin === 1
    },
    getIsSuperAdmin(state) {
        return state.isAdmin === 1 && state.userType === '1'
    },
    // get navList
    getNavList(state) {
        return state.navList
    },
    // get some user message
    getUserMsg(state) {
        return {
            username: state.username,
            idRol: state.idRol,
            userType: state.userType,
            userTenantKey: state.userTenantKey,
            portrait: state.portrait,
            lastIp: state.lastIp,
            lastTime: state.lastTime,
        }
    },
    // get theme msg
    getTheme(state) {
        return state.theme
    },
    getType(state) {
        return state.type
    },
    getVersion(state) {
        return state.version
    },
    getPermissionList(state) {
        return state.permissionList
    },
    getPageTitle(state) {
        return state.pageTitle
    },
}

export default getters