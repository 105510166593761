import {Base} from "@/api/Base"

const api = "message"

export class MessageGetListByPage extends Base {
    path = api + "/getListByPage"
}

export class MessageGetUnReadCount extends Base {
    path = api + "/getUnReadCount"
}

export class MessageChangeReadStatus extends Base {
    path = api + "/changeReadStatus"
}

export class MessageGetInfo extends Base {
    path = api + "/getInfo"
}

export class MessageDelete extends Base {
    path = api + "/delInfo"
}

export class MessageEdit extends Base {
    path = api + "/editInfo"
}
