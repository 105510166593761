<template>
  <Modal
      id="ConnectorEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="connectorForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <!--              <Row justify="space-around">-->
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('connector.field.name')" name="name">
                    <Input :placeholder="$t('connector.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('connector.field.connector_id')" name="connectorId">
                    <InputNumber
                        :disabled="actionType === 'edit'"
                        :placeholder="$t('connector.validate.required.connector_id')"
                        v-model:value="form.connectorId"
                        style="width: 100%"
                    >
                    </InputNumber>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('connector.field.power')" name="power">
                    <InputNumber
                        :min="1" :max="9999"
                        :placeholder="$t('connector.validate.required.power')"
                        v-model:value="form.power"
                        style="width: 100%"
                    >
                    </InputNumber>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('connector.field.type')" name="type">
                    <Select
                        v-model:value="form.type"
                        :placeholder="$t('connector.validate.required.type')"
                        :options="chargingPortTypeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem v-if="actionType !== 'quickAdd'" :label="$t('connector.field.id_cha_poi')" name="idChaPoi">
                    <Select
                        v-model:value="form.idChaPoi"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="chaOptions"
                        :filter-option="filterOption"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <!--                  <FormItem v-if="isAdmin" :label="$t('connector.field.tenant_key')" name="tenantKey">-->
                  <!--                    <Input :placeholder="$t('connector.validate.required.tenant_key')"-->
                  <!--                           v-model:value="form.tenantKey">-->
                  <!--                    </Input>-->
                  <!--                  </FormItem>-->
                </Col>
              </Row>


              <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              <!--              </Row>-->
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "ConnectorEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
} from "ant-design-vue";
import {ConnectorAdd, ConnectorEdit} from "@/api/connector";
import {ChargingStationGetSelectList} from "@/api/chargingStation";
import {useStore} from "vuex";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const emits = defineEmits(['updateList'])

const connectorForm = ref()

const isAdmin = store.getters.getIsAdmin

let visible = ref(false)
let title = ref("")
let actionType = ref("")

const statusList = [
  {value: 1, label: 'Available'},
  {value: 2, label: 'Preparing'},
  {value: 3, label: 'Charging'},
  {value: 4, label: 'SuspendedEVSE'},
  {value: 5, label: 'SuspendedEV'},
  {value: 6, label: 'Finishing'},
  {value: 7, label: 'Reserved'},
  {value: 8, label: 'Unavailable'},
  {value: 9, label: 'Faulted'},
  {value: 10, label: 'Disconnect'}
]
const chargingPortTypeList = [
  {value: 0, label: 'Type 1'},
  {value: 1, label: 'Type 2'},
  {value: 2, label: 'CHAdeMO'},
  {value: 3, label: 'CCS 1'},
  {value: 4, label: 'CCS 2'},
]
const validateRules = {
  idCon: [
    {
      required: true,
      message: i18n.t("connector.validate.required.id_con"),
      trigger: "change",
      type: "number",
    },
  ],
  idChaPoi: [
    {
      required: true,
      message: i18n.t("connector.validate.required.id_cha_poi"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("connector.validate.required.name"),
      trigger: "change",
    },
  ],
  connectorId: [
    {
      required: true,
      message: i18n.t("connector.validate.required.connector_id"),
      trigger: "change",
      type: "number",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("connector.validate.required.status"),
      trigger: "change",
      type: "number",
    },
  ],
  tenantKey: [
    {
      required: false,
      message: i18n.t("connector.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
  power: [
    {
      required: true,
      message: i18n.t("connector.validate.required.power"),
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: i18n.t("connector.validate.required.type"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  chaOptions: [],
  searchForm: {},
  form: {
    idCon: undefined,
    idChaPoi: undefined,
    name: undefined,
    connectorId: undefined,
    status: undefined,
    tenantKey: undefined,
    power: undefined,
    type: undefined,
  },
})
const {chaOptions, searchForm, form} = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add")
  actionType.value = "add";
  _resetData();
  getChargingStation();
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit")
  _resetData();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  getChargingStation();
  visible.value = true;
}

function quickAddShow(idChaPoi) {
  title.value = i18n.t("public.name.add")
  actionType.value = "quickAdd";
  _resetData();

  if (idChaPoi) {
    form.value.idChaPoi = idChaPoi
  }
  getChargingStation();
  visible.value = true;
}

function submit() {
  connectorForm.value?.validate().then(() => {
        //没有传父id，则代表新增时的状况，直接回调对象，不请求，由新增的api自行按数组添加数据
        if (!form.value.idChaPoi) {
          emits("updateList", form.value);
          visible.value = false;
          return;
        }
        let api;
        switch (actionType.value) {
          case "add":
            api = new ConnectorAdd();
            break;
          case "quickAdd":
            api = new ConnectorAdd();
            break;
          case "edit":
            api = new ConnectorEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then(response => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", form.value);
            visible.value = false;
          } else {
            if (response.data.code !== undefined) {
              message.error(i18n.t("errorMsg." + response.data.code));
            }
          }
        });
      }).catch(() => {});
}

function filterOption(input, option){
  return option.label.indexOf(input) >= 0;
}

function getChargingStation(){
  const api = new ChargingStationGetSelectList();
  api.get({
    ...searchForm.value
  }).then(res => {
    if (0 === parseInt(res.data.code)) {
      chaOptions.value = res.data.data;
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  });
}

function _resetData(){
  form.value = {
    idCon: undefined,
    idChaPoi: undefined,
    name: undefined,
    connectorId: undefined,
    status: undefined,
    tenantKey: undefined,
    power: undefined,
    type: undefined,
  };
}

defineExpose({
  addShow,
  quickAddShow,
  editShow
})
</script>
