import { createApp } from 'vue';
import 'ant-design-vue/dist/reset.css';
import App from './App';
import store from '@/store'
import router from '@/router';
import i18n from '@/i18n'
import "@/assets/less/public.less"
import mixin from '@/mixin';
import VueQrcode from '@chenfengyuan/vue-qrcode';

const app = createApp(App);
app.component(VueQrcode.name, VueQrcode);
//挂载方法到实例上
app.config.globalProperties.$mixin = mixin
app.use(i18n).use(store).use(router).mixin(mixin).mount('#app');