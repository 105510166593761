<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('serviceManage.table.reId')" name="reId">
                  <Select
                      v-model:value="searchForm.reId"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="options"
                      :filter-option="filterOption"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('serviceManage.table.type')" name="type">
                  <Select v-model:value="searchForm.type" :placeholder="$t('serviceManage.validate.type')"
                          :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('serviceManage.type.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('serviceManage.type.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('serviceManage.type.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('serviceManage.type.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('serviceManage.table.name')" name="name">
                  <Input :placeholder="$t('serviceManage.validate.name')" v-model:value="searchForm.name"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('serviceManage.table.address')" name="address">
                  <Input :placeholder="$t('serviceManage.validate.address')" v-model:value="searchForm.address"></Input>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('serviceManage.table.status')" name="status">
                  <Select v-model:value="searchForm.status" :placeholder="$t('serviceManage.validate.status')"
                          :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8"></Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list"
               :scroll="{x: 1500}" rowKey="id"
               :loading="loading"
               :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('ServiceManage.Add')" type="primary" size="small" shape="circle"
                        @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('ServiceManage.Edit')" type="primary" size="small" @click="edit(record)">
                    {{ $t("public.name.edit") }}
                  </Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('ServiceManage.Delete')" type="primary" danger size="small"
                          @click="del(record.id)">{{ $t("public.name.del") }}
                  </Button>
                </div>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <ServiceManageDetail ref="refServiceManageDetail"></ServiceManageDetail>
    <ServiceManageEdit ref="refServiceManageEdit" @updateList="get_list" :options="options"></ServiceManageEdit>
  </Row>
</template>
<script>
export default {
  name: "ServiceManageManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  SelectOption,
  message
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {ServiceGetListByPage, ServiceDel} from "@/api/service";
import {RegionGetSelectList} from "@/api/region";
import ServiceManageDetail from "./Detail";
import ServiceManageEdit from "./Edit";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refServiceManageDetail = ref()
const refServiceManageEdit = ref()

let value = ref()
let loading = ref(false)

let columns = [
  {
    title: i18n.t("public.name.index"),
    fixed: "left",
    width: 60,
    customRender: ({index}) => `${(tablePagination.value.current - 1) * tablePagination.value.pageSize + index + 1}`
  },
  {title: i18n.t("serviceManage.table.id"), fixed: "center", dataIndex: "id", key: "id"},
  {
    title: i18n.t("serviceManage.table.type"),
    dataIndex: "type",
    key: "type",
    customRender: ({text}) => {
      let ty
      switch (text) {
        case 0:
          ty = i18n.t('serviceManage.type.0')
          break;
        case 1:
          ty = i18n.t('serviceManage.type.1')
          break;
        case 2:
          ty = i18n.t('serviceManage.type.2')
          break;
        case 3:
          ty = i18n.t('serviceManage.type.3')
          break;
        default:
          ty = text
      }
      return ty
    }
  },
  {
    title: i18n.t("serviceManage.table.status"),
    dataIndex: "status",
    key: "status",
    customRender: ({text}) => {
      let st
      switch (text) {
        case 0:
          st = i18n.t("public.isTrue.0")
          break;
        case 1:
          st = i18n.t("public.isTrue.1")
          break;
        default:
          st = text
      }
      return st
    }
  },
  {title: i18n.t("serviceManage.table.name"), dataIndex: "name", key: "name"},
  {title: i18n.t("serviceManage.table.address"), dataIndex: "address", key: "address"},
  {title: i18n.t("serviceManage.table.description"), dataIndex: "description", key: "description"},
  {key: "action", fixed: "right", dataIndex: "action"},
]

const data = reactive({
  options: [],
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {options, searchForm, list, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  // 获取下拉列表
  getRegionSelectList()

  const api = new ServiceGetListByPage();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize
  }
  // if (route.query.idReg) {
  //   getData.idReg = i18n.route.query.idReg
  //   this.value = i18n.route.query.idReg
  // }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refServiceManageDetail.value?.detailShow(obj);
}

function add() {
  refServiceManageEdit.value?.addShow(options.value);
}

function edit(obj) {
  refServiceManageEdit.value?.editShow(obj, options.value);
}

function del(id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ServiceDel();
      let l = []
      l.push(id)
      api.post({ids: l}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.ServiceManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
