<template>
<!--  <Button type="primary" @click="showDrawer">Open</Button>-->
  <Drawer
      :open="visible"
      class="custom-class"
      style="padding: 0"
      :title="drawerTitle"
      placement="right"
      @afterOpenChange="afterVisibleChange"
      @close="close"
  >
    <!--    :header="`${data.length} replies`"-->
    <List
        class="comment-list"
        item-layout="horizontal"
        :data-source="data"
    >
      <template #renderItem="{ item }">
        <BadgeRibbon :text="item.badgeText" :color="item.badgeColor">
          <ListItem>
            <Comment :author="item.author" :avatar="item.avatar">
              <template #actions>
<!--                <span v-for="(action, index) in item.actions" :key="index" @click="onClickMsg(action, index)">{{-->
<!--                    action-->
<!--                  }}</span>-->
                <span @click="onClickReadMsg(item.id, item.status)">
                  {{ item.status === 1 ? $t('public.msg.read') : $t('public.msg.unRead')}}
                </span>
                <span v-if="item.status === 1" @click="onClickDelMsg(item.id)"> {{ $t('public.name.del') }} </span>
              </template>
              <template #content>
                <p>
                  {{ item.content }}
                </p>
              </template>
              <template #datetime>
                <Tooltip :title="item.datetime">
                  <span>{{ item.datetime }}</span>
                </Tooltip>
                <span>&nbsp;</span>
                <Badge v-if="item.status === 0" color="red"/>
              </template>

            </Comment>
          </ListItem>
        </BadgeRibbon>
      </template>
    </List>
    <Pagination v-show="pageTotal > 20" v-model:current="page.pageCurrent" simple :total="page.pageTotal" style="text-align: center;margin-top: 5px" @change="pageChange"/>
  </Drawer>
</template>
<script>
export default {
  name: "MsgDrawer"
}
</script>
<script setup>
import {
  Drawer,
  List,
  ListItem,
  Comment,
  Badge,
  BadgeRibbon,
  Pagination, message, Tooltip
} from "ant-design-vue";
import {ref, reactive, toRefs} from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {MessageGetListByPage, MessageGetUnReadCount, MessageChangeReadStatus, MessageDelete} from "@/api/message";
import {useI18n} from "vue-i18n";

dayjs.extend(relativeTime);

const i18n = useI18n()

const emits = defineEmits(['onMsgCount'])

const unReadCount = ref(0);
const drawerTitle = ref('');

const visible = ref(false);
const page = reactive({
  data: [],
  pageCurrent: 1,
  pageSize: 10,
  pageTotal: 20,
})
const { data, pageCurrent, pageSize, pageTotal } = toRefs(page)

const pageChange = () => {
  const api = new MessageGetListByPage()
  api.get({
    pageCurrent:pageCurrent.value,
    pageSize: pageSize.value
  }).then(response=>{
    if (0 === parseInt(response.data.code)) {
      const dt = []
      response.data.data.data?.forEach(item => {
        let badgeText = ''
        let badgeColor = ''
        switch (item.tag) {
          case 'alarm':
            badgeText = i18n.t("public.msg.alarm")
            badgeColor = 'red'
            break
          case 'reminder':
            badgeText = i18n.t("public.msg.reminder")
            badgeColor = 'cyan'
            break
          case 'advertise':
            badgeText = i18n.t("public.msg.advertise")
            break
          default:
            badgeText = 'notify'
        }

        dt.push({
          id: item.id,
          status: item.status,

          author: item.subject,
          // avatar: 'https://joeschmoe.io/api/v1/random',
          content: item.content,
          datetime: item.createTime,
          badgeText: badgeText,
          badgeColor: badgeColor
        })
      })
      data.value = dt
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

const afterVisibleChange = bool => {
  // console.log('visible', bool);
}

const showDrawer = () => {
  getUnReadCount()
  pageChange()
  visible.value = true;
}

// 获取未读信息的条数
const getUnReadCount = () => {
  const api = new MessageGetUnReadCount()
  return api.get().then(response => {
    if (0 === parseInt(response.data.code)) {
      unReadCount.value =  response.data.data | 0
      drawerTitle.value = i18n.t("public.name.email") + '(' + unReadCount.value + ' ' + i18n.t("public.msg.unRead") + ')'
      emits('onMsgCount', unReadCount.value)
      return unReadCount.value
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

const onClickReadMsg = (id, st) => {
  const api = new MessageChangeReadStatus()
  api.get({id:id, status: st === 1 ? 0 : 1}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      getUnReadCount()
      pageChange()
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

const onClickDelMsg = (id) => {
  const api = new MessageDelete();
  let ids = []
  ids.push(id)
  api.post({ids: ids}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      getUnReadCount()
      pageChange()
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

function close() {
  visible.value = false
}

defineExpose({
  getUnReadCount,
  showDrawer,
  afterVisibleChange
});
</script>

<style>
/* 虽然显示灰色，但其实是起作用的样式，切勿删除*/
.ant-drawer-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>