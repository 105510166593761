<template>
  <Modal id="UserPlanDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('userPlan.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('user.table.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{data.phone}}</DescriptionsItem>
      <DescriptionsItem :label="$t('statisticsManage.table.identifier')">{{data.identifier}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.id_pla')">{{data.planName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.arrearsCount')">{{data.arrearsCount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.table.free_charging_time_limit')">{{data.currentlyFreeTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.start_date')">{{data.startDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.status')">{{status}}</DescriptionsItem>
      <DescriptionsItem :label="$t('userPlan.table.tenant_key')">{{data.tenantKey}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.name.title')">{{ data.idBalLogs }}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "BalUserPlanDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {UserPlanGetInfoByIdBal} from "@/api/userPlan";
import {ref, reactive} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()

let visible = ref(false)
const selectList = reactive({})
let data = reactive({})
let status = ref('')

async function detailShow(idBal) {
  const api = new UserPlanGetInfoByIdBal()
  api.get({idBal:idBal}).then(res => {
    const obj = res.data.data
    if (obj !== null) {
      switch (obj.status) {
        case 0:
          status.value = i18n.t('public.doingStatus.0')
          break;
        case 1:
          status.value = i18n.t('public.doingStatus.1')
          break;
        case 2:
          status.value = i18n.t('public.doingStatus.2')
          break;
        case 3:
          status.value = i18n.t('public.doingStatus.3')
          break;
        default:
          status.value
      }
      data = obj;
      visible.value = true;
    } else {
      console.log(res)
    }
  })
}

defineExpose({
  detailShow
});

</script>

<style lang="less">
</style>