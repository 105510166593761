import {Base} from "@/api/Base"

const api = "transactionLog"

export class TransactionLogAdd extends Base {
    path = api + "/add"
}

export class TransactionLogDelete extends Base {
    path = api + "/delete"
}

export class TransactionLogList extends Base {
    path = api + "/list"
}

export class TransactionLogEdit extends Base {
    path = api + "/edit"
}
