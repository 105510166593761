import {Base} from "@/api/Base"

const api = "userPlan"

export class UserPlanAdd extends Base {
    path = api + "/add"
}

export class UserPlanDelete extends Base {
    path = api + "/delete"
}

export class UserPlanList extends Base {
    path = api + "/list"
}

// 通过账变ID获取用户计划
export class UserPlanGetInfoByIdBal extends Base {
    path = api + "/getInfoByIdBal"
}

// 通过用户计划ID获取账变记录列表
export class UserPlanGetBalLogByIdBalLogs extends Base {
    path = api + "/getBalLogByIdBalLogs"
}

// 处理用户计划
export class UserPlanEditStatus extends Base {
    path = api + "/editStatus"
}

// 完成用户计划
export class UserPlanEditFinish extends Base {
    path = api + "/editFinish"
}

// 编辑用户计划
export class UserPlanEdit extends Base {
    path = api + "/edit"
}

// 获取最多可输入预支付月份
export class UserPlanGetRenewMonth extends Base {
    path = api + "/getRenewMonth"
}

// 获取应付款金额
export class UserPlanGetRenewAmount extends Base {
    path = api + "/getRenewAmount"
}

// 获取应付款月份
export class UserPlanGetRenewDate extends Base {
    path = api + "/getRenewDate"
}

// 提交付款（线下付款，此时是后台录入数据）
export class UserPlanRenewUserPlanByAdmin extends Base {
    path = api + "/renewUserPlanByAdmin"
}

// 提交付款（线下付款，此时是后台录入数据）
export class UserPlanStopUserPlanByAdmin extends Base {
    path = api + "/stopUserPlanByAdmin"
}
