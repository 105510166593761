<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('user.table.account')" name="account">
                  <Input :placeholder="$t('user.validate.required.account')" v-model:value="searchForm.account"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('waller.table.type')" name="type">
                  <Select v-model:value="searchForm.type" :placeholder="$t('waller.validate.type')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="1">{{ $t('waller.wType.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('waller.wType.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('waller.wType.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('waller.table.status')" name="status">
                  <Select v-model:value="searchForm.status" :placeholder="$t('waller.validate.status')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <!--                <Button type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>-->
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idCar" :loading="loading"
               :pagination="tablePagination" @change="chang_page">

          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <!--                <Button v-if="checkPermission('CarManage.Add')" type="primary" size="small" shape="circle" @click="add()">-->
                <!--                  <PlusOutlined />-->
                <!--                </Button>-->
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                </div>
              </div>
            </template>

            <template v-if="column.dataIndex === 'type'">
              <div>
                <Tag v-if="record.type === 1" color="orange">{{ $t('waller.wType.1') }}</Tag>
                <Tag v-else-if="record.type === 2" color="blue">{{ $t('waller.wType.2') }}</Tag>
                <Tag v-else-if="record.type === 3" color="green">{{ $t('waller.wType.3') }}</Tag>
                <Tag v-else>{{ record.type }}</Tag>
              </div>
            </template>

            <template v-if="column.dataIndex === 'status'">
              <div>
                <Tag v-if="record.status === 1" color="red">{{ $t("public.isTrue.1") }}</Tag>
                <Tag v-else color="green">{{ $t("public.isTrue.0") }}</Tag>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <WallerDetail ref="refWallerDetail"></WallerDetail>
  </Row>
</template>
<script>
export default {
  name: "WallerManage",
}
</script>
<script setup>
import {reactive, ref, toRefs} from "vue";
import {Row, Col, Table, Button, Form, Input, FormItem, Select, SelectOption, Tag} from "ant-design-vue";

import WallerDetail from "./WallerDetail";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {WalletGetByPage} from "@/api/waller";

const store = useStore()
const i18n = useI18n()

const refWallerDetail = ref()

let loading = ref(false)
let userList = ref([])

const columns = [
  {
    title: i18n.t("waller.table.walletId"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    dataIndex: "walletId",
    key: "walletId"
  },
  {title: i18n.t("waller.table.account"), width: 90, ellipsis: true, dataIndex: "account", key: "account"},
  {
    title: i18n.t("waller.table.type"),
    width: 80,
    ellipsis: true,
    dataIndex: "type",
  },
  {
    title: i18n.t("waller.table.status"),
    width: 80,
    ellipsis: true,
    dataIndex: "status",
  },
  {title: i18n.t("waller.table.balance"), width: 80, ellipsis: true, dataIndex: "balance", key: "balance"},
  {title: i18n.t("public.name.updateTime"), width: 80, ellipsis: true, dataIndex: "updateTime", key: "updateTime"},
  {key: "action", width: 180, ellipsis: true, fixed: "right", dataIndex: "action"},
]

const data = reactive({
  list: [],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new WalletGetByPage();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function detail(obj) {
  refWallerDetail.value?.detailShow(obj);
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  searchForm.value = {}
  get_list()
}

function exportToExcel() {

}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.WallerManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
