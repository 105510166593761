<template>
  <Modal
      id="BalanceLogEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="balanceLogForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('balanceLog.field.id_use')" name="idUse">
                    <Input :placeholder="$t('balanceLog.validate.required.id_use')"
                           v-model:value="form.account"
                           disabled
                    >
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('balanceLog.field.type')" name="type">
                    <Select
                        v-model:value="form.type"
                        :placeholder="$t('balanceLog.validate.required.type')"
                        :options="typeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                        disabled
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('balanceLog.field.amount')" name="amount">
                    <InputNumber
                        style="width: 100%"
                        v-model:value="form.amount"
                        :placeholder="$t('balanceLog.validate.required.amount')"
                        :step="0.01"
                    />
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UserBalanceLogEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  InputNumber,
  message,
  Select,
} from "ant-design-vue";
import {BalanceAddInfoByUserId} from "@/api/balanceLog";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const balanceLogForm = ref()

let visible = ref(false)
let title = ref("")
let actionType = ref("")
let userList = ref([])

const typeList = [
  {value: 1, label: i18n.t("public.consumeType.0")},
  {value: 2, label: i18n.t("public.consumeType.1")},
  {value: 3, label: i18n.t("public.consumeType.2")},
  {value: 4, label: i18n.t("public.consumeType.3")},
]
const statusList = [
  {value: 1, label: i18n.t("public.payStatus.0")},
  {value: 2, label: i18n.t("public.payStatus.3")}
]
const validateRules = {
  idBalLog: [
    {
      required: true,
      message: i18n.t("balanceLog.validate.required.id_bal_log"),
      trigger: "change",
      type: "number",
    },
  ],
  type: [
    {
      required: true,
      message: i18n.t("balanceLog.validate.required.type"),
      trigger: "change",
      type: "number",
    },
  ],
  amount: [
    {
      required: true,
      message: i18n.t("balanceLog.validate.required.amount"),
      trigger: "change",
      type: "number",
    },
  ],
  logTime: [
    {
      required: true,
      message: i18n.t("balanceLog.validate.required.log_time"),
      trigger: "change",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("balanceLog.validate.required.status"),
      trigger: "change",
      type: "number",
    },
  ],
}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

function addShow(obj) {
  title.value = i18n.t("balanceLog.name.title")
  actionType.value = "add";
  _resetData();
  form.value.idUse = obj.idUse
  form.value.account = obj.account
  form.value.type = 4
  visible.value = true;
}

function submit() {
  balanceLogForm.value?.validate().then(() => {
    const api = new BalanceAddInfoByUserId();
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

defineExpose({
  addShow
})
</script>

<style lang="less">
</style>
