<template>
  <Modal
      id="PartnerList"
      width="50%"
      :open="visible"
      @cancel="cancel"
      @ok="cancel"
      title="合作商列表"
      :footer="null"
      :destroyOnClose="true"
  >
    <Table :columns="columns" :dataSource="listView" :scroll="{ x: 800, y: 500 }" rowKey="id" :loading="loading" :pagination=false>
      <!-- 标题插槽-->
      <template #headerCell="{ column }">
        <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
        </template>
      </template>
      <!-- 内容插槽-->
      <template #bodyCell="{column, record}">
        <template v-if="column.dataIndex === 'partnerName'">
          <div v-if="record?.isEdit">
            <Input v-model:value="formData.partnerName" />
          </div>
          <div v-else>
            {{ record.partnerName }}
          </div>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <div v-if="record?.isEdit">
            <Button type="primary" size="small" @click="onSubmit()" :loading="onSubmitLoading">{{ $t("public.name.submit") }}</Button>
            <span>&nbsp;</span>
            <Button size="small" @click="edit(record, false)">{{ $t("public.name.cancel") }}</Button>
          </div>
          <div v-else>
            <Button type="primary" size="small" @click="edit(record, true)">
              {{ $t("public.name.edit") }}
            </Button>
            <span>&nbsp;</span>
            <Button type="primary" danger size="small" @click="del(record.partnerId)">{{ $t("public.name.del") }}
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </Modal>
</template>

<script setup>
import {
  Modal,
  Button,
  Table,
  Tag,
  Input, message
} from "ant-design-vue";
import {PlusOutlined} from "@ant-design/icons-vue";
import {ref, reactive, toRefs} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n"
import {ConnectorLogFindByPage} from "@/api/connectorLog";
import {
  ChargingStationDelPartner,
  ChargingStationGetPartnerList,
  ChargingStationSaveOrUpdatePartner
} from "@/api/chargingStation";
const i18n = useI18n()

let visible = ref(false)
let loading = ref(false)
let onSubmitLoading = ref(false)

const formData = ref({
  partnerId: undefined,
  partnerName: undefined,
})

const columns = [
  {
    title: "ID",
    width: 60,
    ellipsis: true,
    dataIndex: "partnerId",
    key: "partnerId"
  },
  {
    title: "Name",
    width: 90,
    ellipsis: true,
    dataIndex: "partnerName",
    key: "partnerName"
  },
  {
    title: "Amount",
    width: 90,
    ellipsis: true,
    dataIndex: "amount",
    key: "amount"
  },
  {key: "action", fixed: "right", width: 50, dataIndex:"action"},
]

const data = reactive({
  listView: [],
  listEdit: [],
})
const {listView, listEdit} = toRefs(data)

function onShow() {
  visible.value = true
  get_list()
}

function add() {
  // 校验是否有存在编辑
  if (listView.value.find((item=>item.isEdit===true))) return;

  formData.value = {}
  // 添加一行
  listView.value.push({
    partnerId: undefined,
    partnerName: undefined,
    isEdit: true
  })
}

function edit(record, isEdit) {
  if (record) {
    if (isEdit) {
      // 校验是否有存在编辑
      if (listView.value.find((item=>item.isEdit===true))) return;

      formData.value.partnerId = record.partnerId
      formData.value.partnerName = record.partnerName
    } else {
      formData.value = {}
      listView.value = listView.value.filter(item => {
        return item.partnerId !== undefined
      })
    }
    record.isEdit = isEdit
  }
}

function onSubmit(){
  if (formData.value.partnerName === undefined || formData.value.partnerName === "") {
    return
  }
  onSubmitLoading.value = true
  let api = new ChargingStationSaveOrUpdatePartner();
  api.post(formData.value).then(response => {
    if (0 === parseInt(response.data.code)) {
      get_list()
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  }).finally(() => {
    onSubmitLoading.value = false;
  });
}

function del(k){
  let api = new ChargingStationDelPartner();
  api.post({partnerId: k}).then(response => {
    if (0 === parseInt(response.data.code)) {
      get_list()
    }
  })
}

function cancel() {
  visible.value = false
}

function get_list() {
  loading.value = true
  listView.value = []
  loading.value = false
  const api = new ChargingStationGetPartnerList();
  api.get().then(response => {
    loading.value = false
    if (0 === parseInt(response.data.code)) {
      let ls = []
      response.data.data.forEach(item => {
        ls.push({
          ...item,
          isEdit: false
        })
      })
      listView.value = ls
    } else {
      listView.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

defineExpose({
  onShow,
});
</script>
