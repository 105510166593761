import {Base} from "@/api/Base"

const api = "notice"

export class NoticeGetListByPage extends Base {
    path = api + "/getListByPage"
}

export class NoticeGetInfo extends Base {
    path = api + "/getInfo"
}

export class NoticeAddInfo extends Base {
    path = api + "/addInfo"
}

export class NoticeDelete extends Base {
    path = api + "/delInfo"
}

export class NoticeEdit extends Base {
    path = api + "/editInfo"
}
