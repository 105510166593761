import {Base} from "@/api/Base"

const api = "regionService"

export class ServiceGetListByPage extends Base {
    path = api + "/getListByPage"
}

export class ServiceAdd extends Base {
    path = api + "/add"
}

export class ServiceGetInfo extends Base {
    path = api + "/getInfoById"
}

export class ServiceEdit extends Base {
    path = api + "/edit"
}

export class ServiceDel extends Base {
    path = api + "/delete"
}
