<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="4">
                <FormItem :label="$t('order.table.id_ord')" name="idOrd">
                  <Input :placeholder="$t('order.validate.id_ord')" v-model:value="searchForm.idOrd" allowClear></Input>
                </FormItem>
              </Col>
<!--              <Col :span="4">-->
<!--                <FormItem :label="$t('region.table.name')" name="regName">-->
<!--                  <Input :placeholder="$t('region.validate.name')" v-model:value="searchForm.regName"-->
<!--                         allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
<!--              <Col :span="4">-->
<!--                <FormItem :label="$t('meter.table.name')" name="metName">-->
<!--                  <Input :placeholder="$t('meter.validate.required.name')" v-model:value="searchForm.metName"-->
<!--                         allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->

              <Col :span="4">
                <FormItem :label="$t('meter.table.id_reg')" name="idReg">
                  <Select
                      v-model:value="searchForm.idReg"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="options"
                      :filter-option="filterOption"
                      @change="handleChange"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('chargingStation.table.id_met')" name="idMet">
                  <Select
                      v-model:value="searchForm.idMet"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="metOptions"
                      :filter-option="filterOption"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('user.table.account')" name="account">
                  <Input :placeholder="$t('user.table.account')" v-model:value="searchForm.account" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('chargingStation.table.charge_box_id')" name="charge_box_id">
                  <Input :placeholder="$t('chargingStation.table.charge_box_id')" v-model:value="searchForm.chargeBoxId"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="4">
                <!--                <FormItem label="ID" name="id">-->
                <!--                  <Input :placeholder="$t('authority.validate.required.id_aut')" v-model:value="searchForm.idOrd"></Input>-->
                <!--                </FormItem>-->
                <FormItem :label="$t('order.table.order_time')" name="valueTime">
                  <RangePicker style="width: 100%" v-model:value="searchForm.valueTime"/>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('order.table.type')" name="type">
                  <Select
                      v-model:value="searchForm.type"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="typeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('order.table.status')" name="status">
                  <Select
                      v-model:value="searchForm.status"
                      :placeholder="$t('order.validate.status')"
                      :options="statusList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('order.table.pay_type')" name="payType">
                  <Select
                      v-model:value="searchForm.payType"
                      :placeholder="$t('order.validate.pay_type')"
                      :options="payTypeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('order.table.payment_method')" name="paymentMethod">
                  <Input :placeholder="$t('order.table.payment_method')" v-model:value="searchForm.paymentMethod"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="4">
                <FormItem :label="$t('region.table.postpaid')" name="postpaid">
                  <Select
                      v-model:value="searchForm.postpaid"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="postpaidList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="4"></Col>
              <Col :span="4"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button type="primary" @click="exportToExecl">{{ $t('public.name.Export') }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{ x: 1500}" rowKey="idOrd"
               :loading="loading" :pagination="tablePagination" @change="chang_page">

          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <!-- <Button v-if="checkPermission('OrderManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
              </span>
            </template>
          </template>

          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'type'">
              <div>
                <Tag v-if="record.type === 1" color="cyan">{{ $t("public.chargeType.0") }}</Tag>
                <Tag v-else-if="record.type === 2" color="green">{{ $t("public.chargeType.1") }}</Tag>
                <Tag v-else-if="record.type === 3" color="orange">{{ $t("public.chargeType.2") }}</Tag>
                <Tag v-else>{{ record.type }}</Tag>
              </div>
            </template>

            <template v-if="column.dataIndex === 'status'">
              <div>
                <Tag v-if="record.status === 1" color="pink">{{ $t("public.payStatus.0") }}</Tag>
                <Tag v-else-if="record.status === 2" color="blue">{{ $t("public.payStatus.1") }}</Tag>
                <Tag v-else-if="record.status === 3" color="red">{{ $t("public.payStatus.2") }}</Tag>
                <Tag v-else-if="record.status === 4" color="green">{{ $t("public.payStatus.3") }}</Tag>
                <Tag v-else-if="record.status === 5" color="orange">{{ $t("public.payStatus.4") }}</Tag>
                <Tag v-else>{{ record.status }}</Tag>
              </div>
            </template>

            <template v-if="column.dataIndex === 'postpaid'">
              <div>
                <Tag v-if="record.postpaid === 1" color="cyan">{{ $t("public.isTrue.1") }}</Tag>
                <Tag v-else-if="record.postpaid === 2" color="orange">{{ $t("public.payStatus.3") }}</Tag>
                <Tag v-else color="green">{{ $t("public.isTrue.0") }}</Tag>
              </div>
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">
                  {{ $t("public.name.detail") }}
                </a>
                <Divider type="vertical"/>
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem v-if="record.idBalLog !== undefined && record.idBalLog !== null">
                        <Button type="default" size="small" @click="detailBal(record)">{{ $t("balanceLog.name.title") }}
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button type="primary" danger size="small" @click="editCancel(record)">{{
                            $t("public.consumeType.2")
                          }}
                        </Button>
                      </MenuItem>
                      <!--                    <MenuItem v-if="checkPermission('OrderManage.Edit')">-->
                      <!--                      <Button type="primary" size="small" @click="edit(text)">{{ $t("public.name.edit") }}</Button>-->
                      <!--                    </MenuItem>-->
                      <!--                    <MenuItem v-if="checkPermission('OrderManage.Delete')">-->
                      <!--                      <Button type="primary" danger size="small" @click="del(text.idOrd)">{{$t("public.name.del")}}</Button>-->
                      <!--                    </MenuItem>-->
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>


          </template>

          <template #actionTitle>
              <span>
                {{ $t("public.table.action") }}
                <!-- <Button v-if="checkPermission('OrderManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
              </span>
          </template>
        </Table>
      </div>
    </Col>
    <OrderDetail ref="refOrderDetail"></OrderDetail>
    <BalanceLogDetail ref="refBalanceLogDetail"></BalanceLogDetail>
    <CancelOrderEdit ref="refCancelOrderEdit" @updateList="get_list"></CancelOrderEdit>
  </Row>
</template>
<script>
export default {
  name: "OrderManage",
}
</script>
<script setup>
import {createVNode, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Tag,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  message,
  RangePicker,
  Divider
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {OrderList, OrderDelete, OrderExportToExcel} from "@/api/order";
import OrderDetail from "./OrderDetail";
import BalanceLogDetail from "./BalanceLogDetail";
import CancelOrderEdit from "./CancelOrderEdit";
import moment from "moment";
import dayjs from "dayjs";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";
import {MeterGetSelectListByidReg} from "@/api/meter";
import {RegionGetSelectList} from "@/api/region";

const store = useStore()
const i18n = useI18n()
const { proxy } = getCurrentInstance();

let loading = ref(false)
let list = ref([])
let options = ref([])
let metOptions = ref([])

const refOrderEdit = ref()
const refOrderDetail = ref()
const refBalanceLogDetail = ref()
const refCancelOrderEdit = ref()

const typeList = [
  {value: 1, label: i18n.t("public.chargeType.0")},
  {value: 2, label: i18n.t("public.chargeType.1")},
  {value: 3, label: i18n.t("public.chargeType.2")}
]
const statusList = [
  {value: 1, label: i18n.t("public.payStatus.0")},
  {value: 2, label: i18n.t("public.payStatus.1")},
  {value: 3, label: i18n.t("public.payStatus.2")},
  {value: 4, label: i18n.t("public.payStatus.3")},
  {value: 5, label: i18n.t("public.payStatus.4")}
]
const postpaidList = [
  {value: 0, label: i18n.t("public.isTrue.0")},
  {value: 1, label: i18n.t("public.isTrue.1")},
  {value: 2, label: i18n.t("public.payStatus.3")},
]
const payTypeList = [{value: 1, label: 'Online Pay'}, {value: 2, label: 'Balance'}, {value: 3, label: 'Swipe'}]
const columns = [
  {
    title: i18n.t("order.table.id_ord"),
    width: 80,
    ellipsis: true,
    fixed: "left",
    dataIndex: "idOrd",
    key: "idOrd"
  },
  // {title: 'YedPay ID', width: 180, ellipsis: true, dataIndex: "yedPayId", key: "yedPayId"},
  {
    title: i18n.t("region.table.name"),
    width: 180,
    ellipsis: true,
    dataIndex: "regName",
    key: "regName"
  },
  {
    title: i18n.t("meter.table.name"),
    width: 120,
    ellipsis: true,
    dataIndex: "metName",
    key: "metName"
  },
  {title: i18n.t("user.table.account"), width: 180, ellipsis: true, dataIndex: "account", key: "account"},
  {title: i18n.t("user.table.phone"), width: 180, ellipsis: true, dataIndex: "phone", key: "phone"},
  {title: i18n.t("order.table.id_car"), width: 120, ellipsis: true, dataIndex: "carModel", key: "carModel"},
  {title: i18n.t("car.table.car_license"), width: 120, ellipsis: true, dataIndex: "carLicense", key: "carLicense"},
  {
    title: i18n.t("chargingStation.table.charge_box_id"),
    width: 180,
    ellipsis: true,
    dataIndex: "chargeBoxId",
    key: "chargeBoxId"
  },
  {
    title: i18n.t("order.table.id_con"),
    width: 180,
    ellipsis: true,
    dataIndex: "connectorName",
    key: "connectorName"
  },
  {
    title: i18n.t("order.table.order_time"),
    width: 180,
    dataIndex: "orderTime",
    key: "orderTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    title: i18n.t("order.table.type"),
    width: 100,
    dataIndex: "type",
    // slots: {customRender: "type"}
    // key: "type",
    // customRender: ({text}) => {
    //   const obj = typeList.find(item => item.value === text)
    //   return obj === undefined ? text : obj.label
    // }
  },
  {
    title: i18n.t("order.table.charge_minutes") + '(min)',
    width: 100,
    ellipsis: true,
    dataIndex: "chargeMinutes",
    key: "chargeMinutes",
    customRender: ({text, record, index, column}) => {
      return record.type === 1 || record.type === 3 ? (text === undefined || text === null ? '-' : text) : '-'
    }
  },
  {
    title: i18n.t("order.table.electricity") + '(kwh)',
    width: 100,
    ellipsis: true,
    dataIndex: "electricity",
    key: "electricity",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : text / 1000
    }
  },
  {
    title: i18n.t("order.table.charge_start_time"),
    width: 180,
    ellipsis: true,
    dataIndex: "chargeStartTime",
    key: "chargeStartTime",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    title: i18n.t("order.table.chargeEndTimeActual"),
    width: 180,
    ellipsis: true,
    dataIndex: "chargeEndTimeActual",
    key: "chargeEndTimeActual",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    title: i18n.t("order.table.currentElectricity"),
    width: 100,
    ellipsis: true,
    dataIndex: "currentElectricity",
    key: "currentElectricity",
    customRender: ({text}) => {
      return text === undefined || text === null || text === 0 || text === '0' ? '-' : text / 1000
    }
  },
  {title: 'SoC', width: 80, ellipsis: true, dataIndex: "soc", key: "soc",
    customRender: ({text}) => {
      return text === undefined || text === null || text === '' ? '-' : text + '%'
    }
  },
  {
    title: i18n.t("order.table.status"),
    width: 100,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
    // slots: {customRender: "status"}
    // customRender: ({text}) => {
    //   const obj = statusList.find(item => item.value === text)
    //   return obj === undefined ? text : obj.label
    // }
  },
  {
    title: i18n.t("region.table.postpaid"),
    width: 100,
    ellipsis: true,
    dataIndex: "postpaid",
    // slots: {customRender: "postpaid"}
  },
  {
    title: i18n.t("order.table.amount"),
    width: 100,
    ellipsis: true,
    dataIndex: "amount",
    key: "amount",
    customRender: ({text}) => {
      return '$' + proxy.money(text)
    }
  },
  {
    title: i18n.t("order.table.payment_time"),
    width: 180,
    ellipsis: true,
    dataIndex: "paymentTime",
    key: "paymentTime",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },

  // {title: i18n.t("order.table.ref_id_pla"), width: 100, ellipsis: true, dataIndex: "refIdPla", key: "refIdPla"},
  {
    title: i18n.t("order.table.price"),
    width: 110,
    ellipsis: true,
    dataIndex: "price",
    key: "price",
    customRender: ({text}) => {
      return '$' + proxy.money(text)
    }
  },
  // {
  //   title: i18n.t("order.table.commission_rate"),
  //   width: 160,
  //   ellipsis: true,
  //   dataIndex: "commissionRate",
  //   key: "commissionRate"
  // },

  // {title: i18n.t("order.table.id_tra_log"), width: 120, ellipsis: true, dataIndex: "idTraLog", key: "idTraLog"},
  {
    title: i18n.t("order.table.pay_type"),
    width: 100,
    ellipsis: true,
    dataIndex: "payType",
    key: "payType",
    customRender: ({text}) => {
      if (text === undefined || text === null) {
        return '-'
      } else {
        if (text === 2) {
          return payTypeList[1].label
        } else if (text === 16) {
          return payTypeList[2].label
        } else {
          return payTypeList[0].label
        }
      }
    }
  },
  {
    title: i18n.t("order.table.payment_method"),
    width: 100,
    ellipsis: true,
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : text
    }
  },
  {
    title: i18n.t("public.name.args"),
    width: 100,
    ellipsis: true,
    dataIndex: "byUnit",
    key: "byUnit",
    customRender: ({text}) => {
      return text === undefined || text === null || text === 0 ? '-' : text
    }
  },
  {
    title: i18n.t('plan.table.free_charging_time_limit'),
    width: 100,
    ellipsis: true,
    dataIndex: "freeMeter",
    key: "freeMeter",
    customRender: ({text}) => {
      return text === undefined || text === null ? '-' : text
    }
  },
  {
    title: i18n.t("order.table.tenant_key"),
    width: 120,
    ellipsis: true,
    dataIndex: "tenantKey",
    key: "tenantKey"
  },
  {
    key: "action",
    width: 150,
    ellipsis: true,
    fixed: "right",
    // title: "actionTitle",
    dataIndex: "action",

    // slots: {title: "actionTitle", customRender: "action"}
  },
]

const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  // 获取电表列表
  getMeterList()
  // 获取区域列表
  getRegionSelectList()

  const api = new OrderList();
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }

  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...args
  }

  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function add() {
  refOrderEdit.value?.addShow();
}

function detail(obj) {
  refOrderDetail.value?.detailShow(obj);
}

function detailBal(obj) {
  refBalanceLogDetail.value?.detailShow(obj);
}

function edit(obj) {
  refOrderEdit.value?.editShow(obj);
}

function editCancel(obj) {
  refCancelOrderEdit.value?.editShow(obj);
}

function del(idOrd) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new OrderDelete();
      api.post({idOrd: idOrd}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if (response.data.code !== undefined) {
            message.error(i18n.t("errorMsg." + response.data.code));
          }
        }
      });
    },
  });
}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}
function handleChange() {
  searchForm.value.idMet = undefined
  getMeterList()
}
function getMeterList() {
  const api = new MeterGetSelectListByidReg();
  api.get({idReg: searchForm.value.idReg}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      metOptions.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}
// 获取所有区域 id-name 列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      options.value = res.data.data
    }
  })
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function exportToExecl() {
  loading.value = true
  const api = new OrderExportToExcel()
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  api.getDownLoad(args).then(() => {}).finally(f => {
    loading.value = false
  })
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.OrderManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
