<template>
  <Modal id="UserDetail" width="50%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('user.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('user.field.id_use')">{{data.idUse}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.field.balance')">{{data.balance}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.field.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.field.register_time')">{{data.registerTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.field.email')">{{data.email}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('user.field.token')">{{data.token}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.field.salt')">{{data.salt}}</DescriptionsItem> -->
      <!-- <DescriptionsItem :label="$t('user.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('user.field.phone')">{{data.phone}}</DescriptionsItem>
      <DescriptionsItem v-if="data.registerType" :label="$t('user.field.register_type')">{{registerTypeList.find(item=>item.value===data.registerType).label}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "UserDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

const registerTypeList = [{value: 1,label: i18n.t("public.registerType.0")},{value: 2,label: i18n.t("public.registerType.1")}]

let visible = ref(false)

const dataTemp = reactive({
  data: {}
})
const {data} = toRefs(dataTemp)

async function detailShow(obj) {
  data.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>