import {Base} from "@/api/Base"

const api = "repairLog"

export class RepairLogAdd extends Base {
    path = api + "/add"
}

export class RepairLogDelete extends Base {
    path = api + "/delete"
}

export class RepairLogList extends Base {
    path = api + "/list"
}

export class RepairLogEdit extends Base {
    path = api + "/edit"
}
