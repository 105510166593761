<template>
  <div id="App">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App"
}
</script>

<script setup>
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
const i18n = useI18n()
const store = useStore()


const customIconBase64 = store.getters.getLogo;
const defaultIconLink = '/favicon.ico';

const setFavicon = (iconUrl) => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon.href = iconUrl;
  }
};
// 在应用加载时设置图标
if (customIconBase64) {
  setFavicon(customIconBase64);
} else {
  setFavicon(defaultIconLink);
}

switch (store.getters.getLanguage) {
  case "zhTW":
    i18n.locale.value = "zhTW";
    break;
  case "enUS":
    i18n.locale.value = "enUS";
    break;
  case "zhCN":
    i18n.locale.value = "zhCN";
    break;
  case "daDK":
    i18n.locale.value = "daDK";
    break;
  case "idID":
    i18n.locale.value = "idID";
    break;
  case "msMY":
    i18n.locale.value = "msMY";
    break;
  case "thTH":
    i18n.locale.value = "thTH";
    break;
  case "viVN":
    i18n.locale.value = "viVN";
    break;
  case "frFR":
    i18n.locale.value = "frFR";
    break;
  case "plPL":
    i18n.locale.value = "plPL";
    break;
  case "it":
    i18n.locale.value = "it";
    break;
}
</script>

