import {Base} from "@/api/Base"

const api = "wallet"

export class WalletGetByPage extends Base {
    path = api + "/getByPage"
}

export class WalletGetInfo extends Base {
    path = api + "/getInfo"
}
