<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
        @onChange="handleChange"
    />
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import {onBeforeUnmount, ref, shallowRef, onMounted} from 'vue'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {i18nChangeLanguage} from '@wangeditor/editor'

export default {
  components: {Editor, Toolbar},
  props: ["getFullText", "content"], //回调方法
  setup(props, content) {

    // 切换语言 - 'en' 或者 'zh-CN'
    i18nChangeLanguage('en')

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref(props.content)

    // 模拟 ajax 异步获取内容
    // onMounted(() => {
    //   setTimeout(() => {
    //     valueHtml.value = props.content
    //   }, 1)
    // })

    const toolbarConfig = {}
    // 排查掉部分菜单信息
    toolbarConfig.excludeKeys = [
      // 暂时不开放图片上传接口
      'uploadImage',
      // 暂时不开放视频上传接口
      'uploadVideo',
    ]

    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {}
    }
    // base64 格式
    editorConfig.MENU_CONF['uploadImage'] = {
      // 小于该值就插入 base64 格式（而不上传），默认为 0
      base64LimitSize: 3 * 1024 * 1024,  // 3M
      // async customUpload(file, insertFn) {
      //   // file 即选中的文件
      //   // 自己实现上传，并得到图片 url alt href
      //   // 最后插入图片
      //   insertFn(url, alt, href)
      // }
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    const handleChange = (editor) => {
      content.emit("getFullText", editor.getHtml());
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange
    };
  }
}
</script>