<template>
  <Modal
      id="CancelFines"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('balanceLog.name.cancelFines')"
  >
    <Row align="middle" justify="center" >
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="orderForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('order.table.id_ord')" name="idOrd">
                    <Input
                        v-model:value="form.idBalLog"
                        :placeholder="$t('order.validate.id_ord')"
                        disabled
                    />
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('user.table.account')" name="account">
                    <Input v-model:value="form.userAccount" disabled/>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('order.table.amount')" name="amount">
                    <InputNumber
                        v-model:value="form.amount"
                        :placeholder="$t('order.validate.amount')"
                        :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="0" :step="0.01"
                        style="width: 100%"
                        disabled
                    />
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('order.table.status')" name="status">
                    <Select
                        v-model:value="form.status"
                        :placeholder="$t('order.validate.status')"
                        :options="statusList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                        disabled
                    >
                    </Select>
                  </FormItem>
                </Col>

                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount=""
                    />
                    <p style="font-size:8px;color: #bdc3c7;margin-top: 10px">{{minRemake}}</p>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "CancelFines",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  InputNumber,
  message,
  Select,
} from "ant-design-vue";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import {BalanceCancelFines} from "@/api/balanceLog";
import {createVNode, reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

let visible = ref(false)
let actionType = ref("")
let title = ref("")
let minRemake = ref("")

const orderForm = ref()

const typeList = [
  {value: 1, label: i18n.t("public.chargeType.0")},
  {value: 2,label: i18n.t("public.chargeType.1")},
  {value: 3, label: i18n.t("public.chargeType.2")}
]
const statusList = [{value: 1,label: i18n.t("public.payStatus.0")},{value: 2,label: i18n.t("public.payStatus.3")},{value: 3,label: i18n.t("public.payStatus.4")}]
const payTypeList = [{value: 1, label: 'Online Pay'}, {value: 2, label: 'Balance'}]
const validateRules = {
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
}
const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function editShow(obj) {
  actionType.value = "edit";
  _resetData();
  let args = JSON.parse(JSON.stringify(obj))
  minRemake.value = args.remark
  delete args.remark
  form.value = args
  visible.value = true;
}

function submit() {
  orderForm.value.validate().then(() => {
    Modal.confirm({
      title: i18n.t("public.name.are_you_sure_operate") + "?",
      content: i18n.t("public.name.operate_remind") + "！",
      icon: createVNode(ExclamationCircleOutlined),
      okText: i18n.t("public.name.yes"),
      okType: "danger",
      cancelText: i18n.t("public.name.no"),
      onOk: () => {
        let api = new BalanceCancelFines();
        const args = {
          idBalLog: form.value.idBalLog,
          remark: form.value.remark
        }
        api.post(args).then((response) => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      },
    });
  }).catch(() => {});
}

function _resetData() {
  minRemake.value = "";
  form.value = {};
}

defineExpose({
  editShow
})
</script>