<template>
  <Modal
      id="UploadForm"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.name.Import')"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="userForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="22">
                  <FormItem>
                    <UploadDragger
                        v-model:file-list="fileList"
                        name="file"
                        :multiple="true"
                        :customRequest="customUpload"
                        @change="handleChange"
                    >
                      <p class="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                      </p>
                      <p class="ant-upload-text">{{$t('public.name.ImportTips')}}</p>
<!--                      <p class="ant-upload-hint">-->
<!--                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other-->
<!--                        band files-->
<!--                      </p>-->
                    </UploadDragger>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem>
                    <Button :loading="buttonLoading" @click="downloadUserTemp()">
                      <CloudDownloadOutlined />{{$t('public.name.DownloadTemp')}}
                    </Button>
                  </FormItem>
                </Col>
                <Col span="10"></Col>

                <Col span="22">
                  <FormItem>
                    <Textarea
                        v-model:value="resData"
                        :auto-size="{ minRows: 15, maxRows: 15 }"
                        showCount=""
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UploadForm",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  UploadDragger,
  message,
  Textarea,
  FormItem
} from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {CloudUploadOutlined, CloudDownloadOutlined} from "@ant-design/icons-vue";
import {UserDownloadUserTemp, UserImport} from "@/api/user";

const i18n = useI18n()
const fileList = ref([]);

let userForm = ref()

let visible = ref(false)
let buttonLoading = ref(false)
let uploading = ref(false);
let downloading = ref(false);
let resData = ref("");

const validateRules = {}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function showForm() {
  _resetData();
  visible.value = true;
}

function customUpload(obj) {
  uploading.value = true;
  let fm = new FormData();
  let api = new UserImport();

  console.log(obj.file)
  // fm.append("file", fileList.value[0]);
  fm.append("file", obj.file);
  api.upload(fm).then((response) => {
    if (parseInt(response.data.code) === 0) {
      resData.value = response.data.data
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  }).finally(() => {
    fileList.value = []
    uploading.value = false;
  });
}

function handleChange() {

}

function submit() {

}

function downloadUserTemp() {
  buttonLoading.value = true
  const api = new UserDownloadUserTemp()
  api.getDownLoad({}).then(response => {
    console.log(response)
    if (parseInt(response.data.code) !== 0 && response.data.code !== undefined) {
      message.error(i18n.t("errorMsg." + response.data.code));
    }
  }).finally(f => {
    buttonLoading.value = false
  })
}

function _resetData() {
  form.value = {};
  resData.value = ""
}

defineExpose({
  showForm,
})
</script>

<style lang="less">
</style>
