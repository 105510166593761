<template>
  <Modal id="MeterDash" width="60%"
         :open="visible"
         :okText="$t('public.name.reset')"
         @cancel="visible = !visible"
         @ok="onOk"
         :title="$t('nav.name.Dashboard')"
         :zIndex="9999999900"
         :confirmLoading="confirmLoading"
  >
    <Descriptions>
      <DescriptionsItem label="电表最大负载电流">{{ loadData.meterMaxCurrent }}</DescriptionsItem>
      <DescriptionsItem label="最小电流之和">{{ loadData.minAssignableCurrent }}</DescriptionsItem>
      <DescriptionsItem label="最大可分配电流">{{ loadData.maxAssignableCurrent }}</DescriptionsItem>

      <DescriptionsItem label="实际可分配电流">{{ loadData.actualAssignableCurrent }}</DescriptionsItem>
      <DescriptionsItem label="在使用电桩总权重">{{ loadData.zWeight }}</DescriptionsItem>
      <DescriptionsItem label="执行结果">{{ loadData.status }}</DescriptionsItem>

      <DescriptionsItem label="日志ID">{{ loadData.logId }}</DescriptionsItem>
      <DescriptionsItem label="执行时长">{{ loadData.timeConsuming }}</DescriptionsItem>
      <DescriptionsItem label="异常消息">{{ loadData.errMsg }}</DescriptionsItem>
    </Descriptions>

    <div style="background-color: #ececec; padding: 20px">
      <Row>
        <Col>
          <TypographyTitle :level="4">{{$t('meter.table.loadByTactics')}}</TypographyTitle>
        </Col>
      </Row>
      <Row :gutter="[16,16]">
        <Col :span="6" v-for="it in loadData.loadList" :key="it.id">
          <Card :title="it.identifier" :bordered="false">
            <div v-for="(value, key, index) in it" :key="index">
              <p v-if="key !== 'identifier'">{{key}}: {{value}}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>

    <div style="background-color: #ececec; padding: 20px">
      <Row>
        <Col>
          <TypographyTitle :level="4">{{$t('nav.name.Dashboard')}}</TypographyTitle>
        </Col>
      </Row>
      <Row v-for="item in meterList" :key="item.id">
        <Row>
          <Col>
            <Descriptions>
              <DescriptionsItem label="电桩ID">{{ item.identifier }}</DescriptionsItem>
              <DescriptionsItem label="端口ID">{{ item.connectorId }}</DescriptionsItem>
              <DescriptionsItem label="时间">{{ item.dateTime }}</DescriptionsItem>
            </Descriptions>
          </Col>
        </Row>
        <Row :gutter="[16,16]">
          <Col :span="6" v-for="it in item.meterValue[0].sampledValue" :key="it.id">
            <Card :title="it.measurand" :bordered="false">
              <div v-for="(value, key, index) in it" :key="index">
                <p v-if="key !== 'context' && key !== 'location' && key !== 'format' && key !== 'measurand'">{{key}}: {{value}}</p>
              </div>
            </Card>
          </Col>
        </Row>
      </Row>
    </div>

  </Modal>
</template>
<script>
export default {
  name: "MeterDash",
}
</script>
<script setup>
import {message, Modal, TypographyTitle, Row, Col, Card, Descriptions, DescriptionsItem} from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {MeterFindLoadBalancedLogByIdMet} from "@/api/meter";

const i18n = useI18n()

let visible = ref(false)
let confirmLoading = ref(false)
let idMet = ref()
let meterList = ref([])
let sampledList = ref([])
const dataTemp = reactive({
  loadData: {},
})
const {loadData} = toRefs(dataTemp)

function onOk() {
  confirmLoading.value = true
  // 刷新列表
  // 获取当前仪表值记录
  const api = new MeterFindLoadBalancedLogByIdMet()
  api.post({idMet:idMet.value}).then(response=>{
    if (0 === parseInt(response.data.code)) {
      message.success(i18n.t("succeedMsg.0"));
      const resDate = response.data.data
      loadData.value = resDate.loadData
      meterList.value = resDate.mList
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  }).finally(()=>{
    confirmLoading.value = false
  })
}

async function detailShow(id) {
  // 获取当前仪表值记录
  idMet.value = id
  const api = new MeterFindLoadBalancedLogByIdMet()
  api.post({idMet:idMet.value}).then(response=>{
    if (0 === parseInt(response.data.code)) {
      const resDate = response.data.data
      loadData.value = resDate.loadData
      meterList.value = resDate.mList
      visible.value = true;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

defineExpose({
  detailShow
})
</script>