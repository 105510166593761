<template>
  <Modal id="PlanDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('plan.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('plan.field.id_pla')">{{data.idPla}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.field.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.field.price_per_month')">{{data.pricePerMonth}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.field.contract_period')">{{data.contractPeriod}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.field.free_charging_time_limit')">{{data.freeChargingTimeLimit}}</DescriptionsItem>
      <DescriptionsItem :label="$t('plan.field.overtime_charge_price')">{{data.overtimeChargePrice}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('plan.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "PlanDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { ref, reactive } from "vue";

let visible = ref(false)
let data = reactive({})

async function detailShow(obj) {
  data = obj;
  visible.value = true;
}

defineExpose({
  detailShow
});
</script>