<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="onOk"
      :title=" $t('balanceLog.rechargePackage') + ' (...] '"
      :zIndex="9999999900"
  >
    <Form
        ref="refForm"
        layout="vertical"
        name="dynamic_form_nest_item"
        :model="dynamicValidateForm"
        class="modal"
    >
      <div style="text-align: center;margin: 8px 30px">

        <Row justify="space-around" style="width: 80%;margin-left: 7%">
          <Col span="10">
            <FormItem name="enable" :label="$t('balanceLog.gift.enable')" :rules="{required: true, message: $t('balanceLog.vGift.enable'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.enable" :placeholder="$t('balanceLog.vGift.enable')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="percentage" :label="$t('balanceLog.gift.percentage')" :rules="{required: true, message: $t('balanceLog.vGift.percentage'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.percentage" :placeholder="$t('balanceLog.vGift.percentage')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Space
            v-for="(info, index) in dynamicValidateForm.infos"
            :key="info.id"
            size="small"
            style="width: 80%;"
        >
          <FormItem :name="['infos', index, 'star']" :label="$t('balanceLog.gift.star')" :rules="{required: true, message: $t('balanceLog.vGift.star'),}">
            <InputNumber v-model:value="info.star" :placeholder="$t('balanceLog.vGift.star')" style="width: 100%"/>
          </FormItem>
          <FormItem :name="['infos', index, 'end']" :label="$t('balanceLog.gift.end')" :rules="{required: true, message: $t('balanceLog.vGift.end'),}">
            <InputNumber v-model:value="info.end" :placeholder="$t('balanceLog.vGift.end')" style="width: 100%"/>
          </FormItem>
          <FormItem :name="['infos', index, 'give']" :label="dynamicValidateForm.percentage === 1 ? $t('balanceLog.gift.give') + '(%)' : $t('balanceLog.gift.give')" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
            <InputNumber v-model:value="info.give" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
          </FormItem>
          <FormItem label="-">
            <Button type="dashed" block @click="removeInfo(info)"><MinusCircleOutlined/></Button>
          </FormItem>
        </Space>

        <FormItem>
          <Button type="dashed" block @click="addInfo" style="width: 80%;"><PlusOutlined/>{{$t('balanceLog.addRule')}}</Button>
        </FormItem>
      </div>
    </Form>
  </Modal>
</template>
<script>
export default {
  name: "RechargePackageModal"
}
</script>
<script setup>
import {Modal, Form, FormItem, Space, InputNumber, Button, message, Select, SelectOption, Row, Col} from "ant-design-vue";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {reactive, ref} from "vue";
import {useI18n} from 'vue-i18n'
import {SetMealEdit, SetMealGet} from "@/api/setMeal";
const refForm = ref();

const i18n = useI18n()
let visible = ref(false)
let data = ref({})

const enable = ref(0);
const percentage = ref(0);

const dynamicValidateForm = reactive({
  infos: [],
});
const removeInfo = item => {
  const index = dynamicValidateForm.infos.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.infos.splice(index, 1);
  }
};
const addInfo = () => {
  dynamicValidateForm.infos.push({
    id: Date.now(),
    star: undefined,
    end: undefined,
    give: undefined
  });
};

async function detailShow(obj) {
  dynamicValidateForm.infos.length = 0
  // 获取后台充值套餐信息
  const api = new SetMealGet()
  api.get({type: 1}).then(response => {
    if (parseInt(response.data.code) === 0) {
      if (response.data.data) {
        let data = JSON.parse(response.data.data)
        dynamicValidateForm.enable = data.enable
        dynamicValidateForm.percentage = data.percentage
        dynamicValidateForm.infos = data.infos
        data.value = obj;
      }
      visible.value = true;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  })
}

function onOk() {
  refForm.value?.validate().then(() => {
    const api = new SetMealEdit()
    api.post({type: 1, args: JSON.stringify(dynamicValidateForm), remark: ""}).then(response => {
      if (parseInt(response.data.code) === 0) {
        message.success(i18n.t("succeedMsg.0"));
        dynamicValidateForm.infos.length = 0
        visible.value = false
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    })
  })
}

defineExpose({
  detailShow,
});
</script>

<style lang="less">
</style>