<template>
  <Modal id="ChargingStationDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('chargingStation.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('chargingStation.table.id_cha_poi')">{{data.idChaPoi}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.id_met')">{{data.meterName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.charge_box_id')">{{data.chargeBoxId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.firmwareVersion')">{{data.firmwareVersion}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.last_heartbeat_time')">{{data.lastHeartbeatTime}}</DescriptionsItem>
<!--      <DescriptionsItem :label="$t('chargingStation.table.monthly_fee')">${{data.monthlyFee}}</DescriptionsItem>-->
      <DescriptionsItem :label="$t('chargingStation.table.charger_type')">{{chargerTypeList.find(item=>item.value===data.chargerType).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.tenant_key')">{{data.tenantKey}}</DescriptionsItem>
      <DescriptionsItem :label="$t('chargingStation.table.enable')">{{booleanList.find(item=>item.value===data.enable).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.createTime')">{{data.createTime}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "ChargingStationDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

let visible = ref(false)

const chargerTypeList = [{value: 1,label: i18n.t('public.chargerType.0')},{value: 2,label: i18n.t('public.chargerType.1')}]
const booleanList = [{value: 0,label: i18n.t('public.isTrue.0')},{value: 1,label: i18n.t('public.isTrue.1')}]

const dataTemp = reactive({
  data: {},
})
const {data} = toRefs(dataTemp)

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}
</script>

<style lang="less">
</style>