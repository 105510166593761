<template>
  <Modal
      id="TenantArgs"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" >
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around" :gutter="[18,18]">
                <Col :span="12">
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.yedPayApiKey')" name="yedPayApiKey">
                        <Input
                            :placeholder="$t('tenantInfo.validate.yedPayApiKey')"
                            v-model:value="form.yedPayApiKey"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.yedPaySingKey')" name="yedPaySingKey">
                        <Input
                            :placeholder="$t('tenantInfo.validate.yedPaySingKey')"
                            v-model:value="form.yedPaySingKey"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.mPayPubKey')" name="mPayPubKey">
                        <Input
                            :placeholder="$t('tenantInfo.validate.mPayPubKey')"
                            v-model:value="form.mPayPubKey"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.mPayPriKey')" name="mPayPriKey">
                        <Input
                            :placeholder="$t('tenantInfo.validate.mPayPriKey')"
                            v-model:value="form.mPayPriKey"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col :span="12">
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.yedPayExtendParams')" name="yedPayExtendParams">
                        <Textarea
                            v-model:value="form.yedPayExtendParams"
                            :placeholder="$t('tenantInfo.validate.yedPayExtendParams')"
                            :auto-size="{ minRows: 5, maxRows: 10 }"
                            showCount=""
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.args.mPayExtendParams')" name="mPayExtendParams">
                        <Textarea
                            v-model:value="form.mPayExtendParams"
                            :placeholder="$t('tenantInfo.validate.mPayExtendParams')"
                            :auto-size="{ minRows: 5, maxRows: 10 }"
                            showCount=""
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "TenantArgs"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
} from "ant-design-vue";
import {TenantGetArgs, TenantSetArgs} from "@/api/tenantInfo";
import {ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let visible = ref(false)
let title = ref("")
let fid = ref(0)

const refForm = ref()
const validateRules = reactive({
  // packageNo: [
  //   {
  //     required: true,
  //     message: i18n.t("tenantPackage.validate.packageNo"),
  //     trigger: "change",
  //   },
  // ]
})
const data = reactive({
  form: {},
})
const {form} = toRefs(data)

async function editShow(id, tenantKey) {
  title.value = i18n.t('public.name.edit') + "-" + tenantKey
  fid.value = id
  _resetData();
  const api = new TenantGetArgs();
  api.get({id:id}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      form.value = response.data.data;
      visible.value = true;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function submit() {
  refForm.value?.validate().then(() => {
    const api = new TenantSetArgs();
    form.value.id = fid.value
    form.value.yedPadApiKey = form.value.yedPadApiKey?.replace(/\s+/g, '')
    form.value.yedPaySingKey = form.value.yedPaySingKey?.replace(/\s+/g, '')
    form.value.mPayPubKey = form.value.mPayPubKey?.replace(/\s+/g, '')
    form.value.mPayPriKey = form.value.mPayPriKey?.replace(/\s+/g, '')
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

defineExpose({
  editShow
});
</script>