import {Base} from "@/api/Base"

const api = "tenant"

export class TenantList extends Base {
    path = api + "/findByPage"
}

export class TenantAdd extends Base {
    path = api + "/addInfo"
}

export class TenantDelete extends Base {
    path = api + "/delInfo"
}

export class TenantEdit extends Base {
    path = api + "/editInfo"
}

export class TenantExport extends Base {
    path = api + "/export"
}

export class TenantGetAuthTreeByTenant extends Base {
    path = api + "/getAuthTreeByTenant"
}

export class selectAuthIdsByTenantId extends Base {
    path = api + "/selectAuthIdsByTenantId"
}

export class TenantGetSelectTenantKeyList extends Base {
    path = api + "/getSelectTenantKeyList"
}

export class TenantGetArgs extends Base {
    path = api + "/getArgs"
}

export class TenantSetArgs extends Base {
    path = api + "/setArgs"
}

