import { defineComponent, ref, onMounted } from "vue";

import { Upload, Button, message } from "ant-design-vue";
import { LoadingOutlined, UploadOutlined,PlusOutlined } from "@ant-design/icons-vue";
import { Uploads as uploadApi } from "@/api/common";
import {useI18n} from "vue-i18n";
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: "UploadFile",
  props: {
    urlList: {
      type: Array,
      default: [],
    },
    limit: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: "*.*",
    },
  },
  emits: ['update_list', 'on_preview'],
  setup(props, ctx) {
    const uploading = ref(false);
    const uploadList = ref([]);
    const limit = ref(props.limit);

    const send_list = (urlList) => {
      uploadList.value = [];
      if (urlList == null || urlList === []) {
        return false;
      }
      if (typeof urlList == "string") {
        urlList = [urlList];
      }
      for (let item in urlList) {
        _get_msg(urlList[item]);
      }
    };

    const get_list = () => {
      let list = [];
      uploadList.value.map((item) => {
        list.push(item.url);
      });
      return list;
    };

    const get_data_list = () => {
      let list = [];
      uploadList.value.map((item) => {
        list.push({
          name: item.name,
          url: item.url,
        });
      });
      return list;
    };

    const get_list_md5 = () => {
      let list = [];
      uploadList.value.map((item) => {
        if (item.url.indexOf("md5=") !== -1) {
          let md5 = item.url.slice(item.url.indexOf("md5=") + 4);
          if (md5.indexOf("&") !== -1) {
            md5 = md5.slice(0, md5.indexOf("&"));
          }
          list.push(md5);
        }
      });
      return list;
    };

    const get_list_name = () => {
      let list = [];
      uploadList.value.map((item) => {
        list.push(item.name);
      });
      return list;
    };

    const _get_msg = async (imgObj) => {
      if (imgObj.url === "" || imgObj.url == null) {
        return false;
      }
      if (uploadList.value == null) {
        uploadList.value = [];
      }
      
      if (uploadList.value.length >= props.limit) {
        uploadList.value.shift();
      }
      uploadList.value.push({
        uid: uuidv4(),
        name: imgObj.objName,
        status: "done",
        url: imgObj.url,
      });
    };

    const _upload_handle = async (obj) => {
      const _props = props
      uploading.value = true;
      let form = new FormData();
      let api = new uploadApi();
      form.append("file", obj.file);
      await api.upload(form).then(async (response) => {
        if (parseInt(response.data.code) === 0) {
          await _get_msg(response.data.data);
          _props.urlList.push(response.data.data)
          ctx.emit('update_list', _props.urlList);
        } else {
          const i18n = useI18n()
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
        uploading.value = false;
      });
    };

    const _onPreview = (file) => {
      // console.log("文件预览 => ", file)

      ctx.emit('on_preview', file);
    }

    const _remove = (file) => {
      let tmpList = [];
      let tmpUrlList = [];

      uploadList.value.map((item) => {
        if (item.uid !== file.uid) {
          tmpList.push(item);
          tmpUrlList.push({url: item.url, objName:item.name});
        }
      });
      uploadList.value = tmpList;
      ctx.emit('update_list', tmpUrlList);
    };

    onMounted(() => {
      send_list(props.urlList);
    });

    ctx.expose({ get_list, get_list_md5, get_list_name, get_data_list, send_list });
    return () => (
        <div>
          <Upload
              customRequest={_upload_handle}
              fileList={uploadList.value}
              onPreview={_onPreview}
              onRemove={_remove}
              accept={props.accept}
              list-type="picture-card"
          >
            {uploading.value === true ? (
                <LoadingOutlined style="{fontSize:'25px'}"/>
            ) : (
                limit.value > uploadList.value.length ? (
                    <div>
                      <PlusOutlined />
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                ) : ('')
            )}
          </Upload>
        </div>

    );
  },
});
