<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('superTenantManage.searchForm.tenant')" name="tenant">
                  <Input :placeholder="$t('superTenantManage.searchFormPlaceholder.tenant')" v-model:value="searchForm.tenant" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('superTenantManage.table.dataSourceKey')" name="dataSourceKey">
                  <Input :placeholder="$t('superTenantManage.table.dataSourceKey')" v-model:value="searchForm.dataSourceKey" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('superTenantManage.searchForm.isEnable')" name="isEnable">
                  <Select v-model:value="searchForm.isEnable" :placeholder="$t('superTenantManage.searchFormPlaceholder.isEnable')" allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('superTenantManage.searchForm.isCreate')">
                  <Select v-model:value="searchForm.isCreate" :placeholder="$t('superTenantManage.searchFormPlaceholder.isCreate')">
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
<!--            <Row :gutter="16">-->
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('superTenantManage.searchForm.url')" name="url">-->
<!--                  <Input :placeholder="$t('superTenantManage.searchFormPlaceholder.url')" v-model:value="searchForm.url" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
<!--              <Col :span="6">-->
<!--                <FormItem :label="$t('superTenantManage.searchForm.username')" name="username">-->
<!--                  <Input :placeholder="$t('superTenantManage.searchFormPlaceholder.username')" v-model:value="searchForm.username" allowClear></Input>-->
<!--                </FormItem>-->
<!--              </Col>-->
<!--              <Col :span="6"></Col>-->
<!--              <Col :span="6"></Col>-->
<!--            </Row>-->
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button v-if="checkPermission('TenantManage.Add')" type="primary" size="default" @click="add()">{{$t("public.name.add")}}</Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('TenantManage.Delete')" type="primary" size="default" danger @click="delByIds()">{{$t("public.name.batchDel")}}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table
            :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :pagination="tablePagination"
            :scroll="{x: 1200}"
            @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{$t("public.table.action")}}
                <Button type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">

            <template v-if="column.dataIndex === 'isEnableSwitch'">
              <Switch :checked="record.isEnable === '1'" @click="e => switchClick(e, record)" checked-children="ON" un-checked-children="OFF" />
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="primary" size="small" @click="onShowCompanyModel(record)">{{$t("companyManage.field.company")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="record.isEnable==='1'" type="default" size="small" @click="testConn(record)" >{{$t("superTenantManage.table.action.testConn")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="record.isCreate!=='1'" type="primary" size="small" @click="create(record)">{{$t("superTenantManage.table.action.create")}}</Button>
                  <span>&nbsp;</span>
                  <Button type="primary" size="small" @click="edit(record)">{{$t("superTenantManage.table.action.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('SuperTenantManage.Delete')" type="primary" danger size="small" @click="del(record)">{{$t("superTenantManage.table.action.del")}}</Button>
                </div>
              </div>
            </template>

          </template>
        </Table>
      </div>
    </Col>
    <FormModel ref="refFormModel" @updateList="get_list"></FormModel>
    <CompanyModel ref="refCompanyModel" @updateList="get_list"></CompanyModel>
  </Row>
</template>
<script>
export default {
  name: "SuperTenantManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormItem,
  Input,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Switch,
  Table,
  Spin
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";

import {
  superTenantCreateDb,
  superTenantDelInfo,
  superTenantEditInfo,
  superTenantGetListByPage,
  superTenantTestConn
} from "@/api/superTenant";
import FormModel from "./FormModel";
import CompanyModel from "./CompanyModel";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refFormModel = ref()
const refCompanyModel = ref()

let loading = ref(false)

const columns = [
  { title: i18n.t("superTenantManage.table.index"), fixed: "center", width: 80, customRender: ({text, record, index, column}) => `${index + 1}`},
  { title: i18n.t("superTenantManage.table.tenant"), fixed: "center", width: 100, ellipsis:true, dataIndex: "tenant", key: "tenant" },
  { title: i18n.t("superTenantManage.table.dataSourceKey"), fixed: "center", width: 130, dataIndex: "dataSourceKey", key: "dataSourceKey" },
  // { title: i18n.t("superTenantManage.table.url"), fixed: "center", width: 300, ellipsis:true, dataIndex: "url", key: "url"},
  // { title: i18n.t("superTenantManage.table.host"), fixed: "center", width: 200,  ellipsis:true, dataIndex: "host", key: "host"},
  // { title: i18n.t("superTenantManage.table.port"), fixed: "center", width: 100, ellipsis:true, dataIndex: "port", key: "port"},
  { title: i18n.t("superTenantManage.table.dbName"), fixed: "center", width: 200, ellipsis:true, dataIndex: "dbName", key: "dbName"},
  // { title: i18n.t("superTenantManage.table.username"), fixed: "center", width: 100, ellipsis:true, dataIndex: "username", key: "username" },
  // { title: i18n.t("superTenantManage.table.password"), fixed: "center", width: 100, ellipsis:true, dataIndex: "password", key: "password" },
  { title: i18n.t("superTenantManage.table.domain"), fixed: "center", width: 200, ellipsis:true, dataIndex: "domain", key: "domain" },
  { title: i18n.t("superTenantManage.table.isEnable"), key: "isEnable", fixed: "center", width: 100, dataIndex: "isEnableSwitch" },
  { title: i18n.t("superTenantManage.table.isCreate"), fixed: "center", width: 100, dataIndex: "isCreate", key: "isCreate",
    customRender: ({text, record, index, column}) => {
      return text === '1' ? 'ON' : 'OFF'
    }},
  { key: "action", fixed: "center", width: 300, dataIndex: "action"},
]

const data = reactive({
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  selectedRowKeys: [],
})
const {searchForm, list, tablePagination, selectedRowKeys} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new superTenantGetListByPage();
  // 构建查询参数
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      let l = dataTmp.data;
      l.map(item => {
        const t = interceptUrl(item.url)
        item.host = t.host
        item.port = t.port
        item.dbName = t.name
      })
      list.value = l
    } else {
      list.value = [];
    }
  });
}

function onShowCompanyModel(val) {
  refCompanyModel.value?.onShowVisible(val.id);
}

function testConn(obj) {
  loading.value = true

  delete obj.createDbTime
  delete obj.createTime
  delete obj.updateTime

  const api = new superTenantTestConn();
  api.post(obj).then((response) => {
    loading.value = false
    if (0 === response.data.code) {
      message.success(i18n.t("succeedMsg.0"))
    } else {
      message.error(i18n.t("errorMsg." + response.data.code))
    }
  });
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  console.log("1111111==>")
  refFormModel.value.editShow(obj);
}

function create(obj) {
  loading.value = true

  const api = new superTenantCreateDb();
  delete obj.createDbTime
  delete obj.createTime
  delete obj.updateTime

  api.post(obj).then((response) => {
    loading.value = false
    if (0 === response.data.code) {
      message.success(i18n.t("succeedMsg.0"))
    } else {
      message.error(i18n.t("errorMsg." + response.data.code))
    }
    get_list();
  });
}

function interceptUrl (url) {
  if (url === undefined || url === null || url === '') {
    return {
      host: '',
      port: '',
      name: ''
    }
  } else {
    // 截取url, 获取host和port
    let c = url.substring(13, url.indexOf("?"));
    let e = c.substring(0, c.indexOf("/"));
    let f = e.indexOf(":")
    return {
      host: e.substring(0, f),
      port: c.substring(e.indexOf(':')+1, e.length),
      name: c.substring(c.indexOf("/") + 1, c.length)
    }
  }
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new superTenantDelInfo();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        this.get_list();
      });
    },
  });
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new superTenantDelInfo();
      api.post({ids: this.selectedRowKeys}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function switchClick(e, obj) {
  // 判断是否已创建
  if (obj.isCreate !== '1') {
    message.error(i18n.t("errorMsg.500105"));
    return
  }
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      // 更新数据库及刷新列表
      // console.log("switchClick点击事件", obj.isEnable)
      obj.isEnable = e ? '1' : '0'

      delete obj.createDbTime
      delete obj.createTime
      delete obj.updateTime

      const api = new superTenantEditInfo();
      api.post(obj).then((response) => {
        if (0 === parseInt(response.data.code)) {
          // 刷新列表
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.SuperTenantManage'));
get_list();

defineExpose({
  get_list
})
</script>
