<template>
  <div id="bigScreen">
    <iframe :src="url" scrolling="auto" frameborder="0" class="trend-container" id="iframe"></iframe>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "bigScreen",
}
</script>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const store = useStore();

let url = ref("")

function get_list() {
  const protocol = window.location.protocol;
  // 正式环境
  const host = window.location.host;
  window.open(protocol + "//" + host + "/big" , "BigScreen");

  // 开发环境
  // const hostname = window.location.hostname;
  // url.value = protocol + "//" + hostname + ":8112/#/index" + "?tenantKey=" + store.getters.getTenantKey + "&token=" + store.getters.getToken
  // console.log("toUrl => ", url.value)
}

onMounted(() => {
  get_list()
})

store.commit("setPageTitle", i18n.t('nav.name.Dashboard'))

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("./style.less");
</style>