<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('tenantPackage.table.packageNo')" name="packageNo">
                  <Input :placeholder="$t('tenantPackage.validate.packageNo')" v-model:value="searchForm.packageNo" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tenantPackage.table.name')" name="name">
                  <Input :placeholder="$t('tenantPackage.validate.name')" v-model:value="searchForm.name" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button :loading="exportButtonLoading" type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list"
               :scroll="{x: 1000}"
               rowKey="id"
               :loading="loading" :pagination="tablePagination" @change="chang_page">

          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('TenantPackage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                <span>&nbsp;</span>
                <Button v-if="checkPermission('TenantPackage.Edit')" type="primary" size="small" @click="edit(record)">
                  {{ $t("public.name.edit") }}
                </Button>
                <span>&nbsp;</span>
                <Button v-if="checkPermission('TenantPackage.Delete')" type="primary" danger size="small" @click="del(record.id, record.tenantKey)">{{ $t("public.name.del") }}
                </Button>
              </div>
            </template>
          </template>

        </Table>
      </div>
    </Col>
    <TenantPackageEdit ref="refTenantPackageEdit" @updateList="get_list" />
  </Row>
</template>

<script>
export default {
  name: "TenantPackage"
}
</script>
<script setup>
import {createVNode, onMounted} from "vue";
import {
  TypographyTitle,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Modal, message
} from "ant-design-vue";
import moment from "moment";

import dayjs from "dayjs";
import {ref, reactive, toRefs} from "vue";
import {TenantPackageExport, TenantPackageList, TenantPackageDelete} from "@/api/tenantPackage";
import TenantPackageEdit from "./TenantPackageEdit";
import {useI18n} from "vue-i18n"
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {useStore} from "vuex";
const i18n = useI18n()

const refTenantPackageEdit = ref()
let loading = ref(false)
let exportButtonLoading = ref(false)
let columns = [
  {
    title: i18n.t("public.name.index"),
    width: 60,
    ellipsis: true,
    fixed: "left",
    customRender: ({index}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  },
  {
    title: i18n.t("tenantPackage.table.packageNo"),
    width: 80,
    ellipsis: true,
    dataIndex: "packageNo",
    key: "packageNo"
  },
  {
    title: i18n.t("tenantPackage.table.name"),
    width: 60,
    ellipsis: true,
    dataIndex: "name",
    key: "name"
  },
  {
    title: i18n.t("tenantPackage.table.createTenantMax"),
    width: 60,
    ellipsis: true,
    dataIndex: "createTenantMax",
    key: "createTenantMax"
  },
  {
    title: i18n.t("tenantPackage.table.createUserMax"),
    width: 60,
    ellipsis: true,
    dataIndex: "createUserMax",
    key: "createUserMax"
  },
  {
    title: i18n.t("tenantPackage.table.createAppMax"),
    width: 60,
    ellipsis: true,
    dataIndex: "createAppMax",
    key: "createAppMax"
  },
  {
    title: i18n.t("tenantPackage.table.expireLong"),
    width: 60,
    ellipsis: true,
    dataIndex: "expireLong",
    key: "expireLong"
  },
  {
    title: i18n.t("public.name.createTime"),
    width: 100,
    ellipsis: true,
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  // {
  //   title: i18n.t("public.name.createBy"),
  //   width: 80,
  //   ellipsis: true,
  //   dataIndex: "createBy",
  //   key: "createBy"
  // },
  // {
  //   title: i18n.t("tenantPackage.table.tenantKey"),
  //   width: 80,
  //   ellipsis: true,
  //   dataIndex: "tenantKey",
  //   key: "tenantKey"
  // },
  // {
  //   title: i18n.t("public.name.remark"),
  //   width: 80,
  //   ellipsis: true,
  //   dataIndex: "remark",
  //   key: "remark"
  // },
  {key: "action", fixed: "right", width: 120, dataIndex:"action"},
]

let searchData = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: []
})
const {searchForm, tablePagination, list} = toRefs(searchData)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list()
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}
function detail(pagination) {

}
function add() {
  refTenantPackageEdit.value?.addShow()
}
function edit(text) {
  refTenantPackageEdit.value?.editShow(text)
}
function del(id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new TenantPackageDelete();
      let ids = []
      ids.push(id)
      api.post({ ids: ids}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}
function exportToExcel() {
  exportButtonLoading.value = true;
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const api = new TenantPackageExport();
  api.getDownLoad(args).then((res) => {
    exportButtonLoading.value = false;
  });
}

function get_list() {
  loading.value = true;
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  const api = new TenantPackageList();
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.TenantPackage'));

onMounted(()=>{
  get_list();
})
defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>