const enUS = {
    succeedMsg: {
        0: 'Succeed',
    },
    errorMsg: {
        500100: 'Server-side exception',
        500101: 'Parameter error',
        500102: 'Parameter validation exception',
        500103: 'The request is illegal',
        500104: 'Visits are too frequent',
        500105: 'Object already exists',
        500106: 'The operation failed, please try again',
        500107: 'There is an association, deletion is prohibited',
        500108: 'Export exceptions',

        500200: 'Login expired, please log in again',
        500201: 'The account number or password cannot be empty',
        500202: 'The phone number cannot be empty',
        500203: 'The phone number is in the wrong format',
        500204: 'The phone number does not exist',
        500205: 'The password is incorrect',
        500206: 'The account does not exist',
        500207: 'Account numbers can only contain numbers, English, and underscores',
        500208: 'Authentication failed',
        500209: 'The two passwords are not the same',
        500210: 'Duplicate accounts',
        500211: 'The account has been locked',
        500212: 'Captcha failed',
        500213: 'The phone number is wrong',
        500214: 'There is no tenant corresponding to the domain name, contact your administrator',
        500215: 'exists the same role name',

        500300: 'If the OCCP service is abnormal, contact the administrator',
        500301: 'Electric piles are not available',
        500302: 'The pile configuration is empty',
        500303: 'The battery is offline, please check if it is available',
        500304: 'The pile port is not available',
        500305: 'The gun is not inserted or the current gun is not ready',
        500306: 'Start charging failure',
        500307: 'Bundled charging failure',

        500401: 'Insufficient balance',
        500402: 'The order does not exist',
        500403: 'The order was not paid',
        500404: 'The order has been paid',
        500405: 'Order status is not [Paid Unused]',

        500501: 'The item has been sold in seconds',
        500502: 'Lightning Deals cannot be repeated',
        500503: 'Second kill failure',

        500601: 'The file cannot be empty',
        500602: 'File format error',
        500603: 'The Excel workbook is empty',
        500604: 'Failed to read file',
    },
    public: {
        name: {
            nextStep: 'Next Step',
            status: "State",
            hello: "Hello",
            login: "Login",
            logout: "Logout",
            username: "Username",
            email: "E-mail",
            password: "Password",
            remember: 'Remember me',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirm_password: "Confirm Password",
            reset_password: "Reset Password",
            captcha: "Captcha",
            getCaptcha: "Get Captcha",
            more: "More",
            add: "Add",
            batchDel: "Batch Delete",
            Export: 'Export',
            Import: 'Import',
            DownloadTemp: 'Download Template',
            ImportTips: 'Click or drag file to this area to upload',
            edit: "Edit",
            del: "Delete",
            detail: "Detail",
            yes: "Yes",
            no: "No",
            empty: "Empty",
            are_you_sure_delete: "Are you Sure Want To Delete This Item",
            action_cannot_resumed: "This Action Cannot Be Resumed",
            are_you_sure_operate: 'Are you sure about the current operation',
            operate_remind: 'Current operation is sensitive, please be cautious',
            search: "Search",
            reset: "Reset",
            spread: "Spread",
            retract: "Retract",
            show: "Show",
            no_page: "No Page",
            service: "Service",
            phone_country_code: "Phone Country Code",
            name: "Name",
            phone: "Phone",
            sex: "Sex",
            country: "Country",
            address: "Address",
            birthday: "Birthday",
            remark: "Remark",
            personal: "Personal",
            enclosure: "Enclosure",
            logo: "Logo",
            submit:"Submit",
            unknown: "Unknown",
            setting: 'Setting',
            index: 'Index',
            createBy: "Create By",
            createTime: "Create Time",
            updateBy: "Update By",
            updateTime: "Update Time",
            type: 'Type',
            args: 'Parameter',
            qrcode: 'QR Code',
            query: 'Query',
            refund: 'Refund',
            cancel: 'Cancel',
            revoke: 'Revoke',
        },
        msg: {
            unRead: 'UnRead',
            read: 'Read',
            alarm: 'Alarm',
            reminder: 'Reminder',
            advertise: 'Advertise'
        },
        language: {
            zhTW: "Chinese(Traditional)",
            enUS: "English",
            zhCN: "Chinese (Simplified)",
            daDK: "Danish",
            idID: "Indonesian",
            msMY: "Malay",
            thTH: "Thai",
            viVN: "Vietnamese",
            frFR: "French",
            plPL: "Polish",
            it: "Italian",
        },
        timeType: {
            0: 'Year',
            1: 'Month',
            2: 'Week',
            3: 'Day',
        },
        promptsToSelectTime: 'Please select a time',
        pleaseSelect: 'Please select',
        pileStatus: {
            0: 'Avaliable',
            1: 'Charging',
            2: 'Unavailable',
        },
        isTrue: {
            0: "No",
            1: "Yes",
        },
        chargerType: {
            0: "AC",
            1: "DC",
        },
        registerType:{
            0: "Email",
            1: "Phone",
        },
        terminal:{
          1: "H5",
          2: "APP",
        },
        doingStatus:{
            0: 'Pending',
            1: 'Processing',
            2: 'Completed',
            3: 'Cancel',
            4: 'Expired',
            5: 'Termination',
        },
        consumeType: {
            0: "Recharge",
            1: "Charging Consumption",
            2: "Charging Cancellation",
            3: "Balance",
            4: 'Purchase Plan',
            5: 'Cancel Plan',
            6: 'Renewal Plan',
            7: 'Refund',
            8: 'Ticket',
            9: 'Cancel fines',
            10: 'Withdrawal',
            11: 'Full gift',
            12: 'Revoke',
        },
        payStatus: {
            0: "Unpaid",
            1: "Paid",
            2: "Charging",
            3: "Completed",
            4: "Cancel",
        },
        chargeType: {
            0: "By Time",
            1: "By Order",
            2: "By User Plan",
        },
        status: {
            0: "Unknown",
            1: "Normal",
            2: "Disable",
            3: "Delete",
        },
        sex: {
            1: "Man",
            2: "Woman",
            3: "Unknown"
        },
        placeholder: {
            please_select: "Please Select",
        },
        table: {
            name: "Name",
            status: "State",
            action: "Action",
            content: "Content",
            created_at: "Create Time",
            image: "Image",
            email: "E-mail",
            order_id: "Order",
            sex: "Sex",
            last_ip: "Last Ip",
            phone_country_code: "Please Select Phone Country Code",
            phone: "Phone",
        },
        field: {
            name: "Name",
            username: "Username",
            content: "Content",
            phone_country_code: "Phone Country Code",
            phone: "Phone",
            email: "E-mail",
            order_id: "Order",
            portrait: "Portrait",
            status: "State",
            image: "Image",
            sex: "Sex",
        },
        validate: {
            required: {
                id: "Lack ID",
                username: "Please Entry User Name",
                password: "Please Entry Password",
                repassword: "Please Repeat password",
                name: "Please Entry Name",
                phone: "Please Entry Phone",
                email: "Please Entry E-mail",
                portrait: "Please Upload Portrait",
            },
            tips: {
                inconsistent_password: "Inconsistent password",
                length_should: "Length Should Be {min} To {max}"
            }
        }
    },
    language: {
        zhTW: "Chinese(Traditional)",
        enUS: "English",
        zhCN: "Chinese (Simplified)",
        daDK: "Danish",
        idID: "Indonesian",
        msMY: "Malay",
        thTH: "Thai",
        viVN: "Vietnamese",
        frFR: "French",
        plPL: "Polish",
        it: "Italian",
    },
    nav: {
        name: {
            Dashboard: "Dashboard",
            RegionDashboard: 'Piles Overview ',
            BigScreen: 'Large Screen',

            AuthorityManage: "Authority Manage",
            BalanceLogManage: "Balance LogManage",
            CarManage: "Car Manage",
            CarImagesManage: "Car ImagesManage",
            ChargeStationImagesManage: "Charge Point ImageManage",
            ChargingStationManage: "Charge PointManage",
            ConnectorManage: "Connector Manage",
            EmployeeManage: "Operator Manage",
            EventLogManage: "Event LogManage",
            ConnectorLogManage: 'Connector Log',
            MeterManage: "Meter Manage",
            OrderManage: "Order Manage",
            PlanManage: "Plan Manage",
            RegionManage: "Region Manage",
            RegionImagesManage: "Region Image Manage",
            RepairLogManage: "Repair LogManage",
            RoleManage: "Role Manage",
            TransactionLogManage: "Transaction LogManage",
            UserManage: "User Manage",
            UserPlanManage: "User PlanManage",
            GetConfiguration: "Get Configuration",
            DataTransfer: "Data Transfer",

            TenantManagement: "System Management",
            SuperTenantManage: "Super Tenant",
            TenantManage: "Tenant Manage",
            TenantInfo: 'Tenant Manage',
            TenantPackage: 'Tenant Package',

            NoticeManage: "Notice Manage",
            PublicityManage: 'Publicity Manage',

            ChargerSetting: "Charger Setting",
            StatisticsManage: 'Statistics',
            ServiceManage: 'Service Manage',
            CardManage: 'Card Manage',

            AppManagement: "App Management",
            LogManagement: "Log Management",
            AppVersionManage: "App Version Manage",
            WallerManage: 'Account limit',

            iPadManagement: "iPad Management",
            iPadManage: "iPad Manage",
        },
        table: {
            order_id: "Order ID",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "Order ID",
            name: "Name",
            url: "Url",
            status: "State",
            icon: "Icon",
        },
    },
    login: {
        validate: {
            required: {
                username: "Please Entry Username",
                email: "Please Entry Email",
                password: "Please Entry Password",
                captcha: "Please Entry Captcha",
            }
        }
    },
    authority:{
        name: {
            title: "Authority",
            detailTitle: "Authority Detail",
            editTitle: "Edit Authority",
        },
        table: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        validate: {
            required: {
                id_aut: "ID Required",
                a_id_aut: "Parent Authority Required",
                name: "Authority Required",
                resource_name: "Resource Name Required",
                type: "Type Required",
                sort: "Sort Required",
            }
        },
    },
    balanceLog:{
        rechargePackage: 'Recharge Package',
        addRule: 'Add Rule',
        gift: {
            enable: 'Is it enabled',
            percentage: 'By Percentage',
            star: 'Starting value',
            end: 'End value',
            give: 'Limit',
        },
        vGift: {
            enable: 'Please choose whether to enable it or not',
            percentage: 'Please choose whether to use percentage or not',
            star: 'Please enter the starting value',
            end: 'Please enter the end value',
            give: 'Please enter the credit limit',
        },
        name: {
            title: "Balance Log",
            detailTitle: "Balance Log Detail",
            editTitle: "Edit Balance Log",
            ticket: 'Ticket',
            cancelFines: 'Cancel Fines'
        },
        table: {
            id_bal_log: "ID",
            id_use: "Use",
            type: "Type",
            amount: "Amount",
            log_time: "Log Time",
            tenant_key: "Tenant Key",
            status: "Status",
            payment_method: "Payment Method",
        },
        field: {
            id_bal_log: "ID",
            id_use: "Uer",
            type: "Type",
            amount: "Amount",
            log_time: "Log Time",
            tenant_key: "Tenant Key",
            status: "Status",
            payment_method: "Payment Method",
        },
        validate: {
            required: {
                id_bal_log: "ID Required",
                id_use: "Uer Required",
                type: "Type Required",
                amount: "Amount Required",
                log_time: "Log Time Required",
                tenant_key: "Tenant Key Required",
                status: "Status Required",
                payment_method: "Payment Method Required",
            }
        },
    },
    car:{
        name: {
            title: "Car",
            detailTitle: "Car Detail",
            editTitle: "Edit Car",
        },
        table: {
            id_car: "ID",
            id_use: "Uer",
            model: "Model",
            car_license: "License",
            max_years_old: "Max Year",
            manufacture_year: "Manufacture Year",
            maximum_power: "Maximum Power",
            is_second_hand: "Is Second Hand",
            vehicle_inspection_date: "Vehicle Inspection Date",
            insurance_expiry_date: "Insurance Expiry Date",
            tenant_key: "Tenant Key",
        },
        field: {
            id_car: "ID",
            id_use: "Uer",
            model: "Model",
            car_license: "License",
            max_years_old: "Max Year",
            manufacture_year: "Manufacture Year",
            maximum_power: "Maximum Power",
            is_second_hand: "Is Second Hand",
            vehicle_inspection_date: "Vehicle Inspection Date",
            insurance_expiry_date: "Insurance Expiry Date",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_car: "ID Required",
                id_use: "Uer Required",
                model: "Model Required",
                car_license: "License Required",
                max_years_old: "Max Year Required",
                manufacture_year: "Manufacture Year Required",
                maximum_power: "Maximum Power Required",
                is_second_hand: "Is Second Hand Required",
                vehicle_inspection_date: "Vehicle Inspection Date Required",
                insurance_expiry_date: "Insurance Expiry Date Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    carImages:{
        name: {
            title: "Car Images",
            detailTitle: "Car Images Detail",
            editTitle: "Edit Car Images",
        },
        table: {
            id_car_ima: "ID",
            id_car: "Car",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_car_ima: "ID",
            id_car: "Car",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_car_ima: "ID Required",
                id_car: "Car Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    chargeStationImages:{
        name: {
            title: "Charge Point Image",
            detailTitle: "Charge Point Image Detail",
            editTitle: "Edit Charge Point Image",
        },
        table: {
            id_ima: "ID",
            id_cha_poi: "Charge Point",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_ima: "ID",
            id_cha_poi: "Charge Point",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_ima: "ID Required",
                id_cha_poi: "Charge Point Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    chargingStation:{
        tooltip: {
            postpaid: "Set charging order payment sequence, default to regional settings",
            billingMethod: "Set charging order billing method, default to set by region",
        },
        billingMethodType: {
            0:"By Time",
            1:"By Power",
            2:"By User Plan",
            3:"By APP",
            4:"By Region",
        },
        updateFirmware: {
            identifier: "Serial number",
            location: "Remote address",
            retrieveDate: "Date of retrieval",
        },
        uValidate: {
            identifier: "Please enter the serial number",
            location: "Please enter a remote address",
            retrieveDate: "Please select a search date",
        },
        setting: {
            index: "serial number",
            readonly: "Read-only",
            key: "Set the key",
            value: "Set the value",
        },
        settingField: {
            readonly: "Select whether it is read-only",
            key: "The set key cannot be empty",
            value: "The setting value cannot be empty",
        },
        name: {
            title: "Charge Point",
            detailTitle: "Charge Point Detail",
            editTitle: "Edit Charge Point",
            quickAdd: "Add Charge Point",
        },
        table: {
            id_cha_poi: "ID",
            id_met: "Meter",
            charge_box_id: "Charge Box Id",
            firmwareVersion: 'Firmware version',
            last_heartbeat_time: "Last Heartbeat Time",
            monthly_fee: "Monthly Fee",
            charger_type: "Charger Type",
            tenant_key: "Tenant Key",
            name: "Name",
            heartbeat_interval: "Heartbeat Interval",
            enable: "Enable",
            maxCurrent: "Maximum current",
            minCurrent: "Minimum current",
            isPrivate: "Is Private",
            isReadMeter: "Whether to read the table",
            isReset: "Whether to restart (restart can take effect)",
            action: {
                updateFirmware: "Firmware upgrade",
                hardReset: "Hard Reset",
                softReset: "Soft Reset"
            }
        },
        validate: {
            id_cha_poi: "ID Required",
            id_met: "Meter Required",
            charge_box_id: "Charge Box Id Required",
            last_heartbeat_time: "Last Heartbeat Time Required",
            monthly_fee: "Monthly Fee Required",
            charger_type: "Charger Type Required",
            tenant_key: "Tenant Key Required",
            name: "Name Required",
            heartbeat_interval: "Heartbeat Interval Required",
            enable: "Enable Required",
            maxCurrent: "Please fill in the maximum current",
            minCurrent: "Please fill in the minimum current",
        },
    },
    connector:{
        name: {
            title: "Connector",
            detailTitle: "Connector Detail",
            editTitle: "Edit Connector",
            startTransaction: "Start Transaction",
            stopTransaction: "Stop Transaction",
        },
        table: {
            id_con: "ID",
            id_cha_poi: "Charge Point",
            name: "Name",
            connector_id: "Connector Id",
            status: "Status",
            tenant_key: "Tenant Key",
            power: "Power(kw)",
            type: "Type",
        },
        field: {
            id_con: "ID",
            id_cha_poi: "Charge Point",
            name: "Name",
            connector_id: "Connector Id",
            status: "Status",
            tenant_key: "Tenant Key",
            power: "Power(kw)",
            type: "Type",
        },
        validate: {
            required: {
                id_con: "ID Required",
                id_cha_poi: "Charge Point Required",
                name: "Name Required",
                connector_id: "Connector Id Required",
                status: "Status Required",
                tenant_key: "Tenant Key Required",
                power: "Power Required(kw)",
                type: "Type Required",
            }
        },
    },
    employee:{
        name: {
            title: "Operator",
            detailTitle: "Operator Detail",
            editTitle: "Edit Operator",
        },
        table: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            whatsAppPhone: 'WhatsApp Number',
            remark: "Remark",
            status: "Status",
            name: "Name",
            commission_rate: "Commission Rate",
            yedpay_api_key: "Yedpay API Key",
            yedpay_sign_key: "Yedpay Sign Key",
            tenant_key: "Tenant Key",
        },
        field: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            whatsAppPhone: 'WhatsApp Number',
            remark: "Remark",
            status: "Status",
            name: "Name",
            commission_rate: "Commission Rate",
            yedpay_api_key: "Yedpay API Key",
            yedpay_sign_key: "Yedpay Sign Key",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_emp: "ID Required",
                id_rol: "Role Required",
                account: "Account Required",
                password: "Password Required",
                salt: "Salt Required",
                last_login_time: "Last Login Time Required",
                register_time: "Register Time Required",
                login_failure_counter: "Login Failure Counter Required",
                email: "Email Required",
                whatsAppPhone: 'Please fill in WhatsApp Number',
                remark: "Remark Required",
                status: "Status Required",
                name: "Name Required",
                commission_rate: "Commission Rate Required",
                yedpay_api_key: "Yedpay API Key Required",
                yedpay_sign_key: "Yedpay Sign Key Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    eventLog:{
        name: {
            title: "Event Log",
            detailTitle: "Event Log Detail",
            editTitle: "Edit Event Log",
        },
        table: {
            id_env_log: "ID",
            run_time: 'Execution Time(ms)',
            class_method: 'Execution Method',
            event_time: "Event Time",
            log: "Log",
            tenant_key: "Tenant Key",
            resCode: 'Response Code',
        },
        field: {
            id_env_log: "ID",
            event_time: "Event Time",
            log: "Log",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_env_log: "ID Required",
                class_method: 'Please fill in the execution method',
                event_time: "Event Time Required",
                log: "Log Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    meter:{
        name: {
            title: "Meter",
            detailTitle: "Meter Detail",
            editTitle: "Edit Meter",
        },
        table: {
            id_met: "ID",
            id_reg: "Region",
            name: "Meter Name",
            current_threshold: "Current Threshold",
            tenant_key: "Tenant Key",
            loadByTactics: 'Turn on Policies'
        },
        field: {
            id_met: "ID",
            id_reg: "Region",
            name: "Meter Name",
            current_threshold: "Current Threshold",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_met: "ID Required",
                id_reg: "Region Required",
                name: "Meter Name Required",
                current_threshold: "Current Threshold Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    order:{
        name: {
            title: "Order",
            detailTitle: "Order Detail",
            editTitle: "Edit Order",
            cancel: 'Cancellation of order',
            cancelAndRefund: 'Cancel order and refund',
        },
        table: {
            id_ord: "ID",
            id_car: "Car",
            id_con: "Connector",
            order_time: "Order Time",
            type: "Type",
            amount: "Amount",
            charge_start_time: "Charge Start Time",
            charge_end_time: "Expected End Time",
            chargeEndTimeActual: 'Actual End Time',
            status: "Status",
            payment_time: " Payment Time",
            electricity: "Purchase electricity",
            charge_minutes: "Purchase duration",
            currentElectricity: "Current Electricity",
            ref_id_pla: "Plan",
            price: "Price",
            commission_rate: "Commission Rate",
            tenant_key: "Tenant Key",
            id_tra_log: "TransactionLogId",
            pay_type: "PayType",
            payment_method: "Payment Method",
        },
        validate: {
            id_ord: "ID Required",
            id_car: "Car Required",
            id_con: "Connector Required",
            order_time: "Order Time Required",
            type: "Type Required",
            amount: "Amount Required",
            charge_start_time: "Charge Start Time Required",
            charge_end_time: "Charge End Time Required",
            status: "Status Required",
            payment_time: " Payment Time Required",
            electricity: "Electricity Required",
            ref_id_pla: "Plan Required",
            price: "Price Required",
            commission_rate: "Commission Rate Required",
            tenant_key: "Tenant Key Required",
            id_tra_log: "TransactionLogId Required",
            charge_minutes: "ChargeMinutes Required",
            pay_type: "PayType Required",
            payment_method: "Payment Method Required",
        },
    },
    plan:{
        name: {
            title: "Plan",
            detailTitle: "Plan Detail",
            editTitle: "Edit Plan",
        },
        table: {
            id_pla: "ID",
            name: "Plan Name",
            price_per_month: "Price",
            contract_period: "Contract Period",
            free_charging_time_limit: "Free Charging Time",
            overtime_charge_price: "Overtime Price",
            tenant_key: "Tenant Key",
        },
        field: {
            id_pla: "ID",
            name: "Plan Name",
            price_per_month: "Price",
            contract_period: "Contract Period",
            free_charging_time_limit: "Free Charging Time",
            overtime_charge_price: "Overtime Price",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_pla: "ID Required",
                name: "Plan Name Required",
                price_per_month: "Price Required",
                contract_period: "Contract Period Required",
                free_charging_time_limit: "Free Charging Time Required",
                overtime_charge_price: "Overtime Price Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    region:{
        tooltip: {
            byPowerUnit: "The step size for each jump, if less than one jump, it will be calculated as one jump. If calculated based on the actual electricity consumption of the order, please set it to zero",
            byTimeUnit: "The step size for each jump, if less than one jump, it will be calculated as one jump. If calculated based on the actual time of the order, please set it to zero",

            maximumPower: "When charging according to the battery level, the upper limit of a single charge is not limited if left blank or zero, and is not limited by default",
            maximumMinute: "When charging according to time, the upper limit of a single charging time is left blank or zero, which is not limited by default",
        },
        billingMethodType:{
            0: "By Time",
            1: "By Power",
            2: "By APP",
        },
        name: {
            title: "Region",
            detailTitle: "Region Detail",
            editTitle: "Edit Region",
        },
        table: {
            id_reg: "ID",
            name: "Region Name",
            ac_price_per_electricity: "AC Price Per Electricity",
            ac_price_per_minute: "AC Price Per Minute",
            lon: "Lon",
            lat: "Lat",
            address: "Address",
            dc_price_per_electricity: "DC Price Per Electricity",
            dc_price_per_minute: "DC Price Per Minute",
            tenant_key: "Tenant Key",
            businessAllDay: "Open All Day",
            images: "Images",
            byPowerUnit: "By Power Unit",
            byTimeUnit: "By Time Unit",
            postpaid: "Postpaid",
            billingMethod: "Billing Method",
            businessHours: "Business hours",

            maximumPower: "Upper limit of single charging capacity",
            maximumMinute: "Upper limit of single charging time",
            directDebit: "direct debit",

            enableTimeoutFine: "Opening timeout penalty",
            timeoutFine: "Timeout time(min)",
            timeoutCharging: "Overtime billing",
        },
        validate: {
            id_reg: "ID Required",
            name: "Region Name Required",
            ac_price_per_electricity: "AC Price Per Electricity Required",
            ac_price_per_minute: "AC Price Per Minute Required",
            lon: "Lon Required",
            lat: "Lat Required",
            address: "Address Required",
            dc_price_per_electricity: "DC Price Per Electricity Required",
            dc_price_per_minute: "DC Price Per Minute Required",
            tenant_key: "Tenant Key Required",
            businessAllDay: "Please select whether it is open all day",
            byPowerUnit: "Please fill in the Unit of measurement based on electricity quantity",
            byTimeUnit: "Please fill in Unit of measurement by time",
            postpaid: "Please choose whether to pay after",
            billingMethod: "Please select a billing method",
            businessHours: "Select business hours",

            maximumPower: "Please fill in the upper limit of single charging capacity",
            maximumMinute: "Please fill in the upper limit of single charging time",
            directDebit: "Please choose whether to automatically deduct the payment",

            enableTimeoutFine: "Please select whether to enable timeout penalty",
            timeoutFine: "Please fill in the timeout period(min)",
            timeoutCharging: "Please fill in the overtime billing",
        },
    },
    regionImages:{
        name: {
            title: "Region Image",
            detailTitle: "Region Image Detail",
            editTitle: "Edit Region Image",
        },
        table: {
            id_reg_img: "ID",
            id_reg: "Region",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_reg_img: "ID",
            id_reg: "Region",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_reg_img: "ID Required",
                id_reg: "Region Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    repairLog:{
        name: {
            title: "Repair Log",
            detailTitle: "Repair Log Detail",
            editTitle: "Edit Repair Log",
        },
        table: {
            id_rep_log: "ID",
            id_car: "Car",
            amount: "Amount",
            repair_date: "Repair Date",
            remark: "Remark",
            tenant_key: "Tenant Key",
        },
        field: {
            id_rep_log: "ID",
            id_car: "Car",
            amount: "Amount",
            repair_date: "Repair Date",
            remark: "Remark",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_rep_log: "ID Required",
                id_car: "Car Required",
                amount: "Amount Required",
                repair_date: "Repair Date Required",
                remark: "Remark Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    role:{
        name: {
            title: "Role",
            detailTitle: "Role Detail",
            editTitle: "Edit Role",
        },
        table: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
        },
        field: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
            authorityList: "Authority List"
        },
        validate: {
            required: {
                id_rol: "ID Required",
                name: "Role Required",
                remark: "Remark Required",
            }
        },
    },
    roleAuthority:{
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority",
        },
        table: {
            id_rol_aut: "ID",
            id_rol: "Role",
            id_aut: "Authority",
        },
        field: {
            id_rol_aut: "ID",
            id_rol: "Role",
            id_aut: "Authority",
        },
        validate: {
            required: {
                id_rol_aut: "ID Required",
                id_rol: "Role Required",
                id_aut: "Authority Required",
            }
        },
    },
    transactionLog:{
        name: {
            title: "Transaction Log",
            detailTitle: "Transaction Log Detail",
            editTitle: "Edit Transaction Log",
        },
        table: {
            id_tra_log: "ID",
            id_con: "Connector IDt",
            event_time: "Event Time",
            id_tag: "ID Tag",
            start_time: "Start Time",
            start_value: "Start Value",
            stop_time: "Stop Time",
            stop_value: "Stop Value",
            stop_reason: "Stop Reason",
            fail_reason: "Fail Reason",
            tenant_key: "Tenant Key",
            current_electricity: "Current Electricity",
        },
        field: {
            id_tra_log: "ID",
            id_con: "Connector IDt",
            event_time: "Event Time",
            id_tag: "ID Tag",
            start_time: "Start Time",
            start_value: "Start Value",
            stop_time: "Stop Time",
            stop_value: "Stop Value",
            stop_reason: "Stop Reason",
            fail_reason: "Fail Reason",
            tenant_key: "Tenant Key",
            current_electricity: "Current Electricity",
        },
        validate: {
            required: {
                id_tra_log: "ID Required",
                id_con: "Connector IDt Required",
                event_time: "Event Time Required",
                id_tag: "ID Tag Required",
                start_time: "Start Time Required",
                start_value: "Start Value Required",
                stop_time: "Stop Time Required",
                stop_value: "Stop Value Required",
                stop_reason: "Stop Reason Required",
                fail_reason: "Fail Reason Required",
                tenant_key: "Tenant Key Required",
                current_electricity: "Current Electricity Required",
            }
        },
    },
    user:{
        name: {
            title: "User",
            detailTitle: "User Detail",
            editTitle: "Edit User",
        },
        table: {
            id_use: "ID",
            balance: "Balance",
            account: "Account",
            password: "Password",
            register_time: "Register Time",
            email: "Email",
            token: "Token",
            salt: "Salt",
            tenant_key: "Tenant Key",
            phone: "Phone",
            register_type: "Register Type",
            terminal: "Terminal",
        },
        field: {
            id_use: "ID",
            balance: "Balance",
            account: "Account",
            password: "Password",
            register_time: "Register Time",
            email: "Email",
            token: "Token",
            salt: "Salt",
            tenant_key: "Tenant Key",
            phone: "Phone",
            register_type: "Register Type",
            terminal: "Terminal",
            chargingCard: 'Charging card number',
            chargingType: 'Priority charging mode',
            chargingDefDuration: 'Card charging time(min)',
            chargingDefPower: 'Card charging capacity(kWh)'
        },
        validate: {
            required: {
                id_use: "ID Required",
                balance: "Balance Required",
                account: "Account Required",
                password: "Password Required",
                register_time: "Register Time Required",
                email: "Email Required",
                token: "Token Required",
                salt: "Salt Required",
                tenant_key: "Tenant Key Required",
                phone: "Phone Required",
                register_type: "Register Type Required",
                terminal: "Terminal Required",
                chargingCard: 'Please fill in the charging card number',
                chargingType: 'Please select card priority charging mode',
                chargingDefDuration: 'Please fill in the card charging time',
                chargingDefPower: 'Please fill in the card charging amount(kWh)'
            }
        },
    },
    userPlan:{
        name: {
            title: "User Plan",
            detailTitle: "User Plan Detail",
            editTitle: "Edit User Plan",
        },
        table: {
            id_use: "User",
            id_pla: "Plan",
            arrearsCount: 'Arrears Count',
            start_date: "Start Date",
            tenant_key: "Tenant Key",
            createType: 'Create Identity',
            updateByMonth: 'Advance Payment Time',

            total: 'Prepaid months',
            amount: 'Expected payment amount',
            prePayData: 'Expected payment month',
        },
        validate: {
            id_use: "Uer Required",
            id_pla: "Plan Required",
            arrearsCount: 'Please fill in the number of months in arrears',
            start_date: "Start Date Required",
            tenant_key: "Tenant Key Required",
        },
    },
    dashboard:{
        name: {
            totalChargers:"Total Chargers",
            availableChargers:"Available Charger",
            inUseChargers:"In Use Charger",
            unavailableChargers:"Unavailable Charger",
            portUsage: "Charger Usage",

            energyUsage:"Energy Usage(kWh)",
            totalRevenue: "Total Revenue",
            totalEnergy: "Total Energy",
            newUser: "New User",
        }
    },
    appVersionManage:{
        field: {
            androidVersion:"Android Version",
            androidDownloadLink:"Android Download Link",
            androidComplieNumber:"Android Compile Number",
            iosVersion:"IOS Version",
            iosBundleId:"IOS Bundle Id",
            iosComplieNumber:"IOS Compile Number",
            isForcedUpdate: 'Is Forced Update'
        },
        validate: {
            required: {
                androidVersion:"Android Version Required",
                androidDownloadLink:"Android Download Link Required",
                androidComplieNumber:"Android Compile Number Required",
                iosVersion:"IOS Version Required",
                iosBundleId:"IOS Bundle Id Required",
                iosComplieNumber:"IOS Compile Number Required",
                isForcedUpdate: 'Please choose whether to force updates'
            }
        },
    },
    dataTransfer: {
        field: {
            messageId: "Setting Key",
            dataStr: "Setting Value"
        }
    },
    superTenantManage:{
        name: {
            title: "Permissions",
            detailTitle: "Details",
            editTitle: "Edit"
        },
        searchForm: {
            dataSourceKey: "Data Source Key",
            url: "Url",
            username: "UserName",
            tenant: "Tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
        },
        searchFormPlaceholder: {
            dataSourceKey: "Please enter data source key",
            url: "Please enter the link address",
            username: "Please enter account",
            tenant: "Please enter tenant name",
            isEnable: "Please choose whether to enable",
            isCreate: "Please choose to create a logo",
        },
        table: {
            index: "Index",
            dataSourceKey: "Data Source Key",
            url: "Url",
            host: "Host",
            port: "Port",
            dbName: "Database Name",
            username: "Username",
            password: "Password",
            tenant: "tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
            domain: "Domain",
            action: {
                testConn: "Conn",
                create: "Create",
                edit: "Edit",
                del: "Delete",
            }
        },
        field: {
            id: "ID",
            datasourceKey: "Data Source Key",
            seckey: "Access keys",
            url: "Url",
            host: "Host",
            port: "Port",
            dbName: "Database Name",
            username: "UserName",
            password: "Password",
            tenant: "Tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
            remark: "Remark",
            domain: "Domain",
            onesignalKey: 'APP notification key',
        },
        validate: {
            required: {
                id: "Please fill in the ID",
                dataSourceKey: "Please fill in the Data Source Key",
                seckey: "Please fill in the SecKey",
                url: "Please fill in the link Url",
                host: "Please fill in the host",
                port: "Please fill in the port",
                dbName: "Please fill in the database name",
                username: "please enter username",
                password: "Please fill in the password",
                tenant: "Please fill in the tenant name",
                isEnable: "Please select an active state",
                remark: "Please fill in the remarks",
                domain: "Please fill in the domain",
                onesignalKey: 'Please fill in the APP notification key',
            }
        }
    },
    iPadManage:{
        field: {
            id: "ID",
            index: "Index",
            machineCode: "Machine Code",
            name: "Name",
            regId: "Region ID",
            regName: "Region Name",
            seckey: "SecKey",
            args: "Args",
            createBy: "Create By",
            createTime: "Create Time",
            updateBy: "Update By",
            updateTime: "Update Time",
            remark: "Remark",
        },
        validate: {
            id: "Please fill in the ID",
            machineCode: "Please fill in the machine code",
            name: "Please fill in the name",
            regId: "Please fill in the region id",
            seckey: "Please fill in the SecKey",
            args: "Please fill in the args",
            remark: "Please fill in the remark",
        }
    },
    noticeManage:{
        noticeName:{
            0: "E-mail",
            1: "Phone"
        },
        noticeType: {
            0: "Warning",
            1: "Remind",
            2: "Announcement",
            3: "Timeout",
        },
        field: {
            id: "ID",
            index: "Index",
            toFrom: "Account",
            type: "Account Type",
            noticeType: "Notification Type",
            status: "Is Enable",

            createBy: "Create By",
            createTime: "Create Time",
            updateBy: "Update By",
            updateTime: "Update Time",
            remark: "Remark",
        },
        validate: {
            id: "Please fill in the ID",
            type: "Please choose account type",
            toFrom: "Please fill in the account",
            noticeType: "Please choose notification type",
            status: "Please choose whether to enable",
            remark: "Please fill in the remark",
        }
    },
    publicityManage:{
        publicityType: {
            0: 'Terms of Service',
            1: 'About us',
            2: 'Announcement',
            3: 'Notice',
            4: 'Remind',
        },
        field: {
            id: 'ID',
            index: 'Index',
            type: 'Advertisement Type',
            status: 'Is Enabled',
            title: 'Title',
            content: 'Content',
            tenantKey: 'Tenant Key',

            createBy: 'create By',
            createTime: 'Create Time',
            updateBy: 'Update By',
            updateTime: 'Update Time',
            remark: 'Remark',
        },
        validate: {
            id: 'Please fill in the ID',
            type: 'Please select the type of announcement',
            status: 'Please select whether to enable it',
            title: 'Please fill in the title of the announcement',
            content: 'Please fill in the content of the notice',
            remark: 'Please fill in the comments',
        }
    },
    statisticsManage: {
        table: {
            index: 'Serial Number',
            identifier: 'Pile Identifier',
            useNum: 'Use Number',
            chargeCapacity: 'Total Charge(kw)',

            startTime: 'Start Time',
            endTime: 'End Time'
        },
        validate: {
            identifier: 'Please enter the pile number',
            startTime: 'Please select a start time',
            endTime: 'Please select an end time'
        }
    },
    serviceManage: {
        type: {
            0: 'Drinks',
            1: 'Food',
            2: 'Restroom',
            3: 'Shops',
        },
        table: {
            id: 'ID',
            type: 'type',
            status: 'status',
            name: 'name',
            lng: 'longitude',
            lat: 'latitude',
            address: 'address',
            description: 'description',
            reId: 'reId'
        },
        validate: {
            type: 'Please select a type',
            status: 'Please select a status',
            name: 'Please fill in the name',
            lng: 'Please fill in the longitude',
            lat: 'Please fill in the latitude',
            address: 'Please fill in the address',
            description: 'Please fill in a description',
            reId: 'Associated region'
        }
    },
    companyManage: {
        field: {
            id: 'Primary Key',
            tId: 'Associate Tenant ID',
            name: 'Company Name',
            attn: 'Company Liaison',
            tel: 'Company Phone',
            address: 'Company Address',
            email: 'Company Email',
            invoiceDate: 'Invoice Date',
            invoiceNo: 'Invoice Number',
            contactPerson: 'Contact',
            contactTel: 'Contact number',
            contactEmail: 'Contact Email',
            monthlyFee: 'System access monthly fee preset value',
            company: 'Company Information'
        },
        validate: {
            id: 'Please enter a primary key',
            tId: 'Enter the associated tenant ID',
            name: 'Please enter a company name',
            attn: 'Please enter company contact person',
            tel: 'Please enter your company phone',
            address: 'Please enter your company address',
            email: 'Please enter your company email',
            invoiceDate: 'Please enter the invoice date',
            invoiceNo: 'Please enter the invoice number',
            contactPerson: 'Please enter a contact person',
            contactTel: 'Please enter your contact number',
            contactEmail: 'Please enter your contact email',
            monthlyFee: 'Please enter the default value of the monthly system access fee'
        }
    },
    tenantInfo: {
        table: {
            id: 'ID',
            pid: 'Parent Tenant ID',
            tenantKey: 'Tenant Key',
            name: 'Tenant Name',
            domain: 'Bind domain name',
            tpId: 'Associated Package',
            authId: 'Associated permissions',
            level: 'level',
            contact: 'Contact person',
            email: 'Contact email',
            phone: 'Contact number',
            startTime: 'Activation time',
            expireTime: 'Expiration time',
        },
        args:{
            yedPayExtendParams: 'YedPay Args',
            yedPayApiKey: 'YedPay ApiKey',
            yedPaySingKey: 'YedPay SingKey',

            mPayExtendParams: 'MPay Args',
            mPayPubKey: 'MPay PubKey',
            mPayPriKey: 'MPay PriKey',
        },
        validate: {
            id: 'Please enter an ID',
            pid: 'Please enter the parent tenant ID',
            tenantKey: 'Please fill in the tenant key',
            name: 'Please fill in the tenant name',
            domain: 'Please fill in the bound domain name',
            tpId: 'Please select an associated package',
            authId: 'Please select the associated permissions',
            contact: 'Please fill in the contact person',
            email: 'Please fill in the contact email',
            phone: 'Please fill in the contact phone number',
            startTime: 'Please select a start time',
            expireTime: 'Please select an expiration time',

            yedPayExtendParams: 'Please enter the YedPay parameter',
            yedPayApiKey: 'Please enter YedPay ApiKey',
            yedPaySingKey: 'Please enter YedPay SingKey',
            mPayExtendParams: 'Please enter MPay parameters',
            mPayPubKey: 'Please enter the MPay public key',
            mPayPriKey: 'Please enter the MPay private key',
        }
    },
    tenantPackage: {
        table: {
            id: 'ID',
            packageNo: 'Package number',
            name: 'Package Name',
            createTenantMax: 'Number of tenants that can be created',
            createUserMax: 'Number of users that can be created',
            createAppMax: 'Number of registrable apps',
            expireLong: 'Effective duration of the package',
            tenantKey: 'Tenant identification',
        },
        validate: {
            id: 'Please enter an ID',
            packageNo: 'Please enter the package number',
            name: 'Please enter the package name',
            createTenantMax: 'Please enter the number of tenants that can be created',
            createUserMax: 'Please enter the number of tenants that can be created',
            createAppMax: 'Please enter the number of registrable apps',
            expireLong: 'Please select the effective duration of the package',
            tenantKey: 'Please enter tenant key',
        }
    },
    card: {
        cardType: {
            0: 'Whitelist',
            1: 'Blacklist',
            2: 'System card',
            3: 'User card',
        },
        table: {
            cardId: 'ID',
            cardNo: 'Card number',
            cardType: 'Type',
            cardStatus: 'Is it enabled',
        },
        validate: {
            cardId: 'Please fill in the ID',
            cardNo: 'Please fill in the card number',
            cardType: 'Please select a type',
            cardStatus: 'Please select a status',
        }
    },
    waller: {
        wType: {
            1: 'Balance',
            2: 'Gift amount',
            3: 'Integral'
        },
        table: {
            walletId: 'ID',
            account: 'Account',
            type: 'Type',
            status: 'Freeze or not',
            balance: 'Balance',
        },
        validate: {
            account: 'Please fill in your account',
            type: 'Please select the type',
            status: 'Please select a status',
        }
    }
}
export default enUS