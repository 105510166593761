<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('user.field.account')" name="account">
                  <Input :placeholder="$t('user.validate.required.account')" v-model:value="searchForm.account"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('statisticsManage.table.identifier')" name="identifier">
                  <Input :placeholder="$t('chargingStation.table.charge_box_id')" v-model:value="searchForm.identifier" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('userPlan.table.id_pla')" name="planName">
                  <Input :placeholder="$t('userPlan.validate.id_pla')" v-model:value="searchForm.planName" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.status')" name="status">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}" allowClear>
                    <SelectOption :value="0"><Tag color="blue">{{ $t("public.doingStatus.0") }}</Tag></SelectOption>
                    <SelectOption :value="1"><Tag color="orange">{{ $t("public.doingStatus.1") }}</Tag></SelectOption>
                    <SelectOption :value="2"><Tag color="green">{{ $t("public.doingStatus.2") }}</Tag></SelectOption>
                    <SelectOption :value="4"><Tag color="cyan">{{ $t("public.doingStatus.4") }}</Tag></SelectOption>
                    <SelectOption :value="5"><Tag color="red">{{ $t("public.doingStatus.5") }}</Tag></SelectOption>
                    <SelectOption :value="3"><Tag>{{ $t("public.doingStatus.3") }}</Tag></SelectOption>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <!--                <Button type="primary" @click="exportToExecl">{{ $t('public.name.Export') }}</Button>-->
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table :columns="columns" :dataSource="data.list" :scroll="{x: 1000}" rowKey="idUsePlan" :loading="loading"
             :pagination="tablePagination" @change="chang_page">
        <template #status="{text}">
          <div>
            <Tag v-if="text === 0" color="blue">{{ $t("public.doingStatus.0") }}</Tag>
            <Tag v-else-if="text === 1" color="orange">{{ $t("public.doingStatus.1") }}</Tag>
            <Tag v-else-if="text === 2" color="green">{{ $t("public.doingStatus.2") }}</Tag>
            <Tag v-else-if="text === 3">{{ $t("public.doingStatus.3") }}</Tag>
            <Tag v-else-if="text === 4" color="cyan">{{ $t("public.doingStatus.4") }}</Tag>
            <Tag v-else-if="text === 5" color="red">{{ $t("public.doingStatus.5") }}</Tag>
            <Tag v-else>{{ text }}</Tag>
          </div>
        </template>
        <template #actionTitle>
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('UserPlanManage.Add')" type="primary" size="small" shape="circle"
                        @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
        </template>
        <template #action="{text}">
          <div class="pc-display">
            <a @click="detail(text)">
              {{ $t("public.name.detail") }}
            </a>
            <Divider type="vertical"/>

            <a @click="editBal(text.idBalLogs)">
              {{ $t("public.consumeType.3") }}
            </a>
            <Divider type="vertical"/>

            <Dropdown :trigger="['click']" @click.prevent>
              <a>
                {{ $t("public.name.setting") }}
                <DownOutlined/>
              </a>
              <template #overlay>
                <Menu>
<!--                  <MenuItem v-if="checkPermission('UserPlanManage.Edit')">-->
<!--                    <Button size="small" @click="showTimeLine(text.idUsePla)">时间轴</Button>-->
<!--                  </MenuItem>-->
                  <MenuItem v-if="checkPermission('UserPlanManage.Edit') && text.status === 0">
                    <Button type="primary" size="small" @click="editStatus(text.idUsePla)">{{$t("public.name.nextStep")}}</Button>
                  </MenuItem>
                  <MenuItem v-if="checkPermission('UserPlanManage.Edit') && text.status === 1 ">
                    <Button type="primary" size="small" @click="editFinish(text)">{{$t("public.name.nextStep")}}</Button>
                  </MenuItem>
                  <MenuItem v-if="checkPermission('UserPlanManage.Edit') && text.status === 2 ">
                    <Button type="primary" size="small" @click="renew(text)">{{$t("public.consumeType.6")}}</Button>
                  </MenuItem>
                  <MenuItem v-if="checkPermission('UserPlanManage.Edit') && text.status === 2">
                    <Button type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  </MenuItem>
                  <MenuItem v-if="checkPermission('UserPlanManage.Edit') && text.status !== 3">
                    <Button type="primary" size="small" danger @click="stopPlan(text)">{{$t("public.doingStatus.5")}}</Button>
                  </MenuItem>
                </Menu>
              </template>
            </Dropdown>
          </div>
        </template>
      </Table>
    </Col>
    <UserPlanDetail ref="refUserPlanDetail"></UserPlanDetail>
    <UserPlanEdit ref="refUserPlanEdit" @updateList="get_list"></UserPlanEdit>
    <BalLogList ref="refBalLogList" @updateList="get_list"></BalLogList>
    <RenewEdit ref="refRenewEdit" @updateList="get_list"></RenewEdit>
    <StopPlan ref="refStopPlan" @updateList="get_list"></StopPlan>
    <UserPlanTimeLine ref="refUserPlanTimeLine"></UserPlanTimeLine>
  </Row>
</template>

<script>
export default {
  name: "UserPlanManage"
}
</script>

<script setup>
import {
  Row,
  Col,
  Table,
  Button,
  Tag,
  TypographyTitle,
  Form,
  FormItem,
  Select,
  SelectOption,
  Input,
  Dropdown,
  Menu,
  MenuItem,
  Divider,
  Modal,
  message
} from "ant-design-vue";
import {PlusOutlined, DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {UserPlanEditStatus, UserPlanList} from "@/api/userPlan";
import UserPlanDetail from "./UserPlanDetail";
import UserPlanEdit from "./UserPlanEdit";
import BalLogList from "./BalLogList";
import RenewEdit from "./RenewEdit";
import StopPlan from "./StopPlan";
import UserPlanTimeLine from "./UserPlanTimeLine";
import moment from "moment";

import {ref, reactive, toRefs, createVNode} from "vue";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const i18n = useI18n()

const refUserPlanEdit = ref()
const refUserPlanDetail = ref()
const refBalLogList = ref()
const refRenewEdit = ref()
const refStopPlan = ref()
const refUserPlanTimeLine = ref()

let loading = ref(false)

const columns = reactive([
  {title: i18n.t("public.name.index"), width: 80, ellipsis: true, fixed: "left",
    customRender: ({text,record,index,column}) => `${(tablePagination.value.current - 1) * tablePagination.value.pageSize + index + 1}`
  },
  // {title: i18n.t("balanceLog.table.id_bal_log"), width: 80, ellipsis: true, dataIndex: "idUsePla", key: "idUsePla"},
  {title: i18n.t("user.table.account"), width: 120, ellipsis: true, dataIndex: "account", key: "account"},
  {title: i18n.t("user.table.phone"), width: 160, ellipsis: true, dataIndex: "phone", key: "phone"},
  {title: i18n.t("statisticsManage.table.identifier"), width: 180, ellipsis: true, dataIndex: "identifier", key: "identifier"},
  {title: i18n.t("userPlan.table.id_pla"), width: 130, ellipsis: true, dataIndex: "planName", key: "planName"},
  {title: i18n.t('userPlan.table.arrearsCount'), width: 100, ellipsis: true, dataIndex: "arrearsCount", key: "arrearsCount"},
  {title: i18n.t('plan.table.free_charging_time_limit'), width: 120, ellipsis: true, dataIndex: "currentlyFreeTime", key: "currentlyFreeTime"},
  {
    title: i18n.t("userPlan.table.start_date"), width: 120, dataIndex: "startDate", key: "startDate", customRender: ({text}) => {
      return text === null || text === undefined ? '-' : moment(text).format('YYYY-MM-DD')
    }
  },
  {title: i18n.t("public.name.status"), width: 80, ellipsis: true, dataIndex: "status", slots: {customRender: "status"}},
  {
    title: i18n.t("userPlan.table.updateByMonth"), width: 90, dataIndex: "updateByMonth", key: "updateByMonth", customRender: ({text}) => {
      return text === null || text === undefined ? '-' : moment(text).format('YYYY-MM')
    }
  },
  { title: i18n.t("public.name.createTime"), width: 160, ellipsis: true, dataIndex: "createTime", key: "createTime" },
  // { title: i18n.t("public.name.createBy"), width: 120, ellipsis: true, dataIndex: "createBy", key: "createBy" },
  // {title: i18n.t("userPlan.table.tenant_key"), dataIndex: "tenantKey", key: "tenantKey"},
  {key: "action", width: 180, fixed: "right", slots: {title: "actionTitle", customRender: "action"}},
])

const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: [],
})
const {searchForm, tablePagination, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new UserPlanList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  delete getData.createTime
  delete getData.updateTime
  api.get(getData).then((response) => {
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

function add() {
  refUserPlanEdit.value?.addShow();
}

function detail(obj) {
  refUserPlanDetail.value?.detailShow(obj);
}
// 切换状态
function editStatus(idUsePla) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new UserPlanEditStatus();
      api.post({idUsePla: idUsePla, status: 1}).then(response=>{
        if (0 === parseInt(response.data.code)) {
          get_list()
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      })
    }
  })
}

// 编辑
function edit(obj) {
  refUserPlanEdit.value?.editShow(obj);
}

// 完成
function editFinish(obj) {
  refUserPlanEdit.value?.editShowFinish(obj);
}

// 续费
function renew(obj) {
  refRenewEdit.value?.editShow(obj);
}

// 终止计划
function stopPlan(obj) {
  refStopPlan.value?.editShow(obj);
}

// 展示时间轴
function showTimeLine(idUsePla) {
  refUserPlanTimeLine.value?.detailShow(idUsePla);
}

function editBal(idBalLogs) {
  refBalLogList.value?.onShow(idBalLogs);
}

function search_submit() {
  tablePagination.value.current = 1;
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.UserPlanManage'));

get_list();

defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>
