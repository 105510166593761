<template>
  <Modal
      id="ConnectorLogList"
      width="50%"
      :open="visible"
      @cancel="cancel"
      @ok="cancel"
      :title="$t('nav.name.ConnectorLogManage')"
      :footer="null"
      :destroyOnClose="true"
  >
      <Table :columns="columns" :dataSource="listView" :scroll="{ x: 800, y: 500 }" rowKey="id" :loading="loading" :pagination=false>
        <!-- 内容插槽-->
        <template #bodyCell="{column, record}">
          <template v-if="column.dataIndex === 'status'">
            <div>
              <Tag v-if="record.status === 8 || record.status === 10" color="#f50">
                {{ statusList.find(item => item.value === record.status).label }}
              </Tag>
              <Tag v-else color="#87d068">{{ statusList.find(item => item.value === record.status).label }}</Tag>
            </div>
          </template>
        </template>
      </Table>
  </Modal>
</template>

<script>
export default {
  name: "ConnectorLogList",
}
</script>
<script setup>
import {
  Modal,
  Button,
  Table,
  Tag,
} from "ant-design-vue";
import {ref, reactive, toRefs} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n"
import {ConnectorLogFindByPage} from "@/api/connectorLog";
const i18n = useI18n()

const visible = ref(false)
const loading = ref(false)

const statusList = [
  {value: 1, label: 'Available'},
  {value: 2, label: 'Preparing'},
  {value: 3, label: 'Charging'},
  {value: 4, label: 'SuspendedEVSE'},
  {value: 5, label: 'SuspendedEV'},
  {value: 6, label: 'Finishing'},
  {value: 7, label: 'Reserved'},
  {value: 8, label: 'Unavailable'},
  {value: 9, label: 'Faulted'},
  {value: 10, label: 'Disconnect'}
]
const columns = [
  {
    title: i18n.t("chargingStation.table.charge_box_id"),
    width: 120,
    ellipsis: true,
    dataIndex: "chargeBoxId",
    key: "chargeBoxId"
  },
  {
    title: i18n.t("connector.table.connector_id"),
    width: 120,
    ellipsis: true,
    dataIndex: "connectorId",
    key: "connectorId"
  },
  {
    title: i18n.t("connector.table.status"),
    width: 80,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
  },
  {
    title: i18n.t("public.table.created_at"),
    width: 180,
    ellipsis: true,
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  }
]

const data = reactive({
  listView: [],
})
const {listView} = toRefs(data)

function onShow(id) {
  visible.value = true
  get_list(id)
}

function cancel() {
  visible.value = false
}

function get_list(id) {
  loading.value = true
  listView.value = []
  loading.value = false
  const api = new ConnectorLogFindByPage();
  api.get({
    page: 1,
    limit: 50,
    idCon: id
  }).then(response => {
    loading.value = false
    if (0 === parseInt(response.data.code)) {
      listView.value = response.data.data.data
    } else {
      listView.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

defineExpose({
  onShow,
});
</script>
