/**
 * @Auther    william
 * @Name      state define file
 * @Note      
 * @Time      2021/04/13 16:53
 */

let state = {
    dsKey: "", // 数据源标识
    tenantKey: "", // 租户标识

    systemTag: "crm",
    language: "enUS",
    logo: "",
    theme: "dark",
    version: "@v23.11.15",
    copyRight: "Ttech Global Service Limited. All Rights Reserved 2022",
    token: "",
    noImage: require("@/assets/img/no-img-small.png"),
    username: "",
    idRol: "",
    userType: "",
    userTenantKey: "",
    isAdmin: 0,

    portrait: "",
    lastIp: "",
    lastTime: 0,
    phone: "",
    login: "",
    email: "",
    type: 0,
    permissionList:[],

    navList: [],
    pageTitle: ''
}

export default state