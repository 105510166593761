import {Base} from "@/api/Base"

const api = "superTenant"

// 分页查询租户列表
export class superTenantGetListByPage extends Base {
    path = api + "/getListByPage"
}

// 获取下拉选择框内容
export class superTenantSelectList extends Base {
    path = api + "/selectList"
}

// 获取记录
export class superTenantGetInfo extends Base {
    path = api + "/getInfo"
}

// 新增记录
export class superTenantAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class superTenantEditInfo extends Base {
    path = api + "/editInfo"
}

// 删除记录
export class superTenantDelInfo extends Base {
    path = api + "/delInfo"
}

// 创建数据库
export class superTenantCreateDb extends Base {
    path = api + "/createDb"
}

// 测试连结
export class superTenantTestConn extends Base {
    path = api + "/testConn"
}
