import {Base} from "@/api/Base"

const api = "company"

// 获取记录
export class companyGetInfo extends Base {
    path = api + "/getInfo"
}

// 通过租户编号获取记录
export class companyGetInfoByTid extends Base {
    path = api + "/getInfoByTid"
}

// 新增记录
export class companyAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class companyEditInfo extends Base {
    path = api + "/editInfo"
}

export class companyExportExcelBymonthlyFee extends Base {
    path = api + "/exportExcelBymonthlyFee"
}
