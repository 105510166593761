import {Base} from "@/api/Base"

const api = "chargeStationImages"

export class ChargeStationImagesAdd extends Base {
    path = api + "/add"
}

export class ChargeStationImagesDelete extends Base {
    path = api + "/delete"
}

export class ChargeStationImagesList extends Base {
    path = api + "/list"
}

export class ChargeStationImagesEdit extends Base {
    path = api + "/edit"
}

