<template>
  <Modal id="MeterDetailRef" :destroyOnClose="true" width="60%" :open="visible" @cancel="onCancel" @ok="visible = !visible" :title="$t('nav.name.Dashboard')">
    <Card>
      <template #extra>
        <RangePicker
            style="width: 100%"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :presets="rangePresets"
            @change="onRangeChange"/>
      </template>
      <div id="container"></div>
    </Card>
  </Modal>
</template>
<script>
export default {
  name: "MeterDetail",
}
</script>
<script setup>
import {Modal, RangePicker, Card, message} from "ant-design-vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {ElaLogsFindOcppLog} from "@/api/elaLogs";
import {Line} from "@antv/g2plot";
import dayjs from 'dayjs';

const i18n = useI18n()
let visible = ref(false)

const line = ref()
let linePlot = ref()

let boxNo = ref("")
let conId = ref(0)

const rangePresets = ref([
  {
    label: 'Last 3 Days',
    value: [dayjs().add(-3, 'd'), dayjs()],
  },
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
]);

function onRangeChange(e) {
  // console.log("onCa => ", e)
  if (e) {
    getStatisticEnergyUsage(e[0], e[1])
  } else {
    getStatisticEnergyUsage()
  }
}

function getStatisticEnergyUsage(inTime, toTime) {
  const api = new ElaLogsFindOcppLog();
  api.post({
    identifier: boxNo.value,
    connectorId: conId.value,
    handleType: "handleMeterValuesRequest,handleStatusNotificationRequest",
    inTime: inTime,
    toTime: toTime
  }).then((response) => {
    // console.log("获取到日志：", response.data)
    let data = []
    let kdz = 0
    response.data.rows.forEach(item => {
      kdz++
      // let dateTime = item.dateTime.slice(2, -4);
      let dateTime = item.dateTime.slice(0, -4).replaceAll("-", "/");
      let dtTime = item.dtTime;
      let content = JSON.parse(item.content)
      if (item.handleType === "handleMeterValuesRequest") {
        const sampledValue = content.data.meterValue[0].sampledValue
        if (sampledValue) {
          sampledValue.forEach(it => {
            let val = Number(it.value)
            let unit = it.unit
            if (unit === "Wh") {
              unit = "kWh"
              val = val / 1000
            }
            if (unit === "W") {
              unit = "kW"
              val = val / 1000
            }
            if (unit === "A") {
              unit = "kA"
              val = val / 1000
            }
            if (unit === "V") {
              unit = "kV"
              val = val / 1000
            }
            data.push({
              dtTime: dtTime,
              xField: dateTime,
              yField: val,
              seriesField: it.phase ? it.measurand + "-" + it.phase + "("+ unit +")" : it.measurand + "("+ unit +")"
            })
          })
        }
      }
      if (item.handleType === "handleStatusNotificationRequest") {
        const d = content.data
        data.push({
          dtTime: dtTime,
          xField: dateTime,
          yField: Number(d.status),
          seriesField: "status"
        })
        data.push({
          dtTime: dtTime,
          xField: dateTime,
          yField: Number(d.errorCode),
          seriesField: "errorCode"
        })
      }
    })
    data.sort((it1, it2) => (it1.dtTime - it2.dtTime))
    if (data && data.length > 0) {
      if (linePlot.value) {
        linePlot.value.changeData(data);
        return
      }
      linePlot.value = new Line('container', {
        padding: 'auto',
        data,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        smooth: true,
        responsive: true,
        xAxis: {
          tickLine: {
            visible: true,
          }
        },
        legend: {
          position: 'right-top',
        }
      });
      linePlot.value.render();
    } else {
      message.error("no data");
    }
  });
}

function onCancel() {
  linePlot.value = null
  visible.value = false
}

async function detailShow(bNo, cId) {
  boxNo.value = bNo
  conId.value = cId
  getStatisticEnergyUsage()
  visible.value = true;
}
defineExpose({
  detailShow
})
</script>

<style lang="less">
</style>