<template>
  <Select
      v-model:value="tenantKey"
      show-search
      :placeholder="$t('public.pleaseSelect')"
      style="width: 300px;margin-bottom: 20px"
      :options="options"
      :filter-option="filterOption"
      @change="handleChange"
  ></Select>
</template>
<script>
export default {
  name: "SelectTenant"
}
</script>
<script setup>
import {
  message,
  Select,
} from "ant-design-vue";

import {useStore} from "vuex";
import {ref} from "vue";
import {TenantGetSelectTenantKeyList} from "@/api/tenantInfo";

const store = useStore()
const emits = defineEmits(['onClick'])

let options = ref([])
let tenantKey = ref(store.getters.getTenantKey)
let isAdmin = store.getters.getIsAdmin
// 搜索框选择事件
function handleChange(v) {
  tenantKey.value = v
  // 缓存所选的数据源标识
  store.commit("setTenantKey", v);
  // 完成通知
  emits("onClick", v);
}
// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}
// 获取下拉框选择内容
function initSelect() {
  options.value = []
  tenantKey.value = store.getters.getTenantKey
  // 判断是否是Admin用户
  if (isAdmin) {
    // 获取该租户下所有的租户列表
    const api = new TenantGetSelectTenantKeyList();
    api.get().then((res) => {
      let lst = res.data.data
      options.value = []
      lst.forEach(item =>{
        let it = {
          value: item.tenantKey,
          label: item.tenantKey
        }
        options.value.push(it)
      })
    });
  }
}
// 去除list中的某个对象
function delListByKey(li, delKey) {
  let index;
  for(let i=0;i<li.length;i++){
    if((li[i].value).indexOf(delKey)>-1){
      index = i;
      li.splice(index,1);
    }
  }
  return li
}
defineExpose({
  initSelect
});
</script>

<style lang="less">
@import url("style.less");
</style>
