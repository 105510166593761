import {Base} from "@/api/Base"

const api = "car"

export class CarAdd extends Base {
    path = api + "/add"
}

export class CarDelete extends Base {
    path = api + "/delete"
}

export class CarList extends Base {
    path = api + "/list"
}

export class CarEdit extends Base {
    path = api + "/edit"
}

export class CarListAll extends Base {
    path = api + "/listAll"
}

export class CarExportToExcel extends Base {
    path = api + "/exportToExcel"
}
