const daDK = {
    'succeedMsg': {
        '0': 'Lykkes',
    },
    'errorMsg': {
        '500100': 'Undtagelse på serversiden',
        '500101': 'Parameterfejl',
        '500102': 'Undtagelse fra parametervalidering',
        '500103': 'Anmodningen er ulovlig',
        '500104': 'Besøg er for hyppige',
        '500105': 'Objekt eksisterer allerede',
        '500106': 'Operationen mislykkedes, "Prøv igen',
        '500107': 'Der er en forening, "sletning er forbudt',
        '500108': 'Eksport undtagelser',
        '500200': 'Login udløbet, "Log venligst ind igen',
        '500201': 'Kontonummeret eller adgangskoden kan ikke være tomt',
        '500202': 'Telefonnummeret kan ikke være tomt',
        '500203': 'Telefonnummeret er i det forkerte format',
        '500204': 'Telefonnummeret findes ikke',
        '500205': 'Adgangskoden er forkert',
        '500206': 'Kontoen findes ikke',
        '500207': 'Kontonumre kan kun indeholde numre, "engelsk" og understreger',
        '500208': 'Godkendelse mislykkedes',
        '500209': 'De to adgangskoder er ikke de samme',
        '500210': 'Duplikat konti',
        '500211': 'Kontoen er blevet låst',
        '500212': 'Captcha mislykkedes',
        '500213': 'Telefonnummeret er forkert',
        '500214': 'Der er ingen lejer, der svarer til domænenavnet, "Kontakt din administrator',
        '500215': 'eksisterer det samme rollenavn',
        '500300': 'Hvis OCCP -tjenesten er unormal, "kontakt administratoren',
        '500301': 'Elektriske bunker er ikke tilgængelige',
        '500302': 'Pilekonfigurationen er tom',
        '500303': 'Batteriet er offline, "Kontroller, om det er tilgængeligt',
        '500304': 'The pile port is not available',
        '500305': 'The gun is not inserted or the current gun is not ready',
        '500306': 'Begynd at oplade fiasko',
        '500307': 'Bundtet opladningssvigt',
        '500401': 'Insufficient balance',
        '500402': 'Ordren findes ikke',
        '500403': 'The order was not paid',
        '500404': 'The order has been paid',
        '500405': 'Order status is not [Paid Unused]',
        '500406': 'Oprettelse af ordre mislykkedes',
        '500407': 'Der er ubetalte ordrer',
        '500408': 'Ordre annulleret',
        '500501': 'Varen er solgt på få sekunder',
        '500502': 'Lynaftaler kan ikke gentages',
        '500503': 'Anden dræbssvigt',
        '500601': 'The file cannot be empty',
        '500602': 'Fejlfejlfejl',
        '500603': 'Excel -arbejdsbogen er tom',
        '500604': 'Kunne ikke læse fil'
    },
    'public': {
        'name': {
            'nextStep': 'Næste trin',
            'status': 'Stat',
            'hello': 'Hej',
            'login': 'Log på',
            'logout': 'Log ud',
            'username': 'Brugernavn',
            'email': 'E-mail',
            'password': 'Adgangskode',
            'remember': 'Husk mig',
            'currentPassword': 'Nuværende adgangskode',
            'newPassword': 'Ny adgangskode',
            'confirm_password': 'Bekræft kodeord',
            'reset_password': 'Nulstille kodeord',
            'captcha': 'CAPTCHA',
            'getCaptcha': 'Hent Captcha',
            'more': 'Mere',
            'add': 'Tilføje',
            'batchDel': 'Batch Slet',
            'Export': 'Eksport',
            'Import': 'Importér',
            'DownloadTemp': 'Download skabelon',
            'ImportTips': 'Klik eller træk filen til dette område for at uploade',
            'edit': 'Redigere',
            'del': 'Slet',
            'detail': 'Detalje',
            'yes': 'Ja',
            'no': 'Ingen',
            'empty': 'Tom',
            'are_you_sure_delete': 'Er du sikker på at slette denne vare',
            'action_cannot_resumed': 'Denne handling kan ikke genoptages',
            'are_you_sure_operate': 'Er du sikker på den aktuelle operation',
            'operate_remind': 'Nuværende operation er følsom, vær forsigtig',
            'search': 'Søg',
            'reset': 'Nulstil',
            'spread': 'Spredning',
            'retract': 'Trække tilbage',
            'show': 'At vise',
            'no_page': 'Ingen side',
            'service': 'Service',
            'phone_country_code': 'Telefonlandskode',
            'name': 'Navn',
            'phone': 'telefon',
            'sex': 'Køn',
            'country': 'Land',
            'address': 'Adresse',
            'birthday': 'Fødselsdag',
            'remark': 'Bemærkning',
            'personal': 'Personlig',
            'enclosure': 'Kabinet',
            'logo': 'Logo',
            'submit': 'Indsend',
            'unknown': 'Ukendt',
            'setting': 'Rejse',
            'index': 'serienummer',
            'createBy': 'Opret ved',
            'createTime': 'Opret tid',
            'updateBy': 'Opdatering af',
            'updateTime': 'Opdateringstid',
            'type': 'Type',
            'args': 'Parameter',
            'qrcode': 'QR-Kode',
            'query': 'Forespørgsel',
            'refund': 'Refusion',
            'cancel': 'Annullér',
            'revoke': 'Tilbagekalde',
        },
        'msg': {
            'unRead': 'Læs ikke',
            'read': 'Læs',
            'alarm': 'Alarm',
            'reminder': 'Påmindelse',
            'advertise': 'Annoncer'
        },
        'language': {
            'zhTW': 'Kinesisk (traditionel)',
            'enUS': 'engelsk',
            'zhCN': 'Kinesisk forenklet)',
            'daDK': 'Dansk',
            'idID': 'Indonesisk',
            'msMY': 'Malaysisk',
            'thTH': 'Thai',
            'viVN': 'Vietnamesisk',
            'frFR': 'Fransk',
            'plPL': 'Polsk',
            'it': 'Italiensk',
        },
        'timeType': {
            0: 'År',
            1: 'Måned',
            2: 'Uge',
            3: 'Dag',
        },
        'promptsToSelectTime': 'Vælg et tidspunkt',
        'pleaseSelect': 'Vælg venligst',
        'pileStatus': {
            0: 'Tilgængelig',
            1: 'Opladning',
            2: 'Ikke tilgængelig',
        },
        'isTrue': {'0': 'Ingen', '1': 'Ja'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E -mail', '1': 'telefon'},
        'doingStatus':{
            '0': 'Afventende',
            '1': 'Forarbejdning',
            '2': 'Afsluttet',
            '3': 'Annullér',
            '4': 'Udløbet',
            '5': 'opsigelse',
        },
        'consumeType': {
            '0': "Genopladning",
            '1': "Afgiftsforbrug",
            '2': "Opkrævning annullering",
            '3': "Balance",
            '4': 'Købsplan',
            '5': 'Annullér plan',
            '6': 'Fornyelsesplan',
            '7': 'refusion',
            '8': 'Straffenote',
            '9': 'Annuller bøder',
            '10': 'Tilbagetrækning',
            '11': 'Fuld gave',
            '12': 'Tilbagekalde',
        },
        'payStatus': {'0': 'Ubetalt', '1': 'Betalt', '2': 'Opladning', '3': 'Afsluttet', '4': 'Aflyse'},
        'chargeType': {'0': 'Med tiden', '1': 'Efter ordre', '2': 'Efter brugerplan'},
        'status': {'0': 'Ukendt', '1': 'Normal', '2': 'Deaktiver', '3': 'Slet'},
        'sex': {'1': 'Mand', '2': 'Kvinde', '3': 'Ukendt'},
        'placeholder': {'please_select': 'Vælg venligst'},
        'table': {
            'name': 'Navn',
            'status': 'Stat',
            'action': 'Handling',
            'content': 'Indhold',
            'created_at': 'Opret tid',
            'image': 'Billede',
            'email': 'E-mail',
            'order_id': 'Bestille',
            'sex': 'Køn',
            'last_ip': 'Sidste IP',
            'phone_country_code': 'Vælg telefonlandskode',
            'phone': 'telefon'
        },
        'field': {
            'name': 'Navn',
            'username': 'Brugernavn',
            'content': 'Indhold',
            'phone_country_code': 'Telefonlandskode',
            'phone': 'telefon',
            'email': 'E-mail',
            'order_id': 'Bestille',
            'portrait': 'Portræt',
            'status': 'Stat',
            'image': 'Billede',
            'sex': 'Køn'
        },
        'validate': {
            'required': {
                'id': 'Mangler id',
                'username': 'Indtast brugernavn',
                'password': 'Venligst adgangskode',
                'repassword': 'Gentag adgangskoden',
                'name': 'Indgangsnavn',
                'phone': 'Indtast telefon',
                'email': 'Indsag e-mail',
                'portrait': 'Upload portræt'
            },
            'tips': {
                'inconsistent_password': 'Inkonsekvent adgangskode',
                'length_should': 'Længde skal være {min} til {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Kinesisk (traditionel)',
        'enUS': 'engelsk',
        'zhCN': 'Kinesisk forenklet)',
        'daDK': 'Dansk',
        'idID': 'Indonesisk',
        'msMY': 'Malaysisk',
        'thTH': 'Thai',
        'viVN': 'Vietnamesisk',
        'frFR': 'Fransk',
        'plPL': 'Polsk',
        'it': 'Italiensk',
    },
    'nav': {
        'name': {
            'Dashboard': 'Instrumentpanel',
            'RegionDashboard': 'Oversigt over bunker',
            'BigScreen': 'Storskærm',

            'AuthorityManage': 'Autoritet administrerer',

            'BalanceLogManage': 'Balance Log Administrere',
            'CarManage': 'Bilstyring',
            'CarImagesManage': 'Bilbilleder',
            'ChargeStationImagesManage': 'Billedstyring af ladepunkter',
            'ChargingStationManage': 'Ladestander administrere',
            'ConnectorManage': 'Koblinger administrere',
            'EmployeeManage': 'Operatør administrere',
            'ConnectorLogManage': 'Tilslutningslog',
            'EventLogManage': 'Begivenheds log administrere',
            'MeterManage': 'Måler administrere',
            'OrderManage': 'Ordre administrere',
            'PlanManage': 'Planadministration',
            'RegionManage': 'Region styrer',
            'RegionImagesManage': 'Regionbillede administrerer',
            'RepairLogManage': 'Reparations log administrere',
            'RoleManage': 'Rollehåndtering',
            'TransactionLogManage': 'Administration af transaktionslog',
            'UserManage': 'Bruger administration',
            'UserPlanManage': 'Bruger PlanManage',
            'GetConfiguration': 'Få konfiguration',
            'DataTransfer': 'Dataoverførsel',
            'TenantManagement': 'Administration af systemer',
            'NoticeManage': 'BEMÆRKNING Administrer',
            'PublicityManage': 'Reklamehåndtering',
            'ChargerSetting': 'Opladerindstilling',

            'StatisticsManage': 'Statistik',
            'ServiceManage': 'Administration af tjenester',
            'CardManage': 'Håndtering af kortnumre',

            'AppManagement': 'App Management',
            'LogManagement': 'Logstyring',
            'AppVersionManage': 'App version administrerer',
            'WallerManage': 'Kontogrænse',
            'iPadManagement': 'iPad Management',
            'iPadManage': 'iPad administrerer',

            'TenantManage': "Lejerforvaltning",
            'SuperTenantManage': "Superlejer",
            'TenantInfo': 'Lejerforvaltning',
            'TenantPackage': 'Lejerpakke',
        },
        'table': {'order_id': 'Ordre ID', 'url': 'URL'},
        'field': {'pid': 'Pid', 'order_id': 'Ordre ID', 'name': 'Navn', 'url': 'URL', 'status': 'Stat', 'icon': 'Ikon'}
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Indtast brugernavn',
                'email': 'Indsag e -mail',
                'password': 'Venligst adgangskode',
                'captcha': 'Indgang CAPTCHA'
            }
        }
    },
    'authority': {
        'name': {'title': 'Myndighed', 'detailTitle': 'Autoritetsdetaljer', 'editTitle': 'Rediger autoritet'},
        'table': {
            'id_aut': 'Id',
            'a_id_aut': 'Forældremyndighed',
            'name': 'Myndighed',
            'resource_name': 'Ressourcenavn',
            'type': 'Type',
            'sort': 'Sortere'
        },
        'field': {
            'id_aut': 'Id',
            'a_id_aut': 'Forældremyndighed',
            'name': 'Myndighed',
            'resource_name': 'Ressourcenavn',
            'type': 'Type',
            'sort': 'Sortere'
        },
        'validate': {
            'required': {
                'id_aut': 'Id krævet',
                'a_id_aut': 'Forældremyndighed krævet',
                'name': 'Myndighed krævet',
                'resource_name': 'Ressourcenavn krævet',
                'type': 'Type krævet',
                'sort': 'Sortering krævet'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Genopladningspakke',
        'addRule': 'Tilføj regel',
        'gift': {
            'enable': 'Er det aktiveret',
            'percentage': 'I procent',
            'star': 'Startværdi',
            'end': 'Slutværdi',
            'give': 'Grænse',
        },
        'vGift': {
            'enable': 'Vælg venligst om du vil aktivere det eller ej',
            'percentage': 'Vælg venligst om procentdelen skal bruges eller ej',
            'star': 'Indtast startværdien',
            'end': 'Indtast endeværdien',
            'give': 'Indtast kreditgrænsen',
        },
        'name': {
            'title': 'Balancelog',
            'detailTitle': 'Balancelogdetaljer',
            'editTitle': 'Rediger balance log',
            'ticket': 'Straffenote',
            'cancelFines': 'Annullere bøder'
        },
        'table': {
            'id_bal_log': 'Id',
            'id_use': 'Bruger',
            'type': 'Type',
            'amount': 'Beløb',
            'log_time': 'Logtid',
            'tenant_key': 'Lejernøgle',
            'status': 'Status',
            'payment_method': 'Betalingsmetode'
        },
        'field': {
            'id_bal_log': 'Id',
            'id_use': 'Bruger',
            'type': 'Type',
            'amount': 'Beløb',
            'log_time': 'Logtid',
            'tenant_key': 'Lejernøgle',
            'status': 'Status',
            'payment_method': 'Betalingsmetode'
        },
        'validate': {
            'required': {
                'id_bal_log': 'Id krævet',
                'id_use': 'Bruger krævet',
                'type': 'Type krævet',
                'amount': 'Krævet beløb',
                'log_time': 'Logtid krævet',
                'tenant_key': 'Lejernøgle kræves',
                'status': 'Status krævet',
                'payment_method': 'Betalingsmetode krævet'
            }
        }
    },
    'car': {
        'name': {'title': 'Bil', 'detailTitle': 'Bildetaljer', 'editTitle': 'Rediger bil'},
        'table': {
            'id_car': 'Id',
            'id_use': 'Bruger',
            'model': 'Model',
            'car_license': 'Licens',
            'max_years_old': 'Maks. År',
            'manufacture_year': 'Fremstillingsår',
            'maximum_power': 'Maksimal effekt',
            'is_second_hand': 'Er brugte',
            'vehicle_inspection_date': 'Køretøjets inspektionsdato',
            'insurance_expiry_date': 'Forsikringsudløbsdato',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_car': 'Id',
            'id_use': 'Bruger',
            'model': 'Model',
            'car_license': 'Licens',
            'max_years_old': 'Maks. År',
            'manufacture_year': 'Fremstillingsår',
            'maximum_power': 'Maksimal effekt',
            'is_second_hand': 'Er brugte',
            'vehicle_inspection_date': 'Køretøjets inspektionsdato',
            'insurance_expiry_date': 'Forsikringsudløbsdato',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_car': 'Id krævet',
                'id_use': 'Bruger krævet',
                'model': 'Model krævet',
                'car_license': 'Licens kræves',
                'max_years_old': 'Maksimalt påkrævet',
                'manufacture_year': 'Fremstillingsår krævet',
                'maximum_power': 'Maksimal effekt krævet',
                'is_second_hand': 'Er brugte',
                'vehicle_inspection_date': 'Køretøjets inspektionsdato krævet',
                'insurance_expiry_date': 'Forsikringsudløbsdato krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Bilbilleder',
            'detailTitle': 'Bilbilleder detaljer',
            'editTitle': 'Rediger bilbilleder'
        },
        'table': {
            'id_car_ima': 'Id',
            'id_car': 'Bil',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_car_ima': 'Id',
            'id_car': 'Bil',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_car_ima': 'Id krævet',
                'id_car': 'Bil kræves',
                'url': 'URL påkrævet',
                'upload_time': 'Upload tid krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Opladningspunktbillede',
            'detailTitle': 'Opladningspunkt Billeddetaljer',
            'editTitle': 'Rediger opladningspunktbillede'
        },
        'table': {
            'id_ima': 'Id',
            'id_cha_poi': 'Opladningspunkt',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_ima': 'Id',
            'id_cha_poi': 'Opladningspunkt',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_ima': 'Id krævet',
                'id_cha_poi': 'Gebyrpunkt krævet',
                'url': 'URL påkrævet',
                'upload_time': 'Upload tid krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Indstil betalingssekvens for opkrævningsordre, standard til regionale indstillinger",
            'billingMethod': "Angiv faktureringsmetode for opkrævningsordre, som standard angives efter område",
        },
        'billingMethodType': {
            '0':"Af tid",
            '1':"Ved magt",
            '2':"Efter brugerplan",
            '3':"Ved APP",
            '4':"Efter region",
        },
        'updateFirmware': {
            'identifier': "Serienummer",
            'location': "Fjernadresse",
            'retrieveDate': "Dato for hentning",
        },
        'uValidate': {
            'identifier': "Indtast serienummeret",
            'location': "Indtast en fjernadresse",
            'retrieveDate': "Vælg venligst en søgedato",
        },
        'setting': {
            'index': "serienummer",
            'readonly': "Skrivebeskyttet",
            'key': "Indstil nøglen",
            'value': "Indstil værdien",
        },
        'settingField': {
            'readonly': "Vælg, om den er skrivebeskyttet",
            'key': "Den indstillede nøgle kan ikke være tom",
            'value': "Indstillingsværdien kan ikke være tom",
        },
        'name': {
            'title': 'Opladningspunkt',
            'detailTitle': 'Opladningspunktdetaljer',
            'editTitle': 'Rediger opladningspunkt',
            'quickAdd': 'Tilføj ladepunkt'
        },
        'table': {
            'id_cha_poi': 'Id',
            'id_met': 'Måler',
            'charge_box_id': 'Charge Box ID',
            'firmwareVersion': 'Firmware version',
            'last_heartbeat_time': 'Sidste hjerteslagstid',
            'monthly_fee': 'Månedligt gebyr',
            'charger_type': 'Opladertype',
            'tenant_key': 'Lejernøgle',
            'name': 'Navn',
            'heartbeat_interval': 'Hjerteslag interval',
            'enable': 'Aktivér',
            'maxCurrent': "Maksimal strøm",
            'minCurrent': "Mindste strøm",
            'isPrivate': "Privat eller ej",
            'isReadMeter': "Om tabellen skal læses",
            'isReset': "Om genstart skal genstartes (genstart kan træde i kraft)",
            'action': {
                'updateFirmware': "Firmwareopgradering",
                'hardReset': "Hård nulstil",
                'softReset': "Blød nulstil"
            }
        },
        'validate': {
            'id_cha_poi': 'Id krævet',
            'id_met': 'Måler krævet',
            'charge_box_id': 'Opladningsboks -id påkrævet',
            'last_heartbeat_time': 'Sidste hjerteslagstid krævet',
            'monthly_fee': 'Det krævede månedlige gebyr',
            'charger_type': 'Opladningstype krævet',
            'tenant_key': 'Lejernøgle kræves',
            'name': 'Navn krævet',
            'heartbeat_interval': 'Hjerteligbehandlingsinterval kræves',
            'enable': 'Aktivér påkrævet',
            'maxCurrent': "Udfyld venligst den maksimale strøm",
            'minCurrent': "Udfyld venligst minimumsstrømmen",
        }
    },
    'connector': {
        'name': {
            'title': 'Stik',
            'detailTitle': 'Stikdetalje',
            'editTitle': 'Rediger stik',
            'startTransaction': 'Start transaktion',
            'stopTransaction': 'Stop transaktion'
        },
        'table': {
            'id_con': 'Id',
            'id_cha_poi': 'Opladningspunkt',
            'name': 'Navn',
            'connector_id': 'Stik ID',
            'status': 'Status',
            'tenant_key': 'Lejernøgle',
            'power': 'Strøm(kw)',
            'type': 'Type'
        },
        'field': {
            'id_con': 'Id',
            'id_cha_poi': 'Opladningspunkt',
            'name': 'Navn',
            'connector_id': 'Stik ID',
            'status': 'Status',
            'tenant_key': 'Lejernøgle',
            'power': 'Strøm(kw)',
            'type': 'Type'
        },
        'validate': {
            'required': {
                'id_con': 'Id krævet',
                'id_cha_poi': 'Gebyrpunkt krævet',
                'name': 'Navn krævet',
                'connector_id': 'Stik ID krævet',
                'status': 'Status krævet',
                'tenant_key': 'Lejernøgle kræves',
                'power': 'Kraft krævet(kw)',
                'type': 'Type krævet'
            }
        }
    },
    'employee': {
        'name': {'title': 'Operatør', 'detailTitle': 'Operatørdetaljer', 'editTitle': 'Rediger operatør'},
        'table': {
            'id_emp': 'Id',
            'id_rol': 'Rolle',
            'account': 'Konto',
            'password': 'Adgangskode',
            'salt': 'Salt',
            'last_login_time': 'Sidste login -tid',
            'register_time': 'Registrer tid',
            'login_failure_counter': 'Loginfejl tæller',
            'email': 'E -mail',
            'whatsAppPhone': 'WhatsApp Tal',
            'remark': 'Bemærkning',
            'status': 'Status',
            'name': 'Navn',
            'commission_rate': 'Kommissionen',
            'yedpay_api_key': 'Yedpay API -nøgle',
            'yedpay_sign_key': 'YedPay Sign Key',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_emp': 'Id',
            'id_rol': 'Rolle',
            'account': 'Konto',
            'password': 'Adgangskode',
            'salt': 'Salt',
            'last_login_time': 'Sidste login -tid',
            'register_time': 'Registrer tid',
            'login_failure_counter': 'Loginfejl tæller',
            'email': 'E -mail',
            'whatsAppPhone': 'WhatsApp Tal',
            'remark': 'Bemærkning',
            'status': 'Status',
            'name': 'Navn',
            'commission_rate': 'Kommissionen',
            'yedpay_api_key': 'Yedpay API -nøgle',
            'yedpay_sign_key': 'YedPay Sign Key',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_emp': 'Id krævet',
                'id_rol': 'Den krævede rolle',
                'account': 'Konto kræves',
                'password': 'Adgangskode kræves',
                'salt': 'Salt krævet',
                'last_login_time': 'Sidste login tid krævet',
                'register_time': 'Registrer tid krævet',
                'login_failure_counter': 'Loginfejl tæller krævet',
                'email': 'E -mail krævet',
                'whatsAppPhone': 'Udfyld venligst WhatsApp-nummer',
                'remark': 'Bemærkning krævet',
                'status': 'Status krævet',
                'name': 'Navn krævet',
                'commission_rate': 'Kommissionen krævet',
                'yedpay_api_key': 'Yedpay API -nøgle kræves',
                'yedpay_sign_key': 'Yedpay -tegnnøgle kræves',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Hændelseslog',
            'detailTitle': 'Begivenhedslogdetaljer',
            'editTitle': 'Rediger begivenhedsloggen'
        },
        'table': {
            'id_env_log': 'Id',
            'run_time': 'Udførelsestid(ms)',
            'class_method': 'Udførelsesmetode',
            'event_time': 'Begivenhedstid',
            'log': 'Log',
            'tenant_key': 'Lejernøgle',
            'resCode': 'Svarkode',
        },
        'field': {'id_env_log': 'Id', 'event_time': 'Begivenhedstid', 'log': 'Log', 'tenant_key': 'Lejernøgle'},
        'validate': {
            'required': {
                'id_env_log': 'Id krævet',
                'class_method': 'Udfyld udførelsesmetoden',
                'event_time': 'Begivenhedstid krævet',
                'log': 'Log krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'meter': {
        'name': {'title': 'Måler', 'detailTitle': 'Meterdetaljer', 'editTitle': 'Rediger måler'},
        'table': {
            'id_met': 'Id',
            'id_reg': 'Område',
            'name': 'Målernavn',
            'current_threshold': 'Nuværende tærskel',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_met': 'Id',
            'id_reg': 'Område',
            'name': 'Målernavn',
            'current_threshold': 'Nuværende tærskel',
            'tenant_key': 'Lejernøgle',
            'loadByTactics': 'Aktivér politikker'
        },
        'validate': {
            'required': {
                'id_met': 'Id krævet',
                'id_reg': 'Region krævet',
                'name': 'Målernavn krævet',
                'current_threshold': 'Nuværende tærskel krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Bestille',
            'detailTitle': 'Bestil detaljer',
            'editTitle': 'Rediger ordre',
            'cancel': 'Annullér ordre',
            'cancelAndRefund': 'Annuller ordre og refundering',
        },
        'table': {
            'id_ord': 'Id',
            'id_car': 'Bil',
            'id_con': 'Stik',
            'order_time': 'Bestil tid',
            'type': 'Type',
            'amount': 'Beløb',
            'charge_start_time': 'Gebyrstartstid',
            'charge_end_time': 'Når det skal ende',
            'chargeEndTimeActual': 'Faktisk sluttid',
            'status': 'Status',
            'payment_time': 'Betalingstid',
            'electricity': 'Indkøbsafgiftskapacitet',
            'charge_minutes': 'Varighed af købsafgifter',
            'currentElectricity': 'Faktisk gebyrbeløb',
            'ref_id_pla': 'Plan',
            'price': 'Pris',
            'commission_rate': 'Kommissionen',
            'tenant_key': 'Lejernøgle',
            'id_tra_log': 'Transactionlogid',

            'pay_type': 'PAYTYPE',
            'payment_method': 'Betalingsmetode'
        },
        'validate': {
            'id_ord': 'Id krævet',
            'id_car': 'Bil kræves',
            'id_con': 'Stik krævet',
            'order_time': 'Bestil tid krævet',
            'type': 'Type krævet',
            'amount': 'Krævet beløb',
            'charge_start_time': 'Gebyrstartstid krævet',
            'charge_end_time': 'Opladning af sluttid krævet',
            'status': 'Status krævet',
            'payment_time': 'Betalingstid krævet',
            'electricity': 'Elektricitet kræves',
            'ref_id_pla': 'Plan kræves',
            'price': 'Pris kræves',
            'commission_rate': 'Kommissionen krævet',
            'tenant_key': 'Lejernøgle kræves',
            'id_tra_log': 'TransactionLogID kræves',
            'charge_minutes': 'Chargeminutes kræves',
            'pay_type': 'PAYTYPE kræves',
            'payment_method': 'Betalingsmetode krævet'
        }
    },
    'plan': {
        'name': {'title': 'Plan', 'detailTitle': 'Planlæg detaljer', 'editTitle': 'Rediger plan'},
        'table': {
            'id_pla': 'Id',
            'name': 'Plannavn',
            'price_per_month': 'Pris',
            'contract_period': 'Kontraktperiode',
            'free_charging_time_limit': 'Gratis opladningstid',
            'overtime_charge_price': 'Overtidspris',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_pla': 'Id',
            'name': 'Plannavn',
            'price_per_month': 'Pris',
            'contract_period': 'Kontraktperiode',
            'free_charging_time_limit': 'Gratis opladningstid',
            'overtime_charge_price': 'Overtidspris',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_pla': 'Id krævet',
                'name': 'Plannavn krævet',
                'price_per_month': 'Pris kræves',
                'contract_period': 'Kontraktsperiode krævet',
                'free_charging_time_limit': 'Gratis opladningstid krævet',
                'overtime_charge_price': 'Overtidspris kræves',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Trinstørrelsen for hvert spring, hvis mindre end et spring, vil det blive beregnet som et spring. Hvis beregnet baseret på det faktiske elforbrug af ordren, bedes du indstille det til nul",
            'byTimeUnit': "Trinstørrelsen for hvert spring, hvis mindre end et spring, vil den blive beregnet som et spring. Hvis beregnet baseret på det faktiske tidspunkt for rækkefølgen, bedes du sætte den til nul",
            'maximumPower': "Ved opladning i henhold til batteriniveauet er den øvre grænse for en enkelt opladning ikke begrænset, hvis den efterlades tom eller nul, og er ikke begrænset som standard",
            'maximumMinute': "Ved opladning i henhold til tid, efterlades den øvre grænse for en enkelt opladningstid tom eller nul, hvilket som standard ikke er begrænset",
        },
        'billingMethodType':{
            '0': "Af tid",
            '1': "Ved magt",
            '2': "Ved APP",
        },
        'name': {'title': 'Område', 'detailTitle': 'Regiondetaljer', 'editTitle': 'Rediger region'},
        'table': {
            'id_reg': 'Id',
            'name': 'Regionens navn',
            'ac_price_per_electricity': 'AC -pris pr. Elektricitet',
            'ac_price_per_minute': 'AC -pris pr. Minut',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Adresse',
            'dc_price_per_electricity': 'DC -pris pr. Elektricitet',
            'dc_price_per_minute': 'DC -pris pr. Minut',
            'tenant_key': 'Lejernøgle',
            'businessAllDay': 'Åben hele dagen',
            'images': 'Billeder',
            'byPowerUnit': "Målt i elektricitetsenheder",
            'byTimeUnit': "Måleenhed efter tid",
            'postpaid': "Efter betaling",
            'billingMethod': "Faktureringsmetode",
            'businessHours': "Åbningstider",

            'maximumPower': "Øvre grænse for enkelt ladekapacitet",
            'maximumMinute': "Øvre grænse for enkelt afgiftstid",
            'directDebit': "Automatisk fradrag",

            'enableTimeoutFine': "Straf for åbning af timeout",
            'timeoutFine': "Timeout tid(min)",
            'timeoutCharging': "Overtidsfakturering",
        },
        'validate': {
            'id_reg': 'Id krævet',
            'name': 'Regionnavn krævet',
            'ac_price_per_electricity': 'AC -pris pr. Elektricitet krævet',
            'ac_price_per_minute': 'AC -pris pr. Minut krævet',
            'lon': 'Lon kræves',
            'lat': 'Lat påkrævet',
            'address': 'Adresse kræves',
            'dc_price_per_electricity': 'DC -pris pr. Krævet elektricitet',
            'dc_price_per_minute': 'DC -pris pr. Minut krævet',
            'tenant_key': 'Lejernøgle kræves',
            'businessAllDay': 'Vælg, om det er åbent hele dagen',
            'byPowerUnit': "Udfyld venligst måleenheden baseret på elektricitetsmængde",
            'byTimeUnit': "Udfyld måleenhed efter tid",
            'postpaid': "Vælg venligst om du vil betale efter",
            'billingMethod': "Vælg venligst en faktureringsmetode",
            'businessHours': "Vælg åbningstider",

            'maximumPower': "Udfyld den øverste grænse for enkelt opladningskapacitet",
            'maximumMinute': "Udfyld den øverste grænse for enkelt opladningstid",
            'directDebit': "Vælg venligst om betalingen automatisk skal trækkes fra",

            'enableTimeoutFine': "Vælg venligst om timeout straf skal aktiveres",
            'timeoutFine': "Udfyld tidsfristen(min)",
            'timeoutCharging': "Udfyld venligst overtidsfaktureringen",
        }
    },
    'regionImages': {
        'name': {
            'title': 'Regionbillede',
            'detailTitle': 'Regionbilleddetaljer',
            'editTitle': 'Rediger regionbillede'
        },
        'table': {
            'id_reg_img': 'Id',
            'id_reg': 'Område',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_reg_img': 'Id',
            'id_reg': 'Område',
            'url': 'URL',
            'upload_time': 'Upload tid',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_reg_img': 'Id krævet',
                'id_reg': 'Region krævet',
                'url': 'URL påkrævet',
                'upload_time': 'Upload tid krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Reparationslog',
            'detailTitle': 'Reparationslogdetaljer',
            'editTitle': 'Rediger reparationslog'
        },
        'table': {
            'id_rep_log': 'Id',
            'id_car': 'Bil',
            'amount': 'Beløb',
            'repair_date': 'Reparationsdato',
            'remark': 'Bemærkning',
            'tenant_key': 'Lejernøgle'
        },
        'field': {
            'id_rep_log': 'Id',
            'id_car': 'Bil',
            'amount': 'Beløb',
            'repair_date': 'Reparationsdato',
            'remark': 'Bemærkning',
            'tenant_key': 'Lejernøgle'
        },
        'validate': {
            'required': {
                'id_rep_log': 'Id krævet',
                'id_car': 'Bil kræves',
                'amount': 'Krævet beløb',
                'repair_date': 'Reparationsdato krævet',
                'remark': 'Bemærkning krævet',
                'tenant_key': 'Lejernøgle kræves'
            }
        }
    },
    'role': {
        'name': {'title': 'Rolle', 'detailTitle': 'Rolle detaljer', 'editTitle': 'Rediger rolle'},
        'table': {'id_rol': 'Id', 'name': 'Rolle', 'remark': 'Bemærkning'},
        'field': {'id_rol': 'Id', 'name': 'Rolle', 'remark': 'Bemærkning', 'authorityList': 'Autoritetsliste'},
        'validate': {'required': {'id_rol': 'Id krævet', 'name': 'Den krævede rolle', 'remark': 'Bemærkning krævet'}}
    },
    'roleAuthority': {
        'name': {
            'title': 'Roleauthority',
            'detailTitle': 'Roleauthority detaljer',
            'editTitle': 'Rediger Roleauthority'
        },
        'table': {'id_rol_aut': 'Id', 'id_rol': 'Rolle', 'id_aut': 'Myndighed'},
        'field': {'id_rol_aut': 'Id', 'id_rol': 'Rolle', 'id_aut': 'Myndighed'},
        'validate': {
            'required': {
                'id_rol_aut': 'Id krævet',
                'id_rol': 'Den krævede rolle',
                'id_aut': 'Myndighed krævet'
            }
        }
    },
    'transactionLog': {
        'name': {
            'title': 'Transaktionslog',
            'detailTitle': 'Transaktionslogdetaljer',
            'editTitle': 'Rediger transaktionsloggen'
        },
        'table': {
            'id_tra_log': 'Id',
            'id_con': 'Connector IDT',
            'event_time': 'Begivenhedstid',
            'id_tag': 'ID -tag',
            'start_time': 'Starttidspunkt',
            'start_value': 'Startværdi',
            'stop_time': 'Stop tid',
            'stop_value': 'Stopværdi',
            'stop_reason': 'Stop fornuft',
            'fail_reason': 'Mislykkes grund',
            'tenant_key': 'Lejernøgle',
            'current_electricity': 'Nuværende elektricitet'
        },
        'field': {
            'id_tra_log': 'Id',
            'id_con': 'Connector IDT',
            'event_time': 'Begivenhedstid',
            'id_tag': 'ID -tag',
            'start_time': 'Starttidspunkt',
            'start_value': 'Startværdi',
            'stop_time': 'Stop tid',
            'stop_value': 'Stopværdi',
            'stop_reason': 'Stop fornuft',
            'fail_reason': 'Mislykkes grund',
            'tenant_key': 'Lejernøgle',
            'current_electricity': 'Nuværende elektricitet'
        },
        'validate': {
            'required': {
                'id_tra_log': 'Id krævet',
                'id_con': 'Connector IDT krævet',
                'event_time': 'Begivenhedstid krævet',
                'id_tag': 'ID -tag påkrævet',
                'start_time': 'Starttid krævet',
                'start_value': 'Startværdi krævet',
                'stop_time': 'Stop tid krævet',
                'stop_value': 'Stopværdi kræves',
                'stop_reason': 'Stop fornuft krævet',
                'fail_reason': 'Mislykkes grund krævet',
                'tenant_key': 'Lejernøgle kræves',
                'current_electricity': 'Nuværende elektricitet krævet'
            }
        }
    },
    'user': {
        'name': {'title': 'Bruger', 'detailTitle': 'Brugerdetaljer', 'editTitle': 'Rediger bruger'},
        'table': {
            'id_use': 'Id',
            'balance': 'Balance',
            'account': 'Konto',
            'password': 'Adgangskode',
            'register_time': 'Registrer tid',
            'email': 'E -mail',
            'token': 'Polet',
            'salt': 'Salt',
            'tenant_key': 'Lejernøgle',
            'phone': 'telefon',
            'register_type': 'Registertype'
        },
        'field': {
            'id_use': 'Id',
            'balance': 'Balance',
            'account': 'Konto',
            'password': 'Adgangskode',
            'register_time': 'Registrer tid',
            'email': 'E -mail',
            'token': 'Polet',
            'salt': 'Salt',
            'tenant_key': 'Lejernøgle',
            'phone': 'telefon',
            'register_type': 'Registertype',

            'chargingCard': 'Ladekortsnummer',
            'chargingType': 'Prioriteret opladningstilstand',
            'chargingDefDuration': 'kortopladningstid(min)',
            'chargingDefPower': 'Kortopladningskapacitet(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'Id krævet',
                'balance': 'Krævet balance',
                'account': 'Konto kræves',
                'password': 'Adgangskode kræves',
                'register_time': 'Registrer tid krævet',
                'email': 'E -mail krævet',
                'token': 'Token kræves',
                'salt': 'Salt krævet',
                'tenant_key': 'Lejernøgle kræves',
                'phone': 'Telefon kræves',
                'register_type': 'Registret type påkrævet',

                'chargingCard': 'Udfyld venligst opladningskortets nummer',
                'chargingType': 'Vælg kortprioritetsopladningstilstand',
                'chargingDefDuration': 'Udfyld venligst kortets opladningstid(min)',
                'chargingDefPower': 'Udfyld venligst beløbet for kortets opkrævning(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Brugerplan',
            'detailTitle': 'Brugerplandetaljer',
            'editTitle': 'Rediger brugerplan'
        },
        'table': {
            'id_use': 'Bruger',
            'id_pla': 'Plan',
            'arrearsCount': 'Udbetalinger Antal',
            'start_date': 'Start dato',
            'tenant_key': 'Lejernøgle',
            'createType': 'Opret identitet',
            'updateByMonth': 'Forskudsbetalingstid',

            'total': 'Forventet betalingsmåned',
            'amount': 'Forventet betalingsbeløb',
            'prePayData': 'Forventet betalingsmåned',
        },
        'validate': {
            'id_use': 'Bruger krævet',
            'id_pla': 'Plan kræves',
            'arrearsCount': 'Udfyld venligst antallet af udestående måneder',
            'start_date': 'Startdato krævet',
            'tenant_key': 'Lejernøgle kræves'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Samlede opladere',
            'availableChargers': 'Antallet af tilgængelige porte',
            'inUseChargers': 'Antal porte i brug',
            'unavailableChargers': 'Antallet af porte, der ikke er tilgængelige',
            'portUsage': 'Brug af port',

            'energyUsage': 'Energiforbrug (kWh)',
            'totalRevenue': 'Samlede indtægter',
            'totalEnergy': 'Total energi',
            'newUser': 'Ny bruger'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Android -version',
            'androidDownloadLink': 'Android Download Link',
            'androidComplieNumber': 'Android kompilerer nummer',
            'iosVersion': 'IOS -version',
            'iosBundleId': 'IOS Bundle ID',
            'iosComplieNumber': 'IOS kompilerer nummer',
            'isForcedUpdate': 'Vil du tvinge opdateringer'
        },
        'validate': {
            'required': {
                'androidVersion': 'Android -version kræves',
                'androidDownloadLink': 'Android Download Link krævet',
                'androidComplieNumber': 'Android -kompileringsnummer kræves',
                'iosVersion': 'IOS -version krævet',
                'iosBundleId': 'IOS -bundt -id påkrævet',
                'iosComplieNumber': 'IOS kompilerer nummer krævet',
                'isForcedUpdate': 'Vælg venligst om opdateringer skal tvinges'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Indstilling af nøgle', 'dataStr': 'Indstilling af værdi'}},
    'superTenantManage': {
        'name': {'title': 'Tilladelser', 'detailTitle': 'detaljer', 'editTitle': 'Redigere'},
        'searchForm': {
            'dataSourceKey': 'Datakilde -nøgle',
            'url': 'URL',
            'username': 'Brugernavn',
            'tenant': 'Lejer',
            'isEnable': 'Er aktiveret',
            'isCreate': 'Er oprettet'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Indtast venligst datakilde -nøgle',
            'url': 'Indtast venligst linkets adresse',
            'username': 'Indtast konto',
            'tenant': 'Indtast venligst lejernavn',
            'isEnable': 'Vælg, om du skal aktivere',
            'isCreate': 'Vælg er Opret'
        },
        'table': {
            'index': 'Indeks',
            'dataSourceKey': 'Datakilde -nøgle',
            'url': 'URL',
            'host': 'Vært',
            'port': 'Havn',
            'dbName': 'Databasens navn',
            'username': 'Brugernavn',
            'password': 'Adgangskode',
            'tenant': 'lejer',
            'isEnable': 'Er aktiveret',
            'isCreate': 'Er Opret',
            'domain': 'Domæne',
            'action': {'testConn': 'Conn', 'create': 'skab', 'edit': 'Redigere', 'del': 'Slet'}
        },
        'field': {
            'id': 'Id',
            'datasourceKey': 'Datakilde -nøgle',
            'seckey': 'Adgangsnøgler',
            'url': 'URL',
            'host': 'Vært',
            'port': 'Havn',
            'dbName': 'Database navn',
            'username': 'Brugernavn',
            'password': 'Adgangskode',
            'tenant': 'Lejer',
            'isEnable': 'Er aktiveret',
            'isCreate': 'Er oprettet',
            'remark': 'Bemærkning',
            'domain': 'Domæne',
            'onesignalKey': 'APP-meddelelsesnøgle'
        },
        'validate': {
            'required': {
                'id': 'Udfyld ID',
                'dataSourceKey': 'Udfyld venligst DataSourceKey',
                'seckey': 'Udfyld venligst Adgangsnøgler',
                'url': 'Udfyld venligst linket URL',
                'host': 'Udfyld værten',
                'port': 'Udfyld venligst porten',
                'dbName': 'Udfyld venligst database navn',
                'username': 'Indtast venligst brugernavn',
                'password': 'Udfyld venligst adgangskoden',
                'tenant': 'Udfyld venligst lejernavnet',
                'isEnable': 'Vælg en aktiv tilstand',
                'remark': 'Udfyld venligst bemærkningerne',
                'domain': 'Udfyld venligst domænet',
                'onesignalKey': 'Udfyld venligst APP-meddelelsesnøglen'
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'Id',
            'index': 'Indeks',
            'machineCode': 'Maskinkode',
            'name': 'Navn',
            'regId': 'Region ID',
            'regName': 'Regionens navn',
            'seckey': 'Adgangsnøgler',
            'args': 'Other parameters',
            'createBy': 'Opret ved',
            'createTime': 'Opret tid',
            'updateBy': 'Opdatering af',
            'updateTime': 'Opdateringstid',
            'remark': 'Bemærkning'
        },
        'validate': {
            'id': 'Udfyld ID',
            'machineCode': 'Udfyld venligst maskinkoden',
            'name': 'Udfyld navnet',
            'regId': 'Udfyld venligst region -ID',
            'seckey': 'Udfyld venligst Adgangsnøgler',
            'args': 'Udfyld venligst Other parameters',
            'remark': 'Udfyld venligst bemærkningen'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mail', '1': 'telefon'},
        'noticeType': {
            '0': 'Advarsel',
            '1': 'Minde om',
            '2': 'Bekendtgørelse',
            '3': 'Overarbejde',
        },
        'field': {
            'id': 'Id',
            'index': 'Indeks',
            'toFrom': 'Konto',
            'type': 'Kontotype',
            'noticeType': 'Underretningstype',
            'status': 'Er aktiveret',
            'createBy': 'Opret ved',
            'createTime': 'Opret tid',
            'updateBy': 'Opdatering af',
            'updateTime': 'Opdateringstid',
            'remark': 'Bemærkning'
        },
        'validate': {
            'id': 'Udfyld ID',
            'type': 'Vælg Kontotype',
            'toFrom': 'Udfyld venligst kontoen',
            'noticeType': 'Vælg anmeldelsestype',
            'status': 'Vælg, om du skal aktivere',
            'remark': 'Udfyld venligst bemærkningen'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'Servicevilkår',
            '1': 'Om os',
            '2': 'bekendtgørelse',
            '3': 'meddelelse',
            '4': 'minde',
        },
        'field': {
            'id': 'ID',
            'index': 'Serienummer',
            'type': 'Annonce Type',
            'status': 'Er aktiveret',
            'title': 'Titel',
            'content': 'Indhold',
            'tenantKey': 'Lejers identitet',

            'createBy': 'Oprette af',
            'createTime': 'Skab tid',
            'updateBy': 'Opdatering af',
            'updateTime': 'Opdateringstid',
            'remark': 'Bemærkning',
        },
        'validate': {
            'id': 'Udfyld venligst ID\'et',
            'type': 'Vælg typen af meddelelse',
            'status': 'Vælg, om du vil aktivere det',
            'title': 'Udfyld venligst titlen på meddelelsen',
            'content': 'Udfyld venligst indholdet af meddelelsen',
            'remark': 'Udfyld venligst kommentarerne',
        }
    },
    statisticsManage: {
        table: {
            index: 'Serienummer',
            identifier: 'Pile Identifier',
            useNum: 'Antal Anvendelser',
            chargeCapacity: 'Samlet Forbrug(kw)',

            startTime: 'Starttidspunkt',
            endTime: 'Sluttidspunkt'
        },
        validate: {
            identifier: 'Indtast pæleidentifikationskoden',
            startTime: 'Vælg et starttidspunkt',
            endTime: 'Vælg et sluttidspunkt'
        }
    },
    serviceManage: {
        type: {
            0: 'Drikkevarer',
            1: 'Mad',
            2: 'Toilet',
            3: 'Butikker',
        },
        table: {
            id: 'ID',
            type: 'slags',
            status: 'stat',
            name: 'Navn',
            lng: 'længdegrad',
            lat: 'breddegrad',
            address: 'adresse',
            description: 'Beskrivelse',
            reId: 'Region ID'
        },
        validate: {
            type: 'Vælg en type',
            status: 'Vælg en status',
            name: 'Udfyld venligst navnet',
            lng: 'Udfyld længdegraden',
            lat: 'Udfyld breddegraden',
            address: 'Udfyld venligst adressen',
            description: 'Udfyld en beskrivelse',
            reId: 'Tilknyttet region'
        }
    },
    companyManage: {
        field: {
            id: 'Primær nøgle',
            tId: 'Tilknyt Lejer-id',
            name: 'Virksomhedens Navn',
            attn: 'Virksomhedens Kontaktperson',
            tel: 'Firma Telefon',
            address: 'Virksomhedens Adresse',
            email: 'E-mail til Virksomheden',
            invoiceDate: 'Faktura Dato',
            invoiceNo: 'Faktura Nummer',
            contactPerson: 'Kontakt',
            contactTel: 'Kontakt Number',
            contactEmail: 'Kontakt Email',
            monthlyFee: 'Systemadgang månedligt gebyr forudindstillet værdi',
            company: 'Firmaoplysninger'
        },
        validate: {
            id: 'Indtast en primær nøgle',
            tId: 'Angiv det tilknyttede lejer-id',
            name: 'Indtast et firmanavn',
            attn: 'Indtast virksomhedens kontaktperson',
            tel: 'Indtast din firmatelefon',
            address: 'Indtast din firmaadresse',
            email: 'Indtast din virksomheds e-mail',
            invoiceDate: 'Indtast venligst fakturadato',
            invoiceNo: 'Indtast fakturanummeret',
            contactPerson: 'Indtast en kontaktperson',
            contactTel: 'Indtast dit kontaktnummer',
            contactEmail: 'Indtast din kontakt-e-mail',
            monthlyFee: 'Indtast standardværdien af det månedlige systemadgangsgebyr'
        }
    },
    tenantInfo: {
        table: {
            id: 'ID',
            pid: 'ID for forælderlejer',
            tenantKey: 'Identifikation af lejer',
            name: 'Lejerens navn',
            domain: 'Bind domænenavn',
            tpId: 'Tilknyttet pakke',
            authId: 'Tilknyttede tilladelser',
            level: 'niveau',
            contact: 'kontakter',
            email: 'Kontaktmail',
            phone: 'Kontaktnummer',
            startTime: 'Aktiveringstid',
            expireTime: 'Udløbstid',
        },
        args:{
            yedPayExtendParams: 'YedPay-Parameter',
            yedPayApiKey: 'YedPay ApiKey',
            yedPaySingKey: 'YedPay SingKey',

            mPayExtendParams: 'MPay-Parameter',
            mPayPubKey: 'MPay Offentlig nøgle',
            mPayPriKey: 'MPay Privat nøgle',
        },
        validate: {
            id: 'Indtast et ID',
            pid: 'Indtast den overordnede lejer-id',
            tenantKey: 'Udfyld lejerens ID',
            name: 'Udfyld venligst lejerens navn',
            domain: 'Udfyld venligst det bundne domænenavn',
            tpId: 'Vælg venligst en tilknyttet pakke',
            authId: 'Vælg de tilknyttede tilladelser',
            contact: 'Udfyld venligst kontaktpersonen',
            email: 'Udfyld venligst kontaktemailen',
            phone: 'Udfyld venligst kontaktnummeret',
            startTime: 'Vælg aktiveringstidspunktet',
            expireTime: 'Vælg en udløbstid',

            yedPayExtendParams: 'Indtast YedPay-parameteren',
            yedPayApiKey: 'Indtast YedPay ApiKey',
            yedPaySingKey: 'Indtast YedPay SingKey',
            mPayExtendParams: 'Indtast MPay-parametre',
            mPayPubKey: 'Indtast den offentlige MPay-nøgle',
            mPayPriKey: 'Indtast venligst MPays private nøgle',
        }
    },
    tenantPackage: {
        table: {
            id: 'ID',
            packageNo: 'Pakningsnummer',
            name: 'Pakkenavn',
            createTenantMax: 'Antal lejere, der kan oprettes',
            createUserMax: 'Antal brugere, der kan oprettes',
            createAppMax: 'Antal registrerede apps',
            expireLong: 'Pakkens faktiske varighed',
            tenantKey: 'Identifikation af lejer',
        },
        validate: {
            id: 'Indtast et ID',
            packageNo: 'Indtast venligst pakkenummeret',
            name: 'Indtast venligst pakkenavnet',
            createTenantMax: 'Indtast antallet af lejere, der kan oprettes',
            createUserMax: 'Indtast antallet af lejere, der kan oprettes',
            createAppMax: 'Indtast antallet af registrerede apps',
            expireLong: 'Vælg pakkens effektive varighed',
            tenantKey: 'Indtast lejerens ID',
        }
    },
    card: {
        cardType: {
            0:"Hvidliste",
            1:"Sorteliste",
            2:"Systemkort",
            3:"Brugerkort",
        },
        table: {
            cardId: 'ID',
            cardNo: 'Kortnummer',
            cardType: 'Type',
            cardStatus: 'Er det aktiveret',
        },
        validate: {
            cardId: 'Udfyld ID\'et',
            cardNo: 'Udfyld kortnummeret',
            cardType: 'Vælg en type',
            cardStatus: 'Vælg en status',
        }
    },
    waller: {
        wType: {
            1: 'Balance',
            2: 'Gavebeløb',
            3: 'Integreret'
        },
        table: {
            walletId: 'ID',
            account: 'Konto',
            type: 'Type',
            status: 'Frys eller ej',
            balance: 'Balance',
        },
        validate: {
            account: 'Udfyld venligst din konto',
            type: 'Vælg venligst typen',
            status: 'Vælg en status',
        }
    }
}
export default daDK