<template>
  <Modal
      id="ForgotPassword"
      width="38%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="onSubmit"
      :title="$t('public.name.reset_password')"
      :confirmLoading="confirmLoading"
      destroyOnClose
  >
    <Row align="middle" justify="center" class="tmodal">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="formState"
                layout="vertical"
                :rules="validateRules"
            >
              <FormItem name="radio-button">
                <RadioGroup v-model:value="formState.verify">
                  <RadioButton value="1">{{ $t('public.name.email') }}</RadioButton>
<!--                  <RadioButton value="2">{{ $t('public.name.phone') }}</RadioButton>-->
                </RadioGroup>
              </FormItem>

              <FormItem name="username" :label="$t('public.name.username')">
                <Input :placeholder="$t('login.validate.required.username')" v-model:value="formState.username"></Input>
              </FormItem>

              <FormItem name="newPassword" :label="$t('public.name.newPassword')">
                <InputPassword :placeholder="$t('public.validate.required.password')" v-model:value="formState.newPassword"></InputPassword>
              </FormItem>

              <FormItem name="confirmPassword" :label="$t('public.name.confirm_password')">
                <InputPassword :placeholder="$t('public.validate.required.repassword')" v-model:value="formState.confirmPassword"></InputPassword>
              </FormItem>

              <Row>
                <Col :span="18">
                  <FormItem name="code" :label="$t('public.name.captcha')">
                    <Input :placeholder="$t('login.validate.required.captcha')" v-model:value="formState.code"></Input>
                  </FormItem>
                </Col>
                <Col :span="5" :offset="1">
                  <FormItem label="&nbsp;">
                    <Button type="primary" :disabled="codeBtnDisable" :loading="buttLoading" @click="getCode()">{{ codeBtnWord }}</Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputPassword,
  Button,
  RadioGroup,
  RadioButton, message
} from "ant-design-vue";
import {ref, reactive} from 'vue'
import {useI18n} from "vue-i18n";

import { GetCode, tForgotPassword} from '@/api/employee'

const i18n = useI18n()

let visible = ref(false)
let confirmLoading = ref(false)
const refForm = ref()
const validateRules = reactive({
  username: [
    {
      required: true,
      message: i18n.t("public.validate.required.username"),
      trigger: "blur",
    },
  ],
  newPassword: [
    {
      required: true,
      message: i18n.t("public.validate.required.password"),
      trigger: "blur",
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: i18n.t("public.validate.required.repassword"),
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      type: "email",
      message: i18n.t("public.validate.required.email"),
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      // type: "email",
      message: i18n.t("public.validate.required.phone"),
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: i18n.t("login.validate.required.captcha"),
      trigger: "blur",
    },
  ],

})
let buttLoading = ref(false)
let codeBtnWord = ref(i18n.t("public.name.getCaptcha"))
let waitTime = ref(61)
let codeBtnDisable = ref(false)

const formState = ref({
  verify: '1'
})
const onSubmit = () => {
  refForm.value.validate().then(() => {
    if (formState.value.newPassword === undefined || formState.value.newPassword !== formState.value.confirmPassword) {
      message.error(i18n.t("errorMsg.500209"));
      return false
    }

    confirmLoading.value = true
    const api = new tForgotPassword()
    api.post(formState.value).then(response => {
      confirmLoading.value = false
      if (response.data.code === 0) {
        message.success(i18n.t("succeedMsg.0"));
        visible.value = false
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    }).finally(()=>{
      confirmLoading.value = false
    })
  })
}

const onShow = () => {
  formState.value.verify = "1"
  visible.value = true
}

const getCode = () => {
  if (formState.value.newPassword === undefined || formState.value.newPassword !== formState.value.confirmPassword) {
    message.error(i18n.t("errorMsg.500209"));
    return false
  }

  waitTime.value--;
  codeBtnDisable.value = true;

  buttLoading.value = true
  const api = new GetCode()
  const args = {
    verify: formState.value.verify,
    username: formState.value.username,
  }
  api.get(args).then(response => {
    buttLoading.value = false
    if (response.data.code === 0) {
      message.success(i18n.t("succeedMsg.0"));

      codeBtnWord.value = `${waitTime.value}s`;
      let timer = setInterval(() => {
        if (waitTime.value > 1) {
          waitTime.value--;
          codeBtnWord.value = `${waitTime.value}s`;
        } else {
          clearInterval(timer);
          codeBtnWord.value = i18n.t("public.name.getCaptcha");
          codeBtnDisable.value = false;
          waitTime.value = 61;
        }
      }, 1000);
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  }).finally(()=>{
    buttLoading.value = false
  })
}

// 暴露方法给父组件
defineExpose({
  onShow,
});
</script>

<style lang="less">
.tmodal {
  //min-height: 390px;
  height: 400px;
}
</style>