const plPL = {
    'succeedMsg': {
        '0': 'Sukces',
    },
    'errorMsg': {
        '500100': 'Wyjątek po stronie serwera',
        '500101': 'Błąd parametru',
        '500102': 'Wyjątek sprawdzania poprawności parametru',
        '500103': 'Wniosek jest nielegalny',
        '500104': 'Wizyty są zbyt częste',
        '500105': 'Obiekt już istnieje',
        '500106': 'Operacja nie powiodła się: „Spróbuj ponownie',
        '500107': 'Istnieje skojarzenie ”, usunięcie jest zabronione',
        '500108': 'Wyjątki eksportu',
        '500200': 'Zaloguj się, „Zaloguj się ponownie',
        '500201': 'Numer konta lub hasło nie mogą być puste',
        '500202': 'Numer telefonu nie może być pusty',
        '500203': 'Numer telefonu jest w niewłaściwym formacie',
        '500204': 'Numer telefonu nie istnieje',
        '500205': 'Hasło jest błędne',
        '500206': 'Konto nie istnieje',
        '500207': 'Numery konta mogą zawierać tylko liczby, „angielski” i podkreśla',
        '500208': 'Uwierzytelnianie nie powiodło się',
        '500209': 'Dwa hasła nie są takie same',
        '500210': 'Duplicate accounts',
        '500211': 'Konto zostało zablokowane',
        '500212': 'Błąd CAPTCHA',
        '500213': 'Numer telefonu jest zły',
        '500214': 'There is no tenant corresponding to the domain name, contact your administrator',
        '500215': 'exists the same role name',
        '500300': 'If the OCCP service is abnormal, contact the administrator',
        '500301': 'Electric piles are not available',
        '500302': 'The pile configuration is empty',
        '500303': 'The battery is offline, please check if it is available',
        '500304': 'Port stosu nie jest dostępny',
        '500305': 'The gun is not inserted or the current gun is not ready',
        '500306': 'Start charging failure',
        '500307': 'Bundled charging failure',
        '500401': 'Niewystarczająca ilość środków',
        '500402': 'The order does not exist',
        '500403': 'The order was not paid',
        '500404': 'Zamówienie zostało zapłacone',
        '500405': 'Order status is not [Paid Unused]',
        '500406': 'Tworzenie zamówienia nie powiodło się',
        '500407': 'Są niezapłacone zamówienia.',
        '500408': 'Zamówienie anulowane',
        '500501': 'The item has been sold in seconds',
        '500502': 'Lightning Deals cannot be repeated',
        '500503': 'Second kill failure',
        '500601': 'The file cannot be empty',
        '500602': 'File format error',
        '500603': 'The Excel workbook is empty',
        '500604': 'Nie udało się odczytać pliku'
    },
    'public': {
        'name': {
            'nextStep': 'Następny krok',
            'status': 'Państwo',
            'hello': 'Cześć',
            'login': 'Zaloguj sie',
            'logout': 'Wyloguj',
            'username': 'Nazwa użytkownika',
            'email': 'E-mail',
            'password': 'Hasło',
            'remember': 'Pamiętaj mnie',
            'currentPassword': 'Aktualne hasło',
            'newPassword': 'Nowe hasło',
            'confirm_password': 'Potwierdź hasło',
            'reset_password': 'Zresetuj hasło',
            'captcha': 'Captcha',
            'getCaptcha': 'Pobierz Captcha',
            'more': 'Więcej',
            'add': 'Dodać',
            'batchDel': 'Usuń partię',
            'Export': 'Eksport',
            'Import': 'Import',
            'DownloadTemp': 'Pobierz szablon',
            'ImportTips': 'Kliknij lub przeciągnij plik do tego obszaru, aby przesłać',
            'edit': 'Edytować',
            'del': 'Usuwać',
            'detail': 'Szczegół',
            'yes': 'Tak',
            'no': 'NIE',
            'empty': 'Pusty',
            'are_you_sure_delete': 'Czy na pewno chcesz usunąć ten element',
            'action_cannot_resumed': 'Tego działania nie można wznowić',
            'are_you_sure_operate': 'Czy jesteś pewien co do bieżącej operacji',
            'operate_remind': 'Jesteś pewien, że obecna operacja jest wrażliwa? Proszę zachować ostrożność',
            'search': 'Szukaj',
            'reset': 'Resetowanie',
            'spread': 'Rozpowszechnianie się',
            'retract': 'Wycofać',
            'show': 'Pokazywać',
            'no_page': 'Brak strony',
            'service': 'Praca',
            'phone_country_code': 'Kod kraju telefonicznego',
            'name': 'Nazwa',
            'phone': 'Telefon',
            'sex': 'Seks',
            'country': 'Kraj',
            'address': 'Adres',
            'birthday': 'Urodziny',
            'remark': 'Uwaga',
            'personal': 'Osobisty',
            'enclosure': 'Załącznik',
            'logo': 'Logo',
            'submit': 'Składać',
            'unknown': 'Nieznany',
            'setting': 'Organizować coś',
            'index': 'Indeks',
            'createBy': 'Utwórz przez',
            'createTime': 'Utwórz czas',
            'updateBy': 'Aktualizacja według',
            'updateTime': 'Czas aktualizacji',
            'type': 'Typ',
            'args': 'Parametr',
            'qrcode': 'Kod QR',
            'query': 'Zapytanie',
            'refund': 'Refundacja',
            'cancel': 'Anuluj',
            'revoke': 'Cofnij'
        },
        'msg': {
            'unRead': 'Odczytaj',
            'read': 'Przeczytaj',
            'alarm': 'Alarm',
            'reminder': 'Przypomnienie',
            'advertise': 'Reklama'
        },
        'language': {
            'zhTW': 'Chiński tradycyjny)',
            'enUS': 'język angielski',
            'zhCN': 'Uproszczony chiński)',
            'daDK': 'duński',
            'idID': 'indonezyjski',
            'msMY': 'malajski',
            'thTH': 'tajski',
            'viVN': 'wietnamski',
            'frFR': 'Francuski',
            'plPL': 'Polski',
            'it': 'Włoski',
        },
        'timeType': {'0': 'Rok', '1': 'Miesiąc', '2': 'Tydzień', '3': 'Dzień'},
        'promptsToSelectTime': 'Wybierz czas',
        'pleaseSelect': 'Proszę wybrać',
        'pileStatus': {'0': 'Dostępny', '1': 'Ładowanie', '2': 'Niedostępne'},
        'isTrue': {'0': 'NIE', '1': 'Tak'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E-mail', '1': 'Telefon'},
        'doingStatus':{
            '0': 'Oczekujące',
            '1': 'Przetwarzanie',
            '2': 'Zakończone',
            '3': 'Anuluj',
            '4': 'Wygaśnięty',
            '5': 'Zakończenie',
        },
        'consumeType': {
            '0': "Ładowanie",
            '1': "Pobieranie opłat",
            '2': "Anulowanie opłat",
            '3': "Saldo",
            '4': 'Plan bilansu',
            '5': 'Anuluj plan',
            '6': 'Plan odnowy',
            '7': 'Refundacja',
            '8': 'Nota karna',
            '9': 'Anuluj grzywny',
            '10': 'Wycofanie',
            '11': 'Pełny prezent',
            '12': 'Cofnij',
        },
        'payStatus': {'0': 'Nie zapłacony', '1': 'Płatny', '2': 'Ładowanie', '3': 'Zakończony', '4': 'Anuluj'},
        'chargeType': {'0': 'Z czasem', '1': 'Według zamówienia', '2': 'Według planu użytkownika'},
        'status': {'0': 'Nieznany', '1': 'Normalna', '2': 'Wyłączyć', '3': 'Usuwać'},
        'sex': {'1': 'Człowiek', '2': 'Kobieta', '3': 'Nieznany'},
        'placeholder': {'please_select': 'Proszę wybrać'},
        'table': {
            'name': 'Nazwa',
            'status': 'Państwo',
            'action': 'Działanie',
            'content': 'Treść',
            'created_at': 'Utwórz czas',
            'image': 'Obraz',
            'email': 'E-mail',
            'order_id': 'Zamówienie',
            'sex': 'Seks',
            'last_ip': 'Ostatnie IP',
            'phone_country_code': 'Wybierz kod kraju telefonicznego',
            'phone': 'Telefon'
        },
        'field': {
            'name': 'Nazwa',
            'username': 'Nazwa użytkownika',
            'content': 'Treść',
            'phone_country_code': 'Kod kraju telefonicznego',
            'phone': 'Telefon',
            'email': 'E-mail',
            'order_id': 'Zamówienie',
            'portrait': 'Portret',
            'status': 'Państwo',
            'image': 'Obraz',
            'sex': 'Seks'
        },
        'validate': {
            'required': {
                'id': 'Brak id',
                'username': 'Proszę wpisać nazwę użytkownika',
                'password': 'Proszę wpisać hasło',
                'repassword': 'Powtórz hasło',
                'name': 'Proszę o nazwę wpisu',
                'phone': 'Proszę o wjazd telefon',
                'email': 'Prosimy o e-mail',
                'portrait': 'Prześlij portret'
            },
            'tips': {
                'inconsistent_password': 'Niespójne hasło',
                'length_should': 'Długość powinna wynosić {min} do {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Chiński tradycyjny)',
        'enUS': 'język angielski',
        'zhCN': 'Uproszczony chiński)',
        'daDK': 'duński',
        'idID': 'indonezyjski',
        'msMY': 'malajski',
        'thTH': 'tajski',
        'viVN': 'wietnamski',
        'frFR': 'Francuski',
        'plPL': 'Polski',
        'it': 'Włoski',
    },
    'nav': {
        'name': {
            'Dashboard': 'Panel',
            'RegionDashboard': 'Przegląd stosów',
            'BigScreen': 'Duży ekran',
            'AuthorityManage': 'Organ zarządza',

            'BalanceLogManage': 'Różnorodność bilansu',
            'CarManage': 'Zarządzanie samochodem',
            'CarImagesManage': 'Obrazy samochodowe',
            'ChargeStationImagesManage': 'Charge Point ImageManage',
            'ChargingStationManage': 'Charge Point Managage',
            'ConnectorManage': 'Zarządzanie złączem',
            'EmployeeManage': 'Operator zarządza',
            'ConnectorLogManage': 'Dziennik łącznika',
            'EventLogManage': 'Logowanie zdarzeń',
            'MeterManage': 'Zarządzanie licznikami',
            'OrderManage': 'Zarządzanie zamówieniem',
            'PlanManage': 'Plan zarządzania',
            'RegionManage': 'Region zarządza',
            'RegionImagesManage': 'Zarządzanie obrazem regionu',
            'RepairLogManage': 'ZAPROPAMING Managage',
            'RoleManage': 'Zarządzaj rolą',
            'TransactionLogManage': 'Dziennik transakcji',
            'UserManage': 'Zarządzanie użytkownikiem',
            'UserPlanManage': 'Planowanie użytkowników',
            'GetConfiguration': 'Uzyskaj konfigurację',
            'DataTransfer': 'Transfer danych',

            'NoticeManage': 'Zawiadomienie zarządzaj',
            'PublicityManage': 'Zarządzanie reklamą',
            'ChargerSetting': 'Ustawienie ładowarki',
            'StatisticsManage': 'Statystyka',
            'AppManagement': 'Zarządzanie aplikacjami',
            'LogManagement': 'Zarządzanie dziennikiem',
            'AppVersionManage': 'Wersja aplikacji Zarządzaj',
            'WallerManage': 'Limit konta',
            'iPadManagement': 'Zarządzanie iPada',

            'ServiceManage': 'Zarządzanie usługami',
            'CardManage': 'Zarządzanie numerami karty',

            'iPadManage': 'Zarządzaj iPada',

            'TenantManagement': 'Zarządzanie systemem',
            'SuperTenantManage': 'Super Najemca',
            'TenantManage': 'Najemcy zarządzają',
            'TenantInfo': 'Najemcy zarządzają',
            'TenantPackage': 'Pakej Tenant',
        },
        'table': {'order_id': 'Zamów id', 'url': 'URL'},
        'field': {
            'pid': 'Pid',
            'order_id': 'Zamów id',
            'name': 'Nazwa',
            'url': 'URL',
            'status': 'Państwo',
            'icon': 'Ikona'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Proszę, wpisz nazwę użytkownika',
                'email': 'Prosimy o wpis e -mail',
                'password': 'Proszę wpisać hasło',
                'captcha': 'Proszę wpisać captcha'
            }
        }
    },
    'authority': {
        'name': {'title': 'Autorytet', 'detailTitle': 'Szczegóły autorytetu', 'editTitle': 'Edytuj organ'},
        'table': {
            'id_aut': 'ID',
            'a_id_aut': 'Urząd rodzica',
            'name': 'Autorytet',
            'resource_name': 'Nazwa zasobu',
            'type': 'Typ',
            'sort': 'Sortować'
        },
        'field': {
            'id_aut': 'ID',
            'a_id_aut': 'Urząd rodzica',
            'name': 'Autorytet',
            'resource_name': 'Nazwa zasobu',
            'type': 'Typ',
            'sort': 'Sortować'
        },
        'validate': {
            'required': {
                'id_aut': 'Wymagany id',
                'a_id_aut': 'Wymagany organ rodzica',
                'name': 'Wymagany autorytet',
                'resource_name': 'Wymagana nazwa zasobu',
                'type': 'Wymagany typ',
                'sort': 'Wymagane sortowanie'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Pakiet ładowania',
        'addRule': 'Dodaj regułę',
        'gift': {
            'enable': 'Czy to włączone',
            'percentage': 'W procentach',
            'star': 'Wartość początkowa',
            'end': 'Wartość końcowa',
            'give': 'Limit',
        },
        'vGift': {
            'enable': 'Proszę wybrać, czy chcesz ją włączyć, czy nie',
            'percentage': 'Proszę wybrać, czy użyć procentowego czy nie',
            'star': 'Proszę wprowadzić wartość początkową',
            'end': 'Proszę wprowadzić wartość końcową',
            'give': 'Proszę wprowadzić limit kredytowy',
        },
        'name': {
            'title': 'Dziennik równowagi',
            'detailTitle': 'Szczegóły dziennika równowagi',
            'editTitle': 'Edytuj dziennik salda',
            'ticket': 'Nota karna',
            'cancelFines': 'Anuluj grzywny'
        },
        'table': {
            'id_bal_log': 'ID',
            'id_use': 'Używać',
            'type': 'Typ',
            'amount': 'Kwota',
            'log_time': 'Czas dziennika',
            'tenant_key': 'Klucz najemcy',
            'status': 'Status',
            'payment_method': 'Metoda płatności'
        },
        'field': {
            'id_bal_log': 'ID',
            'id_use': 'Uer',
            'type': 'Typ',
            'amount': 'Kwota',
            'log_time': 'Czas dziennika',
            'tenant_key': 'Klucz najemcy',
            'status': 'Status',
            'payment_method': 'Metoda płatności'
        },
        'validate': {
            'required': {
                'id_bal_log': 'Wymagany id',
                'id_use': 'Wymagane',
                'type': 'Wymagany typ',
                'amount': 'Wymagana kwota',
                'log_time': 'Wymagany czas dziennika',
                'tenant_key': 'Wymagany klucz najemcy',
                'status': 'Wymagany status',
                'payment_method': 'Wymagana metoda płatności'
            }
        }
    },
    'car': {
        'name': {'title': 'Samochód', 'detailTitle': 'Szczegóły samochodu', 'editTitle': 'Edytuj samochód'},
        'table': {
            'id_car': 'ID',
            'id_use': 'Uer',
            'model': 'Model',
            'car_license': 'Licencja',
            'max_years_old': 'MAX ROK',
            'manufacture_year': 'Rok produkcji',
            'maximum_power': 'Maksymalna moc',
            'is_second_hand': 'Jest z drugiej ręki',
            'vehicle_inspection_date': 'Data kontroli pojazdu',
            'insurance_expiry_date': 'Data wygaśnięcia ubezpieczenia',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_car': 'ID',
            'id_use': 'Uer',
            'model': 'Model',
            'car_license': 'Licencja',
            'max_years_old': 'MAX ROK',
            'manufacture_year': 'Rok produkcji',
            'maximum_power': 'Maksymalna moc',
            'is_second_hand': 'Jest z drugiej ręki',
            'vehicle_inspection_date': 'Data kontroli pojazdu',
            'insurance_expiry_date': 'Data wygaśnięcia ubezpieczenia',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_car': 'Wymagany id',
                'id_use': 'Wymagane',
                'model': 'Wymagany model',
                'car_license': 'Wymagana licencja',
                'max_years_old': 'Wymagany maksymalny rok',
                'manufacture_year': 'Wymagany rok produkcji',
                'maximum_power': 'Wymagana maksymalna moc',
                'is_second_hand': 'Jest wymagane z drugiej ręki',
                'vehicle_inspection_date': 'Wymagana data kontroli pojazdu',
                'insurance_expiry_date': 'Wymagana data wygaśnięcia ubezpieczenia',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Obrazy samochodowe',
            'detailTitle': 'Obrazy samochodowe szczegółowe',
            'editTitle': 'Edytuj obrazy samochodu'
        },
        'table': {
            'id_car_ima': 'ID',
            'id_car': 'Samochód',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_car_ima': 'ID',
            'id_car': 'Samochód',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_car_ima': 'Wymagany id',
                'id_car': 'Wymagany samochód',
                'url': 'Wymagany adres URL',
                'upload_time': 'Wymagany czas przesłania',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Obraz punktu ładowania',
            'detailTitle': 'Szczegóły obrazu punktu ładowania',
            'editTitle': 'Edytuj obraz punktu ładowania'
        },
        'table': {
            'id_ima': 'ID',
            'id_cha_poi': 'Punkt ładowania',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_ima': 'ID',
            'id_cha_poi': 'Punkt ładowania',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_ima': 'Wymagany id',
                'id_cha_poi': 'Wymagany punkt ładunku',
                'url': 'Wymagany adres URL',
                'upload_time': 'Wymagany czas przesłania',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Ustaw sekwencję płatności zlecenia ładowania, domyślnie ustawienia regionalne",
            'billingMethod': "Ustaw metodę rozliczania zlecenia ładowania, domyślnie ustawiona według regionu",
        },
        'billingMethodType': {
            '0':"Z czasem",
            '1':"Przez moc",
            '2':"Według planu użytkownika",
            '3':"Naciśnij APP",
            '4':"Według regionu",
        },
        'updateFirmware': {
            'identifier': "Numer seryjny",
            'location': "Adres zdalny",
            'retrieveDate': "Data pobrania",
        },
        'uValidate': {
            'identifier': "Wprowadź numer seryjny",
            'location': "Wprowadź zdalny adres",
            'retrieveDate': "Wybierz datę wyszukiwania",
        },
        'setting': {
            'index': 'numer seryjny',
            'readonly': 'Tylko czytać',
            'key': 'Ustaw klucz',
            'value': 'Ustaw wartość'
        },
        'settingField': {
            'readonly': 'Wybierz, czy jest to tylko do odczytu',
            'key': 'Klucz ustawiony nie może być pusty',
            'value': 'Wartość ustawienia nie może być pusta'
        },
        'name': {
            'title': 'Punkt ładowania',
            'detailTitle': 'Szczegóły punktu ładowania',
            'editTitle': 'Edytuj punkt ładowania',
            'quickAdd': 'Dodaj punkt ładowania'
        },
        'table': {
            'id_cha_poi': 'ID',
            'id_met': 'Metr',
            'charge_box_id': 'Identyfikator skrzynki ładunku',
            'firmwareVersion': 'Wersja oprogramowania sprzętowego',
            'last_heartbeat_time': 'Ostatni czas bicia serca',
            'monthly_fee': 'Miesięczna opłata',
            'charger_type': 'Typ ładowarki',
            'tenant_key': 'Klucz najemcy',
            'name': 'Nazwa',
            'heartbeat_interval': 'Interwał bicia serca',
            'enable': 'Włączać',
            'maxCurrent': "Maksymalny prąd",
            'minCurrent': "Minimalny prąd",
            'isPrivate': "Prywatne lub nie",
            'isReadMeter': "Czy odczytać tabelę",
            'isReset': "Czy należy ponownie uruchomić (ponowne uruchomienie może wejść w życie)",
            'action': {
                'updateFirmware': "Aktualizacja oprogramowania sprzętowego",
                'hardReset': "Twarde resetowanie",
                'softReset': "Miękkie resetowanie"
            }
        },
        'validate': {
            'id_cha_poi': 'Wymagany id',
            'id_met': 'Wymagany licznik',
            'charge_box_id': 'Wymagany identyfikator pola ładowania',
            'last_heartbeat_time': 'Wymagany ostatni czas bicia serca',
            'monthly_fee': 'Wymagana miesięczna opłata',
            'charger_type': 'Wymagany typ ładowarki',
            'tenant_key': 'Wymagany klucz najemcy',
            'name': 'Imię (wymagane',
            'heartbeat_interval': 'Wymagany interwał bicia serca',
            'enable': 'Włącz wymagane',
            'maxCurrent': "Proszę wypełnić maksymalny prąd",
            'minCurrent': "Proszę wypełnić minimalny prąd",
        }
    },
    'connector': {
        'name': {
            'title': 'Złącze',
            'detailTitle': 'Szczegóły złącza',
            'editTitle': 'Edytuj złącze',
            'startTransaction': 'Rozpocznij transakcję',
            'stopTransaction': 'Zatrzymaj transakcję'
        },
        'table': {
            'id_con': 'ID',
            'id_cha_poi': 'Punkt ładowania',
            'name': 'Nazwa',
            'connector_id': 'Identyfikator złącza',
            'status': 'Status',
            'tenant_key': 'Klucz najemcy',
            'power': 'Power (KW)',
            'type': 'Typ'
        },
        'field': {
            'id_con': 'ID',
            'id_cha_poi': 'Punkt ładowania',
            'name': 'Nazwa',
            'connector_id': 'Identyfikator złącza',
            'status': 'Status',
            'tenant_key': 'Klucz najemcy',
            'power': 'Power (KW)',
            'type': 'Typ'
        },
        'validate': {
            'required': {
                'id_con': 'Wymagany id',
                'id_cha_poi': 'Wymagany punkt ładunku',
                'name': 'Imię (wymagane',
                'connector_id': 'Wymagany identyfikator złącza',
                'status': 'Wymagany status',
                'tenant_key': 'Wymagany klucz najemcy',
                'power': 'Wymagana moc (kW)',
                'type': 'Wymagany typ'
            }
        }
    },
    'employee': {
        'name': {'title': 'Operator', 'detailTitle': 'Szczegóły operatora', 'editTitle': 'Edytuj operator'},
        'table': {
            'id_emp': 'ID',
            'id_rol': 'Rola',
            'account': 'Konto',
            'password': 'Hasło',
            'salt': 'Sól',
            'last_login_time': 'Ostatni czas logowania',
            'register_time': 'Zarejestruj czas',
            'login_failure_counter': 'Licznik niepowodzenia logowania',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Numer',
            'remark': 'Uwaga',
            'status': 'Status',
            'name': 'Nazwa',
            'commission_rate': 'Stawka prowizji',
            'yedpay_api_key': 'Klucz API Yedpay',
            'yedpay_sign_key': 'Klucz znaku Yedpay',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_emp': 'ID',
            'id_rol': 'Rola',
            'account': 'Konto',
            'password': 'Hasło',
            'salt': 'Sól',
            'last_login_time': 'Ostatni czas logowania',
            'register_time': 'Zarejestruj czas',
            'login_failure_counter': 'Licznik niepowodzenia logowania',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Numer',
            'remark': 'Uwaga',
            'status': 'Status',
            'name': 'Nazwa',
            'commission_rate': 'Stawka prowizji',
            'yedpay_api_key': 'Klucz API Yedpay',
            'yedpay_sign_key': 'Klucz znaku Yedpay',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_emp': 'Wymagany id',
                'id_rol': 'Wymagana rola',
                'account': 'Wymagane konto',
                'password': 'Wymagane hasło',
                'salt': 'Wymagana sól',
                'last_login_time': 'Wymagany ostatni czas logowania',
                'register_time': 'Wymagany czas zarejestrowania',
                'login_failure_counter': 'Wymagany licznik awarii logowania',
                'email': 'Email (wymagany',
                'whatsAppPhone': 'Wpisz numer WhatsApp',
                'remark': 'Wymagana uwaga',
                'status': 'Wymagany status',
                'name': 'Imię (wymagane',
                'commission_rate': 'Wymagana stawka prowizji',
                'yedpay_api_key': 'Wymagany klucz API Yedpay',
                'yedpay_sign_key': 'Wymagany klucz do znaku Yedpay',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Dziennik zdarzeń',
            'detailTitle': 'Szczegóły dziennika zdarzeń',
            'editTitle': 'Edytuj dziennik zdarzeń'
        },
        'table': {
            'id_env_log': 'ID',
            'run_time': 'Czas realizacji(ms)',
            'class_method': 'Metoda wykonania',
            'event_time': 'Czas wydarzenia',
            'log': 'Dziennik',
            'tenant_key': 'Klucz najemcy',
            'resCode': 'Kod odpowiedzi',
        },
        'field': {
            'id_env_log': 'ID',
            'event_time': 'Czas wydarzenia',
            'log': 'Dziennik',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_env_log': 'Wymagany id',
                'class_method': 'Proszę wypełnić metodę wykonania',
                'event_time': 'Wymagany czas zdarzenia',
                'log': 'Wymagany dziennik',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'meter': {
        'name': {'title': 'Metr', 'detailTitle': 'Szczegóły licznika', 'editTitle': 'Edytuj licznik'},
        'table': {
            'id_met': 'ID',
            'id_reg': 'Region',
            'name': 'Nazwa licznika',
            'current_threshold': 'Obecny próg',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_met': 'ID',
            'id_reg': 'Region',
            'name': 'Nazwa licznika',
            'current_threshold': 'Obecny próg',
            'tenant_key': 'Klucz najemcy',
            'loadByTactics': 'Włączanie zasad'
        },
        'validate': {
            'required': {
                'id_met': 'Wymagany id',
                'id_reg': 'Wymagany region',
                'name': 'Wymagana nazwa licznika',
                'current_threshold': 'Wymagany próg obecny',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Zamówienie',
            'detailTitle': 'Szczegóły zamówienia',
            'editTitle': 'Edytować zamówienie',
            'cancel': 'Anuluj zamówienie',
            'cancelAndRefund': 'Anuluj zamówienie i zwrot kosztów',
        },
        'table': {
            'id_ord': 'ID',
            'id_car': 'Samochód',
            'id_con': 'Złącze',
            'order_time': 'Czas realizacji',
            'type': 'Typ',
            'amount': 'Kwota',
            'charge_start_time': 'Czas rozpoczęcia ładowania',
            'charge_end_time': 'Przewidywany czas zakończenia',
            'chargeEndTimeActual': 'Rzeczywisty czas zakończenia',
            'status': 'Status',
            'payment_time': 'Czas płatności',
            'electricity': 'Zakup energii elektrycznej',
            'charge_minutes': 'Czas zakupu',
            'currentElectricity': "Prąd elektryczny",
            'ref_id_pla': 'Plan',
            'price': 'Cena',
            'commission_rate': 'Stawka prowizji',
            'tenant_key': 'Klucz najemcy',
            'id_tra_log': 'TransactionLogid',
            'pay_type': 'Paytype',
            'payment_method': 'Metoda płatności'
        },
        'validate': {
            'id_ord': 'Wymagany id',
            'id_car': 'Wymagany samochód',
            'id_con': 'Wymagane złącze',
            'order_time': 'Wymagany czas zamówienia',
            'type': 'Wymagany typ',
            'amount': 'Wymagana kwota',
            'charge_start_time': 'Wymagany czas rozpoczęcia opłaty',
            'charge_end_time': 'Wymagany czas zakończenia ładowania',
            'status': 'Wymagany status',
            'payment_time': 'Wymagany czas płatności',
            'electricity': 'Wymagana energia elektryczna',
            'ref_id_pla': 'Wymagany plan',
            'price': 'Wymagana cena',
            'commission_rate': 'Wymagana stawka prowizji',
            'tenant_key': 'Wymagany klucz najemcy',
            'id_tra_log': 'Wymagany TransactionLogid',
            'charge_minutes': 'Wymagane Chargeminuty',
            'pay_type': 'Wymagane jest paytype',
            'payment_method': 'Wymagana metoda płatności'
        }
    },
    'plan': {
        'name': {'title': 'Plan', 'detailTitle': 'Planuj szczegół', 'editTitle': 'Edytuj plan'},
        'table': {
            'id_pla': 'ID',
            'name': 'Nazwa planu',
            'price_per_month': 'Cena',
            'contract_period': 'Okres umowy',
            'free_charging_time_limit': 'Bezpłatny czas ładowania',
            'overtime_charge_price': 'Cena nadgodzin',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_pla': 'ID',
            'name': 'Nazwa planu',
            'price_per_month': 'Cena',
            'contract_period': 'Okres umowy',
            'free_charging_time_limit': 'Bezpłatny czas ładowania',
            'overtime_charge_price': 'Cena nadgodzin',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_pla': 'Wymagany id',
                'name': 'Wymagana nazwa planu',
                'price_per_month': 'Wymagana cena',
                'contract_period': 'Wymagany okres umowy',
                'free_charging_time_limit': 'Wymagany bezpłatny czas ładowania',
                'overtime_charge_price': 'Wymagana cena nadgodzin',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Rozmiar każdego skoku jest obliczany jako jeden skok, jeśli jest mniejszy niż jeden skok. Jeśli jest obliczany na podstawie rzeczywistego zużycia energii elektrycznej w zamówieniu, ustaw go na zero.",
            'byTimeUnit': "Rozmiar kroku każdego skoku jest obliczany jako jeden skok, jeśli jest mniejszy niż jeden skok. Jeśli jest obliczany na podstawie rzeczywistego czasu zlecenia, ustaw go na zero.",

            'maximumPower': "Podczas ładowania zgodnie z poziomem baterii górna granica pojedynczego ładowania nie jest ograniczona, jeśli pozostawiono pustą lub zerową i domyślnie nie jest ograniczona",
            'maximumMinute': "Podczas ładowania zgodnie z czasem górny limit pojedynczego czasu ładowania jest pusty lub zerowy, co domyślnie nie jest ograniczone",
        },
        'billingMethodType':{
            '0': "Z czasem",
            '1': "Przez moc",
            '2': "Przez aplikację",
        },
        'name': {'title': 'Region', 'detailTitle': 'Szczegóły regionu', 'editTitle': 'Edytuj region'},
        'table': {
            'id_reg': 'ID',
            'name': 'Nazwa regionu',
            'ac_price_per_electricity': 'Cena AC za energię elektryczną',
            'ac_price_per_minute': 'Cena AC za minutę',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Adres',
            'dc_price_per_electricity': 'Cena DC za energię elektryczną',
            'dc_price_per_minute': 'Cena DC za minutę',
            'tenant_key': 'Klucz najemcy',
            'businessAllDay': 'Otwarte przez cały dzień',
            'images': 'Obrazy',
            'byPowerUnit': "Pomiar w jednostkach energii elektrycznej",
            'byTimeUnit': "Jednostka pomiaru według czasu",
            'postpaid': "Płatność pocztowa",
            'billingMethod': "Metoda rozliczania",
            'businessHours': "Godziny pracy",

            'maximumPower': "Górna granica pojedynczej zdolności ładowania",
            'maximumMinute': "Górna granica pojedynczego czasu ładowania",
            'directDebit': "Automatyczne odliczenie",

            'enableTimeoutFine': "Kara otwarcia czasu",
            'timeoutFine': "Czas przerwy(min)",
            'timeoutCharging': "Rozliczenia za nadgodziny",
        },
        'validate': {
            'id_reg': 'Wymagany id',
            'name': 'Wymagana nazwa regionu',
            'ac_price_per_electricity': 'Wymagana cena prądu przemiennego za energię elektryczną',
            'ac_price_per_minute': 'Wymagana cena prądu przemiennego za minutę',
            'lon': 'Wymagany lon',
            'lat': 'Wymagany Lat',
            'address': 'Wymagany adres',
            'dc_price_per_electricity': 'Wymagana cena DC za energię elektryczną',
            'dc_price_per_minute': 'Wymagana cena DC za minutę',
            'tenant_key': 'Wymagany klucz najemcy',
            'businessAllDay': 'Wybierz, czy jest otwarty przez cały dzień',
            'byPowerUnit': "Proszę wypełnić jednostkę pomiaru na podstawie ilości energii elektrycznej",
            'byTimeUnit': "Proszę wypełnić jednostkę pomiaru według czasu",
            'postpaid': "Proszę wybrać, czy zapłacić po",
            'billingMethod': "Proszę wybrać metodę rozliczenia",
            'businessHours': "Wybierz godziny pracy",

            'maximumPower': "Proszę wypełnić górną granicę pojedynczej zdolności ładowania",
            'maximumMinute': "Proszę wypełnić górną granicę czasu ładowania pojedynczego",
            'directDebit': "Proszę wybrać, czy automatycznie odliczyć płatność",

            'enableTimeoutFine': "Proszę wybrać, czy włączyć karę timeout",
            'timeoutFine': "Proszę wypełnić okres przerwy(min)",
            'timeoutCharging': "Proszę wypełnić rachunek za nadgodziny",
        }
    },
    'regionImages': {
        'name': {
            'title': 'Obraz regionu',
            'detailTitle': 'Szczegóły obrazu regionu',
            'editTitle': 'Edytuj obraz regionu'
        },
        'table': {
            'id_reg_img': 'ID',
            'id_reg': 'Region',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_reg_img': 'ID',
            'id_reg': 'Region',
            'url': 'URL',
            'upload_time': 'Czas przesłania',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_reg_img': 'Wymagany id',
                'id_reg': 'Wymagany region',
                'url': 'Wymagany adres URL',
                'upload_time': 'Wymagany czas przesłania',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Dziennik napraw',
            'detailTitle': 'Szczegóły dziennika napraw',
            'editTitle': 'Edytuj dziennik naprawy'
        },
        'table': {
            'id_rep_log': 'ID',
            'id_car': 'Samochód',
            'amount': 'Kwota',
            'repair_date': 'Data naprawy',
            'remark': 'Uwaga',
            'tenant_key': 'Klucz najemcy'
        },
        'field': {
            'id_rep_log': 'ID',
            'id_car': 'Samochód',
            'amount': 'Kwota',
            'repair_date': 'Data naprawy',
            'remark': 'Uwaga',
            'tenant_key': 'Klucz najemcy'
        },
        'validate': {
            'required': {
                'id_rep_log': 'Wymagany id',
                'id_car': 'Wymagany samochód',
                'amount': 'Wymagana kwota',
                'repair_date': 'Wymagana data naprawy',
                'remark': 'Wymagana uwaga',
                'tenant_key': 'Wymagany klucz najemcy'
            }
        }
    },
    'role': {
        'name': {'title': 'Rola', 'detailTitle': 'Szczegóły roli', 'editTitle': 'Edytuj rolę'},
        'table': {'id_rol': 'ID', 'name': 'Rola', 'remark': 'Uwaga'},
        'field': {'id_rol': 'ID', 'name': 'Rola', 'remark': 'Uwaga', 'authorityList': 'Lista autorytetów'},
        'validate': {'required': {'id_rol': 'Wymagany id', 'name': 'Wymagana rola', 'remark': 'Wymagana uwaga'}}
    },
    'roleAuthority': {
        'name': {
            'title': 'Roleauthority',
            'detailTitle': 'Szczegóły Roleauthority',
            'editTitle': 'Edytuj Roleauthority'
        },
        'table': {'id_rol_aut': 'ID', 'id_rol': 'Rola', 'id_aut': 'Autorytet'},
        'field': {'id_rol_aut': 'ID', 'id_rol': 'Rola', 'id_aut': 'Autorytet'},
        'validate': {
            'required': {
                'id_rol_aut': 'Wymagany id',
                'id_rol': 'Wymagana rola',
                'id_aut': 'Wymagany autorytet'
            }
        }
    },
    'transactionLog': {
        'name': {
            'title': 'Dziennik transakcji',
            'detailTitle': 'Szczegóły dziennika transakcji',
            'editTitle': 'Edytuj dziennik transakcji'
        },
        'table': {
            'id_tra_log': 'ID',
            'id_con': 'Złącze IDT',
            'event_time': 'Czas wydarzenia',
            'id_tag': 'Tag',
            'start_time': 'Czas rozpoczęcia',
            'start_value': 'Rozpocznij wartość',
            'stop_time': 'Zatrzymaj czas',
            'stop_value': 'Zatrzymaj wartość',
            'stop_reason': 'Zatrzymaj rozum',
            'fail_reason': 'Nieuda powodów',
            'tenant_key': 'Klucz najemcy',
            'current_electricity': 'Prąd elektryczny'
        },
        'field': {
            'id_tra_log': 'ID',
            'id_con': 'Złącze IDT',
            'event_time': 'Czas wydarzenia',
            'id_tag': 'Tag',
            'start_time': 'Czas rozpoczęcia',
            'start_value': 'Rozpocznij wartość',
            'stop_time': 'Zatrzymaj czas',
            'stop_value': 'Zatrzymaj wartość',
            'stop_reason': 'Zatrzymaj rozum',
            'fail_reason': 'Nieuda powodów',
            'tenant_key': 'Klucz najemcy',
            'current_electricity': 'Prąd elektryczny'
        },
        'validate': {
            'required': {
                'id_tra_log': 'Wymagany id',
                'id_con': 'Wymagane IDT złącza',
                'event_time': 'Wymagany czas zdarzenia',
                'id_tag': 'Wymagany tag id',
                'start_time': 'Wymagany czas rozpoczęcia',
                'start_value': 'Wymagana wartość uruchom',
                'stop_time': 'Wymagany czas zatrzymania',
                'stop_value': 'Wymagana wartość zatrzymania',
                'stop_reason': 'Zatrzymaj wymagany rozum',
                'fail_reason': 'Wymagany powód nieudany',
                'tenant_key': 'Wymagany klucz najemcy',
                'current_electricity': 'Wymagana obecna energia elektryczna'
            }
        }
    },
    'user': {
        'name': {'title': 'Użytkownik', 'detailTitle': 'Szczegóły użytkownika', 'editTitle': 'Edytuj użytkownika'},
        'table': {
            'id_use': 'ID',
            'balance': 'Balansować',
            'account': 'Konto',
            'password': 'Hasło',
            'register_time': 'Zarejestruj czas',
            'email': 'E-mail',
            'token': 'Znak',
            'salt': 'Sól',
            'tenant_key': 'Klucz najemcy',
            'phone': 'Telefon',
            'register_type': 'Typ rejestru'
        },
        'field': {
            'id_use': 'ID',
            'balance': 'Balansować',
            'account': 'Konto',
            'password': 'Hasło',
            'register_time': 'Zarejestruj czas',
            'email': 'E-mail',
            'token': 'Znak',
            'salt': 'Sól',
            'tenant_key': 'Klucz najemcy',
            'phone': 'Telefon',
            'register_type': 'Typ rejestru',

            'chargingCard': 'Numer karty ładowania',
            'chargingType': 'Tryb ładowania priorytetowego',
            'chargingDefDuration': 'Czas ładowania karty(min)',
            'chargingDefPower': 'Pojemność ładowania kart(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'Wymagany id',
                'balance': 'Wymagane saldo',
                'account': 'Wymagane konto',
                'password': 'Wymagane hasło',
                'register_time': 'Wymagany czas zarejestrowania',
                'email': 'Email (wymagany',
                'token': 'Wymagany token',
                'salt': 'Wymagana sól',
                'tenant_key': 'Wymagany klucz najemcy',
                'phone': 'Wymagany telefon',
                'register_type': 'Wymagany typ rejestru',

                'chargingCard': 'Proszę wprowadzić numer karty ładowania',
                'chargingType': 'Proszę wybrać tryb ładowania priorytetowego karty',
                'chargingDefDuration': 'Proszę wprowadzić czas ładowania karty(min)',
                'chargingDefPower': 'Proszę wprowadzić kwotę ładowania karty(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Plan użytkownika',
            'detailTitle': 'Szczegóły planu użytkownika',
            'editTitle': 'Edytuj plan użytkownika'
        },
        'table': {
            'id_use': 'Użytkownik',
            'id_pla': 'Plan',
            'arrearsCount': 'Liczba poleceń',
            'start_date': 'Data rozpoczęcia',
            'tenant_key': 'Klucz najemcy',
            'createType': 'Utwórz tożsamość',
            'updateByMonth': 'Termin zaliczki',
            'total': 'Miesiące z góry',
            'amount': 'Przewidywana kwota płatności',
            'prePayData': 'Oczekiwany miesiąc płatności',
        },
        'validate': {
            'id_use': 'Wymagane',
            'id_pla': 'Wymagany plan',
            'arrearsCount': 'Proszę podać liczbę zaległych miesięcy',
            'start_date': 'Wymagana data rozpoczęcia',
            'tenant_key': 'Wymagany klucz najemcy'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Łączne ładowarki',
            'availableChargers': 'Dostępne porty',
            'inUseChargers': 'W użyciu portów',
            'unavailableChargers': 'Niedostępne porty',
            'portUsage': 'Wykorzystanie portu',

            'energyUsage': 'Zużycie energii (KWH)',
            'totalRevenue': 'Całkowity dochód',
            'totalEnergy': 'Całkowita energia',
            'newUser': 'Nowy użytkownik'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Wersja Androida',
            'androidDownloadLink': 'Link do pobrania Android',
            'androidComplieNumber': 'Numer kompilacji Android',
            'iosVersion': 'Wersja iOS',
            'iosBundleId': 'IOS ID',
            'iosComplieNumber': 'IOS Compile Number',
            'isForcedUpdate': 'Jest obowiązkowa aktualizacja'
        },
        'validate': {
            'required': {
                'androidVersion': 'Wymagana wersja Androida',
                'androidDownloadLink': 'Wymagany link do pobrania Android',
                'androidComplieNumber': 'Android Compile Number Required',
                'iosVersion': 'Wymagana wersja iOS',
                'iosBundleId': 'Wymagany identyfikator pakietu iOS',
                'iosComplieNumber': 'Wymagany numer kompilacji iOS',
                'isForcedUpdate': 'Proszę wybrać, czy wymuszać aktualizacje'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Ustawienie klucza', 'dataStr': 'Ustalanie wartości'}},
    'superTenantManage': {
        'name': {'title': 'Uprawnienia', 'detailTitle': 'Detale', 'editTitle': 'Edytować'},
        'searchForm': {
            'dataSourceKey': 'Klucz źródła danych',
            'url': 'URL',
            'username': 'Nazwa użytkownika',
            'tenant': 'Najemca',
            'isEnable': 'Jest włączony',
            'isCreate': 'Iscreate'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Wprowadź klucz źródła danych',
            'url': 'Wprowadź adres linku',
            'username': 'Wprowadź konto',
            'tenant': 'Please enter tenant name',
            'isEnable': 'Wybierz, czy włączyć',
            'isCreate': 'Wybierz logo'
        },
        'table': {
            'index': 'Indeks',
            'dataSourceKey': 'Klucz źródła danych',
            'url': 'URL',
            'host': 'Gospodarz',
            'port': 'Port',
            'dbName': 'Nazwa bazy danych',
            'username': 'Username',
            'password': 'Hasło',
            'tenant': 'najemca',
            'isEnable': 'Jest włączony',
            'isCreate': 'IsCreate',
            'domain': 'Domena',
            'action': {'testConn': 'Conn', 'create': 'Tworzyć', 'edit': 'Edytować', 'del': 'Usuwać'}
        },
        'field': {
            'id': 'ID',
            'datasourceKey': 'Klucz źródła danych',
            'seckey': 'Klucze dostępu',
            'url': 'URL',
            'host': 'Gospodarz',
            'port': 'Port',
            'dbName': 'Nazwa bazy danych',
            'username': 'Nazwa użytkownika',
            'password': 'Hasło',
            'tenant': 'Najemca',
            'isEnable': 'Jest włączony',
            'isCreate': 'Iscreate',
            'remark': 'Uwaga',
            'domain': 'Domena',
            'onesignalKey': 'Klucz powiadomienia APP',

            'chargingCard': 'Numer karty płatniczej',
            'chargingType': 'Tryb ładowania karty',
            'chargingDefDuration': 'Czas ładowania karty/pojemność ładowania (Min/kWh)'
        },
        'validate': {
            'required': {
                'id': 'Proszę wypełnić identyfikator',
                'dataSourceKey': 'Wypełnij klucz źródła danych',
                'seckey': 'Please fill in the SecKey',
                'url': 'Proszę wypełnić adres URL linku',
                'host': 'Please fill in the host',
                'port': 'Proszę wypełnić port',
                'dbName': 'Wypełnij nazwę bazy danych',
                'username': 'Wprowadź nazwę użytkownika',
                'password': 'Proszę wypełnić hasło',
                'tenant': 'Wypełnij nazwę najemcy',
                'isEnable': 'Wybierz stan aktywny',
                'remark': 'Proszę wypełnić uwagi',
                'domain': 'Proszę wypełnić domenę',
                'onesignalKey': 'Wypełnij klucz powiadomienia APP',

                'chargingCard': 'Wprowadź numer karty płatniczej',
                'chargingType': 'Proszę wybrać tryb ładowania karty',
                'chargingDefDuration': 'Proszę wprowadzić czas/kwotę ładowania karty (Min/kWh)'
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'ID',
            'index': 'Indeks',
            'machineCode': 'Kod maszynowy',
            'name': 'Nazwa',
            'regId': 'ID regionu',
            'regName': 'Nazwa regionu',
            'seckey': 'Seckey',
            'args': 'Args',
            'createBy': 'Utwórz przez',
            'createTime': 'Utwórz czas',
            'updateBy': 'Aktualizacja według',
            'updateTime': 'Czas aktualizacji',
            'remark': 'Uwaga'
        },
        'validate': {
            'id': 'Please fill in the ID',
            'machineCode': 'Wypełnij kod maszyny',
            'name': 'Wypełnij nazwę',
            'regId': 'Proszę wypełnić ID regionu',
            'seckey': 'Proszę wypełnić seckey',
            'args': 'Proszę wypełnić args',
            'remark': 'Proszę wypełnić uwagę'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mail', '1': 'Telefon'},
        'noticeType': {
            '0': 'Ostrzeżenie',
            '1': 'Przypomnieć',
            '2': 'Ogłoszenie',
            '3': 'Nadgodziny',
        },
        'field': {
            'id': 'ID',
            'index': 'Indeks',
            'toFrom': 'Konto',
            'type': 'typ konta',
            'noticeType': 'Typ powiadomienia',
            'status': 'Jest włączony',
            'createBy': 'Utwórz przez',
            'createTime': 'Utwórz czas',
            'updateBy': 'Aktualizacja według',
            'updateTime': 'Czas aktualizacji',
            'remark': 'Uwaga'
        },
        'validate': {
            'id': 'Proszę wypełnić identyfikator',
            'type': 'Please choose account type',
            'toFrom': 'Wypełnij konto',
            'noticeType': 'Wybierz typ powiadomienia',
            'status': 'Wybierz, czy włączyć',
            'remark': 'Please fill in the remark'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'Warunki usługi',
            '1': 'O nas',
            '2': 'Ogłoszenie',
            '3': 'Ogłoszenie',
            '4': 'Przypomnieć'
        },
        'field': {
            'id': 'ID',
            'index': 'Indeks',
            'type': 'Advertisement Type',
            'status': 'Jest włączony',
            'title': 'Title',
            'content': 'Treść',
            'tenantKey': 'Tożsamość dzierżawy',
            'createBy': 'Utwórz przez',
            'createTime': 'Utwórz czas',
            'updateBy': 'Aktualizacja według',
            'updateTime': 'Czas aktualizacji',
            'remark': 'Uwaga'
        },
        'validate': {
            'id': 'Proszę wypełnić identyfikator',
            'type': 'Wybierz rodzaj ogłoszenia',
            'status': 'Wybierz, czy to włączyć',
            'title': 'Please fill in the title of the announcement',
            'content': 'Proszę wypełnić treść zawiadomienia',
            'remark': 'Proszę wypełnić komentarze'
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'Numer seryjny',
            'identifier': 'Identyfikator stosu',
            'useNum': 'Użyj numeru',
            'chargeCapacity': 'Całkowity ładunek (kW)',
            'startTime': 'Czas rozpoczęcia',
            'endTime': 'Koniec czasu'
        },
        'validate': {
            'identifier': 'Wprowadź numer stosu',
            'startTime': 'Wybierz czas rozpoczęcia',
            'endTime': 'Wybierz czas zakończenia'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'Napój',
            '1': 'Żywność',
            '2': 'Toaleta',
            '3': 'Zakupy',
        },
        'table': {
            'id': 'ID',
            'type': 'type',
            'status': 'status',
            'name': 'name',
            'lng': 'longitude',
            'lat': 'latitude',
            'address': 'address',
            'description': 'description',
            'reId': 'associated area ID'
        },
        'validate': {
            'type': 'Proszę wybrać typ',
            'status': 'Proszę wybrać status',
            'name': 'Please fill name',
            'lng': 'Please fill longitude',
            'lat': 'Please fill latitude',
            'address': 'Please fill address',
            'description': 'Please fill description',
            'reId': 'Associated area'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Klucz podstawowy',
            'tId': 'ID najemcy współpracującego',
            'name': 'Nazwa firmy',
            'attn': 'Łącznik z firmą',
            'tel': 'Telefon firmowy',
            'address': 'Adres firmy',
            'email': 'Email firmy',
            'invoiceDate': 'Data faktury',
            'invoiceNo': 'Numer faktury',
            'contactPerson': 'Kontakt',
            'contactTel': 'Numer kontaktowy',
            'contactEmail': 'Kontakt e-mail',
            'monthlyFee': 'Ustawiona wartość miesięcznej opłaty za dostęp do systemu',
            'company': 'Informacje firmie'
        },
        'validate': {
            'id': 'Proszę wprowadzić klucz podstawowy',
            'tId': 'Wprowadź powiązany identyfikator najemcy',
            'name': 'Proszę wprowadzić nazwę firmy',
            'attn': 'Proszę podać osobę kontaktową firmy',
            'tel': 'Proszę wpisać telefon firmowy',
            'address': 'Proszę podać adres firmy',
            'email': 'Proszę wpisać adres e-mail firmy',
            'invoiceDate': 'Proszę wprowadzić datę faktury',
            'invoiceNo': 'Proszę wprowadzić numer faktury',
            'contactPerson': 'Proszę podać osobę kontaktową',
            'contactTel': 'Proszę wprowadzić swój numer kontaktowy',
            'contactEmail': 'Proszę wpisać swój adres e-mail kontaktowy',
            'monthlyFee': 'Proszę wprowadzić domyślną wartość miesięcznej opłaty za dostęp do systemu'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'Identyfikator najemcy macierzystego',
            'tenantKey': 'Identyfikacja najemcy',
            'name': 'Nazwa najemcy',
            'domain': 'Związana nazwa domeny',
            'tpId': 'Pakiet powiązany',
            'authId': 'Powiązane uprawnienia',
            'level': 'poziom',
            'contact': 'Osoba kontaktowa',
            'email': 'Kontakt e-mail',
            'phone': 'Numer kontaktowy',
            'startTime': 'Czas aktywacji',
            'expireTime': 'Czas ważności',
        },
        'args':{
            'yedPayExtendParams': 'Parametr YedPay',
            'yedPayApiKey': 'YedPay ApiKey',
            'yedPaySingKey': 'YedPay SingKey',

            'mPayExtendParams': 'Parametry MPay',
            'mPayPubKey': 'MPay PubKey',
            'mPayPriKey': 'MPay PriKey',
        },
        'validate': {
            'id': 'Proszę wprowadzić identyfikator',
            'pid': 'Proszę wprowadzić nadrzędny identyfikator najemcy',
            'tenantKey': 'Proszę wypełnić identyfikator najemcy',
            'name': 'Proszę wypełnić nazwę najemcy',
            'domain': 'Proszę wypełnić związaną nazwę domeny',
            'tpId': 'Proszę wybrać powiązany pakiet',
            'authId': 'Proszę wybrać powiązane uprawnienia',
            'contact': 'Proszę wypełnić osobę kontaktową',
            'email': 'Proszę wypełnić adres e-mail kontaktowy',
            'phone': 'Proszę wypełnić numer telefonu kontaktowego',
            'startTime': 'Proszę wybrać godzinę rozpoczęcia',
            'expireTime': 'Proszę wybrać czas ważności',

            'yedPayExtendParams': 'Proszę wprowadzić parametr YedPay',
            'yedPayApiKey': 'Proszę wprowadzić YedPay ApiKey',
            'yedPaySingKey': 'Proszę wprowadzić YedPay SingKey',
            'mPayExtendParams': 'Proszę wprowadzić parametry MPay',
            'mPayPubKey': 'Proszę wprowadzić klucz publiczny MPay',
            'mPayPriKey': 'Proszę wprowadzić klucz prywatny MPay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Numer opakowania',
            'name': 'Nazwa pakietu',
            'createTenantMax': 'Liczba najemców, których można utworzyć',
            'createUserMax': 'Liczba użytkowników, które można utworzyć',
            'createAppMax': 'Liczba aplikacji podlegających rejestracji',
            'expireLong': 'Skuteczny czas trwania pakietu',
            'tenantKey': 'Identyfikacja najemcy',
        },
        'validate': {
            'id': 'Proszę wprowadzić identyfikator',
            'packageNo': 'Proszę wprowadzić numer paczki',
            'name': 'Proszę wprowadzić nazwę pakietu',
            'createTenantMax': 'Proszę wprowadzić liczbę najemców, które można utworzyć',
            'createUserMax': 'Proszę wprowadzić liczbę użytkowników, które można utworzyć',
            'createAppMax': 'Proszę wprowadzić liczbę rejestracyjnych aplikacji',
            'expireLong': 'Proszę wybrać skuteczny czas trwania pakietu',
            'tenantKey': 'Proszę wprowadzić identyfikator najemcy',
        }
    },
    'card': {
        'cardType': {
            '0': "Biała lista",
            '1': "Czarna lista",
            '2': "Karta systemowa",
            '3': "Karta użytkownika",
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'Numer karty',
            'cardType': 'Typ',
            'cardStatus': 'Czy jest włączona',
        },
        'validate': {
            'cardId': 'Proszę wypełnić dowód tożsamości',
            'cardNo': 'Proszę wypełnić numer karty',
            'cardType': 'Proszę wybrać typ',
            'cardStatus': 'Proszę wybrać status',
        }
    },
    'waller': {
        'wType': {
            '1': 'Saldo',
            '2': 'Kwota prezentu',
            '3': 'Całkowita'
        },
        'table': {
            'walletId': 'ID',
            'account': 'Rachunek',
            'type': 'Typ',
            'status': 'Zatrzymać lub nie',
            'balance': 'Saldo',
        },
        'validate': {
            'account': 'Proszę wypełnić swoje konto',
            'type': 'Proszę wybrać typ',
            'status': 'Proszę wybrać status',
        }
    }
}
export default plPL