<template>
  <Modal
      id="OrderDetail"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="visible = !visible"
      :title="$t('order.name.detailTitle')" :zIndex="9999999900"
      :destroyOnClose="true"
  >
    <Descriptions>
      <DescriptionsItem :label="$t('order.table.id_ord')">{{ data.idOrd }}</DescriptionsItem>
      <DescriptionsItem label="Pay ID">{{ handelNull(data.yedPayId) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.name')">{{ data.regName }}</DescriptionsItem>

      <DescriptionsItem :label="$t('meter.table.name')">{{ data.metName }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.id_car')">{{ data.carModel }}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.table.car_license')">{{ data.carLicense }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.id_con')">{{ data.connectorName }}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.account')">{{ data.account }}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{ data.phone }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.order_time')">{{ data.orderTime }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.status')">
        <Tag v-if="data.status === 1" color="pink">{{ $t("public.payStatus.0") }}</Tag>
        <Tag v-else-if="data.status === 2" color="blue">{{ $t("public.payStatus.1") }}</Tag>
        <Tag v-else-if="data.status === 3" color="red">{{ $t("public.payStatus.2") }}</Tag>
        <Tag v-else-if="data.status === 4" color="green">{{ $t("public.payStatus.3") }}</Tag>
        <Tag v-else-if="data.status === 5" color="orange">{{ $t("public.payStatus.4") }}</Tag>
        <Tag v-else>{{ data.status }}</Tag>
      </DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.postpaid')">
        <Tag v-if="data.postpaid === 1" color="cyan">{{ $t("public.isTrue.1") }}</Tag>
        <Tag v-else-if="data.postpaid === 2" color="orange">{{ $t("public.payStatus.3") }}</Tag>
        <Tag v-else color="green">{{ $t("public.isTrue.0") }}</Tag>
      </DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.amount')">{{ handelNull(data.amount) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.payment_time')">{{ handelNull(data.paymentTime) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.pay_type')">{{ data.payType }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.payment_method')">{{ handelNull(data.paymentMethod) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.price')">{{ data.price }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.id_tra_log')">{{ handelNull(data.idTraLog) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('public.name.args')">{{ handelNull(data.byUnit) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.type')">
        <Tag v-if="data.type === 1" color="cyan">{{ $t("public.chargeType.0") }}</Tag>
        <Tag v-else-if="data.type === 2" color="green">{{ $t("public.chargeType.1") }}</Tag>
        <Tag v-else-if="data.type === 3" color="orange">{{ $t("public.chargeType.2") }}</Tag>
        <Tag v-else>{{ data.type }}</Tag>
      </DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_minutes') + '(min)'">{{ data.chargeMinutes }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.electricity') + '(kwh)'">{{ handelNull(data.electricity) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_start_time')">{{ handelNull(data.chargeStartTime) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.charge_end_time')">{{ handelNull(data.chargeEndTime) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('order.table.chargeEndTimeActual')">{{ handelNull(data.chargeEndTimeActual) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.currentElectricity') + '(kwh)'">{{ handelNull(data.currentElectricity) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('order.table.ref_id_pla') + '(ID)'">{{ handelNull(data.refIdPla) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('plan.table.free_charging_time_limit') + '(min)'">{{ handelNull(data.freeMeter) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.maximumMinute') + '(min)'">{{ handelNull(data.maximumMinute) }}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.maximumPower') + '(kwh)'">{{ handelNull(maximumPower) }}</DescriptionsItem>

      <DescriptionsItem :label="$t('region.table.directDebit')">{{ handelNull(directDebit) }}</DescriptionsItem>
      <DescriptionsItem label="SoC">{{ handelNull(data.soc) === '-' ? handelNull(data.soc) : handelNull(data.soc) + '%' }}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.remark')">{{ handelNull(data.remark) }}</DescriptionsItem>

    </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "OrderDetail",
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem, Tag} from "ant-design-vue";
import {ref, reactive} from 'vue'
import {useI18n} from 'vue-i18n'

const i18n = useI18n()

let directDebit = ref("")
let maximumPower = ref(0)

const data = ref({})
const visible = ref(false)
function detailShow (obj) {
  const oj = {...obj}
  oj.electricity = oj.electricity === undefined ? 0 : oj.electricity / 1000
  oj.currentElectricity = oj.currentElectricity === undefined ? 0 : oj.currentElectricity / 1000

  oj.chargeMinutes = oj.type === 1 || oj.type === 3 ? handelNull(oj.chargeMinutes) : '-';

  if (oj.payType === undefined || oj.payType === null) {
    oj.payType = '-'
  } else if (oj.payType === 2) {
    oj.payType = 'Balance'
  } else if (oj.payType === 16) {
    oj.payType = 'Swipe'
  } else {
    oj.payType = 'Online Pay'
  }

  directDebit.value = oj.directDebit === 1 ? i18n.t("public.isTrue.1") : i18n.t("public.isTrue.0")
  if (oj.maximumPower) {
    maximumPower.value = oj.maximumPower / 1000
  }

  data.value = oj;
  visible.value = true;
}

function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow,
});
</script>

<style lang="less">
</style>