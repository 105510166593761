<template>
  <Modal
    id="TransactionLogEdit"
    width="60%"
    :open="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
              ref="transactionLogForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.id_con')" name="idCon">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.idCon"
                                :placeholder="$t('transactionLog.validate.required.id_con')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['connectorList']" :value="item['idCon']">{{ item['name'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.event_time')" name="eventTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.eventTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.id_tag')" name="idTag">
                                            <Input :placeholder="$t('transactionLog.validate.required.id_tag')"
                                                   v-model:value="form.idTag">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.start_time')" name="startTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.startTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.start_value')" name="startValue">
                                            <Input :placeholder="$t('transactionLog.validate.required.start_value')"
                                                   v-model:value="form.startValue">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.stop_time')" name="stopTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.stopTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.stop_value')" name="stopValue">
                                            <Input :placeholder="$t('transactionLog.validate.required.stop_value')"
                                                   v-model:value="form.stopValue">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.stop_reason')" name="stopReason">
                                            <Input :placeholder="$t('transactionLog.validate.required.stop_reason')"
                                                   v-model:value="form.stopReason">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.fail_reason')" name="failReason">
                                            <Input :placeholder="$t('transactionLog.validate.required.fail_reason')"
                                                   v-model:value="form.failReason">
                                            </Input>
                  </FormItem>
                  </Col>
                  <!-- <Col span="10">
                    <FormItem :label="$t('transactionLog.field.tenant_key')" name="tenantKey">
                                            <Input :placeholder="$t('transactionLog.validate.required.tenant_key')"
                                                   v-model:value="form.tenantKey">
                                            </Input>
                  </FormItem>
                  </Col> -->
                  <Col span="10">
                    <FormItem :label="$t('transactionLog.field.current_electricity')" name="currentElectricity">
                                            <Input :placeholder="$t('transactionLog.validate.required.current_electricity')"
                                                   v-model:value="form.currentElectricity">
                                            </Input>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { TransactionLogAdd, TransactionLogEdit } from "@/api/transactionLog";
import { ConnectorListAll } from "@/api/connector"

export default {
  name: "TransactionLogEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idTraLog : "",
        idCon : "",
        eventTime : "",
        idTag : "",
        startTime : "",
        startValue : "",
        stopTime : "",
        stopValue : "",
        stopReason : "",
        failReason : "",
        tenantKey : "",
        currentElectricity : "",
      },
      fileList: {
      },
      selectList: {
        connectorList: [],
      },
      validateRules: {
        idTraLog: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.id_tra_log"),
            trigger: "change",
            type: "number",
          },
        ],
        idCon: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.id_con"),
            trigger: "change",
            type: "number",
          },
        ],
        eventTime: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.event_time"),
            trigger: "change",
          },
        ],
        idTag: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.id_tag"),
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.start_time"),
            trigger: "change",
          },
        ],
        startValue: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.start_value"),
            trigger: "change",
          },
        ],
        stopTime: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.stop_time"),
            trigger: "change",
          },
        ],
        stopValue: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.stop_value"),
            trigger: "change",
          },
        ],
        stopReason: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.stop_reason"),
            trigger: "change",
          },
        ],
        failReason: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.fail_reason"),
            trigger: "change",
          },
        ],
        tenantKey: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.tenant_key"),
            trigger: "change",
          },
        ],
        currentElectricity: [
          {
            required: true,
            message: this.$t("transactionLog.validate.required.current_electricity"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getConnectorList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getConnectorList();
      this.visible = true;
    },
    submit() {
      this.$refs["transactionLogForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new TransactionLogAdd();
              break;
            case "edit":
              console.log("edit");
              api = new TransactionLogEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(this.$t("succeedMsg.0"));
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
            }
          });
        })
        .catch(() => {
        });
    },
    getConnectorList() {
      const api = new ConnectorListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.connectorList = response.data.data;
        } else {
            if(response.data.code !== undefined){message.error(this.$t("errorMsg." + response.data.code));}
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idTraLog : "",
        idCon : "",
        eventTime : "",
        idTag : "",
        startTime : "",
        startValue : "",
        stopTime : "",
        stopValue : "",
        stopReason : "",
        failReason : "",
        tenantKey : "",
        currentElectricity : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>
