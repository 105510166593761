<template>
  <Modal
      id="MeterEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" >
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="meterForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('meter.field.id_reg')" name="idReg">
                    <Select
                        v-model:value="form.idReg"
                        show-search
                        :placeholder="$t('public.pleaseSelect')"
                        style="width: 100%"
                        :options="options"
                        :filter-option="filterOption"
                        allowClear
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('meter.table.loadByTactics')" name="loadByTactics">
                    <Select v-model:value="form.loadByTactics" :placeholder="$t('public.pleaseSelect')"
                            :dropdownStyle="{zIndex: 9999999950}" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('meter.field.name')" name="name">
                    <Input :placeholder="$t('meter.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('meter.field.current_threshold')" name="currentThreshold">
                    <InputNumber
                        style="width: 100%"
                        :placeholder="$t('meter.validate.required.current_threshold')"
                        v-model:value="form.currentThreshold"
                        :max="999"
                        :min="0"
                    >
                    </InputNumber>
                  </FormItem>
                </Col>

                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
export default {
  name: "MeterEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {MeterAdd, MeterEdit} from "@/api/meter";
import {useStore} from "vuex";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

const meterForm = ref()

const isAdmin = store.getters.getIsAdmin

let visible = ref(false)
let title = ref("")
let actionType = ref("")

const validateRules = {
  idMet: [
    {
      required: true,
      message: i18n.t("meter.validate.required.id_met"),
      trigger: "change",
      type: "number",
    },
  ],
  idReg: [
    {
      required: true,
      message: i18n.t("meter.validate.required.id_reg"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("meter.validate.required.name"),
      trigger: "change",
    },
  ],
  currentThreshold: [
    {
      required: true,
      message: i18n.t("meter.validate.required.current_threshold"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: false,
      message: i18n.t("meter.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
  loadByTactics: [
    {
      required: true,
      message: i18n.t("public.pleaseSelect"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {
    idMet: undefined,
    idReg: undefined,
    name: undefined,
    currentThreshold: undefined,
    tenantKey: "",
  },
  options: []
})
const {form, options} = toRefs(data)

function addShow(idReg, opt) {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  options.value = opt
  _resetData();
  if (idReg) {
    form.value.idReg = idReg
  }
  visible.value = true;
}

async function editShow(obj, opt) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  options.value = opt
  _resetData();
  form.value = obj;
  visible.value = true;
}

function submit() {
  meterForm.value?.validate().then(() => {
        let api;
        switch (actionType.value) {
          case "add":
            api = new MeterAdd();
            break;
          case "edit":
            api = new MeterEdit();
            break;
          default:
            return false;
        }
        api.post(form.value).then((response) => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        });
      }).catch(() => {
  });
}

function _resetData() {
  form.value = {};
}

function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

defineExpose({
  addShow,
  editShow
})
</script>