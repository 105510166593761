<template>
  <Modal
      id="BalLogList"
      width="50%"
      :open="visible"
      @cancel="cancel"
      @ok="cancel"
      :title="$t('balanceLog.name.title')"
      :footer="null"
      :destroyOnClose="true"
  >
    <div class="modal">
      <Table
          :columns="columns"
          :dataSource="listView"
          :scroll="{ x: 500, y: 500 }"
          rowKey="idBalLog"
          :loading="loading"
          :pagination=false
      >
        <!--        <template #status="{text}">-->
        <!--          <div>-->
        <!--            <Tag v-if="text === 8 || text === 10" color="#f50">-->
        <!--              {{ statusList.find(item => item.value === text).label }}-->
        <!--            </Tag>-->
        <!--            <Tag v-else color="#87d068">{{ statusList.find(item => item.value === text).label }}</Tag>-->
        <!--          </div>-->
        <!--        </template>-->
      </Table>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "BalLogList",
}
</script>

<script setup>
import {
  Modal,
  Button,
  Table,
  Tag,
} from "ant-design-vue";
import {ref, reactive, toRefs} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n"
import {UserPlanGetBalLogByIdBalLogs} from "@/api/userPlan";

const i18n = useI18n()

const visible = ref(false)
const loading = ref(false)
const columns = reactive([
  {
    title: i18n.t("balanceLog.table.id_bal_log"),
    width: 48,
    fixed: "left",
    dataIndex: "idBalLog",
    key: "idBalLog"
  },
  {
    title: i18n.t("balanceLog.table.type"),
    width: 80,
    dataIndex: "type",
    key: "type",
    customRender: ({text}) => {
      return i18n.t("public.consumeType." + (text - 1));
    }
  },
  {title: i18n.t("balanceLog.table.amount"), width: 80, dataIndex: "amount", key: "amount"},
  {
    title: i18n.t("balanceLog.table.payment_method"),
    width: 90,
    dataIndex: "paymentMethod",
    key: "paymentMethod"
  },
  {
    title: i18n.t("balanceLog.table.log_time"),
    width: 110,
    dataIndex: "logTime",
    key: "logTime",
    customRender: ({text}) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    title: i18n.t("balanceLog.table.status"),
    width: 80,
    dataIndex: "status",
    key: "status",
    customRender: ({text}) => {
      return text === 2 ? i18n.t("public.payStatus.3") : i18n.t("public.payStatus.1")
    }
  },
])

const data = reactive({
  listView: [],
})
const {listView} = toRefs(data)

function onShow(idBalLogs) {
  visible.value = true
  listView.value = []
  get_list(idBalLogs)
}

function cancel() {
  visible.value = false
}

function get_list(idBalLogs) {
  loading.value = true
  const api = new UserPlanGetBalLogByIdBalLogs();
  api.get({
    page: 1,
    limit: 50,
    idBalLogs: idBalLogs
  }).then(response => {
    if (0 === parseInt(response.data.code)) {
      listView.value = response.data.data
    }
  }).finally(() => {
    loading.value = false;
  });
}

defineExpose({
  onShow,
});
</script>
