<template xmlns="http://www.w3.org/1999/html">
  <Row id="PaySuccess" align="middle" justify="center" style="position: absolute; top: 20%; left: 50%; transform: translateX(-50%); text-align: center">
    <Col class="header" :span="24">
      <CheckCircleOutlined :style="{ color: '#52c41a', fontSize: '120px'}"/>
      <p>支付成功</p>
      <Button type="primary" @click="closeWindow" :disabled="countdown > 0"> {{countdown > 0 ? '關閉頁面(' + countdown + 's)' : '關閉頁面'}} </Button>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "PaySuccess",
}
</script>
<script setup>
import {Row, Col, Button} from "ant-design-vue";
import {CheckCircleOutlined} from "@ant-design/icons-vue"
import {ref} from "vue";

let countdown = ref(0)

// 延迟5秒才给点击
startCountdown()
function startCountdown() {
  if (countdown.value > 0) return;
  countdown.value = 5;
  const intervalId = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(intervalId);
    }
  }, 1000);
}

function closeWindow() {
  if (countdown.value > 0) return;
  // console.log("关闭浏览器");
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("MSIE") > 0) {
    if (userAgent.indexOf("MSIE 6.0") > 0) {
      window.opener = null;
      window.close();
    } else {
      window.open("", "_top");
      window.top.close();
    }
  } else if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
    window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
    //window.history.go(-2);
  } else {
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();
  }
  window.location.href = "octopus://com.octopusPay.demo";
}

function getParam() {
  // let arr = "?login=gk&age=56";
  let queryStr = location.search.split("?")[1];
  let strs = queryStr.split("?")[1];
  let paramt = strs.split("&");
  console.log(paramt)
  let obj = {};
  for (let i = 0; i < paramt.length; i++) {
    let g = paramt[i].split("=");
    obj[g[0]] = g[1];
  }
  return obj
}
</script>


