import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from "@/store"
import Login from '@/views/Login'
import Home from '@/views/Home'
import PaySuccess from '@/views/PaySuccess'
import DelAccount from '@/views/DelAccount'

import AppVersionManage from '@/views/Home/appVersionManage'
import iPadManage from '@/views/Home/iPadSetting/iPadManage'
import Dashboard from '@/views/Home/dashboard/dashboard'
import RegionDashboard from '@/views/Home/dashboard/regionDashboard'
import BigScreen from '@/views/Home/dashboard/bigScreen'

import TransactionLogManage from '@/views/Home/logSetting/transactionLogManage'
import ConnectorLogManage from '@/views/Home/logSetting/connectorLogManage'
import EventLogManage from '@/views/Home/logSetting/eventLogManage'

import RepairLogManage from '@/views/Home/appDataSetting/repairLogManage'
import BalanceLogManage from '@/views/Home/appDataSetting/balanceLogManage'
import CarManage from '@/views/Home/appDataSetting/carManage'
import CarImagesManage from '@/views/Home/appDataSetting/carImagesManage'
import UserManage from '@/views/Home/appDataSetting/userManage'
import UserPlanManage from '@/views/Home/appDataSetting/userPlanManage'
import OrderManage from '@/views/Home/appDataSetting/orderManage'
import PlanManage from '@/views/Home/appDataSetting/planManage'
import WallerManage from '@/views/Home/appDataSetting/wallerManage'

import ChargeStationImagesManage from '@/views/Home/chargingStationSetting/chargeStationImagesManage'
import ChargingStationManage from '@/views/Home/chargingStationSetting/chargingStationManage'
import ConnectorManage from '@/views/Home/chargingStationSetting/connectorManage'
import MeterManage from '@/views/Home/chargingStationSetting/meterManage'
import RegionManage from '@/views/Home/chargingStationSetting/regionManage'
import RegionImagesManage from '@/views/Home/chargingStationSetting/regionImagesManage'
import GetConfiguration from '@/views/Home/chargingStationSetting/getConfiguration'
import DataTransfer from '@/views/Home/chargingStationSetting/dataTransfer'
import StatisticsManage from '@/views/Home/chargingStationSetting/statisticsManage'
import ServiceManage from '@/views/Home/chargingStationSetting/ServiceManage'
import CardManage from '@/views/Home/chargingStationSetting/cardManage'

import AuthorityManage from '@/views/Home/tenantSetting/authorityManage'
import SuperTenantManage from '@/views/Home/tenantManage/superTenantManage/index'
import RoleManage from '@/views/Home/tenantSetting/roleManage'
import EmployeeManage from '@/views/Home/tenantSetting/employeeManage'
import NoticeManage from '@/views/Home/tenantSetting/noticeManage'
import PublicityManage from '@/views/Home/tenantSetting/publicityManage'
import TenantInfo from '@/views/Home/tenantManage/tenantInfo'
import TenantPackage from '@/views/Home/tenantManage/tenantPackage'

const routes = [
  {
    path: "/",
    component: Login
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/PaySuccess",
    name: "PaySuccess",
    component: PaySuccess
  },
  {
    path: "/BigScreen",
    name: "BigScreen",
    component: BigScreen
  },
  {
    path: "/DelAccount",
    name: "DelAccount",
    component: DelAccount
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if ("" !== store.getters.getToken && undefined !== store.getters.getToken && null !== store.getters.getToken) {
        //console.log(store.getters.getToken)
        next()
      } else {
        next('/Login')
      }
    },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "/RegionDashboard",
        name: "RegionDashboard",
        component: RegionDashboard
      },
      // {
      //   path: "/BigScreen",
      //   name: "BigScreen",
      //   component: BigScreen
      // },
      {
        path: "/AuthorityManage",
        name: "AuthorityManage",
        component: AuthorityManage
      },
      {
        path: "/SuperTenantManage",
        name: "SuperTenantManage",
        component: SuperTenantManage
      },
      {
        path: "/BalanceLogManage",
        name: "BalanceLogManage",
        component: BalanceLogManage
      },
      {
        path: "/CarManage",
        name: "CarManage",
        component: CarManage
      },
      {
        path: "/CarImagesManage",
        name: "CarImagesManage",
        component: CarImagesManage
      },
      {
        path: "/ChargeStationImagesManage",
        name: "ChargeStationImagesManage",
        component: ChargeStationImagesManage
      },
      {
        path: "/ChargingStationManage",
        name: "ChargingStationManage",
        component: ChargingStationManage
      },
      {
        path: "/ConnectorManage",
        name: "ConnectorManage",
        component: ConnectorManage
      },
      {
        path: "/EmployeeManage",
        name: "EmployeeManage",
        component: EmployeeManage
      },
      {
        path: "/EventLogManage",
        name: "EventLogManage",
        component: EventLogManage
      },
      {
        path: "/ConnectorLogManage",
        name: "ConnectorLogManage",
        component: ConnectorLogManage
      },
      {
        path: "/MeterManage",
        name: "MeterManage",
        component: MeterManage
      },
      {
        path: "/OrderManage",
        name: "OrderManage",
        component: OrderManage
      },
      {
        path: "/PlanManage",
        name: "PlanManage",
        component: PlanManage
      },
      {
        path: "/RegionManage",
        name: "RegionManage",
        component: RegionManage
      },
      {
        path: "/RegionImagesManage",
        name: "RegionImagesManage",
        component: RegionImagesManage
      },
      {
        path: "/RepairLogManage",
        name: "RepairLogManage",
        component: RepairLogManage
      },
      {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage
      },
      {
        path: "/TransactionLogManage",
        name: "TransactionLogManage",
        component: TransactionLogManage
      },
      {
        path: "/UserManage",
        name: "UserManage",
        component: UserManage
      },
      {
        path: "/UserPlanManage",
        name: "UserPlanManage",
        component: UserPlanManage
      },
      {
        path: "/GetConfiguration",
        name: "GetConfiguration",
        component: GetConfiguration
      },
      {
        path: "/DataTransfer",
        name: "DataTransfer",
        component: DataTransfer
      },
      {
        path: "/AppVersionManage",
        name: "AppVersionManage",
        component: AppVersionManage
      },
      {
        path: "/iPadManage",
        name: "iPadManage",
        component: iPadManage
      },
      {
        path: "/NoticeManage",
        name: "NoticeManage",
        component: NoticeManage
      },
      {
        path: "/PublicityManage",
        name: "PublicityManage",
        component: PublicityManage
      },
      {
        path: "/StatisticsManage",
        name: "StatisticsManage",
        component: StatisticsManage
      },
      {
        path: "/ServiceManage",
        name: "ServiceManage",
        component: ServiceManage
      },
      {
        path: "/TenantInfo",
        name: "TenantInfo",
        component: TenantInfo
      },
      {
        path: "/TenantPackage",
        name: "TenantPackage",
        component: TenantPackage
      },
      {
        path: "/CardManage",
        name: "CardManage",
        component: CardManage
      },
      {
        path: "/WallerManage",
        name: "WallerManage",
        component: WallerManage
      },
    ]
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
})

export default router
