<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('plan.table.name')" name="name">
                  <Input :placeholder="$t('plan.validate.required.name')" v-model:value="searchForm.name"></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <!--                <Button type="primary" @click="exportToExecl">{{ $t('public.name.Export') }}</Button>-->
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table :columns="data.columns" :dataSource="list" :scroll="{ x: true }" rowKey="idPla" :loading="loading" :pagination="tablePagination" @change="chang_page">
        <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('PlanManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
        </template>
        <template #action="{text}">
          <div>
            <div class="pc-display">
              <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('PlanManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('PlanManage.Delete')" type="primary" danger size="small" @click="del(text.idPla)">{{$t("public.name.del")}}</Button>
            </div>
            <Dropdown :trigger="['click']" class="wap-display">
              <template #overlay>
                <Menu>
                  <MenuItem @click="del(text.id)">{{$t('public.name.del')}}</MenuItem>
                </Menu>
              </template>
              <Button size="small">
                {{$t("public.name.more")}}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </template>
      </Table>
    </Col>
    <PlanDetail ref="refPlanDetail"></PlanDetail>
    <PlanEdit ref="refPlanEdit" @updateList="get_list"></PlanEdit>
  </Row>
</template>

<script>
export default {
  name: "PlanManage"
}
</script>
<script setup>
import { Row, Col, Table, Button, Dropdown, Menu, Modal, Form, FormItem, Input, Select, message, TypographyTitle } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { PlanList, PlanDelete } from "@/api/plan";
import PlanDetail from "./PlanDetail";
import PlanEdit from "./PlanEdit";
import {createVNode, onMounted, ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const i18n = useI18n()
const refPlanEdit = ref()
const refPlanDetail = ref()

let loading = ref(false)
const data = reactive({
  list:[],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  columns: [
    { title: i18n.t("plan.table.id_pla"), fixed: "left", dataIndex: "idPla", key: "idPla" },
    { title: i18n.t("plan.table.name"), dataIndex: "name", key: "name" },
    { title: i18n.t("plan.table.price_per_month"), dataIndex: "pricePerMonth", key: "pricePerMonth" },
    { title: i18n.t("plan.table.contract_period"), dataIndex: "contractPeriod", key: "contractPeriod" },
    { title: i18n.t("plan.table.free_charging_time_limit"), dataIndex: "freeChargingTimeLimit", key: "freeChargingTimeLimit" },
    { title: i18n.t("plan.table.overtime_charge_price"), dataIndex: "overtimeChargePrice", key: "overtimeChargePrice" },
    { title: i18n.t("plan.table.tenant_key"), dataIndex: "tenantKey", key: "tenantKey" },
    { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
  ],
  selectList: {}
})
const {searchForm, tablePagination, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new PlanList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
        loading.value = false;
        if (0 === parseInt(response.data.code)) {
          const dataTmp = response.data.data;
          tablePagination.value.current = parseInt(dataTmp.current);
          tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
          tablePagination.value.total = parseInt(dataTmp.total);
          list.value = dataTmp.data;
        } else {
          list.value = [];
        }
      });
}

function add() {
  refPlanEdit.value?.addShow();
}

function detail(obj) {
  refPlanDetail.value?.detailShow(obj);
}

function edit(obj) {
  refPlanEdit.value?.editShow(obj);
}

function del(idPla) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PlanDelete();
      api.post({ idPla: idPla }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.PlanManage'));

get_list();

defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>
