<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Row>
            <Col :span="24">
              <Form :model="searchForm">
                <Row :gutter="16">
                  <Col :span="6">
                    <FormItem :label="$t('region.table.id_reg')" name="idReg">
                      <Input :placeholder="$t('region.validate.id_reg')"
                             v-model:value="searchForm.idReg"></Input>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('region.table.name')" name="name">
                      <Input :placeholder="$t('region.validate.name')" v-model:value="searchForm.name"></Input>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('region.table.address')" name="address">
                      <Input :placeholder="$t('region.validate.address')"
                             v-model:value="searchForm.address"></Input>
                    </FormItem>
                  </Col>
                  <Col :span="6">
                    <FormItem :label="$t('region.table.businessAllDay')" name="businessAllDay">
                      <Select v-model:value="searchForm.businessAllDay"
                              :placeholder="$t('region.validate.businessAllDay')"
                              :dropdownStyle="{zIndex: 9999999950}">
                        <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                        <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button v-if="checkPermission('ChargingStationManage.Add')" type="primary" @click="quickAdd()">
                {{ $t("chargingStation.name.quickAdd") }}
              </Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idReg" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <!--                <Button v-if="checkPermission('RegionManage.Add')" type="primary" size="small" shape="circle" @click="add()">-->
                <!--                  <PlusOutlined />-->
                <!--                </Button>-->
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">

            <template v-if="column.dataIndex === 'isEnableSwitch'">
              <Switch :checked="record.status === 1" @click="e => switchClick(e, record.idReg)" checked-children="ON" un-checked-children="OFF" />
            </template>

            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">
                  {{ $t("public.name.detail") }}
                </a>
                <Divider type="vertical"/>
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem v-if="checkPermission('RegionManage.Edit')">
                        <Button type="primary" size="small" @click="showConfig(record.idReg)">{{ $t("public.name.setting") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('RegionManage.Edit')">
                        <Button type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('RegionManage.Delete')">
                        <Button type="primary" danger size="small" @click="del(record.idReg)">{{ $t("public.name.del") }}</Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('RegionManage.ChargingStation')">
                        <Button type="primary" size="small" @click="goChargingStation(record.idReg)">{{ $t("nav.name.ChargingStationManage") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('RegionManage.Meter')">
                        <Button type="primary" size="small" @click="goMeter(record.idReg)">{{ $t("nav.name.MeterManage") }}</Button>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>
          </template>
        </Table>
      </div>
      <RegionDetail ref="refRegionDetail"></RegionDetail>
      <RegionEdit ref="refRegionEdit" @updateList="get_list"></RegionEdit>
      <RegionConfig ref="refRegionConfig" @updateList="get_list"></RegionConfig>
      <ChargingStationQuickEdit ref="refChargingStationQuickEdit" @updateList="get_list"></ChargingStationQuickEdit>
    </Col>
  </Row>
</template>
<script>
export default {
  name: "RegionManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs, getCurrentInstance} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  SelectOption,
  message,
  Divider,
  Switch
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {RegionList, RegionDelete, RegionSetStatus} from "@/api/region"
import RegionDetail from "./RegionDetail";
import RegionEdit from "./RegionEdit";
import RegionConfig from "./RegionConfig";
import ChargingStationQuickEdit from "@/components/Home/ChargingStationQuickEdit";
import {GetUrlList} from "@/api/common";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router/dist/vue-router";
const { proxy } = getCurrentInstance();

const store = useStore()
const i18n = useI18n()
const router = useRouter()

let refRegionDetail = ref()
let refRegionEdit = ref()
let refRegionConfig = ref()
let refChargingStationQuickEdit = ref()

let loading = ref(false)

const columns = [
  {
    title: i18n.t("public.name.index"),
    fixed: "left",
    width: 80,
    ellipsis: true,
    customRender: ({
                     text,
                     record,
                     index,
                     column
                   }) => `${(tablePagination.value.current - 1) * tablePagination.value.pageSize + index + 1}`
  },
  { title: i18n.t("superTenantManage.table.isEnable"), key: "status", fixed: "center", width: 100, dataIndex: "isEnableSwitch" },
  {title: i18n.t("region.table.id_reg"), width: 80, ellipsis: true, dataIndex: "idReg", key: "idReg"},
  {title: i18n.t("region.table.name"), width: 180, ellipsis: true, dataIndex: "name", key: "name"},
  {title: i18n.t("region.table.address"), width: 180, ellipsis: true, dataIndex: "address", key: "address"},
  // {
  //   title: i18n.t("region.table.tenant_key"),
  //   width: 120,
  //   ellipsis: true,
  //   dataIndex: "tenantKey",
  //   key: "tenantKey"
  // },
  // {
  //   title: i18n.t("region.table.ac_price_per_electricity"),
  //   width: 180,
  //   ellipsis: true,
  //   dataIndex: "acPricePerElectricity",
  //   key: "acPricePerElectricity",
  //   customRender: ({text}) => {
  //     return '$' + proxy.money(text)
  //   }
  // },
  // {
  //   title: i18n.t("region.table.ac_price_per_minute"),
  //   width: 180,
  //   ellipsis: true,
  //   dataIndex: "acPricePerMinute",
  //   key: "acPricePerMinute",
  //   customRender: ({text}) => {
  //     return '$' + proxy.money(text)
  //   }
  // },
  // {
  //   title: i18n.t("region.table.dc_price_per_electricity"),
  //   width: 180,
  //   ellipsis: true,
  //   dataIndex: "dcPricePerElectricity",
  //   key: "dcPricePerElectricity",
  //   customRender: ({text}) => {
  //     return '$' + proxy.money(text)
  //   }
  // },
  // {
  //   title: i18n.t("region.table.dc_price_per_minute"),
  //   width: 180,
  //   ellipsis: true,
  //   dataIndex: "dcPricePerMinute",
  //   key: "dcPricePerMinute",
  //   customRender: ({text}) => {
  //     return '$' + proxy.money(text)
  //   }
  // },
  {title: i18n.t("region.table.lon"), width: 180, ellipsis: true, dataIndex: "lon", key: "lon"},
  {title: i18n.t("region.table.lat"), width: 180, ellipsis: true, dataIndex: "lat", key: "lat"},
  {title: i18n.t("authority.table.sort"), width: 60, ellipsis: true, dataIndex: "sort", key: "sort"},
  {
    key: "action",
    width: 160,
    ellipsis: true,
    fixed: "right",
    dataIndex: "action"
  },
]

const data = reactive({
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  selectList: {}
})
const {searchForm, list, tablePagination, selectList} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new RegionList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  delete getData.createTime
  delete getData.updateTime
  api.get(getData).then((response) => {
    loading.value = false
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function add() {
  loading.value = true;
  refRegionEdit.value?.addShow();
  loading.value = false;
}

async function detail(obj) {
  loading.value = true;
  await refRegionDetail.value?.detailShow(obj);
  loading.value = false;
}
// 设置方法同步
function getImgUrl(api, url) {
  return new Promise((resolve, reject) => {
    api.post({objNameList: url}).then(res => {
      resolve(res.data.data)
    })
  });
}
async function getAllImgList(obj) {
  const api = new GetUrlList();
  let imgList

  let l = []
  for (let i = 0; i < obj.regionImagesList.length; i++) {
    l.push(obj.regionImagesList[i].url)
  }
  imgList = await getImgUrl(api, l)
  return imgList
}

async function edit(obj) {
  loading.value = true;
  // 设置图片展示列表
  obj.imgList = await getAllImgList(obj)
  await refRegionEdit.value.editShow(obj);
  loading.value = false;
}

function del(idReg) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new RegionDelete();
      api.post({idReg: idReg}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if (response.data.code !== undefined) {
            message.error(i18n.t("errorMsg." + response.data.code));
          }
        }
      });
    },
  });
}

function quickAdd() {
  refChargingStationQuickEdit.value.addShow();
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function goChargingStation(idReg) {
  router.push({
    path: '/ChargingStationManage',
    query: {
      idReg: idReg
    }
  })
}

function goMeter(idReg) {
  router.push({
    path: '/MeterManage',
    query: {
      idReg: idReg
    }
  })
}

function showConfig(idReg) {
  refRegionConfig.value.showConfig(idReg);
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function switchClick(e, id) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_operate") + "?",
    content: i18n.t("public.name.operate_remind") + "！",
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      loading.value = true
      // 更新数据库及刷新列表
      const api = new RegionSetStatus()
      api.post({id: id, isEnable: e}).then(response=>{
        if (0 === parseInt(response.data.code)) {
          // 更新列表
          get_list()
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      }).finally(()=>{
        loading.value = false
      })
    },
  });
}

store.commit("setPageTitle", i18n.t('nav.name.RegionManage'))
get_list()

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
