<template>
  <Menu id="Nav"
        :theme="store.getters.getTheme"
        @click="clickItem"
        mode="inline"
        :forceSubMenuRender="true"
        :openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        @openChange="onOpenChange"
  >
    <div class="logo" v-show="!collapsed">
      <span>EvCharge</span>
    </div>
    <div class="logo" v-show="collapsed"></div>

    <MenuItem key="Dashboard">
      <DashboardOutlined />
      <span>{{$t('nav.name.Dashboard')}}</span>
    </MenuItem>
    <MenuItem key="BigScreen">
      <DashboardOutlined />
      <span>{{$t('nav.name.BigScreen')}}</span>
    </MenuItem>
    <MenuItem key="RegionDashboard">
      <DashboardOutlined />
      <span>{{$t('nav.name.RegionDashboard')}}</span>
    </MenuItem>
    <template v-for="(item) in navList" :key="item.name">
      <MenuItem v-if="!item.children" :key="item.name">
        <ControlOutlined v-if="item.name === 'Auth'" />
        <TeamOutlined v-else-if="item.name === 'Member'" />
        <UserOutlined v-else-if="item.name === 'Users'" />
        <UserOutlined v-else-if="item.name === 'UserManage'" />
        <UploadOutlined v-else-if="item.name === 'Uploads'" />
        <PhoneOutlined v-else-if="item.name === 'PhoneCountry'" />
        <BankOutlined v-else-if="item.name === 'CompanyManage'" />
        <ControlOutlined v-else />
        <span>{{$t('nav.name.'+item.name)}}</span>
      </MenuItem>
      <SubMenu v-else :key="item.name">
        <template #title>
          <span>
            <ControlOutlined v-if="item.name === 'Auth'" />
            <TeamOutlined v-else-if="item.name === 'Member'" />
            <UserOutlined v-else-if="item.name === 'Users'" />
            <UserOutlined v-else-if="item.name === 'UserManage'" />
            <UploadOutlined v-else-if="item.name === 'Uploads'" />
            <PhoneOutlined v-else-if="item.name === 'PhoneCountry'" />
            <BankOutlined v-else-if="item.name === 'CompanyManage'" />
            <ControlOutlined v-else />
            <span>{{$t('nav.name.'+item.name)}}</span>
          </span>
        </template>
        <MenuItem v-for="v in item.children" :key="v.name">{{$t('nav.name.'+v.name)}}</MenuItem>
      </SubMenu>
    </template>
  </Menu>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Nav",
}
</script>
<script setup>
import { Menu, MenuItem, SubMenu, notification, Row, Col } from "ant-design-vue";
import { LogoutOutlined, UserOutlined, DashboardOutlined, ControlOutlined, TeamOutlined, UploadOutlined, PhoneOutlined, BankOutlined } from "@ant-design/icons-vue";
import { NavList } from "@/api/NavList"
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {useRouter, useRoute} from "vue-router";

const i18n = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()
const props = defineProps(['collapsed'])

let navList = ref([])
let openKeys = ref([])
let selectedKeys = ref([])

function onOpenChange (Keys) {
  const latestOpenKey = Keys.find(key => openKeys.value.indexOf(key) === -1);
  if (navList.value.indexOf(latestOpenKey) === -1) {
    openKeys.value = latestOpenKey ? [latestOpenKey] : [];
  } else {
    openKeys.value = Keys;
  }
}

function logout() {
  // 清空tenantDb缓存标识
  store.commit("setTenantDb", undefined);
  store.dispatch("logout");
  location.reload();
}

function clickItem(item) {
  if (!item) return false;
  if (item.key === "BigScreen") {
    const protocol = window.location.protocol;
    // 正式环境
    // const host = window.location.host;
    const hostname = window.location.hostname;
    const toURL = protocol + "//" + hostname + "/big/#/index" + "?tenantKey=" + store.getters.getTenantKey + "&token=" + store.getters.getToken
    window.open(toURL , "BigScreen");

    // 开发环境
    // const hostname = window.location.hostname;
    // const toURL = protocol + "//" + hostname + ":8112/#/index" + "?tenantKey=" + store.getters.getTenantKey + "&token=" + store.getters.getToken
    // window.open(toURL, "BigScreen");
    return
  }

  if (router.hasRoute(item.key)) {
    router.push({
      name: item.key,
    });
  } else {
    notification.warning({
      message: i18n.t('public.name.no_page'),
    });
  }
}

function defaultSelectedKeys() {
  let rPath = route.path
  // eslint-disable-next-line no-constant-condition
  if ((rPath !== undefined || true || null) || rPath !=='') {
    rPath = rPath.replace('/', '')
  }
  const Menus = navList.value
  let pd = true
  for (let i = 0; i < Menus.length; i++) {
    const a = Menus[i]
    if (a.children) {
      for (let y = 0; y < a.children.length; y++) {
        const t = a.children[y].name
        if (t === rPath) {
          pd = false
          openKeys.value = [a.name, t]
          selectedKeys.value = [a.name, t]
        }
      }
    }
  }
  if (pd) {
    openKeys.value = []
    selectedKeys.value = []
  }
}

function get_user_nav() {
  // 先从浏览器缓存中获取菜单，若浏览器缓存中的菜单为空，则请求后台获取菜单
  navList.value = store.getters.getNavList;
  if (navList.value === undefined || navList.value.length === 0) {
    let api = new NavList();
    api.get().then((response) => {
      if (0 === parseInt(response.data.code)) {
        navList.value = response.data.data;
      } else {
        navList.value = [];
      }
      // 设置
      store.commit("setNavList", navList.value);
    });
  }
  defaultSelectedKeys();
}

onMounted(()=>{
  get_user_nav();
})

// export default {
//   props: ['collapsed'],
// }
</script>


<style lang="less">
.logo {
  height    : 30px;
  background: rgba(255, 255, 255, 0.2);
  margin    : 16px;
  text-align: center;
  display   : flex;
  align-items: center;
  justify-content: center;
}
.logo span {
  font-weight: bold;
  color:white;
  font-size: 20px;
  /* 可以根据需要添加其他样式 */
}
</style>
