<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('publicityManage.field.type')" name="type">
                  <Select v-model:value="searchForm.type" :placeholder="$t('publicityManage.validate.type')" allowClear>
                    <SelectOption :value="0">{{ $t('publicityManage.publicityType.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('publicityManage.publicityType.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('publicityManage.publicityType.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('publicityManage.publicityType.3') }}</SelectOption>
                    <SelectOption :value="4">{{ $t('publicityManage.publicityType.4') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('publicityManage.field.status')" name="status">
                  <Select v-model:value="searchForm.status" :placeholder="$t('publicityManage.validate.status')"
                          allowClear>
                    <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('publicityManage.field.title')" name="title">
                  <Input v-model:value="searchForm.title" :placeholder="$t('publicityManage.validate.title')"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>

            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <Button type="primary" size="default" @click="add()">{{ $t('public.name.add') }}</Button>
                <span>&nbsp;</span>
                <Button type="primary" size="default" danger @click="delByIds()" :disabled="selectedRowKeys === undefined || selectedRowKeys.length === 0">{{ $t('public.name.batchDel') }}
                </Button>
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
                <span>&nbsp;</span>
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <!-- 列表-->
      <div>
        <Table
            :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :pagination="tablePagination"
            :scroll="{x: 1500}"
            @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('PublicityManage.table.action.Add')" type="primary" size="small"
                        shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>
          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                  <span>&nbsp;</span>
                  <Button type="primary" danger size="small" @click="del(record)">{{ $t("public.name.del") }}</Button>
                </div>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <FormModel ref="refFormModel" @updateList="get_list" :selectList="selectList"></FormModel>
  </Row>
</template>

<script>
export default {
  name: "publicityManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  Input,
  message,
  Modal,
  Row,
  Select,
  SelectOption,
  Switch,
  Table,
  Spin
} from "ant-design-vue";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {PublicityGetListByPage, PublicityDelete} from "@/api/publicity";
import FormModel from "./FormModel";
import {RegionListAll} from "@/api/region";

import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refFormModel = ref()

let loading = ref(false)
let isClickLoading = ref(false)

const columns = [
  {
    title: i18n.t("publicityManage.field.index"),
    fixed: "center",
    width: 60,
    customRender: ({text, record, index, column}) => `${index + 1}`
  },
  {
    title: i18n.t("publicityManage.field.type"), fixed: "center", width: 60, dataIndex: "type", key: "type",
    customRender: ({text, record, index, column}) => {
      if (text === 0) {
        return i18n.t("publicityManage.publicityType.0")
      } else if (text === 1) {
        return i18n.t("publicityManage.publicityType.1")
      } else if (text === 2) {
        return i18n.t("publicityManage.publicityType.2")
      } else if (text === 3) {
        return i18n.t("publicityManage.publicityType.3")
      } else if (text === 4) {
        return i18n.t("publicityManage.publicityType.4")
      }
    }
  },
  {
    title: i18n.t("publicityManage.field.status"), fixed: "center", width: 60, dataIndex: "status", key: "status",
    customRender: ({text, record, index, column}) => {
      if (text === 1) {
        return i18n.t("public.isTrue.1")
      } else {
        return i18n.t("public.isTrue.0")
      }
    }
  },
  {title: i18n.t("publicityManage.field.title"), fixed: "center", width: 200, dataIndex: "title", key: "title"},
  {
    title: i18n.t("publicityManage.field.tenantKey"),
    fixed: "center",
    width: 80,
    dataIndex: "tenantKey",
    key: "tenantKey"
  },
  {title: i18n.t("publicityManage.field.createBy"), fixed: "center", width: 60, dataIndex: "createBy", key: "createBy"},
  {
    title: i18n.t("publicityManage.field.createTime"),
    fixed: "center",
    width: 80,
    dataIndex: "createTime",
    key: "createTime"
  },
  {key: "action", fixed: "center", width: 80, dataIndex: "action"},
]

const data = reactive({
  selectList: [],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  selectedRowKeys: [],
  list: [],
})
const {selectList, searchForm, tablePagination, selectedRowKeys, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new PublicityGetListByPage();
  // 构建查询参数
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data
    } else {
      list.value = [];
    }
  });
  // 获取所有区域列表
  const api1 = new RegionListAll();
  api1.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      let l = []
      let regList = res.data.data
      for (let i = 0; i < regList.length; i++) {
        const obj = {
          value: regList[i].idReg,
          label: regList[i].name,
        }
        l.push(obj)
      }
      selectList.value = l
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  })
}

function add() {
  refFormModel.value?.addShow();
}

function edit(obj) {
  refFormModel.value?.editShow(obj);
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PublicityDelete();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new PublicityDelete();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        if (0 === response.data.code) {
          message.success(i18n.t("succeedMsg.0"))
        } else {
          message.error(i18n.t("errorMsg." + response.data.code))
        }
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  tablePagination.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.PublicityManage'));
get_list();

defineExpose({
  get_list
})
</script>
