<template>
  <Modal
      id="StopPlan"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.doingStatus.5')"
      destroyOnClose
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('balanceLog.table.id_bal_log')" name="idUsePla">
                    <InputNumber v-model:value="form.idUsePla" style="width: 100%;" disabled></InputNumber>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "StopPlan"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  message,
  Select,
  SelectOption,
  DatePicker,
  Input,
  InputNumber,
  Textarea,
  Tag
} from "ant-design-vue";
import {ref, reactive, toRefs, createVNode} from "vue";
import {useI18n} from "vue-i18n"
import {
  UserPlanEditStatus,
  UserPlanGetRenewAmount,
  UserPlanGetRenewDate,
  UserPlanGetRenewMonth,
  UserPlanRenewUserPlanByAdmin, UserPlanStopUserPlanByAdmin
} from "@/api/userPlan";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()
let visible = ref(false)

const validateRules = reactive({
  idUsePla: [
    {
      required: true,
      message: i18n.t("userPlan.validate.id_use"),
      trigger: "change",
      type: "number",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
})

const data = reactive({
  form: {
    idUsePla: undefined,
    remark: undefined
  },
})
const {form} = toRefs(data)

async function editShow(obj) {
  _resetData();
  visible.value = true;
  form.value.idUsePla = obj.idUsePla
}

function submit() {
  refForm.value.validate().then(() => {
    Modal.confirm({
      title: i18n.t("public.name.are_you_sure_operate") + "?",
      content: i18n.t("public.name.operate_remind") + "！",
      icon: createVNode(ExclamationCircleOutlined),
      okText: i18n.t("public.name.yes"),
      okType: "danger",
      cancelText: i18n.t("public.name.no"),
      onOk: () => {
        const api = new UserPlanStopUserPlanByAdmin();
        api.post(form.value).then(response=>{
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
          }
        })
      }
    })
  }).catch(() => {});
}

function _resetData() {
  form.value = {
    idUsePla: undefined,
    remark: undefined
  }
}

defineExpose({
  editShow
});

</script>

<style lang="less">
</style>
