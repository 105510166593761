<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('meter.table.id_reg')" name="idReg">
                  <Select
                      v-model:value="searchForm.idReg"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="regOptions"
                      :filter-option="filterOption"
                      @change="handleRegChange"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargingStation.table.id_met')" name="idMet">
                  <Select
                      v-model:value="searchForm.idMet"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="metOptions"
                      :filter-option="filterOption"
                      @change="handleMetChange"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('connector.table.id_cha_poi')" name="idChaPoi">
                  <Select
                      v-model:value="searchForm.idChaPoi"
                      show-search
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%"
                      :options="chaOptions"
                      :filter-option="filterOption"
                      allowClear
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('connector.table.status')" name="status">
                  <Select
                      v-model:value="searchForm.status"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="statusList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">

              <Col :span="6">
                <FormItem :label="$t('connector.table.power')" name="power">
                  <InputNumber
                      v-model:value="searchForm.power"
                      :placeholder="$t('connector.validate.required.power')"
                      :max="999"
                      :min="0"
                      style="width: 100%"
                  />
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('connector.table.type')" name="type">
                  <Select
                      v-model:value="searchForm.type"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="chargingPortTypeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8"></Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idCon" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
              {{ $t("public.table.action") }}
                <!--                            <Button v-if="checkPermission('ConnectorManage.Add')" type="primary" size="small" shape="circle"-->
                <!--                                    @click="add()">-->
                <!--                              <PlusOutlined/>-->
                <!--                            </Button>-->
            </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'vQrcode'">
              <vue-qrcode :value="record.chargingStationChargeBoxId + '&' + record.idCon" :options="{ width: 80 }"></vue-qrcode>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <div>
                <Tag v-if="record.status === 1" color="green">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else-if="record.status === 2 || record.status === 3 || record.status === 4 || record.status === 5 || record.status === 6" color="orange">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else-if="record.status === 9" color="pink">
                  {{ statusList.find(item => item.value === record.status).label }}
                </Tag>
                <Tag v-else color="red">{{ statusList.find(item => item.value === record.status).label }}</Tag>
              </div>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">{{ $t("public.name.detail") }}</a>
                <Divider type="vertical"/>
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <MenuItem>
                        <Button size="small" @click="showLog(record)">
                          {{ $t("nav.name.ConnectorLogManage") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ConnectorManage.Edit')">
                        <Button type="primary" size="small" @click="edit(record)">
                          {{ $t("public.name.edit") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ConnectorManage.Delete')">
                        <Button type="primary" danger size="small" @click="del(record.idCon)">
                          {{ $t("public.name.del") }}
                        </Button>
                      </MenuItem>
                      <MenuItem v-if="checkPermission('ConnectorManage.StartTranscation')">
                        <Button type="primary" danger size="small" @click="startTranscation(record.idCon)">
                          {{ $t("connector.name.startTransaction") }}
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button v-if="checkPermission('ConnectorManage.StopTranscation')" type="primary" danger
                                size="small" @click="stopTranscation(record.idCon)">
                          {{ $t("connector.name.stopTransaction") }}
                        </Button>
                      </MenuItem>
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <ConnectorDetail ref="refConnectorDetail"></ConnectorDetail>
    <ConnectorEdit ref="refConnectorEdit" @updateList="get_list" :chaOptions="chaOptions"></ConnectorEdit>
    <ConnectorLogList ref="refConnectorLogList"></ConnectorLogList>
  </Row>
</template>
<script>
export default {
  name: "ConnectorManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Tag,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  message,
  InputNumber,
  Divider
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {ConnectorList, ConnectorDelete} from "@/api/connector";
import {ChargingStationGetSelectList} from "@/api/chargingStation";
import ConnectorDetail from "./ConnectorDetail";
import ConnectorEdit from "./ConnectorEdit";
import ConnectorLogList from "./ConnectorLogList";
import {ConnectorStartTranscation} from "@/api/connector/ConnectorStartTranscation";
import {ConnectorStopTranscation} from "@/api/connector/ConnectorStopTranscation";
import {RegionGetSelectList} from "@/api/region";
import {MeterGetSelectListByidReg} from "@/api/meter";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refConnectorDetail = ref()
const refConnectorEdit = ref()
const refConnectorLogList = ref()

let loading = ref(false)

const statusList = [
  {value: 1, label: 'Available'},
  {value: 2, label: 'Preparing'},
  {value: 3, label: 'Charging'},
  {value: 4, label: 'SuspendedEVSE'},
  {value: 5, label: 'SuspendedEV'},
  {value: 6, label: 'Finishing'},
  {value: 7, label: 'Reserved'},
  {value: 8, label: 'Unavailable'},
  {value: 9, label: 'Faulted'},
  {value: 10, label: 'Disconnect'}
]
const chargingPortTypeList = [
  {value: 0, label: 'Type 1'},
  {value: 1, label: 'Type 2'},
  {value: 2, label: 'CHAdeMO'},
  {value: 3, label: 'CCS 1'},
  {value: 4, label: 'CCS 2'},
  {value: 5, label: 'Unknown'},
]
const columns = [
  // {
  //   title: i18n.t("public.name.index"),
  //   fixed: "left",
  //   width: 60,
  //   ellipsis: true,
  //   customRender: ({
  //                    text,
  //                    record,
  //                    index,
  //                    column
  //                  }) => `${(tablePagination.value.current - 1) * tablePagination.value.pageSize + index + 1}`
  // },
  {title: i18n.t("connector.table.id_con"), width: 60, ellipsis: true, dataIndex: "idCon", key: "idCon"},
  { title: i18n.t("public.name.qrcode"), key: "qrCode", width: 120, dataIndex: "vQrcode" },
  // { title: i18n.t("meter.table.id_reg"), width: 180, ellipsis: true, dataIndex: "regionName", key: "regionName" },
  // { title: i18n.t("chargingStation.table.id_met"), width: 180, ellipsis: true, dataIndex: "meterName", key: "meterName" },
  {title: i18n.t("chargingStation.table.charge_box_id"), width: 180, ellipsis: true, dataIndex: "chargingStationChargeBoxId", key: "chargingStationChargeBoxId" },
  {title: i18n.t("connector.table.name"), width: 180, ellipsis: true, dataIndex: "name", key: "name"},

  {title: i18n.t("connector.table.power"), width: 120, ellipsis: true, dataIndex: "power", key: "power"},
  {
    title: i18n.t("connector.table.type"),
    width: 120,
    ellipsis: true,
    dataIndex: "type",
    key: "type",
    customRender: ({text}) => {
      const obj = chargingPortTypeList.find(item => item.value === text)
      return obj === undefined ? text : obj.label
    }
  },
  {
    title: i18n.t("connector.table.status"),
    width: 80,
    ellipsis: true,
    dataIndex: "status",
    key: "status",
  },
  {
    title: "TransactionId",
    width: 80,
    ellipsis: true,
    dataIndex: "transactionid",
    key: "transactionid",
  },
  {
    title: "Reason",
    width: 80,
    ellipsis: true,
    dataIndex: "reason",
    key: "reason",
  },
  // {
  //   title: i18n.t("connector.table.tenant_key"),
  //   width: 80,
  //   ellipsis: true,
  //   dataIndex: "tenantKey",
  //   key: "tenantKey"
  // },
  {
    key: "action",
    width: 90,
    ellipsis: true,
    fixed: "right",
    dataIndex: "action"
  },
]

const data = reactive({
  // 电桩搜索框
  chaOptions: [],
  // 电表搜索框
  metOptions: [],
  // 区域搜索框
  regOptions: [],

  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  chargingStationList: [],
})
const {chaOptions, metOptions, regOptions, searchForm, list, tablePagination, chargingStationList} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  // 获取电桩下拉列表
  getChargingStation()
  // 获取电表列表
  getMeterList()
  // 获取区域列表
  getRegionSelectList()

  const api = new ConnectorList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refConnectorDetail.value?.detailShow(obj);
}

function add() {
  refConnectorEdit.value?.addShow();
}

function edit(obj) {
  delete obj.createTime
  delete obj.updateTime
  refConnectorEdit.value?.editShow(obj);
}

function showLog(obj) {
  refConnectorLogList.value?.onShow(obj.idCon);
}

function del(idCon) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ConnectorDelete();
      api.post({idCon: idCon}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function startTranscation(idCon) {
  loading.value = true
  const api = new ConnectorStartTranscation();
  api.post({idCon: idCon}).then((response) => {
    loading.value = false
    if (0 === parseInt(response.data.code)) {
      get_list();
      message.success(i18n.t("succeedMsg.0"));
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function stopTranscation(idCon) {
  loading.value = true
  const api = new ConnectorStopTranscation();
  api.post({idCon: idCon}).then((response) => {
    loading.value = false
    if (0 === parseInt(response.data.code)) {
      get_list();
      message.success(i18n.t("succeedMsg.0"));
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

// 获取区域下拉列表
function getRegionSelectList() {
  const api = new RegionGetSelectList
  api.get().then(res => {
    if (0 === parseInt(res.data.code)) {
      regOptions.value = res.data.data
    }
  })
}

// 获取电表下拉列表
function getMeterList() {
  const api = new MeterGetSelectListByidReg();
  api.get({idReg: searchForm.value.idReg}).then(res => {
    if (0 === parseInt(res.data.code)) {
      metOptions.value = res.data.data;
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  });
}

// 获取电桩下拉列表
function getChargingStation() {
  const api = new ChargingStationGetSelectList();
  api.get({
    ...searchForm.value
  }).then(res => {
    if (0 === parseInt(res.data.code)) {
      chaOptions.value = res.data.data;
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  });
}

// 当区域列表发生变化时，重新获取电表下拉列表
function handleRegChange() {
  searchForm.value.idMet = undefined
  searchForm.value.idChaPoi = undefined
  getMeterList()
  getChargingStation()
}

// 当电表下拉列表发生改变时，查询获取电桩下拉列表
function handleMetChange() {
  searchForm.value.idChaPoi = undefined
  getChargingStation()
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.ConnectorManage'));
get_list()

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
