<template>
  <Modal id="CarDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('car.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('car.field.id_car')">{{data.idCar}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.id_use')">{{data.userAccount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{data.userPhone}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.model')">{{data.model}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.car_license')">{{data.carLicense}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.max_years_old')">{{data.maxYearsOld}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.manufacture_year')">{{data.manufactureYear}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.maximum_power')">{{data.maximumPower}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.is_second_hand')">{{booleanList.find(item=>item.value===data.isSecondHand).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.vehicle_inspection_date')">{{data.vehicleInspectionDate}}</DescriptionsItem>
      <DescriptionsItem :label="$t('car.field.insurance_expiry_date')">{{data.insuranceExpiryDate}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('car.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "CarDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

let visible = ref(false)
const booleanList = [{value: 0,label: i18n.t("public.isTrue.0")},{value: 1,label: i18n.t("public.isTrue.1")}]

let data = ref({})

async function detailShow(obj) {
  data.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;
}
defineExpose({
  detailShow
})
</script>

<style lang="less">
</style>