
import {createI18n} from "vue-i18n"
import zhTW from "@/i18n/zhTW"
import enUS from "@/i18n/enUS"
import zhCN from "@/i18n/zhCN"
// danish
import daDK from "@/i18n/daDK"
// indonesian
import idID from "@/i18n/idID"
// malay
import msMY from "@/i18n/msMY"
// thai
import thTH from "@/i18n/thTH";
// vietnamese
import viVN from "@/i18n/viVN";
// fr-FR
import frFR from "@/i18n/frFR";
// pl-PL
import plPL from "@/i18n/plPL";
// it
import it from "@/i18n/it";

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "enUS",
    fallbackLocale: "enUS",
    messages: {
        zhTW,
        enUS,
        zhCN,
        daDK,
        idID,
        msMY,
        thTH,
        viVN,
        frFR,
        plPL,
        it,
    }
})
export default i18n