const idID = {
    'succeedMsg': {
        '0': 'Berhasil',
    },
    'errorMsg': {
        '500100': 'Server-side exception',
        '500101': 'Parameter error',
        '500102': 'Parameter validation exception',
        '500103': 'The request is illegal',
        '500104': 'Kunjungan terlalu sering',
        '500105': 'Object already exists',
        '500106': 'Operasi gagal, Coba lagi',
        '500107': 'There is an association, deletion is prohibited',
        '500108': 'Export exceptions',
        '500200': 'Login expired, please log in again',
        '500201': 'The account number or password cannot be empty',
        '500202': 'The phone number cannot be empty',
        '500203': 'The phone number is in the wrong format',
        '500204': 'The phone number does not exist',
        '500205': 'Kata sandi salah',
        '500206': 'The account does not exist',
        '500207': 'Account numbers can only contain numbers, English, and underscores',
        '500208': 'Authentication failed',
        '500209': 'The two passwords are not the same',
        '500210': 'Duplicate accounts',
        '500211': 'The account has been locked',
        '500212': 'Captcha failed',
        '500213': 'Nomor teleponnya salah',
        '500214': 'There is no tenant corresponding to the domain name, contact your administrator',
        '500215': 'exists the same role name',
        '500300': 'Jika layanan OCCP abnormal, hubungi administrator',
        '500301': 'Electric piles are not available',
        '500302': 'Konfigurasi tiang kosong',
        '500303': 'The battery is offline, please check if it is available',
        '500304': 'Port tiang tidak tersedia',
        '500305': 'Pistol tidak dimasukkan atau pistol saat ini belum siap',
        '500306': 'Start charging failure',
        '500307': 'Kegagalan pengisian yang dibundel',
        '500401': 'Insufficient balance',
        '500402': 'Urutan tidak ada',
        '500403': 'The order was not paid',
        '500404': 'Pesanan telah dibayar',
        '500405': 'Status pesanan tidak [dibayar tidak digunakan]',
        '500406': 'Penciptaan perintah gagal',
        '500407': 'Ada perintah yang belum dibayar',
        '500408': 'Perintah dibatalkan',
        '500501': 'The item has been sold in seconds',
        '500502': 'Penawaran Petir tidak dapat diulangi',
        '500503': 'Kegagalan pembunuhan kedua',
        '500601': 'File tidak bisa kosong',
        '500602': 'Kesalahan Format File',
        '500603': 'The Excel workbook is empty',
        '500604': 'Gagal Membaca File'
    },
    'public': {
        'name': {
            'nextStep': 'Langkah berikutnya',
            'status': 'Negara',
            'hello': 'Halo',
            'login': 'Gabung',
            'logout': 'Keluar',
            'username': 'Nama belakang',
            'email': 'Surel',
            'password': 'Kata sandi',
            'remember': 'Ingat aku',
            'currentPassword': 'Kata Sandi Saat Ini',
            'newPassword': 'Kata Sandi Baru',
            'confirm_password': 'konfirmasi sandi',
            'reset_password': 'Setel ulang kata sandi',
            'captcha': 'Captcha',
            'getCaptcha': 'Dapatkan Captcha',
            'more': 'Lagi',
            'add': 'Menambahkan',
            'batchDel': 'Hapus Batch',
            'Export': 'Ekspor',
            'Import': 'Import',
            'DownloadTemp': 'Muat Turun Templat',
            'ImportTips': 'Klik atau seret berkas ke daerah ini untuk mengunggah',
            'edit': 'Edit',
            'del': 'Menghapus',
            'detail': 'Detail',
            'yes': 'Ya',
            'no': 'Tidak',
            'empty': 'Kosong',
            'are_you_sure_delete': 'Apakah Anda yakin ingin menghapus item ini',
            'action_cannot_resumed': 'Tindakan ini tidak dapat dilanjutkan',
            'are_you_sure_operate': 'Apakah Anda yakin tentang operasi saat ini',
            'operate_remind': 'Operasi saat ini sensitif, silakan berhati-hati',
            'search': 'Mencari',
            'reset': 'Mengatur ulang',
            'spread': 'Menyebar',
            'retract': 'Menarik',
            'show': 'Menunjukkan',
            'no_page': 'Tidak ada halaman',
            'service': 'Melayani',
            'phone_country_code': 'Kode negara telepon',
            'name': 'Nama',
            'phone': 'Telepon',
            'sex': 'Seks',
            'country': 'Negara',
            'address': 'Alamat',
            'birthday': 'Hari ulang tahun',
            'remark': 'Komentar',
            'personal': 'Pribadi',
            'enclosure': 'Lampiran',
            'logo': 'Logo',
            'submit': 'Kirim',
            'unknown': 'Tidak dikenal',
            'setting': 'Mengatur',
            'index': 'Nomor seri',
            'createBy': 'Dibuat oleh',
            'createTime': 'Buat waktu',
            'updateBy': 'Perbarui oleh',
            'updateTime': 'Perbarui waktu',
            'type': 'Jenis',
            'args': 'Parameter',
            'qrcode': 'Kode QR',
            'query': 'Pertanyaan',
            'refund': 'Refund',
            'cancel': 'Batalkan',
            'revoke': 'Batalkan',
        },
        'msg': {
            'unRead': 'Tidak Dibaca',
            'read': 'Baca',
            'alarm': 'Alarm',
            'reminder': 'Ingatan',
            'advertise': 'pengumuman'
        },
        'language': {
            'zhTW': 'Cina tradisional)',
            'enUS': 'Bahasa inggris',
            'zhCN': 'Bahasa Tiongkok yang disederhanakan)',
            'daDK': 'Denmark',
            'idID': 'Indonesia',
            'msMY': 'Melayu',
            'thTH': 'Thai',
            'viVN': 'Vietnam',
            'frFR': 'Prancis',
            'plPL': 'Polandia',
            'it': 'Italia',
        },
        'timeType': {
            0: 'Tahun',
            1: 'Bulan',
            2: 'Minggu',
            3: 'Hari',
        },
        'promptsToSelectTime': 'Silakan pilih waktu',
        'pleaseSelect': 'Silakan pilih',
        'pileStatus': {
            0: 'Tersedia',
            1: 'Pengisian',
            2: 'Tilgængelig',
        },
        'isTrue': {'0': 'Tidak', '1': 'Ya'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'Surel', '1': 'Telepon'},
        'doingStatus':{
            '0': 'Menunggu',
            '1': 'Memproses',
            '2': 'Selesai',
            '3': 'Batalkan',
            '4': 'Mati',
            '5': 'Penghentian'
        },
        'consumeType': {
            '0': "Isi ulang",
            '1': "Memuatkan Konsum",
            '2': "Memuatkan Pembatalan",
            '3': "Balance",
            '4': 'Rencana Pembelian',
            '5': 'Batalkan Rencana',
            '6': 'Rencana Pembaharuian',
            '7': 'refund',
            '8': 'Catatan hukuman',
            '9': 'Batal denda',
            '10': 'Pengunduran',
            '11': 'Hadiah penuh',
            '12': 'Batalkan',
        },
        'payStatus': {'0': 'Tidak dibayar', '1': 'Dibayar', '2': 'Pengisian daya', '3': 'Lengkap', '4': 'Membatalkan'},
        'chargeType': {'0': 'Oleh waktu', '1': 'Atas perintah', '2': 'Berdasarkan rencana pengguna'},
        'status': {'0': 'Tidak dikenal', '1': 'Normal', '2': 'Cacat', '3': 'Menghapus'},
        'sex': {'1': 'Manusia', '2': 'Wanita', '3': 'Tidak dikenal'},
        'placeholder': {'please_select': 'Silahkan pilih'},
        'table': {
            'name': 'Nama',
            'status': 'Negara',
            'action': 'Tindakan',
            'content': 'Isi',
            'created_at': 'Buat waktu',
            'image': 'Gambar',
            'email': 'Surel',
            'order_id': 'Memesan',
            'sex': 'Seks',
            'last_ip': 'IP terakhir',
            'phone_country_code': 'Pilih Kode Negara Telepon',
            'phone': 'Telepon'
        },
        'field': {
            'name': 'Nama',
            'username': 'Nama belakang',
            'content': 'Isi',
            'phone_country_code': 'Kode negara telepon',
            'phone': 'Telepon',
            'email': 'Surel',
            'order_id': 'Memesan',
            'portrait': 'Potret',
            'status': 'Negara',
            'image': 'Gambar',
            'sex': 'Seks'
        },
        'validate': {
            'required': {
                'id': 'Kurang id',
                'username': 'Harap masuk nama pengguna',
                'password': 'Harap masuk kata sandi',
                'repassword': 'Harap ulangi kata sandi',
                'name': 'Mohon Nama Masuk',
                'phone': 'Mohon masuk telepon',
                'email': 'Harap masuk email',
                'portrait': 'Harap unggah potret'
            },
            'tips': {
                'inconsistent_password': 'Kata sandi yang tidak konsisten',
                'length_should': 'Panjang harus {min} ke {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Cina tradisional)',
        'enUS': 'Bahasa inggris',
        'zhCN': 'Bahasa Tiongkok yang disederhanakan)',
        'daDK': 'Denmark',
        'idID': 'Indonesia',
        'msMY': 'Melayu',
        'thTH': 'Thai',
        'viVN': 'Vietnam',
        'frFR': 'Prancis',
        'plPL': 'Polandia',
        'it': 'Italia',
    },
    'nav': {
        'name': {
            'Dashboard': 'Dasbor',
            'RegionDashboard': 'Penjelasan tumpukan',
            'BigScreen': 'Monitor layar besar',
            'AuthorityManage': 'Kelola otoritas',
            'superTenantManage': 'Super Tenant',
            'BalanceLogManage': 'Saldo Kelola Log',
            'CarManage': 'Mengelola mobil',
            'CarImagesManage': 'Managing Manages Mobil',
            'ChargeStationImagesManage': 'Titik Besar Imagemanage',
            'ChargingStationManage': 'Tagihan PointanMenage',
            'ConnectorManage': 'Connector mengelola',
            'EmployeeManage': 'Kelola operator',
            'ConnectorLogManage': 'Log Konektor',
            'EventLogManage': 'Acara Kelola Log',
            'MeterManage': 'Pengelola meter',
            'OrderManage': 'Kelola Pesanan',
            'PlanManage': 'Rencana mengelola',
            'RegionManage': 'Kelola wilayah',
            'RegionImagesManage': 'Kelola Gambar Wilayah',
            'RepairLogManage': 'Perbaikan Kelola Log',
            'RoleManage': 'Kelola Peran',
            'TransactionLogManage': 'Kelola Log transaksi',
            'UserManage': 'Mengelola pengguna',
            'UserPlanManage': 'Pengguna PlantanManage',
            'GetConfiguration': 'Dapatkan konfigurasi',
            'DataTransfer': 'Transfer data',

            'NoticeManage': 'Pemberitahuan Kelola',
            'PublicityManage': 'Pengelolaan Publisitas',
            'ChargerSetting': 'Pengaturan Charger',
            'StatisticsManage': 'Statistik',

            'TenantManagement': 'Manajemen Sistem',
            'TenantManage': 'Pengurusan Tenant',
            'SuperTenantManage': "Super Tenant",
            'TenantInfo': 'Pengurusan Tenant',
            'TenantPackage': 'Paket Tenant',
            'CardManage': 'Manajemen nomor kartu',

            'ServiceManage': 'Kelola Layanan',

            'AppManagement': 'Manajemen aplikasi',
            'LogManagement': 'Manajemen Kelola Log',
            'AppVersionManage': 'Versi Aplikasi Kelola',
            'WallerManage': 'Batas akun',
            'iPadManagement': 'Manajemen iPad',
            'iPadManage': 'ipad kelola'
        },
        'table': {'order_id': 'Id pemesanan', 'url': 'Url'},
        'field': {
            'pid': 'Pid',
            'order_id': 'Id pemesanan',
            'name': 'Nama',
            'url': 'Url',
            'status': 'Negara',
            'icon': 'Ikon'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Mohon masuk nama pengguna',
                'email': 'Mohon masuk email',
                'password': 'Harap masuk kata sandi',
                'captcha': 'Mohon masuk captcha'
            }
        }
    },
    'authority': {
        'name': {'title': 'Wewenang', 'detailTitle': 'Detail Otoritas', 'editTitle': 'Edit Otoritas'},
        'table': {
            'id_aut': 'PENGENAL',
            'a_id_aut': 'Otoritas induk',
            'name': 'Wewenang',
            'resource_name': 'Nama Sumberdaya',
            'type': 'Jenis',
            'sort': 'Menyortir'
        },
        'field': {
            'id_aut': 'PENGENAL',
            'a_id_aut': 'Otoritas induk',
            'name': 'Wewenang',
            'resource_name': 'Nama Sumberdaya',
            'type': 'Jenis',
            'sort': 'Menyortir'
        },
        'validate': {
            'required': {
                'id_aut': 'ID diperlukan',
                'a_id_aut': 'Otoritas induk diperlukan',
                'name': 'Otoritas diperlukan',
                'resource_name': 'Nama sumber daya diperlukan',
                'type': 'Jenis yang dibutuhkan',
                'sort': 'Sortir diperlukan'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Isi ulang Pakej',
        'addRule': 'Tambah Peraturan',
        'gift': {
            'enable': 'Apakah diaktifkan',
            'percentage': 'Menurut persentase',
            'star': 'Nilai awal',
            'end': 'Nilai akhir',
            'give': 'Batas',
        },
        'vGift': {
            'enable': 'Silakan pilih apakah untuk mengaktifkannya atau tidak',
            'percentage': 'Silakan pilih apakah untuk menggunakan persentase atau tidak',
            'star': 'Silakan masukkan nilai awal',
            'end': 'Silakan masukkan nilai akhir',
            'give': 'Silakan masukkan batas kredit',
        },
        'name': {
            'title': 'Log Balance',
            'detailTitle': 'Saldo Detail Log',
            'editTitle': 'Edit Log Balance',
            'ticket': 'Catatan hukuman',
            'cancelFines': 'Batal denda'
        },
        'table': {
            'id_bal_log': 'PENGENAL',
            'id_use': 'Pengguna',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'log_time': 'Waktu log',
            'tenant_key': 'Tenant Key',
            'status': 'Status',
            'payment_method': 'Cara Pembayaran'
        },
        'field': {
            'id_bal_log': 'PENGENAL',
            'id_use': 'Pengguna',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'log_time': 'Waktu log',
            'tenant_key': 'Kunci Penyewa',
            'status': 'Status',
            'payment_method': 'Cara Pembayaran'
        },
        'validate': {
            'required': {
                'id_bal_log': 'ID diperlukan',
                'id_use': 'Diperlukan',
                'type': 'Jenis yang dibutuhkan',
                'amount': 'Jumlah yang dibutuhkan',
                'log_time': 'Waktu log diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa',
                'status': 'Status diperlukan',
                'payment_method': 'Metode pembayaran diperlukan'
            }
        }
    },
    'car': {
        'name': {'title': 'Mobil', 'detailTitle': 'Detail Mobil', 'editTitle': 'Edit mobil'},
        'table': {
            'id_car': 'PENGENAL',
            'id_use': 'Pengguna',
            'model': 'Model',
            'car_license': 'Lisensi',
            'max_years_old': 'Tahun maksimal',
            'manufacture_year': 'Tahun pembuatan',
            'maximum_power': 'Daya maksimum',
            'is_second_hand': 'Adalah tangan kedua',
            'vehicle_inspection_date': 'Tanggal Pemeriksaan Kendaraan',
            'insurance_expiry_date': 'Tanggal kedaluwarsa asuransi',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_car': 'PENGENAL',
            'id_use': 'Pengguna',
            'model': 'Model',
            'car_license': 'Lisensi',
            'max_years_old': 'Tahun maksimal',
            'manufacture_year': 'Tahun pembuatan',
            'maximum_power': 'Daya maksimum',
            'is_second_hand': 'Adalah tangan kedua',
            'vehicle_inspection_date': 'Tanggal Pemeriksaan Kendaraan',
            'insurance_expiry_date': 'Tanggal kedaluwarsa asuransi',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_car': 'ID diperlukan',
                'id_use': 'Diperlukan',
                'model': 'Model diperlukan',
                'car_license': 'Lisensi diperlukan',
                'max_years_old': 'Max tahun diperlukan',
                'manufacture_year': 'Diperlukan tahun pembuatan',
                'maximum_power': 'Daya maksimum diperlukan',
                'is_second_hand': 'Diperlukan tangan kedua',
                'vehicle_inspection_date': 'Tanggal Inspeksi Kendaraan Diperlukan',
                'insurance_expiry_date': 'Tanggal kedaluwarsa asuransi diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Gambar mobil',
            'detailTitle': 'Detail gambar mobil',
            'editTitle': 'Edit gambar mobil'
        },
        'table': {
            'id_car_ima': 'PENGENAL',
            'id_car': 'Mobil',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_car_ima': 'PENGENAL',
            'id_car': 'Mobil',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_car_ima': 'ID diperlukan',
                'id_car': 'Dibutuhkan mobil',
                'url': 'URL diperlukan',
                'upload_time': 'Upload Time Required',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Gambar Titik Biaya',
            'detailTitle': 'Detail Gambar Titik Biaya',
            'editTitle': 'Edit gambar titik biaya'
        },
        'table': {
            'id_ima': 'PENGENAL',
            'id_cha_poi': 'Titik Biaya',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_ima': 'PENGENAL',
            'id_cha_poi': 'Titik Biaya',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_ima': 'ID diperlukan',
                'id_cha_poi': 'Diperlukan titik biaya',
                'url': 'URL diperlukan',
                'upload_time': 'Diperlukan waktu unggah',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Tetapkan urutan pembayaran perintah muatan, lalai ke pengaturan regional",
            'billingMethod': "Tetapkan metode penghitungan perintah muatan, lalai untuk ditetapkan mengikuti daerah",
        },
        'billingMethodType': {
            '0':"Menurut Waktu",
            '1':"Dengan kekuatan",
            '2':"By User Plan",
            '3':"Oleh APP",
            '4':"Menurut Daerah",
        },
        'updateFirmware': {
            'identifier': "Nomor seri",
            'location': "Alamat jarak jauh",
            'retrieveDate': "Tanggal pengambilan",
        },
        'uValidate': {
            'identifier': "Silakan masukkan nomor seri",
            'location': "Silakan masukkan alamat jarak jauh",
            'retrieveDate': "Silakan pilih tanggal pencarian",
        },
        'setting': {
            'index': "nomor seri",
            'readonly': "Baca-saja",
            'key': "Mengatur kunci",
            'value': "Tetapkan nilainya",
        },
        'settingField': {
            'readonly': "Pilih apakah itu baca-saja",
            'key': "Kunci set tidak boleh kosong",
            'value': "Nilai pengaturan tidak boleh kosong",
        },
        'name': {
            'title': 'Titik Biaya',
            'detailTitle': 'Detail Titik Biaya',
            'editTitle': 'Edit Titik Biaya',
            'quickAdd': 'Tambahkan Titik Biaya'
        },
        'table': {
            'id_cha_poi': 'PENGENAL',
            'id_met': 'Meter',
            'charge_box_id': 'Charge Box Id',
            'firmwareVersion': 'Versi firmware',
            'last_heartbeat_time': 'Waktu detak jantung terakhir',
            'monthly_fee': 'Biaya bulanan',
            'charger_type': 'Jenis pengisi daya',
            'tenant_key': 'Kunci Penyewa',
            'name': 'Nama',
            'heartbeat_interval': 'Interval detak jantung',
            'enable': 'Memungkinkan',
            'maxCurrent': "Arus maksimum",
            'minCurrent': "Minimum saat ini",
            'isPrivate': "Pribadi atau tidak",
            'isReadMeter': "Apakah untuk membaca tabel",
            'isReset': "Apakah memulai ulang (memulai ulang dapat mengambil efek)",
            'action': {
                'updateFirmware': "Peningkatan firmware",
                'hardReset': "Hard Reset",
                'softReset': "Reset Lembut"
            }
        },
        'validate': {
            'id_cha_poi': 'ID diperlukan',
            'id_met': 'Meter diperlukan',
            'charge_box_id': 'ID kotak pengisian daya diperlukan',
            'last_heartbeat_time': 'Waktu detak jantung terakhir dibutuhkan',
            'monthly_fee': 'Biaya bulanan diperlukan',
            'charger_type': 'Jenis pengisi daya diperlukan',
            'tenant_key': 'Diperlukan kunci penyewa',
            'name': 'Nama (wajib',
            'heartbeat_interval': 'Interval detak jantung diperlukan',
            'enable': 'Aktifkan diperlukan',
            'maxCurrent': "Silakan isi arus maksimum",
            'minCurrent': "Silakan isi arus minimum",
        }
    },
    'connector': {
        'name': {
            'title': 'Penyambung',
            'detailTitle': 'Detail konektor',
            'editTitle': 'Edit konektor',
            'startTransaction': 'Mulai transaksi',
            'stopTransaction': 'Hentikan transaksi'
        },
        'table': {
            'id_con': 'PENGENAL',
            'id_cha_poi': 'Titik Biaya',
            'name': 'Nama',
            'connector_id': 'ID konektor',
            'status': 'Status',
            'tenant_key': 'Kunci Penyewa',
            'power': 'Kekuasaan(kw)',
            'type': 'Jenis(kw)'
        },
        'field': {
            'id_con': 'PENGENAL',
            'id_cha_poi': 'Titik Biaya',
            'name': 'Nama',
            'connector_id': 'ID konektor',
            'status': 'Status',
            'tenant_key': 'Kunci Penyewa',
            'power': 'Kekuasaan(kw)',
            'type': 'Jenis'
        },
        'validate': {
            'required': {
                'id_con': 'ID diperlukan',
                'id_cha_poi': 'Diperlukan titik biaya',
                'name': 'Nama (wajib',
                'connector_id': 'ID konektor diperlukan',
                'status': 'Status diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa',
                'power': 'Daya diperlukan(kw)',
                'type': 'Jenis yang dibutuhkan'
            }
        }
    },
    'employee': {
        'name': {'title': 'Operator', 'detailTitle': 'Detail Operator', 'editTitle': 'Edit operator'},
        'table': {
            'id_emp': 'PENGENAL',
            'id_rol': 'Wewenang',
            'account': 'Akun',
            'password': 'Kata sandi',
            'salt': 'Garam',
            'last_login_time': 'Waktu login terakhir',
            'register_time': 'Waktu mendaftar',
            'login_failure_counter': 'Penghitung kegagalan login',
            'email': 'Surel',
            'whatsAppPhone': 'WhatsApp Angka',
            'remark': 'Komentar',
            'status': 'Status',
            'name': 'Nama',
            'commission_rate': 'Tarif komisi',
            'yedpay_api_key': 'Kunci API Yedpay',
            'yedpay_sign_key': 'Kunci tanda Yedpay',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_emp': 'PENGENAL',
            'id_rol': 'Wewenang',
            'account': 'Akun',
            'password': 'Kata sandi',
            'salt': 'Garam',
            'last_login_time': 'Waktu login terakhir',
            'register_time': 'Waktu mendaftar',
            'login_failure_counter': 'Penghitung kegagalan login',
            'email': 'Surel',
            'whatsAppPhone': 'WhatsApp Angka',
            'remark': 'Komentar',
            'status': 'Status',
            'name': 'Nama',
            'commission_rate': 'Tarif komisi',
            'yedpay_api_key': 'Kunci API Yedpay',
            'yedpay_sign_key': 'Kunci tanda Yedpay',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_emp': 'ID diperlukan',
                'id_rol': 'Diperlukan peran',
                'account': 'Akun diperlukan',
                'password': 'Kata sandi yang dibutuhkan',
                'salt': 'Diperlukan garam',
                'last_login_time': 'Waktu login terakhir diperlukan',
                'register_time': 'Daftar waktu yang diperlukan',
                'login_failure_counter': 'Penghitung Gagal Masuk Diperlukan',
                'email': 'Email diperlukan',
                'whatsAppPhone': 'Silakan isi Nomor WhatsApp',
                'remark': 'Komentar diperlukan',
                'status': 'Status diperlukan',
                'name': 'Nama (wajib',
                'commission_rate': 'Tarif komisi diperlukan',
                'yedpay_api_key': 'Kunci API Yedpay diperlukan',
                'yedpay_sign_key': 'Kunci tanda yedpay diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'eventLog': {
        'name': {'title': 'Log acara', 'detailTitle': 'Detail Log Acara', 'editTitle': 'Edit Log Acara'},
        'table': {
            'id_env_log': 'PENGENAL',
            'run_time': 'Waktu Eksekusi(ms)',
            'class_method': 'Metode Eksekusi',
            'event_time': 'Waktu acara',
            'log': 'Catatan',
            'tenant_key': 'Kunci Penyewa',
            'resCode': 'Kode respon',
        },
        'field': {
            'id_env_log': 'PENGENAL',
            'event_time': 'Waktu acara',
            'log': 'Catatan',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_env_log': 'ID diperlukan',
                'class_method': 'Please fill in the execution method',
                'event_time': 'Waktu acara diperlukan',
                'log': 'Log diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'meter': {
        'name': {'title': 'Meter', 'detailTitle': 'Detail meter', 'editTitle': 'Edit meter'},
        'table': {
            'id_met': 'PENGENAL',
            'id_reg': 'Wilayah',
            'name': 'Nama meter',
            'current_threshold': 'Ambang saat ini',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_met': 'PENGENAL',
            'id_reg': 'Wilayah',
            'name': 'Nama meter',
            'current_threshold': 'Ambang saat ini',
            'tenant_key': 'Kunci Penyewa',
            'loadByTactics': 'Mengaktifkan kebijakan'
        },
        'validate': {
            'required': {
                'id_met': 'ID diperlukan',
                'id_reg': 'Wilayah diperlukan',
                'name': 'Nama meter diperlukan',
                'current_threshold': 'Dibutuhkan ambang saat ini',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Memesan',
            'detailTitle': 'Detail pesanan',
            'editTitle': 'Edit pesanan',
            'cancel': 'Batal Order',
            'cancelAndRefund': 'Batalkan pesanan dan kembalian',
        },
        'table': {
            'id_ord': 'PENGENAL',
            'id_car': 'Mobil',
            'id_con': 'Penyambung',
            'order_time': 'Waktu pesanan',
            'type': 'Jenis',
            'amount': 'Jumlah',
            'charge_start_time': 'Mengisi waktu mulai',
            'charge_end_time': 'Waktu akhir yang diharapkan',
            'chargeEndTimeActual': 'Waktu akhir sebenarnya',
            'status': 'Status',
            'payment_time': 'Waktu pembayaran',
            'electricity': 'Beli listrik',
            'charge_minutes': 'Durasi pembelian',
            'currentElectricity': "Listrik saat ini",
            'ref_id_pla': 'Rencana',
            'price': 'Harga',
            'commission_rate': 'Tarif komisi',
            'tenant_key': 'Kunci Penyewa',
            'id_tra_log': 'TransactionLogid',

            'pay_type': 'PayType',
            'payment_method': 'Cara Pembayaran'
        },
        'validate': {
            'id_ord': 'ID diperlukan',
            'id_car': 'Dibutuhkan mobil',
            'id_con': 'Diperlukan konektor',
            'order_time': 'Waktu pesanan diperlukan',
            'type': 'Jenis yang dibutuhkan',
            'amount': 'Jumlah yang dibutuhkan',
            'charge_start_time': 'Diperlukan Waktu Mulai Biaya',
            'charge_end_time': 'Dibutuhkan waktu akhir yang dibutuhkan',
            'status': 'Status diperlukan',
            'payment_time': 'Waktu pembayaran diperlukan',
            'electricity': 'Diperlukan listrik',
            'ref_id_pla': 'Rencana diperlukan',
            'price': 'Harga dibutuhkan',
            'commission_rate': 'Tarif komisi diperlukan',
            'tenant_key': 'Diperlukan kunci penyewa',
            'id_tra_log': 'Diperlukan TransactionLogid',
            'charge_minutes': 'Diperlukan chargeminutes',
            'pay_type': 'PayType diperlukan',
            'payment_method': 'Metode pembayaran diperlukan'
        }
    },
    'plan': {
        'name': {'title': 'Rencana', 'detailTitle': 'Merencanakan detail', 'editTitle': 'Edit rencana'},
        'table': {
            'id_pla': 'PENGENAL',
            'name': 'Nama rencana',
            'price_per_month': 'Harga',
            'contract_period': 'Masa kontrak',
            'free_charging_time_limit': 'Waktu pengisian gratis',
            'overtime_charge_price': 'Harga lembur',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_pla': 'PENGENAL',
            'name': 'Nama rencana',
            'price_per_month': 'Harga',
            'contract_period': 'Masa kontrak',
            'free_charging_time_limit': 'Waktu pengisian gratis',
            'overtime_charge_price': 'Harga lembur',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_pla': 'ID diperlukan',
                'name': 'Nama rencana diperlukan',
                'price_per_month': 'Harga dibutuhkan',
                'contract_period': 'Periode kontrak diperlukan',
                'free_charging_time_limit': 'Diperlukan waktu pengisian gratis',
                'overtime_charge_price': 'Harga lembur diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Ukuran langkah setiap lompatan dihitung sebagai satu lompatan jika itu kurang dari satu lompatan. Jika itu dihitung berdasarkan konsumsi listrik sebenarnya dari perintah, silakan tetapkan ke nol.",
            'byTimeUnit': "Ukuran langkah setiap lompatan dihitung sebagai satu lompatan jika itu kurang dari satu lompatan. Jika itu dihitung berdasarkan waktu sebenarnya dari perintah, silakan tetapkan ke nol.",

            'maximumPower': "Ketika memuat menurut tingkat baterai, batas atas satu muatan tidak terbatas jika kiri kosong atau nol, dan tidak terbatas secara lalai",
            'maximumMinute': "Ketika mengisi menurut waktu, batas atas waktu mengisi satu waktu ditinggalkan kosong atau nol, yang tidak terbatas secara lalai",
        },
        'billingMethodType':{
            '0': "Pada waktu",
            '1': "Dengan kekuatan",
            '2': "Tekan APP",
        },
        'name': {'title': 'Wilayah', 'detailTitle': 'Detail Wilayah', 'editTitle': 'Edit wilayah'},
        'table': {
            'id_reg': 'PENGENAL',
            'name': 'Nama Wilayah',
            'ac_price_per_electricity': 'Harga AC per listrik',
            'ac_price_per_minute': 'Harga AC per menit',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Alamat',
            'dc_price_per_electricity': 'Harga DC per listrik',
            'dc_price_per_minute': 'Harga DC per menit',
            'tenant_key': 'Kunci Penyewa',
            'businessAllDay': 'Buka Sepanjang Hari',
            'images': 'Gambar-gambar',
            'byPowerUnit': "Unit pengukuran",
            'byTimeUnit': "Menurut waktu Unit pengukuran",
            'postpaid': "Postpaid",
            'billingMethod': "Metode penghitungan",
            'businessHours': "Jam bisnis",

            'maximumPower': "Batas atas kapasitas muatan tunggal",
            'maximumMinute': "Batas atas waktu pencarian tunggal",
            'directDebit': "Batas atas waktu pencarian tunggal",

            'enableTimeoutFine': "Membuka hukuman kehabisan waktu",
            'timeoutFine': "Waktu habis waktu(min)",
            'timeoutCharging': "Pembayaran Overtime",
        },
        'validate': {
            'id_reg': 'ID diperlukan',
            'name': 'Nama Wilayah Diperlukan',
            'ac_price_per_electricity': 'Harga AC per listrik diperlukan',
            'ac_price_per_minute': 'Harga AC per menit diperlukan',
            'lon': 'Diperlukan lon',
            'lat': 'Lat diperlukan',
            'address': 'Alamat diperlukan',
            'dc_price_per_electricity': 'Harga DC per listrik diperlukan',
            'dc_price_per_minute': 'Harga DC per menit diperlukan',
            'tenant_key': 'Diperlukan kunci penyewa',
            'businessAllDay': 'Silakan pilih apakah buka sepanjang hari',
            'byPowerUnit': "Silakan isi Unit pengukuran berdasarkan kuantitas listrik",
            'byTimeUnit': "Silakan isi Unit pengukuran dengan waktu",
            'postpaid': "Silakan pilih apakah harus membayar setelah",
            'billingMethod': "Silakan pilih metode penghitungan",
            'businessHours': "Pilih jam bisnis",

            'maximumPower': "Pengurangan otomatis",
            'maximumMinute': "Silakan isi batas atas waktu muatan tunggal",
            'directDebit': "Silakan memilih apakah secara otomatis menurunkan pembayaran",

            'enableTimeoutFine': "Silakan pilih apakah untuk memungkinkan hukuman kehabisan waktu",
            'timeoutFine': "Silakan isi periode kehabisan waktu(min)",
            'timeoutCharging': "Silakan mengisi tagihan waktu tambahan",
        }
    },
    'regionImages': {
        'name': {
            'title': 'Gambar wilayah',
            'detailTitle': 'Detail Gambar Wilayah',
            'editTitle': 'Edit gambar wilayah'
        },
        'table': {
            'id_reg_img': 'PENGENAL',
            'id_reg': 'Wilayah',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_reg_img': 'PENGENAL',
            'id_reg': 'Wilayah',
            'url': 'Url',
            'upload_time': 'Waktu unggah',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_reg_img': 'ID diperlukan',
                'id_reg': 'Wilayah diperlukan',
                'url': 'URL diperlukan',
                'upload_time': 'Diperlukan waktu unggah',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Log perbaikan',
            'detailTitle': 'Perbaiki detail log',
            'editTitle': 'Edit Log Perbaikan'
        },
        'table': {
            'id_rep_log': 'PENGENAL',
            'id_car': 'Mobil',
            'amount': 'Jumlah',
            'repair_date': 'Tanggal perbaikan',
            'remark': 'Komentar',
            'tenant_key': 'Kunci Penyewa'
        },
        'field': {
            'id_rep_log': 'PENGENAL',
            'id_car': 'Mobil',
            'amount': 'Jumlah',
            'repair_date': 'Tanggal perbaikan',
            'remark': 'Komentar',
            'tenant_key': 'Kunci Penyewa'
        },
        'validate': {
            'required': {
                'id_rep_log': 'ID diperlukan',
                'id_car': 'Dibutuhkan mobil',
                'amount': 'Jumlah yang dibutuhkan',
                'repair_date': 'Tanggal perbaikan diperlukan',
                'remark': 'Komentar diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa'
            }
        }
    },
    'role': {
        'name': {'title': 'Wewenang', 'detailTitle': 'Detail Peran', 'editTitle': 'Edit peran'},
        'table': {'id_rol': 'PENGENAL', 'name': 'Wewenang', 'remark': 'Komentar'},
        'field': {'id_rol': 'PENGENAL', 'name': 'Wewenang', 'remark': 'Komentar', 'authorityList': 'Daftar Otoritas'},
        'validate': {
            'required': {
                'id_rol': 'ID diperlukan',
                'name': 'Diperlukan peran',
                'remark': 'Komentar diperlukan'
            }
        }
    },
    'roleAuthority': {
        'name': {
            'title': 'Roleauthority',
            'detailTitle': 'Detail Roleauthority',
            'editTitle': 'Edit Roleauthority'
        },
        'table': {'id_rol_aut': 'PENGENAL', 'id_rol': 'Wewenang', 'id_aut': 'Wewenang'},
        'field': {'id_rol_aut': 'PENGENAL', 'id_rol': 'Wewenang', 'id_aut': 'Wewenang'},
        'validate': {
            'required': {
                'id_rol_aut': 'ID diperlukan',
                'id_rol': 'Diperlukan peran',
                'id_aut': 'Otoritas diperlukan'
            }
        }
    },
    'transactionLog': {
        'name': {'title': 'Log transaksi', 'detailTitle': 'Detail log transaksi', 'editTitle': 'Edit log transaksi'},
        'table': {
            'id_tra_log': 'PENGENAL',
            'id_con': 'Connector IDT',
            'event_time': 'Waktu acara',
            'id_tag': 'Tag ID',
            'start_time': 'Waktu mulai',
            'start_value': 'Nilai mulai',
            'stop_time': 'Hentikan waktu',
            'stop_value': 'Nilai berhenti',
            'stop_reason': 'Berhenti alasan',
            'fail_reason': 'Alasan gagal',
            'tenant_key': 'Kunci Penyewa',
            'current_electricity': 'Arus listrik'
        },
        'field': {
            'id_tra_log': 'PENGENAL',
            'id_con': 'Connector IDT',
            'event_time': 'Waktu acara',
            'id_tag': 'Tag ID',
            'start_time': 'Waktu mulai',
            'start_value': 'Nilai mulai',
            'stop_time': 'Hentikan waktu',
            'stop_value': 'Nilai berhenti',
            'stop_reason': 'Berhenti alasan',
            'fail_reason': 'Alasan gagal',
            'tenant_key': 'Kunci Penyewa',
            'current_electricity': 'Arus listrik'
        },
        'validate': {
            'required': {
                'id_tra_log': 'ID diperlukan',
                'id_con': 'Connector IDT diperlukan',
                'event_time': 'Waktu acara diperlukan',
                'id_tag': 'Tag ID diperlukan',
                'start_time': 'Waktu mulai diperlukan',
                'start_value': 'Nilai Mulai Diperlukan',
                'stop_time': 'Diperlukan waktu berhenti',
                'stop_value': 'Nilai berhenti diperlukan',
                'stop_reason': 'BERHENTI NASIRAN BUTUH',
                'fail_reason': 'Alasan gagal diperlukan',
                'tenant_key': 'Diperlukan kunci penyewa',
                'current_electricity': 'Listrik saat ini dibutuhkan'
            }
        }
    },
    'user': {
        'name': {'title': 'Pengguna', 'detailTitle': 'Detail Pengguna', 'editTitle': 'Edit pengguna'},
        'table': {
            'id_use': 'PENGENAL',
            'balance': 'Keseimbangan',
            'account': 'Akun',
            'password': 'Kata sandi',
            'register_time': 'Waktu mendaftar',
            'email': 'Surel',
            'token': 'Token',
            'salt': 'Garam',
            'tenant_key': 'Kunci Penyewa',
            'phone': 'Telepon',
            'register_type': 'Jenis Daftar'
        },
        'field': {
            'id_use': 'PENGENAL',
            'balance': 'Keseimbangan',
            'account': 'Akun',
            'password': 'Kata sandi',
            'register_time': 'Waktu mendaftar',
            'email': 'Surel',
            'token': 'Token',
            'salt': 'Garam',
            'tenant_key': 'Kunci Penyewa',
            'phone': 'Telepon',
            'register_type': 'Jenis Daftar',

            'chargingCard': 'Memuatkan nomor kartu',
            'chargingType': 'Mod muat prioritas',
            'chargingDefDuration': 'Waktu memuat kartu(min)',
            'chargingDefPower': 'Kapasitas muatan kartu(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'ID diperlukan',
                'balance': 'Keseimbangan diperlukan',
                'account': 'Akun diperlukan',
                'password': 'Kata sandi yang dibutuhkan',
                'register_time': 'Daftar waktu yang diperlukan',
                'email': 'Email diperlukan',
                'token': 'Token diperlukan',
                'salt': 'Diperlukan garam',
                'tenant_key': 'Diperlukan kunci penyewa',
                'phone': 'Diperlukan telepon',
                'register_type': 'Jenis Daftar Diperlukan',

                'chargingCard': 'Silakan isi nomor kartu muat',
                'chargingType': 'Silakan pilih mode muat prioritas kartu',
                'chargingDefDuration': 'Silakan isi waktu memuat kartu(min)',
                'chargingDefPower': 'Silakan isi jumlah muatan kartu(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Rencana pengguna',
            'detailTitle': 'Detail Rencana Pengguna',
            'editTitle': 'Edit Rencana Pengguna'
        },
        'table': {
            'id_use': 'Pengguna',
            'id_pla': 'Rencana',
            'arrearsCount': 'Jumlah Arrears',
            'start_date': 'Mulai tanggal',
            'tenant_key': 'Kunci Penyewa',
            'createType': 'Buat Identitas',
            'updateByMonth': 'Waktu Pembayaran Lanjut',

            'total': 'Bulan yang dibayar sebelumnya',
            'amount': 'Jumlah bayaran yang diharapkan',
            'prePayData': 'Bulan pembayaran yang diharapkan',
        },
        'validate': {
            'id_use': 'Diperlukan',
            'id_pla': 'Rencana diperlukan',
            'arrearsCount': 'Silakan isi jumlah bulan yang terlambat',
            'start_date': 'Tanggal mulai diperlukan',
            'tenant_key': 'Diperlukan kunci penyewa'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Total Pengisi Daya',
            'availableChargers': 'Jumlah port yang tersedia',
            'inUseChargers': 'Jumlah port yang digunakan',
            'unavailableChargers': 'Jumlah port tidak tersedia',
            'portUsage': 'Penggunaan port',

            'energyUsage': 'Penggunaan Energi (KWH)',
            'totalRevenue': 'Total pendapatan',
            'totalEnergy': 'Energi total',
            'newUser': 'Pengguna baru'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Versi Android',
            'androidDownloadLink': 'Tautan unduhan android',
            'androidComplieNumber': 'Nomor Kompilasi Android',
            'iosVersion': 'Versi iOS',
            'iosBundleId': 'ID Bundel iOS',
            'iosComplieNumber': 'Nomor kompilasi iOS',
            'isForcedUpdate': 'Apakah pemutakhiran wajib'
        },
        'validate': {
            'required': {
                'androidVersion': 'Diperlukan versi Android',
                'androidDownloadLink': 'Tautan unduhan android diperlukan',
                'androidComplieNumber': 'Nomor kompilasi android diperlukan',
                'iosVersion': 'Diperlukan versi iOS',
                'iosBundleId': 'ID Bundel IOS Diperlukan',
                'iosComplieNumber': 'IOS Kompilasi Nomor Diperlukan',
                'isForcedUpdate': 'Silakan pilih apakah memaksa pemutakhiran'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Pengaturan Kunci', 'dataStr': 'Nilai pengaturan'}},
    'superTenantManage': {
        'name': {'title': 'Izin', 'detailTitle': 'Detail', 'editTitle': 'Edit'},
        'searchForm': {
            'dataSourceKey': 'Kunci Sumber Data',
            'url': 'Url',
            'username': 'Nama belakang',
            'tenant': 'Penyewa',
            'isEnable': 'Diaktifkan',
            'isCreate': 'Menciptakan'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Harap masukkan kunci sumber data',
            'url': 'Harap masukkan alamat tautan',
            'username': 'Harap masukkan akun',
            'tenant': 'Harap masukkan nama penyewa',
            'isEnable': 'Pilih apakah akan mengaktifkan',
            'isCreate': 'Pilih untuk menciptakan'
        },
        'table': {
            'index': 'Indeks',
            'dataSourceKey': 'Kunci Sumber Data',
            'url': 'Url',
            'host': 'Tuan rumah',
            'port': 'Pelabuhan',
            'dbName': 'Nama Database',
            'username': 'Nama belakang',
            'password': 'Kata sandi',
            'tenant': 'penyewa',
            'isEnable': 'Diaktifkan',
            'isCreate': 'Menciptakan',
            'domain': 'Domain',
            'action': {'testConn': 'Conn', 'create': 'Membuat', 'edit': 'Edit', 'del': 'Menghapus'}
        },
        'field': {
            'id': 'PENGENAL',
            'datasourceKey': 'Kunci Sumber Data',
            'seckey': 'Kunci akses',
            'url': 'Url',
            'host': 'Tuan rumah',
            'port': 'Pelabuhan',
            'dbName': 'Nama Database',
            'username': 'Nama belakang',
            'password': 'Kata sandi',
            'tenant': 'Penyewa',
            'isEnable': 'Diaktifkan',
            'isCreate': 'Menciptakan',
            'remark': 'Komentar',
            'domain': 'Domain',
            'onesignalKey': 'Kunci notifikasi APP',
        },
        'validate': {
            'required': {
                'id': 'Harap isi id',
                'dataSourceKey': 'Harap isi Kunci Sumber Data',
                'seckey': 'Harap isi Kunci akses',
                'url': 'Harap isi URL tautan',
                'host': 'Harap isi tuan rumah',
                'port': 'Harap isi port',
                'dbName': 'Harap isi nama database',
                'username': 'Harap masukkan nama pengguna',
                'password': 'Harap isi kata sandi',
                'tenant': 'Harap isi nama penyewa',
                'isEnable': 'Pilih keadaan aktif',
                'remark': 'Harap isi komentar',
                'domain': 'Harap isi domain',
                'onesignalKey': 'Silakan isi kunci notifikasi APP',
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'PENGENAL',
            'index': 'Indeks',
            'machineCode': 'Kode Mesin',
            'name': 'Nama',
            'regId': 'ID Wilayah',
            'regName': 'Nama Wilayah',
            'seckey': 'Kunci akses',
            'args': 'Other parameters',
            'createBy': 'Dibuat oleh',
            'createTime': 'Buat waktu',
            'updateBy': 'Perbarui oleh',
            'updateTime': 'Perbarui waktu',
            'remark': 'Komentar'
        },
        'validate': {
            'id': 'Harap isi id',
            'machineCode': 'Harap isi kode mesin',
            'name': 'Silakan isi namanya',
            'regId': 'Harap isi ID wilayah',
            'seckey': 'Harap isi Kunci akses',
            'args': 'Harap isi Other parameters',
            'remark': 'Silakan isi komentar'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'Surel', '1': 'Telepon'},
        'noticeType': {
            '0': 'Peringatan',
            '1': 'Mengingatkan',
            '2': 'Pengumuman',
            '3': 'Waktu tambahan',
        },
        'field': {
            'id': 'PENGENAL',
            'index': 'Indeks',
            'toFrom': 'Akun',
            'type': 'Jenis akun',
            'noticeType': 'Jenis pemberitahuan',
            'status': 'Diaktifkan',
            'createBy': 'Dibuat oleh',
            'createTime': 'Buat waktu',
            'updateBy': 'Perbarui oleh',
            'updateTime': 'Perbarui waktu',
            'remark': 'Komentar'
        },
        'validate': {
            'id': 'Harap isi id',
            'type': 'Pilih Jenis Akun',
            'toFrom': 'Harap isi akunnya',
            'noticeType': 'Pilih Jenis Pemberitahuan',
            'status': 'Pilih apakah akan mengaktifkan',
            'remark': 'Silakan isi komentar'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'Ketentuan Layanan',
            '1': 'Tentang kita',
            '2': 'informasi',
            '3': 'memperhatikan',
            '4': 'ingatkan',
        },
        'field': {
            'id': 'ID',
            'index': 'nomor seri',
            'type': 'Jenis iklan',
            'status': 'Apakah itu diaktifkan',
            'title': 'Judul pengumuman',
            'content': 'Pemberitahuan',
            'tenantKey': 'Identitas penyewa',

            'createBy': 'Dibuat oleh',
            'createTime': 'Waktu pembuatan',
            'updateBy': 'Diperbarui oleh',
            'updateTime': 'Waktu pembaruan',
            'remark': 'Pernyataan',
        },
        'validate': {
            'id': 'Silahkan isi ID',
            'type': 'Pilih jenis pengumuman',
            'status': 'Silakan pilih apakah akan mengaktifkannya',
            'title': 'Silakan isi judul pengumuman',
            'content': 'Silakan isi isi pemberitahuan',
            'remark': 'Silahkan isi komentar',
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'nomor seri',
            'identifier': 'Pengidentifikasi tumpukan',
            'useNum': 'Jumlah penggunaan',
            'chargeCapacity': 'Total penggunaan(kw)',

            'startTime': 'Waktu mulai',
            'endTime': 'Waktu berakhir'
        },
        'validate': {
            'identifier': 'Silakan masukkan nomor tumpukan',
            'startTime': 'Silakan pilih waktu mulai',
            'endTime': 'Silakan pilih waktu akhir'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'Minuman',
            '1': 'Makanan',
            '2': 'Toilet',
            '3': 'Belanja',
        },
        'table': {
            'id': 'ID',
            'type': 'type',
            'status': 'status',
            'name': 'name',
            'lng': 'longitude',
            'lat': 'latitude',
            'address': 'address',
            'description': 'description',
            'reId': 'associated area ID'
        },
        'validate': {
            'type': 'Silakan pilih type',
            'status': 'Silakan pilih status',
            'name': 'Silakan isi nama',
            'lng': 'Silakan isi bujur',
            'lat': 'Silakan isi latitude',
            'address': 'Silakan isi alamat',
            'description': 'Silakan isi deskripsi',
            'reId': 'Area terkait'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Primary Key',
            'tId': 'Associate Tenant ID',
            'name': 'Company Name',
            'attn': 'Hubungan Perusahaan',
            'tel': 'Telepon Perusahaan',
            'address': 'Company Address',
            'email': 'Email Perusahaan',
            'invoiceDate': 'Tarikh Faktur',
            'invoiceNo': 'Nomor Fakta',
            'contactPerson': 'Kontak',
            'contactTel': 'Contact Number',
            'contactEmail': 'Email Kontak',
            'monthlyFee': 'Nilai preset tagihan bulanan akses sistem',
            'company': 'Informasi Perusahaan'
        },
        'validate': {
            'id': 'Silakan masukkan kunci utama',
            'tId': 'Masukkan ID penyewa yang terkait',
            'name': 'Silakan masukkan nama perusahaan',
            'attn': 'Silakan masukkan orang kontak perusahaan',
            'tel': 'Silakan masukkan telepon perusahaan Anda',
            'address': 'Silakan masukkan alamat perusahaan Anda',
            'email': 'Please enter your company email',
            'invoiceDate': 'Silakan masukkan tanggal faktur',
            'invoiceNo': 'Please enter the invoice number',
            'contactPerson': 'Silakan masukkan orang kontak',
            'contactTel': 'Silakan masukkan nomor kontak Anda',
            'contactEmail': 'Please enter your contact email',
            'monthlyFee': 'Silakan masukkan nilai default dari biaya akses sistem bulanan'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'ID Parent Tenant',
            'tenantKey': 'Kunci Tenant',
            'name': 'Nama Tenant',
            'domain': 'Nama domain terikat',
            'tpId': 'Paket Associated',
            'authId': 'Keizinan terkait',
            'level': 'level',
            'contact': 'Orang kontak',
            'email': 'E-mail kontak',
            'phone': 'Nomor kontak',
            'startTime': 'Waktu aktivasi',
            'expireTime': 'Waktu habis',
        },
        'args':{
            'yedPayExtendParams': 'YedPay Args',
            'yedPayApiKey': 'YedPay ApiKey',
            'yedPaySingKey': 'YedPay SingKey',

            'mPayExtendParams': 'MPay Args',
            'mPayPubKey': 'MPay PubKey',
            'mPayPriKey': 'MPay PriKey',
        },
        'validate': {
            'id': 'Silakan masukkan ID',
            'pid': 'Silakan masukkan ID penyewa orang tua',
            'tenantKey': 'Silakan isi kunci penyewa',
            'name': 'Silakan isi nama penyewa',
            'domain': 'Silakan isi nama domain terikat',
            'tpId': 'Silakan pilih paket terkait',
            'authId': 'Silakan pilih keizinan yang terkait',
            'contact': 'Silakan isi orang kontak',
            'email': 'Silakan isi email kontak',
            'phone': 'Silakan isi nomor telepon kontak',
            'startTime': 'Silakan pilih waktu awal',
            'expireTime': 'Silakan pilih waktu penghabisan',

            'yedPayExtendParams': 'Silakan masukkan parameter YedPay',
            'yedPayApiKey': 'Silakan masukkan parameter YedPay',
            'yedPaySingKey': 'Silakan masukkan YedPay SingKey',
            'mPayExtendParams': 'Silakan masukkan parameter MPay',
            'mPayPubKey': 'Silakan masukkan kunci publik MPay',
            'mPayPriKey': 'Silakan masukkan kunci pribadi MPay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Nomor paket',
            'name': 'Nama Pakej',
            'createTenantMax': 'Jumlah penyewa yang dapat diciptakan',
            'createUserMax': 'Jumlah pengguna yang dapat diciptakan',
            'createAppMax': 'Jumlah aplikasi yang dapat didaftar',
            'expireLong': 'Durasi efektif paket',
            'tenantKey': 'Identifikasi sengaja',
        },
        'validate': {
            'id': 'Silakan masukkan ID',
            'packageNo': 'Silakan masukkan nomor paket',
            'name': 'Silakan masukkan nama paket',
            'createTenantMax': 'Silakan masukkan jumlah penyewa yang dapat diciptakan',
            'createUserMax': 'Silakan masukkan jumlah pengguna yang dapat diciptakan',
            'createAppMax': 'Silakan masukkan jumlah aplikasi yang dapat didaftar',
            'expireLong': 'Silakan pilih durasi efektif paket',
            'tenantKey': 'Silakan masukkan kunci penyewa',
        }
    },
    'card': {
        'cardType': {
            '0': 'Whitelist',
            '1': "Blacklist",
            '2': 'Kartu sistem',
            '3': 'Kartu pengguna',
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'Card number',
            'cardType': 'Type',
            'cardStatus': 'Apakah ini diaktifkan',
        },
        'validate': {
            'cardId': 'Silakan isi ID',
            'cardNo': 'Silakan isi nomor kartu',
            'cardType': 'Silakan pilih tipe',
            'cardStatus': 'Silakan pilih status',
        }
    },
    'waller': {
        'wType': {
            '1': 'Balance',
            '2': 'Jumlah hadiah',
            '3': 'Integral'
        },
        'table': {
            'walletId': 'ID',
            'account': 'Akun',
            'type': 'Tipe',
            'status': 'Berhenti atau tidak',
            'balance': 'Balance',
        },
        'validate': {
            'account': 'Silakan isi rekening Anda',
            'type': 'Silakan pilih tipe',
            'status': 'Silakan pilih status',
        }
    }
}
export default idID