<template>
  <Modal id="RegionImagesDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('regionImages.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('regionImages.field.id_reg_img')">{{data.idRegImg}}</DescriptionsItem>
      <DescriptionsItem :label="$t('regionImages.field.id_reg')">{{data.regionName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('regionImages.field.url')">{{data.url}}</DescriptionsItem>
      <DescriptionsItem :label="$t('regionImages.field.upload_time')">{{data.uploadTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('regionImages.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "RegionImagesDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>