<template>
  <Modal
      id="TransactionLogDetail"
      width="60%"
      :open="visible" @cancel="visible = !visible"
      @ok="visible = !visible"
      :title="$t('transactionLog.name.detailTitle')"
      :zIndex="9999999900"
  >
    <Descriptions >
      <DescriptionsItem :label="$t('transactionLog.field.id_tra_log')">{{data.idTraLog}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.id_con')">{{data.connectorName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.event_time')">{{data.eventTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.id_tag')">{{data.idTag}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.start_time')">{{data.startTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.start_value')">{{data.startValue}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.stop_time')">{{data.stopTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.stop_value')">{{data.stopValue}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.stop_reason')">{{data.stopReason}}</DescriptionsItem>
      <DescriptionsItem :label="$t('transactionLog.field.fail_reason')">{{data.failReason}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('transactionLog.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('transactionLog.field.current_electricity')">{{data.currentElectricity}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "TransactionLogDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>