import {Base} from "@/api/Base"

const api = "eventLog"

export class EventLogAdd extends Base {
    path = api + "/add"
}

export class EventLogDelete extends Base {
    path = api + "/delete"
}

export class EventLogList extends Base {
    path = api + "/list"
}

export class EventLogEdit extends Base {
    path = api + "/edit"
}

// 导出Excel
export class EventLogExportToExcel extends Base {
    path = api + "/export"
}
