<template>
  <Modal
      id="UserPlanEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      destroyOnClose
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="data.validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('userPlan.table.id_use')" name="idUse">
                    <Select
                        v-model:value="form.idUse"
                        show-search
                        :placeholder="$t('userPlan.validate.id_use')"
                        style="width: 100%"
                        :options="userList"
                        :filter-option="filterOption"
                        allowClear
                        :disabled="actionType === 'editFinish' || actionType === 'edit'"
                    ></Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('userPlan.table.id_pla')" name="idPla">
                    <Select
                        v-model:value="form.idPla"
                        show-search
                        :placeholder="$t('userPlan.validate.id_pla')"
                        style="width: 100%"
                        :options="planList"
                        :filter-option="filterOption"
                        allowClear
                        :disabled="actionType === 'editFinish' || actionType === 'edit'"
                    ></Select>
                  </FormItem>
                </Col>

                <!--                <Col span="10" v-if="actionType === 'edit'">-->
                <!--                  <FormItem :label="$t('public.name.status')" name="status">-->
                <!--                    <Select v-model:value="form.status" :placeholder="$t('public.pleaseSelect')" :dropdownStyle="{zIndex: 9999999950}">-->
                <!--                      <SelectOption :value="0"><Tag color="blue">{{ $t("public.doingStatus.0") }}</Tag></SelectOption>-->
                <!--                      <SelectOption :value="1"><Tag color="orange">{{ $t("public.doingStatus.1") }}</Tag></SelectOption>-->
                <!--                      <SelectOption :value="2"><Tag color="green">{{ $t("public.doingStatus.2") }}</Tag></SelectOption>-->
                <!--                      <SelectOption :value="3"><Tag>{{ $t("public.doingStatus.3") }}</Tag></SelectOption>-->
                <!--                    </Select>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->

                <Col span="10" v-if="actionType === 'editFinish' || actionType === 'edit'">
                  <FormItem :label="$t('userPlan.table.start_date')" name="startDate">
                    <DatePicker
                        style="width: 100%"
                        :placeholder="$t('public.promptsToSelectTime')"
                        valueFormat="YYYY-MM-DD"
                        v-model:value="form.startDate" :popupStyle="{ zIndex: 9999999950 }"
                        :disabled="actionType === 'edit'"
                    >
                    </DatePicker>
                  </FormItem>
                </Col>

                <Col span="10" v-if="actionType === 'editFinish' || actionType === 'edit'">
                  <FormItem :label="$t('statisticsManage.table.identifier')" name="identifier">
                    <Input :placeholder="$t('statisticsManage.validate.identifier')"
                           v-model:value="form.identifier">
                    </Input>
                  </FormItem>
                </Col>
                <!--                <Col span="10" v-if="actionType === 'edit'">-->
                <!--                  <FormItem :label="$t('userPlan.table.arrearsCount')" name="identifier">-->
                <!--                    <InputNumber-->
                <!--                        style="width: 100%;"-->
                <!--                        :placeholder="$t('userPlan.validate.arrearsCount')"-->
                <!--                        v-model:value="form.arrearsCount"-->
                <!--                        :disabled="actionType === 'edit'"-->
                <!--                    >-->
                <!--                    </InputNumber>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->
                <!--                <Col span="10" v-if="actionType === 'edit'">-->
                <!--                  <FormItem :label="$t('plan.table.free_charging_time_limit') + '(min)'" name="currentlyFreeTime">-->
                <!--                    <InputNumber-->
                <!--                        style="width: 100%;"-->
                <!--                        :placeholder="$t('companyManage.validate.monthlyFee') + '(min)'"-->
                <!--                        v-model:value="form.currentlyFreeTime"-->
                <!--                        :disabled="actionType === 'edit'"-->
                <!--                    >-->
                <!--                    </InputNumber>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->
                <Col span="10"></Col>
                <Col span="22">
                  <FormItem :label="$t('public.name.remark')" name="remark">
                    <Textarea
                        v-model:value="form.remark"
                        :placeholder="$t('employee.validate.required.remark')"
                        :auto-size="{ minRows: 5, maxRows: 10 }"
                        showCount
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UserPlanEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  message,
  Select,
  SelectOption,
  DatePicker,
  Input,
  InputNumber,
  Textarea,
  Tag
} from "ant-design-vue";
import {UserPlanAdd, UserPlanEdit, UserPlanEditFinish} from "@/api/userPlan";
import {UserListAll} from "@/api/user"
import {PlanListAll} from "@/api/plan"
import {ref, reactive, toRefs, createVNode} from "vue";
import {useI18n} from "vue-i18n"
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()
let visible = ref(false)
let actionType = ref('')
let title = ref('')

const data = reactive({
  form: {
    idUse: undefined,
    idPla: undefined,
    startDate: undefined,
    tenantKey: undefined,
  },
  fileList: {},
  userList: [],
  planList: [],
  validateRules: {
    idUse: [
      {
        required: true,
        message: i18n.t("userPlan.validate.id_use"),
        trigger: "change",
        type: "number",
      },
    ],
    idPla: [
      {
        required: true,
        message: i18n.t("userPlan.validate.id_pla"),
        trigger: "change",
        type: "number",
      },
    ],
    startDate: [
      {
        required: true,
        message: i18n.t("userPlan.validate.start_date"),
        trigger: "change",
      },
    ],
    tenantKey: [
      {
        required: true,
        message: i18n.t("userPlan.validate.tenant_key"),
        trigger: "change",
      },
    ],
    identifier: [
      {
        required: true,
        message: i18n.t("statisticsManage.validate.identifier"),
        trigger: "change",
      },
    ],
    currentlyFreeTime: [
      {
        required: true,
        message: i18n.t("companyManage.validate.monthlyFee") + '(min)',
        trigger: "change",
      },
    ],
    status: [
      {
        required: true,
        message: i18n.t("public.pleaseSelect"),
        trigger: "change",
      },
    ],
    remark: [
      {
        required: true,
        message: i18n.t("employee.validate.required.remark"),
        trigger: "change",
      },
    ],
  },
})
const {form, userList, planList} = toRefs(data)

function addShow() {
  _resetData()
  actionType.value = "add";
  title.value = i18n.t("public.consumeType.4");
  getUserList();
  getPlanList();
  visible.value = true;
}

async function editShowFinish(obj) {
  _resetData();
  actionType.value = "editFinish";
  title.value = i18n.t("public.name.edit");
  form.value = obj;
  getUserList()
  getPlanList()
  visible.value = true;
}

async function editShow(obj) {
  _resetData();
  actionType.value = "edit";
  title.value = i18n.t("public.name.edit");
  form.value = obj;
  getUserList()
  getPlanList()
  visible.value = true;
}

function submit() {
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new UserPlanAdd();
        break;
      case "editFinish":
        api = new UserPlanEditFinish();
        break;
      case "edit":
        api = new UserPlanEdit();
        break;
      default:
        return false;
    }

    Modal.confirm({
      title: i18n.t("public.name.are_you_sure_operate") + "?",
      content: i18n.t("public.name.operate_remind") + "！",
      icon: createVNode(ExclamationCircleOutlined),
      okText: i18n.t("public.name.yes"),
      okType: "danger",
      cancelText: i18n.t("public.name.no"),
      onOk: () => {
        api.post(form.value).then((response) => {
          if (0 === parseInt(response.data.code)) {
            message.success(i18n.t("succeedMsg.0"));
            emits("updateList", response.data.data);
            visible.value = false;
          } else {
            if (response.data.code !== undefined) {
              message.error(i18n.t("errorMsg." + response.data.code));
            }
          }
        });
      }
    })
  }).catch(() => {
  });
}

function getUserList() {
  const api = new UserListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      const data = response.data.data
      let l = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          l.push({
            value: data[i].idUse,
            label: data[i].account
          })
        }
      }
      userList.value = l;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function getPlanList() {
  const api = new PlanListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      const data = response.data.data
      let l = []
      if (data) {
        for (let i = 0; i < data.length; i++) {
          l.push({
            value: data[i].idPla,
            label: data[i].name
          })
        }
      }
      planList.value = l;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}

function _resetData() {
  form.value = {
    idUse: undefined,
    idPla: undefined,
    startDate: undefined,
    tenantKey: undefined,
  };
}

defineExpose({
  addShow,
  editShowFinish,
  editShow
});

</script>

<style lang="less">
</style>
