<template>
  <Modal
      width="60%"
      :open="visible"
      @cancel="visible = false"
      @ok="visible = false"
      :title="$t('public.name.detail')"
      :zIndex="9999999900"
      :destroyOnClose="true"
  >
    <Descriptions>
      <DescriptionsItem :label="$t('serviceManage.table.id')">{{ data.id }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.type')">{{ data.type }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.status')">{{ data.status }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.name')">{{ data.name }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.address')">{{ data.address }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.description')">{{ data.description }}</DescriptionsItem>
      <DescriptionsItem :label="$t('serviceManage.table.reId')">{{ data.reId }}</DescriptionsItem>
    </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "MeterDetail",
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem} from "ant-design-vue";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {reactive, ref} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

let data = reactive({})
let visible = ref(false)

function detailShow(obj) {
  data = obj
  let ty
  switch (data.type) {
    case 0:
      ty = i18n.t('serviceManage.type.0')
      break;
    case 1:
      ty = i18n.t('serviceManage.type.1')
      break;
    case 2:
      ty = i18n.t('serviceManage.type.2')
      break;
    case 3:
      ty = i18n.t('serviceManage.type.3')
      break;
    default:
      ty = data.type
  }
  data.type = ty
  data.status = data.status === 1 ? i18n.t('public.isTrue.1') : i18n.t('public.isTrue.0')
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>