<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
<!--        <Col :span="24" >-->
          <!-- <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('member.name.search_user_name')" name="search">
                  <Input :placeholder="$t('member.name.search_user_name')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="0">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form> -->
<!--        </Col>-->
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idRol" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('RoleManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
          </template>
          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(record)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RoleManage.Edit')" type="primary" size="small" @click="edit(record)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RoleManage.Delete') && record.idRol !== 1" type="primary" danger size="small" @click="del(record.idRol)">{{$t("public.name.del")}}</Button>
                </div>
              </div>
            </template>
          </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <RoleDetail ref="refRoleDetail"></RoleDetail>
    <RoleEdit ref="refRoleEdit" @updateList="get_list"></RoleEdit>
  </Row>
</template>
<script>
export default {
  name: "RoleManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { RoleList, RoleDelete } from "@/api/role";
import RoleDetail from "./RoleDetail";
import RoleEdit from "./RoleEdit";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refRoleDetail = ref()
const refRoleEdit = ref()

let loading = ref(false)

const columns = [
  { title: i18n.t("role.table.id_rol"), fixed: "left", dataIndex: "idRol", key: "idRol" },
  { title: i18n.t("role.table.name"), dataIndex: "name", key: "name" },
  { title: i18n.t("role.table.remark"), dataIndex: "remark", key: "remark" },
  { key: "action", fixed: "right", dataIndex: "action"},
]

const data = reactive({
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, list, tablePagination} = toRefs(data)

function get_list() {
  loading.value = true;
  const api = new RoleList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function chang_page(pagination) {
  this.tablePagination = pagination;
  this.get_list();
}

function detail(obj) {
  refRoleDetail.value?.detailShow(obj);
}

function add() {
  refRoleEdit.value?.addShow();
}

function edit(obj) {
  refRoleEdit.value?.editShow(obj);
}

function del(idRol) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new RoleDelete();
      api.post({ idRol: idRol }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  this.get_list()
}

function search_reset() {
  this.searchForm = {}
  this.get_list()
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.RoleManage'));
get_list();

defineExpose({
  get_list
})
</script>