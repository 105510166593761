<template>
  <Form v-bind="$attrs" class="login-form" ref="loginRef" layout="vertical" :model="formState" :rules="validateRules" @finish="(values)=>onSubmit(values)">
    <FormItem>
      <Row>
        <Col :span="8"></Col>
        <Col :span="8" style="text-align: center"><h1>EvCharge</h1></Col>
        <Col :span="8"><h6>{{store.getters.getVersion}}</h6></Col>
      </Row>
      <div style="text-align: right">
        <Select
            v-model:value="language"
            size="small"
            style="width: 120px"
            :options="options"
            @change="changeLanguage"
        ></Select>
      </div>
    </FormItem>

    <FormItem name="email">
      <Input :placeholder="$t('public.name.username')" v-model:value="formState.email" size="large">
        <template #suffix>
          <UserOutlined/>
        </template>
      </Input>
    </FormItem>
    <FormItem name="password">
      <InputPassword :placeholder="$t('public.name.password')" v-model:value="formState.password" size="large"></InputPassword>
    </FormItem>
    <FormItem>
      <Button type="primary" class="login-btn" :loading="loading" html-type="submit" size="large">
        {{ $t('public.name.login') }}
      </Button>
    </FormItem>
    <FormItem name="rememberMe" style="width: 100%; text-align: right">

      <a style="margin-right: 10px" @click="onForgotPassword">{{ $t('public.name.reset_password') }}</a>
      <Checkbox v-model:checked="formState.rememberMe">{{ $t('public.name.remember') }}</Checkbox>
    </FormItem>
    <!--    <FormItem :label="$t('public.name.captcha')+':'" name="captcha">-->
    <!--      <Input :placeholder="$t('public.name.captcha')" v-model:value="Login.captcha_code" @keyup.enter="sendLogin">-->
    <!--        <template #suffix>-->
    <!--          <Image :width="150" :height="40" :fallback="$store.getters.getNoImage" :src="captchaImg" :preview="false" @click="getCaptcha"></Image>-->
    <!--        </template>-->
    <!--      </Input>-->
    <!--    </FormItem>-->
  </Form>
  <ForgotPassword ref="forgotPassword" />
</template>

<script setup>
import {Form, FormItem, Input, InputPassword, Button, Checkbox, Select, message, Row, Col} from "ant-design-vue";
import {UserOutlined} from "@ant-design/icons-vue";
import ForgotPassword from "./ForgotPassword"

import {reactive, ref, onMounted} from 'vue';
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {Login} from "@/api/employee";
import {useRouter} from "vue-router/dist/vue-router";

const i18n = useI18n()
const store = useStore()
const router = useRouter()

let language = ref(store.getters.getLanguage)
let language_temp = ref(store.getters.getLanguage)
const validateRules = reactive({
  email: [
    {
      required: true,
      // type: "email",
      message: i18n.t("login.validate.required.username"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("login.validate.required.password"),
      trigger: "blur",
    },
  ],
  // captcha_code: [
  //   {
  //     required: true,
  //     message: window.vm.$t("Login.validate.required.captcha"),
  //     trigger: "blur",
  //   },
  // ],
})
const loading = ref(false)
const formState = reactive({
  email: '',
  password: '',
  rememberMe: false,
});
const options = reactive([
  {label: "中（繁体）", value: "zhTW"},
  {label: "English", value: "enUS"},
  {label: "中（简体）", value: "zhCN"},
  {label: "Danmark", value: "daDK"},
  {label: "Indonesia", value: "idID"},
  {label: "Melayu", value: "msMY"},
  {label: "แบบไทย", value: "thTH"},
  {label: "Tiếng Việt", value: "viVN"},
  {label: "Français", value: "frFR"},
  {label: "Polski", value: "plPL"},
  {label: "Italiano", value: "it"},
])
const loginRef = ref();

const changeLanguage = () => {
  if (language.value === undefined) {
    // 默认使用英语
    language.value = 'enUS'
    store.commit("setLanguage", "enUS");
  } else {
    i18n.locale.value = language.value
    store.commit("setLanguage", language.value);
    if (language.value !== language_temp.value) {
      location.reload();
    }
  }
}
const onSubmit = (value) => {
  if (!value) {
    return false
  }
  const api = new Login();
  api.post(formState).then((response) => {
    if (0 === parseInt(response.data.code)) {
      store.commit("setToken", response.data.data.token);
      store.commit("setUsername", response.data.data.name);
      store.commit("setIdRol", response.data.data.idRol);
      store.commit("setUserType", response.data.data.userType);
      store.commit("setPermissionList", response.data.data.permissionList);
      store.commit("setDsKey", response.data.data.dsKey);
      store.commit("setTenantKey", response.data.data.tenantKey);
      store.commit("setUserTenantKey", response.data.data.tenantKey);
      store.commit("setIsAdmin", response.data.data.isAdmin);
      // ctx.$store.commit("setEmail", response.data.data.email);
      // ctx.$store.commit("setLogin", response.data.data.Login);
      // ctx.$store.commit("setPhone", response.data.data.phone);
      // ctx.$store.commit("setType", response.data.data.type);
      // message.success(i18n.t("succeedMsg.0"));
      router.push({name: "Dashboard"});
    } else {
      message.error(i18n.t("errorMsg." + response.data.code));
    }
  });
}

const forgotPassword = ref();
const onForgotPassword = () => {
  forgotPassword.value.onShow()
}

onMounted(() => {
  changeLanguage()
})
</script>

<style scoped>
.login-form {
  grid-column: 1;
  grid-row: 1;
  opacity: 1;
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
  padding: 1% 25%;
  pointer-events: all;
}

.login-form.sign-up-model {
  opacity: 0;
  transition: 1s ease-in-out;
  transition-delay: 0.5s;
  pointer-events: none;
}

.login-title {
  text-align: center;
  color: #444;
}

.login-btn {
  width: 100%;
  font-size: 18px;
}
</style>