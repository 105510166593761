import {Base} from "@/api/Base"

const api = "regionImages"

export class RegionImagesAdd extends Base {
    path = api + "/add"
}

export class RegionImagesEdit extends Base {
    path = api + "/edit"
}

export class RegionImagesDelete extends Base {
    path = api + "/delete"
}

export class RegionImagesList extends Base {
    path = api + "/list"
}
