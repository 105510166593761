import {Axios, Base} from "@/api/Base"

const api = "card"

export class CardList extends Base {
    path = api + "/list"
}

export class CardAdd extends Base {
    path = api + "/add"
}

export class CardEdit extends Base {
    path = api + "/edit"
}

export class CardDelete extends Base {
    path = api + "/delete"
}

export class CardEditStatus extends Base {
    path = api + "/editStatus"
}

export class CardGetSelectList extends Base {
    path = api + "/getSelectList"
}

// 导出
export class CardExportToExcel extends Base {
    path = api + "/exportToExcel"
}

// 下载模板
export class CardDownloadCardTemp extends Base {
    path = api + "/downloadCardTemp"
}

// 导入
export class CardImport extends Base {
    path = api
    upload(param){
        return Axios.post(api + "/importCard", param, {
            headers: {
                "Content-Type":"multipart/form-data"
            }
        })
    }
}

