/**
 * @Auther    william
 * @Name      state mutations file
 * @Note      
 * @Time      2021/04/14 17:21
 */
let mutations = {
    // test
    test(state) {
        // console.log(state)
    },
    // logout
    logout(state) {
        // 清除掉该用户所有缓存信息
        // localStorage.removeItem(state.systemTag + "_info")
        // 使用默认信息
        this.commit("reset")
    },
    // reset state
    reset(state) {
        state.dsKey = ""
        state.tenantKey = ""

        // 保留语言不重置
        // state.language = "enUS"
        state.token = ""
        state.logo = ""
        // 版本信息不重置
        state.version = "@v23.11.15"
        // 底部信息不重置
        state.copyRight = "Ttech Global Service Limited. All Rights Reserved 2022 " + state.version
        state.navList = []
        state.username = ""
        state.idRol = ""
        state.userType = ""
        state.userTenantKey = ""
        state.isAdmin = 0

        state.portrait = ""
        state.lastIp = ""
        state.lastTime = 0
        state.phone = ""
        state.login = ""
        state.email = ""
        state.type = 0
        state.permissionList = []
        state.navList = []
        state.pageTitle = ""
    },
    // set dsKey
    setDsKey(state, value) {
        state.dsKey = value
    },
    // set isAdmin
    setIsAdmin(state, value) {
        state.isAdmin = value
    },
    // set language
    setLanguage(state, value) {
        state.language = value
    },
    // set username
    setUsername(state, value) {
        state.username = value
    },
    // set idRol
    setIdRol(state, value) {
        state.idRol = value
    },
    // set userType
    setUserType(state, value) {
        state.userType = value
    },
    // set userTenantKey
    setUserTenantKey(state, value) {
        state.userTenantKey = value
    },
    // set userType
    setTenantKey(state, value) {
        state.tenantKey = value
    },
    // set portrait
    setPortrait(state, value) {
        state.portrait = value
    },
    // set last login ip
    setLastIp(state, value) {
        state.lastIp = value
    },
    // set last login time
    setLastTime(state, value) {
        state.lastTime = value
    },
    // set toekn
    setToken(state, value) {
        state.token = value
    },
    setPhone(state, value) {
        state.phone = value
    },
    setLogin(state, value) {
        state.login = value
    },
    setEmail(state, value) {
        state.email = value
    },
    setType(state, value) {
        state.type = value
    },
    setPermissionList(state, value) {
        state.permissionList = value
    },
    setNavList(state, value) {
        state.navList = value
    },
    // set pageTitle
    setPageTitle(state, value) {
        state.pageTitle = value
    },
    // set pageTitle
    setLogo(state, value) {
        state.logo = value
    },
}


export default mutations