<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
<!--                <FormItem :label="$t('car.field.id_use')" name="idUse">-->
<!--                  <Select-->
<!--                      v-model:value="searchForm.idUse"-->
<!--                      :placeholder="$t('car.validate.required.id_use')"-->
<!--                      :dropdownStyle="{ zIndex: 9999999950 }"-->
<!--                  >-->
<!--                    <SelectOption :key="sk" v-for="(item,sk) in selectList['userList']" :value="item['idUse']">{{ item['account'] }}</SelectOption>-->
<!--                  </Select>-->
                  <FormItem :label="$t('user.field.account')" name="userAccount">
                    <Input :placeholder="$t('user.validate.required.account')" v-model:value="searchForm.userAccount"></Input>
                  </FormItem>
<!--                </FormItem>-->
              </Col>
              <Col :span="6">
                <FormItem :label="$t('car.field.car_license')" name="carLicense">
                  <Input :placeholder="$t('car.validate.required.car_license')"
                         v-model:value="searchForm.carLicense">
                  </Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
            <Row style="margin-bottom: 5px">
              <Col :span="8">
                <Button type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>
              </Col>
              <Col :span="8" :offset="8" style="text-align: right;">
                <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
                <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1500}" rowKey="idCar" :loading="loading" :pagination="tablePagination" @change="chang_page">

          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('CarManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
          </template>

          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(record)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('CarManage.Edit')" type="primary" size="small" @click="edit(record)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('CarManage.Delete')" type="primary" danger size="small" @click="del(record.idCar)">{{$t("public.name.del")}}</Button>
                </div>
                <Dropdown :trigger="['click']" class="wap-display">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="del(record.id)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </template>

        </Table>
      </div>
    </Col>
    <CarDetail ref="refCarDetail"></CarDetail>
    <CarEdit ref="refCarEdit" @updateList="get_list"></CarEdit>
  </Row>
</template>
<script>
export default {
  name: "CarManage",
}
</script>
<script setup>
import { PlusOutlined } from "@ant-design/icons-vue";
import {createVNode, reactive, ref, toRefs} from "vue";
import { Row, Col, Table, Button, Dropdown, Menu, Modal, Form, Input, FormItem, MenuItem, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { CarList, CarDelete, CarExportToExcel} from "@/api/car";
import CarDetail from "./CarDetail";
import CarEdit from "./CarEdit";
import {UserListAll} from "@/api/user";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()

const refCarEdit = ref()
const refCarDetail = ref()

let loading = ref(false)
let userList = ref([])

const booleanList = [{value: 0,label: i18n.t("public.isTrue.0")},{value: 1,label: i18n.t("public.isTrue.1")}]
const columns = [
  { title: i18n.t("car.table.id_car"), width: 60, ellipsis: true, fixed: "left", dataIndex: "idCar", key: "idCar" },
  { title: i18n.t("car.table.id_use"), width: 90, ellipsis: true, dataIndex: "userAccount", key: "userAccount" },
  { title: i18n.t("user.table.phone"), width: 150, ellipsis: true, dataIndex: "userPhone", key: "userPhone" },
  { title: i18n.t("car.table.model"), width: 80, ellipsis: true, dataIndex: "model", key: "model" },
  { title: i18n.t("car.table.car_license"), width: 120, ellipsis: true, dataIndex: "carLicense", key: "carLicense" },
  { title: i18n.t("car.table.max_years_old"), width: 80, ellipsis: true, dataIndex: "maxYearsOld", key: "maxYearsOld" },
  { title: i18n.t("car.table.manufacture_year"), width: 80, ellipsis: true, dataIndex: "manufactureYear", key: "manufactureYear" },
  { title: i18n.t("car.table.maximum_power"), width: 80, ellipsis: true, dataIndex: "maximumPower", key: "maximumPower" },

  { title: i18n.t("car.table.is_second_hand"), width: 80, ellipsis: true, dataIndex: "isSecondHand", key: "isSecondHand", customRender: ({text}) =>{
      const obj = booleanList.find(item=>item.value===text)
      return obj === undefined ? text :obj.label
    }
  },

  { title: i18n.t("car.table.vehicle_inspection_date"), width: 180, ellipsis: true, dataIndex: "vehicleInspectionDate", key: "vehicleInspectionDate" },
  { title: i18n.t("car.table.insurance_expiry_date"), width: 180, ellipsis: true, dataIndex: "insuranceExpiryDate", key: "insuranceExpiryDate" },
  { title: i18n.t("car.table.tenant_key"), width: 80, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey" },
  { key: "action", width: 180, ellipsis: true, fixed: "right", dataIndex: "action" },
]

const data = reactive({
  list: [],
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination, list} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new CarList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    if (0 === parseInt(response.data.code)) {
      getUserList()
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(()=>{
    loading.value = false;
  });
}

function add() {
  refCarEdit.value?.addShow(userList.value);
}

function detail(obj) {
  refCarDetail.value?.detailShow(obj);
}

function edit(obj) {
  refCarEdit.value?.editShow(obj, userList.value);
}

function del(idCar) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new CarDelete();
      api.post({ idCar: idCar }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  searchForm.value = {}
  get_list()
}

function getUserList() {
  const api = new UserListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      userList.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

function exportToExcel() {
  loading.value = true
  const api = new CarExportToExcel()
  api.getDownLoad(searchForm.value).then(() => {}).finally(() => {
    loading.value = false
  })
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.CarManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
