<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('publicityManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('publicityManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('publicityManage.field.type')" name="type">
                    <Select v-model:value="form.type" :placeholder="$t('publicityManage.validate.type')" allowClear>
                      <SelectOption :value="0">{{ $t('publicityManage.publicityType.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('publicityManage.publicityType.1') }}</SelectOption>
                      <SelectOption :value="2">{{ $t('publicityManage.publicityType.2') }}</SelectOption>
                      <SelectOption :value="3">{{ $t('publicityManage.publicityType.3') }}</SelectOption>
                      <SelectOption :value="4">{{ $t('publicityManage.publicityType.4') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('publicityManage.field.status')" name="status">
                    <Select v-model:value="form.status" :placeholder="$t('publicityManage.validate.status')" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('publicityManage.field.title')" name="title">
                    <Input :placeholder="$t('publicityManage.validate.title')"
                           v-model:value="form.title">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">

                </Col>

                <Col span="22">
                  <FormItem :label="$t('publicityManage.field.remark')" name="remark">
                    <Textarea :placeholder="$t('publicityManage.validate.remark')"
                              v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>

                <Col span="22" name="content">
<!--                  旧版本-->
<!--                    <MyEditor-->
<!--                        ref="myEditor"-->
<!--                        :content="form.content"-->
<!--                        :getFullText="(t)=>form.content = t"-->
<!--                    />-->
<!--                  新版本-->
                  <MyEditor5
                      ref="myEditor"
                      :content="form.content"
                      @getFullText="(t)=>form.content = t"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
export default {
  name: "FormModel"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
  Select,
  SelectOption,
} from "ant-design-vue";
import {PublicityAddInfo, PublicityEditInfo} from "@/api/publicity";
import MyEditor5 from "@/components/MyEditor5";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()

let confirmLoading = ref(false)
let visible = ref(false)
let title = ref("")
let actionType = ref("")

const validateRules = {
  id: [
    {
      required: false,
      message: i18n.t("publicityManage.validate.id"),
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: i18n.t("publicityManage.validate.type"),
      trigger: "change",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("publicityManage.validate.status"),
      trigger: "change",
    },
  ],
  title: [
    {
      required: true,
      message: i18n.t("publicityManage.validate.title"),
      trigger: "change",
    },
  ],
  remark: [
    {
      required: false,
      message: i18n.t("publicityManage.validate.remark"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  form.value = obj;
  visible.value = true;
}

function submit() {
  refForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new PublicityAddInfo();
        break;
      case "edit":
        api = new PublicityEditInfo();
        break;
      default:
        return false;
    }
    // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
    delete form.value.createTime
    delete form.value.updateTime
    confirmLoading.value = true
    api.post(form.value).then(response => {
      confirmLoading.value = false
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        // 触发主页面组件定义事件
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {
    id: undefined,
    type: undefined,
    status: undefined,
    title: undefined,
    content: undefined,
    remark: undefined,
  };
}

defineExpose({
  addShow,
  editShow
})
</script>

<style lang="less">
</style>
