import {Base} from "@/api/Base"

const api = "order"

export class OrderAdd extends Base {
    path = api + "/add"
}

export class OrderDelete extends Base {
    path = api + "/delete"
}

export class OrderList extends Base {
    path = api + "/list"
}

export class OrderEdit extends Base {
    path = api + "/edit"
}

// 导出Excel
export class OrderExportToExcel extends Base {
    path = api + "/exportToExcel"
}

// 获取订单信息
export class OrderGetInfoByIdBalLog extends Base {
    path = api + "/getInfoByIdBalLog"
}

// 根据订单id取消订单
export class OrderCancelOrderById extends Base {
    path = api + "/cancelOrderById"
}
