<template>
  <Modal
      id="EmployeeEdit"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('employee.field.id_rol')" name="idRol">
                    <!--关联表的下拉选择-->
                    <Select
                        v-model:value="form.idRol"
                        :placeholder="$t('employee.validate.required.id_rol')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption :key="sk" v-for="(item,sk) in roleList" :value="item['idRol']">
                        {{ item['name'] }}
                      </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.account')" name="account">
                    <Input :placeholder="$t('employee.validate.required.account')" v-model:value="form.account" :disabled="actionType !== 'add'"></Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                  <FormItem :label="$t('employee.field.password')" name="password">
                    <InputPassword :placeholder="$t('employee.validate.required.password')" v-model:value="form.password"></InputPassword>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.email')" name="email">
                    <Input :placeholder="$t('employee.validate.required.email')" v-model:value="form.email"></Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.whatsAppPhone')" name="phone">
                    <Input :placeholder="$t('employee.validate.required.whatsAppPhone')"
                           v-model:value="form.phone">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.name')" name="name">
                    <Input :placeholder="$t('employee.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.status')" name="status">
                    <Select
                        v-model:value="form.status"
                        :placeholder="$t('employee.validate.required.status')"
                        :options="statusList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.commission_rate')" name="commissionRate">
                    <InputNumber
                        style="width: 100%"
                        v-model:value="form.commissionRate"
                        :placeholder="$t('employee.validate.required.commission_rate')"
                        :step="0.01"
                    />
                  </FormItem>
                </Col>
                <!--                <Col span="10">-->
                <!--                  <FormItem :label="$t('employee.field.yedpay_api_key')" name="yedpayApiKey">-->
                <!--                    <Input :placeholder="$t('employee.validate.required.yedpay_api_key')"-->
                <!--                           v-model:value="form.yedpayApiKey">-->
                <!--                    </Input>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->
                <!--                <Col span="10">-->
                <!--                  <FormItem :label="$t('employee.field.yedpay_sign_key')" name="yedpaySignKey">-->
                <!--                    <Input :placeholder="$t('employee.validate.required.yedpay_sign_key')"-->
                <!--                           v-model:value="form.yedpaySignKey">-->
                <!--                    </Input>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->
                <!--                <Col span="10">-->
                <!--                  <FormItem :label="$t('employee.field.tenant_key')" name="tenantKey">-->
                <!--                    <Input :placeholder="$t('employee.validate.required.tenant_key')"-->
                <!--                           v-model:value="form.tenantKey">-->
                <!--                    </Input>-->
                <!--                  </FormItem>-->
                <!--                </Col>-->
                <Col span="10">

                </Col>
                <Col span="22">
                  <FormItem :label="$t('employee.field.remark')" name="remark">
                      <Textarea
                          v-model:value="form.remark"
                          :placeholder="$t('employee.validate.required.remark')"
                          :auto-size="{ minRows: 5, maxRows: 10 }"
                          showCount=""
                      />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "EmployeeEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  InputPassword,
  InputNumber,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {EmployeeAdd, EmployeeEdit} from "@/api/employee";
import {RoleListAll} from "@/api/role"
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n"

const i18n = useI18n()

const emits = defineEmits(['updateList'])

const refForm = ref()
let visible = ref(false)
let title = ref('')
let actionType = ref('')

const validateRules = {
  idEmp: [
    {
      required: true,
      message: i18n.t("employee.validate.required.id_emp"),
      trigger: "change",
      type: "number",
    },
  ],
  idRol: [
    {
      required: true,
      message: i18n.t("employee.validate.required.id_rol"),
      trigger: "change",
      type: "number",
    },
  ],
  account: [
    {
      required: true,
      message: i18n.t("employee.validate.required.account"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("employee.validate.required.password"),
      trigger: "change",
    },
  ],
  salt: [
    {
      required: true,
      message: i18n.t("employee.validate.required.salt"),
      trigger: "change",
    },
  ],
  lastLoginTime: [
    {
      required: true,
      message: i18n.t("employee.validate.required.last_login_time"),
      trigger: "change",
    },
  ],
  registerTime: [
    {
      required: true,
      message: i18n.t("employee.validate.required.register_time"),
      trigger: "change",
    },
  ],
  loginFailureCounter: [
    {
      required: true,
      message: i18n.t("employee.validate.required.login_failure_counter"),
      trigger: "change",
      type: "number",
    },
  ],
  email: [
    {
      required: true,
      message: i18n.t("employee.validate.required.email"),
      trigger: "change",
      type: "email",
    },
  ],
  phone: [
    {
      required: true,
      message: i18n.t("employee.validate.required.whatsAppPhone"),
      trigger: "change",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    }
  ],
  status: [
    {
      required: true,
      message: i18n.t("employee.validate.required.status"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("employee.validate.required.name"),
      trigger: "change",
    },
  ],
  commissionRate: [
    {
      required: true,
      message: i18n.t("employee.validate.required.commission_rate"),
      trigger: "change",
      type: "number",
    },
  ],
  // yedpayApiKey: [
  //   {
  //     required: true,
  //     message: i18n.t("employee.validate.required.yedpay_api_key"),
  //     trigger: "change",
  //   },
  // ],
  // yedpaySignKey: [
  //   {
  //     required: true,
  //     message: i18n.t("employee.validate.required.yedpay_sign_key"),
  //     trigger: "change",
  //   },
  // ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("employee.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
}
const statusList = [{value: 0, label: 'Off'}, {value: 1, label: 'On'}]

const data = reactive({
  form: {},
  roleList: [],
})
const {form, roleList} = toRefs(data)

function addShow(rList) {
  actionType.value = "add";
  title.value = i18n.t("public.name.add");
  _resetData();
  roleList.value = rList
  visible.value = true;
}

async function editShow(obj, rList) {
  _resetData();
  actionType.value = "edit";
  title.value = i18n.t("public.name.edit");
  form.value = obj;
  roleList.value = rList
  visible.value = true;
}

function _resetData() {
  form.value = {};
}

function submit() {
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new EmployeeAdd();
        break;
      case "edit":
        api = new EmployeeEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {
  });
}

defineExpose({
  addShow,
  editShow
});
</script>
