import {Base} from "@/api/Base"

const api = "config"

export class ConfigGetConfig extends Base {
    path = api + "/getConfig"
}

export class ConfigSetConfig extends Base {
    path = api + "/setConfig"
}