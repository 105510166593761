<template>
  <Modal id="WallerDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('public.name.detail')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('waller.table.walletId')">{{data.walletId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('waller.table.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('waller.table.type')">
        <Tag v-if="data.type === 1" color="orange">{{ $t('waller.wType.1') }}</Tag>
        <Tag v-else-if="data.type === 2" color="blue">{{ $t('waller.wType.2') }}</Tag>
        <Tag v-else-if="data.type === 3" color="green">{{ $t('waller.wType.3') }}</Tag>
        <Tag v-else>{{ data.type }}</Tag>
      </DescriptionsItem>
      <DescriptionsItem :label="$t('waller.table.status')">
        <Tag v-if="data.status === 1" color="red">{{ $t("public.isTrue.1") }}</Tag>
        <Tag v-else color="green">{{ $t("public.isTrue.0") }}</Tag>
      </DescriptionsItem>
      <DescriptionsItem :label="$t('waller.table.balance')">{{data.balance}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateTime')">{{data.updateTime}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "WallerDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem, Tag} from "ant-design-vue";
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

let visible = ref(false)

let data = ref({})

async function detailShow(obj) {
  data.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;
}
defineExpose({
  detailShow
})
</script>

<style lang="less">
</style>