<template>
  <Modal
      id="UpdateFirmwareModel"
      width="50%"
      :open="visible"
      :confirmLoading="confirmLoading"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('chargingStation.table.action.updateFirmware')"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">

                <Col span="10">
                  <FormItem :label="$t('chargingStation.updateFirmware.identifier')" name="identifier">
                    <Input
                        :placeholder="$t('chargingStation.uValidate.identifier')"
                        v-model:value="form.identifier" disabled>
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">

                </Col>

                <Col span="22">
                  <FormItem :label="$t('chargingStation.updateFirmware.location')" name="location">
                    <Textarea style="height: 150px" :placeholder="$t('chargingStation.uValidate.location')"
                           v-model:value="form.location">
                    </Textarea>
                  </FormItem>
                </Col>

<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('chargingStation.updateFirmware.retrieveDate')" name="retrieveDate">-->
<!--                    <DatePicker-->
<!--                        style="width:100%"-->
<!--                        :placeholder="$t('chargingStation.uValidate.retrieveDate')"-->
<!--                        :getCalendarContainer="(triggerNode) => triggerNode.parentNode"-->
<!--                        format="YYYY-MM-DD HH:mm:ss"-->
<!--                        :showTime="{ defaultValue: defTime() }"-->
<!--                        v-model:value="form.retrieveDate"-->

<!--                        :disabledDate="disabledDate"-->
<!--                        :disabledDateTime="disabledDateTime"-->
<!--                        @ok="onOk"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->

                <Col span="10"></Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "UpdateFirmwareModel",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
} from "ant-design-vue";
import { ChargingStationUpdateFirmware } from "@/api/chargingStation";
import {reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import dayjs from 'dayjs';

const store = useStore()
const i18n = useI18n()

const refForm = ref()

let confirmLoading = ref(false)
let visible = ref(false)
let actionType = ref("")

const statusList = [
  {value: 0, label: 'Downloaded'},
  {value: 1, label: 'DownloadFailed'},
  {value: 2, label: 'Downloading'},
  {value: 3, label: 'Idle'},
  {value: 4, label: 'InstallationFailed'},
  {value: 5, label: 'Installing'},
  {value: 6, label: 'Installed'},
]
const validateRules = {
  identifier: [
    {
      required: true,
      message: i18n.t("chargingStation.uValidate.identifier"),
      trigger: "change",
    },
  ],
  location: [
    {
      required: true,
      message: i18n.t("chargingStation.uValidate.location"),
      trigger: "change",
    },
  ],
  // retrieveDate: [
  //   {
  //     required: true,
  //     message: i18n.t("chargingStation.uValidate.retrieveDate"),
  //     trigger: "change",
  //   },
  // ],
}

const data = reactive({
  form: {},
})
const {form} = toRefs(data)

function onShow(obj) {
  _resetData();
  form.value = JSON.parse(JSON.stringify(obj));
  form.value.identifier = form.value.chargeBoxId
  visible.value = true;
}

function submit() {
  refForm.value?.validate().then(() => {
    confirmLoading.value = true
    // 构建参数
    const param = {
      identifier: form.value.identifier,
      location: form.value.location,
    }

    let api = new ChargingStationUpdateFirmware()
    api.post(param).then((response) => {
      confirmLoading.value = false
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    }).catch().finally(()=>{
      visible.value = false;
    });
  }).catch(() => {});
}

function onOk(value) {
  form.value.retrieveDatet = value.format('YYYY-MM-DD HH:mm:ss')
}

function range(start, end) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}



// 今天之前的日期不可选（不包括今天）
function disabledDate(date) {
  return date < dayjs(dayjs(new Date()).format("YYYY-MM-DD "));
}

function _resetData() {
  form.value = {};
}

defineExpose({
  onShow
})
</script>

<style lang="less">
@import url("style");
</style>
