import { Base,Axios } from "@/api/Base"

export class Uploads extends Base {
    //此上传接口配合上传控件使用，如项目需要使用上传控件，则需要修改如下上传接口
    path = "common"
    upload(param){
        return Axios.post(this.path + "/upload", param, {
            headers: {
                "Content-Type":"multipart/form-data"
            }
        })
    }
    show(url) {
        return Axios.get(url, { responseType: 'arraybuffer' })
    }

    download(url) {
        return Axios.get(url, { responseType: "blob" })
    }
}

// 获取文件直链地址
export class GetUrl extends Base {
    path = "common" + "/getUrl"
}

// 获取多文件直链地址
export class GetUrlList extends Base {
    path = "common" + "/getUrlList"
}