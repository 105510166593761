<template>
  <Modal id="BalanceLogDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible"
         :title="$t('balanceLog.name.detailTitle')" :zIndex="9999999900">
    <Descriptions>
      <DescriptionsItem :label="$t('balanceLog.field.id_bal_log')">{{ data.idBalLog }}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.id_use')">{{ data.createBy }}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.type')">{{ data.type }}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.amount')">{{ data.amount }}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.log_time')">{{ data.logTime }}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('balanceLog.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('balanceLog.field.status')">{{data.status}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.payment_method')">{{ data.paymentMethod }}</DescriptionsItem>
      <DescriptionsItem label="Pay ID">{{data.customerId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.createBy')">{{data.createBy}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.createTime')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateBy')">{{handelNull(data.updateBy)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateTime')">{{handelNull(data.updateTime)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.remark')">{{handelNull(data.remark)}}</DescriptionsItem>
    </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "BalanceLogDetail1"
}
</script>
<script setup>
import {Modal, Descriptions, DescriptionsItem} from "ant-design-vue";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {BalanceLogGetInfo} from "@/api/balanceLog";
import {useI18n} from 'vue-i18n'
import {ref} from "vue";

const i18n = useI18n()

let data = ref({})
let visible = ref(false)

async function detailShow(obj) {
  // 获取变帐信息
  const api = new BalanceLogGetInfo()
  api.get({id: obj.idBalLog}).then(res => {
    let dds = res.data.data
    dds.type = i18n.t("public.consumeType." + (dds.type-1))

    switch (dds.status) {
      case 1:
        dds.status = i18n.t("public.payStatus.0")
        break;
      case 2:
        dds.status = i18n.t("public.payStatus.3")
        break;
    }
    data.value = res.data.data;
  })
  visible.value = true;
}
function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow,
});
</script>