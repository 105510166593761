<template>
  <Modal id="BalanceLogDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('balanceLog.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('balanceLog.field.id_bal_log')">{{data.idBalLog}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.id_use')">{{data.userAccount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('user.table.phone')">{{data.userPhone}}</DescriptionsItem>

      <DescriptionsItem :label="$t('balanceLog.field.type')">{{selectList.typeList.find(item=>item.value===data.type)?.label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.amount')">{{data.amount}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.log_time')">{{data.logTime}}</DescriptionsItem>

      <!-- <DescriptionsItem :label="$t('balanceLog.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('balanceLog.field.status')">{{selectList.statusList.find(item=>item.value===data.status)?.label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('balanceLog.field.payment_method')">{{data.paymentMethod}}</DescriptionsItem>
      <DescriptionsItem label="YedPay ID">{{handelNull(data.customerId)}}</DescriptionsItem>

      <DescriptionsItem :label="$t('public.name.createBy')">{{data.createBy}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.createTime')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.updateBy')">{{handelNull(data.updateBy)}}</DescriptionsItem>

      <DescriptionsItem :label="$t('public.name.updateTime')">{{handelNull(data.updateTime)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('public.name.remark')">{{handelNull(data.remark)}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "BalanceLogDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref} from "vue";
import {useI18n} from 'vue-i18n'

const i18n = useI18n()
let visible = ref(false)
let data = ref({})
const selectList = reactive({
  typeList: [
    {value: 1,label: i18n.t("public.consumeType.0")},
    {value: 2,label: i18n.t("public.consumeType.1")},
    {value: 3,label: i18n.t("public.consumeType.2")},
    {value: 4,label: i18n.t("public.consumeType.3")},
    {value: 5,label: i18n.t("public.consumeType.4")},
    {value: 6,label: i18n.t("public.consumeType.5")},
    {value: 7,label: i18n.t("public.consumeType.6")},
    {value: 8,label: i18n.t("public.consumeType.7")},
    {value: 9,label: i18n.t("public.consumeType.8")},
    {value: 10,label: i18n.t("public.consumeType.9")},
  ],
  statusList: [
    {value: 1,label: i18n.t("public.payStatus.0")},
    {value: 2,label: i18n.t("public.payStatus.3")},
    {value: 3, label: i18n.t("public.payStatus.4")},
  ],
})

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}
function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow,
});
</script>

<style lang="less">
</style>