<template>
  <Modal
      id="TenantEdit"
      width="60%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
      :destroyOnClose="true"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around" :gutter="[18,18]">
                <Col :span="12">
                  <Row v-if="actionType === 'edit'">
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.id')" name="id">
                        <Input :placeholder="$t('tenantInfo.validate.id')"
                               v-model:value="form.id" disabled>
                        </Input>
                      </FormItem>
                    </Col>
                  </Row>
<!--                  <Row>-->
<!--                    <Col span="24">-->
<!--                      <FormItem :label="$t('tenantInfo.table.pid')" name="pid">-->
<!--                        <Input :placeholder="$t('tenantInfo.validate.pid')"-->
<!--                               v-model:value="form.pid" :disabled="disabled" >-->
<!--                        </Input>-->
<!--                      </FormItem>-->
<!--                    </Col>-->
<!--                  </Row>-->
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.tenantKey')" name="tenantKey">
                        <Input
                            :placeholder="$t('tenantInfo.validate.tenantKey')"
                            v-model:value="form.tenantKey"
                            :disabled="disabled || actionType==='edit'"
                        />
                      </FormItem>
                    </Col>
                  </Row>
<!--                  <Row>-->
<!--                    <Col span="24">-->
<!--                      <FormItem :label="$t('tenantInfo.table.domain')" name="domain">-->
<!--                        <Input-->
<!--                            :placeholder="$t('tenantInfo.validate.domain')"-->
<!--                            v-model:value="form.domain" :disabled="disabled"-->
<!--                        />-->
<!--                      </FormItem>-->
<!--                    </Col>-->
<!--                  </Row>-->
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.tpId')" name="tpId">
<!--                        <Input-->
<!--                            :placeholder="$t('tenantInfo.validate.tpId')"-->
<!--                            v-model:value="form.tpId"-->
<!--                        />-->
                        <Select
                            v-model:value="form.tpId"
                            :placeholder="$t('tenantInfo.validate.tpId')"
                            :options="tenantPackageSelectList"
                            :dropdownStyle="{ zIndex: 9999999950 }"
                            :disabled="disabled"
                        >
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>

<!--                  <Row>-->
<!--                    <Col span="24">-->
<!--                      <FormItem :label="$t('tenantInfo.table.level')" name="level">-->
<!--                        <Input-->
<!--                            :placeholder="$t('tenantInfo.validate.level')"-->
<!--                            v-model:value="form.level" :disabled="disabled"-->
<!--                        />-->
<!--                      </FormItem>-->
<!--                    </Col>-->
<!--                  </Row>-->
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.contact')" name="contact">
                        <Input
                            :placeholder="$t('tenantInfo.validate.contact')"
                            v-model:value="form.contact"
                            :disabled="disabled"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.email')" name="email">
                        <Input
                            :placeholder="$t('tenantInfo.validate.email')"
                            v-model:value="form.email"
                            :disabled="disabled"
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.phone')" name="phone">
                        <Input
                            :placeholder="$t('tenantInfo.validate.phone')"
                            v-model:value="form.phone"
                            :disabled="disabled"
                        />
                      </FormItem>
                    </Col>
                  </Row>

<!--                  <Row>-->
<!--                    <Col span="24">-->
<!--                      <FormItem :label="$t('tenantInfo.table.startTime')" name="startTime">-->
<!--                        <Input-->
<!--                            :placeholder="$t('tenantInfo.validate.startTime')"-->
<!--                            v-model:value="form.startTime" :disabled="disabled"-->
<!--                        />-->
<!--                      </FormItem>-->
<!--                    </Col>-->
<!--                  </Row>-->
<!--                  <Row>-->
<!--                    <Col span="24">-->
<!--                      <FormItem :label="$t('tenantInfo.table.expireTime')" name="expireTime">-->
<!--                        <Input-->
<!--                            :placeholder="$t('tenantInfo.validate.expireTime')"-->
<!--                            v-model:value="form.expireTime" :disabled="disabled"-->
<!--                        />-->
<!--                      </FormItem>-->
<!--                    </Col>-->
<!--                  </Row>-->
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('public.name.status')" name="status">
                        <Select v-model:value="form.status" :placeholder="$t('public.pleaseSelect')"
                                :dropdownStyle="{zIndex: 9999999950}" :disabled="disabled">
                          <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                          <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col :span="12">
                  <Row>
                    <Col span="24">
                      <FormItem :label="$t('tenantInfo.table.authId')" name="authId">
                        <div style="height: 500px; overflow: auto;">
                          <Tree
                              checkable
                              v-model:checkedKeys="checkedKeys"
                              :tree-data="treeData"
                              autoExpandParent
                              @check="checkTree"
                          />
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "TenantEdit"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  Select,
  SelectOption,
  message,
  Tree
} from "ant-design-vue";
import {TenantAdd, TenantEdit,  TenantGetAuthTreeByTenant, selectAuthIdsByTenantId} from "@/api/tenantInfo";
import {ref, reactive, toRefs} from "vue";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const i18n = useI18n()
const store = useStore()

const emits = defineEmits(['updateList'])

let title = ref("")
let visible = ref(false)
let actionType = ref("")
let disabled = ref(false)

const refForm = ref()

const validateRules = reactive({
  pid: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.pid"),
      trigger: "change",
      type: "number",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.tenantKey"),
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.name"),
      trigger: "change",
    },
  ],
  domain: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.domain"),
      trigger: "change",
    },
  ],
  tpId: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.tpId"),
      trigger: "change",
    },
  ],
  contact: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.contact"),
      trigger: "change",
    },
  ],
  email: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.email"),
      trigger: "change",
    },
  ],
  phone: [
    {
      required: true,
      message: i18n.t("tenantInfo.validate.phone"),
      trigger: "change",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("public.name.status"),
      trigger: "change",
    },
  ],
},)
const data = reactive({
  form: {},
  treeData: [],
  checkedKeys: [],
  fileList: {},
  selectList: {},
  tenantPackageSelectList: []
})
const {form, treeData, checkedKeys, tenantPackageSelectList} = toRefs(data)

function addShow(sel) {
  disabled.value = false
  title.value = i18n.t("public.name.add")
  actionType.value = "add"
  tenantPackageSelectList.value = sel
  _resetData()
  getTreeData()
  visible.value = true
}

async function editShow(obj, sel) {
  disabled.value = store.getters.getTenantKey !== 'admin' && store.getters.getTenantKey === obj.tenantKey
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  tenantPackageSelectList.value = sel
  _resetData();
  getTreeData(obj.tenantKey)
  getAuthIds(obj.id)
  form.value = obj;
  visible.value = true;
}

function submit() {
  if (disabled.value) {
    return false
  }
  refForm.value?.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new TenantAdd();
        break;
      case "edit":
        api = new TenantEdit();
        break;
      default:
        return false;
    }
    form.value.checkedKeys = checkedKeys.value
    api.post(form.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch(() => {
  });
}

function checkTree(e) {
  // console.log("checkedKeys =>", checkedKeys.value)
}

function getTreeData(k) {
  const api = new TenantGetAuthTreeByTenant()
  api.get({tenantKey: k}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      treeData.value = response.data.data === null || response.data.data === undefined ? [] : response.data.data
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function getAuthIds(id) {
  const api = new selectAuthIdsByTenantId()
  api.get({id:id}).then((response) => {
    if (0 === parseInt(response.data.code)) {
      checkedKeys.value = response.data.data
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

function _resetData() {
  checkedKeys.value = []
  form.value = {};
}

defineExpose({
  addShow,
  editShow
});
</script>
