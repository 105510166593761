import {Base} from "@/api/Base"

export class SettingsAdd extends Base {
    path = "settings/add"
}

export class SettingsGetByTag extends Base {
    path = "settings/getByTag"
}

export class SettingsUploadIconStr extends Base {
    path = "settings/uploadIconStr"
}

export class SettingsGetIconStr extends Base {
    path = "settings/getIconStr"
}