const thTH = {
    'succeedMsg': {
        '0': 'บรรลุวัตถุประสงค์',
    },
    'errorMsg': {
        '500100': 'ข้อยกเว้นฝั่งเซิร์ฟเวอร์',
        '500101': 'ข้อผิดพลาดของพารามิเตอร์',
        '500102': 'ข้อยกเว้นการตรวจสอบพารามิเตอร์',
        '500103': 'คำขอผิดกฎหมาย',
        '500104': 'การเยี่ยมชมบ่อยเกินไป',
        '500105': 'วัตถุมีอยู่แล้ว',
        '500106': 'การดำเนินการล้มเหลว โปรดลองอีกครั้ง',
        '500107': 'มีการเชื่อมโยง ห้ามลบ',
        '500108': 'ข้อยกเว้นการส่งออก',
        '500200': 'เข้าสู่ระบบหมดอายุ โปรดเข้าสู่ระบบอีกครั้ง',
        '500201': 'หมายเลขบัญชีหรือรหัสผ่านจะไม่ว่างเปล่า',
        '500202': 'หมายเลขโทรศัพท์ไม่ว่างเปล่า',
        '500203': 'หมายเลขโทรศัพท์อยู่ในรูปแบบที่ไม่ถูกต้อง',
        '500204': 'ไม่มีหมายเลขโทรศัพท์อยู่',
        '500205': 'รหัสผ่านไม่ถูกต้อง',
        '500206': 'บัญชีไม่มีอยู่จริง',
        '500207': 'หมายเลขบัญชีสามารถมีตัวเลข ภาษาอังกฤษ และขีดเส้นใต้',
        '500208': 'รับรองความถูกต้องล้มเหลว',
        '500209': 'รหัสผ่านสองรหัสไม่เหมือนกัน',
        '500210': 'บัญชีที่ซ้ำกัน',
        '500211': 'บัญชีถูกล็อค',
        '500212': 'Captcha ล้มเหลว',
        '500213': 'หมายเลขโทรศัพท์ผิด',
        '500214': 'ไม่มีผู้เช่าที่สอดคล้องกับชื่อโดเมน ติดต่อผู้ดูแลระบบของคุณ',
        '500215': 'มีชื่อบทบาทเดียวกัน',
        '500300': 'หากบริการ OCCP ผิดปกติ ติดต่อผู้ดูแลระบบ',
        '500301': 'ไม่มีกองไฟฟ้า',
        '500302': 'การกำหนดค่าเสาเข็มว่างเปล่า',
        '500303': 'แบตเตอรี่ออฟไลน์ โปรดตรวจสอบว่ามีอยู่หรือไม่',
        '500304': 'พอร์ตเสาเข็มไม่พร้อมใช้งาน',
        '500305': 'ปืนไม่ได้แทรกหรือปืนปัจจุบันยังไม่พร้อม',
        '500306': 'เริ่มชาร์จความล้มเหลว',
        '500307': 'รวมความล้มเหลวในการชาร์จ',
        '500401': 'สมดุลไม่เพียงพอ',
        '500402': 'คำสั่งซื้อไม่มีอยู่จริง',
        '500403': 'คำสั่งไม่ได้ชำระเงิน',
        '500404': 'การชำระเงินสำหรับการสั่งซื้อ',
        '500405': 'สถานะการสั่งซื้อไม่ใช่ [ไม่ได้ใช้จ่าย]',
        '500406': 'การสร้างคำสั่งซื้อล้มเหลว',
        '500407': 'มีคำสั่งค้างชำระ',
        '500408': 'ยกเลิกคำสั่งซื้อ',
        '500501': 'รายการถูกขายในไม่กี่วินาที',
        '500502': 'การเทรดแบบ Lightning ไม่สามารถทำซ้ำได้',
        '500503': 'การฆ่าครั้งที่สองล้มเหลว',
        '500601': 'ไฟล์ไม่สามารถว่างเปล่า',
        '500602': 'ข้อผิดพลาดรูปแบบไฟล์',
        '500603': 'สมุดงาน Excel ว่างเปล่า',
        '500604': 'ไม่สามารถอ่านไฟล์ได้'
    },
    'public': {
        'name': {
            'nextStep': 'ขั้นตอนต่อไป',
            'status': 'สถานะ',
            'hello': 'สวัสดี',
            'login': 'เข้าสู่ระบบ',
            'logout': 'ออกจากระบบ',
            'username': 'ชื่อผู้ใช้',
            'email': 'อีเมล',
            'password': 'รหัสผ่าน',
            'remember': 'จำฉันไว้',
            'currentPassword': 'รหัสผ่านปัจจุบัน',
            'newPassword': 'รหัสผ่านใหม่',
            'confirm_password': 'ยืนยันรหัสผ่าน',
            'reset_password': 'รีเซ็ตรหัสผ่าน',
            'captcha': 'แคปชา',
            'getCaptcha': '"ับแคปต์ชา',
            'more': 'มากกว่า',
            'add': 'เพิ่ม',
            'batchDel': 'การลบแบทช์',
            'Export': 'ส่งออก',
            'Import': 'นำเข้า',
            'DownloadTemp': 'ดาวน์โหลดแม่แบบ',
            'ImportTips': 'คลิกหรือลากไฟล์เพื่ออัปโหลดในพื้นที่นี้',
            'edit': 'แก้ไข',
            'del': 'ลบ',
            'detail': 'รายละเอียด',
            'yes': 'ใช่',
            'no': 'ไม่',
            'empty': 'ว่างเปล่า',
            'are_you_sure_delete': 'คุณแน่ใจหรือไม่ว่าต้องการลบรายการนี้',
            'action_cannot_resumed': 'การกระทำนี้ไม่สามารถดำเนินการต่อได้',
            'are_you_sure_operate': 'ไม่ว่าจะเป็นการกำหนดให้การดำเนินการในปัจจุบัน',
            'operate_remind': 'พิจารณาว่าการดำเนินงานในปัจจุบันมีความอ่อนไหวต่อการดำเนินงานในปัจจุบันโปรดใช้ความระมัดระวัง',
            'search': 'ค้นหา',
            'reset': 'รีเซ็ต',
            'spread': 'การแพร่กระจาย',
            'retract': 'ดึงกลับ',
            'show': 'แสดง',
            'no_page': 'ไม่มีเพจ',
            'service': 'บริการ',
            'phone_country_code': 'รหัสประเทศโทรศัพท์',
            'name': 'ชื่อ',
            'phone': 'โทรศัพท์',
            'sex': 'เพศ',
            'country': 'ประเทศ',
            'address': 'ที่อยู่',
            'birthday': 'วันเกิด',
            'remark': 'สังเกต',
            'personal': 'ส่วนตัว',
            'enclosure': 'สิ่งที่แนบมา',
            'logo': 'โลโก้',
            'submit': 'ส่ง',
            'unknown': 'ไม่รู้จัก',
            'setting': 'สถาปนา',
            'index': 'หมายเลขซีเรียล',
            'createBy': 'สร้างโดย',
            'createTime': 'สร้างเวลา',
            'updateBy': 'อัปเดตโดย',
            'updateTime': 'อัปเดตเวลา',
            'type': 'ประเภท',
            'args': 'พารามิเตอร์',
            'qrcode': 'รหัส QR',
            'query': 'สอบถาม',
            'refund': 'การคืนเงิน',
            'cancel': 'การยกเลิก'
        },
        'msg': {
            'unRead': 'ยกเลิกการอ่าน',
            'read': 'อ่านหนังสือ',
            'alarm': 'ความหวาดกลัว',
            'reminder': 'เตือนความจำ',
            'advertise': 'ลงโฆษณา'
        },
        'language': {
            'zhTW': 'จีน (ดั้งเดิม)',
            'enUS': 'ภาษาอังกฤษ',
            'zhCN': 'ภาษาจีน (ง่าย)',
            'daDK': 'เดนมาร์ก',
            'idID': 'อินโดนีเซีย',
            'msMY': 'มาเลย์',
            'thTH': 'ไทย',
            'viVN': 'เวียดนาม',
            'frFR': 'ฝรั่งเศส',
            'plPL': 'โปแลนด์',
            'it': 'อิตาลี',
        },
        'timeType': {
            0: 'ปี',
            1: 'เดือน',
            2: 'อาทิตย์',
            3: 'กลางวัน',
        },
        'promptsToSelectTime': 'โปรดเลือกเวลา',
        'pleaseSelect': 'กรุณาเลือก',
        'pileStatus': {
            0: 'ใช้ได้',
            1: 'ชาร์จ',
            2: 'ไม่',
        },
        'isTrue': {'0': 'ไม่', '1': 'ใช่'},
        'chargerType': {'0': 'กระแสสลับ', '1': 'กระแสตรง'},
        'registerType': {'0': 'อีเมล', '1': 'โทรศัพท์'},
        'doingStatus':{
            0: 'แขวนอยู่',
            1: 'กำลังดำเนินการ',
            2: 'เสร็จสมบูรณ์',
            3: 'การยกเลิก',
            4: 'หมดอายุ',
            5: 'การสิ้นสุด',
        },
        'consumeType': {
            0: "การชาร์จ",
            1: "การบริโภคการชาร์จ",
            2: "ค่าธรรมเนียมการยกเลิก",
            3: "สมดุล",
            4: 'แผนการจัดซื้อจัดจ้าง',
            5: 'แผนการยกเลิก',
            6: 'แผนการปรับปรุง',
            7: 'การคืนเงิน',
            8: 'ใบปรับ',
            9: 'การยกเลิกค่าปรับ',
            10: 'ถอนเงินสด',
            11: 'แจกเต็ม',
        },
        'payStatus': {'0': 'ไม่ได้รับค่าจ้าง', '1': 'จ่าย', '2': 'การชาร์จ', '3': 'สมบูรณ์', '4': 'ยกเลิก'},
        'chargeType': {'0': 'ตามเวลา', '1': 'ตามคำสั่ง', '2': 'ตามแผนผู้ใช้'},
        'status': {'0': 'ไม่รู้จัก', '1': 'ปกติ', '2': 'ปิดการใช้งาน', '3': 'ลบ'},
        'sex': {'1': 'ผู้ชาย', '2': 'ผู้หญิง', '3': 'ไม่รู้จัก'},
        'placeholder': {'please_select': 'โปรดเลือก'},
        'table': {
            'name': 'ชื่อ',
            'status': 'สถานะ',
            'action': 'การกระทำ',
            'content': 'เนื้อหา',
            'created_at': 'สร้างเวลา',
            'image': 'ภาพ',
            'email': 'อีเมล',
            'order_id': 'คำสั่ง',
            'sex': 'เพศ',
            'last_ip': 'IP ล่าสุด',
            'phone_country_code': 'กรุณาเลือกรหัสประเทศโทรศัพท์',
            'phone': 'โทรศัพท์'
        },
        'field': {
            'name': 'ชื่อ',
            'username': 'ชื่อผู้ใช้',
            'content': 'เนื้อหา',
            'phone_country_code': 'รหัสประเทศโทรศัพท์',
            'phone': 'โทรศัพท์',
            'email': 'อีเมล',
            'order_id': 'คำสั่ง',
            'portrait': 'ภาพเหมือน',
            'status': 'สถานะ',
            'image': 'ภาพ',
            'sex': 'เพศ'
        },
        'validate': {
            'required': {
                'id': 'ขาดรหัส',
                'username': 'กรุณาป้อนชื่อผู้ใช้',
                'password': 'กรุณาเข้ารหัสผ่าน',
                'repassword': 'กรุณาทำซ้ำรหัสผ่าน',
                'name': 'กรุณาชื่อรายการ',
                'phone': 'กรุณาเข้าโทรศัพท์',
                'email': 'กรุณาเข้าอีเมล',
                'portrait': 'กรุณาอัปโหลดภาพบุคคล'
            },
            'tips': {
                'inconsistent_password': 'รหัสผ่านที่ไม่สอดคล้องกัน',
                'length_should': 'ความยาวควรเป็น {min} ถึง {max}'
            }
        }
    },
    'language': {
        'zhTW': 'จีน (ดั้งเดิม)',
        'enUS': 'ภาษาอังกฤษ',
        'zhCN': 'ภาษาจีน (ง่าย)',
        'daDK': 'เดนมาร์ก',
        'idID': 'อินโดนีเซีย',
        'msMY': 'มาเลย์',
        'thTH': 'ไทย',
        'viVN': 'เวียดนาม',
        'frFR': 'ฝรั่งเศส',
        'plPL': 'โปแลนด์',
        'it': 'อิตาลี',
    },
    'nav': {
        'name': {
            'Dashboard': 'แผงควบคุม',
            'RegionDashboard': 'ภาพรวมของเสาเข็ม',
            'BigScreen': 'บิ๊กปิง',
            'AuthorityManage': 'ผู้มีอำนาจจัดการ',

            'BalanceLogManage': 'สมดุลของ จัดการบันทึก',
            'CarManage': 'รถจัดการ',
            'CarImagesManage': 'การจัดการภาพรถยนต์',
            'ChargeStationImagesManage': 'จัดเก็บภาพสถานี',
            'ChargingStationManage': 'จัดการสถานีชาร์จ',
            'ConnectorManage': 'ตัวเชื่อมต่อจัดการ',
            'EmployeeManage': 'ผู้ประกอบการจัดการ',
            'ConnectorLogManage': 'บันทึกการเชื่อมต่อ',
            'EventLogManage': 'เหตุการณ์ จัดการบันทึก',
            'MeterManage': 'จัดการมิเตอร์',
            'OrderManage': 'สั่งจัดการ',
            'PlanManage': 'วางแผนจัดการ',
            'RegionManage': 'การจัดการภูมิภาค',
            'RegionImagesManage': 'การจัดการภาพภูมิภาค',
            'RepairLogManage': 'ซ่อมแซม จัดการบันทึก',
            'RoleManage': 'การจัดการบทบาท',
            'TransactionLogManage': 'การทำธุรกรรม จัดการบันทึก',
            'UserManage': 'ผู้ใช้จัดการ',
            'UserPlanManage': 'ผู้ใช้ จัดการแผน',
            'GetConfiguration': 'รับการกำหนดค่า',
            'DataTransfer': 'การถ่ายโอนข้อมูล',

            'CardManage': 'การจัดการหมายเลขบัตร',

            'NoticeManage': 'สังเกตการจัดการ',
            'PublicityManage': 'การจัดการประกาศ',
            'ChargerSetting': 'การตั้งค่าเครื่องชาร์จ',
            'StatisticsManage': 'สถิติ',
            'AppManagement': 'การจัดการแอพ',
            'LogManagement': 'การจัดการบันทึก',
            'AppVersionManage': 'แอพเวอร์ชันจัดการ',
            'WallerManage': 'วงเงินบัญชี',
            'iPadManagement': 'การจัดการ แบน',
            'ServiceManage': 'การจัดการบริการ',
            'iPadManage': 'แบน จัดการ',

            'TenantManagement': 'การจัดการระบบ',
            'SuperTenantManage': 'ซุปเปอร์ ผู้เช่า',
            'TenantManage': 'ผู้เช่าจัดการ',
            'TenantInfo': 'การจัดการผู้เช่า',
            'TenantPackage': 'แพ็คเกจผู้เช่า',
        },
        'table': {'order_id': 'รหัสสั่งซื้อ', 'url': 'URL'},
        'field': {
            'pid': 'PID',
            'order_id': 'รหัสสั่งซื้อ',
            'name': 'ชื่อ',
            'url': 'URL',
            'status': 'สถานะ',
            'icon': 'ไอคอน'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'กรุณาเข้าสู่ชื่อผู้ใช้',
                'email': 'กรุณาเข้าอีเมล',
                'password': 'กรุณาเข้ารหัสผ่าน',
                'captcha': 'กรุณาเข้าร่วม captcha'
            }
        }
    },
    'authority': {
        'name': {'title': 'อำนาจ', 'detailTitle': 'รายละเอียดอำนาจ', 'editTitle': 'แก้ไขอำนาจ'},
        'table': {
            'id_aut': 'รหัสประจำตัว',
            'a_id_aut': 'ผู้ปกครอง',
            'name': 'อำนาจ',
            'resource_name': 'ชื่อทรัพยากร',
            'type': 'พิมพ์',
            'sort': 'เรียงลำดับ'
        },
        'field': {
            'id_aut': 'รหัสประจำตัว',
            'a_id_aut': 'ผู้ปกครอง',
            'name': 'อำนาจ',
            'resource_name': 'ชื่อทรัพยากร',
            'type': 'พิมพ์',
            'sort': 'เรียงลำดับ'
        },
        'validate': {
            'required': {
                'id_aut': 'ต้องใช้รหัส',
                'a_id_aut': 'จำเป็นต้องมีอำนาจหลัก',
                'name': 'ผู้มีอำนาจที่ต้องการ',
                'resource_name': 'ต้องการชื่อทรัพยากร',
                'type': 'ประเภทที่ต้องการ',
                'sort': 'เรียงลำดับที่ต้องการ'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'แพ็คเกจเติมเงิน',
        'addRule': 'เพิ่มกฎ',
        'gift': {
            'enable': 'ไม่ว่าจะเป็นการเปิด',
            'percentage': 'ตามเปอร์เซ็นต์',
            'star': 'ค่าเริ่มต้น',
            'end': 'ค่าสิ้นสุด',
            'give': 'วงเงิน',
        },
        'vGift': {
            'enable': 'โปรดเลือกว่าจะเปิดใช้งานหรือไม่',
            'percentage': 'โปรดเลือกว่าจะตามเปอร์เซ็นต์หรือไม่',
            'star': 'โปรดป้อนค่าเริ่มต้น',
            'end': 'โปรดป้อนค่าสิ้นสุด',
            'give': 'กรุณากรอกวงเงิน',
        },
        'name': {
            'title': 'บันทึกสมดุล',
            'detailTitle': 'รายละเอียดบันทึกสมดุล',
            'editTitle': 'แก้ไขบันทึกยอดคงเหลือ',
            'ticket': 'ใบปรับ',
            'cancelFines': 'การยกเลิกค่าปรับ'
        },
        'table': {
            'id_bal_log': 'รหัสประจำตัว',
            'id_use': 'ผู้ใช้',
            'type': 'พิมพ์',
            'amount': 'จำนวน',
            'log_time': 'เวลาบันทึกเวลา',
            'tenant_key': 'กุญแจผู้เช่า',
            'status': 'สถานะ',
            'payment_method': 'วิธีการชำระเงิน'
        },
        'field': {
            'id_bal_log': 'รหัสประจำตัว',
            'id_use': 'ผู้ใช้',
            'type': 'พิมพ์',
            'amount': 'จำนวน',
            'log_time': 'เวลาบันทึกเวลา',
            'tenant_key': 'กุญแจผู้เช่า',
            'status': 'สถานะ',
            'payment_method': 'วิธีการชำระเงิน'
        },
        'validate': {
            'required': {
                'id_bal_log': 'ต้องใช้รหัส',
                'id_use': 'จำเป็นต้องใช้ ผู้ใช้',
                'type': 'ประเภทที่ต้องการ',
                'amount': 'จำนวนเงินที่ต้องการ',
                'log_time': 'ต้องใช้เวลาบันทึก',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
                'status': 'สถานะที่ต้องการ',
                'payment_method': 'ต้องใช้วิธีการชำระเงิน'
            }
        }
    },
    'car': {
        'name': {'title': 'รถ', 'detailTitle': 'รายละเอียดรถยนต์', 'editTitle': 'แก้ไขรถ'},
        'table': {
            'id_car': 'รหัสประจำตัว',
            'id_use': 'ผู้ใช้',
            'model': 'แบบอย่าง',
            'car_license': 'ใบอนุญาต',
            'max_years_old': 'สูงสุดปี',
            'manufacture_year': 'ปีผลิต',
            'maximum_power': 'กำลังสูงสุด',
            'is_second_hand': 'เป็นมือสอง',
            'vehicle_inspection_date': 'วันที่ตรวจสอบยานพาหนะ',
            'insurance_expiry_date': 'วันหมดอายุการประกันภัย',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_car': 'รหัสประจำตัว',
            'id_use': 'ผู้ใช้',
            'model': 'แบบอย่าง',
            'car_license': 'ใบอนุญาต',
            'max_years_old': 'สูงสุดปี',
            'manufacture_year': 'ปีผลิต',
            'maximum_power': 'กำลังสูงสุด',
            'is_second_hand': 'เป็นมือสอง',
            'vehicle_inspection_date': 'วันที่ตรวจสอบยานพาหนะ',
            'insurance_expiry_date': 'วันหมดอายุการประกันภัย',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_car': 'ต้องใช้รหัส',
                'id_use': 'จำเป็นต้องใช้ ผู้ใช้',
                'model': 'แบบจำลองที่ต้องการ',
                'car_license': 'ต้องมีใบอนุญาต',
                'max_years_old': 'จำเป็นต้องใช้ปีสูงสุด',
                'manufacture_year': 'ต้องผลิตปี',
                'maximum_power': 'ต้องใช้กำลังสูงสุด',
                'is_second_hand': 'ต้องใช้มือสอง',
                'vehicle_inspection_date': 'วันที่ตรวจสอบยานพาหนะต้องการ',
                'insurance_expiry_date': 'วันหมดอายุการประกัน',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'carImages': {
        'name': {'title': 'ภาพรถยนต์', 'detailTitle': 'รายละเอียดภาพรถยนต์', 'editTitle': 'แก้ไขภาพรถยนต์'},
        'table': {
            'id_car_ima': 'รหัสประจำตัว',
            'id_car': 'รถ',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_car_ima': 'รหัสประจำตัว',
            'id_car': 'รถ',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_car_ima': 'ต้องใช้รหัส',
                'id_car': 'ต้องใช้รถยนต์',
                'url': 'จำเป็นต้องใช้ URL',
                'upload_time': 'ต้องอัปโหลดเวลาที่ต้องใช้',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'ภาพจุดประจุ',
            'detailTitle': 'รายละเอียดภาพจุดประจุ',
            'editTitle': 'แก้ไขภาพจุดประจุ'
        },
        'table': {
            'id_ima': 'รหัสประจำตัว',
            'id_cha_poi': 'จุดประจุ',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_ima': 'รหัสประจำตัว',
            'id_cha_poi': 'จุดประจุ',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_ima': 'ต้องใช้รหัส',
                'id_cha_poi': 'ต้องใช้จุดประจุ',
                'url': 'จำเป็นต้องใช้ URL',
                'upload_time': 'ต้องอัปโหลดเวลาที่ต้องใช้',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "ตั้งค่าการชำระเงินตามลำดับการสั่งซื้อการชาร์จเริ่มต้นตามภูมิภาค",
            'billingMethod': "ตั้งค่าวิธีการเรียกเก็บเงินสำหรับการสั่งซื้อค่าใช้จ่ายโดยค่าเริ่มต้นตามภูมิภาค",
        },
        'billingMethodType': {
            '0':"ตามเวลา",
            '1':"โดยพลังงาน",
            '2':"ตามแผนผู้ใช้",
            '3':"โดย APP",
            '4':"ตามภูมิภาค",
        },
        'updateFirmware': {
            'identifier': "หมายเลขซีเรียล",
            'location': "ที่อยู่ระยะไกล",
            'retrieveDate': "วันที่เรียกค้น",
        },
        'uValidate': {
            'identifier': "โปรดป้อนหมายเลขซีเรียล",
            'location': "โปรดป้อนที่อยู่ระยะไกล",
            'retrieveDate': "โปรดเลือกวันค้นหา",
        },
        'setting': {
            'index': "หมายเลขซีเรียล",
            'readonly': "อ่านอย่างเดียว",
            'key': "ตั้งค่าคีย์",
            'value': "ตั้งค่า",
        },
        'settingField': {
            'readonly': "เลือกว่าจะเป็นแบบอ่านอย่างเดียว",
            'key': "คีย์ชุดต้องไม่ว่างเปล่า",
            'value': "ค่าการตั้งค่าไม่สามารถว่างเปล่า",
        },
        'name': {
            'title': 'จุดประจุ',
            'detailTitle': 'รายละเอียดจุดประจุ',
            'editTitle': 'แก้ไขจุดประจุ',
            'quickAdd': 'เพิ่มจุดประจุ'
        },
        'table': {
            'id_cha_poi': 'รหัสประจำตัว',
            'id_met': 'เมตร',
            'charge_box_id': 'ID กล่องประจุ',
            'firmwareVersion': 'เวอร์ชั่นของเฟิร์มแวร์',
            'last_heartbeat_time': 'เวลาการเต้นของหัวใจครั้งสุดท้าย',
            'monthly_fee': 'ค่าธรรมเนียมรายเดือน',
            'charger_type': 'ประเภทเครื่องชาร์จ',
            'tenant_key': 'กุญแจผู้เช่า',
            'name': 'ชื่อ',
            'heartbeat_interval': 'ช่วงเวลาการเต้นของหัวใจ',
            'enable': 'เปิดใช้งาน',
            'maxCurrent': "กระแสสูงสุด",
            'minCurrent': "กระแสไฟฟ้าขั้นต่ํา",
            'isPrivate': "ไม่ว่าจะเป็นส่วนตัว",
            'isReadMeter': "ไม่ว่าจะเป็นการอ่านตาราง",
            'isReset': "จะรีสตาร์ทหรือไม่ (รีสตาร์ทจะมีผล)",
            'action': {
                'updateFirmware': "อัพเกรดเฟิร์มแวร์",
                'hardReset': "รีเซ็ตยาก",
                'softReset': "รีเซ็ตซอฟท์"
            }
        },
        'validate': {
            'id_cha_poi': 'ต้องใช้รหัส',
            'id_met': 'ต้องใช้เมตร',
            'charge_box_id': 'ต้องใช้รหัสกล่องประจุ',
            'last_heartbeat_time': 'ต้องใช้เวลาในการเต้นครั้งสุดท้าย',
            'monthly_fee': 'ต้องมีค่าธรรมเนียมรายเดือน',
            'charger_type': 'ต้องการประเภทเครื่องชาร์จ',
            'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
            'name': 'ชื่อที่ต้องการ',
            'heartbeat_interval': 'ต้องการช่วงเวลาการเต้นของหัวใจ',
            'enable': 'เปิดใช้งาน',
            'maxCurrent': "กรุณากรอกข้อมูลปัจจุบันสูงสุด",
            'minCurrent': "กรุณากรอกข้อมูลขั้นต่ําในปัจจุบัน",
        }
    },
    'connector': {
        'name': {
            'title': 'ตัวเชื่อมต่อ',
            'detailTitle': 'รายละเอียดตัวเชื่อมต่อ',
            'editTitle': 'แก้ไขตัวเชื่อมต่อ',
            'startTransaction': 'เริ่มธุรกรรม',
            'stopTransaction': 'หยุดธุรกรรม'
        },
        'table': {
            'id_con': 'รหัสประจำตัว',
            'id_cha_poi': 'จุดประจุ',
            'name': 'ชื่อ',
            'connector_id': 'รหัสตัวเชื่อมต่อ',
            'status': 'สถานะ',
            'tenant_key': 'กุญแจผู้เช่า',
            'power': 'พลัง(kw)',
            'type': 'พิมพ์'
        },
        'field': {
            'id_con': 'รหัสประจำตัว',
            'id_cha_poi': 'จุดประจุ',
            'name': 'ชื่อ',
            'connector_id': 'รหัสตัวเชื่อมต่อ',
            'status': 'สถานะ',
            'tenant_key': 'กุญแจผู้เช่า',
            'power': 'พลัง(kw)',
            'type': 'พิมพ์'
        },
        'validate': {
            'required': {
                'id_con': 'ต้องใช้รหัส',
                'id_cha_poi': 'ต้องใช้จุดประจุ',
                'name': 'ชื่อที่ต้องการ',
                'connector_id': 'จำเป็นต้องใช้รหัสตัวเชื่อมต่อ',
                'status': 'สถานะที่ต้องการ',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
                'power': 'ต้องใช้พลังงาน(kw)',
                'type': 'ประเภทที่ต้องการ'
            }
        }
    },
    'employee': {
        'name': {'title': 'ผู้ดำเนินการ', 'detailTitle': 'รายละเอียดผู้ประกอบการ', 'editTitle': 'แก้ไขโอเปอเรเตอร์'},
        'table': {
            'id_emp': 'รหัสประจำตัว',
            'id_rol': 'บทบาท',
            'account': 'บัญชี',
            'password': 'รหัสผ่าน',
            'salt': 'เกลือ',
            'last_login_time': 'เวลาเข้าสู่ระบบครั้งสุดท้าย',
            'register_time': 'ลงทะเบียนเวลา',
            'login_failure_counter': 'ตัวนับความล้มเหลวเข้าสู่ระบบ',
            'email': 'อีเมล',
            'whatsAppPhone': 'WhatsApp เลข',
            'remark': 'สังเกต',
            'status': 'สถานะ',
            'name': 'ชื่อ',
            'commission_rate': 'อัตราค่านายหน้า',
            'yedpay_api_key': 'คีย์ yedpay api',
            'yedpay_sign_key': 'รหัสป้าย Yedpay',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_emp': 'รหัสประจำตัว',
            'id_rol': 'บทบาท',
            'account': 'บัญชี',
            'password': 'รหัสผ่าน',
            'salt': 'เกลือ',
            'last_login_time': 'เวลาเข้าสู่ระบบครั้งสุดท้าย',
            'register_time': 'ลงทะเบียนเวลา',
            'login_failure_counter': 'ตัวนับความล้มเหลวเข้าสู่ระบบ',
            'email': 'อีเมล',
            'whatsAppPhone': 'WhatsApp เลข',
            'remark': 'สังเกต',
            'status': 'สถานะ',
            'name': 'ชื่อ',
            'commission_rate': 'อัตราค่านายหน้า',
            'yedpay_api_key': 'คีย์ yedpay api',
            'yedpay_sign_key': 'รหัสป้าย Yedpay',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_emp': 'ต้องใช้รหัส',
                'id_rol': 'ต้องการบทบาท',
                'account': 'ต้องใช้บัญชี',
                'password': 'ต้องใช้รหัสผ่าน',
                'salt': 'ต้องการเกลือ',
                'last_login_time': 'ต้องใช้เวลาเข้าสู่ระบบล่าสุด',
                'register_time': 'ต้องลงทะเบียนเวลาที่ต้องการ',
                'login_failure_counter': 'ตัวนับความล้มเหลวเข้าสู่ระบบจำเป็นต้องใช้',
                'email': 'ต้องใช้อีเมล',
                'whatsAppPhone': 'กรุณากรอกหมายเลข WhatsApp',
                'remark': 'จำเป็นต้องมีข้อสังเกต',
                'status': 'สถานะที่ต้องการ',
                'name': 'ชื่อที่ต้องการ',
                'commission_rate': 'ต้องการอัตราค่านายหน้า',
                'yedpay_api_key': 'จำเป็นต้องใช้คีย์ YedPay API',
                'yedpay_sign_key': 'จำเป็นต้องใช้รหัสป้าย Yedpay',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'บันทึกเหตุการณ์',
            'detailTitle': 'รายละเอียดบันทึกเหตุการณ์',
            'editTitle': 'แก้ไขบันทึกเหตุการณ์'
        },
        'table': {
            'id_env_log': 'รหัสประจำตัว',
            'run_time': 'เวลาดำเนินการ(ms)',
            'class_method': 'วิธีการดำเนินการ',
            'event_time': 'เวลาจัดงาน',
            'log': 'บันทึก',
            'tenant_key': 'กุญแจผู้เช่า',
            'resCode': 'รหัสตอบกลับ',
        },
        'field': {
            'id_env_log': 'รหัสประจำตัว',
            'event_time': 'เวลาจัดงาน',
            'log': 'บันทึก',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_env_log': 'ต้องใช้รหัส',
                'class_method': 'กรุณากรอกวิธีการดำเนินการ',
                'event_time': 'ต้องใช้เวลากิจกรรม',
                'log': 'จำเป็นต้องมีบันทึก',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'meter': {
        'name': {'title': 'เมตร', 'detailTitle': 'รายละเอียดเมตร', 'editTitle': 'แก้ไขมิเตอร์'},
        'table': {
            'id_met': 'รหัสประจำตัว',
            'id_reg': 'ภูมิภาค',
            'name': 'ชื่อเมตร',
            'current_threshold': 'เกณฑ์ปัจจุบัน',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_met': 'รหัสประจำตัว',
            'id_reg': 'ภูมิภาค',
            'name': 'ชื่อเมตร',
            'current_threshold': 'เกณฑ์ปัจจุบัน',
            'tenant_key': 'กุญแจผู้เช่า',
            'loadByTactics': 'เปิดนโยบาย'
        },
        'validate': {
            'required': {
                'id_met': 'ต้องใช้รหัส',
                'id_reg': 'จำเป็นต้องมีภูมิภาค',
                'name': 'ต้องใช้ชื่อเมตร',
                'current_threshold': 'ต้องการเกณฑ์ปัจจุบัน',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'order': {
        'name': {
            'title': 'คำสั่ง',
            'detailTitle': 'สั่งซื้อรายละเอียด',
            'editTitle': 'แก้ไขคำสั่งซื้อ',
            'cancel': 'การยกเลิกคำสั่งซื้อ',
            'cancelAndRefund': 'ยกเลิกคำสั่งซื้อและคืนเงิน',
        },
        'table': {
            'id_ord': 'รหัสประจำตัว',
            'id_car': 'รถ',
            'id_con': 'ตัวเชื่อมต่อ',
            'order_time': 'เวลาสั่งซื้อ',
            'type': 'พิมพ์',
            'amount': 'จำนวน',
            'charge_start_time': 'เวลาเริ่มต้นการชาร์จ',
            'charge_end_time': 'ควรสิ้นสุดเวลา',
            'chargeEndTimeActual': 'เวลาสิ้นสุดที่แท้จริง',
            'status': 'สถานะ',
            'payment_time': 'เวลาชำระเงิน',
            'electricity': 'รับซื้อไฟฟ้า',
            'charge_minutes': 'ระยะเวลาซื้อ',
            'currentElectricity': 'กระแสไฟฟ้าปัจจุบัน',
            'ref_id_pla': 'วางแผน',
            'price': 'ราคา',
            'commission_rate': 'อัตราค่านายหน้า',
            'tenant_key': 'กุญแจผู้เช่า',
            'id_tra_log': 'การทำธุรกรรม',
            'pay_type': 'สายจ่ายเงิน',
            'payment_method': 'วิธีการชำระเงิน'
        },
        'validate': {
            'id_ord': 'ต้องใช้รหัส',
            'id_car': 'ต้องใช้รถยนต์',
            'id_con': 'ต้องการตัวเชื่อมต่อ',
            'order_time': 'เวลาสั่งซื้อ',
            'type': 'ประเภทที่ต้องการ',
            'amount': 'จำนวนเงินที่ต้องการ',
            'charge_start_time': 'ต้องใช้เวลาเริ่มต้น',
            'charge_end_time': 'ต้องใช้เวลาสิ้นสุด',
            'status': 'สถานะที่ต้องการ',
            'payment_time': 'ต้องใช้เวลาในการชำระเงิน',
            'electricity': 'ต้องใช้ไฟฟ้า',
            'ref_id_pla': 'จำเป็นต้องมีแผน',
            'price': 'ราคาที่ต้องการ',
            'commission_rate': 'ต้องการอัตราค่านายหน้า',
            'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
            'id_tra_log': 'ต้องการธุรกรรม',
            'charge_minutes': 'จำเป็นต้องมี chargeminutes',
            'pay_type': 'ต้องใช้ Paytype',
            'payment_method': 'ต้องใช้วิธีการชำระเงิน'
        }
    },
    'plan': {
        'name': {'title': 'วางแผน', 'detailTitle': 'วางแผนรายละเอียด', 'editTitle': 'แก้ไขแผน'},
        'table': {
            'id_pla': 'รหัสประจำตัว',
            'name': 'วางแผนชื่อ',
            'price_per_month': 'ราคา',
            'contract_period': 'ระยะเวลาสัญญา',
            'free_charging_time_limit': 'เวลาชาร์จฟรี',
            'overtime_charge_price': 'ราคาล่วงเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_pla': 'รหัสประจำตัว',
            'name': 'วางแผนชื่อ',
            'price_per_month': 'ราคา',
            'contract_period': 'ระยะเวลาสัญญา',
            'free_charging_time_limit': 'เวลาชาร์จฟรี',
            'overtime_charge_price': 'ราคาล่วงเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_pla': 'ต้องใช้รหัส',
                'name': 'ต้องวางแผนชื่อ',
                'price_per_month': 'ราคาที่ต้องการ',
                'contract_period': 'ระยะเวลาสัญญาที่ต้องการ',
                'free_charging_time_limit': 'ต้องใช้เวลาชาร์จฟรี',
                'overtime_charge_price': 'ต้องใช้ราคาค่าล่วงเวลา',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "แต่ละขั้นตอนของการกระโดดไม่พอใจกระโดดจะถูกคำนวณโดยการกระโดดถ้าคำนวณโดยการสั่งซื้อไฟฟ้าจริงโปรดตั้งเป็นศูนย์",
            'byTimeUnit': "ขั้นตอนของแต่ละกระโดด, ไม่พอใจกระโดดจะถูกคำนวณโดยหนึ่งกระโดด, ถ้าคำนวณตามเวลาจริงของการสั่งซื้อ, กรุณาตั้งค่าเป็นศูนย์.",

            'maximumPower': "ขีด จำกัด สูงสุดของการชาร์จหนึ่งครั้งเมื่อชาร์จโดยการกดแบตเตอรี่ปล่อยให้ว่างหรือเป็นศูนย์ไม่ จำกัด ค่าเริ่มต้นไม่ จำกัด",
            'maximumMinute': "เมื่อชาร์จตามเวลาขีด จำกัด เวลาในการชาร์จเพียงครั้งเดียวปล่อยให้ว่างหรือเป็นศูนย์ไม่ จำกัด ค่าเริ่มต้นไม่ จำกัด",
        },
        'billingMethodType':{
            '0': "ตามเวลา",
            '1': "โดยพลังงาน",
            '2': "โดย APP",
        },
        'name': {'title': 'ภูมิภาค', 'detailTitle': 'รายละเอียดภูมิภาค', 'editTitle': 'แก้ไขภูมิภาค'},
        'table': {
            'id_reg': 'รหัสประจำตัว',
            'name': 'ชื่อภูมิภาค',
            'ac_price_per_electricity': 'ราคา AC ต่อไฟฟ้า',
            'ac_price_per_minute': 'ราคา AC ต่อนาที',
            'lon': 'โหลน',
            'lat': 'ละทิ้ง',
            'address': 'ที่อยู่',
            'dc_price_per_electricity': 'ราคา DC ต่อไฟฟ้า',
            'dc_price_per_minute': 'ราคา DC ต่อนาที',
            'tenant_key': 'กุญแจผู้เช่า',
            'businessAllDay': 'เปิดบริการทั้งวัน',
            'images': 'ภาพ',
            'byPowerUnit': "หน่วยวัดกระแสไฟฟ้า",
            'byTimeUnit': "หน่วยวัดตามเวลา",
            'postpaid': "โพสต์จ่าย",
            'billingMethod': "วิธีการชำระเงิน",
            'businessHours': "ช่วงเวลาทำการ",

            'maximumPower': "ขีด จำกัด สูงสุดของการชาร์จเพียงครั้งเดียว",
            'maximumMinute': "ขีด จำกัด เวลาในการชาร์จเพียงครั้งเดียว",
            'directDebit': "การหักเงินอัตโนมัติ",

            'enableTimeoutFine': "เปิดค่าปรับหมดเวลา",
            'timeoutFine': "หมดเวลา(min)",
            'timeoutCharging': "การเรียกเก็บเงินล่วงเวลา",
        },
        'validate': {
            'id_reg': 'ต้องใช้รหัส',
            'name': 'ต้องการชื่อภูมิภาค',
            'ac_price_per_electricity': 'ราคา AC ต่อไฟฟ้าที่ต้องการ',
            'ac_price_per_minute': 'ราคา AC ต่อนาทีที่ต้องการ',
            'lon': 'ต้องการ LON',
            'lat': 'จำเป็นต้องใช้ LAT',
            'address': 'ต้องใช้ที่อยู่',
            'dc_price_per_electricity': 'ราคา DC ต่อไฟฟ้าที่ต้องการ',
            'dc_price_per_minute': 'ราคา DC ต่อนาทีที่ต้องการ',
            'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
            'businessAllDay': 'โปรดเลือกว่าจะเปิดให้บริการตลอดทั้งวันหรือไม่',
            'byPowerUnit': "กรุณากรอกข้อมูลในหน่วยวัดกระแสไฟฟ้า",
            'byTimeUnit': "กรุณากรอกข้อมูลในหน่วยวัดเวลา",
            'postpaid': "โปรดเลือกว่าจะชำระเงินภายหลังหรือไม่",
            'billingMethod': "กรุณาเลือกวิธีการชำระเงิน",
            'businessHours': "เลือกช่วงเวลาทำการ",

            'maximumPower': "กรุณากรอก Power Cap สำหรับการชาร์จหนึ่งครั้ง",
            'maximumMinute': "กรุณากรอก Time Cap สำหรับการชาร์จหนึ่งครั้ง",
            'directDebit': "โปรดเลือกว่าจะหักเงินอัตโนมัติหรือไม่",

            'enableTimeoutFine': "โปรดเลือกว่าจะเปิดค่าปรับหมดเวลาหรือไม่",
            'timeoutFine': "กรุณากรอกเวลาหมดเวลา(min)",
            'timeoutCharging': "กรุณากรอกข้อมูลการเรียกเก็บเงินที่หมดเวลา",
        }
    },
    'regionImages': {
        'name': {
            'title': 'ภาพภูมิภาค',
            'detailTitle': 'รายละเอียดภาพภูมิภาค',
            'editTitle': 'แก้ไขภาพภูมิภาค'
        },
        'table': {
            'id_reg_img': 'รหัสประจำตัว',
            'id_reg': 'ภูมิภาค',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_reg_img': 'รหัสประจำตัว',
            'id_reg': 'ภูมิภาค',
            'url': 'url',
            'upload_time': 'อัพโหลดเวลา',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_reg_img': 'ต้องใช้รหัส',
                'id_reg': 'จำเป็นต้องมีภูมิภาค',
                'url': 'จำเป็นต้องใช้ URL',
                'upload_time': 'ต้องอัปโหลดเวลาที่ต้องใช้',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'บันทึกการซ่อมแซม',
            'detailTitle': 'ซ่อมแซมรายละเอียดบันทึก',
            'editTitle': 'แก้ไขบันทึกการซ่อมแซม'
        },
        'table': {
            'id_rep_log': 'รหัสประจำตัว',
            'id_car': 'รถ',
            'amount': 'จำนวน',
            'repair_date': 'วันที่ซ่อมแซม',
            'remark': 'สังเกต',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'field': {
            'id_rep_log': 'รหัสประจำตัว',
            'id_car': 'รถ',
            'amount': 'จำนวน',
            'repair_date': 'วันที่ซ่อมแซม',
            'remark': 'สังเกต',
            'tenant_key': 'กุญแจผู้เช่า'
        },
        'validate': {
            'required': {
                'id_rep_log': 'ต้องใช้รหัส',
                'id_car': 'ต้องใช้รถยนต์',
                'amount': 'จำนวนเงินที่ต้องการ',
                'repair_date': 'วันที่ซ่อมแซมต้อง',
                'remark': 'จำเป็นต้องมีข้อสังเกต',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
            }
        }
    },
    'role': {
        'name': {'title': 'บทบาท', 'detailTitle': 'รายละเอียดบทบาท', 'editTitle': 'แก้ไขบทบาท'},
        'table': {'id_rol': 'รหัสประจำตัว', 'name': 'บทบาท', 'remark': 'สังเกต'},
        'field': {'id_rol': 'รหัสประจำตัว', 'name': 'บทบาท', 'remark': 'สังเกต', 'authorityList': 'รายชื่อผู้มีอำนาจ'},
        'validate': {'required': {'id_rol': 'ต้องใช้รหัส', 'name': 'ต้องการบทบาท', 'remark': 'จำเป็นต้องมีข้อสังเกต'}}
    },
    'roleAuthority': {
        'name': {
            'title': 'การโรลี',
            'detailTitle': 'รายละเอียด Roleauthority',
            'editTitle': 'แก้ไข Roleauthority'
        },
        'table': {'id_rol_aut': 'รหัสประจำตัว', 'id_rol': 'บทบาท', 'id_aut': 'อำนาจ'},
        'field': {'id_rol_aut': 'รหัสประจำตัว', 'id_rol': 'บทบาท', 'id_aut': 'อำนาจ'},
        'validate': {
            'required': {
                'id_rol_aut': 'ต้องใช้รหัส',
                'id_rol': 'ต้องการบทบาท',
                'id_aut': 'ผู้มีอำนาจที่ต้องการ'
            }
        }
    },
    'transactionLog': {
        'name': {'title': 'บันทึกธุรกรรม', 'detailTitle': 'รายละเอียดบันทึกธุรกรรม', 'editTitle': 'แก้ไขบันทึกธุรกรรม'},
        'table': {
            'id_tra_log': 'รหัสประจำตัว',
            'id_con': 'ตัวเชื่อมต่อ ท่า',
            'event_time': 'เวลาจัดงาน',
            'id_tag': 'แท็ก id',
            'start_time': 'เวลาเริ่มต้น',
            'start_value': 'ค่าเริ่มต้น',
            'stop_time': 'หยุดเวลา',
            'stop_value': 'หยุดมูลค่า',
            'stop_reason': 'หยุดเหตุผล',
            'fail_reason': 'ล้มเหลวเหตุผล',
            'tenant_key': 'กุญแจผู้เช่า',
            'current_electricity': 'กระแสไฟฟ้าปัจจุบัน'
        },
        'field': {
            'id_tra_log': 'รหัสประจำตัว',
            'id_con': 'ตัวเชื่อมต่อ IDT',
            'event_time': 'เวลาจัดงาน',
            'id_tag': 'แท็ก id',
            'start_time': 'เวลาเริ่มต้น',
            'start_value': 'ค่าเริ่มต้น',
            'stop_time': 'หยุดเวลา',
            'stop_value': 'หยุดมูลค่า',
            'stop_reason': 'หยุดเหตุผล',
            'fail_reason': 'ล้มเหลวเหตุผล',
            'tenant_key': 'กุญแจผู้เช่า',
            'current_electricity': 'กระแสไฟฟ้าปัจจุบัน'
        },
        'validate': {
            'required': {
                'id_tra_log': 'ต้องใช้รหัส',
                'id_con': 'จำเป็นต้องใช้ตัวเชื่อมต่อ IDT',
                'event_time': 'ต้องใช้เวลากิจกรรม',
                'id_tag': 'ต้องใช้แท็ก ID',
                'start_time': 'ต้องใช้เวลาเริ่มต้น',
                'start_value': 'ค่าเริ่มต้นที่ต้องการ',
                'stop_time': 'ต้องหยุดเวลา',
                'stop_value': 'ต้องการค่าหยุด',
                'stop_reason': 'หยุดเหตุผลที่จำเป็น',
                'fail_reason': 'ต้องใช้เหตุผลล้มเหลว',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
                'current_electricity': 'ต้องใช้ไฟฟ้าในปัจจุบัน'
            }
        }
    },
    'user': {
        'name': {'title': 'ผู้ใช้', 'detailTitle': 'รายละเอียดของผู้ใช้', 'editTitle': 'แก้ไขผู้ใช้'},
        'table': {
            'id_use': 'รหัสประจำตัว',
            'balance': 'สมดุล',
            'account': 'บัญชี',
            'password': 'รหัสผ่าน',
            'register_time': 'ลงทะเบียนเวลา',
            'email': 'อีเมล',
            'token': 'โทเค็น',
            'salt': 'เกลือ',
            'tenant_key': 'กุญแจผู้เช่า',
            'phone': 'โทรศัพท์',
            'register_type': 'ประเภทลงทะเบียน'
        },
        'field': {
            'id_use': 'รหัสประจำตัว',
            'balance': 'สมดุล',
            'account': 'บัญชี',
            'password': 'รหัสผ่าน',
            'register_time': 'ลงทะเบียนเวลา',
            'email': 'อีเมล',
            'token': 'โทเค็น',
            'salt': 'เกลือ',
            'tenant_key': 'กุญแจผู้เช่า',
            'phone': 'โทรศัพท์',
            'register_type': 'ประเภทลงทะเบียน',

            'chargingCard': 'หมายเลขบัตรชาร์จ',
            'chargingType': 'โหมดการชาร์จครั้งแรก',
            'chargingDefDuration': 'เวลาในการชาร์จบัตร(min)',
            'chargingDefPower': 'ปริมาณการชาร์จบัตร(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'ต้องใช้รหัส',
                'balance': 'ความสมดุลที่ต้องการ',
                'account': 'ต้องใช้บัญชี',
                'password': 'ต้องใช้รหัสผ่าน',
                'register_time': 'ต้องลงทะเบียนเวลาที่ต้องการ',
                'email': 'ต้องใช้อีเมล',
                'token': 'ต้องใช้โทเค็น',
                'salt': 'ต้องการเกลือ',
                'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า',
                'phone': 'ต้องใช้โทรศัพท์',
                'register_type': 'ประเภทการลงทะเบียนที่ต้องการ',

                'chargingCard': 'กรุณาใส่หมายเลขบัตรชาร์จ',
                'chargingType': 'โปรดเลือกโหมดการชาร์จบัตรก่อน',
                'chargingDefDuration': 'โปรดป้อนเวลาในการชาร์จการ์ด(min)',
                'chargingDefPower': 'กรุณาใส่จำนวนการชาร์จบัตร(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {'title': 'แผนผู้ใช้', 'detailTitle': 'รายละเอียดแผนผู้ใช้', 'editTitle': 'แก้ไขแผนผู้ใช้'},
        'table': {
            'id_use': 'ผู้ใช้',
            'id_pla': 'วางแผน',
            'arrearsCount': 'จำนวนยอดค้างชำระ',
            'start_date': 'วันที่เริ่มต้น',
            'tenant_key': 'กุญแจผู้เช่า',
            'createType': 'การสร้างอัตลักษณ์',
            'updateByMonth': 'เวลาชำระล่วงหน้า',
            'total': 'จำนวนเดือนล่วงหน้า',
            'amount': 'จำนวนเงินที่ต้องชำระโดยประมาณ',
            'prePayData': 'คาดว่าจะจ่ายเงินสมทบเดือน',
        },
        'validate': {
            'id_use': 'จำเป็นต้องใช้ ผู้ใช้',
            'id_pla': 'จำเป็นต้องมีแผน',
            'arrearsCount': 'กรุณากรอกจำนวนเดือนที่ค้างชำระ',
            'start_date': 'วันที่เริ่มต้นที่ต้องการ',
            'tenant_key': 'จำเป็นต้องใช้คีย์ผู้เช่า'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'เครื่องชาร์จทั้งหมด',
            'availableChargers': 'จํานวนพอร์ตที่พร้อมใช้งาน',
            'inUseChargers': 'จํานวนพอร์ตที่ใช้งาน',
            'unavailableChargers': 'จํานวนพอร์ตไม่พร้อมใช้งาน',
            'portUsage': 'การใช้พอร์ต',

            'energyUsage': 'การใช้พลังงาน (kWh)',
            'totalRevenue': 'รายได้รวม',
            'totalEnergy': 'พลังงานทั้งหมด',
            'newUser': 'ผู้ใช้ใหม่'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'เวอร์ชัน Android',
            'androidDownloadLink': 'ลิงค์ดาวน์โหลด Android',
            'androidComplieNumber': 'หมายเลขรวบรวม Android',
            'iosVersion': 'เวอร์ชัน iOS',
            'iosBundleId': 'IOS Bundle ID',
            'iosComplieNumber': 'หมายเลขรวบรวม iOS',
            'isForcedUpdate': 'ไม่ว่าจะเป็นการบังคับต่ออายุ'
        },
        'validate': {
            'required': {
                'androidVersion': 'ต้องใช้เวอร์ชัน Android',
                'androidDownloadLink': 'จำเป็นต้องมีลิงค์ดาวน์โหลด Android',
                'androidComplieNumber': 'ต้องการหมายเลขรวบรวม Android',
                'iosVersion': 'ต้องใช้เวอร์ชัน iOS',
                'iosBundleId': 'ต้องใช้ IOS Bundle ID',
                'iosComplieNumber': 'ต้องการหมายเลขรวบรวม iOS',
                'isForcedUpdate': 'โปรดเลือกว่าจะบังคับให้อัปเดตหรือไม่'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'การตั้งค่าคีย์', 'dataStr': 'ค่าการตั้งค่า'}},
    'superTenantManage': {
        'name': {'title': 'การอนุญาต', 'detailTitle': 'รายละเอียด', 'editTitle': 'แก้ไข'},
        'searchForm': {
            'dataSourceKey': 'คีย์แหล่งข้อมูล',
            'url': 'url',
            'username': 'ชื่อผู้ใช้',
            'tenant': 'ผู้เช่า',
            'isEnable': 'เปิดใช้งาน',
            'isCreate': 'สร้าง'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'โปรดป้อนคีย์แหล่งข้อมูล',
            'url': 'กรุณากรอกที่อยู่ลิงค์',
            'username': 'กรุณากรอกบัญชี',
            'tenant': 'กรุณากรอกชื่อผู้เช่า',
            'isEnable': 'โปรดเลือกว่าจะเปิดใช้งานหรือไม่',
            'isCreate': 'โปรดเลือกสร้างโลโก้'
        },
        'table': {
            'index': 'ดัชนี',
            'dataSourceKey': 'โปรดป้อนคีย์แหล่งข้อมูล',
            'url': 'url',
            'host': 'เจ้าภาพ',
            'port': 'ท่าเรือ',
            'dbName': 'ชื่อฐานข้อมูล',
            'username': 'ชื่อผู้ใช้',
            'password': 'รหัสผ่าน',
            'tenant': 'ผู้เช่า',
            'isEnable': 'เปิดใช้งาน',
            'isCreate': 'สร้าง',
            'domain': 'โดเมน',
            'action': {'testConn': 'Conn', 'create': 'สร้าง', 'edit': 'แก้ไข', 'del': 'ลบ'}
        },
        'field': {
            'id': 'รหัสประจำตัว',
            'datasourceKey': 'โปรดป้อนคีย์แหล่งข้อมูล',
            'seckey': 'คีย์การเข้าถึง',
            'url': 'URL',
            'host': 'เจ้าภาพ',
            'port': 'ท่าเรือ',
            'dbName': 'ชื่อฐานข้อมูล',
            'username': 'ชื่อผู้ใช้',
            'password': 'รหัสผ่าน',
            'tenant': 'ผู้เช่า',
            'isEnable': 'เปิดใช้งาน',
            'isCreate': 'สร้าง',
            'remark': 'สังเกต',
            'domain': 'โดเมน',
            'onesignalKey': 'คีย์การแจ้งเตือน APP',
        },
        'validate': {
            'required': {
                'id': 'กรุณากรอกข้อมูลใน ID',
                'dataSourceKey': 'กรุณากรอกข้อมูลใน โปรดป้อนคีย์แหล่งข้อมูล',
                'seckey': 'กรุณากรอกข้อมูลใน คีย์การเข้าถึง',
                'url': 'กรุณากรอกข้อมูลในลิงค์ URL',
                'host': 'กรุณากรอกข้อมูลในโฮสต์',
                'port': 'กรุณากรอกในพอร์ต',
                'dbName': 'กรุณากรอกชื่อฐานข้อมูล',
                'username': 'กรุณาป้อนชื่อผู้ใช้',
                'password': 'กรุณากรอกรหัสผ่าน',
                'tenant': 'กรุณากรอกชื่อผู้เช่า',
                'isEnable': 'โปรดเลือกสถานะที่ใช้งานอยู่',
                'remark': 'กรุณากรอกคำพูด',
                'domain': 'กรุณากรอกข้อมูลในโดเมน',
                'onesignalKey': 'กรุณากรอกคีย์การแจ้งเตือน APP',
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'รหัสประจำตัว',
            'index': 'ดัชนี',
            'machineCode': 'รหัสเครื่องจักร',
            'name': 'ชื่อ',
            'regId': 'ID ภูมิภาค',
            'regName': 'ชื่อภูมิภาค',
            'seckey': 'คีย์การเข้าถึง',
            'args': 'พารามิเตอร์อื่น ๆ',
            'createBy': 'สร้างโดย',
            'createTime': 'สร้างเวลา',
            'updateBy': 'อัปเดตโดย',
            'updateTime': 'อัปเดตเวลา',
            'remark': 'สังเกต'
        },
        'validate': {
            'id': 'กรุณากรอกข้อมูลใน ID',
            'machineCode': 'กรุณากรอกรหัสเครื่อง',
            'name': 'กรุณากรอกชื่อ',
            'regId': 'กรุณากรอกข้อมูลในภูมิภาค',
            'seckey': 'กรุณากรอกข้อมูลใน คีย์การเข้าถึง',
            'args': 'กรุณากรอกข้อมูลใน พารามิเตอร์อื่น ๆ',
            'remark': 'กรุณากรอกคำพูด'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'อีเมล', '1': 'โทรศัพท์'},
        'noticeType': {
            '0': 'คำเตือน',
            '1': 'เตือน',
            '2': 'ประกาศ',
            '3': 'หมดเวลา',
        },
        'field': {
            'id': 'รหัสประจำตัว',
            'index': 'ดัชนี',
            'toFrom': 'บัญชี',
            'type': 'ประเภทบัญชี',
            'noticeType': 'ประเภทการแจ้งเตือน',
            'status': 'เปิดใช้งาน',
            'createBy': 'สร้างโดย',
            'createTime': 'สร้างเวลา',
            'updateBy': 'อัปเดตโดย',
            'updateTime': 'อัปเดตเวลา',
            'remark': 'สังเกต'
        },
        'validate': {
            'id': 'กรุณากรอกข้อมูลใน ID',
            'type': 'กรุณาเลือกประเภทบัญชี',
            'toFrom': 'กรุณากรอกบัญชี',
            'noticeType': 'โปรดเลือกประเภทการแจ้งเตือน',
            'status': 'โปรดเลือกว่าจะเปิดใช้งานหรือไม่',
            'remark': 'กรุณากรอกคำพูด'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'ข้อกําหนดในการให้บริการ',
            '1': 'เกี่ยวกับเรา',
            '2': 'ประกาศ',
            '3': 'สังเกต',
            '4': 'เตือน',
        },
        'field': {
            'id': 'รหัส',
            'index': 'หมายเลขซีเรียล',
            'type': 'ประเภทโฆษณา',
            'status': 'ไม่ว่าจะเปิดใช้งานหรือไม่',
            'title': 'ชื่อเรื่องของประกาศ',
            'content': 'การแจ้งเตือน',
            'tenantKey': 'ข้อมูลประจําตัวของผู้เช่า',

            'createBy': 'ที่สร้างขึ้นโดย',
            'createTime': 'เวลาในการสร้าง',
            'updateBy': 'อัปเดตโดย',
            'updateTime': 'เวลาอัปเดต',
            'remark': 'ข้อคิดเห็น',
        },
        'validate': {
            'id': 'กรุณากรอก ID',
            'type': 'เลือกประเภทของการประกาศ',
            'status': 'โปรดเลือกว่าจะเปิดใช้งานหรือไม่',
            'title': 'กรุณากรอกชื่อประกาศ',
            'content': 'กรุณากรอกเนื้อหาของประกาศ',
            'remark': 'กรุณากรอกความคิดเห็น',
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'หมายเลขซีเรียล',
            'identifier': 'ตัวระบุกอง',
            'useNum': 'จํานวนการใช้งาน',
            'chargeCapacity': 'การใช้งานทั้งหมด(kw)',

            'startTime': 'เวลาเริ่มต้น',
            'endTime': 'เวลาสิ้นสุด'
        },
        'validate': {
            'identifier': 'โปรดป้อนตัวระบุกอง',
            'startTime': 'โปรดเลือกเวลาเริ่มต้น',
            'endTime': 'โปรดเลือกเวลาสิ้นสุด'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'เครื่องดื่ม',
            '1': 'อาหาร',
            '2': 'ห้องน้ํา',
            '3': 'ช้อปปิ้ง',
        },
        'table': {
            'id': 'รหัส',
            'type': 'ประเภท',
            'status': 'สภาพ',
            'name': 'ชื่อ',
            'lng': 'เส้นแวง',
            'lat': 'เส้นรุ้ง',
            'address': 'ที่อยู่',
            'description': 'การบรรยาย',
            'reId': 'รหัสภูมิภาคที่เกี่ยวข้อง'
        },
        'validate': {
            'type': 'โปรดเลือกประเภท',
            'status': 'โปรดเลือกสถานะ',
            'name': 'กรุณากรอกชื่อ',
            'lng': 'กรุณากรอกลองจิจูด',
            'lat': 'กรุณากรอกละติจูด',
            'address': 'กรุณากรอกที่อยู่',
            'description': 'กรุณากรอกรายละเอียด',
            'reId': 'ภูมิภาคที่เกี่ยวข้อง'
        }
    },
    'companyManage': {
        'field': {
            'id': 'คีย์หลัก',
            'tId': 'รหัสผู้เช่าที่เกี่ยวข้อง',
            'name': 'ชื่อบริษัท',
            'attn': 'ติดต่อบริษัท',
            'tel': 'โทรศัพท์บริษัท',
            'address': 'ที่อยู่บริษัท',
            'email': 'อีเมล์บริษัท',
            'invoiceDate': 'วันที่ออกใบแจ้งหนี้',
            'invoiceNo': 'หมายเลขใบแจ้งหนี้',
            'contactPerson': 'ติดต่อ',
            'contactTel': 'เบอร์ติดต่อ',
            'contactEmail': 'อีเมล์ติดต่อ',
            'monthlyFee': 'ค่าที่ตั้งไว้ล่วงหน้าของค่าบริการรายเดือนสำหรับการเข้าถึงระบบ',
            'company': 'ข้อมูล บริษัท'
        },
        'validate': {
            'id': 'กรุณาป้อนคีย์หลัก',
            'tId': 'ป้อนรหัสผู้เช่าที่เกี่ยวข้อง',
            'name': 'กรุณากรอกชื่อบริษัท',
            'attn': 'กรุณากรอกข้อมูลติดต่อบริษัท',
            'tel': 'กรุณากรอกเบอร์โทรศัพท์บริษัท',
            'address': 'กรุณากรอกเบอร์โทรศัพท์บริษัท',
            'email': 'กรุณากรอกอีเมลบริษัท',
            'invoiceDate': 'กรุณาใส่วันที่ออกใบแจ้งหนี้',
            'invoiceNo': 'กรุณากรอกหมายเลขใบแจ้งหนี้',
            'contactPerson': 'กรุณากรอกข้อมูลติดต่อ',
            'contactTel': 'กรุณากรอกเบอร์ติดต่อ',
            'contactEmail': 'กรุณากรอกอีเมลติดต่อของคุณ',
            'monthlyFee': 'โปรดป้อนค่าเริ่มต้นของค่าเข้าชมระบบรายเดือน'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'รหัสผู้เช่า',
            'tenantKey': 'โลโก้ผู้เช่า',
            'name': 'ชื่อผู้เช่า',
            'domain': 'ผูกชื่อโดเมน',
            'tpId': 'แพคเกจพันธมิตร',
            'authId': 'สิทธิ์ของสมาคม',
            'level': 'ระดับ',
            'contact': 'ผู้ติดต่อ',
            'email': 'ติดต่อกล่องจดหมาย',
            'phone': 'เบอร์ติดต่อ',
            'startTime': 'เวลาเปิดใช้งาน',
            'expireTime': 'เวลาหมดอายุ',
        },
        'args':{
            'yedPayExtendParams': 'พารามิเตอร์ YedPay',
            'yedPayApiKey': 'YedPay ApiKey',
            'yedPaySingKey': 'YedPay SingKey',

            'mPayExtendParams': 'พารามิเตอร์ MPay',
            'mPayPubKey': 'กุญแจสาธารณะ MPay',
            'mPayPriKey': 'กุญแจส่วนตัวของ MPay',
        },
        'validate': {
            'id': 'กรุณากรอก ID',
            'pid': 'กรุณากรอกรหัสผู้เช่า',
            'tenantKey': 'กรุณากรอกรหัสผู้เช่า',
            'name': 'กรุณากรอกชื่อผู้เช่า',
            'domain': 'กรุณากรอกชื่อโดเมนที่ผูกไว้',
            'tpId': 'โปรดเลือกแพ็คเกจพันธมิตร',
            'authId': 'โปรดเลือกสิทธิ์ของสมาคม',
            'contact': 'กรุณากรอกชื่อผู้ติดต่อ',
            'email': 'กรุณากรอกอีเมลสำหรับติดต่อ',
            'phone': 'กรุณากรอกเบอร์ติดต่อ',
            'startTime': 'โปรดเลือกเวลาเริ่มต้น',
            'expireTime': 'โปรดเลือกเวลาหมดอายุ',

            'yedPayExtendParams': 'โปรดป้อนพารามิเตอร์ YedPay',
            'yedPayApiKey': 'กรุณากรอก YedPay ApiKey',
            'yedPaySingKey': 'กรุณากรอก YedPay SingKey',
            'mPayExtendParams': 'โปรดป้อนพารามิเตอร์ MPay',
            'mPayPubKey': 'โปรดป้อนกุญแจสาธารณะของ MPay',
            'mPayPriKey': 'โปรดป้อนกุญแจส่วนตัวของ MPay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'หมายเลขแพ็คเกจ',
            'name': 'ชื่อแพคเกจ',
            'createTenantMax': 'สามารถสร้างจำนวนผู้เช่าได้',
            'createUserMax': 'สามารถสร้างจำนวนผู้ใช้งานได้',
            'createAppMax': 'จำนวนแอพที่สามารถลงทะเบียนได้',
            'expireLong': 'ระยะเวลาที่แพคเกจใช้ได้',
            'tenantKey': 'โลโก้ผู้เช่า',
        },
        'validate': {
            'id': 'กรุณากรอก ID',
            'packageNo': 'กรุณากรอกหมายเลขแพ็คเกจ',
            'name': 'กรุณากรอกชื่อแพคเกจ',
            'createTenantMax': 'กรุณาใส่จำนวนผู้เช่าที่สามารถสร้างได้',
            'createUserMax': 'โปรดป้อนจำนวนผู้ใช้ที่สามารถสร้างได้',
            'createAppMax': 'กรุณากรอกจำนวนแอพที่สามารถลงทะเบียนได้',
            'expireLong': 'กรุณาเลือกระยะเวลาที่แพคเกจสามารถใช้ได้',
            'tenantKey': 'กรุณาระบุชื่อผู้เช่า',
        }
    },
    'card': {
        'cardType': {
            '0': 'รายการสีขาว',
            '1': 'บัญชีดำ',
            '2': 'การ์ดระบบ',
            '3': 'บัตรผู้ใช้',
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'หมายเลขบัตร',
            'cardType': 'ประเภท',
            'cardStatus': 'เปิดใช้งานหรือไม่',
        },
        'validate': {
            'cardId': 'กรุณากรอก ID',
            'cardNo': 'กรุณากรอกหมายเลขบัตร',
            'cardType': 'กรุณาเลือกประเภท',
            'cardStatus': 'กรุณาเลือกสถานะ',
        }
    },
    'waller': {
        'wType': {
            '1': 'ยอดคงเหลือ',
            '2': 'จำนวนเงินที่ได้รับ',
            '3': 'คะแนน'
        },
        'table': {
            'walletId': 'ID',
            'account': 'บัญชี',
            'type': 'ประเภท',
            'status': 'ไม่ว่าจะเป็นการแช่แข็ง',
            'balance': 'ยอดคงเหลือ',
        },
        'validate': {
            'account': 'กรุณากรอกข้อมูลบัญชี',
            'type': 'กรุณาเลือกประเภท',
            'status': 'กรุณาเลือกสถานะ',
        }
    }
}
export default thTH