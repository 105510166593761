import {Base} from "@/api/Base"

const api = "meter"

export class MeterAdd extends Base {
    path = api + "/add"
}

export class MeterDelete extends Base {
    path = api + "/delete"
}

export class MeterList extends Base {
    path = api + "/list"
}

export class MeterEdit extends Base {
    path = api + "/edit"
}

export class MeterListAll extends Base {
    path = api + "/listAll"
}

export class MeterGetSelectListByidReg extends Base {
    path = api + "/getSelectListByidReg"
}

export class MeterFindLoadBalancedLogByIdMet extends Base {
    path = api + "/findLoadBalancedLogByIdMet"
}

// 设置电表状态
export class MeterSetStatus extends Base {
    path = api + "/setStatus"
}
