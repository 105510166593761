const frFR = {
    'succeedMsg': {
        '0': 'Réussir',
    },
    'errorMsg': {
        '500100': 'Exception côté serveur',
        '500101': 'Erreur de paramètre',
        '500102': 'Exception de validation des paramètres',
        '500103': 'La demande est illégale',
        '500104': 'Les visites sont trop fréquentes',
        '500105': "L'objet existe déjà",
        '500106': "L'opération a échoué, Veuillez réessayer",
        '500107': 'There is an association, deletion is prohibited',
        '500108': 'Exportation des exceptions',
        '500200': 'Login expired, please log in again',
        '500201': 'Le numéro de compte ou le mot de passe ne peut pas être vide',
        '500202': 'Le numéro de téléphone ne peut pas être vide',
        '500203': 'Le numéro de téléphone est dans le mauvais format',
        '500204': "Le numéro de téléphone n'existe pas",
        '500205': 'Le mot de passe est incorrect',
        '500206': 'Le compte est inexistant',
        '500207': 'Les numéros de compte ne peuvent contenir que des nombres, «anglais» et souligner',
        '500208': 'Authentification échouée',
        '500209': 'Les deux mots de passe ne sont pas les mêmes',
        '500210': 'Comptes en double',
        '500211': 'The account has been locked',
        '500212': 'Echec du CAPTCHA',
        '500213': 'Le numéro de téléphone est incorrect',
        '500214': 'Il ny a pas de locataire correspondant au nom de domaine, Contactez votre administrateur',
        '500215': 'existe le même nom de rôle',
        '500300': 'Si le service OCCP est anormal, contactez l\'administrateur',
        '500301': 'Les piles électriques ne sont pas disponibles',
        '500302': 'La configuration de la pile est vide',
        '500303': 'La batterie est hors ligne, veuillez vérifier si elle est disponible',
        '500304': "Le port de pile n'est pas disponible",
        '500305': "Le pistolet n'est pas inséré ou le pistolet actuel n'est pas prêt",
        '500306': "Commencez à charger l'échec",
        '500307': 'Échec de la charge groupée',
        '500401': 'Solde insuffisant',
        '500402': 'The order does not exist',
        '500403': "La commande n'a pas été payée",
        '500404': 'La commande a été payée',
        '500405': "Le statut de commande n'est pas [payé inutilisé]",
        '500406': 'La création de la commande a échoué',
        '500407': 'Il existe une commande non payée',
        '500408': 'La commande a été annulée',
        '500501': "L'article a été vendu en quelques secondes",
        '500502': 'Les offres de foudre ne peuvent pas être répétées',
        '500503': 'Deuxième échec de tuer',
        '500601': 'Le fichier ne peut pas être vide',
        '500602': 'Erreur de format de fichier',
        '500603': 'Le classeur Excel est vide',
        '500604': 'Échec de la lecture du fichier'
    },
    'public': {
        'name': {
            'nextStep': 'Prochaines étapes',
            'status': 'État',
            'hello': 'Bonjour',
            'login': 'Connexion',
            'logout': 'Se déconnecter',
            'username': "Nom d'utilisateur",
            'email': 'E-mail',
            'password': 'Mot de passe',
            'remember': 'Se souvenir de moi',
            'currentPassword': 'Mot de passe actuel',
            'newPassword': 'Nouveau mot de passe',
            'confirm_password': 'Confirmez le mot de passe',
            'reset_password': 'réinitialiser le mot de passe',
            'captcha': 'Captcha',
            'getCaptcha': 'Obtenir Captcha',
            'more': 'Plus',
            'add': 'Ajouter',
            'batchDel': 'Suppression par lots',
            'Export': 'Exportation',
            'Import': 'Importation',
            'DownloadTemp': 'Télécharger le template',
            'ImportTips': 'Cliquez ou faites glisser un fichier dans cette zone pour le télécharger',
            'edit': 'Modifier',
            'del': 'Supprimer',
            'detail': 'Détail',
            'yes': 'Oui',
            'no': 'Non',
            'empty': 'Vide',
            'are_you_sure_delete': 'Êtes-vous sûr de supprimer cet élément',
            'action_cannot_resumed': 'Cette action ne peut pas reprendre',
            'are_you_sure_operate': 'Déterminer l\'opération actuelle',
            'operate_remind': 'Opération actuelle sensible, soyez prudent',
            'search': 'Recherche',
            'reset': 'Réinitialiser',
            'spread': 'Diffuser',
            'retract': 'Se rétracter',
            'show': 'Montrer',
            'no_page': 'Pas de page',
            'service': 'Service',
            'phone_country_code': 'Code de pays téléphonique',
            'name': 'Nom',
            'phone': 'Téléphone',
            'sex': 'Sexe',
            'country': 'Pays',
            'address': 'Adresse',
            'birthday': 'Anniversaire',
            'remark': 'Remarque',
            'personal': 'Personal',
            'enclosure': 'Enceinte',
            'logo': 'Logo',
            'submit': 'Soumettre',
            'unknown': 'Unknown',
            'setting': 'Installation',
            'index': 'Indice',
            'createBy': 'Crée par',
            'createTime': 'Créer du temps',
            'updateBy': 'Mettre à jour',
            'updateTime': 'Temps de mise à jour',
            'type': 'Type',
            'args': 'Paramètres',
            'qrcode': 'Code 2D',
            'query': 'Enquête',
            'refund': 'Remboursement',
            'cancel': 'Annulation',
            'revoke': 'Révocation',
        },
        'msg': {
            'unRead': 'UnRead',
            'read': 'Read',
            'alarm': 'Panique',
            'reminder': 'Rappel',
            'advertise': 'Annonces'
        },
        'language': {
            'zhTW': 'Chinois (Traditionnel)',
            'enUS': 'English',
            'zhCN': 'Chinois simplifié)',
            'daDK': 'Danish',
            'idID': 'Indonesian',
            'msMY': 'Malay',
            'thTH': 'thaïlandais',
            'viVN': 'Vietnamese',
            'frFR': 'Français',
            'plPL': 'Polonais',
            'it': 'Italien',
        },
        'timeType': {'0': 'Année', '1': 'Mois', '2': 'Semaine', '3': 'Le jour'},
        'promptsToSelectTime': 'Veuillez sélectionner une heure',
        'pleaseSelect': 'Veuillez sélectionner',
        'pileStatus': {'0': 'Disponible', '1': 'Mise en charge', '2': 'Indisponible'},
        'isTrue': {'0': 'Non', '1': 'Oui'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E-mail', '1': 'Téléphone'},
        'doingStatus':{
            '0': 'En suspens',
            '1': 'Traitement en cours',
            '2': 'Terminé',
            '3': 'Annulation',
            '4': 'A expiré',
            '5': 'Terminaison'
        },
        'consumeType': {
            '0': "Chargement",
            '1': "Consommation de charge",
            '2': "Frais d'annulation",
            '3': "Équilibre",
            '4': 'Plan d\'achat',
            '5': 'Annuler le plan',
            '6': 'Plan de mise à jour',
            '7': 'Remboursement',
            '8': 'Ordre d\'amende',
            '9': 'Annulation des pénalités',
            '10': 'Présente',
            '11': 'Pleine donation',
            '12': 'Révocation',
        },
        'payStatus': {'0': 'Non payé', '1': 'Payé', '2': 'Mise en charge', '3': 'Complété', '4': 'Annuler'},
        'chargeType': {'0': 'Par heure', '1': 'Par ordre', '2': 'Par plan utilisateur'},
        'status': {'0': 'Unknown', '1': 'Normal', '2': 'Désactiver', '3': 'Supprimer'},
        'sex': {'1': 'Homme', '2': 'Femme', '3': 'Unknown'},
        'placeholder': {'please_select': 'Veuillez sélectionner'},
        'table': {
            'name': 'Nom',
            'status': 'État',
            'action': 'Action',
            'content': 'Contenu',
            'created_at': 'Créer du temps',
            'image': 'Image',
            'email': 'E-mail',
            'order_id': 'Commande',
            'sex': 'Sexe',
            'last_ip': 'Dernière IP',
            'phone_country_code': 'Veuillez sélectionner le code du pays du téléphone',
            'phone': 'Téléphone'
        },
        'field': {
            'name': 'Nom',
            'username': "Nom d'utilisateur",
            'content': 'Contenu',
            'phone_country_code': 'Code de pays téléphonique',
            'phone': 'Téléphone',
            'email': 'E-mail',
            'order_id': 'Commande',
            'portrait': 'Portrait',
            'status': 'État',
            'image': 'Image',
            'sex': 'Sexe'
        },
        'validate': {
            'required': {
                'id': "Manque d'identité",
                'username': "Veuillez saisir le nom d'utilisateur",
                'password': 'Veuillez saisir le mot de passe',
                'repassword': 'Veuillez répéter le mot de passe',
                'name': "Nom de l'entrée s'il vous plaît",
                'phone': 'Veuillez saisir le téléphone',
                'email': 'Veuillez être entrée par e-mail',
                'portrait': 'Veuillez télécharger le portrait'
            },
            'tips': {
                'inconsistent_password': 'Mot de passe incohérent',
                'length_should': 'La longueur doit être {min} à {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Chinois (Traditionnel)',
        'enUS': 'English',
        'zhCN': 'Chinois simplifié)',
        'daDK': 'Danish',
        'idID': 'Indonesian',
        'msMY': 'Malay',
        'thTH': 'thaïlandais',
        'viVN': 'Vietnamese',
        'frFR': 'Français',
        'plPL': 'Polonais',
        'it': 'Italien',
    },
    'nav': {
        'name': {
            'Dashboard': 'Tableau de bord',
            'RegionDashboard': 'Aperçu des pieux',
            'BigScreen': 'Grand écran',

            'AuthorityManage': "Gérer l'autorité",

            'BalanceLogManage': 'Équilibrez la connexion',
            'CarManage': 'Gérer la voiture',
            'CarImagesManage': "SMANGE D'IMAGE",
            'ChargeStationImagesManage': 'Charge Point Imagemanage',
            'ChargingStationManage': 'Facture de pointage',
            'ConnectorManage': 'Gérer le connecteur',
            'EmployeeManage': "Gérer l'opérateur",
            'ConnectorLogManage': 'Log du connecteur',
            'EventLogManage': "Logation d'événements",
            'MeterManage': 'Gérer le compteur',
            'OrderManage': 'Gérer la commande',
            'PlanManage': 'Plan Gérer',
            'RegionManage': 'Gérer la région',
            'RegionImagesManage': "Gérer l'image de la région",
            'RepairLogManage': 'Réparer la connexion',
            'RoleManage': 'Gérer les rôles',
            'TransactionLogManage': 'Logmanger les transactions',
            'UserManage': 'Gérer les utilisateurs',
            'UserPlanManage': "Planificateur d'utilisateur",
            'GetConfiguration': 'Obtenir une configuration',
            'DataTransfer': 'Transfert de données',

            'NoticeManage': 'Avis Gérer',
            'PublicityManage': 'Gérer la publicité',
            'ChargerSetting': 'Réglage du chargeur',
            'StatisticsManage': 'Statistiques',
            'AppManagement': 'Gestion des applications',
            'LogManagement': 'Gestion des journaux',
            'AppVersionManage': "Gérer la version de l'application",
            'WallerManage': 'Limite du compte',
            'iPadManagement': "Gestion de l'iPad",
            'iPadManage': "Gérer l'iPad",
            'ServiceManage': 'Gestion des services',
            'CardManage': 'Manajemen nomor kartu',

            'TenantManagement': 'La gestion du système',
            'SuperTenantManage': 'Super locataires',
            'TenantManage': 'Gérer les locataires',
            'TenantInfo': 'Gérer les locataires',
            'TenantPackage': 'Pack locataire',
        },
        'table': {'order_id': 'numéro de commande', 'url': 'URL'},
        'field': {
            'pid': 'Piquer',
            'order_id': 'numéro de commande',
            'name': 'Nom',
            'url': 'URL',
            'status': 'État',
            'icon': 'Icône'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': "Nom d'utilisateur s'il vous plaît",
                'email': "S'il vous plaît l'entrée par e-mail",
                'password': 'Veuillez saisir le mot de passe',
                'captcha': 'Veuillez entrer CAPTCHA'
            }
        }
    },
    'authority': {
        'name': {
            'title': 'Autorité',
            'detailTitle': "Détail de l'autorité",
            'editTitle': "Modifier l'autorité"
        },
        'table': {
            'id_aut': 'IDENTIFIANT',
            'a_id_aut': 'Autorité parentale',
            'name': 'Autorité',
            'resource_name': 'Nom de la ressource',
            'type': 'Taper',
            'sort': 'Trier'
        },
        'field': {
            'id_aut': 'IDENTIFIANT',
            'a_id_aut': 'Autorité parentale',
            'name': 'Autorité',
            'resource_name': 'Nom de la ressource',
            'type': 'Taper',
            'sort': 'Trier'
        },
        'validate': {
            'required': {
                'id_aut': 'ID requis',
                'a_id_aut': 'Autorité des parents requis',
                'name': 'Autorité requise',
                'resource_name': 'Nom de la ressource requis',
                'type': 'Type requis',
                'sort': 'Trier requis'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Paquet de recharge',
        'addRule': 'Ajouter une règle',
        'gift': {
            'enable': 'Ouvert ou non',
            'percentage': 'Par pourcentage',
            'star': 'Valeur de départ',
            'end': 'Valeur de fin',
            'give': 'La ligne',
        },
        'vGift': {
            'enable': 'Veuillez choisir d\'activer ou non',
            'percentage': 'Veuillez choisir par pourcentage',
            'star': 'Veuillez entrer une valeur de départ',
            'end': 'Veuillez entrer une valeur de fin',
            'give': 'Veuillez entrer une limite',
        },
        'name': {
            'title': 'Balance du journal',
            'detailTitle': "Détail du journal d'équilibre",
            'editTitle': "Modifier le journal de l'équilibre",
            'ticket': 'ticket de pénalité',
            'cancelFines': 'pénalité d\'annulation'
        },
        'table': {
            'id_bal_log': 'IDENTIFIANT',
            'id_use': 'Utiliser',
            'type': 'Taper',
            'amount': 'Amount',
            'log_time': 'Heure du journal',
            'tenant_key': 'Clé de locataire',
            'status': 'Statut',
            'payment_method': 'Mode de paiement'
        },
        'field': {
            'id_bal_log': 'IDENTIFIANT',
            'id_use': 'Uer',
            'type': 'Taper',
            'amount': 'Amount',
            'log_time': 'Heure du journal',
            'tenant_key': 'Clé de locataire',
            'status': 'Statut',
            'payment_method': 'Mode de paiement'
        },
        'validate': {
            'required': {
                'id_bal_log': 'ID requis',
                'id_use': 'Uer requis',
                'type': 'Type requis',
                'amount': 'Somme requise',
                'log_time': 'Temps de journal requis',
                'tenant_key': 'Clé du locataire requise',
                'status': 'État requis',
                'payment_method': 'Méthode de paiement requis'
            }
        }
    },
    'car': {
        'name': {'title': 'Voiture', 'detailTitle': 'Détail de la voiture', 'editTitle': 'Éditer la voiture'},
        'table': {
            'id_car': 'IDENTIFIANT',
            'id_use': 'Uer',
            'model': 'Modèle',
            'car_license': 'Licence',
            'max_years_old': 'Année maximale',
            'manufacture_year': 'Année de fabrication',
            'maximum_power': 'Puissance maximum',
            'is_second_hand': "Est d'occasion",
            'vehicle_inspection_date': "Date d'inspection des véhicules",
            'insurance_expiry_date': "Date d'expiration de l'assurance",
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_car': 'IDENTIFIANT',
            'id_use': 'Uer',
            'model': 'Modèle',
            'car_license': 'Licence',
            'max_years_old': 'Année maximale',
            'manufacture_year': 'Année de fabrication',
            'maximum_power': 'Puissance maximum',
            'is_second_hand': "Est d'occasion",
            'vehicle_inspection_date': "Date d'inspection des véhicules",
            'insurance_expiry_date': "Date d'expiration de l'assurance",
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_car': 'ID requis',
                'id_use': 'Uer requis',
                'model': 'Modèle requis',
                'car_license': 'Licence requise',
                'max_years_old': 'Année maximale requise',
                'manufacture_year': 'Année de fabrication requise',
                'maximum_power': 'Puissance maximale requise',
                'is_second_hand': "Est-ce que l'occasion est requise",
                'vehicle_inspection_date': "Date d'inspection du véhicule requise",
                'insurance_expiry_date': "Date d'expiration d'assurance requise",
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Images de voiture',
            'detailTitle': "Détails d'images de voiture",
            'editTitle': 'Modifier les images de la voiture'
        },
        'table': {
            'id_car_ima': 'IDENTIFIANT',
            'id_car': 'Voiture',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_car_ima': 'IDENTIFIANT',
            'id_car': 'Voiture',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_car_ima': 'ID requis',
                'id_car': 'Voiture requise',
                'url': 'URL requise',
                'upload_time': 'Téléchargement du temps requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Image du point de charge',
            'detailTitle': "Détail de l'image du point de charge",
            'editTitle': "Modifier l'image du point de charge"
        },
        'table': {
            'id_ima': 'IDENTIFIANT',
            'id_cha_poi': 'Point de charge',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_ima': 'IDENTIFIANT',
            'id_cha_poi': 'Point de charge',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_ima': 'ID requis',
                'id_cha_poi': 'Point de charge requis',
                'url': 'URL requise',
                'upload_time': 'Téléchargement du temps requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Configurer le paiement séquentiel de l'ordre de charge, défini par défaut par région",
            'billingMethod': "Définir la méthode de facturation des commandes de charge, définie par défaut par région",
        },
        'billingMethodType': {
            '0':"Par temps",
            '1':"Par puissance",
            '2':"Par plan utilisateur",
            '3':"Appuyez sur app",
            '4':"Par région",
        },
        'updateFirmware': {
            'identifier': "Matricule",
            'location': "Adresse distante",
            'retrieveDate': "Date de récupération",
        },
        'uValidate': {
            'identifier': "Veuillez saisir le numéro de série",
            'location': "Veuillez saisir une adresse distante",
            'retrieveDate': "Veuillez sélectionner une date de recherche",
        },
        'setting': {
            'index': 'numéro de série',
            'readonly': 'Lecture seulement',
            'key': 'Définir la clé',
            'value': 'Définir la valeur'
        },
        'settingField': {
            'readonly': "Sélectionnez s'il est en lecture seule",
            'key': 'La touche définie ne peut pas être vide',
            'value': 'La valeur de réglage ne peut pas être vide'
        },
        'name': {
            'title': 'Point de charge',
            'detailTitle': 'Détail du point de charge',
            'editTitle': 'Modifier le point de charge',
            'quickAdd': 'Ajouter le point de charge'
        },
        'table': {
            'id_cha_poi': 'IDENTIFIANT',
            'id_met': 'Mètre',
            'charge_box_id': 'ID de la boîte de charge',
            'firmwareVersion': 'Version du firmware',
            'last_heartbeat_time': 'Dernier temps cardiaque',
            'monthly_fee': 'Redevance mensuelle',
            'charger_type': 'Type de chargeur',
            'tenant_key': 'Clé de locataire',
            'name': 'Nom',
            'heartbeat_interval': 'Battement de battements cardiaques',
            'enable': 'Activer',
            'maxCurrent': "Courant maximal",
            'minCurrent': "Courant minimum",
            'isPrivate': "Privé ou non",
            'isReadMeter': "Lire la table ou non",
            'isReset': "Redémarrer ou non (redémarrer pour prendre effet)",
            'action': {
                'updateFirmware': "Micrologiciel",
                'hardReset': "Réinitialisation dure",
                'softReset': "Soft reset"
            }
        },
        'validate': {
            'id_cha_poi': 'ID requis',
            'id_met': 'Compteur requis',
            'charge_box_id': 'ID de boîte de charge requis',
            'last_heartbeat_time': 'Dernier temps cardiaque requis',
            'monthly_fee': 'Frais mensuels requis',
            'charger_type': 'Type de chargeur requis',
            'tenant_key': 'Clé du locataire requise',
            'name': 'Nom (obligatoire',
            'heartbeat_interval': 'Intervalle de battements cardiaques requis',
            'enable': 'Activer requis',
            'maxCurrent': "Veuillez remplir le courant maximum",
            'minCurrent': "Veuillez remplir le courant minimum",
        }
    },
    'connector': {
        'name': {
            'title': 'Connecteur',
            'detailTitle': 'Détail du connecteur',
            'editTitle': 'Modifier le connecteur',
            'startTransaction': 'Démarrer la transaction',
            'stopTransaction': 'Arrêter la transaction'
        },
        'table': {
            'id_con': 'IDENTIFIANT',
            'id_cha_poi': 'Point de charge',
            'name': 'Nom',
            'connector_id': 'ID de connecteur',
            'status': 'Statut',
            'tenant_key': 'Clé de locataire',
            'power': 'Power (KW)',
            'type': 'Taper'
        },
        'field': {
            'id_con': 'IDENTIFIANT',
            'id_cha_poi': 'Point de charge',
            'name': 'Nom',
            'connector_id': 'ID de connecteur',
            'status': 'Statut',
            'tenant_key': 'Clé de locataire',
            'power': 'Power (KW)',
            'type': 'Taper'
        },
        'validate': {
            'required': {
                'id_con': 'ID requis',
                'id_cha_poi': 'Point de charge requis',
                'name': 'Nom (obligatoire',
                'connector_id': 'ID du connecteur requis',
                'status': 'État requis',
                'tenant_key': 'Clé du locataire requise',
                'power': 'Puissance requise (kW)',
                'type': 'Type requis'
            }
        }
    },
    'employee': {
        'name': {'title': 'Operator', 'detailTitle': "Détail de l'opérateur", 'editTitle': "Modifier l'opérateur"},
        'table': {
            'id_emp': 'IDENTIFIANT',
            'id_rol': 'Rôle',
            'account': 'Compte',
            'password': 'Mot de passe',
            'salt': 'Sel',
            'last_login_time': 'Dernier temps de connexion',
            'register_time': 'Heure de registre',
            'login_failure_counter': 'Compteur de défaillance de connexion',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Nombre',
            'remark': 'Remarque',
            'status': 'Statut',
            'name': 'Nom',
            'commission_rate': 'Taux de commission',
            'yedpay_api_key': 'Clé API YedPay',
            'yedpay_sign_key': 'Clé de signe yedpay',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_emp': 'IDENTIFIANT',
            'id_rol': 'Rôle',
            'account': 'Compte',
            'password': 'Mot de passe',
            'salt': 'Sel',
            'last_login_time': 'Dernier temps de connexion',
            'register_time': 'Heure de registre',
            'login_failure_counter': 'Compteur de défaillance de connexion',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Nombre',
            'remark': 'Remarque',
            'status': 'Statut',
            'name': 'Nom',
            'commission_rate': 'Taux de commission',
            'yedpay_api_key': 'Clé API YedPay',
            'yedpay_sign_key': 'Clé de signe yedpay',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_emp': 'ID requis',
                'id_rol': 'Rôle requis',
                'account': 'Compte requis',
                'password': 'Mot de passe requis',
                'salt': 'Sel requis',
                'last_login_time': 'Dernier temps de connexion requis',
                'register_time': "Le temps d'enregistrement requis",
                'login_failure_counter': 'Compteur de défaillance de connexion requis',
                'email': 'Email (requis',
                'whatsAppPhone': 'Veuillez remplir le numéro WhatsApp',
                'remark': 'Remarque requise',
                'status': 'État requis',
                'name': 'Nom (obligatoire',
                'commission_rate': 'Taux de commission requis',
                'yedpay_api_key': 'Clé API Yedpay requis',
                'yedpay_sign_key': 'Clé de signe yedpay requise',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Journal des événements',
            'detailTitle': 'Détail du journal des événements',
            'editTitle': 'Modifier le journal des événements'
        },
        'table': {
            'id_env_log': 'IDENTIFIANT',
            'run_time': 'Temps d\'exécution(ms)',
            'class_method': 'Méthode d\'exécution',
            'event_time': "Heure de l'évènement",
            'log': 'Enregistrer',
            'tenant_key': 'Clé de locataire',
            'resCode': 'Code de réponse',
        },
        'field': {
            'id_env_log': 'IDENTIFIANT',
            'event_time': "Heure de l'évènement",
            'log': 'Enregistrer',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_env_log': 'ID requis',
                'class_method': 'Veuillez remplir la méthode d\'exécution',
                'event_time': "Temps d'événement requis",
                'log': 'Journal requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'meter': {
        'name': {'title': 'Mètre', 'detailTitle': 'Détail du compteur', 'editTitle': 'Modifier'},
        'table': {
            'id_met': 'IDENTIFIANT',
            'id_reg': 'Région',
            'name': 'Nom du compteur',
            'current_threshold': 'Seuil actuel',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_met': 'IDENTIFIANT',
            'id_reg': 'Région',
            'name': 'Nom du compteur',
            'current_threshold': 'Seuil actuel',
            'tenant_key': 'Clé de locataire',
            'loadByTactics': 'Activer les stratégies'
        },
        'validate': {
            'required': {
                'id_met': 'ID requis',
                'id_reg': 'Région requise',
                'name': 'Nom du compteur requis',
                'current_threshold': 'Seuil actuel requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Commande',
            'detailTitle': 'Détails de la commande',
            'editTitle': 'Modifier la commande',
            'cancel': 'Annuler une commande',
            'cancelAndRefund': 'Annulation de la commande et remboursement',
        },
        'table': {
            'id_ord': 'IDENTIFIANT',
            'id_car': 'Voiture',
            'id_con': 'Connecteur',
            'order_time': 'Temps de commande',
            'type': 'Taper',
            'amount': 'Amount',
            'charge_start_time': 'Heure de début de charge',
            'charge_end_time': 'Heure de fin devrait',
            'chargeEndTimeActual': 'Heure de fin réelle',
            'status': 'Statut',
            'payment_time': 'Temps de paiement',
            'electricity': 'Achat d\'électricité',
            'charge_minutes': 'Durée d\'achat',
            'currentElectricity': "Électricité actuelle",
            'ref_id_pla': 'Plan',
            'price': 'Prix',
            'commission_rate': 'Taux de commission',
            'tenant_key': 'Clé de locataire',
            'id_tra_log': 'TransactionLogid',

            'pay_type': 'Paytype',
            'payment_method': 'Mode de paiement'
        },
        'validate': {
            'id_ord': 'ID requis',
            'id_car': 'Voiture requise',
            'id_con': 'Connecteur requis',
            'order_time': 'Temps de commande requis',
            'type': 'Type requis',
            'amount': 'Somme requise',
            'charge_start_time': 'Heure de début de charge requise',
            'charge_end_time': 'Temps de fin de charge requis',
            'status': 'État requis',
            'payment_time': 'Temps de paiement requis',
            'electricity': 'Électricité requise',
            'ref_id_pla': 'Plan requis',
            'price': 'Prix requis',
            'commission_rate': 'Taux de commission requis',
            'tenant_key': 'Clé du locataire requise',
            'id_tra_log': 'TransactionLogid requis',
            'charge_minutes': 'Chargeminutes requis',
            'pay_type': 'PayType requis',
            'payment_method': 'Méthode de paiement requis'
        }
    },
    'plan': {
        'name': {'title': 'Plan', 'detailTitle': 'Détail du plan', 'editTitle': 'Modifier le plan'},
        'table': {
            'id_pla': 'IDENTIFIANT',
            'name': 'Nom de plan',
            'price_per_month': 'Prix',
            'contract_period': 'Période de contrat',
            'free_charging_time_limit': 'Temps de charge gratuit',
            'overtime_charge_price': 'Prix supplémentaire',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_pla': 'IDENTIFIANT',
            'name': 'Nom de plan',
            'price_per_month': 'Prix',
            'contract_period': 'Période de contrat',
            'free_charging_time_limit': 'Temps de charge gratuit',
            'overtime_charge_price': 'Prix supplémentaire',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_pla': 'ID requis',
                'name': 'Nom du plan requis',
                'price_per_month': 'Prix requis',
                'contract_period': 'Période de contrat requise',
                'free_charging_time_limit': 'Temps de charge gratuit requis',
                'overtime_charge_price': 'Prix des heures supplémentaires requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Pas par saut, pas de pas moins d'un saut est calculé par saut, si le calcul de la puissance réelle de la commande s'il vous plaît mettre à zéro",
            'byTimeUnit': "Pas par saut, pas d'un saut est calculé par un saut, si le temps réel de la commande est calculé, s'il vous plaît définir à zéro",

            'maximumPower': "Lors de la charge par charge, la limite supérieure de la charge pour une seule charge, laisser vide ou zéro n'est pas limité, pas de limite par défaut",
            'maximumMinute': "Lors de la charge par temps, le temps de charge unique est plafonné, laisser vide ou zéro est illimité, illimité par défaut",
        },
        'billingMethodType':{
            '0': "Par temps",
            '1': "Par puissance",
            '2': "Appuyez sur app",
        },
        'name': {'title': 'Région', 'detailTitle': 'Détails de la région', 'editTitle': 'Éditer la région'},
        'table': {
            'id_reg': 'IDENTIFIANT',
            'name': 'Nom de région',
            'ac_price_per_electricity': 'Prix AC par électricité',
            'ac_price_per_minute': 'Prix AC par minute',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Adresse',
            'dc_price_per_electricity': 'Prix CC par électricité',
            'dc_price_per_minute': 'Prix DC par minute',
            'tenant_key': 'Clé de locataire',
            'businessAllDay': 'Ouvert toute la journée',
            'images': 'Images',
            'byPowerUnit': "Unités de mesure par quantité d'électricité",
            'byTimeUnit': "Unités de mesure par temps",
            'postpaid': "Postpayé",
            'billingMethod': "Mode de facturation",
            'businessHours': "Période d'ouverture",

            'maximumPower': "Limite supérieure de charge pour une seule charge",
            'maximumMinute': "Limite supérieure de temps de charge unique",
            'directDebit': "Déductions automatiques",

            'enableTimeoutFine': "Pénalité pour délai d'ouverture",
            'timeoutFine': "Temps écoulé(min)",
            'timeoutCharging': "Facturation des timeouts",
        },
        'validate': {
            'id_reg': 'ID requis',
            'name': 'Nom de la région requis',
            'ac_price_per_electricity': 'Prix CA par électricité requis',
            'ac_price_per_minute': 'Prix AC par minute requis',
            'lon': 'LON requis',
            'lat': 'Lat requis',
            'address': 'Adresse requise',
            'dc_price_per_electricity': 'Prix CC par électricité requis',
            'dc_price_per_minute': 'Prix CC par minute requis',
            'tenant_key': 'Clé du locataire requise',
            'businessAllDay': 'Veuillez indiquer s’il est ouvert toute la journée',
            'byPowerUnit': "Veuillez remplir l'unité de mesure par quantité d'électricité",
            'byTimeUnit': "Veuillez remplir l'unité de mesure par temps",
            'postpaid': "Veuillez choisir si postpaid",
            'billingMethod': "Veuillez choisir une méthode de facturation",
            'businessHours': "Choisir une période d'ouverture",

            'maximumPower': "Veuillez remplir la limite de temps de charge unique",
            'maximumMinute': "Veuillez remplir la limite de temps de charge unique",
            'directDebit': "Veuillez choisir si vous souhaitez effectuer un prélèvement automatique",

            'enableTimeoutFine': "Veuillez choisir d'activer la pénalité de délai d'attente",
            'timeoutFine': "Veuillez remplir Timeout(min)",
            'timeoutCharging': "Veuillez remplir le délai de facturation",
        }

    },
    'regionImages': {
        'name': {
            'title': 'Image de région',
            'detailTitle': "Détail de l'image de la région",
            'editTitle': 'Edit Image de la région'
        },
        'table': {
            'id_reg_img': 'IDENTIFIANT',
            'id_reg': 'Région',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_reg_img': 'IDENTIFIANT',
            'id_reg': 'Région',
            'url': 'URL',
            'upload_time': 'Téléchargement',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_reg_img': 'ID requis',
                'id_reg': 'Région requise',
                'url': 'URL requise',
                'upload_time': 'Téléchargement du temps requis',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Journal de réparation',
            'detailTitle': 'Détail du journal de réparation',
            'editTitle': 'Modifier le journal de réparation'
        },
        'table': {
            'id_rep_log': 'IDENTIFIANT',
            'id_car': 'Voiture',
            'amount': 'Amount',
            'repair_date': 'Date de réparation',
            'remark': 'Remarque',
            'tenant_key': 'Clé de locataire'
        },
        'field': {
            'id_rep_log': 'IDENTIFIANT',
            'id_car': 'Voiture',
            'amount': 'Amount',
            'repair_date': 'Date de réparation',
            'remark': 'Remarque',
            'tenant_key': 'Clé de locataire'
        },
        'validate': {
            'required': {
                'id_rep_log': 'ID requis',
                'id_car': 'Voiture requise',
                'amount': 'Somme requise',
                'repair_date': 'Date de réparation requise',
                'remark': 'Remarque requise',
                'tenant_key': 'Clé du locataire requise'
            }
        }
    },
    'role': {
        'name': {'title': 'Rôle', 'detailTitle': 'Détail du rôle', 'editTitle': 'Modifier le rôle'},
        'table': {'id_rol': 'IDENTIFIANT', 'name': 'Rôle', 'remark': 'Remarque'},
        'field': {'id_rol': 'IDENTIFIANT', 'name': 'Rôle', 'remark': 'Remarque', 'authorityList': "Liste d'autorité"},
        'validate': {'required': {'id_rol': 'ID requis', 'name': 'Rôle requis', 'remark': 'Remarque requise'}}
    },
    'roleAuthority': {
        'name': {
            'title': 'Roléautorité',
            'detailTitle': 'Détail roleautority',
            'editTitle': 'Modifier la roleautority'
        },
        'table': {'id_rol_aut': 'IDENTIFIANT', 'id_rol': 'Rôle', 'id_aut': 'Autorité'},
        'field': {'id_rol_aut': 'IDENTIFIANT', 'id_rol': 'Rôle', 'id_aut': 'Autorité'},
        'validate': {'required': {'id_rol_aut': 'ID requis', 'id_rol': 'Rôle requis', 'id_aut': 'Autorité requise'}}
    },
    'transactionLog': {
        'name': {
            'title': 'Journal des transactions',
            'detailTitle': 'Détail du journal des transactions',
            'editTitle': 'Modifier le journal des transactions'
        },
        'table': {
            'id_tra_log': 'IDENTIFIANT',
            'id_con': 'Connecteur idt',
            'event_time': "Heure de l'évènement",
            'id_tag': "Étiquette d'identité",
            'start_time': 'Heure de début',
            'start_value': 'Valeur de démarrage',
            'stop_time': "Temps d'arrêt",
            'stop_value': "Valeur d'arrêt",
            'stop_reason': 'Arrêter la raison',
            'fail_reason': 'Échec de la raison',
            'tenant_key': 'Clé de locataire',
            'current_electricity': 'Électricité actuelle'
        },
        'field': {
            'id_tra_log': 'IDENTIFIANT',
            'id_con': 'Connecteur idt',
            'event_time': "Heure de l'évènement",
            'id_tag': "Étiquette d'identité",
            'start_time': 'Heure de début',
            'start_value': 'Valeur de démarrage',
            'stop_time': "Temps d'arrêt",
            'stop_value': "Valeur d'arrêt",
            'stop_reason': 'Arrêter la raison',
            'fail_reason': 'Échec de la raison',
            'tenant_key': 'Clé de locataire',
            'current_electricity': 'Électricité actuelle'
        },
        'validate': {
            'required': {
                'id_tra_log': 'ID requis',
                'id_con': 'Connecteur IDT requis',
                'event_time': "Temps d'événement requis",
                'id_tag': "Tag d'identification requise",
                'start_time': 'Heure de début requise',
                'start_value': 'Valeur de démarrage requise',
                'stop_time': "Temps d'arrêt requis",
                'stop_value': "Valeur d'arrêt requise",
                'stop_reason': 'Arrêt Raison requis',
                'fail_reason': 'Défaillance de la raison requise',
                'tenant_key': 'Clé du locataire requise',
                'current_electricity': 'Électricité actuelle requise'
            }
        }
    },
    'user': {
        'name': {'title': 'User', 'detailTitle': "Détail de l'utilisateur", 'editTitle': "Modifier l'utilisateur"},
        'table': {
            'id_use': 'IDENTIFIANT',
            'balance': 'Équilibre',
            'account': 'Compte',
            'password': 'Mot de passe',
            'register_time': 'Heure de registre',
            'email': 'E-mail',
            'token': 'Jeton',
            'salt': 'Sel',
            'tenant_key': 'Clé de locataire',
            'phone': 'Téléphone',
            'register_type': "Type d'enregistrement"
        },
        'field': {
            'id_use': 'IDENTIFIANT',
            'balance': 'Équilibre',
            'account': 'Compte',
            'password': 'Mot de passe',
            'register_time': 'Heure de registre',
            'email': 'E-mail',
            'token': 'Jeton',
            'salt': 'Sel',
            'tenant_key': 'Clé de locataire',
            'phone': 'Téléphone',
            'register_type': "Type d'enregistrement",

            'chargingCard': 'Numéro de carte de charge',
            'chargingType': 'Mode de charge prioritaire',
            'chargingDefDuration': 'Temps de charge des cartes(min)',
            'chargingDefPower': 'Quantité de charge de cartes(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'ID requis',
                'balance': 'Équilibre requis',
                'account': 'Compte requis',
                'password': 'Mot de passe requis',
                'register_time': "Le temps d'enregistrement requis",
                'email': 'Email (requis',
                'token': 'Token requis',
                'salt': 'Sel requis',
                'tenant_key': 'Clé du locataire requise',
                'phone': 'Téléphone requis',
                'register_type': "Type d'enregistrement requis",

                'chargingCard': 'Veuillez remplir le numéro de la carte de charge',
                'chargingType': 'Veuillez sélectionner le mode de charge prioritaire carte',
                'chargingDefDuration': 'Veuillez remplir la carte temps de charge(min)',
                'chargingDefPower': 'Veuillez remplir la carte quantité de charge(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': "Plan d'utilisateur",
            'detailTitle': 'Détail du plan utilisateur',
            'editTitle': 'Modifier le plan utilisateur'
        },
        'table': {
            'id_use': 'User',
            'id_pla': 'Plan',
            'arrearsCount': 'Comptage des arriérés',
            'start_date': 'Date de début',
            'tenant_key': 'Clé de locataire',
            'createType': 'Créer une identité',
            'updateByMonth': 'Temps de pré - paiement',

            'total': 'Nombre de mois prépayés',
            'amount': 'Montant estimé du paiement',
            'prePayData': 'Mois de cotisation estimés',
        },
        'validate': {
            'id_use': 'Uer requis',
            'id_pla': 'Plan requis',
            'arrearsCount': 'Veuillez remplir le nombre de mois en retard',
            'start_date': 'Date de début requise',
            'tenant_key': 'Clé du locataire requise'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Total des chargeurs',
            'availableChargers': 'Ports disponibles',
            'inUseChargers': "Ports d'utilisation",
            'unavailableChargers': 'Ports indisponibles',
            'portUsage': 'Utilisation des ports',

            'energyUsage': "Utilisation d'énergie (kWh)",
            'totalRevenue': 'Revenu total',
            'totalEnergy': 'Énergie totale',
            'newUser': 'Nouvel utilisateur'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Version Android',
            'androidDownloadLink': 'Lien de téléchargement Android',
            'androidComplieNumber': 'Numéro de compilation Android',
            'iosVersion': 'Version iOS',
            'iosBundleId': 'ID de faisceau iOS',
            'iosComplieNumber': 'Numéro de compilation iOS',
            'isForcedUpdate': 'Est une mise à jour forcée'
        },
        'validate': {
            'required': {
                'androidVersion': 'Version Android requise',
                'androidDownloadLink': 'Lien de téléchargement Android requis',
                'androidComplieNumber': 'Numéro de compilation Android requis',
                'iosVersion': 'Version iOS requise',
                'iosBundleId': 'ID de faisceau iOS requis',
                'iosComplieNumber': 'Numéro de compilation iOS requis',
                'isForcedUpdate': 'Veuillez choisir si vous souhaitez forcer la mise à jour'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Clé de réglage', 'dataStr': 'Valeur de réglage'}},
    'superTenantManage': {
        'name': {'title': 'Autorisation', 'detailTitle': 'Détails', 'editTitle': 'Modifier'},
        'searchForm': {
            'dataSourceKey': 'Clé de source de données',
            'url': 'URL',
            'username': "Nom d'utilisateur",
            'tenant': 'Locataire',
            'isEnable': 'Est autorisé',
            'isCreate': 'IsCreate'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Veuillez saisir la clé de source de données',
            'url': "Veuillez saisir l'adresse du lien",
            'username': 'Veuillez saisir le compte',
            'tenant': 'Veuillez saisir le nom du locataire',
            'isEnable': "Veuillez choisir d'activer",
            'isCreate': 'Veuillez choisir de créer un logo'
        },
        'table': {
            'index': 'Indice',
            'dataSourceKey': 'Clé de source de données',
            'url': 'URL',
            'host': 'Host',
            'port': 'Port',
            'dbName': 'Nom de la base de données',
            'username': "Nom d'utilisateur",
            'password': 'Mot de passe',
            'tenant': 'locataire',
            'isEnable': 'Est autorisé',
            'isCreate': 'IsCreate',
            'domain': 'Domaine',
            'action': {'testConn': 'Connecticut', 'create': 'Créer', 'edit': 'Modifier', 'del': 'Supprimer'}
        },
        'field': {
            'id': 'IDENTIFIANT',
            'datasourceKey': 'Clé de source de données',
            'seckey': "Clés d'accès",
            'url': 'URL',
            'host': 'Host',
            'port': 'Port',
            'dbName': 'Database Name',
            'username': "Nom d'utilisateur",
            'password': 'Mot de passe',
            'tenant': 'Locataire',
            'isEnable': 'Est autorisé',
            'isCreate': 'IsCreate',
            'remark': 'Remarque',
            'domain': 'Domaine',
            'onesignalKey': 'Clé de notification APP',
        },
        'validate': {
            'required': {
                'id': "Veuillez remplir l'identifiant",
                'dataSourceKey': 'Veuillez remplir la clé de source de données',
                'seckey': 'Veuillez remplir le Seckey',
                'url': "Veuillez remplir l'URL du lien",
                'host': "Veuillez remplir l'hôte",
                'port': 'Veuillez remplir le port',
                'dbName': 'Veuillez remplir le nom de la base de données',
                'username': "Veuillez entrer le nom d'utilisateur",
                'password': 'Veuillez remplir le mot de passe',
                'tenant': 'Veuillez remplir le nom du locataire',
                'isEnable': 'Veuillez sélectionner un état actif',
                'remark': 'Veuillez remplir les remarques',
                'domain': 'Veuillez remplir le domaine',
                'onesignalKey': 'Veuillez remplir la clé de notification APP',
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'IDENTIFIANT',
            'index': 'Indice',
            'machineCode': 'Langage machine',
            'name': 'Nom',
            'regId': 'Region ID',
            'regName': 'Nom de région',
            'seckey': 'Seckey',
            'args': 'Args',
            'createBy': 'Crée par',
            'createTime': 'Créer du temps',
            'updateBy': 'Mettre à jour',
            'updateTime': 'Temps de mise à jour',
            'remark': 'Remarque'
        },
        'validate': {
            'id': "Veuillez remplir l'identifiant",
            'machineCode': 'Veuillez remplir le code machine',
            'name': 'Veuillez remplir le nom',
            'regId': "Veuillez remplir l'ID de la région",
            'seckey': 'Veuillez remplir le Seckey',
            'args': 'Veuillez remplir les args',
            'remark': 'Veuillez remplir la remarque'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mail', '1': 'Phone'},
        'noticeType': {
            '0': 'Avertissement',
            '1': 'Rappeler',
            '2': 'Annonce',
            '3': 'Timeout',
        },
        'field': {
            'id': 'IDENTIFIANT',
            'index': 'Indice',
            'toFrom': 'Compte',
            'type': 'Type de compte',
            'noticeType': 'Type de notification',
            'status': 'Est activé',
            'createBy': 'Crée par',
            'createTime': 'Créer du temps',
            'updateBy': 'Mettre à jour',
            'updateTime': 'Temps de mise à jour',
            'remark': 'Remarque'
        },
        'validate': {
            'id': 'Please fill in the ID',
            'type': 'Veuillez choisir le type de compte',
            'toFrom': 'Veuillez remplir le compte',
            'noticeType': 'Veuillez choisir le type de notification',
            'status': "Veuillez choisir d'activer",
            'remark': 'Veuillez remplir la remarque'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': "Conditions d'utilisation",
            '1': 'À propos de nous',
            '2': 'Annonce',
            '3': 'Avis',
            '4': 'Rappeler'
        },
        'field': {
            'id': 'IDENTIFIANT',
            'index': 'Indice',
            'type': 'Type de publicité',
            'status': 'Est autorisé',
            'title': 'Titre',
            'content': 'Contenu',
            'tenantKey': 'Identité du locataire',
            'createBy': 'crée par',
            'createTime': 'Créer du temps',
            'updateBy': 'Mettre à jour',
            'updateTime': 'Temps de mise à jour',
            'remark': 'Remarque'
        },
        'validate': {
            'id': "Veuillez remplir l'identifiant",
            'type': "Veuillez sélectionner le type d'annonce",
            'status': "Veuillez sélectionner s'il faut l'activer",
            'title': "Veuillez remplir le titre de l'annonce",
            'content': "Veuillez remplir le contenu de l'avis",
            'remark': 'Veuillez remplir les commentaires'
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'Numéro de série',
            'identifier': 'Identifiant de tas',
            'useNum': "Numéro d'utilisation",
            'chargeCapacity': 'Charge totale (KW)',
            'startTime': 'Heure de début',
            'endTime': 'Heure de fin'
        },
        'validate': {
            'identifier': 'Veuillez saisir le numéro de pile',
            'startTime': 'Veuillez sélectionner une heure de début',
            'endTime': 'Veuillez sélectionner une heure de fin'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'Drink',
            '1': 'Food',
            '2': 'Toilet',
            '3': 'Shopping',
        },
        'table': {
            'id': 'ID',
            'type': 'Type',
            'status': 'Status',
            'name': 'Name',
            'lng': 'longitude',
            'lat': 'latitude',
            'address': 'adresse',
            'description': 'description',
            'reId': 'ID de région associé'
        },
        'validate': {
            'type': 'Please select type',
            'status': 'Please select status',
            'name': 'Please fill in name',
            'lng': 'Please fill in longitude',
            'lat': 'Please fill in latitude',
            'address': 'Please fill in address',
            'description': 'Please fill in description',
            'reId': 'Associated area'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Clé primaire',
            'tId': 'ID du locataire associé',
            'name': 'Nom l\'entreprise',
            'attn': 'Contact l\'entreprise',
            'tel': 'Téléphone l\'entreprise',
            'address': 'Adresse la société',
            'email': 'Email l\'entreprise',
            'invoiceDate': 'Date la facture',
            'invoiceNo': 'Numéro facture',
            'contactPerson': 'Contact',
            'contactTel': 'Téléphone Contact',
            'contactEmail': 'Email Contact',
            'monthlyFee': 'Contact Email système d\'accès frais mensuels prédéfinis',
            'company': 'Informations sur la société'
        },
        'validate': {
            'id': 'Veuillez entrer la clé primaire',
            'tId': 'Entrez l\'ID du locataire associé',
            'name': 'Veuillez entrer le nom de l\'entreprise',
            'attn': 'Veuillez entrer la société contact',
            'tel': 'Veuillez entrer votre téléphone d\'entreprise',
            'address': 'Veuillez entrer l\'adresse de votre entreprise',
            'email': 'Veuillez entrer votre adresse e - mail d\'entreprise',
            'invoiceDate': 'Veuillez entrer la date de facturation',
            'invoiceNo': 'Veuillez entrer le numéro de facture',
            'contactPerson': 'Veuillez entrer un contact',
            'contactTel': 'Veuillez entrer votre numéro de contact',
            'contactEmail': 'Veuillez entrer votre email de contact',
            'monthlyFee': 'Veuillez entrer une valeur par défaut pour les frais d\'accès mensuels au système'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'ID du locataire parent',
            'tenantKey': 'Clé du locataire',
            'name': 'Nom du locataire',
            'domain': 'Lier un nom de domaine',
            'tpId': 'Paquets associés',
            'authId': 'Autorisations associées',
            'level': 'Quantité',
            'contact': 'Contact',
            'email': 'Email de contact',
            'phone': 'Téléphone de contact',
            'startTime': 'Temps d\'activation',
            'expireTime': 'Temps d\'expiration',
        },
        'args':{
            'yedPayExtendParams': 'Les args yedpay',
            'yedPayApiKey': 'Yedpay apikey',
            'yedPaySingKey': 'Yedpay singkey',

            'mPayExtendParams': 'Le Mpay args',
            'mPayPubKey': 'Clé publique Mpay',
            'mPayPriKey': 'Le Mpay prikey',
        },
        'validate': {
            'id': 'Veuillez entrer un ID',
            'pid': 'Veuillez entrer l\'ID du locataire parent',
            'tenantKey': 'Veuillez remplir la clé du locataire',
            'name': 'Veuillez remplir le nom du locataire',
            'domain': 'Veuillez remplir le nom de domaine lié',
            'tpId': 'Veuillez sélectionner le paquet associé',
            'authId': 'Veuillez sélectionner les autorisations associées',
            'contact': 'Veuillez remplir le contact',
            'email': 'Veuillez remplir l\'email de contact',
            'phone': 'Veuillez remplir le numéro de contact',
            'startTime': 'Veuillez sélectionner une heure de début',
            'expireTime': 'Veuillez sélectionner une date d\'expiration',

            'yedPayExtendParams': 'Veuillez entrer les paramètres yedpay',
            'yedPayApiKey': 'Veuillez entrer yedpay apikey',
            'yedPaySingKey': 'Veuillez entrer yedpay singkey',
            'mPayExtendParams': 'Veuillez entrer le paramètre Mpay',
            'mPayPubKey': 'Veuillez entrer la clé publique Mpay',
            'mPayPriKey': 'Veuillez entrer la clé privée Mpay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Numéro du colis',
            'name': 'Nom du paquet',
            'createTenantMax': 'Nombre de locataires pouvant être créés',
            'createUserMax': 'Nombre d’utilisateurs pouvant être créés',
            'createAppMax': 'Nombre d\'applications pouvant être enregistrées',
            'expireLong': 'Durée de validité du pack',
            'tenantKey': 'Identification des locataires',
        },
        'validate': {
            'id': 'Veuillez entrer un ID',
            'packageNo': 'Veuillez entrer le numéro de colis',
            'name': 'Veuillez entrer le nom du paquet',
            'createTenantMax': 'Veuillez entrer le nombre de locataires que vous pouvez créer',
            'createUserMax': 'Veuillez entrer l\'identification du locataire',
            'createAppMax': 'Veuillez entrer le nombre d\'applications enregistrables',
            'expireLong': 'Veuillez choisir la période de validité de votre colis',
            'tenantKey': 'Veuillez entrer la clé du locataire',
        }
    },
    'card': {
        'cardType': {
            '0': 'Liste Blanche',
            '1': 'Liste Noire',
            '2': 'Carte Système',
            '3': 'Carte Utilisateur',
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'numéro de carte',
            'cardType': 'type',
            'cardStatus': 'activer ou non',
        },
        'validate': {
            'cardId': 'veuillez remplir l\'id',
            'cardNo': "Veuillez remplir le numéro de la carte",
            'cardtype': "Veuillez sélectionner un type",
            'cardstatus': "Veuillez sélectionner un statut",
        }
    },
    'waller': {
        'wType': {
            '1': 'Solde',
            '2': 'Subvention',
            '3': 'Points'
        },
        'table': {
            'walletId': 'ID',
            'account': 'Compte',
            'type': 'Type',
            'status': 'Congelé ou non',
            'balance': 'Solde',
        },
        'validate': {
            'account': 'Veuillez remplir le compte',
            'type': 'Veuillez sélectionner le type',
            'status': 'Veuillez sélectionner un statut',
        }
    }
}
export default frFR