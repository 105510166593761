<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="onOk"
      :title=" $t('public.name.edit')"
      :zIndex="900"
      :loading="loading"
  >
    <Form
        ref="refForm"
        layout="vertical"
        name="dynamic_form_nest_item"
        :model="dynamicValidateForm"
        class="modal"
    >
      <div style="text-align: center;margin: 8px 30px">

        <Row justify="space-around" style="width: 80%;margin-left: 7%">
          <Col span="10">
            <FormItem name="postpaid" :label="$t('region.table.postpaid')"
                      :rules="{required: true, message: $t('region.validate.postpaid'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.postpaid" :placeholder="$t('region.validate.postpaid')"
                      :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="directDebit" :label="$t('region.table.directDebit')" :rules="{required: true, message: $t('region.validate.directDebit'),}" style="width: 100%" v-if="dynamicValidateForm.postpaid === 1">
              <Select v-model:value="dynamicValidateForm.directDebit" :placeholder="$t('region.validate.directDebit')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>

          <Col span="10">
            <FormItem name="billingMethod" :label="$t('region.table.billingMethod')"
                      :rules="{required: true, message: $t('balanceLog.vGift.enable'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.billingMethod"
                      :placeholder="$t('region.validate.billingMethod')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('region.billingMethodType.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('region.billingMethodType.1') }}</SelectOption>
                <SelectOption :value="2">{{ $t('region.billingMethodType.2') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="enableTimeoutFine" :label="$t('region.table.enableTimeoutFine')" :rules="{required: true, message: $t('region.validate.enableTimeoutFine'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.enableTimeoutFine" :placeholder="$t('balanceLog.vGift.enable')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>

          <Col span="10" v-if="dynamicValidateForm.enableTimeoutFine === 1">
            <FormItem name="timeoutFine" :label="$t('region.table.timeoutFine')" :rules="{required: true, message: $t('region.validate.timeoutFine'),}" style="width: 100%">
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.timeoutFine"
                  :placeholder="$t('region.validate.timeoutFine')"
                  :step="1"
              />
            </FormItem>
          </Col>
          <Col span="10" v-if="dynamicValidateForm.enableTimeoutFine === 1">
            <FormItem name="timeoutCharging" :label="$t('region.table.timeoutCharging')" :rules="{required: true, message: $t('region.validate.timeoutCharging'),}" style="width: 100%">
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.timeoutCharging"
                  :placeholder="$t('region.validate.timeoutCharging')"
                  :step="1"
              />
            </FormItem>
          </Col>

          <Col span="10">
            <FormItem name="byPowerUnit" :rules="{required: true, message: $t('region.validate.byPowerUnit'),}" style="width: 100%">
              <template #label>
                <div class="label-container">
                  <span>{{ $t('region.table.byPowerUnit') + '(kwh)' }}</span>
                  <Tooltip placement="top" :title="$t('region.tooltip.byPowerUnit')" autoAdjustOverflow>
                    <question-circle-outlined class="label-icon"/>
                  </Tooltip>
                </div>
              </template>
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.byPowerUnit"
                  :placeholder="$t('region.validate.byPowerUnit')"
                  :step="1"
              />
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="byTimeUnit" :rules="{required: true, message: $t('region.validate.byTimeUnit'),}" style="width: 100%">
              <template #label>
                <div class="label-container">
                  <span>{{ $t('region.table.byTimeUnit') + '(min)' }}</span>
                  <Tooltip placement="top" :title="$t('region.tooltip.byTimeUnit')" autoAdjustOverflow>
                    <question-circle-outlined class="label-icon"/>
                  </Tooltip>
                </div>
              </template>
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.byTimeUnit"
                  :placeholder="$t('region.validate.byTimeUnit')"
                  :step="1"
              />
            </FormItem>
          </Col>

          <Col span="10">
            <FormItem name="maximumPower" :rules="{required: true, message: $t('region.validate.maximumPower'),}" style="width: 100%">
              <template #label>
                <div class="label-container">
                  <span>{{ $t('region.table.maximumPower') + '(kwh)' }}</span>
                  <Tooltip placement="top" :title="$t('region.tooltip.maximumPower')" autoAdjustOverflow>
                    <question-circle-outlined class="label-icon"/>
                  </Tooltip>
                </div>
              </template>
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.maximumPower"
                  :placeholder="$t('region.validate.maximumPower')"
                  :step="1"
              />
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="maximumMinute" :rules="{required: true, message: $t('region.validate.maximumMinute'),}" style="width: 100%">
              <template #label>
                <div class="label-container">
                  <span>{{ $t('region.table.maximumMinute') + '(min)' }}</span>
                  <Tooltip placement="top" :title="$t('region.tooltip.maximumMinute')" autoAdjustOverflow>
                    <QuestionCircleOutlined class="label-icon"/>
                  </Tooltip>
                </div>
              </template>
              <InputNumber
                  style="width: 100%"
                  v-model:value="dynamicValidateForm.maximumMinute"
                  :placeholder="$t('region.validate.maximumMinute')"
                  :step="1"
              />
            </FormItem>
          </Col>

          <Col span="10">
            <FormItem name="businessAllDay" :label="$t('region.table.businessAllDay')"
                      :rules="{required: true, message: $t('region.validate.businessAllDay'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.businessAllDay"
                      :placeholder="$t('region.validate.businessAllDay')" :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>
          <Col span="10">
            <FormItem name="distingAcDc" label="AC/DC"
                      :rules="{required: true, message: $t('balanceLog.vGift.percentage'),}" style="width: 100%">
              <Select v-model:value="dynamicValidateForm.distingAcDc" :placeholder="$t('balanceLog.vGift.percentage')"
                      :dropdownStyle="{zIndex: 9999999950}">
                <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
              </Select>
            </FormItem>
          </Col>

          <template v-if="dynamicValidateForm.businessAllDay !== 1">
            <Col :span="22">
              <Card
                  v-for="(info, index) in dynamicValidateForm.infos"
                  :key="info.id"
                  size="small"
                  style="margin-bottom: 5px"
              >
                <Row>
                  <Row :gutter="[16,0]" wrap>
                    <Col :span="12">
                      <FormItem :name="['infos', index, 'timeSlot']" :label="$t('balanceLog.table.log_time')" :rules="{required: true, message: $t('balanceLog.table.log_time')}">
                        <TimeRangePicker :order="false" style="width: 100%" v-model:value="info.timeSlot" format="HH:mm" valueFormat="HH:mm" @change="changeTime" :zIndex="900"/>
                      </FormItem>
                    </Col>
                    <Col :span="6">
                      <FormItem label="-">
                        <Button type="primary" ghost @click="removeInfo(info)" style="width: 100%">
                          <MinusCircleOutlined/>
                        </Button>
                      </FormItem>
                    </Col>

                    <template v-if="dynamicValidateForm.distingAcDc === 1">
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byAcPower']" :label="$t('region.table.ac_price_per_electricity') + '(kwh)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byAcPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byAcTime']" :label="$t('region.table.ac_price_per_minute') + '(min)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byAcTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byDcPower']" :label="$t('region.table.dc_price_per_electricity') + '(kwh)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byDcPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byDcTime']" :label="$t('region.table.dc_price_per_minute') + '(min)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byDcTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                    </template>

                    <template v-else>
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byPower']" :label="$t('public.chargeType.1')" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem :name="['infos', index, 'byTime']" :label="$t('public.chargeType.0')" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="info.byTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                    </template>
                  </Row>
                </Row>
              </Card>
            </Col>
            <Col :span="22">
              <FormItem>
                <Button type="primary" ghost @click="addInfo" style="width: 100%;"><PlusOutlined/>{{ $t('balanceLog.addRule') }}</Button>
              </FormItem>
            </Col>
          </template>
          <template v-else>
            <Col :span="22">
              <Card size="small" style="margin-bottom: 5px">
                  <Row :gutter="[16,0]" wrap>
                    <template v-if="dynamicValidateForm.distingAcDc === 1">
                      <Col :span="12">
                        <FormItem name="byAcPower" :label="$t('region.table.ac_price_per_electricity') + '(kwh)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byAcPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem name="byAcTime" :label="$t('region.table.ac_price_per_minute') + '(min)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byAcTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem name="byDcPower" :label="$t('region.table.dc_price_per_electricity') + '(kwh)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byDcPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem name="byDcTime" :label="$t('region.table.dc_price_per_minute') + '(min)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byDcTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                    </template>
                    <template v-else>
                      <Col :span="12">
                        <FormItem name="byPower" :label="$t('public.chargeType.1') + '(kwh)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byPower" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                      <Col :span="12">
                        <FormItem name="byTime" :label="$t('public.chargeType.0') + '(min)'" :rules="{required: true, message: $t('balanceLog.vGift.give'),}">
                          <InputNumber v-model:value="dynamicValidateForm.byTime" :placeholder="$t('balanceLog.vGift.give')" style="width: 100%"/>
                        </FormItem>
                      </Col>
                    </template>
                  </Row>
              </Card>
            </Col>
          </template>
        </Row>

      </div>
    </Form>
  </Modal>
</template>
<script>
export default {
  name: "RechargePackageModal"
}
</script>
<script setup>
import {
  Modal,
  Form,
  FormItem,
  Space,
  InputNumber,
  Button,
  message,
  Select,
  SelectOption,
  Row,
  Col,
  TimeRangePicker,
  Card,
  Tooltip
} from "ant-design-vue";
import {MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons-vue";
import {reactive, ref} from "vue";
import {useI18n} from 'vue-i18n'
import {ConfigGetConfig, ConfigSetConfig} from "@/api/config";

const refForm = ref();

const i18n = useI18n()
let visible = ref(false)
let data = ref({})

const enable = ref(0);
const percentage = ref(0);
let correlationId = ref()
const cType = 1
let loading = ref(true)

const dynamicValidateForm = reactive({
  postpaid: undefined,
  directDebit: undefined,
  billingMethod: undefined,
  enableTimeoutFine: undefined,
  timeoutFine: undefined,
  timeoutCharging: undefined,
  byPowerUnit: undefined,
  byTimeUnit: undefined,
  maximumPower: undefined,
  maximumMinute: undefined,
  businessAllDay: undefined,
  distingAcDc: undefined,
  byAcPower: undefined,
  byAcTime: undefined,
  byDcPower: undefined,
  byDcTime: undefined,
  byPower: undefined,
  byTime: undefined,
  infos: [],
});
const removeInfo = item => {
  const index = dynamicValidateForm.infos.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.infos.splice(index, 1);
  }
};
const addInfo = () => {
  dynamicValidateForm.infos.push({
    id: Date.now(),
    byAcPower: undefined,
    byAcTime: undefined,
    byDcPower: undefined,
    byDcTime: undefined,
    byPower: undefined,
    byTime: undefined,
  });
};

async function showConfig(idReg) {
  correlationId.value = idReg
  dynamicValidateForm.infos.length = 0
  // 获取后台充值套餐信息
  const api = new ConfigGetConfig()
  api.get({correlationId: correlationId.value, type: cType}).then(response => {
    if (parseInt(response.data.code) === 0) {
      if (response.data.data) {
        let data = response.data.data

        dynamicValidateForm.postpaid =  data.postpaid === undefined ? 0 : data.postpaid
        dynamicValidateForm.directDebit =  data.directDebit
        dynamicValidateForm.billingMethod =  data.billingMethod === undefined ? 2 : data.billingMethod
        dynamicValidateForm.enableTimeoutFine =  data.enableTimeoutFine === undefined ? 0 : data.enableTimeoutFine
        dynamicValidateForm.timeoutFine =  data.timeoutFine
        dynamicValidateForm.timeoutCharging =  data.timeoutCharging
        dynamicValidateForm.byPowerUnit =  data.byPowerUnit === undefined ? 0 : data.byPowerUnit
        dynamicValidateForm.byTimeUnit =  data.byTimeUnit === undefined ? 0 : data.byTimeUnit
        dynamicValidateForm.maximumPower =  data.maximumPower === undefined ? 0 : data.maximumPower
        dynamicValidateForm.maximumMinute =  data.maximumMinute === undefined ? 0 : data.maximumMinute
        dynamicValidateForm.businessAllDay = data.businessAllDay === undefined ? 1 : data.businessAllDay
        dynamicValidateForm.distingAcDc = data.distingAcDc === undefined ? 0 : data.distingAcDc
        dynamicValidateForm.byAcPower = data.byAcPower
        dynamicValidateForm.byAcTime = data.byAcTime
        dynamicValidateForm.byDcPower = data.byDcPower
        dynamicValidateForm.byDcTime = data.byDcTime
        dynamicValidateForm.byPower = data.byPower
        dynamicValidateForm.byTime = data.byTime
        dynamicValidateForm.infos = Array.isArray(data.infos) ? data.infos : []
      }
      visible.value = true;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  })
}

function onOk() {
  refForm.value?.validate().then(() => {
    const api = new ConfigSetConfig()
    api.post({
      correlationId: correlationId.value,
      type: cType,
      args: JSON.stringify(dynamicValidateForm)
    }).then(response => {
      if (parseInt(response.data.code) === 0) {
        message.success(i18n.t("succeedMsg.0"));
        _resetData()
        visible.value = false
      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    })
  })
}

function changeTime(e) {}

function _resetData() {
  dynamicValidateForm.infos.length = 0
  dynamicValidateForm.name = undefined
  dynamicValidateForm.postpaid = undefined
  dynamicValidateForm.directDebit = undefined
  dynamicValidateForm.billingMethod = undefined
  dynamicValidateForm.enableTimeoutFine = undefined
  dynamicValidateForm.timeoutFine = undefined
  dynamicValidateForm.timeoutCharging = undefined
  dynamicValidateForm.byPowerUnit = undefined
  dynamicValidateForm.byTimeUnit = undefined
  dynamicValidateForm.maximumPower = undefined
  dynamicValidateForm.maximumMinute = undefined
  dynamicValidateForm.businessAllDay = undefined
  dynamicValidateForm.distingAcDc = undefined
  dynamicValidateForm.byAcPower = undefined
  dynamicValidateForm.byAcTime = undefined
  dynamicValidateForm.byDcPower = undefined
  dynamicValidateForm.byDcTime = undefined
  dynamicValidateForm.byPower = undefined
  dynamicValidateForm.byTime = undefined
}

defineExpose({
  showConfig,
});
</script>

<style lang="less">
</style>