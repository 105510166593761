<template>
  <Modal
      id="PersonalPassword"
      :open="visible"
      @cancel="visible = !visible"
      @ok="send"
      :title="$t('public.name.reset_password')"
      destroyOnClose
  >
    <Form ref="member_form" :model="data" layout="vertical" :rules="validateRules">
      <Row :gutter="[16,16]">
        <Col :span="24">
          <FormItem :label="$t('public.name.currentPassword')" name="currentPassword">
            <InputPassword :placeholder="$t('public.name.currentPassword')" v-model:value="data.currentPassword"></InputPassword>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="[16,16]">
        <Col :span="24">
          <FormItem :label="$t('public.name.newPassword')" name="password">
            <InputPassword :placeholder="$t('public.name.newPassword')" v-model:value="data.password"></InputPassword>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="[16,16]">
        <Col :span="24">
          <FormItem :label="$t('public.name.confirm_password')" name="confirmPassword">
            <InputPassword :placeholder="$t('public.name.confirm_password')"
                           v-model:value="data.confirmPassword"></InputPassword>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </Modal>
</template>

<script>
export default {
  name: "PersonalPassword",
}
</script>

<script setup>
import {Modal, Row, Col, Form, FormItem, Input, message, InputPassword} from "ant-design-vue";
import {ChangePassword, Logout} from "@/api/employee"
import {ref, reactive} from "vue"
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const i18n = useI18n()
const store = useStore()
const router = useRouter()

let visible = ref(false)
const member_form = ref()
const data = ref({
  currentPassword: "",
  password: "",
  confirmPassword: ""
})
const validateRules = reactive({
  currentPassword: [
    {
      required: true,
      message: i18n.t("public.validate.required.password"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("public.validate.required.password"),
      trigger: "blur",
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: i18n.t("public.validate.required.password"),
      trigger: "blur",
    },
  ],
})

const show = () => {
  _resetData()
  visible.value = true
}

const send = () => {
  let api = new ChangePassword();
  member_form.value.validate().then(() => {
    api.post(data.value).then((response) => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        visible.value = false;

        const logoutApi = new Logout();
        logoutApi.get().then((response) => {
          if (0 === parseInt(response.data.code)) {
            store.dispatch("logout");
            router.push({name: "Login"});
            location.reload();
          } else {
            if (response.data.code !== undefined) {
              message.error(i18n.t("errorMsg." + response.data.code));
            }
            // this.getCaptcha();
          }
        });

      } else {
        if (response.data.code !== undefined) {
          message.error(i18n.t("errorMsg." + response.data.code));
        }
      }
    });
  }).catch();
}

const _resetData = () => {
  data.value = {
    currentPassword: "",
    password: "",
    confirmPassword: ""
  };
}

// 暴露方法给父组件
defineExpose({
  show,
});
</script>

<style lang="less">
</style>
