<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="onSubmit"
      :confirmLoading="confirmLoading"
      :title="title"
  >
    <Row align="middle" justify="center" class="modal">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="formState"
                layout="vertical"
                :rules="validateRules"
                @finish="()=>onSubmit(values)"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('superTenantManage.field.id')" name="idRole">
                    <Input
                        :disabled="true"
                        :placeholder="$t('superTenantManage.validate.required.id')"
                        v-model:value="formState.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('superTenantManage.field.datasourceKey')" name="dataSourceKey">
                    <Input :placeholder="$t('superTenantManage.validate.required.dataSourceKey')"
                           v-model:value="formState.dataSourceKey" :disabled="formState.isCreate === '1'">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <Row>
                    <Col span="15">
                      <FormItem :label="$t('superTenantManage.field.host')" name="host">
                        <Input :placeholder="$t('superTenantManage.validate.required.host')"
                               v-model:value="formState.host" :disabled="formState.isCreate === '1'">
                        </Input>
                      </FormItem>
                    </Col>
                    <Col span="1"/>
                    <Col span="8">
                      <FormItem :label="$t('superTenantManage.field.port')" name="port" >
                        <Input :placeholder="$t('superTenantManage.validate.required.port')"
                               v-model:value="formState.port" :disabled="formState.isCreate === '1'">
                        </Input>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('superTenantManage.field.dbName')" name="dbName">
                    <Input :placeholder="$t('superTenantManage.validate.required.dbName')"
                           v-model:value="formState.dbName" :disabled="formState.isCreate === '1'">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <Row>
                    <Col span="10">
                      <FormItem :label="$t('superTenantManage.field.username')" name="username">
                        <Input :placeholder="$t('superTenantManage.validate.required.username')"
                               v-model:value="formState.username" :disabled="formState.isCreate === '1'" >
                        </Input>
                      </FormItem>
                    </Col>
                    <Col span="1"/>
                    <Col span="13">
                      <FormItem :label="$t('superTenantManage.field.password')" name="password">
                        <InputPassword :placeholder="$t('superTenantManage.validate.required.password')"
                               v-model:value="formState.password" :disabled="formState.isCreate === '1'" >
                        </InputPassword>
                      </FormItem>
                    </Col>
                  </Row>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('superTenantManage.field.tenant')" name="tenant">
                    <Input :placeholder="$t('superTenantManage.validate.required.tenant')"
                           v-model:value="formState.tenant" :disabled="formState.isCreate === '1'" >
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('superTenantManage.field.seckey')" name="seckey">
                    <InputPassword :placeholder="$t('superTenantManage.validate.required.seckey')"
                           v-model:value="formState.seckey">
                    </InputPassword>
                  </FormItem>
                </Col>

                <Col span="10">

                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="22">
                  <FormItem :label="$t('superTenantManage.field.domain')" name="domain">
                    <Textarea :placeholder="$t('superTenantManage.validate.required.domain')"
                              v-model:value="formState.domain">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="22">
                  <FormItem :label="$t('superTenantManage.field.onesignalKey')" name="onesignalKey">
                    <Textarea :placeholder="$t('superTenantManage.validate.required.onesignalKey')"
                              v-model:value="formState.onesignalKey">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="22">
                  <FormItem :label="$t('superTenantManage.field.remark')" name="remarkRole">
                    <Textarea :placeholder="$t('superTenantManage.validate.required.remark')"
                              v-model:value="formState.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "FormModel",
};
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputPassword,
  Textarea,
  message
} from "ant-design-vue"

import {superTenantAddInfo, superTenantEditInfo} from "@/api/superTenant";
import { ref, reactive, getCurrentInstance } from 'vue'
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

let confirmLoading = ref(false)
let visible = ref(false)
let actionType = ref("")
let title = ref("")
const refForm = ref();
let formState = ref({})
const validateRules = {
  host: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.host"),
      trigger: "change",
    },
  ],
  port: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.port"),
      trigger: "change",
    },
  ],
  dbName: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.dbName"),
      trigger: "change",
    },
  ],
  dataSourceKey: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.dataSourceKey"),
      trigger: "change",
    },
  ],
  seckey: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.seckey"),
      trigger: "change",
    },
  ],
  username: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.username"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.password"),
      trigger: "change",
    },
  ],
  tenant: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.tenant"),
      trigger: "change",
    },
  ],
  remarkRole: [
    {
      required: false,
      message: i18n.t("superTenantManage.validate.required.remark"),
      trigger: "change",
    },
  ],
  domain: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.domain"),
      trigger: "change",
    },
  ],
  onesignalKey: [
    {
      required: true,
      message: i18n.t("superTenantManage.validate.required.onesignalKey"),
      trigger: "change",
    },
  ],
}

const addShow = () => {
  _resetData();
  actionType.value = "add";
  title.value = i18n.t("public.name.add")
  visible.value = true;
}
const editShow = (obj) => {
  _resetData();
  actionType.value = "edit";
  title.value = i18n.t("public.name.edit")
  formState.value = obj;
  visible.value = true;
}
const _resetData = () => {
  formState.value = {};
}
const onSubmit = () => {
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new superTenantAddInfo();
        break;
      case "edit":
        api = new superTenantEditInfo();
        break;
      default:
        return false;
    }
    // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
    delete formState.value.createDbTime
    delete formState.value.createTime
    delete formState.value.updateTime
    // 构建参数
    const param = {
      ...formState.value,
      driverClassName: 'com.mysql.cj.jdbc.Driver',
      dataSourceType: 'com.zaxxer.hikari.HikariDataSource',
      url: 'jdbc:mysql://' + formState.value.host +':' + formState.value.port + '/' +formState.value.dbName + '?useUnicode=true&characterEncoding=utf-8&useSSL=false&serverTimezone=Asia/Shanghai',
    }
    confirmLoading.value = true
    api.post(param).then((response) => {
      confirmLoading.value = false
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        // 触发主页面组件定义事件
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    });
  }).catch(() => {});
}
// 暴露方法给父组件
defineExpose({
  addShow,
  editShow
});
</script>
