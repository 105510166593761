<template>
  <Modal id="AuthorityDetail" width="50%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('authority.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('authority.field.id_aut')">{{data.idAut}}</DescriptionsItem>
      <DescriptionsItem :label="$t('authority.field.a_id_aut')">{{data.authorityName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('authority.field.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('authority.field.resource_name')">{{data.resourceName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('authority.field.type')">{{selectList.typeList.find(item=>item.value===data.type).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('authority.field.sort')">{{data.sort}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
export default {
  name: "AuthorityDetail"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
import {ref} from "vue";

let visible = ref(false)
let data = ref()

const selectList = {
  typeList: [{value: 1,label: '菜單'},{value: 2,label: '按鈕'}],
}

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}

defineExpose({
  detailShow
})

</script>