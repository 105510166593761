import {defineComponent, ref} from "vue"
import {useStore} from "vuex";
import {GlobalOutlined} from "@ant-design/icons-vue"
import {Dropdown, Menu, Button} from "ant-design-vue"

import i18n from "@/i18n"

export default defineComponent({
    name: "LanguageButton",
    setup() {
        const store = useStore();
        const locale = ref(store.getters.language)
        const change_language = (...args) => {
            switch (args[0].key) {
                case "zhTW":
                    locale.value = "zhTW"
                    break;
                case "enUS":
                    locale.value = "enUS"
                    break;
                case "zhCN":
                    locale.value = "zhCN"
                    break;
                case "daDK":
                    locale.value = "daDK"
                    break;
                case "idID":
                    locale.value = "idID"
                    break;
                case "msMY":
                    locale.value = "msMY"
                    break;
                case "thTH":
                    locale.value = "thTH"
                    break;
                case "viVN":
                    locale.value = "viVN"
                    break;
                case "frFR":
                    locale.value = "frFR"
                    break;
                case "plPL":
                    locale.value = "plPL"
                    break;
                case "it":
                    locale.value = "it"
                    break;
            }
            store.commit("setLanguage", locale.value)
            i18n.global.locale = locale.value
            location.reload();
        }
        const menu = (
            <Menu onClick={change_language}>
                <Menu.Item key={"zhTW"}>{i18n.global.t("language.zhTW")}</Menu.Item>
                <Menu.Item key={"enUS"}>{i18n.global.t("language.enUS")}</Menu.Item>
                <Menu.Item key={"zhCN"}>{i18n.global.t("language.zhCN")}</Menu.Item>
                <Menu.Item key={"daDK"}>{i18n.global.t("language.daDK")}</Menu.Item>
                <Menu.Item key={"idID"}>{i18n.global.t("language.idID")}</Menu.Item>
                <Menu.Item key={"msMY"}>{i18n.global.t("language.msMY")}</Menu.Item>
                <Menu.Item key={"thTH"}>{i18n.global.t("language.thTH")}</Menu.Item>
                <Menu.Item key={"viVN"}>{i18n.global.t("language.viVN")}</Menu.Item>
                <Menu.Item key={"frFR"}>{i18n.global.t("language.frFR")}</Menu.Item>
                <Menu.Item key={"plPL"}>{i18n.global.t("language.plPL")}</Menu.Item>
                <Menu.Item key={"it"}>{i18n.global.t("language.it")}</Menu.Item>
            </Menu>
        )
        return () => (
            <Dropdown trigger={['click']} overlay={menu} placement={"bottom"}>
                <Button shape="circle" icon={<GlobalOutlined style={{fontSize: '22px'}} />} style={{border: "0px"}}/>
            </Dropdown>
        )
    }
})