<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="cancel"
      @ok="submit"
      :title="$t('public.name.setting')"
      :footer="null"
      :destroyOnClose="true"
  >
    <div v-if="isShowList">
      <Select
          v-model:value="searchValue"
          show-search
          :placeholder="$t('public.pleaseSelect')"
          style="width: 300px; margin-bottom: 10px"
          :options="options"
          :filter-option="filterOption"
          @change="handleChange"
          allowClear
      ></Select>
      <Table
          :columns="columns"
          :dataSource="listView"
          :scroll="{ x: 800, y: 500 }"
          rowKey="id"
          :loading="loading"
          :pagination=false
      >

        <!-- 插槽 -->
        <template #status="{text}">
          <div>
            <!-- 0:未注册；1:正常；2:异常 -->
            <Tag v-if="text === true" color="#747d8c">&nbsp;</Tag>
            <Tag v-else-if="text === false" color="#2ed573">&nbsp;</Tag>
            <Tag v-else color="#ff4757">&nbsp;</Tag>
          </div>
        </template>

        <!-- 插槽 -->
        <template #settingValue="{text}">
          <div>
            <!-- 0:未注册；1:正常；2:异常 -->
            <Tag v-if="text === true" color="#747d8c">{{ $t("public.isTrue.1") }}</Tag>
            <Tag v-else-if="text === false" color="#2ed573">{{ $t("public.isTrue.0") }}</Tag>
            <Tag v-else color="#ff4757">{{ $t("public.name.unknown") }}</Tag>
          </div>
        </template>

        <!-- 插槽 -->
        <template #actionTitle>
        <span>
          {{ $t("public.table.action") }}
        </span>
        </template>
        <template #action="{text}">
          <div>
            <div class="pc-display">
              <Button type="primary" size="small" @click="isEdit(text)">{{ $t("public.name.edit") }}</Button>
            </div>
          </div>
        </template>
      </Table>
    </div>

    <div v-if="!isShowList">
      <Row>
        <Col span="24">
          <Form
              ref="refForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
          >
            <Row justify="space-around">
              <Col span="10">
                <FormItem :label="$t('chargingStation.settingField.key')" name="key">
                  <Input :placeholder="$t('chargingStation.settingField.key')"
                         v-model:value="form.key" disabled>
                  </Input>
                </FormItem>
              </Col>
              <Col span="10">
                <FormItem :label="$t('chargingStation.settingField.readonly')" name="readonly">
                  <Input :placeholder="$t('chargingStation.settingField.readonly')"
                         v-model:value="form.readonly" disabled>
                  </Input>
                </FormItem>
              </Col>
            </Row>

            <Row justify="space-around">
              <Col span="22">
                <FormItem :label="$t('chargingStation.settingField.value')" name="value">
            <Textarea :placeholder="$t('chargingStation.settingField.value')"
                      v-model:value="form.value" :disabled="form.readonly">
            </Textarea>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span="24" style="text-align: center">
          <Button v-if="!form.readonly" @click="submit">{{$t('public.name.submit')}}</Button>
          <span v-if="!form.readonly">&nbsp;</span>
          <Button @click="isShowList = true">{{$t('public.payStatus.4')}}</Button>
        </Col>
      </Row>
    </div>
  </Modal>
</template>
<script>
export default {
  name: "SettingList",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  Button,
  message,
  Select,
  Table,
  Tag,
} from "ant-design-vue";
import {ChargingStationGetConfig, ChargingStationSetConfig} from "@/api/chargingStation";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

let visible = ref(false)
let loading = ref(false)
let isShowList = ref(true)
let idChaPoi = ref()
let searchValue = ref()

const columns = [
  {
    title: i18n.t("chargingStation.setting.index"),
    align: "center",
    width: 60,
    ellipsis: true,
    customRender: ({text, record, index, column}) => `${index + 1}`
  },
  {title: i18n.t("chargingStation.setting.key"), align: "center", width: 250, dataIndex: "key", key: "key"},
  {
    title: i18n.t("chargingStation.setting.readonly"),
    align: "center",
    width: 150,
    dataIndex: "readonly",
    key: "readonly",
    slots: {title: "statusTitle", customRender: "status"}
  },
  {
    title: i18n.t("chargingStation.setting.value"),
    align: "center",
    width: 100,
    dataIndex: "value",
    key: "value",
    // slots: {customRender: "settingValue"}
  },
  {key: "action", align: "center", width: 150, slots: {title: "actionTitle", customRender: "action"}},
]
const validateRules = {
  key: [
    {
      required: false,
      message: i18n.t("chargingStation.settingField.key"),
      trigger: "change",
    },
  ],
  readonly: [
    {
      required: false,
      message: i18n.t("chargingStation.settingField.readonly"),
      trigger: "change",
    },
  ],
  value: [
    {
      required: true,
      message: i18n.t("chargingStation.settingField.value"),
      trigger: "change",
    },
  ]
}

const data = reactive({
  list: [],
  form: {
    idChaPoi: undefined,
    key: undefined,
    readonly: undefined,
    value: undefined,
  },
  listView: [],
  options: []
})
const {list, form, listView, options} = toRefs(data)

function submit() {
  const api = new ChargingStationSetConfig();
  form.value.idChaPoi = idChaPoi.value
  api.post(form.value).then((res) => {
    if (0 === parseInt(res.data.code)) {
      message.success(res.data.msg)
      get_list()
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  })
}

function cancel() {
  isShowList.value = true
  visible.value = false;
}

function showList(cId) {
  visible.value = true
  idChaPoi.value = cId
  get_list()
}

function get_list() {
  loading.value = true
  list.value = []
  listView.value = []
  options.value = []

  const api = new ChargingStationGetConfig();
  api.get({idChaPoi: idChaPoi.value}).then(res => {
    loading.value = false
    isShowList.value = true
    searchValue.value = undefined

    if (0 === parseInt(res.data.code)) {
      list.value = res.data.data;
      listView.value = res.data.data;
      let l = []
      list.value.forEach(item => {
        l.push({
          value: item.key,
          label: item.key,
        })
      })
      options.value = l
    } else {
      message.error(i18n.t("errorMsg." + res.data.code));
    }
  });
}

function isEdit(obj) {
  isShowList.value = false
  form.value = obj
}

function handleChange(value) {
  if (value === undefined) {
    listView.value = list.value
  } else {
    let l = []
    list.value.forEach(item => {
      if (item.key === value) {
        l.push(item)
      }
    })
    listView.value = l
  }
}

function filterOption(input, option) {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

defineExpose({
  showList
})
</script>

