<template>
  <Select
      v-model:value="selectValue"
      show-search
      :placeholder="$t('public.pleaseSelect')"
      style="width: 300px;margin-bottom: 20px"
      :options="options"
      :filter-option="filterOption"
      @change="handleChange"
  ></Select>
</template>
<script>
export default {
  name: "SelectDsKey"
}
</script>
<script setup>
import {
  message,
  Select,
} from "ant-design-vue";

import {useStore} from "vuex";
import {superTenantSelectList} from "@/api/superTenant";
import {ref} from "vue";

const store = useStore()
const emits = defineEmits(['onClick'])

let selectValue = ref(undefined)
let options = ref([])
let dbKey = ref(undefined)

// 搜索框选择事件
function handleChange(v) {
  dbKey.value = v
  // 缓存所选的数据源标识
  store.commit("setDsKey", v);
  // 完成通知
  emits("onClick", v);
}
// 搜索框过滤筛选
function filterOption(input, option) {
  return option.label.indexOf(input) >= 0;
}
// 获取下拉框选择内容
function initSelect() {
  // 获取用户信息
  const userInfo = store.getters.getUserMsg
  const dsKey = store.getters.getDsKey
  // 判断是否是AdminPlus用户
  if (userInfo.userType === '1' && userInfo.idRol === 1) {
    const api = new superTenantSelectList();
    api.get({dbKey: 'default'}).then((res) => {
      if (dsKey !== undefined && dsKey !== null && dsKey !== '') {
        selectValue.value = dsKey
      } else {
        selectValue.value = 'default'
      }
      options.value = res.data.data
      emits("onClick", selectValue.value);
    });
  }
}
// 去除list中的某个对象
function delListByKey(li, delKey) {
  let index;
  for(let i=0;i<li.length;i++){
    if((li[i].value).indexOf(delKey)>-1){
      index = i;
      li.splice(index,1);
    }
  }
  return li
}

initSelect()
</script>

<style lang="less">
@import url("style.less");
</style>
