import {Base} from "@/api/Base"

const api = "connectorLog"

export class ConnectorLogFindByPage extends Base {
path = api + "/findByPage"
}

export class  ConnectorLogGetInfo extends Base {
path = api + "/getInfo"
}

// 导出Excel
export class ConnectorLogExportToExcel extends Base {
    path = api + "/export"
}
