const viVN = {
    'succeedMsg': {
        '0': 'Mục đích',
    },
    'errorMsg': {
        '500100': 'Ngoại lệ phía máy chủ',
        '500101': 'Parameter error',
        '500102': 'Ngoại lệ xác thực tham số',
        '500103': 'Yêu cầu là bất hợp pháp',
        '500104': 'Các chuyến thăm quá thường xuyên',
        '500105': 'Đối tượng đã tồn tại',
        '500106': 'Hoạt động không thành công, Vui lòng thử lại',
        '500107': 'Có một hiệp hội, Xóa bị cấm',
        '500108': 'Ngoại lệ xuất khẩu',
        '500200': 'Login expired, please log in again',
        '500201': 'Số tài khoản hoặc mật khẩu không thể trống',
        '500202': 'Số điện thoại không thể trống',
        '500203': 'Số điện thoại ở định dạng sai',
        '500204': 'Số điện thoại không tồn tại',
        '500205': 'Mật khẩu không đúng',
        '500206': 'Tài khoản không tồn tại',
        '500207': 'Số tài khoản chỉ có thể chứa số, tiếng Anh và nhấn mạnh',
        '500208': 'Quá trình xác thực đã thất bại',
        '500209': 'Hai mật khẩu không giống nhau',
        '500210': 'Tài khoản trùng lặp',
        '500211': 'Tài khoản đã bị khóa',
        '500212': 'CAPTCHA thất bại',
        '500213': 'Số điện thoại sai',
        '500214': 'Không có người thuê tương ứng với tên miền, Liên hệ với quản trị viên của bạn',
        '500215': 'tồn tại cùng một tên vai trò',
        '500300': 'Nếu dịch vụ OCCP là bất thường , liên hệ với quản trị viên',
        '500301': 'Cọc điện không có sẵn',
        '500302': 'Cấu hình cọc trống',
        '500303': 'Pin nằm ngoại tuyến, Vui lòng kiểm tra xem nó có khả dụng không',
        '500304': 'Cổng cọc không có sẵn',
        '500305': 'Súng không được chèn hoặc súng hiện tại chưa sẵn sàng',
        '500306': 'Start charging failure',
        '500307': 'Bundled charging failure',
        '500401': 'Thiếu cân bằng',
        '500402': 'Thứ tự không tồn tại',
        '500403': 'Đơn đặt hàng không được thanh toán',
        '500404': 'The order has been paid',
        '500405': 'Order status is not [Paid Unused]',
        '500406': 'Lỗi tạo lệnh',
        '500407': 'Có lệnh chưa thanh toán',
        '500408': 'Lệnh đã hủy',
        '500501': 'Mặt hàng đã được bán trong vài giây',
        '500502': 'Không thể lặp lại giao dịch sét',
        '500503': 'Thất bại thứ hai',
        '500601': 'The file cannot be empty',
        '500602': 'Lỗi định dạng tệp',
        '500603': 'Sổ làm việc Excel trống',
        '500604': 'Không đọc được tệp'
    },
    'public': {
        'name': {
            'nextStep': 'Tiếp theo',
            'status': 'Tiểu bang',
            'hello': 'Xin chào',
            'login': 'Đăng nhập',
            'logout': 'Đăng xuất',
            'username': 'tên tài khoản',
            'email': 'E-mail',
            'password': 'Mật khẩu',
            'remember': 'Ghi nhớ tôi',
            'currentPassword': 'Mật khẩu hiện tại',
            'newPassword': 'Mật khẩu mới',
            'confirm_password': 'Xác nhận mật khẩu',
            'reset_password': 'Đặt lại mật khẩu',
            'captcha': 'mã ngẫu nhiên',
            'getCaptcha': 'Nhận Captcha',
            'more': 'Hơn',
            'add': 'Thêm vào',
            'batchDel': 'Xóa hàng loạt',
            'Export': 'Xuất khẩu',
            'Import': 'Nhập khẩu',
            'DownloadTemp': 'Tải xuống mẫu',
            'ImportTips': 'Nhấp hoặc kéo tệp vào khu vực này để tải lên',
            'edit': 'Chỉnh sửa',
            'del': 'Xóa bỏ',
            'detail': 'Chi tiết',
            'yes': 'Đúng',
            'no': 'Không',
            'empty': 'Trống rỗng',
            'are_you_sure_delete': 'Bạn có chắc chắn muốn xóa mục này không',
            'action_cannot_resumed': 'Hành động này không thể được nối lại',
            'are_you_sure_operate': 'Xác định hành động hiện tại',
            'operate_remind': 'Hoạt động hiện tại nhạy cảm, xin vui lòng thận trọng',
            'search': 'Tìm kiếm',
            'reset': 'Cài lại',
            'spread': 'Lây lan',
            'retract': 'Rút lại',
            'show': 'Trình diễn',
            'no_page': 'Không có trang',
            'service': 'Dịch vụ',
            'phone_country_code': 'Mã quốc gia điện thoại',
            'name': 'Tên',
            'phone': 'Điện thoại',
            'sex': 'Tình dục',
            'country': 'Quốc gia',
            'address': 'Địa chỉ nhà',
            'birthday': 'Sinh nhật',
            'remark': 'Nhận xét',
            'personal': 'Cá nhân',
            'enclosure': 'Bao vây',
            'logo': 'Logo',
            'submit': 'Gửi đi',
            'unknown': 'không xác định',
            'setting': 'Cài đặt',
            'index': 'Số sê-ri',
            'createBy': 'Tạo bởi',
            'createTime': 'Tạo thời gian',
            'updateBy': 'cập nhật bởi',
            'updateTime': 'Cập nhật thời gian',
            'type': 'Loại',
            'args': 'Tham số',
            'qrcode': 'Mã QR',
            'query': 'Yêu cầu',
            'refund': 'Hoàn tiền',
            'cancel': 'Hủy bỏ',
            'revoke': 'Hủy bỏ'
        },
        'msg': {
            'unRead': 'Hủy đọc',
            'read': 'Đọc tiếp',
            'alarm': 'Sợ hãi',
            'reminder': 'Nhắc nhở',
            'advertise': 'Quảng cáo'
        },
        'language': {
            'zhTW': 'Truyền thống (Trung Hoa)',
            'enUS': 'Tiếng Anh',
            'zhCN': 'Trung Quốc (đơn giản hóa)',
            'daDK': 'Tiếng Đan Mạch',
            'idID': 'Tiếng Indonesia',
            'msMY': 'Tiếng Mã Lai',
            'thTH': 'Tiếng Thái',
            'viVN': 'Tiếng Thái',
            'frFR': 'Tiếng Pháp',
            'plPL': 'Tiếng Ba Lan',
            'it': 'Tiếng Ý',
        },
        'timeType': {
            0: 'Năm',
            1: 'Tháng',
            2: 'Tuần',
            3: 'Ngày',
        },
        'promptsToSelectTime': 'Vui lòng chọn thời gian',
        'pleaseSelect': 'Vui lòng chọn',
        'pileStatus': {
            0: 'Có sẵn',
            1: 'Sạc',
            2: 'Không khả dụng',
        },
        'isTrue': {'0': 'Không', '1': 'Đúng'},
        'chargerType': {'0': 'AC', '1': 'DC'},
        'registerType': {'0': 'E-mail', '1': 'Điện thoại'},
        'doingStatus':{
            0: 'Đang chờ xử lý',
            1: 'Đang xử lý',
            2: 'Đã hoàn thành',
            3: 'Hủy bỏ',
            4: 'Hết hạn',
            5: 'Chấm dứt',
        },
        'consumeType': {
            0: "Sạc",
            1: "Sạc tiêu thụ",
            2: "Phí hủy bỏ",
            3: "Cân bằng",
            4: 'Kế hoạch mua hàng',
            5: 'Hủy bỏ kế hoạch',
            6: 'Cập nhật kế hoạch',
            7: 'Hoàn tiền',
            8: 'Giấy phạt',
            9: 'Hủy bỏ tiền phạt',
            10: 'Rút tiền',
            11: 'Quà tặng đầy đủ',
            12: 'Hủy bỏ',
        },
        'payStatus': {'0': 'Chưa thanh toán', '1': 'Trả', '2': 'Tính phí', '3': 'Hoàn thành', '4': 'hủy'},
        'chargeType': {'0': 'Theo thời gian', '1': 'Theo đơn đặt hàng', '2': 'Theo kế hoạch người dùng'},
        'status': {'0': 'không xác định', '1': 'Bình thường', '2': 'Vô hiệu hóa', '3': 'Xóa bỏ'},
        'sex': {'1': 'Người đàn ông', '2': 'Người đàn bà', '3': 'không xác định'},
        'placeholder': {'please_select': 'Xin hãy lựa chọn'},
        'table': {
            'name': 'Tên',
            'status': 'Tiểu bang',
            'action': 'Hoạt động',
            'content': 'Nội dung',
            'created_at': 'Tạo thời gian',
            'image': 'Hình ảnh',
            'email': 'E-mail',
            'order_id': 'Gọi món',
            'sex': 'Tình dục',
            'last_ip': 'IP cuối cùng',
            'phone_country_code': 'Vui lòng chọn mã quốc gia điện thoại',
            'phone': 'Điện thoại'
        },
        'field': {
            'name': 'Tên',
            'username': 'tên tài khoản',
            'content': 'Nội dung',
            'phone_country_code': 'Mã quốc gia điện thoại',
            'phone': 'Điện thoại',
            'email': 'E-mail',
            'order_id': 'Gọi món',
            'portrait': 'Chân dung',
            'status': 'Tiểu bang',
            'image': 'Hình ảnh',
            'sex': 'Tình dục'
        },
        'validate': {
            'required': {
                'id': 'Thiếu id',
                'username': 'Vui lòng nhập tên người dùng',
                'password': 'Vui lòng nhập mật khẩu',
                'repassword': 'Vui lòng lặp lại mật khẩu',
                'name': 'Vui lòng nhập tên',
                'phone': 'Vui lòng nhập điện thoại',
                'email': 'Vui lòng nhập e-mail',
                'portrait': 'Vui lòng tải lên chân dung'
            },
            'tips': {
                'inconsistent_password': 'Mật khẩu không nhất quán',
                'length_should': 'Độ dài phải là {min} đến {max}'
            }
        }
    },
    'language': {
        'zhTW': 'Truyền thống Trung Hoa)',
        'enUS': 'Tiếng Anh',
        'zhCN': 'Trung Quốc (đơn giản hóa)',
        'daDK': 'Tiếng Đan Mạch',
        'idID': 'Tiếng Indonesia',
        'msMY': 'Tiếng Mã Lai',
        'thTH': 'Tiếng Thái',
        'viVN': 'Tiếng Thái',
        'frFR': 'Tiếng Pháp',
        'plPL': 'Tiếng Ba Lan',
        'it': 'Tiếng Ý',
    },
    'nav': {
        'name': {
            'Dashboard': 'bảng điều khiển',
            'RegionDashboard': 'Tổng quan về cọc',
            'BigScreen': 'Màn hình lớn',
            'AuthorityManage': 'Quản lý thẩm quyền',

            'BalanceLogManage': 'Quản lý nhật ký cân bằng',
            'CarManage': 'Quản lý xe hơi',
            'CarImagesManage': 'Hình ảnh xe hơi',
            'ChargeStationImagesManage': 'Điểm phí hình ảnh',
            'ChargingStationManage': 'Đoạn tính phí',
            'ConnectorManage': 'Trình quản lý đầu nối',
            'EmployeeManage': 'Nhà điều hành quản lý',
            'ConnectorLogManage': 'Nhật ký kết nối',
            'EventLogManage': 'Quản lý nhật ký sự kiện',
            'MeterManage': 'Quản lý đồng hồ',
            'OrderManage': 'Quản lý đơn hàng',
            'PlanManage': 'Quản lý kế hoạch',
            'RegionManage': 'Quản lý khu vực',
            'RegionImagesManage': 'Quản lý hình ảnh khu vực',
            'RepairLogManage': 'Sửa chữa Quản lý nhật ký',
            'RoleManage': 'Quản lý vai trò',
            'TransactionLogManage': 'Quản lý nhật ký giao dịch',
            'UserManage': 'Quản lý người dùng',
            'UserPlanManage': 'Người dùng PlanManage',
            'GetConfiguration': 'Nhận cấu hình',
            'DataTransfer': 'Truyền dữ liệu',

            'CardManage': 'Quản lý số thẻ',

            'NoticeManage': 'Quản lý thông báo',
            'PublicityManage': 'Quản lý thông báo',
            'ChargerSetting': 'Cài đặt bộ sạc',
            'StatisticsManage': 'Thống kê',
            'AppManagement': 'Quản lý ứng dụng',
            'LogManagement': 'Quản lý nhật ký',
            'AppVersionManage': 'Quản lý phiên bản ứng dụng',
            'WallerManage': 'Hạn mức tài khoản',
            'iPadManagement': 'Quản lý bằng',
            'ServiceManage': 'Quản lý dịch vụ',
            'iPadManage': 'Quản lý bằng',

            'TenantManagement': 'Quản lý hệ thống',
            'SuperTenantManage': 'Siêu thuê nhà',
            'TenantManage': 'Người thuê quản lý',
            'TenantInfo': 'Người thuê quản lý',
            'TenantPackage': 'Gói thuê nhà',
        },
        'table': {'order_id': 'ID đặt hàng', 'url': 'URL'},
        'field': {
            'pid': 'PID',
            'order_id': 'ID đặt hàng',
            'name': 'Tên',
            'url': 'URL',
            'status': 'Tiểu bang',
            'icon': 'Biểu tượng'
        }
    },
    'login': {
        'validate': {
            'required': {
                'username': 'Vui lòng nhập tên người dùng',
                'email': 'Vui lòng nhập email',
                'password': 'Vui lòng nhập mật khẩu',
                'captcha': 'Vui lòng nhập cảnh Captcha'
            }
        }
    },
    'authority': {
        'name': {
            'title': 'Chính quyền',
            'detailTitle': 'Chi tiết thẩm quyền',
            'editTitle': 'Chỉnh sửa thẩm quyền'
        },
        'table': {
            'id_aut': 'TÔI',
            'a_id_aut': 'Cơ quan phụ huynh',
            'name': 'Chính quyền',
            'resource_name': 'Tên tài nguyên',
            'type': 'Loại',
            'sort': 'Loại'
        },
        'field': {
            'id_aut': 'TÔI',
            'a_id_aut': 'Cơ quan phụ huynh',
            'name': 'Chính quyền',
            'resource_name': 'Tên tài nguyên',
            'type': 'Loại',
            'sort': 'Loại'
        },
        'validate': {
            'required': {
                'id_aut': 'Id cần thiết',
                'a_id_aut': 'Cơ quan phụ huynh bắt buộc',
                'name': 'Cần có thẩm quyền',
                'resource_name': 'Tên tài nguyên cần thiết',
                'type': 'Loại cần thiết',
                'sort': 'Sắp xếp bắt buộc'
            }
        }
    },
    'balanceLog': {
        'rechargePackage': 'Gói nạp tiền',
        'addRule': 'Thêm quy tắc',
        'gift': {
            'enable': 'Mở hay không',
            'percentage': 'Theo phần trăm',
            'star': 'Giá trị bắt đầu',
            'end': 'Giá trị kết thúc',
            'give': 'Hạn mức',
        },
        'vGift': {
            'enable': 'Vui lòng chọn có bật hay không',
            'percentage': 'Vui lòng chọn theo phần trăm',
            'star': 'Vui lòng nhập giá trị bắt đầu',
            'end': 'Vui lòng nhập giá trị kết thúc',
            'give': 'Vui lòng nhập hạn mức',
        },
        'name': {
            'title': 'Nhật ký cân bằng',
            'detailTitle': 'Cân bằng nhật ký chi tiết',
            'editTitle': 'Chỉnh sửa nhật ký số dư',
            'ticket': 'Giấy phạt',
            'cancelFines': 'Hủy bỏ tiền phạt'
        },
        'table': {
            'id_bal_log': 'TÔI',
            'id_use': 'Người dùng',
            'type': 'Loại',
            'amount': 'Số lượng',
            'log_time': 'Thời gian đăng nhập',
            'tenant_key': 'Chìa khóa thuê',
            'status': 'Tình trạng',
            'payment_method': 'Phương thức thanh toán'
        },
        'field': {
            'id_bal_log': 'TÔI',
            'id_use': 'Người dùng',
            'type': 'Loại',
            'amount': 'Số lượng',
            'log_time': 'Thời gian đăng nhập',
            'tenant_key': 'Chìa khóa thuê',
            'status': 'Tình trạng',
            'payment_method': 'Phương thức thanh toán'
        },
        'validate': {
            'required': {
                'id_bal_log': 'Id cần thiết',
                'id_use': 'Người dùng yêu cầu',
                'type': 'Loại cần thiết',
                'amount': 'Số tiền cần thiết',
                'log_time': 'Thời gian đăng nhập cần thiết',
                'tenant_key': 'Khóa thuê cần thiết',
                'status': 'Tình trạng cần thiết',
                'payment_method': 'Phương thức thanh toán cần thiết'
            }
        }
    },
    'car': {
        'name': {'title': 'Xe ô tô', 'detailTitle': 'Chi tiết xe hơi', 'editTitle': 'Chỉnh sửa xe'},
        'table': {
            'id_car': 'TÔI',
            'id_use': 'Người dùng',
            'model': 'Mô hình',
            'car_license': 'Giấy phép',
            'max_years_old': 'Năm tối đa',
            'manufacture_year': 'Sản xuất năm',
            'maximum_power': 'Công suất tối đa',
            'is_second_hand': 'Là đồ cũ',
            'vehicle_inspection_date': 'Ngày kiểm tra xe',
            'insurance_expiry_date': 'Ngày hết hạn bảo hiểm',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_car': 'TÔI',
            'id_use': 'Người dùng',
            'model': 'Mô hình',
            'car_license': 'Giấy phép',
            'max_years_old': 'Năm tối đa',
            'manufacture_year': 'Sản xuất năm',
            'maximum_power': 'Công suất tối đa',
            'is_second_hand': 'Là đồ cũ',
            'vehicle_inspection_date': 'Ngày kiểm tra xe',
            'insurance_expiry_date': 'Ngày hết hạn bảo hiểm',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_car': 'Id cần thiết',
                'id_use': 'Người dùng yêu cầu',
                'model': 'Mô hình cần thiết',
                'car_license': 'Giấy phép cần thiết',
                'max_years_old': 'Năm tối đa cần thiết',
                'manufacture_year': 'Năm sản xuất cần thiết',
                'maximum_power': 'Công suất tối đa cần thiết',
                'is_second_hand': 'Được yêu cầu phải sử dụng cũ',
                'vehicle_inspection_date': 'Yêu cầu ngày kiểm tra xe',
                'insurance_expiry_date': 'Yêu cầu ngày hết hạn bảo hiểm',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'carImages': {
        'name': {
            'title': 'Hình ảnh xe hơi',
            'detailTitle': 'Hình ảnh xe chi tiết',
            'editTitle': 'Chỉnh sửa hình ảnh xe hơi'
        },
        'table': {
            'id_car_ima': 'TÔI',
            'id_car': 'Xe ô tô',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_car_ima': 'TÔI',
            'id_car': 'Xe ô tô',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_car_ima': 'Id cần thiết',
                'id_car': 'Yêu cầu xe',
                'url': 'Url cần thiết',
                'upload_time': 'Tải lên thời gian yêu cầu',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'chargeStationImages': {
        'name': {
            'title': 'Hình ảnh điểm điện tích',
            'detailTitle': 'Chi tiết hình ảnh điểm tính phí',
            'editTitle': 'Chỉnh sửa hình ảnh điểm tính phí'
        },
        'table': {
            'id_ima': 'TÔI',
            'id_cha_poi': 'Điểm tính phí',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_ima': 'TÔI',
            'id_cha_poi': 'Điểm tính phí',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_ima': 'Id cần thiết',
                'id_cha_poi': 'Điểm phí cần thiết',
                'url': 'Url cần thiết',
                'upload_time': 'Tải lên thời gian yêu cầu',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'chargingStation': {
        'tooltip': {
            'postpaid': "Đặt lệnh sạc thanh toán lần lượt, mặc định là thiết lập theo khu vực",
            'billingMethod': "Đặt cách tính phí đơn đặt hàng, mặc định là theo khu vực",
        },
        'billingMethodType': {
            '0':"Theo thời gian",
            '1':"Theo sức mạnh",
            '2':"Theo kế hoạch người dùng",
            '3':"bởi app",
            '4':"Theo khu vực",
        },
        'updateFirmware': {
            'identifier': "Số sê-ri",
            'location': "Địa chỉ từ xa",
            'retrieveDate': "Ngày truy xuất",
        },
        'uValidate': {
            'identifier': "Vui lòng nhập số sê-ri",
            'location': "Vui lòng nhập địa chỉ từ xa",
            'retrieveDate': "Vui lòng chọn ngày truy xuất",
        },
        'setting': {
            'index': "Số sê-ri",
            'readonly': "Có phải chỉ đọc hay không",
            'key': "Thiết lập phím",
            'value': "Đặt giá trị",
        },
        'settingField': {
            'readonly': "Vui lòng chọn có chỉ đọc hay không",
            'key': "Phím cài đặt không thể trống",
            'value': "Giá trị cài đặt không thể trống",
        },
        'name': {
            'title': 'Điểm tính phí',
            'detailTitle': 'Chi tiết điểm phí',
            'editTitle': 'Chỉnh sửa điểm phí',
            'quickAdd': 'Thêm điểm phí'
        },
        'table': {
            'id_cha_poi': 'TÔI',
            'id_met': 'Mét',
            'charge_box_id': 'ID hộp tính phí',
            'firmwareVersion': 'Phiên bản firmware',
            'last_heartbeat_time': 'Thời gian nhịp tim cuối cùng',
            'monthly_fee': 'Phí hàng tháng',
            'charger_type': 'Loại bộ sạc',
            'tenant_key': 'Chìa khóa thuê',
            'name': 'Tên',
            'heartbeat_interval': 'Khoảng thời gian nhịp tim',
            'enable': 'Cho phép',
            'maxCurrent': "Dòng điện tối đa",
            'minCurrent': "Dòng điện tối thiểu",
            'isPrivate': "Riêng tư hay không",
            'isReadMeter': "Đọc bảng hay không",
            'isReset': "Khởi động lại (khởi động lại để có hiệu lực)",
            'action': {
                'updateFirmware': "Nâng cấp phần vững",
                'hardReset': "Đặt lại cứng",
                'softReset': "Đặt lại mềm"
            }
        },
        'validate': {
            'id_cha_poi': 'Id cần thiết',
            'id_met': 'Đồng hồ yêu cầu',
            'charge_box_id': 'Yêu cầu ID hộp tính phí',
            'last_heartbeat_time': 'Thời gian nhịp tim cuối cùng cần thiết',
            'monthly_fee': 'Phí hàng tháng cần thiết',
            'charger_type': 'Loại bộ sạc cần thiết',
            'tenant_key': 'Khóa thuê cần thiết',
            'name': 'Tên cần thiết',
            'heartbeat_interval': 'Khoảng thời gian nhịp tim cần thiết',
            'enable': 'Bật yêu cầu',
            'maxCurrent': "Vui lòng điền vào dòng điện tối đa",
            'minCurrent': "Vui lòng điền vào dòng điện tối thiểu",
        }
    },
    'connector': {
        'name': {
            'title': 'Tư nối',
            'detailTitle': 'Chi tiết kết nối',
            'editTitle': 'Chỉnh sửa kết nối',
            'startTransaction': 'Bắt đầu giao dịch',
            'stopTransaction': 'Dừng giao dịch'
        },
        'table': {
            'id_con': 'TÔI',
            'id_cha_poi': 'Điểm tính phí',
            'name': 'Tên',
            'connector_id': 'ID đầu nối',
            'status': 'Tình trạng',
            'tenant_key': 'Chìa khóa thuê',
            'power': 'Quyền lực(kw)',
            'type': 'Loại'
        },
        'field': {
            'id_con': 'TÔI',
            'id_cha_poi': 'Điểm tính phí',
            'name': 'Tên',
            'connector_id': 'ID đầu nối',
            'status': 'Tình trạng',
            'tenant_key': 'Chìa khóa thuê',
            'power': 'Quyền lực(kw)',
            'type': 'Loại'
        },
        'validate': {
            'required': {
                'id_con': 'Id cần thiết',
                'id_cha_poi': 'Điểm phí cần thiết',
                'name': 'Tên cần thiết',
                'connector_id': 'ID đầu nối cần thiết',
                'status': 'Tình trạng cần thiết',
                'tenant_key': 'Khóa thuê cần thiết',
                'power': 'Quyền lực cần thiết(kw)',
                'type': 'Loại cần thiết'
            }
        }
    },
    'employee': {
        'name': {'title': 'Nhà điều hành', 'detailTitle': 'Chi tiết vận hành', 'editTitle': 'Chỉnh sửa toán tử'},
        'table': {
            'id_emp': 'TÔI',
            'id_rol': 'Vai diễn',
            'account': 'Tài khoản',
            'password': 'Mật khẩu',
            'salt': 'Muối ăn',
            'last_login_time': 'Thời gian đăng nhập cuối cùng',
            'register_time': 'Thời gian đăng ký',
            'login_failure_counter': 'Bộ đếm thất bại đăng nhập',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Số',
            'remark': 'Nhận xét',
            'status': 'Tình trạng',
            'name': 'Tên',
            'commission_rate': 'Tỷ lệ hoa hồng',
            'yedpay_api_key': 'Khóa API YedPay',
            'yedpay_sign_key': 'Yedpay Dấu hiệu',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_emp': 'TÔI',
            'id_rol': 'Vai diễn',
            'account': 'Tài khoản',
            'password': 'Mật khẩu',
            'salt': 'Muối ăn',
            'last_login_time': 'Thời gian đăng nhập cuối cùng',
            'register_time': 'Thời gian đăng ký',
            'login_failure_counter': 'Bộ đếm thất bại đăng nhập',
            'email': 'E-mail',
            'whatsAppPhone': 'WhatsApp Số',
            'remark': 'Nhận xét',
            'status': 'Tình trạng',
            'name': 'Tên',
            'commission_rate': 'Tỷ lệ hoa hồng',
            'yedpay_api_key': 'Khóa API YedPay',
            'yedpay_sign_key': 'Yedpay Dấu hiệu',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_emp': 'Id cần thiết',
                'id_rol': 'Vai trò cần thiết',
                'account': 'Yêu cầu tài khoản',
                'password': 'Mật khẩu được yêu câu',
                'salt': 'Muối cần thiết',
                'last_login_time': 'Thời gian đăng nhập cuối cùng được yêu cầu',
                'register_time': 'Thời gian đăng ký cần thiết',
                'login_failure_counter': 'Yêu cầu bộ đếm thất bại đăng nhập',
                'email': 'Email (bắt buộc',
                'whatsAppPhone': 'Vui lòng điền Số WhatsApp',
                'remark': 'Nhận xét cần thiết',
                'status': 'Tình trạng cần thiết',
                'name': 'Tên cần thiết',
                'commission_rate': 'Tỷ lệ hoa hồng cần thiết',
                'yedpay_api_key': 'YEDPAY API khóa cần thiết',
                'yedpay_sign_key': 'Yedpay Dấu hiệu cần thiết',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'eventLog': {
        'name': {
            'title': 'Nhật ký sự kiện',
            'detailTitle': 'Chi tiết nhật ký sự kiện',
            'editTitle': 'Chỉnh sửa nhật ký sự kiện'
        },
        'table': {
            'id_env_log': 'TÔI',
            'run_time': 'Thời gian thực hiện(ms)',
            'class_method': 'Phương pháp thực hiện',
            'event_time': 'Thời gian sự kiện',
            'log': 'Đăng nhập',
            'tenant_key': 'Chìa khóa thuê',
            'resCode': 'Mã phản hồi',
        },
        'field': {
            'id_env_log': 'TÔI',
            'event_time': 'Thời gian sự kiện',
            'log': 'Đăng nhập',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_env_log': 'Id cần thiết',
                'class_method': 'Vui lòng điền vào phương thức thực hiện',
                'event_time': 'Thời gian sự kiện cần thiết',
                'log': 'Yêu cầu nhật ký',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'meter': {
        'name': {'title': 'Mét', 'detailTitle': 'Chi tiết đồng hồ', 'editTitle': 'Chỉnh sửa đồng hồ đo'},
        'table': {
            'id_met': 'TÔI',
            'id_reg': 'Khu vực',
            'name': 'Tên mét',
            'current_threshold': 'Ngưỡng hiện tại',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_met': 'TÔI',
            'id_reg': 'Khu vực',
            'name': 'Tên mét',
            'current_threshold': 'Ngưỡng hiện tại',
            'tenant_key': 'Chìa khóa thuê',
            'loadByTactics': 'Bật chính sách'
        },
        'validate': {
            'required': {
                'id_met': 'Id cần thiết',
                'id_reg': 'Khu vực cần thiết',
                'name': 'Tên đồng hồ cần thiết',
                'current_threshold': 'Ngưỡng hiện tại cần thiết',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'order': {
        'name': {
            'title': 'Gọi món',
            'detailTitle': 'Chi tiết đặt hàng',
            'editTitle': 'Chỉnh sửa thứ tự',
            'cancel': 'Hủy đặt hàng',
            'cancelAndRefund': 'Hủy đặt hàng và hoàn tiền',
        },
        'table': {
            'id_ord': 'TÔI',
            'id_car': 'Xe ô tô',
            'id_con': 'Tư nối',
            'order_time': 'Thời gian phục vụ',
            'type': 'Loại',
            'amount': 'Số lượng',
            'charge_start_time': 'Thời gian bắt đầu sạc',
            'charge_end_time': 'Thời gian nên kết thúc',
            'chargeEndTimeActual': 'Thời gian kết thúc thực tế',
            'status': 'Tình trạng',
            'payment_time': 'Thời gian thanh toán',
            'electricity': 'Mua điện',
            'charge_minutes': 'Thời gian mua',
            'currentElectricity': "Điện hiện tại",
            'ref_id_pla': 'Kế hoạch',
            'price': 'Giá bán',
            'commission_rate': 'Tỷ lệ hoa hồng',
            'tenant_key': 'Chìa khóa thuê',
            'id_tra_log': 'Giao dịchLogid',
            'pay_type': 'Paytype',
            'payment_method': 'Phương thức thanh toán'
        },
        'validate': {
            'id_ord': 'Id cần thiết',
            'id_car': 'Yêu cầu xe',
            'id_con': 'Đầu nối cần thiết',
            'order_time': 'Thời gian đặt hàng cần thiết',
            'type': 'Loại cần thiết',
            'amount': 'Số tiền cần thiết',
            'charge_start_time': 'Thời gian bắt đầu tính phí cần thiết',
            'charge_end_time': 'Thời gian cuối tính phí cần thiết',
            'status': 'Tình trạng cần thiết',
            'payment_time': 'Thời gian thanh toán cần thiết',
            'electricity': 'Điện cần thiết',
            'ref_id_pla': 'Kế hoạch cần thiết',
            'price': 'Giá cả cần thiết',
            'commission_rate': 'Tỷ lệ hoa hồng cần thiết',
            'tenant_key': 'Khóa thuê cần thiết',
            'id_tra_log': 'Giao dịch allingid yêu cầu',
            'charge_minutes': 'Chargeminutes cần thiết',
            'pay_type': 'PayType cần thiết',
            'payment_method': 'Phương thức thanh toán cần thiết'
        }
    },
    'plan': {
        'name': {'title': 'Kế hoạch', 'detailTitle': 'Kế hoạch chi tiết', 'editTitle': 'Chỉnh sửa kế hoạch'},
        'table': {
            'id_pla': 'TÔI',
            'name': 'Tên kế hoạch',
            'price_per_month': 'Giá bán',
            'contract_period': 'Thời hạn hợp đồng',
            'free_charging_time_limit': 'Thời gian sạc miễn phí',
            'overtime_charge_price': 'Giá ngoài giờ',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_pla': 'TÔI',
            'name': 'Tên kế hoạch',
            'price_per_month': 'Giá bán',
            'contract_period': 'Thời hạn hợp đồng',
            'free_charging_time_limit': 'Thời gian sạc miễn phí',
            'overtime_charge_price': 'Giá ngoài giờ',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_pla': 'Id cần thiết',
                'name': 'Tên kế hoạch cần thiết',
                'price_per_month': 'Giá cả cần thiết',
                'contract_period': 'Thời gian hợp đồng cần thiết',
                'free_charging_time_limit': 'Yêu cầu thời gian sạc miễn phí',
                'overtime_charge_price': 'Giá ngoài giờ cần thiết',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'region': {
        'tooltip': {
            'byPowerUnit': "Mỗi bước nhảy, không đủ một bước thì tính theo một bước nhảy, nếu tính theo lượng điện thực tế của đơn đặt hàng thì đặt thành 0.",
            'byTimeUnit': "Mỗi bước nhảy dài, không đủ một bước thì tính theo một bước nhảy, nếu tính theo thời gian thực tế của đơn đặt hàng xin đặt thành 0.",

            'maximumPower': "Khi sạc theo mức điện, giới hạn mức điện cho một lần sạc, để trống hoặc bằng không thì không giới hạn, mặc định không giới hạn",
            'maximumMinute': "Khi sạc theo thời gian, giới hạn thời gian sạc một lần, để trống hoặc bằng không thì không giới hạn, mặc định không giới hạn",
        },
        'billingMethodType':{
            '0': "Theo thời gian",
            '1': "Theo sức mạnh",
            '2': "bởi app",
        },
        'name': {'title': 'Khu vực', 'detailTitle': 'Chi tiết khu vực', 'editTitle': 'Chỉnh sửa khu vực'},
        'table': {
            'id_reg': 'TÔI',
            'name': 'Tên khu vực',
            'ac_price_per_electricity': 'Giá ac trên mỗi điện',
            'ac_price_per_minute': 'Giá AC mỗi phút',
            'lon': 'Lon',
            'lat': 'Lat',
            'address': 'Địa chỉ nhà',
            'dc_price_per_electricity': 'Giá DC trên mỗi điện',
            'dc_price_per_minute': 'Giá DC mỗi phút',
            'businessAllDay': 'Mở cửa cả ngày',
            'tenant_key': 'Chìa khóa thuê',
            'images': 'Hình ảnh',
            'byPowerUnit': "Đơn vị đo điện",
            'byTimeUnit': "Đơn vị đo theo thời gian",
            'postpaid': "Trả sau hay không",
            'billingMethod': "Phương thức thanh toán",
            'businessHours': "Thời gian hoạt động",

            'maximumPower': "Giới hạn trên cho một lần sạc",
            'maximumMinute': "Giới hạn thời gian sạc đơn",
            'directDebit': "Tự động khấu trừ",

            'enableTimeoutFine': "Mở thời hạn phạt tiền",
            'timeoutFine': "Thời gian chờ(min)",
            'timeoutCharging': "Thanh toán ngoài giờ",
        },
        'validate': {
            'id_reg': 'Id cần thiết',
            'name': 'Tên khu vực cần thiết',
            'ac_price_per_electricity': 'Giá ac trên mỗi điện cần',
            'ac_price_per_minute': 'Giá ac mỗi phút cần thiết',
            'lon': 'LON yêu cầu',
            'lat': 'Lat bắt buộc',
            'address': 'Địa chỉ cần thiết',
            'dc_price_per_electricity': 'Giá DC trên mỗi điện cần',
            'dc_price_per_minute': 'Giá DC mỗi phút cần thiết',
            'businessAllDay': 'Vui lòng chọn có mở cửa cả ngày hay không',
            'tenant_key': 'Khóa thuê cần thiết',
            'byPowerUnit': "Vui lòng điền đơn vị đo điện",
            'byTimeUnit': "Vui lòng điền đơn vị đo thời gian",
            'postpaid': "Vui lòng chọn trả sau",
            'billingMethod': "Vui lòng chọn phương thức thanh toán",
            'businessHours': "Chọn thời gian hoạt động",

            'maximumPower': "Vui lòng điền vào giới hạn sạc một lần",
            'maximumMinute': "Vui lòng điền giới hạn thời gian sạc đơn",
            'directDebit': "Vui lòng chọn có tự động khấu trừ hay không",

            'enableTimeoutFine': "Vui lòng chọn có mở hình phạt thời gian chờ hay không",
            'timeoutFine': "Vui lòng điền thời gian chờ(min)",
            'timeoutCharging': "Vui lòng điền vào thanh toán thời gian chờ",
        }
    },
    'regionImages': {
        'name': {
            'title': 'Hình ảnh khu vực',
            'detailTitle': 'Chi tiết hình ảnh khu vực',
            'editTitle': 'Chỉnh sửa hình ảnh khu vực'
        },
        'table': {
            'id_reg_img': 'TÔI',
            'id_reg': 'Khu vực',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_reg_img': 'TÔI',
            'id_reg': 'Khu vực',
            'url': 'URL',
            'upload_time': 'Thời gian tải lên',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_reg_img': 'Id cần thiết',
                'id_reg': 'Khu vực cần thiết',
                'url': 'Url cần thiết',
                'upload_time': 'Tải lên thời gian yêu cầu',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'repairLog': {
        'name': {
            'title': 'Nhật ký sửa chữa',
            'detailTitle': 'Sửa chữa nhật ký chi tiết',
            'editTitle': 'Chỉnh sửa nhật ký sửa chữa'
        },
        'table': {
            'id_rep_log': 'TÔI',
            'id_car': 'Xe ô tô',
            'amount': 'Số lượng',
            'repair_date': 'Ngày sửa chữa',
            'remark': 'Nhận xét',
            'tenant_key': 'Chìa khóa thuê'
        },
        'field': {
            'id_rep_log': 'TÔI',
            'id_car': 'Xe ô tô',
            'amount': 'Số lượng',
            'repair_date': 'Ngày sửa chữa',
            'remark': 'Nhận xét',
            'tenant_key': 'Chìa khóa thuê'
        },
        'validate': {
            'required': {
                'id_rep_log': 'Id cần thiết',
                'id_car': 'Yêu cầu xe',
                'amount': 'Số tiền cần thiết',
                'repair_date': 'Ngày sửa chữa cần thiết',
                'remark': 'Nhận xét cần thiết',
                'tenant_key': 'Khóa thuê cần thiết'
            }
        }
    },
    'role': {
        'name': {'title': 'Vai diễn', 'detailTitle': 'Vai trò chi tiết', 'editTitle': 'Chỉnh sửa vai trò'},
        'table': {'id_rol': 'TÔI', 'name': 'Vai diễn', 'remark': 'Nhận xét'},
        'field': {'id_rol': 'TÔI', 'name': 'Vai diễn', 'remark': 'Nhận xét', 'authorityList': 'Danh sách thẩm quyền'},
        'validate': {
            'required': {
                'id_rol': 'Id cần thiết',
                'name': 'Vai trò cần thiết',
                'remark': 'Nhận xét cần thiết'
            }
        }
    },
    'roleAuthority': {
        'name': {
            'title': 'ROLEAUTHORITY',
            'detailTitle': 'Chi tiết roleauthority',
            'editTitle': 'Chỉnh sửa roleauthority'
        },
        'table': {'id_rol_aut': 'TÔI', 'id_rol': 'Vai diễn', 'id_aut': 'Chính quyền'},
        'field': {'id_rol_aut': 'TÔI', 'id_rol': 'Vai diễn', 'id_aut': 'Chính quyền'},
        'validate': {
            'required': {
                'id_rol_aut': 'Id cần thiết',
                'id_rol': 'Vai trò cần thiết',
                'id_aut': 'Cần có thẩm quyền'
            }
        }
    },
    'transactionLog': {
        'name': {
            'title': 'Nhật ký giao dịch',
            'detailTitle': 'Chi tiết nhật ký giao dịch',
            'editTitle': 'Chỉnh sửa nhật ký giao dịch'
        },
        'table': {
            'id_tra_log': 'TÔI',
            'id_con': 'IDT đầu nối',
            'event_time': 'Thời gian sự kiện',
            'id_tag': 'Id thẻ',
            'start_time': 'Thời gian bắt đầu',
            'start_value': 'Giá trị bắt đầu',
            'stop_time': 'Dừng thời gian',
            'stop_value': 'Dừng giá trị',
            'stop_reason': 'Dừng lý do',
            'fail_reason': 'Thất bại lý do',
            'tenant_key': 'Chìa khóa thuê',
            'current_electricity': 'Điện hiện tại'
        },
        'field': {
            'id_tra_log': 'TÔI',
            'id_con': 'IDT đầu nối',
            'event_time': 'Thời gian sự kiện',
            'id_tag': 'Id thẻ',
            'start_time': 'Thời gian bắt đầu',
            'start_value': 'Giá trị bắt đầu',
            'stop_time': 'Dừng thời gian',
            'stop_value': 'Dừng giá trị',
            'stop_reason': 'Dừng lý do',
            'fail_reason': 'Thất bại lý do',
            'tenant_key': 'Chìa khóa thuê',
            'current_electricity': 'Điện hiện tại'
        },
        'validate': {
            'required': {
                'id_tra_log': 'Id cần thiết',
                'id_con': 'IDT kết nối cần thiết',
                'event_time': 'Thời gian sự kiện cần thiết',
                'id_tag': 'Thẻ ID cần thiết',
                'start_time': 'Thời gian bắt đầu cần thiết',
                'start_value': 'Giá trị bắt đầu cần thiết',
                'stop_time': 'Thời gian dừng bắt buộc',
                'stop_value': 'Dừng giá trị cần thiết',
                'stop_reason': 'Dừng lý do cần thiết',
                'fail_reason': 'Thất bại lý do cần thiết',
                'tenant_key': 'Khóa thuê cần thiết',
                'current_electricity': 'Điện hiện tại cần thiết'
            }
        }
    },
    'user': {
        'name': {'title': 'Người sử dụng', 'detailTitle': 'Chi tiết người dùng', 'editTitle': 'Người dùng biên tập'},
        'table': {
            'id_use': 'TÔI',
            'balance': 'Sự cân bằng',
            'account': 'Tài khoản',
            'password': 'Mật khẩu',
            'register_time': 'Thời gian đăng ký',
            'email': 'E-mail',
            'token': 'Mã thông báo',
            'salt': 'Muối ăn',
            'tenant_key': 'Chìa khóa thuê',
            'phone': 'Điện thoại',
            'register_type': 'Loại đăng ký'
        },
        'field': {
            'id_use': 'TÔI',
            'balance': 'Sự cân bằng',
            'account': 'Tài khoản',
            'password': 'Mật khẩu',
            'register_time': 'Thời gian đăng ký',
            'email': 'E-mail',
            'token': 'Mã thông báo',
            'salt': 'Muối ăn',
            'tenant_key': 'Chìa khóa thuê',
            'phone': 'Điện thoại',
            'register_type': 'Loại đăng ký',

            'chargingCard': 'Số thẻ sạc',
            'chargingType': 'Chế độ sạc ưu tiên',
            'chargingDefDuration': 'Thời gian sạc thẻ(min)',
            'chargingDefPower': 'Số lượng thẻ sạc(kWh)'
        },
        'validate': {
            'required': {
                'id_use': 'Id cần thiết',
                'balance': 'Số dư cần thiết',
                'account': 'Yêu cầu tài khoản',
                'password': 'Mật khẩu được yêu câu',
                'register_time': 'Thời gian đăng ký cần thiết',
                'email': 'Email (bắt buộc',
                'token': 'Cần có mã thông báo',
                'salt': 'Muối cần thiết',
                'tenant_key': 'Khóa thuê cần thiết',
                'phone': 'Yêu cầu điện thoại',
                'register_type': 'Loại đăng ký cần thiết',

                'chargingCard': 'Vui lòng nhập số thẻ sạc',
                'chargingType': 'Vui lòng chọn chế độ sạc ưu tiên thẻ',
                'chargingDefDuration': 'Vui lòng nhập thời gian sạc thẻ(min)',
                'chargingDefPower': 'Vui lòng nhập số lượng thẻ sạc(kWh)',
            }
        }
    },
    'userPlan': {
        'name': {
            'title': 'Kế hoạch người dùng',
            'detailTitle': 'Chi tiết kế hoạch người dùng',
            'editTitle': 'Chỉnh sửa kế hoạch người dùng'
        },
        'table': {
            'id_use': 'Người dùng',
            'id_pla': 'Kế hoạch',
            'arrearsCount': 'Số tiền nợ',
            'start_date': 'Ngày bắt đầu',
            'tenant_key': 'Chìa khóa thuê',
            'createType': 'Tạo nhận dạng',
            'updateByMonth': 'Thời gian trả trước',
            'total': 'Số tháng trả trước',
            'amount': 'Số tiền thanh toán dự kiến',
            'prePayData': 'Dự kiến đóng phí tháng',
        },
        'validate': {
            'id_use': 'Người dùng yêu cầu',
            'id_pla': 'Kế hoạch cần thiết',
            'arrearsCount': 'Vui lòng điền vào số tháng còn nợ',
            'start_date': 'Bắt đầu ngày bắt đầu',
            'tenant_key': 'Khóa thuê cần thiết'
        }
    },
    'dashboard': {
        'name': {
            'totalChargers': 'Tổng số bộ sạc',
            'availableChargers': 'Số lượng cổng có sẵn',
            'inUseChargers': 'Sử dụng số cổng trung bình',
            'unavailableChargers': 'Số lượng cổng không sẵn dùng',
            'portUsage': 'Sử dụng cổng',

            'energyUsage': 'Sử dụng năng lượng (kw)',
            'totalRevenue': 'Tổng doanh thu',
            'totalEnergy': 'Tổng năng lượng',
            'newUser': 'Người dùng mới'
        }
    },
    'appVersionManage': {
        'field': {
            'androidVersion': 'Phiên bản android',
            'androidDownloadLink': 'Liên kết tải xuống Android',
            'androidComplieNumber': 'Số biên dịch Android',
            'iosVersion': 'Phiên bản iOS',
            'iosBundleId': 'IS IOS ID',
            'iosComplieNumber': 'Số biên dịch iOS',
            'isForcedUpdate': 'Buộc cập nhật hay không'
        },
        'validate': {
            'required': {
                'androidVersion': 'Phiên bản Android cần thiết',
                'androidDownloadLink': 'Liên kết tải xuống Android cần thiết',
                'androidComplieNumber': 'Số biên dịch Android được yêu cầu',
                'iosVersion': 'Phiên bản iOS cần thiết',
                'iosBundleId': 'Id bó iOS cần thiết',
                'iosComplieNumber': 'Số biên dịch iOS được yêu cầu',
                'isForcedUpdate': 'Vui lòng chọn có bắt buộc cập nhật hay không'
            }
        }
    },
    'dataTransfer': {'field': {'messageId': 'Cài đặt khóa', 'dataStr': 'Giá trị cài đặt'}},
    'superTenantManage': {
        'name': {'title': 'Quyền', 'detailTitle': 'Thông tin chi tiết', 'editTitle': 'Chỉnh sửa'},
        'searchForm': {
            'dataSourceKey': 'Khóa nguồn dữ liệu',
            'url': 'URL',
            'username': 'Tên tài khoản',
            'tenant': 'Người thuê nhà',
            'isEnable': 'Được kích hoạt',
            'isCreate': 'tạo'
        },
        'searchFormPlaceholder': {
            'dataSourceKey': 'Vui lòng nhập khóa nguồn dữ liệu',
            'url': 'Vui lòng nhập địa chỉ liên kết',
            'username': 'Vui lòng nhập tài khoản',
            'tenant': 'Vui lòng nhập tên người thuê',
            'isEnable': 'Vui lòng chọn có thể bật',
            'isCreate': 'Vui lòng chọn tạo'
        },
        'table': {
            'index': 'Mục lục',
            'dataSourceKey': 'Khóa nguồn dữ liệu',
            'url': 'URL',
            'host': 'Chủ nhà',
            'port': 'Hải cảng',
            'dbName': 'Tên cơ sở dữ liệu',
            'username': 'tên tài khoản',
            'password': 'Mật khẩu',
            'tenant': 'người thuê nhà',
            'isEnable': 'Được kích hoạt',
            'isCreate': 'tạo',
            'domain': 'Lãnh địa',
            'action': {'testConn': 'Conn', 'create': 'Tạo nên', 'edit': 'Chỉnh sửa', 'del': 'Xóa bỏ'}
        },
        'field': {
            'id': 'TÔI',
            'datasourceKey': 'Khóa nguồn dữ liệu',
            'seckey': 'Khóa truy cập',
            'url': 'URL',
            'host': 'Chủ nhà',
            'port': 'Hải cảng',
            'dbName': 'Tên cơ sở dữ liệu',
            'username': 'Tên tài khoản',
            'password': 'Mật khẩu',
            'tenant': 'Người thuê nhà',
            'isEnable': 'Được kích hoạt',
            'isCreate': 'tạo',
            'remark': 'Nhận xét',
            'domain': 'Lãnh địa',
            'onesignalKey': 'Khóa thông báo APP',
        },
        'validate': {
            'required': {
                'id': 'Vui lòng điền ID',
                'dataSourceKey': 'Vui lòng điền vào DataSourceKey',
                'seckey': 'Vui lòng điền vào Khóa truy cập',
                'url': 'Vui lòng điền vào URL liên kết',
                'host': 'Vui lòng điền vào máy chủ',
                'port': 'Vui lòng điền vào cổng',
                'dbName': 'Vui lòng điền vào tên cơ sở dữ liệu',
                'username': 'Vui lòng nhập tên người dùng',
                'password': 'Vui lòng điền mật khẩu',
                'tenant': 'Vui lòng điền vào tên người thuê',
                'isEnable': 'Vui lòng chọn trạng thái hoạt động',
                'remark': 'Vui lòng điền vào các nhận xét',
                'domain': 'Vui lòng điền vào miền',
                'onesignalKey': 'Vui lòng điền vào khóa thông báo APP',
            }
        }
    },
    'iPadManage': {
        'field': {
            'id': 'TÔI',
            'index': 'Mục lục',
            'machineCode': 'Mã máy',
            'name': 'Tên',
            'regId': 'ID khu vực',
            'regName': 'Tên khu vực',
            'seckey': 'Khóa truy cập',
            'args': 'Args',
            'createBy': 'Tạo bởi',
            'createTime': 'Tạo thời gian',
            'updateBy': 'cập nhật bởi',
            'updateTime': 'Cập nhật thời gian',
            'remark': 'Nhận xét'
        },
        'validate': {
            'id': 'Vui lòng điền ID',
            'machineCode': 'Vui lòng điền vào mã máy',
            'name': 'Vui lòng điền vào tên',
            'regId': 'Vui lòng điền vào ID khu vực',
            'seckey': 'Vui lòng điền vào Khóa truy cập',
            'args': 'Vui lòng điền vào ARGS',
            'remark': 'Vui lòng điền vào nhận xét'
        }
    },
    'noticeManage': {
        'noticeName': {'0': 'E-mail', '1': 'Điện thoại'},
        'noticeType': {
            '0': 'Cảnh báo',
            '1': 'Nhắc lại',
            '2': 'Thông báo',
            '3': 'Hết giờ',
        },
        'field': {
            'id': 'TÔI',
            'index': 'Mục lục',
            'toFrom': 'Tài khoản',
            'type': 'Kiểu tài khoản',
            'noticeType': 'Loại thông báo',
            'status': 'Được kích hoạt',
            'createBy': 'Tạo bởi',
            'createTime': 'Tạo thời gian',
            'updateBy': 'cập nhật bởi',
            'updateTime': 'Cập nhật thời gian',
            'remark': 'Nhận xét'
        },
        'validate': {
            'id': 'Vui lòng điền ID',
            'type': 'Vui lòng chọn loại tài khoản',
            'toFrom': 'Vui lòng điền vào tài khoản',
            'noticeType': 'Vui lòng chọn loại thông báo',
            'status': 'Vui lòng chọn có thể bật',
            'remark': 'Vui lòng điền vào nhận xét'
        }
    },
    'publicityManage': {
        'publicityType': {
            '0': 'Điều khoản dịch vụ',
            '1': 'Về chúng tôi',
            '2': 'Thông báo',
            '3': 'Thông báo',
            '4': 'Nhắc nhở',
        },
        'field': {
            'id': 'ID',
            'index': 'Số sê-ri',
            'type': 'Loại thông báo',
            'status': 'Có được bật hay không',
            'title': 'Tiêu đề thông báo',
            'content': 'Thông báo',
            'tenantKey': 'Danh tính người thuê nhà',

            'createBy': 'Người sáng tạo',
            'createTime': 'Tạo thời gian',
            'updateBy': 'Cập nhật bởi',
            'updateTime': 'Cập nhật thời gian',
            'remark': 'Lưu ý',
        },
        'validate': {
            'id': 'Vui lòng điền ID',
            'type': 'Vui lòng chọn loại thông báo',
            'status': 'Vui lòng chọn có bật hay không',
            'title': 'Vui lòng điền vào tiêu đề thông báo',
            'content': 'Vui lòng điền thông báo',
            'remark': 'Vui lòng điền vào ghi chú',
        }
    },
    'statisticsManage': {
        'table': {
            'index': 'Số sê-ri',
            'identifier': 'Số cọc điện',
            'useNum': 'Số lần sử dụng',
            'chargeCapacity': 'Tổng liều lượng sử dụng(kw)',

            'startTime': 'Thời gian bắt đầu',
            'endTime': 'Thời gian kết thúc'
        },
        'validate': {
            'identifier': 'Vui lòng nhập số cọc điện',
            'startTime': 'Vui lòng chọn thời gian bắt đầu',
            'endTime': 'Vui lòng chọn thời gian kết thúc'
        }
    },
    'serviceManage': {
        'type': {
            '0': 'Đồ uống',
            '1': 'Thực phẩm',
            '2': 'Nhà vệ sinh',
            '3': 'Mua sắm',
        },
        'table': {
            'id': 'ID',
            'type': 'Loại',
            'status': 'Trạng thái',
            'name': 'Tên',
            'lng': 'Kinh độ',
            'lat': 'Vĩ độ',
            'address': 'Địa chỉ',
            'description': 'Mô tả',
            'reId': 'ID vùng liên kết'
        },
        'validate': {
            'type': 'Vui lòng chọn loại',
            'status': 'Vui lòng chọn trạng thái',
            'name': 'Xin vui lòng điền tên',
            'lng': 'Xin vui lòng điền vào kinh độ',
            'lat': 'Xin vui lòng điền vào vĩ độ',
            'address': 'Xin vui lòng điền vào địa chỉ',
            'description': 'Xin vui lòng điền vào mô tả',
            'reId': 'Khu vực liên quan'
        }
    },
    'companyManage': {
        'field': {
            'id': 'Khóa chính',
            'tId': 'ID đối tượng thuê liên kết',
            'name': 'Tên công ty',
            'attn': 'Liên hệ công ty',
            'tel': 'Điện thoại công ty',
            'address': 'Địa chỉ công ty',
            'email': 'Email công ty',
            'invoiceDate': 'Ngày hóa đơn',
            'invoiceNo': 'Số hóa đơn',
            'contactPerson': 'Liên hệ',
            'contactTel': 'Số điện thoại',
            'contactEmail': 'Email liên hệ',
            'monthlyFee': 'Truy cập hệ thống Giá trị đặt trước hàng tháng',
            'company': 'Thông tin công ty'
        },
        'validate': {
            'id': 'Vui lòng nhập primary key',
            'tId': 'Nhập ID đối tượng thuê liên kết',
            'name': 'Vui lòng nhập tên công ty',
            'attn': 'Vui lòng nhập liên hệ công ty',
            'tel': 'Vui lòng nhập số điện thoại công ty của bạn',
            'address': 'Vui lòng nhập địa chỉ công ty của bạn',
            'email': 'Vui lòng nhập email công ty của bạn',
            'invoiceDate': 'Vui lòng nhập ngày hóa đơn',
            'invoiceNo': 'Vui lòng nhập số hóa đơn',
            'contactPerson': 'Vui lòng nhập liên hệ',
            'contactTel': 'Vui lòng nhập số điện thoại liên lạc của bạn',
            'contactEmail': 'Vui lòng nhập email liên hệ của bạn',
            'monthlyFee': 'Vui lòng nhập giá trị mặc định của phí truy cập hệ thống hàng tháng'
        }
    },
    'tenantInfo': {
        'table': {
            'id': 'ID',
            'pid': 'ID đối tượng thuê cha',
            'tenantKey': 'Logo người thuê',
            'name': 'Tên đối tượng thuê',
            'domain': 'Tên miền bị ràng buộc',
            'tpId': 'Gói liên kết',
            'authId': 'Quyền liên quan',
            'level': 'Cấp bậc',
            'contact': 'Liên hệ',
            'email': 'Liên hệ hộp thư',
            'phone': 'Số điện thoại',
            'startTime': 'Thời gian bật',
            'expireTime': 'Thời gian hết hạn',
        },
        'args':{
            'yedPayExtendParams': 'Tham số YedPay',
            'yedPayApiKey': 'YedPay ApiKey',
            'yedPaySingKey': 'YedPay SingKey',

            'mPayExtendParams': 'Tham số MPay',
            'mPayPubKey': 'Khóa công khai MPay',
            'mPayPriKey': 'Khóa cá nhân MPay',
        },
        'validate': {
            'id': 'Vui lòng nhập ID',
            'pid': 'Vui lòng nhập ID đối tượng thuê phụ huynh',
            'tenantKey': 'Vui lòng điền vào ID người thuê nhà',
            'name': 'Vui lòng điền tên người thuê',
            'domain': 'Vui lòng điền tên miền bị ràng buộc',
            'tpId': 'Vui lòng chọn gói liên kết',
            'authId': 'Vui lòng chọn quyền liên kết',
            'contact': 'Vui lòng điền liên hệ',
            'email': 'Vui lòng điền vào hộp thư liên hệ',
            'phone': 'Vui lòng điền số điện thoại liên hệ',
            'startTime': 'Vui lòng chọn thời gian bắt đầu',
            'expireTime': 'Vui lòng chọn ngày hết hạn',

            'yedPayExtendParams': 'Vui lòng nhập tham số YedPay',
            'yedPayApiKey': 'Nhập YedPay ApiKey',
            'yedPaySingKey': 'Nhập YedPay SingKey',
            'mPayExtendParams': 'Vui lòng nhập tham số MPay',
            'mPayPubKey': 'Vui lòng nhập MPay Public Key',
            'mPayPriKey': 'Vui lòng nhập khóa riêng MPay',
        }
    },
    'tenantPackage': {
        'table': {
            'id': 'ID',
            'packageNo': 'Số gói',
            'name': 'Tên gói',
            'createTenantMax': 'Có thể tạo số lượng thuê',
            'createUserMax': 'Số lượng user có thể tạo',
            'createAppMax': 'Số lượng app có thể đăng ký',
            'expireLong': 'Gói có hiệu lực trong thời gian dài',
            'tenantKey': 'Logo người thuê',
        },
        'validate': {
            'id': 'Vui lòng nhập ID',
            'packageNo': 'Vui lòng nhập số gói',
            'name': 'Vui lòng nhập tên gói',
            'createTenantMax': 'Vui lòng nhập số lượng thuê có thể tạo',
            'createUserMax': 'Vui lòng nhập số lượng người dùng có thể tạo',
            'createAppMax': 'Vui lòng nhập số lượng app đăng ký',
            'expireLong': 'Vui lòng chọn gói có giá trị trong thời gian dài',
            'tenantKey': 'Vui lòng nhập nhận dạng đối tượng thuê',
        }
    },
    'card': {
        'cardType': {
            '0': 'Danh sách trắng',
            '1': 'Danh sách đen',
            '2': 'Thẻ hệ thống',
            '3': 'Thẻ người dùng',
        },
        'table': {
            'cardId': 'ID',
            'cardNo': 'Số thẻ',
            'cardType': 'Loại thẻ',
            'cardStatus': 'Có bật hay không',
        },
        'validate': {
            'cardId': 'Vui lòng điền ID',
            'cardNo': 'Vui lòng điền số thẻ',
            'cardType': 'Vui lòng chọn loại',
            'cardStatus': 'Vui lòng chọn trạng thái',
        }
    },
    'waller': {
        'wType': {
            '1': 'Số dư',
            '2': 'Tiền thưởng',
            '3': 'Tích phân'
        },
        'table': {
            'walletId': 'ID',
            'account': 'Tài khoản',
            'type': 'Loại',
            'status': 'Đóng băng',
            'balance': 'Số dư',
        },
        'validate': {
            'account': 'Vui lòng điền tài khoản',
            'type': 'Vui lòng chọn loại',
            'status': 'Vui lòng chọn trạng thái',
        }
    }

}
export default viVN