<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('eventLog.table.class_method')" name="class_method">
                  <Input :placeholder="$t('eventLog.validate.required.class_method')" v-model:value="searchForm.classMethod" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.type')" name="type">
                  <Select
                      v-model:value="searchForm.type"
                      :placeholder="$t('public.pleaseSelect')"
                      :options="typeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.createTime')" name="createTime">
                  <RangePicker style="width: 100%" v-model:value="searchForm.valueTime"/>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('public.name.createBy')" name="remark">
                  <Input :placeholder="$t('public.name.createBy')" v-model:value="searchForm.createBy" allowClear></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button :loading="exportButtonLoading" type="primary" @click="exportToExcel">{{ $t('public.name.Export') }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list"
               :scroll="{ x: 1000}" rowKey="idEnvLog"
               :loading="loading" :pagination="tablePagination" @change="chang_page">
<!--          <template #actionTitle>-->
<!--              <span>-->
<!--                {{ $t("public.table.action") }}-->
<!--              </span>-->
<!--          </template>-->
<!--          <template #action="{text}">-->
<!--            <div class="pc-display">-->
<!--              <a @click="detail(text)">-->
<!--                {{ $t("public.name.detail") }}-->
<!--              </a>-->
<!--            </div>-->
<!--          </template>-->

          <template #type="{text}">
            <div>
              <Tag v-if="text === 2" color="cyan">
                {{ typeList.find(item => item.value === text)?.label }}
              </Tag>
              <Tag v-else-if="text === 3" color="orange">
                {{ typeList.find(item => item.value === text)?.label }}
              </Tag>
              <Tag v-else-if="text === 4" color="red">
                {{ typeList.find(item => item.value === text)?.label }}
              </Tag>
              <Tag v-else>{{ typeList.find(item => item.value === text)?.label }}</Tag>
            </div>
          </template>
        </Table>
      </div>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "ConnectorLogManage"
}
</script>
<script setup>
import { onMounted } from "vue";
import {
  TypographyTitle,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormItem,
  Input,
  Tag,
  Select,
  RangePicker,
  PageHeader
} from "ant-design-vue";
import moment from "moment";

import dayjs from "dayjs";
import {ref, reactive, toRefs} from "vue";
import {EventLogExportToExcel, EventLogList} from "@/api/eventLog";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";
const i18n = useI18n()

let loading = ref(false)
let exportButtonLoading = ref(false)
let searchData = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
  list: [],
  typeList: [
    {value: 0, label: 'OTHER'},
    {value: 1, label: 'NOTICE'},
    {value: 2, label: 'INSERT'},
    {value: 3, label: 'UPDATE'},
    {value: 4, label: 'DELETE'},
    {value: 5, label: 'GRANT'},
    {value: 6, label: 'EXPORT'},
    {value: 7, label: 'IMPORT'},
    {value: 8, label: 'FORCE'},
    {value: 9, label: 'GENCODE'},
    {value: 10, label: 'UPLOAD'},
  ],
  columns: [
    {
      title: i18n.t("eventLog.table.id_env_log"),
      width: 60,
      ellipsis: true,
      fixed: "left",
      dataIndex: "idEnvLog",
      key: "idEnvLog"
    },
    {
      title: i18n.t("public.name.type"),
      width: 60,
      ellipsis: true,
      dataIndex: "type",
      key: "type",
      slots: {title: "typeTitle", customRender: "type"}
    },
    {
      title: i18n.t("eventLog.table.resCode"),
      width: 60,
      ellipsis: true,
      dataIndex: "resCode",
      key: "resCode",
    },
    {
      title: i18n.t("eventLog.table.class_method"),
      width: 120,
      ellipsis: true,
      dataIndex: "classMethod",
      key: "classMethod"
    },
    {
      title: i18n.t("eventLog.table.run_time"),
      width: 60,
      ellipsis: true,
      dataIndex: "runTime",
      key: "runTime"
    },
    {
      title: i18n.t("public.name.args"),
      width: 180,
      ellipsis: true,
      dataIndex: "log",
      key: "log"
    },
    {
      title: i18n.t("public.name.createTime"),
      width: 100,
      ellipsis: true,
      dataIndex: "createTime",
      key: "createTime",
      customRender: ({text}) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: i18n.t("public.name.createBy"),
      width: 80,
      ellipsis: true,
      dataIndex: "createBy",
      key: "createBy"
    },
    {
      title: i18n.t("eventLog.table.tenant_key"),
      width: 80,
      ellipsis: true,
      dataIndex: "tenantKey",
      key: "tenantKey"
    },
    {
      title: i18n.t("public.name.remark"),
      width: 80,
      ellipsis: true,
      dataIndex: "remark",
      key: "remark"
    },
  ],
})
const {searchForm, tablePagination, list, columns, typeList} = toRefs(searchData)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list()
}

function search_submit() {
  tablePagination.value.current = 1
  tablePagination.value.pageSize = 10
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function exportToExcel() {
  exportButtonLoading.value = true;
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const api = new EventLogExportToExcel();
  api.getDownLoad(args).then((res) => {
    exportButtonLoading.value = false;
  });
}

function get_list() {
  loading.value = true;
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...args
  }
  const api = new EventLogList();
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  }).finally(() => {
    loading.value = false;
  });
}

// 设置页标题
const store = useStore()
store.commit("setPageTitle", i18n.t('nav.name.EventLogManage'));

onMounted(()=>{
  get_list();
})
defineExpose({
  get_list
});
</script>

<style lang="less">
@import url("style.less");
</style>