import {Base} from "@/api/Base"

const api = "carImages"

export class CarImagesAdd extends Base {
    path = api + "/add"
}

export class CarImagesDelete extends Base {
    path = api + "/delete"
}

export class CarImagesList extends Base {
    path = api + "/list"
}

export class CarImagesEdit extends Base {
    path = api + "/edit"
}
