import {Base} from "@/api/Base"

const api = "publicity"

export class PublicityGetListByPage extends Base {
    path = api + "/getListByPage"
}

export class PublicityGetInfo extends Base {
    path = api + "/getInfo"
}

export class PublicityAddInfo extends Base {
    path = api + "/addInfo"
}

export class PublicityDelete extends Base {
    path = api + "/delInfo"
}

export class PublicityEditInfo extends Base {
    path = api + "/editInfo"
}
