<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('authority.table.a_id_aut')" name="aIdAut">
                  <Select
                      v-model:value="searchForm.aIdAut"
                      :placeholder="$t('authority.validate.required.a_id_aut')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  >
                    <SelectOption :key="sk" v-for="(item,sk) in selectList['authorityList']" :value="item['idAut']">
                      {{ item['name'] }}
                    </SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('authority.field.name')" name="name">
                  <Input :placeholder="$t('authority.validate.required.name')" allowClear
                         v-model:value="searchForm.name"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('authority.field.resource_name')" name="resourceName">
                  <Input :placeholder="$t('authority.validate.required.resource_name')" allowClear
                         v-model:value="searchForm.resourceName"></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>
          </Form>

          <Row style="margin-bottom: 5px">
            <Col :span="8"></Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{ x: 1500}" rowKey="idAut"
               :loading="loading"
               :pagination="tablePagination" @change="chang_page">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('AuthorityManage.Add')" type="primary" size="small" shape="circle"
                        @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('AuthorityManage.Edit')" type="primary" size="small"
                          @click="edit(record)">{{ $t("public.name.edit") }}
                  </Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('AuthorityManage.Delete')" type="primary" danger size="small"
                          @click="del(record.idAut)">{{ $t("public.name.del") }}
                  </Button>
                </div>
<!--                <Dropdown :trigger="['click']" class="wap-display">-->
<!--                  <template #overlay>-->
<!--                    <Menu>-->
<!--                      <MenuItem @click="del(record.id)">{{ $t('public.name.del') }}</MenuItem>-->
<!--                    </Menu>-->
<!--                  </template>-->
<!--                  <Button size="small">-->
<!--                    {{ $t("public.name.more") }}-->
<!--                    <DownOutlined/>-->
<!--                  </Button>-->
<!--                </Dropdown>-->
              </div>
            </template>
          </template>
        </Table>
      </div>

    </Col>
    <AuthorityDetail ref="refAuthorityDetail"></AuthorityDetail>
    <AuthorityEdit ref="refAuthorityEdit" @updateList="get_list"></AuthorityEdit>
  </Row>
</template>

<script>
export default {
  name: "AuthorityManage"
}
</script>
<script setup>
import {createVNode, ref, reactive, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  SelectOption,
  message
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {AuthorityList, AuthorityDelete, AuthorityListAll} from "@/api/authority";
import AuthorityDetail from "./AuthorityDetail";
import AuthorityEdit from "./AuthorityEdit";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refAuthorityDetail = ref()
const refAuthorityEdit = ref()

let loading = ref(false)

const selectList = reactive({
  authorityList: [],
  typeList: [{value: 1, label: '菜單'}, {value: 2, label: '按鈕'}],
})
const columns = reactive([
  {title: i18n.t("authority.table.id_aut"), fixed: "left", dataIndex: "idAut", key: "idAut"},
  {title: i18n.t("authority.table.a_id_aut"), dataIndex: "authorityName", key: "authorityName"},
  {title: i18n.t("authority.table.name"), dataIndex: "name", key: "name"},
  {title: i18n.t("authority.table.resource_name"), dataIndex: "resourceName", key: "resourceName"},
  {
    title: i18n.t("authority.table.type"), dataIndex: "type", key: "type", customRender: ({text}) => {
      return selectList.typeList.find(item => item.value === text).label
    }
  },
  {title: i18n.t("authority.table.sort"), dataIndex: "sort", key: "sort"},
  {key: "action", fixed: "right", dataIndex: "action"},
])

const data = reactive({
  searchForm: {},
  list: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, list, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new AuthorityList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...searchForm.value
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      getAuthorityList()
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refAuthorityDetail.value?.detailShow(obj);
}

function add() {
  refAuthorityEdit.value?.addShow(selectList['authorityList']);
}

function edit(obj) {
  refAuthorityEdit.value?.editShow(obj, selectList['authorityList']);
}

function del(idAut) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new AuthorityDelete();
      api.post({idAut: idAut}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if (response.data.code !== undefined) {
            message.error(i18n.t("errorMsg." + response.data.code));
          }
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function getAuthorityList() {
  const api = new AuthorityListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      // 去掉按钮类型
      let lie = []
      let list = response.data.data
      list.forEach(item => {
        if (item.type === 1) {
          lie.push(item)
        }
      })
      selectList.authorityList = lie;
    } else {
      if (response.data.code !== undefined) {
        message.error(i18n.t("errorMsg." + response.data.code));
      }
    }
  });
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.AuthorityManage'))
get_list()
defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("index.less");
</style>
