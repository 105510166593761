<template>
  <Modal id="RegionDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('region.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('region.table.id_reg')">{{data.idReg}}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.address')">{{data.address}}</DescriptionsItem>
<!--      <DescriptionsItem :label="$t('region.table.ac_price_per_electricity') + '(kwh)'">{{data.acPricePerElectricity}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.ac_price_per_minute') + '(min)'">{{data.acPricePerMinute}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.tenant_key')">{{data.tenantKey}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.dc_price_per_electricity') + '(kwh)'">{{data.dcPricePerElectricity}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.dc_price_per_minute') + '(min)'">{{data.dcPricePerMinute}}</DescriptionsItem>-->

<!--      <DescriptionsItem :label="$t('region.table.byPowerUnit') + '(kwh)'">{{data.byPowerUnit}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.byTimeUnit') + '(min)'">{{data.byTimeUnit}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('region.table.postpaid')">{{ data.postpaid === 1 ? $t('public.isTrue.1'):$t('public.isTrue.0')}}</DescriptionsItem>-->

      <DescriptionsItem :label="$t('region.table.lon')">{{data.lon}}</DescriptionsItem>
      <DescriptionsItem :label="$t('region.table.lat')">{{data.lat}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "RegionDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>