<template>
  <Row id="PageContent">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('iPadManage.field.machineCode')" name="machineCode">
                  <Input  v-model:value="searchForm.machineCode" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('iPadManage.field.name')" name="name">
                  <Input  v-model:value="searchForm.name" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('iPadManage.field.regId')" name="regId">
                  <Input  v-model:value="searchForm.regId" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button type="primary" size="default" @click="add()">{{$t('public.name.add')}}</Button>
              <span>&nbsp;</span>
              <Button type="primary" size="default" danger @click="delByIds()">{{ $t('public.name.batchDel')}}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <!-- 列表-->
      <div>
        <Table
            :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :columns="columns"
            :dataSource="list"
            rowKey="id"
            :loading="loading"
            :pagination="tablePagination"
            @change="chang_page">
          <!-- 插槽 -->
          <template #isEnableSwitchTitle>
              <span>
                {{$t("iPadManage.table.isEnable")}}
              </span>
          </template>
          <template #isEnableSwitch="{text}">
            <Switch :checked="text.isEnable === '1'" @click="e => switchClick(e, text)" checked-children="NO" un-checked-children="OFF" />
          </template>

          <!-- 插槽 -->
          <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('IPadManage.table.action.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
          </template>
          <template #action="{text}">
            <div>
              <div class="pc-display">
                <Button type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                <span>&nbsp;</span>
                <Button type="primary" danger size="small" @click="del(text)">{{$t("public.name.del")}}</Button>
              </div>
            </div>
          </template>
        </Table>
      </div>
    </Col>
    <FormModel ref="refFormModel" @updateList="get_list" :selectList="selectList"></FormModel>
  </Row>
</template>

<script>
import {createVNode} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
  Spin
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {IpadGetListByPage, IpadDelete} from "@/api/iPad";
import FormModel from "./FormModel";
import {RegionListAll} from "@/api/region";

export default {
  name: "iPadManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Switch,
    FormModel,
    Spin
  },
  data() {
    return {
      selectList: [],

      searchForm: {},
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      selectedRowKeys: [],
      columns: [
        { title: this.$t("iPadManage.field.index"), fixed: "center", width: 60, customRender: ({text, record, index, column}) => `${index + 1}`},
        { title: this.$t("iPadManage.field.machineCode"), fixed: "center", width: 150, dataIndex: "machineCode", key: "machineCode" },
        { title: this.$t("iPadManage.field.name"), fixed: "center", width: 100, dataIndex: "name", key: "name" },
        { title: this.$t("iPadManage.field.regId"), fixed: "center", width: 50, dataIndex: "regId", key: "regId" },
        { title: this.$t("iPadManage.field.regName"), fixed: "center", width: 100, dataIndex: "regName", key: "regName" },
        { title: this.$t("iPadManage.field.createBy"), fixed: "center", width: 100, dataIndex: "createBy", key: "createBy" },

        { title: this.$t("iPadManage.field.createTime"), fixed: "center", width: 100, dataIndex: "createTime", key: "createTime" },
        { key: "action", fixed: "center", width: 150, slots: { title: "actionTitle", customRender: "action" } },
      ],
      isClickLoading: false
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new IpadGetListByPage();
      // 构建查询参数
      const getData = {
        ...this.searchForm,
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      api.get(getData).then((response) => {
            this.loading = false;
            if (0 === parseInt(response.data.code)) {
              const dataTmp = response.data.data;
              this.tablePagination.current = parseInt(dataTmp.current);
              this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
              this.tablePagination.total = parseInt(dataTmp.total);
              this.list = dataTmp.data
            } else {
              this.list = [];
            }
          });
      // 获取所有区域列表
      const api1 = new RegionListAll();
      api1.get().then(res => {
        if (0 === parseInt(res.data.code)) {
          let l = []
          let regList = res.data.data
          for (let i = 0; i < regList.length; i++) {
            const obj = {
              value: regList[i].idReg,
              label: regList[i].name,
            }
            l.push(obj)
          }
          this.selectList = l
        } else {
          message.error(this.$t("errorMsg." + res.data.code));
        }
      })
    },
    add() {
      this.$refs.refFormModel.addShow();
    },
    edit(obj) {
      this.$refs.refFormModel.editShow(obj);
    },
    del(obj) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new IpadDelete();
          let l = []
          l.push(obj.id)
          api.post({ids: l}).then((response) => {
            if (0 === response.data.code) {
              message.success(this.$t("succeedMsg.0"))
            } else {
              message.error(this.$t("errorMsg." + response.data.code))
            }
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    onSelectChange(ids) {
      this.selectedRowKeys = ids
    },
    delByIds() {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new IpadDelete();
          api.post({ids: this.selectedRowKeys}).then((response) => {
            if (0 === response.data.code) {
              message.success(this.$t("succeedMsg.0"))
            } else {
              message.error(this.$t("errorMsg." + response.data.code))
            }
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    search_submit() {
      this.get_list()
    },
    // 重置查询表单
    search_reset() {
      this.searchForm = {
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        reLeaseTime: undefined,

        createBy: undefined,
        createTime: undefined,
        updateBy: undefined,
        updateTime: undefined,
      }
      this.get_list()
    },
  },
  mounted() {
    // 设置页标题
    this.$store.commit("setPageTitle", this.$t('nav.name.iPadManage'));

    this.get_list();
  },
}
</script>

<style lang="less">
@import url("style");
</style>
