import {Base} from "@/api/Base"

const api = "setMeal"

export class SetMealGet extends Base {
    path = api + "/getInfo"
}

export class SetMealEdit extends Base {
    path = api + "/edit"
}