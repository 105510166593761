<template>
  <Modal
      v-if="visible"
      id="FormModel"
      width="50%"
      :open="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10" v-if="actionType === 'edit'">
                  <FormItem :label="$t('noticeManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('noticeManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('noticeManage.field.toFrom')" name="toFrom">
                    <Input :placeholder="$t('noticeManage.validate.toFrom')"
                           v-model:value="form.toFrom">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('noticeManage.field.type')" name="type">
                    <Select v-model:value="form.type" :placeholder="$t('public.pleaseSelect')" allowClear>
                      <SelectOption :value="0">{{ $t('public.name.email') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.name.phone') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('noticeManage.field.noticeType')" name="noticeType">
                    <Select
                        v-model:value="valueType"
                        mode="multiple"
                        :placeholder="$t('noticeManage.validate.type')"
                        :options="selectList"
                        @change="handleChange"
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('noticeManage.field.status')" name="status">
                    <Select v-model:value="form.status" :placeholder="$t('noticeManage.field.status')" allowClear>
                      <SelectOption :value="0">{{ $t('public.isTrue.0') }}</SelectOption>
                      <SelectOption :value="1">{{ $t('public.isTrue.1') }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <Col span="22">
                  <FormItem :label="$t('noticeManage.field.remark')" name="remark">
                    <Textarea :placeholder="$t('noticeManage.validate.remark')"
                              v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "FormModel"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
  Select,
  SelectOption
} from "ant-design-vue";

import {NoticeAddInfo, NoticeEdit} from "@/api/notice";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emits = defineEmits(['updateList'])

const refForm = ref()

let valueType = ref()
let visible = ref(false)
let title = ref("")
let actionType = ref("")
const selectList = [
  {label: i18n.t("noticeManage.noticeType.0"), value: '0'},
  {label: i18n.t("noticeManage.noticeType.1"), value: '1'},
  {label: i18n.t("noticeManage.noticeType.2"), value: '2'},
  {label: i18n.t("noticeManage.noticeType.3"), value: '3'},
]
const validateRules = {
  id: [
    {
      required: false,
      message: i18n.t("noticeManage.validate.id"),
      trigger: "change",
    },
  ],
  toFrom: [
    {
      required: true,
      message: i18n.t("noticeManage.validate.toFrom"),
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: i18n.t("noticeManage.validate.type"),
      trigger: "change",
    },
  ],
  noticeType: [
    {
      required: true,
      message: i18n.t("noticeManage.validate.noticeType"),
      trigger: "change",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("noticeManage.validate.status"),
      trigger: "change",
    },
  ],
  remark: [
    {
      required: false,
      message: i18n.t("noticeManage.validate.remark"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {
    id: undefined,
    toFrom: undefined,
    type: undefined,
    noticeType: undefined,
    status: undefined,
    remark: undefined
  },
})
const {form} = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add");
  actionType.value = "add";
  _resetData();
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit");
  actionType.value = "edit";
  _resetData();
  form.value = obj;
  if (form.value.noticeType !== undefined) {
    valueType.value = form.value.noticeType.split(',')
  }
  visible.value = true;
}

function submit() {
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new NoticeAddInfo();
        break;
      case "edit":
        api = new NoticeEdit();
        break;
      default:
        return false;
    }
    delete form.value.createTime
    delete form.value.updateTime
    api.post(form.value).then(response => {
      if (0 === parseInt(response.data.code)) {
        message.success(i18n.t("succeedMsg.0"));
        // 触发主页面组件定义事件
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
      }
    });
  }).catch(() => {});
}

function _resetData() {
  valueType.value = undefined
  form.value = {
    id: undefined,
    toFrom: undefined,
    type: undefined,
    noticeType: undefined,
    status: undefined,
    remark: undefined,
  };
}

function handleChange(v) {
  valueType.value = v
  let a = ''
  for (let i=0; v.length > i; i++) {
    a = a + v[i] + ','
  }
  if (a.endsWith(',')) {
    a = a.substring(0, a.length-1)
  }
  form.value.noticeType = a
}

defineExpose({
  addShow,
  editShow
})
</script>