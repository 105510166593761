<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('employee.field.id_rol')" name="idRol">
                  <!--关联表的下拉选择-->
                  <Select
                      v-model:value="searchForm.idRol"
                      :placeholder="$t('employee.validate.required.id_rol')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                    <SelectOption :key="sk" v-for="(item,sk) in roleList" :value="item['idRol']">
                      {{ item['name'] }}
                    </SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('employee.field.account')" name="account">
                  <Input :placeholder="$t('employee.validate.required.account')"
                         v-model:value="searchForm.account" allowClear>
                  </Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('employee.field.email')" name="email">
                  <Input :placeholder="$t('employee.validate.required.email')"
                         v-model:value="searchForm.email" allowClear>
                  </Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('employee.field.name')" name="name">
                  <Input :placeholder="$t('employee.validate.required.name')"
                         v-model:value="searchForm.name" allowClear>
                  </Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8"></Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list" :scroll="{x: 1300}" rowKey="idEmp" :loading="loading" :pagination="tablePagination" @change="chang_page">
          <!-- 标题插槽-->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('EmployeeManage.Add')" type="primary" size="small"
                        shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
            </template>
          </template>
          <!-- 内容插槽-->
          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div>
                <div class="pc-display">
                  <Button v-if="checkPermission('EmployeeManage.Edit')" type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('EmployeeManage.Delete') && record.idEmp !==1" type="primary" danger size="small" @click="del(record.idEmp)">{{$t("public.name.del")}}</Button>
                </div>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <EmployeeDetail ref="refEmployeeDetail"></EmployeeDetail>
    <EmployeeEdit ref="refEmployeeEdit" @updateList="get_list"></EmployeeEdit>
  </Row>
</template>
<script>
export default {
  name: "EmployeeManage"
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import { Row, Col, Table, Button, Dropdown, Menu, MenuItem, Modal, Form, FormItem, Input, Select, message, SelectOption} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { EmployeeList, EmployeeDelete } from "@/api/employee";
import EmployeeDetail from "./EmployeeDetail";
import EmployeeEdit from "./EmployeeEdit";
import {RoleListAll} from "@/api/role";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore()
const i18n = useI18n()

const refEmployeeDetail = ref()
const refEmployeeEdit = ref()

let loading = ref(false)

const columns = [
  { title: i18n.t("employee.table.id_emp"), width: 60, fixed: "left", dataIndex: "idEmp", key: "idEmp" },
  { title: i18n.t("employee.table.id_rol"), width: 100, dataIndex: "roleName", key: "roleName" },
  { title: i18n.t("employee.table.account"), width: 100, ellipsis: true, dataIndex: "account", key: "account" },
  // { title: i18n.t("employee.table.password"), width: 100, dataIndex: "password", key: "password" },
  // { title: i18n.t("employee.table.salt"), width: 100, dataIndex: "salt", key: "salt" },
  // { title: i18n.t("employee.table.last_login_time"), width: 100, dataIndex: "lastLoginTime", key: "lastLoginTime" },
  // { title: i18n.t("employee.table.register_time"), width: 100, dataIndex: "registerTime", key: "registerTime" },
  // { title: i18n.t("employee.table.login_failure_counter"), width: 100, dataIndex: "loginFailureCounter", key: "loginFailureCounter" },
  { title: i18n.t("employee.table.email"), width: 150, ellipsis: true, dataIndex: "email", key: "email" },
  // { title: i18n.t("employee.table.remark"), width: 100, dataIndex: "remark", key: "remark" },
  { title: i18n.t("employee.table.status"), width: 100, dataIndex: "status", key: "status", customRender: ({text}) =>{
      return selectList.statusList.find(item=>item.value===text).label
    }
  },
  { title: i18n.t("employee.table.name"), width: 100, ellipsis: true, dataIndex: "name", key: "name" },
  // { title: i18n.t("employee.table.commission_rate"), width: 100, dataIndex: "commissionRate", key: "commissionRate" },
  // { title: i18n.t("employee.table.yedpay_api_key"), width: 100, ellipsis: true, dataIndex: "yedpayApiKey", key: "yedpayApiKey" },
  // { title: i18n.t("employee.table.yedpay_sign_key"), width: 100, ellipsis: true, dataIndex: "yedpaySignKey", key: "yedpaySignKey" },
  // { title: i18n.t("employee.table.tenant_key"), width: 100, dataIndex: "tenantKey", key: "tenantKey" },
  { key: "action", width: 150, fixed: "right", dataIndex: "action"},
]
const selectList = {
  statusList: [{value: 0,label: 'Off'},{value:1,label: 'On'}],
}

const data = reactive({
  searchForm: {},
  list: [],
  roleList: [],
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  }
})
const {searchForm, list, roleList, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new EmployeeList();
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      getRoleList()
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function detail(obj) {
  refEmployeeDetail.value?.detailShow(obj);
}

function add() {
  refEmployeeEdit.value?.addShow(roleList.value);
}

function edit(obj) {
  refEmployeeEdit.value?.editShow(obj, roleList.value);
}

function del(idEmp) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new EmployeeDelete();
      api.post({ idEmp: idEmp }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function getRoleList() {
  const api = new RoleListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      roleList.value = response.data.data;
    } else {
      if(response.data.code !== undefined){message.error(i18n.t("errorMsg." + response.data.code));}
    }
  });
}

// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.EmployeeManage'));
get_list();

defineExpose({
  get_list
})
</script>
